import Styles from '../styles.module.scss';
import React from 'react';

export default function LimitIcon({ item, isCurrentChat }) {
    return (
        <div className={Styles.item_left_left}>
            {!!+item?.message_limit && (
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.99879 1.3423C1.82416 1.3423 1.65668 1.41167 1.5332 1.53515C1.40972 1.65864 1.34035 1.82611 1.34035 2.00074V9.33704L2.7657 7.91169C2.83516 7.84223 2.92936 7.80321 3.02759 7.80321H9.20043C9.37506 7.80321 9.54254 7.73384 9.66602 7.61036C9.7895 7.48688 9.85887 7.3194 9.85887 7.14477V2.00074C9.85887 1.82611 9.7895 1.65864 9.66602 1.53515C9.54254 1.41167 9.37506 1.3423 9.20043 1.3423H1.99879ZM1.00942 1.01137C1.27182 0.748975 1.6277 0.601562 1.99879 0.601562H9.20043C9.57152 0.601562 9.9274 0.748975 10.1898 1.01137C10.4522 1.27377 10.5996 1.62965 10.5996 2.00074V7.14477C10.5996 7.51586 10.4522 7.87174 10.1898 8.13414C9.9274 8.39654 9.57152 8.54395 9.20043 8.54395H3.18101L1.23187 10.4931C1.12595 10.599 0.966643 10.6307 0.828245 10.5734C0.689847 10.516 0.599609 10.381 0.599609 10.2312V2.00074C0.599609 1.62965 0.747022 1.27377 1.00942 1.01137Z"
                        stroke={!isCurrentChat ? '#6D9BA8' : '#fff'}
                        strokeWidth="0.5"
                    />
                </svg>
            )}
            {!!(!!+item?.like_limit && !+item?.like) && (
                <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.85864 1.88057C9.6911 1.42851 9.39791 1.01755 9.06283 0.688784C8.68586 0.360017 8.22513 0.154538 7.7644 0.072346C7.59686 0.072346 7.38743 0.03125 7.21989 0.03125C6.67539 0.03125 6.17278 0.195634 5.71204 0.442209C5.46073 0.606592 5.20942 0.812072 5 1.05865C4.79058 0.812072 4.58115 0.606592 4.28796 0.442209C3.82723 0.154538 3.32461 0.03125 2.7801 0.03125C2.31937 0.03125 1.85864 0.154538 1.48168 0.360017C1.06283 0.565497 0.727749 0.853168 0.47644 1.22303C0.225131 1.59289 0.0575916 2.00385 0.0157068 2.45591C-0.026178 2.82577 0.0157068 3.27783 0.141361 3.68878C0.39267 4.5518 0.895288 5.33262 1.27225 5.82577C2.10995 6.93536 3.28272 7.92166 4.83246 8.90796L5 9.03125L5.16754 8.90796C7.09424 7.71618 8.39267 6.4833 9.23037 5.08604C9.6911 4.30522 9.94241 3.5655 9.98429 2.90796C10.0262 2.5792 9.98429 2.20933 9.85864 1.88057ZM5 8.20933C3.6178 7.34632 2.57068 6.40111 1.81675 5.41481C1.48168 4.96276 1.02094 4.26413 0.811518 3.4833C0.727749 3.11344 0.685864 2.78467 0.727749 2.497C0.769634 2.16824 0.895288 1.83947 1.06283 1.59289C1.27225 1.30522 1.52356 1.09974 1.81675 0.93536C2.10995 0.770976 2.44503 0.688784 2.7801 0.688784C3.19895 0.688784 3.57592 0.812072 3.911 1.01755C4.24607 1.22303 4.49738 1.5107 4.66492 1.83947C4.78238 2.08643 5.13385 2.08643 5.25131 1.83947C5.41885 1.5107 5.67016 1.22303 6.00524 0.976456C6.51343 0.72988 6.80105 0.72988 7.21989 0.72988C7.34555 0.72988 7.51309 0.72988 7.63874 0.770976C8.01571 0.853168 8.35079 1.01755 8.60209 1.22303C8.89529 1.46961 9.10471 1.75728 9.23037 2.08604C9.31414 2.33262 9.35602 2.5792 9.35602 2.86687C9.31414 3.40111 9.10471 4.01755 8.68586 4.71618C7.89005 5.99015 6.71728 7.14084 5 8.20933Z"
                        stroke={!isCurrentChat ? '#6D9BA8' : '#fff'}
                        strokeWidth="0.5"
                    />
                </svg>
            )}
            {!!+item?.letter_limit && (
                <svg viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.06249 0.03125H0.937506C0.420534 0.03125 0 0.479819 0 1.03126V7.03124C0 7.58268 0.420534 8.03125 0.937506 8.03125H9.06249C9.57947 8.03125 10 7.58268 10 7.03124V1.03126C10 0.479819 9.57947 0.03125 9.06249 0.03125ZM9.06249 0.697913C9.10495 0.697913 9.14534 0.707309 9.18227 0.723719L5 4.59017L0.817709 0.723719C0.854636 0.707331 0.895027 0.697913 0.937486 0.697913H9.06249ZM9.06249 7.36456H0.937506C0.765085 7.36456 0.624997 7.21516 0.624997 7.03122V1.42806L4.79522 5.28319C4.85413 5.33754 4.92707 5.36458 5 5.36458C5.07294 5.36458 5.14587 5.33757 5.20478 5.28319L9.375 1.42806V7.03124C9.37498 7.21516 9.23491 7.36456 9.06249 7.36456Z"
                        stroke={!isCurrentChat ? '#6D9BA8' : '#fff'}
                        strokeWidth="0.5"
                    />
                </svg>
            )}
        </div>
    );
}
