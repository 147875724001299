import React, { useEffect, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import SearchComponent from '../../chat-old/ChatMenu/Header/Search';

export default function StatDatatable({
    data,
    title,
    defaultSortField,
    defaultSortAsc,
    tableColumns,
    expandableRows,
    expandableRowsComponent,
}) {
    createTheme('solarized', {
        text: {
            primary: '#f8f9fe',
            secondary: '#f8f9fe',
        },
        background: {
            default: '#172b4d',
        },
        context: {
            background: '#172b4d',
            text: 'f8f9fe',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(255,255,255,.54)',
            hover: 'rgba(255,255,255,.08)',
            disabled: 'rgba(255,255,255,.12)',
        },
        button: {
            default: 'rgba(168,168,168,1)',
            focus: 'rgba(255,255,255,.5)',
        },
    });

    const [filterValue, setFilterValue] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        data.length && setFilteredItems(data);
    }, [data]);

    const handleClear = () => {
        if (filterValue) setFilterValue('');
        searchHandler('');
    };

    const searchHandler = (value) => {
        setFilterValue(value);
        setFilteredItems(
            data.filter(
                (item) => item.woman_external_id && item.woman_external_id.toLowerCase().includes(value.toLowerCase()),
            ),
        );
    };

    return (
        <DataTable
            title={title}
            subHeader={true}
            subHeaderComponent={
                <SearchComponent
                    style={{ flex: 'none' }}
                    placeHolder={'Search by External ID'}
                    searchInput={filterValue}
                    deleteSearchInput={handleClear}
                    handleSearch={(e) => searchHandler(e.target.value)}
                />
            }
            columns={tableColumns}
            defaultSortField={defaultSortField}
            defaultSortAsc={defaultSortAsc}
            data={filteredItems}
            highlightOnHover
            expandableRows={expandableRows ?? false}
            expandableRowsComponent={expandableRowsComponent}
            style={{ overflow: 'auto', width: 'max-content' }}
        />
    );
}
