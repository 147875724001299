import React, { useRef } from 'react';
import List from './List';
import Edit from './Edit';
import Styles from './styles.module.scss';
import useEffectToLoadNotice from '../../../hooks/notice/useEffectToLoadNotice';
import useHandleNoticeList from '../../../hooks/notice/useHandleNoticeList';
import useHandleNoticeEdit from '../../../hooks/notice/useHandleNoticeEdit';
import useOutsideClicker from '../../../hooks/dom/useOutsideClicker';

export default () => {
    useEffectToLoadNotice();
    const handleNoticeList = useHandleNoticeList();
    const handleNoticeEdit = useHandleNoticeEdit(handleNoticeList);
    const noticeBoxRef = useRef(null);

    useOutsideClicker(noticeBoxRef, () => {
        handleNoticeList.closeNoticeList();
        handleNoticeEdit.closeNoticeEdit();
    });

    return (
        <div className={Styles.notes_wrap} ref={noticeBoxRef}>
            <div className={Styles.notes_position}>
                <div className={Styles.notes_block}>
                    <div className={Styles.notes_block_head}>
                        <div className={Styles.head_title} onClick={handleNoticeList.openNoticeList}>
                            Notes
                        </div>
                        <div className={Styles.head_create_btn} onClick={handleNoticeEdit.openNoticeEdit} />
                    </div>
                    {handleNoticeList?.isActive && <List {...handleNoticeList} {...handleNoticeEdit} />}
                    {handleNoticeEdit?.isActive && <Edit {...handleNoticeEdit} />}
                </div>
            </div>
        </div>
    );
};
