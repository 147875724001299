import React from 'react';

export default ({
    formDataFrom,
    formDataTo,
    openBoxFrom,
    openBoxTo,
    onOpenBlockFrom,
    onOpenBlockTo,
    selectTitle,
    defaultNameFrom,
    defaultNameTo,
    arrayFieldsFrom,
    arrayFieldsTo,
    handleClickFrom,
    handleClickTo,
    index,
}) => {
    return (
        arrayFieldsFrom?.length > 0 &&
        arrayFieldsTo?.length > 0 && (
            <div className="fill_data_form_select_item_wrap">
                <div className="fill_data_form_select_item_name">{selectTitle}</div>
                <div className="fill_data_form_select_item item_two">
                    <div className="cl2h_tab_wide_filter_item_wrap">
                        <div className={`cl2h_tab_wide_filter_item ${openBoxFrom ? 'open' : ''}`}>
                            <div
                                className={`like_select_head ${formDataFrom ? 'selected' : ''}`}
                                onClick={onOpenBlockFrom}
                            >
                                {formDataFrom ? (index ? formDataFrom[`${index}`] : formDataFrom) : defaultNameFrom}
                            </div>
                            <div className={`like_select_list ${openBoxFrom ? 'open' : ''}`}>
                                {arrayFieldsFrom.map((item, key) => (
                                    <React.Fragment key={key}>
                                        <input
                                            type="radio"
                                            name={`like_sel-${key}`}
                                            id={`main-reg-${key}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-${key}`}
                                            className="like_select_label"
                                            type="button"
                                            onClick={() => handleClickFrom(item)}
                                        >
                                            {index ? item[`${index}`] : item}
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div className={`cl2h_tab_wide_filter_item ${openBoxTo ? 'open' : ''}`}>
                            <div className={`like_select_head ${formDataTo ? 'selected' : ''}`} onClick={onOpenBlockTo}>
                                {formDataTo ? (index ? formDataTo[`${index}`] : formDataTo) : defaultNameTo}
                            </div>
                            <div className={`like_select_list ${openBoxTo ? 'open' : ''}`}>
                                {arrayFieldsTo.map((item, key) => (
                                    <React.Fragment key={key}>
                                        <input
                                            type="radio"
                                            name={`like_sel-${key}`}
                                            id={`main-reg-${key}`}
                                            className="like_select"
                                        />
                                        <label
                                            htmlFor={`like_sel-${key}`}
                                            className="like_select_label"
                                            type="button"
                                            onClick={() => handleClickTo(item)}
                                        >
                                            {index ? item[`${index}`] : item}
                                        </label>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};
