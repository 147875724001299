import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import * as IMAGES from '../Images';
import { openNotifyBlock, closeSubMenuBlock, closeMainMenuBlock } from '../../../services/dom';
import * as notifyAction from '../../../store/actions/notify';
import * as chatActions from '../../../store/actions/chats';
import * as filterActions from '../../../store/actions/currentChatMailbox';
import * as senderActions from '../../../store/actions/sender';
import { useLocation } from 'react-router-dom';

export default () => {
    const dispatch = useDispatch();
    const notifyState = useSelector((state) => state.notify);
    const chatState = useSelector((state) => state.chats);
    const location = useLocation();

    const openNotify = () => {
        if (notifyState?.isOpenMobNotify) dispatch(notifyAction.closeMobNotify());
        else dispatch(notifyAction.openMobNotify());

        closeSubMenuBlock();
        closeMainMenuBlock();
        openNotifyBlock();
    };

    const openChatList = () => {
        if (chatState?.isOpenChatList) dispatch(chatActions.closeChatList());
        else dispatch(chatActions.openChatList());
    };

    const openLetterFilters = () => {
        openNotifyBlock();
        dispatch(filterActions.openLetterFilters());
    };

    const openSenderList = () => {
        openNotifyBlock();
        dispatch(senderActions.openSendersList());
    };

    const currentPage = location.pathname.split('/')[1];
    const isLetterPage = ['letter'].includes(currentPage);
    const isSenderPage = ['sender'].includes(currentPage);
    const isChatPage = ['chat', 'chance', 'maybe', 'special'].includes(currentPage);

    const calcNotificationCounter = () => {
        if (
            notifyState?.listPaid?.filter((item) => +item?.read_status === 0)?.length > 0 ||
            notifyState?.listFree?.filter((item) => +item?.read_status === 0)?.length > 0
        ) {
            return (
                +notifyState?.listPaid?.filter((item) => +item?.read_status === 0)?.length +
                +notifyState?.listFree?.filter((item) => +item?.read_status === 0)?.length
            );
        }
        return 0;
    };

    return (
        <div className={`${styles.mob_head}`}>
            <div className={`${styles.mob_head_left}`}>
                {isChatPage && !chatState?.isOpenChatList ? (
                    <div className={`${styles.left_menu_chat_list}`} onClick={openChatList}>
                        <img src={IMAGES.left_arrow} alt="" />
                        <span>Go back</span>
                    </div>
                ) : (
                    <div className={styles.left_menu_empty} />
                )}
                {isLetterPage && (
                    <div className={`${styles.left_menu_chat_list} ${styles.filters}`} onClick={openLetterFilters}>
                        <img src={IMAGES.mob_filters_letters} alt="" />
                        <span>Filters</span>
                    </div>
                )}
                {isSenderPage && (
                    <div className={`${styles.left_menu_chat_list} ${styles.sender}`} onClick={openSenderList}>
                        <img src={IMAGES.user_ico} alt="" />
                        <span>Choose sender</span>
                    </div>
                )}
            </div>
            <div className={`${styles.head_logo}`}>
                <img src={IMAGES.logo} alt="" />
            </div>
            <div className={`${styles.head_notify_btn}`} onClick={openNotify}>
                <img src={IMAGES.alarm} alt="" />
                {calcNotificationCounter() ? (
                    <div className="c2_item_photo_counter">{calcNotificationCounter()}</div>
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
};
