import React from 'react';

import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import useHandleMainMenu from '../../../hooks/side-menu/useHandleMainMenu';
import * as MENU from '../../../constants/menu';
import { useHistory } from 'react-router-dom';
import * as menuAction from '../../../store/actions/menu';
import { closeMobMenu } from '../../../services/dom/letters';

export default () => {
    const handleMainMenu = useHandleMainMenu();
    const openFolder = (folder) => handleMainMenu.openFolder(folder);
    const activeFolder = useHandleMainMenu().activeFolder;
    const history = useHistory();
    const dispatch = useDispatch();

    const onSetMainMenu = (e, url) => {
        history.push(url);
        // dispatch(currentChatAction.addMainChatInfo({}));
        dispatch(menuAction.setMainMenu(e));
        closeMobMenu();
    };

    return (
        <div className={styles.bottom_nav}>
            <div
                className={`${styles.bottom_nav_item} ${activeFolder === 'chance' && styles.active}`}
                onClick={() => openFolder(MENU.MENU_CHANCE)}
            >
                <div className={`${styles.bottom_nav_item_ico} ${styles.chance}`} />
                <div className={styles.bottom_nav_item_text}>Chance</div>
            </div>
            <div
                className={`${styles.bottom_nav_item} ${activeFolder === 'letter' && styles.active}`}
                data-name={MENU.MENU_LETTER}
                onClick={() => openFolder(MENU.MENU_LETTER)}
            >
                <div className={`${styles.bottom_nav_item_ico} ${styles.letters}`} />
                <div className={styles.bottom_nav_item_text}>Letters</div>
            </div>
            <div
                className={`${styles.bottom_nav_item} ${activeFolder === 'chat' && styles.active}`}
                onClick={() => openFolder(MENU.MENU_CHAT)}
            >
                <div className={`${styles.bottom_nav_item_ico} ${styles.chat}`} />
                <div className={styles.bottom_nav_item_text}>Chat</div>
            </div>
            <div
                className={`${styles.bottom_nav_item} ${activeFolder === 'maybe' && styles.active}`}
                onClick={() => openFolder(MENU.MENU_MAYBE)}
            >
                <div className={`${styles.bottom_nav_item_ico} ${styles.maybe}`} />
                <div className={styles.bottom_nav_item_text}>Maybe</div>
            </div>
            <div
                className={`${styles.bottom_nav_item} ${activeFolder === 'special' && styles.active}`}
                onClick={() => openFolder(MENU.MENU_SPECIAL)}
            >
                <div className={`${styles.bottom_nav_item_ico} ${styles.special}`} />
                <div className={styles.bottom_nav_item_text}>Special</div>
            </div>
            <div
                className={`${styles.bottom_nav_item} ${activeFolder === 'menu' && styles.active}`}
                onClick={() => onSetMainMenu(MENU.MENU_MENU, '/menu')}
            >
                <div className={`${styles.bottom_nav_item_ico} ${styles.menu}`} />
                <div className={styles.bottom_nav_item_text}>Menu</div>
            </div>
        </div>
    );
};
