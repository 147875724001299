import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../styles.module.scss';
import Emoji from 'react-emoji-render';
import AudioPlayer from '../../../../../common/AudioPlayer';
import * as modalsAction from '../../../../../../store/actions/modals';
import { renderLastMessage } from '../../../../../../services/methods';
import { stopAudio } from '../../../../../../services/methods/chat';
import * as STICKERS from '../../../../../../constants/stickers';
import * as CHAT from '../../../../../../constants/chat';
import * as IMAGES from '../../../../../common/Images';
import ReplyPost from './ReplyPost';

export default function SwitchMessageContent({ data, setImageOnLoad, womanInfo }) {
    const dispatch = useDispatch();

    const currentChat = useSelector((state) => state.currentChat);

    if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_IMAGE) {
        const openContentModal = () => {
            dispatch(
                modalsAction.openPhotoContentModal({
                    link: data?.message_popup,
                    attachment_payed: '1',
                }),
            );
        };

        return (
            <div className={Styles.message_text} data-testid="message-image">
                <img
                    src={data?.message_thumb}
                    onLoad={() => setImageOnLoad(true)}
                    alt=""
                    className={Styles.message_img}
                    onClick={openContentModal}
                    data-testid={data?.filename}
                />
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_AUDIO) {
        return (
            <div className={Styles.message_text} data-testid="message-audio">
                <div className={Styles.message_audio_wrap}>
                    {/*<div className={Styles.c3_message_audio_title}>{data?.filename}</div>*/}
                    <AudioPlayer src={data?.message_content} viewedAudio={() => stopAudio(data?.message_content)} />
                </div>
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_VIDEO) {
        const openContentModal = () => {
            dispatch(
                modalsAction.openVideoContentModal({
                    link: data?.message_content,
                    attachment_payed: '1',
                }),
            );
        };

        return (
            <div className={Styles.message_text} data-testid="message-video">
                <div className={Styles.message_video} onClick={openContentModal}>
                    {data?.thumb_link?.length ? (
                        <img
                            src={data?.thumb_link}
                            alt=""
                            onLoad={() => setImageOnLoad(true)}
                            data-testid={data?.filename}
                        />
                    ) : (
                        <video width="320" preload="metadata" data-testid={data?.filename}>
                            <source src={`${data?.message_content}#t=0.6`} type="video/mp4" />
                        </video>
                    )}
                </div>
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_TEXT) {
        const lines = data?.message_content.split('\n');

        return (
            <div className={Styles.message_text} data-testid="message-text">
                {/*<Emoji text={data?.message_content}/>*/}
                {lines.map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_WINK) {
        return (
            <div className={`${Styles.message_text}`}>
                <Emoji text={data?.message_content} />
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_STICKER) {
        const selectedSticker = STICKERS.ARRAY_ALL_IMAGES_STICKER.find((e) =>
            e?.name?.includes(data?.message_content),
        )?.image;
        const selectedStickerName = STICKERS.ARRAY_ALL_IMAGES_STICKER.find((e) =>
            e?.name?.includes(data?.message_content),
        )?.name;
        return (
            <div className={Styles.message_text} data-testid="message-sticker">
                {selectedSticker && <img src={selectedSticker} alt="" data-testid={selectedStickerName} />}
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_LIKE) {
        return (
            <div className={`${Styles.message_text} ${Styles.like}`}>
                <img src={IMAGES.c4_like} alt="" />
                <Emoji
                    text={renderLastMessage(
                        data?.message_type,
                        data?.message_content,
                        data?.is_male,
                        currentChat?.info?.name,
                    )}
                />
            </div>
        );
    } else if (
        data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_VIEW ||
        data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_VIEW_PHOTOS
    ) {
        return (
            <div className={`${Styles.message_text} ${Styles.like}`}>
                <img src={IMAGES.c4_viwed} alt="" />
                <Emoji text={data?.message_content} />
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_REPLY) {
        return <ReplyPost data={data} setImageOnLoad={setImageOnLoad} womanInfo={womanInfo} />;
    } else {
        return (
            <div className={Styles.message_text}>
                <Emoji text={data?.message_content} />
            </div>
        );
    }
}
