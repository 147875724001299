import * as currentChatAction from '../../store/actions/currentChat';
import useTransition from '../transition/useTransition';
import { useDispatch, useSelector } from 'react-redux';

export default function useCloseCurrentChat() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const currentChatState = useSelector((state) => state.currentChat);

    return () => {
        if (currentChatState?.info?.chat_uid) {
            const link = window.location.href
                .replace(window.location.origin, '')
                .replace(`/${currentChatState?.info?.chat_uid}`, '');
            transition.transitionPush(link);
            dispatch(currentChatAction.removeCurrentChat());
            return link;
        }
    };
}
