import useTransition from '../transition/useTransition';
import * as MENU from '../../constants/menu';
import * as SYNC from '../../constants/sync';
import { useDispatch, useSelector } from 'react-redux';
import * as chatsAction from '../../store/actions/chats';
import * as notifyAction from '../../store/actions/notify';
import useCloseCurrentChat from './useCloseCurrentChat';
import ChatsApi from '../../services/api/ChatsApi';
import * as chatActions from '../../store/actions/chats';

export default function useHandleChatListItem({ chatItem }) {
    const transition = useTransition();
    const dispatch = useDispatch();
    const closeCurrentChat = useCloseCurrentChat();
    const currentChatState = useSelector((state) => state.currentChat);
    const chatsState = useSelector((state) => state.chats);
    const profilesState = useSelector((state) => state.profiles);

    const linkToWomanProfile = (externalID) => transition.transitionPush(`/${MENU.MENU_PROFILE_WOMAN}/${externalID}`);

    const openChat = (chatUID) => {
        if (window.location.href?.includes(chatUID)) return false;

        if (currentChatState?.info?.chat_uid) {
            if (currentChatState?.info?.chat_uid !== chatUID) {
                const link = closeCurrentChat();
                dispatch(chatsAction.removeUnreadCount(chatUID));
                dispatch(
                    notifyAction.removePaidNotifyByChatUID(
                        chatUID,
                        [SYNC.MESSAGE_NOTIFY_TYPE, SYNC.LIKED_NOTIFY_TYPE],
                        profilesState?.operatorId,
                    ),
                );
                transition.transitionPush(`${link}/${chatUID}`);
            }
        } else {
            const link = window.location.href.replace(window.location.origin, '');
            dispatch(chatsAction.removeUnreadCount(chatUID));
            dispatch(
                notifyAction.removePaidNotifyByChatUID(
                    chatUID,
                    [SYNC.MESSAGE_NOTIFY_TYPE, SYNC.LIKED_NOTIFY_TYPE],
                    profilesState?.operatorId,
                ),
            );
            transition.transitionPush(`${link}/${chatUID}`);
        }
        dispatch(chatActions.closeChatList());
    };

    const onDeleteChat = async (e, chatUID, womanProfileExternalID) => {
        e.preventDefault();
        e.stopPropagation();

        if (chatUID && womanProfileExternalID) {
            dispatch(chatsAction.deleteChat(chatUID));
            closeCurrentChat();

            new ChatsApi().postHideChat(womanProfileExternalID, chatUID);
        }
    };

    return {
        linkToWomanProfile,
        openChat,
        onDeleteChat,
        isPin: chatsState?.pinList.some((item) => item === chatItem?.chat_uid),
    };
}
