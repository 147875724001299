// import {useDispatch, useSelector} from "react-redux";
// import * as onlineManAction from "../../store/actions/onlineMan";
// import * as currentChatAction from "../../store/actions/currentChat";

export default function useHandleOnlineManListItem() {
    // const dispatch = useDispatch();
    // const currentOnlineManChatState = useSelector(state => state.onlineMan.currentChat);
    //
    // const onActiveChat = (item) => {
    // 	dispatch(currentChatAction.removeChatHistory());
    // 	dispatch(onlineManAction.getCurrentOnlineManChat(item));
    // };
    //
    // const onRemoveActiveChat = () => {
    // 	dispatch(onlineManAction.removeCurrentOnlineManChat());
    // 	dispatch(currentChatAction.removeChatHistory());
    // };

    return {
        // onActiveChat,
        // onRemoveActiveChat,
        // currentOnlineManChatState
    };
}
