import Base from '../Base';

export default class ConfigApi extends Base {
    getConfigById(manExternalID, womanExternalID) {
        return super.post(`v3/config/woman`, { manExternalID, womanExternalID }).then((res) => res?.data);
    }

    getConfigHeaderById(manExternalID, womanExternalID) {
        return super.post(`config/type/check`, { manExternalID, womanExternalID }).then((res) => res?.data);
    }

    postUserComplaint(manExternalID, womanExternalID, type) {
        return super
            .post(`v3/config/user-complaint`, { manExternalID, womanExternalID, type })
            .then((res) => res?.data);
    }
}
