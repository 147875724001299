import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { closeMobChatList, scrollSmoothDownInLetterChat, scrollTopToSelectedLetter } from '../../services/dom/letters';
import { isTopPositionInArrHistoryMails } from '../../services/methods';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as LETTER from '../../constants/letter';

export default function useHandleLettersChat() {
    const dispatch = useDispatch();

    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);

    const [pageShiftUp, setPageShiftUp] = useState(null);
    const [pageShiftDown, setPageShiftDown] = useState(null);
    const [openForm, setOpenForm] = useState(false);

    const scrollPosition = (position) => {
        currentChatMailboxState.history.length > 0 &&
            currentChatMailboxState.specialLetterId &&
            scrollTopToSelectedLetter(
                currentChatMailboxState.specialLetterId,
                isTopPositionInArrHistoryMails(currentChatMailboxState.history, position),
            );
    };

    const onScrollList = (event) => {
        // Pagination on scroll up
        if (
            event.target.scrollTop === 0 &&
            event.target.classList.value.includes('scroll') &&
            pageShiftUp &&
            pageShiftUp < currentChatMailboxState?.historyAllPages
        ) {
            dispatch(
                currentChatMailboxAction.getMailboxChatHistoryWithPageUp({
                    userId: currentChatMailboxState?.info?.female_id,
                    manId: currentChatMailboxState?.info?.male_id,
                    folder: 'dialog',
                    page: pageShiftUp + 1,
                }),
            );
            dispatch(currentChatMailboxAction.addMailboxSpecialLetter(currentChatMailboxState?.history[0]?.mail?.id));
            setPageShiftUp(pageShiftUp + 1);
        }
        // Pagination on scroll down
        if (
            Math.round(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight &&
            pageShiftDown &&
            pageShiftDown > 1
        ) {
            dispatch(
                currentChatMailboxAction.getMailboxChatHistoryWithPageDown({
                    userId: currentChatMailboxState?.info?.female_id,
                    manId: currentChatMailboxState?.info?.male_id,
                    folder: 'dialog',
                    page: pageShiftDown - 1,
                }),
            );
            dispatch(
                currentChatMailboxAction.addMailboxSpecialLetter(
                    currentChatMailboxState?.history[currentChatMailboxState?.history?.length - 1]?.mail?.id,
                ),
            );
            setPageShiftDown(pageShiftDown - 1);
        }
        // Hide button "new message", if the scroll is bellow
        Math.round(event.target.scrollTop + event.target.offsetHeight) >= event.target.scrollHeight &&
            dispatch(currentChatMailboxAction.setIsNewMessage(false));
    };

    const scrollDown = () => {
        dispatch(
            currentChatMailboxAction.getMailboxChatHistory({
                userId: currentChatMailboxState?.info?.female_id,
                folder: 'dialog',
                manId: currentChatMailboxState?.info?.male_id,
                page: 1,
            }),
        );
        dispatch(currentChatMailboxAction.setIsNewMessage(false));
        setTimeout(() => scrollSmoothDownInLetterChat(), 500);
    };

    const returnToLetterList = () => {
        currentChatMailboxState?.info?.id && dispatch(currentChatMailboxAction.addMailboxChatInfo({}));
        closeMobChatList();
    };

    const onOpenForm = () => {
        dispatch(currentChatMailboxAction.setInfoCurrentTarget(LETTER.ACTION_TARGET_REPLY));
        setOpenForm(true);
    };

    useEffect(() => {
        if (currentChatMailboxState?.info?.openFromChat) {
            onOpenForm();
        }
    }, [currentChatMailboxState?.info?.openFromChat]);

    useEffect(() => {
        setPageShiftUp(currentChatMailboxState?.historyCurrentPage);
        setPageShiftDown(currentChatMailboxState?.historyCurrentPage);
    }, [currentChatMailboxState.historyCurrentPage]);

    const profiles = useSelector((state) => state?.profiles?.list);
    const headerInfo = profiles.filter(
        (profile) => +currentChatMailboxState?.info?.female_id === +profile?.external_id,
    )[0];

    return {
        pageShiftUp,
        pageShiftDown,
        openForm,
        headerInfo,
        setOpenForm,
        onOpenForm,
        returnToLetterList,
        scrollDown,
        onScrollList,
        scrollPosition,
    };
}
