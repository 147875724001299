import * as LOADER from '../constants/loader';

export function setActiveStatus(status) {
    return function (dispatch) {
        return dispatch({
            type: LOADER.SET_ACTIVE_STATUS,
            data: status,
        });
    };
}

export function setActiveGifLoader(status) {
    return function (dispatch) {
        return dispatch({
            type: LOADER.SET_ACTIVE_GIF_LOADER,
            data: status,
        });
    };
}
