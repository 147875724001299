import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import personal from '../personal.module.scss';
import * as IMAGES from '../../common/Images';
import * as PERSONAL from '../../../constants/personal';
import * as alertAction from '../../../store/actions/alerts';
import cross from '../../../img/cross.svg';
import Styles from '../../chat/ChatList/List/styles.module.scss';
import moment from 'moment';
import femaleImg from '../../../img/icon_female.svg';
import maleImg from '../../../img/icon_male.svg';
import { formatDate } from '../../../services/time';
import arrowUp from '../../../assets/icons/icon_arrow_up.svg';
import arrowDown from '../../../assets/icons/icon_arrow_down.svg';

export default ({
    currentWoman,
    currentMan,
    setCurrentMan,
    personalManList,
    personalHistoryList,
    connect,
    linkToConnection,
    cancelConnect,
    additionalWomanList,
    setAdditionalWomanList,
}) => {
    const dispatch = useDispatch();
    const profilesState = useSelector((state) => state.profiles);

    const [isOnlineWaitList, setIsOnlineWaitList] = useState(true);
    const [filteredPersonalHistoryList, setFilteredPersonalHistoryList] = useState([]);
    const [sortOrder, setSortOrder] = useState('');

    const malePersonalManList = personalManList?.filter((item) => !item?.userProfileAlias?.gender);
    const femalePersonalManList = personalManList?.filter((item) => item?.userProfileAlias?.gender);
    const lessWoman = currentMan?.connect - 1 - additionalWomanList?.length;

    useEffect(() => {
        setFilteredPersonalHistoryList(
            personalHistoryList?.length
                ? personalHistoryList?.filter((item) =>
                      isOnlineWaitList ? item?.userProfileAlias?.last_online >= moment().unix() : true,
                  )
                : [],
        );
    }, [isOnlineWaitList, personalHistoryList.length]);

    useEffect(() => {
        if (sortOrder === 'asc') {
            setFilteredPersonalHistoryList(
                [...filteredPersonalHistoryList].sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),
            );
        }
        if (sortOrder === 'desc') {
            setFilteredPersonalHistoryList(
                [...filteredPersonalHistoryList].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
            );
        }
    }, [sortOrder]);

    const toggleSortOrder = () => {
        sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
    };

    const onSetOnlineWaitList = () => setIsOnlineWaitList(!isOnlineWaitList);

    const onSetCurrentMan = (item) => {
        setAdditionalWomanList([]);
        setCurrentMan({
            ...item?.userProfileAlias,
            external_id: item?.man_external_id,
            id: item?.id,
            type: item?.type,
            blockList: item?.blockList,
            connect: item?.connect,
        });
    };

    const setConnect = () => {
        if (currentMan?.blockList?.includes(+currentWoman?.id)) {
            return dispatch(alertAction.setMessage('This woman is on the block list!'));
        }

        connect();
    };

    const mapProfileItem = (item, key) => {
        return (
            <div
                className={`${styles.choose_man_item} ${currentMan?.id === item?.id ? styles.active : ''}`}
                key={`${key}_${item?.id}`}
                onClick={() => onSetCurrentMan(item)}
            >
                <div className={personal.personal_user_item}>
                    <img src={item?.userProfileAlias?.photo_link ?? IMAGES.profile_img_empty} alt="" />
                    <span className={personal.personal_long_name}>
                        {`${item?.userProfileAlias?.name} ${item?.userProfileAlias?.age}`}
                    </span>
                </div>
                <div className={styles.buttons}>
                    <div className={styles.btn}>
                        {(function () {
                            switch (item?.type) {
                                case PERSONAL.PERSONALISED_INVITE:
                                    return (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M22 11C22 15.0923 17.8799 19 12 19C10.7937 19 9.64282 18.8283 8.58008 18.5169C8.01172 18.3503 7.38574 18.3849 6.82129 18.6457L3.70776 20.0841L4.40625 18.3255C4.77441 17.3987 4.54712 16.3865 3.92188 15.702C2.68335 14.3458 2 12.7174 2 11C2 6.90767 6.12012 3 12 3C17.8799 3 22 6.90767 22 11ZM24 11C24 16.5228 18.6274 21 12 21C10.604 21 9.26367 20.8014 8.01758 20.4361C7.93213 20.4111 7.84204 20.4084 7.75659 20.4283C7.74268 20.4315 7.72876 20.4354 7.71509 20.4398C7.69653 20.4459 7.67822 20.453 7.66016 20.4613L2.27319 22.9499C1.45312 23.3287 0.591064 22.5123 0.924561 21.6729L2.54761 17.5871C2.62036 17.4041 2.57788 17.1961 2.44507 17.0507C0.910889 15.3708 0 13.274 0 11C0 5.47716 5.37256 1 12 1C18.6274 1 24 5.47716 24 11ZM12 13C12.8286 13 13.5 12.3284 13.5 11.5C13.5 10.6716 12.8286 10 12 10C11.1716 10 10.5 10.6716 10.5 11.5C10.5 12.3284 11.1716 13 12 13ZM8.5 11.5C8.5 12.3284 7.82837 13 7 13C6.17163 13 5.5 12.3284 5.5 11.5C5.5 10.6716 6.17163 10 7 10C7.82837 10 8.5 10.6716 8.5 11.5ZM17 13C17.8284 13 18.5 12.3284 18.5 11.5C18.5 10.6716 17.8284 10 17 10C16.1716 10 15.5 10.6716 15.5 11.5C15.5 12.3284 16.1716 13 17 13Z"
                                            />
                                        </svg>
                                    );
                                case PERSONAL.PERSONALISED_LETTER:
                                    return (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M20 5H4C3.92 5 3.8411 5.0047 3.76355 5.01383L8.44791 8.29288C9.21702 7.86329 10.046 7.88563 10.7363 8.09183C11.2086 8.23291 11.6397 8.46253 12.0001 8.70968C12.3605 8.46253 12.7915 8.23291 13.2638 8.09182C13.9542 7.8856 14.7831 7.86325 15.5522 8.29282L20.2364 5.01383C20.1589 5.0047 20.08 5 20 5ZM16.9203 9.77647C17.048 10.0356 17.1436 10.3128 17.2005 10.6068C17.3381 11.3175 17.2359 12.0565 16.9139 12.7811C16.285 14.1963 14.8133 15.5811 12.4858 16.8742L12.0001 17.144L11.5145 16.8742C9.18693 15.5811 7.71526 14.1963 7.08631 12.7811C6.76429 12.0566 6.66204 11.3175 6.79959 10.6069C6.85648 10.3129 6.95209 10.0357 7.07984 9.77654L2.12333 6.30698C2.04356 6.5229 2 6.75636 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 6.75637 21.9564 6.5229 21.8767 6.30698L16.9203 9.77647ZM4 3C1.79086 3 0 4.79086 0 7V17C0 19.2091 1.79086 21 4 21H20C22.2091 21 24 19.2091 24 17V7C24 4.79086 22.2091 3 20 3H4ZM12.7072 10.7071C12.9613 10.453 13.3982 10.139 13.8363 10.0081C14.2272 9.89137 14.5198 9.934 14.7929 10.2071C15.0483 10.4625 15.1866 10.7263 15.237 10.9869C15.2869 11.245 15.2642 11.5684 15.0862 11.9689C14.744 12.7389 13.8427 13.7474 12.0001 14.8464C10.1576 13.7474 9.25617 12.7389 8.91393 11.9689C8.73595 11.5684 8.7132 11.245 8.76315 10.9869C8.81359 10.7263 8.9518 10.4625 9.20723 10.2071C9.48029 9.93405 9.77297 9.89139 10.1639 10.0082C10.602 10.139 11.039 10.4531 11.293 10.7071L12.0001 11.4142L12.7072 10.7071Z"
                                            />
                                        </svg>
                                    );
                                default:
                                    return <div />;
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.clmn_3_personal}>
            <div className={styles.connect_top_row}>
                <div className={`${styles.choose_man_wrap} ${personal.personal_inner_item}`}>
                    <div className={personal.personal_title}>Pick a person</div>
                    <div className={`${personal.personal_scroll} ${styles.choose_man_list}`}>
                        <div>
                            {!!femalePersonalManList?.length && (
                                <>
                                    <div className={`${styles.country_subtitle} ${styles.female_title}`}>
                                        <img src={femaleImg} alt={'female'} />
                                        <span>Female</span>
                                    </div>
                                    <div className={styles.country_content}>
                                        {femalePersonalManList?.map(mapProfileItem)}
                                    </div>
                                </>
                            )}

                            {!!malePersonalManList?.length && (
                                <>
                                    <div className={`${styles.country_subtitle} ${styles.male_title}`}>
                                        <img src={maleImg} alt={'male'} />
                                        <span>Male</span>
                                    </div>
                                    <div className={styles.country_content}>
                                        {malePersonalManList?.map(mapProfileItem)}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${styles.lets_connect_wrap} ${personal.personal_inner_item}`}>
                    <div className={personal.personal_title}>Lets connect</div>
                    {!currentWoman ? (
                        <img className={styles.empty_img} src={IMAGES.personal_empty_connect} alt="" />
                    ) : (
                        <div className={styles.connect_wrap}>
                            <div className={styles.connect_users_row}>
                                <div className={styles.connect_user_item}>
                                    <img
                                        src={currentWoman?.photo_link}
                                        alt=""
                                        className={styles.connect_user_item_photo}
                                    />
                                    <div>{`${currentWoman?.name} ${currentWoman?.age}`}</div>
                                    <img
                                        src={cross}
                                        alt={''}
                                        className={styles.connect_user_item_cross}
                                        onClick={cancelConnect}
                                    />
                                </div>
                                <div className={styles.connect_user_plus}>
                                    <img src={IMAGES.personal_connect_plus} alt="" />
                                </div>
                                <div className={styles.connect_user_item}>
                                    {!!currentMan && (
                                        <>
                                            <img
                                                src={currentMan?.photo_link ?? IMAGES.profile_img_empty}
                                                alt=""
                                                className={styles.connect_user_item_photo}
                                            />
                                            <div>{`${currentMan?.name} ${currentMan?.age}`}</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className={styles.connect_users_less_row}>
                                {!isNaN(lessWoman) && (
                                    <div className={styles.connect_users_less_text}>Less {lessWoman} lady</div>
                                )}
                                <div className={styles.connect_users_less_list}>
                                    {additionalWomanList?.map((item, key) => (
                                        <img key={key} src={item?.photo_link} alt={''} />
                                    ))}
                                </div>
                            </div>

                            {currentMan ? (
                                <div className={styles.connect_buttons}>
                                    <div className={styles.connect_btn} onClick={setConnect}>
                                        Connect
                                    </div>
                                </div>
                            ) : (
                                <div className={styles.empty_partner_text}>Pick your partner</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className={`${styles.connect_wait_list} ${personal.personal_inner_item}`}>
                <div className={personal.personal_title}>
                    {filteredPersonalHistoryList.length ? (
                        <div className={personal.personal_sort} onClick={() => toggleSortOrder()}>
                            {sortOrder === 'desc' ? <img src={arrowUp} alt="" /> : <img src={arrowDown} alt="" />}
                        </div>
                    ) : null}
                    <span>Wait list</span>
                    <div className={Styles.clmn_2_chat_top_filters_is_online} data-testid="online">
                        <input type="checkbox" id="clmn_is_online" checked={isOnlineWaitList} />
                        <label htmlFor="clmn_is_online" onClick={onSetOnlineWaitList} />
                    </div>
                </div>
                {!filteredPersonalHistoryList?.length ? (
                    <img className={styles.empty_img} src={IMAGES.personal_empty_wait} alt="" />
                ) : (
                    <div className={personal.personal_scroll}>
                        {filteredPersonalHistoryList?.map((item, key) => {
                            const womanInfo = profilesState.list.find(
                                (profile) => +profile.external_id === +item?.woman_external_id,
                            );

                            return (
                                <div className={styles.wait_list_item} key={key}>
                                    <div className={styles.wait_list_item_users}>
                                        <div className={styles.creation_date}>{formatDate(item.created_at)}</div>
                                        <div className={styles.users_photos}>
                                            <img src={womanInfo?.photo_link} alt="" />
                                            <img
                                                src={item?.userProfileAlias?.photo_link ?? IMAGES.profile_img_empty}
                                                alt=""
                                            />
                                        </div>
                                        <div className={styles.users_names}>
                                            <p>{`${womanInfo?.name} ${womanInfo?.age}`}</p>
                                            <span>/</span>
                                            <p>{`${item?.userProfileAlias?.name} ${item?.userProfileAlias?.age}`}</p>
                                        </div>
                                        {!!(item?.operator_id && item?.connect > 1) && (
                                            <div className={styles.wait_list_item_blocked}>
                                                {item?.connect - 1} ladies is blocked
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`${styles.wait_list_item_button} ${item?.active ? '' : styles.wait_list_item_button_disabled}`}
                                        onClick={() =>
                                            item?.active &&
                                            linkToConnection(
                                                item?.type,
                                                item?.man_external_id,
                                                item?.woman_external_id,
                                                item?.id,
                                            )
                                        }
                                    >
                                        {(function () {
                                            switch (item?.type) {
                                                case PERSONAL.PERSONALISED_INVITE:
                                                    return (
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M22 11C22 15.0923 17.8799 19 12 19C10.7937 19 9.64282 18.8283 8.58008 18.5169C8.01172 18.3503 7.38574 18.3849 6.82129 18.6457L3.70776 20.0841L4.40625 18.3255C4.77441 17.3987 4.54712 16.3865 3.92188 15.702C2.68335 14.3458 2 12.7174 2 11C2 6.90767 6.12012 3 12 3C17.8799 3 22 6.90767 22 11ZM24 11C24 16.5228 18.6274 21 12 21C10.604 21 9.26367 20.8014 8.01758 20.4361C7.93213 20.4111 7.84204 20.4084 7.75659 20.4283C7.74268 20.4315 7.72876 20.4354 7.71509 20.4398C7.69653 20.4459 7.67822 20.453 7.66016 20.4613L2.27319 22.9499C1.45312 23.3287 0.591064 22.5123 0.924561 21.6729L2.54761 17.5871C2.62036 17.4041 2.57788 17.1961 2.44507 17.0507C0.910889 15.3708 0 13.274 0 11C0 5.47716 5.37256 1 12 1C18.6274 1 24 5.47716 24 11ZM12 13C12.8286 13 13.5 12.3284 13.5 11.5C13.5 10.6716 12.8286 10 12 10C11.1716 10 10.5 10.6716 10.5 11.5C10.5 12.3284 11.1716 13 12 13ZM8.5 11.5C8.5 12.3284 7.82837 13 7 13C6.17163 13 5.5 12.3284 5.5 11.5C5.5 10.6716 6.17163 10 7 10C7.82837 10 8.5 10.6716 8.5 11.5ZM17 13C17.8284 13 18.5 12.3284 18.5 11.5C18.5 10.6716 17.8284 10 17 10C16.1716 10 15.5 10.6716 15.5 11.5C15.5 12.3284 16.1716 13 17 13Z"
                                                            />
                                                        </svg>
                                                    );
                                                case PERSONAL.PERSONALISED_LETTER:
                                                    return (
                                                        <svg
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M20 5H4C3.92 5 3.8411 5.0047 3.76355 5.01383L8.44791 8.29288C9.21702 7.86329 10.046 7.88563 10.7363 8.09183C11.2086 8.23291 11.6397 8.46253 12.0001 8.70968C12.3605 8.46253 12.7915 8.23291 13.2638 8.09182C13.9542 7.8856 14.7831 7.86325 15.5522 8.29282L20.2364 5.01383C20.1589 5.0047 20.08 5 20 5ZM16.9203 9.77647C17.048 10.0356 17.1436 10.3128 17.2005 10.6068C17.3381 11.3175 17.2359 12.0565 16.9139 12.7811C16.285 14.1963 14.8133 15.5811 12.4858 16.8742L12.0001 17.144L11.5145 16.8742C9.18693 15.5811 7.71526 14.1963 7.08631 12.7811C6.76429 12.0566 6.66204 11.3175 6.79959 10.6069C6.85648 10.3129 6.95209 10.0357 7.07984 9.77654L2.12333 6.30698C2.04356 6.5229 2 6.75636 2 7V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V7C22 6.75637 21.9564 6.5229 21.8767 6.30698L16.9203 9.77647ZM4 3C1.79086 3 0 4.79086 0 7V17C0 19.2091 1.79086 21 4 21H20C22.2091 21 24 19.2091 24 17V7C24 4.79086 22.2091 3 20 3H4ZM12.7072 10.7071C12.9613 10.453 13.3982 10.139 13.8363 10.0081C14.2272 9.89137 14.5198 9.934 14.7929 10.2071C15.0483 10.4625 15.1866 10.7263 15.237 10.9869C15.2869 11.245 15.2642 11.5684 15.0862 11.9689C14.744 12.7389 13.8427 13.7474 12.0001 14.8464C10.1576 13.7474 9.25617 12.7389 8.91393 11.9689C8.73595 11.5684 8.7132 11.245 8.76315 10.9869C8.81359 10.7263 8.9518 10.4625 9.20723 10.2071C9.48029 9.93405 9.77297 9.89139 10.1639 10.0082C10.602 10.139 11.039 10.4531 11.293 10.7071L12.0001 11.4142L12.7072 10.7071Z"
                                                            />
                                                        </svg>
                                                    );
                                                default:
                                                    return <div />;
                                            }
                                        })()}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
