import * as COMMON from '../constants/axiosCancelToken';

const initState = {
    source: null,
};

export default function (state = initState, action) {
    switch (action.type) {
        case COMMON.SET_AXIOS_CANCEL_TOKEN:
            return {
                ...state,
                ...{
                    source: action.token,
                },
            };

        default:
            return state;
    }
}
