import { useDispatch, useSelector } from 'react-redux';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as notifyAction from '../../store/actions/notify';
import * as SYNC from '../../constants/sync';
import * as LETTER from '../../constants/letter';

export default function useHandleLettersListItem(item, isDrafts = false, openDraft) {
    const dispatch = useDispatch();
    const notifyState = useSelector((state) => state.notify);
    const profilesState = useSelector((state) => state.profiles);

    const openChat = () => {
        if (isDrafts) openDraft();
        else {
            const selectedChatUid = notifyState?.listPaid?.find((el) => +el?.chat_id === +item?.chat_id);
            // dispatch(notifyAction.removeLetterNotifyByWomanIDAndManID(item?.female_id, item?.male_id));
            if (selectedChatUid) {
                dispatch(
                    notifyAction.removePaidNotifyByChatUID(
                        selectedChatUid?.chat_uid,
                        [SYNC.LETTER_NOTIFY_TYPE],
                        profilesState?.operatorId,
                    ),
                );
            }
            dispatch(
                currentChatMailboxAction.addMailboxChatInfo({
                    ...item,
                    ...{ actionTarget: LETTER.ACTION_TARGET_LETTER },
                }),
            );
            dispatch(
                currentChatMailboxAction.getChatId({
                    userId: item?.female_id,
                    maleId: item?.male_id,
                }),
            );

            dispatch(currentChatMailboxAction.addMailboxSpecialLetter(item?.id));
            dispatch(
                currentChatMailboxAction.getMailboxChatHistory({
                    userId: item?.female_id,
                    folder: 'dialog',
                    manId: item?.male_id,
                    mailId: item?.id,
                }),
            );
        }
    };

    return {
        openChat,
    };
}
