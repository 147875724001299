import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as statisticAction from '../../../store/actions/statistics';
import StatSenderItem from './StatSenderItem';
import StatSearchParams from '../StatSearchParams';
import '../Stat/style.scss';
import styles from '../styles.module.scss';
import list from './list.module.scss';
import LoaderGif from '../../common/LoaderGif';
import * as IMAGES from '../../common/Images';
import { objectToArray } from '../../../services/methods';

export default function StatSender({ selectEndDate, selectStartDate, setSelectEndDate, setSelectStartDate }) {
    const statisticState = useSelector((state) => state.statistic);

    const dispatch = useDispatch();
    const [senderList, setSenderList] = useState([]);
    const [profile_id, setProfile_id] = useState(null);

    const searchButton = () => {
        if (profile_id) dispatch(statisticAction.setSenderStatListBySearch(profile_id));
        else dispatch(statisticAction.senderStatistic(selectStartDate, selectEndDate, profile_id));
    };

    useEffect(() => {
        setSenderList(statisticState?.filterSearch ? statisticState?.filterSenderList : statisticState?.senderList);
    }, []);

    return (
        <div className={styles.stats_clmn_3}>
            <div className={styles.stats_page_head}>
                <StatSearchParams
                    statisticState={statisticState}
                    profile_id={profile_id}
                    setProfile_id={setProfile_id}
                    selectStartDate={selectStartDate}
                    setSelectStartDate={setSelectStartDate}
                    selectEndDate={selectEndDate}
                    setSelectEndDate={setSelectEndDate}
                    searchButton={searchButton}
                />
            </div>
            <div className={styles.stats_page_table_wrap}>
                <div className={list.send_stat_list_wrap}>
                    {senderList?.length ? (
                        senderList?.map((item, key) => <StatSenderItem key={key} item={objectToArray(item)} />)
                    ) : statisticState?.isLoading ? (
                        <LoaderGif />
                    ) : (
                        <div className={styles.block_page_empty}>
                            <img src={IMAGES.not_found_results} alt="" />
                            <div>Your statistic list is empty</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
