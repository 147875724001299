import Base from './Base';
import { objectToArray } from '../methods';

export default class ChatsAPI extends Base {
    showSingleChat(chat_uid) {
        return super.post(`chat/show`, { chat_uid: chat_uid }).then((res) => res?.data);
    }

    checkDialog(chat_uid, external_id) {
        return super.get(`chat/checkDialog?chat_uid=${chat_uid}&external_id=${external_id}`).then((res) => res?.data);
    }

    getLastMessage(external_id) {
        return super.get(`chat/getLastMessage?external_id=${external_id}`).then((res) => res?.data);
    }

    getChatListByUserId(userId, isOnline, searchItem, chatType, token = null, page = 1, chat_uid = false) {
        // return super.get(`chats/chatListByUserID?user_id=${userId}&ONLINE_STATUS=${isOnline ? 1 : 0}&SEARCH= ${searchItem}&CHAT_TYPE=${chatType}`)
        return super
            .post(
                `chat/chatListByUserID`,
                {
                    user_id: userId,
                    page: page,
                    ONLINE_STATUS: isOnline ? 1 : 0,
                    SEARCH: searchItem,
                    chat_uid: chat_uid,
                    CHAT_TYPE: chatType,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) =>
                res?.data?.status
                    ? {
                          page: res?.data?.page,
                          chats: objectToArray(res?.data?.chats),
                          status: res?.data?.status,
                          message: 'OK',
                      }
                    : {
                          page: 1,
                          chats: null,
                          status: res?.data?.status,
                          message: res?.data?.error,
                      },
            )
            .catch(() => ({ canceled: true }));
    }

    postBlockListByUserId(userId, token = null) {
        return super
            .post(`chat/blockListByUserID`, { user_id: userId }, token ? { cancelToken: token } : {})
            .then((res) =>
                res?.data?.status
                    ? {
                          chats: objectToArray(res?.data?.chats),
                          status: res?.data?.status,
                          message: 'OK',
                      }
                    : {
                          chats: [],
                          status: res?.data?.status,
                          message: res?.data?.error,
                      },
            )
            .catch(() => ({ canceled: true }));
    }

    postTranslate(text, source = 'ru', target = 'en') {
        return super.post(`chat/translate`, { text, source, target }).then((res) => res?.data);
    }

    postMessage(
        senderId,
        recipientId,
        messageContent,
        messageType,
        filename,
        token = null,
        chance = false,
        contentID = null,
    ) {
        return super
            .post(
                `chat/message`,
                {
                    sender_id: senderId,
                    recipient_id: recipientId,
                    message_content: messageContent,
                    message_type: messageType,
                    filename: filename ? filename : '',
                    ...(chance ? { chance: chance } : {}),
                    ...(contentID ? { content_id: contentID } : {}),
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    // getHistory(chatId, page = 1, token=null) {
    // 	return super.get(`chat/history?chat_id=${chatId}&page=${page}`, token ? {cancelToken: token} : {})
    // 		.then(res => res?.data)
    // 		.catch(() => ({canceled: true}))
    // }

    postSetFavorite(userId, chatId, favorite, token = null) {
        return super
            .post(
                `chat/setFavorite`,
                {
                    user_id: userId,
                    chat_uid: chatId,
                    favorite: favorite ? 1 : 0,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    postSetMaybe(userId, chatId, maybe, token = null) {
        return super
            .post(
                `chat/setMaybe`,
                {
                    user_id: userId,
                    chat_uid: chatId,
                    maybe: maybe ? 1 : 0,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    postSetSpecial(userId, chatId, special, token = null) {
        return super
            .post(
                `chat/setSpecial`,
                {
                    user_id: userId,
                    chat_uid: chatId,
                    special: special ? 1 : 0,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    postAddBlackList(userId, chatId, token = null) {
        return super
            .post(
                `chat/addBlackList`,
                {
                    user_id: userId,
                    chat_uid: chatId,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    postRemoveBlackList(userId, chatId, token = null) {
        return super
            .post(
                `chat/removeBlackList`,
                {
                    user_id: userId,
                    chat_uid: chatId,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    getCounters({ user_id, token = null }) {
        return super
            .get(`chat/counters?user_id=${user_id}`, token ? { cancelToken: token } : {})
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    postHideChat(userId, chatUid) {
        return super
            .post(`chat/hideChat`, {
                user_id: userId,
                chat_uid: chatUid,
            })
            .then((res) => res?.data);
    }

    postSetMessage(messageObject, chatUid, recipientExternalId) {
        return super.post(`chat/setMessage`, {
            message_object: messageObject,
            chat_uid: chatUid,
            recipient_external_id: recipientExternalId,
        });
    }

    postEnableChat(chatUid, chatId, token = null) {
        return super
            .post(
                `chat/enableChat`,
                { chat_uid: chatUid, ...(chatId ? { chat_id: chatId } : {}) },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    postReadChat(chatUid, female_id) {
        return super
            .post(`chat/readChat`, {
                chat_uid: chatUid,
                female_id: female_id,
            })
            .then((res) => res?.data);
    }

    hideMessage(messageID) {
        return super
            .post(`chat/hideMessage`, {
                message_id: messageID,
            })
            .then((res) => res?.data);
    }
}
