import React, { useEffect, useState } from 'react';
import Card from '../Card';
import update from 'immutability-helper';

const Container = ({ data, deleteUploadedImage, moveImage }) => {
    const [cards, setCards] = useState([]);
    const moveCard = (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        const updatedCards = update(cards, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        });

        setCards(updatedCards);
        moveImage(updatedCards.map((el) => el?.file));
    };

    useEffect(() => {
        const newArrayObj = data.map((file, index) => ({
            id: index + 1,
            file,
        }));

        setCards(newArrayObj);
    }, [data]);

    return (
        <React.Fragment>
            {cards &&
                cards.map((card, i) => (
                    <Card
                        key={`${card?.id}_${i}`}
                        index={i}
                        id={card?.id}
                        data={card}
                        moveCard={moveCard}
                        deleteUploadedImage={deleteUploadedImage}
                    />
                ))}
        </React.Fragment>
    );
};
export default Container;
