import StatisticApi from '../../services/api/StatisticApi';
import * as STATISTIC from '../constants/statistic';

function objectToArraySender(obj) {
    const arr = [];

    for (let key in obj) {
        arr.push({ ...{ id: key, name: obj[key][0].name }, ...obj[key] });
    }

    return arr;
}

function getProfileArray(obj) {
    const arr = [];

    for (let key in obj) {
        arr.push(key);
    }

    return arr;
}

function statisticFormatter(list, result = []) {
    list.forEach((item) => {
        if (Array.isArray(item)) statisticFormatter(item, result);
        else result.push(item);
    });

    return result;
}

export function getOperatorStatistic(dateStart, dateEnd, token = null) {
    return function (dispatch) {
        dispatch({
            type: STATISTIC.LOADING_STAT_LIST,
            isLoading: true,
        });
        new StatisticApi().getOperatorStatistic(dateStart, dateEnd, token).then((res) => {
            if (res?.status) {
                dispatch({
                    type: STATISTIC.GET_OPERATOR_STATISTIC,
                    statistic: statisticFormatter(res?.response),
                });
            }
        });
    };
}

export function senderStatistic(selectStartDate = '', selectEndDate = '') {
    return function (dispatch) {
        dispatch({
            type: STATISTIC.LOADING_STAT_LIST,
            isLoading: true,
        });
        new StatisticApi().getSenderStatistics(selectStartDate, selectEndDate).then((res) => {
            if (Array.isArray(res?.response) && res?.response?.length && res?.response[0]?.operator_id) {
                return dispatch({
                    type: STATISTIC.SENDER_STAT_LIST,
                    list: objectToArraySender(res?.response[0]),
                    detailList: objectToArraySender(res?.response[1]),
                    profileList: getProfileArray(res?.response[0]),
                });
            } else {
                dispatch({
                    type: STATISTIC.LOADING_STAT_LIST,
                    isLoading: false,
                });
            }
        });
    };
}

export function setSenderStatListBySearch(filterSearch) {
    return function (dispatch) {
        return dispatch({
            type: STATISTIC.SENDER_STAT_LIST_FILTER_SEARCH,
            filterSearch,
        });
    };
}

export function setDetailFilter(detailFilterSearch) {
    return function (dispatch) {
        return dispatch({
            type: STATISTIC.SENDER_DETAIL_FILTER,
            detailFilterSearch,
        });
    };
}

export function setTypesStatistic(token = null) {
    return function (dispatch) {
        new StatisticApi().getTypesStatistic(token).then((res) => {
            return dispatch({
                type: STATISTIC.SET_TYPES_STATISTIC,
                typesStatistic: res?.response,
            });
        });
    };
}
