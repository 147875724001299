import { sendErrToSentry } from '../sentry';

export function addBlur() {
    const blockColumn1 = document.querySelector('.column-1');
    if (blockColumn1) blockColumn1.classList.add('blur');

    const blockColumn2 = document.querySelector('.column-2');
    if (blockColumn2) blockColumn2.classList.add('blur');

    const blockColumn3 = document.querySelector('.column-3');
    if (blockColumn3) blockColumn3.classList.add('blur');

    const blockColumn4 = document.querySelector('.column-4');
    if (blockColumn4) blockColumn4.classList.add('blur');
}

export function removeBlur() {
    const blockColumn1 = document.querySelector('.column-1');
    if (blockColumn1) blockColumn1.classList.remove('blur');

    const blockColumn2 = document.querySelector('.column-2');
    if (blockColumn2) blockColumn2.classList.remove('blur');

    const blockColumn3 = document.querySelector('.column-3');
    if (blockColumn3) blockColumn3.classList.remove('blur');

    const blockColumn4 = document.querySelector('.column-4');
    if (blockColumn4) blockColumn4.classList.remove('blur');
}

export function scrollTopToSelectedLetter(id, alignToTop) {
    if (document.getElementById(`letter-${id}`)) {
        document.getElementById(`letter-${id}`).scrollIntoView(alignToTop);
    }
}

export function scrollSmoothDownInLetterChat() {
    try {
        const letterList = document.querySelectorAll('.c3l_letters_list_item');

        if (letterList && letterList[letterList?.length - 1]) {
            letterList[letterList?.length - 1].scrollIntoView({
                block: 'end',
                behavior: 'smooth',
                inline: 'nearest',
            });
        }
    } catch (error) {
        sendErrToSentry(error);
    }
}

export function onClickCloseMenu() {
    try {
        document.addEventListener('click', function (e) {
            const divMenu = document.querySelector('.c1_bottom_menu');
            const divMenuTitle = document.querySelector('.c1_bottom_menu_title');
            if (divMenu && divMenuTitle && e.path && !e.path.includes(divMenu) && !e.path.includes(divMenuTitle)) {
                divMenu.classList.remove('active');
            }
        });
    } catch (error) {
        sendErrToSentry(error);
    }
}

export function closeMobMenu() {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
    }

    const blockColumn1 = document.querySelector('.column-1');
    if (blockColumn1) blockColumn1.classList.remove('active');
}

export function closeMobChatList() {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
    }

    const blockColumn2 = document.querySelector('.column-2');
    if (blockColumn2) blockColumn2.classList.remove('active');
}
