import Base from './Base';

export default class ProfileApi extends Base {
    getProfileInfo(external_id) {
        return super.get(`operator/profile?user_id=` + external_id).then((res) => res?.data);
    }

    getManProfileInfo(external_id) {
        const activeProfile = +JSON.parse(localStorage.getItem('active_profile'))?.external_id;
        return super
            .get(`operator/myProfile?user_id=${external_id}&activeProfile=${!isNaN(activeProfile) && activeProfile}`)
            .then((res) => res?.data);
    }

    getChatWithProfile(male_id, female_id) {
        return super
            .get(`operator/chatWithProfile?male_id=` + male_id + `&female_id=` + female_id)
            .then((res) => res?.data);
    }

    setProfileOnline({ external_id, operator_id, status }) {
        return super
            .post(`operator/setProfileOnline`, {
                external_id: external_id,
                operator_id: operator_id,
                status: status,
            })
            .then((res) => res?.data);
    }
}
