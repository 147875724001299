import Modal from 'react-modal';
import React, { useRef, useState } from 'react';
import modalContent from '../../../styles/react-modal/modalContent.style';
import styles from '../styles.module.scss';
import * as IMAGES from '../../common/Images';
import * as POSTS from '../../../constants/posts';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import DnDContainer from './DragAndDrop/Container';
import useOutsideClicker from '../../../hooks/dom/useOutsideClicker';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

export default function CreatePostModal({
    createModalType,
    closeCreateModal,
    activeProfile,
    newPostData,
    uploadMedia,
    deleteUploadedImage,
    moveImage,
    handleDescription,
    saveCreatePost,
    saveEditPost,
    createImgRowRef,
    addEmojiDescription,
    isCreateAnother,
    toggleIsCreateAnother,
    onDeleteDraft,
}) {
    const [isOpenedSmile, setIsOpenedSmile] = useState(false);

    const emptyCardLength = POSTS.FILES_MAX_LENGTH - newPostData?.files.length;
    const isModalTypeEdit = createModalType === 'edit';
    const isPostTypeDraft = newPostData?.status === POSTS.STATUS_DRAFT;

    const smileBlockRef = useRef(null);
    const smileIconRef = useRef(null);
    const fileInputRef = useRef(null);

    useOutsideClicker(smileBlockRef, setIsOpenedSmile, smileIconRef);

    const toggleSmileBlock = () => {
        setIsOpenedSmile(!isOpenedSmile);
    };

    const onSaveDraft = () => {
        if (isPostTypeDraft) {
            saveEditPost({ expectedStatus: POSTS.STATUS_DRAFT });
        } else {
            saveCreatePost({ expectedStatus: POSTS.STATUS_DRAFT });
        }
    };

    const onSaveCreatePost = () => {
        if (isPostTypeDraft) {
            saveEditPost({ expectedStatus: POSTS.STATUS_PROCESS });
        } else {
            saveCreatePost({ expectedStatus: POSTS.STATUS_PROCESS });
        }
    };

    const onUploadMedia = (e) => {
        uploadMedia(e.target.files[0]);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    return (
        <Modal style={modalContent} isOpen={createModalType} ariaHideApp={false}>
            <div className={`${styles.post_modal}`}>
                <img
                    className={styles.post_modal_close}
                    src={IMAGES.close_icon}
                    alt="Close"
                    onClick={closeCreateModal}
                />

                <div className={styles.post_modal_header}>
                    <div className={styles.post_modal_profile_box}>
                        <img src={activeProfile?.photo_link ?? ''} alt="" />

                        <div className={styles.post_modal_profile_info}>
                            {activeProfile?.name ?? ''}&nbsp;{activeProfile?.age ?? ''}
                            <span>ID {activeProfile?.external_id ?? ''}</span>
                        </div>
                    </div>
                    <div className={styles.post_modal_title}>
                        {isModalTypeEdit ? 'Edit' : 'Create'} {isPostTypeDraft ? 'draft' : 'post'}
                    </div>
                </div>

                <div className={styles.post_modal_add_img_row}>
                    <div className={styles.post_modal_img_counter}>
                        Media files<span>{newPostData?.files?.length ?? '0'}</span>
                    </div>
                    <input
                        type="file"
                        accept="image/*, video/*"
                        // multiple
                        id="post_page_upload_img"
                        ref={fileInputRef}
                        onChange={onUploadMedia}
                    />
                    <label htmlFor="post_page_upload_img" className={styles.post_modal_add_img_btn}>
                        Add photo/video
                    </label>
                </div>

                <div className={styles.post_modal_img_row} ref={createImgRowRef}>
                    <DndProvider backend={Backend}>
                        <DnDContainer
                            data={newPostData?.files}
                            deleteUploadedImage={deleteUploadedImage}
                            moveImage={moveImage}
                        />
                    </DndProvider>

                    {new Array(emptyCardLength).fill(0).map((item, index) => (
                        <div className={styles.post_modal_img_wrap} key={index}>
                            <div className={styles.post_modal_img_empty} />
                        </div>
                    ))}
                </div>

                <div className={styles.post_modal_warning_text}>You can add no more than 5 media files.</div>

                <div className={styles.post_modal_description_row}>
                    Description
                    <span>
                        {newPostData?.text?.length ?? 0}/{POSTS.POST_TEXT_MAX_LENGTH}
                    </span>
                </div>

                <div className={styles.post_modal_textarea_wrap}>
                    <textarea
                        className={styles.post_modal_textarea}
                        placeholder="Add Text..."
                        value={newPostData?.text}
                        onChange={(e) => handleDescription(e.target.value)}
                        maxLength={POSTS.POST_TEXT_MAX_LENGTH}
                    />
                    <img
                        className={styles.post_modal_smile_icon}
                        src={IMAGES.smile_icon ?? ''}
                        alt=""
                        onClick={toggleSmileBlock}
                        ref={smileIconRef}
                    />

                    {isOpenedSmile && (
                        <div
                            className={`${styles.post_modal_smiles_block} ${styles.description}`}
                            ref={smileBlockRef}
                            id="create-post-smiles"
                        >
                            <Picker
                                data={data}
                                onEmojiSelect={addEmojiDescription}
                                emojiSize={30}
                                perLine={8}
                                theme="light"
                                navPosition="none"
                                previewPosition="none"
                                searchPosition="none"
                            />
                        </div>
                    )}
                </div>

                {!isModalTypeEdit && (
                    <div className={styles.post_modal_create_another}>
                        <input type="checkbox" id="create-another-post" checked={isCreateAnother} />
                        <label htmlFor="create-another-post" onClick={toggleIsCreateAnother}>
                            Create another post
                        </label>
                    </div>
                )}

                <div className={styles.posts_page_btns_row}>
                    {isModalTypeEdit && !isPostTypeDraft ? (
                        <button className={`${styles.posts_page_create_btn}`} onClick={saveEditPost}>
                            <img src={IMAGES.save_icon} alt="" />
                            Save edit
                        </button>
                    ) : (
                        <button className={`${styles.posts_page_create_btn}`} onClick={onSaveCreatePost}>
                            <img src={IMAGES.add_box_white} alt="" />
                            Create new post
                        </button>
                    )}

                    {isPostTypeDraft && (
                        <button
                            className={`${styles.posts_page_create_btn} ${styles.outline} ${styles.delete}`}
                            onClick={onDeleteDraft}
                        >
                            <img src={IMAGES.trash_bin_draft} alt="" />
                            Delete draft
                        </button>
                    )}

                    {newPostData?.status !== POSTS.STATUS_REWORK && (
                        <button className={`${styles.posts_page_create_btn} ${styles.outline}`} onClick={onSaveDraft}>
                            <img src={IMAGES.save_draft} alt="" />
                            Save to draft
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
}
