import React from 'react';
import Item from './Item';
import Styles from './styles.module.scss';
import useHandlePaidNotification from '../../../hooks/notification/useHandlePaidNotification';
import useMakeNotificationSound from '../../../hooks/sound/useMakeNotificationSound';
import * as IMAGES from '../../common/Images';
import { useSelector } from 'react-redux';
import useHandleMainMenu from '../../../hooks/side-menu/useHandleMainMenu';
import { diffDaysByUnix } from '../../../services/time';

export default function Paid() {
    const personalState = useSelector((state) => state.personal);
    const handlePaidNotification = useHandlePaidNotification();
    const { openFolder } = useHandleMainMenu();
    const { isActiveSoundIcon, setSoundNotification } = useMakeNotificationSound();

    const list = [
        ...(personalState?.personalManList?.length ? personalState.personalManList : []),
        ...(personalState?.personalHistoryList?.length ? personalState.personalHistoryList : []),
    ]?.filter((item) => item?.operator_id);

    return (
        <div className={`${Styles.clmn_4_block} ${Styles.paid}`} data-testid="paid-notification-list">
            <div className={Styles.clmn_4_block_head}>
                <div className={Styles.clmn_4_block_sorting}>
                    <div
                        onClick={handlePaidNotification.onSetFilterUp}
                        className={`${Styles.clmn_4_block_sorting_item} ${Styles.up} ${handlePaidNotification?.filterUp ? Styles.active : ''}`}
                        data-testid="paid-notification-sorting-up"
                    />
                    <div
                        onClick={handlePaidNotification.onSetFilterDown}
                        className={`${Styles.clmn_4_block_sorting_item} ${Styles.down} ${handlePaidNotification?.filterDown ? Styles.active : ''}`}
                        data-testid="paid-notification-sorting-down"
                    />
                </div>
                <div
                    className={Styles.c4_mute_btn}
                    onClick={setSoundNotification}
                    data-testid="paid-notification-mute-btn"
                >
                    <img src={isActiveSoundIcon ? IMAGES.muted : IMAGES.mute} alt="" />
                </div>
                <div
                    className={Styles.clmn_4_block_head_clear}
                    onClick={handlePaidNotification.clearAll}
                    data-testid="paid-notification-clear-all-btn"
                >
                    Сlear all
                </div>
            </div>
            <div className={Styles.clmn_4_block_list}>
                {!!list?.length &&
                    list?.map((item, key) => (
                        <div
                            className={`${Styles.clmn_4_block_paid_item} ${Styles.write}`}
                            key={key}
                            onClick={() => openFolder('personal')}
                            data-testid={`personal-${item?.id}`}
                        >
                            <div
                                className={`${Styles.clmn_4_block_paid_item_man_photo_wrap} ${+diffDaysByUnix(item?.userProfileAlias?.last_online) > 0 && Styles.online}`}
                            >
                                <img
                                    src={item?.userProfileAlias?.photo_link || IMAGES.profile_img_empty}
                                    alt=""
                                    className={Styles.clmn_4_block_paid_item_man_photo}
                                />
                            </div>
                            <div className={Styles.clmn_4_block_paid_item_middle}>
                                <div className={Styles.clmn_4_block_paid_item_write_name}>
                                    <img src={IMAGES.c4_write_attention} alt="" />
                                    <span>{`${item?.userProfileAlias?.name || ''}, ${item?.userProfileAlias?.age}`}</span>
                                </div>
                                <div className={Styles.clmn_4_block_paid_item_write_text}>
                                    {item?.type === 'PERSONALISED_INVITE'
                                        ? 'Please write him message'
                                        : 'Please write him letter'}
                                </div>
                            </div>
                        </div>
                    ))}
                {!!handlePaidNotification?.paidNotificationList?.length &&
                    handlePaidNotification.paidNotificationList.map((item, key) => (
                        <Item key={key} item={item} {...handlePaidNotification} />
                    ))}
            </div>
        </div>
    );
}
