import React from 'react';
import SenderMsg from './SenderMsg';

export default ({ currentChatMailboxState, scrollPosition, isUserOnline, history }) => {
    return (
        history?.length > 0 &&
        history.map((item) => (
            <SenderMsg
                item={item}
                key={item?.mail?.id}
                currentChatMailboxState={currentChatMailboxState}
                scrollPosition={scrollPosition}
                sender={+item?.mail?.operator}
                isUserOnline={isUserOnline}
            />
        ))
    );
};
