import UserApi from '../../services/api/UserApi';
import {
    SET_FILTER_BY_SEARCH,
    SET_LIST_BY_SEARCH,
    SET_FILTERS_SEARCH,
    SET_NEXT_SEARCH_PAGE,
    SET_LOADING,
    CLEAR_FILTERS_SEARCH,
} from '../constants/search';

const defaultParams = {
    AGE_FROM: 0,
    AGE_TO: 0,
    HEIGHT_FROM: null,
    HEIGHT_TO: null,
    BODY: null,
    HAIR: null,
    EYE: null,
    STATUS: null,
    NAME: null,
};

export function getMenBySearch(options, page) {
    return function (dispatch) {
        const data = {
            filter: {
                ...defaultParams,
                ...options,
            },
            page,
        };
        new UserApi().searchMen(data).then((res) => {
            const list = res && res?.result ? res?.result : [];
            return dispatch({
                type: SET_LIST_BY_SEARCH,
                data: list,
                page,
            });
        });
    };
}

export function setSearchFilter(name, value) {
    return function (dispatch) {
        return dispatch({
            type: SET_FILTER_BY_SEARCH,
            name,
            value,
        });
    };
}

export function setMultipleFilters(data) {
    return function (dispatch) {
        return dispatch({
            type: SET_FILTERS_SEARCH,
            data,
        });
    };
}

export function nextPage() {
    return function (dispatch) {
        return dispatch({
            type: SET_NEXT_SEARCH_PAGE,
        });
    };
}

export function setLoading() {
    return function (dispatch) {
        return dispatch({
            type: SET_LOADING,
        });
    };
}

export function clearConfigSearch() {
    return function (dispatch) {
        return dispatch({
            type: CLEAR_FILTERS_SEARCH,
        });
    };
}
