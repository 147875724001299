import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { cutLengthString, makeCDN } from '../../../../../services/methods';
import * as LENGTH from '../../../../../constants/length';
import * as filesAction from '../../../../../store/actions/files';
import * as modalsAction from '../../../../../store/actions/modals';
import FilesApi from '../../../../../services/api/FilesApi';
import * as ATTACH from '../../../../../constants/attach';
import * as IMAGES from '../../../Images';
import * as loaderAction from '../../../../../store/actions/loader';
import * as alertAction from '../../../../../store/actions/alerts';
import * as ALERTS from '../../../../../store/constants/alerts';
import { sendErrToSentry } from '../../../../../services/sentry';

export default ({ checked, elementRef, data, opacity }) => {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState(false);

    const handleInputChangeVideo = (e) => {
        const checkBox = e.currentTarget.querySelector('input');
        if (checkBox) {
            setSelectedItem(!checkBox.checked);
            checkBox.checked = !checkBox.checked;
        }
    };

    const openContentModal = () => {
        dispatch(
            modalsAction.openVideoContentModal({
                link: data?.link,
                attachment_payed: '1',
            }),
        );
    };

    const onDeleteItem = () => {
        dispatch(
            alertAction.setCustomConfirmAlert({
                type: ALERTS.SET_CUSTOM_CONFIRM_ALERT,
                title: `Are you sure you want to delete this file?`,
                btnName1: 'No',
                btnName2: 'Yes',
                successCallback: () => deleteItem(),
            }),
        );
    };

    const deleteItem = () => {
        dispatch(loaderAction.setActiveGifLoader(true));
        try {
            new FilesApi()
                .postDeleteMedia(data?.id, data?.external_id)
                .then((res) => {
                    if (res?.status) {
                        dispatch(filesAction.getAllVideos(data?.external_id));
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        } catch (error) {
            sendErrToSentry(error);
            dispatch(loaderAction.setActiveGifLoader(false));
        }
    };

    const handleSort = (e) => {
        const listItems = e.currentTarget.parentNode.querySelectorAll('.upload_popup_tabs_content_item');
        const sortedArr = [];

        // for (let [key, item] of listItems.entries()) {
        // 	if (item?.dataset?.id) {
        // 		sortedArr.push({
        // 			id: item?.dataset?.id,
        // 			sort_order: key,
        // 		});
        // 	}
        // }

        for (let [, item] of listItems.entries()) {
            if (item.dataset.id) {
                sortedArr.push({
                    id: +item.dataset.id,
                });
            }
        }

        new FilesApi().postUpdateSortOrder(ATTACH.UPD_SORT_VIDEO, sortedArr, data?.external_id);
    };

    return (
        <div
            className={`upload_popup_tabs_content_item ${selectedItem ? 'selected' : ''}`}
            ref={elementRef}
            style={{ opacity }}
            data-id={data?.id}
            onDrop={handleSort}
            data-testid={data?.filename}
        >
            <div className="no-drag upload_popup_tabs_content_item_top" onClick={handleInputChangeVideo}>
                <input
                    type="checkbox"
                    className="no-drag"
                    name={data?.id}
                    checked={checked}
                    onChange={handleInputChangeVideo}
                    data-link={data?.link}
                    data-filename={data?.filename}
                    data-contentid={data.id}
                    id={'vp' + data?.id}
                />
                <label htmlFor={'vp' + data?.id} className="no-drag video_label" onClick={handleInputChangeVideo}>
                    {data?.thumb_link?.length ? (
                        <img className="popup_full_img" src={data?.thumb_link} alt="" />
                    ) : (
                        <video width="214" height="115" id={'video-' + data?.id} controls={false} preload="metadata">
                            <source src={`${makeCDN(data?.link, 'VIDEO')}#t=0.6`} type="video/mp4" />
                        </video>
                    )}
                </label>
                {/*<video autoPlay loop id={"video-" + data.id} width="480" height="270" style={{display:"none"}} src={data.link} type="video/mp4"/>*/}
            </div>
            <div className="upload_popup_tabs_content_item_bottom">
                <div className="popup_full_img" onClick={openContentModal}>
                    <img src={IMAGES.photo_full} alt="" />
                </div>
                <div className="popup_time">{cutLengthString(data?.filename, LENGTH.ATTACH_VIDEO_TITLE)}</div>
                <div className="popup_trash" onClick={onDeleteItem}>
                    <img src={IMAGES.trash} alt="" />
                </div>
            </div>
        </div>
    );
};
