import * as blockListAction from '../../store/actions/blockList';
import { useDispatch } from 'react-redux';

export default function useGetBlockList() {
    const dispatch = useDispatch();

    const groupBlockList = (data) => {
        const groups = [];
        for (let element of data) {
            let existingGroups = groups.filter((group) => group.id === element.woman_external_id);
            if (existingGroups.length > 0) existingGroups[0].male_list.push(element);
            else {
                let newGroup = {
                    id: element.woman_external_id,
                    male_list: [element],
                };
                groups.push(newGroup);
            }
        }
        return groups;
    };

    const handleSearch = (value) => {
        dispatch(blockListAction?.setBlockListBySearch(value));
    };

    return {
        groupBlockList,
        handleSearch,
    };
}
