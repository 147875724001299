import React from 'react';
import { userAvatar } from '../../../../../services/methods';
import Styles from './LeftProfile.module.scss';

export default ({ info }) => {
    return (
        <div className="c3_head_left">
            <div style={{ marginRight: '10px' }}>
                {userAvatar(
                    info?.photo_link || info?.photo_link?.length ? info.photo_link : null,
                    info.name,
                    '',
                    Styles.user_avatar,
                )}
            </div>
            <div className={`c3_head_info`}>
                <div className="c3_head_id">{`ID ${info.external_id}`}</div>
                <div className="c3_head_name">{`${info.name}${info.age != null ? `, ${info.age}` : ``}`}</div>
            </div>
        </div>
    );
};
