import NOTIFICATION_IPHONE_SOUND from '../../audio/notification/iphone-sound.mp3';
import { Howl } from 'howler';
import { useState } from 'react';
import { sendErrToSentry } from '../../services/sentry';

export default function useMakeNotificationSound() {
    const iphoneSound = new Howl({ src: [NOTIFICATION_IPHONE_SOUND] });
    const isActiveSound = JSON.parse(localStorage.getItem('sound')) ?? null;
    const [isActiveSoundIcon, setIsActiveSoundIcon] = useState(+JSON.parse(localStorage.getItem('sound')) ?? null);

    const makeSound = () => {
        try {
            if (iphoneSound && +isActiveSound === 1) iphoneSound.play();
        } catch (error) {
            sendErrToSentry(error);
        }
    };

    const setSoundNotification = () => {
        setIsActiveSoundIcon(!isActiveSoundIcon);
        if (isActiveSound) localStorage.setItem('sound', '0');
        else localStorage.setItem('sound', '1');
    };

    return {
        isActiveSound,
        isActiveSoundIcon,
        setSoundNotification,
        makeSound,
    };
}
