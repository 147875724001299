import * as MAILBOX from '../constants/mailbox';
import MailboxApi from '../../services/api/MailboxApi';

export function setFavoriteStatus(favoriteStatus) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.CURRENT_CHAT_MAILBOX_SET_FAVORITE,
            favoriteStatus,
        });
    };
}

export function updateLetterLimit(letterLimit) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.UPDATE_CURRENT_CHAT_MAILBOX_LETTER_LIMIT,
            letterLimit,
        });
    };
}

export function addDraftId(draftId) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.MAILBOX_ADD_DRAFT_ID,
            data: draftId,
        });
    };
}

export function setWink() {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.SET_WINK,
        });
    };
}

export function addMailboxSpecialLetter(payload) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.MAILBOX_CHAT_ADD_SPECIAL_LETTER,
            data: payload,
        });
    };
}

export function setInfoCurrentTarget(data) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.SET_INFO_CURRENT_TARGET,
            data: data,
        });
    };
}

export function setLike() {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.MAILBOX_SET_LIKE,
        });
    };
}

export function setFavorite() {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.MAILBOX_SET_FAVORITE,
        });
    };
}

export function addMailboxChatInfo(payload) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.MAILBOX_CHAT_ADD_INFO,
            data: payload,
        });
    };
}

export function getMailboxChatHistory(payload) {
    return function (dispatch) {
        new MailboxApi().postMails(payload).then((res) => {
            return dispatch({
                type: MAILBOX.MAILBOX_CHAT_GET_HISTORY,
                data: res,
            });
        });
    };
}

export function getMailboxChatHistoryWithPageUp(payload) {
    return function (dispatch) {
        new MailboxApi().postMails(payload).then((res) => {
            return dispatch({
                type: MAILBOX.MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_UP,
                data: res,
            });
        });
    };
}

export function getMailboxChatHistoryWithPageDown(payload) {
    return function (dispatch) {
        new MailboxApi().postMails(payload).then((res) => {
            return dispatch({
                type: MAILBOX.MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_DOWN,
                data: res,
            });
        });
    };
}

export function getMailboxChatHistoryForce(payload) {
    return function (dispatch) {
        new MailboxApi().postMails(payload).then((res) => {
            return dispatch({
                type: MAILBOX.MAILBOX_CHAT_GET_HISTORY_FORCE,
                data: res,
            });
        });
    };
}

export function getMailboxChatHistorySendMess(payload) {
    return function (dispatch) {
        new MailboxApi().postMails(payload).then((res) => {
            if (res?.canceled || !res?.mails?.length) return;
            return dispatch({
                type: MAILBOX.MAILBOX_CHAT_GET_HISTORY_SEND_MESS,
                data: res,
            });
        });
    };
}

export function getChatId(payload) {
    return function (dispatch) {
        new MailboxApi().postChat(payload).then((res) => {
            return dispatch({
                type: MAILBOX.GET_CHAT_ID,
                data: res,
            });
        });
    };
}

export function removeCurrentChatMailbox() {
    return function (dispatch) {
        // dispatch({
        // 	type: MAILBOX.FETCH_MAILBOX_GET
        // });
        return dispatch({
            type: MAILBOX.REMOVE_CURRENT_MAILBOX,
        });
    };
}

export function setIsNewMessage(isBool) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.SET_IS_NEW_MESS,
            data: isBool,
        });
    };
}

export function setReadStatus(mailIDList) {
    if (!mailIDList?.length) return;

    return function (dispatch) {
        return dispatch({
            type: MAILBOX.MAILBOX_SET_READ_LETTER,
            mailIDList,
        });
    };
}

export function reduceLetterLeft() {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.REDUCE_LETTER_LEFT,
        });
    };
}

export function openLetterFilters() {
    return function (dispatch) {
        return dispatch({ type: MAILBOX.OPEN_LETTER_FILTERS });
    };
}

export function closeLetterFilters() {
    return function (dispatch) {
        return dispatch({ type: MAILBOX.CLOSE_LETTER_FILTERS });
    };
}

export function unblockUser() {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.MAILBOX_UNBLOCK_MAN,
        });
    };
}
