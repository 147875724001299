import { useDispatch, useSelector } from 'react-redux';
import * as senderAction from '../../store/actions/sender';

export default function useHandleSenderHeader() {
    const dispatch = useDispatch();
    const senderState = useSelector((state) => state.sender);

    return {
        activeSenderType: senderState?.activeType,
        handleSenderHeader: (senderType) => {
            if (senderType === senderState?.activeType) return;
            dispatch(senderAction.setActiveSenderType(senderType));
        },
    };
}
