import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import '../../../styles/audio-player/attach-style.scss';
import * as IMAGES from '../Images';

export default ({ src }) => {
    const onPlayHandler = (e) => {
        const activeAudioBlock = document.querySelector('.upload_popup_tabs_content_audio_item.playing');
        if (activeAudioBlock) {
            const audio = activeAudioBlock.querySelector('audio');
            activeAudioBlock.classList.remove('playing');
            audio.pause();
        }
        e.currentTarget.parentNode.parentNode.parentNode.parentNode.classList.add('playing');
    };

    const onPauseHandler = () => {
        const activeAudioBlock = document.querySelector('.upload_popup_tabs_content_audio_item.playing');
        if (activeAudioBlock) {
            const audio = activeAudioBlock.querySelector('audio');
            activeAudioBlock.classList.remove('playing');
            audio.pause();
        }
    };

    return (
        <AudioPlayer
            src={src}
            onPlay={onPlayHandler}
            onPause={onPauseHandler}
            customIcons={{
                play: <img src={IMAGES.popup_play} alt="" />,
                pause: <img src={IMAGES.stop_in_attach_file} alt="" />,
                volume: <img src={IMAGES.audio_volume} alt="" />,
                volumeMute: <img src={IMAGES.chat_audio_volume_mute} alt="" />,
            }}
        />
    );
};
