import io from 'socket.io-client';
import { SOCKET_URI, SOCKET_PATH } from '../../../constants/sync';

export default () => {
    let userChannels = ['user_online'];
    const socket = io(SOCKET_URI, {
        path: SOCKET_PATH,
        forceNew: true,
        withCredentials: false,
        // transports: ['websocket'],
        // query: {
        //     token: localStorage.getItem('token'),
        // },
        transportOptions: {
            polling: {
                extraHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    'X-USER-TOKEN': localStorage.getItem('token'),
                },
            },
        },
    });

    return {
        publish: (channel, message) => socket.emit('socket', message),
        subscribe: (channels) =>
            channels.forEach((channel) =>
                !userChannels.find((item) => item === channel) ? userChannels.push(channel) : null,
            ),
        addListener: (type, callback) =>
            userChannels.forEach((channel) =>
                socket.on(channel, (data) => {
                    return callback(data);
                }),
            ),
        unsubscribeAll: () => () => {},
    };
};
