import { makeCDN } from '../../../../../services/methods';
import React, { useEffect } from 'react';
import { addBlur, removeBlur } from '../../../../../services/dom/letters';
import * as IMAGES from '../../../Images';

export default ({ item, closeModal, onViewPhoto, image = false }) => {
    useEffect(() => {
        addBlur();
        return () => removeBlur();
    }, []);

    return (
        <>
            <div
                className={`popup_attach_photo_click_img_wrap ${+item.attachment_payed === 1 ? '' : 'unpaid'}`}
                style={+item.attachment_payed === 0 ? {} : { cursor: 'pointer' }}
                onClick={() => (item?.attachment_payed ? (+item.attachment_payed === 1 ? null : onViewPhoto()) : null)}
            >
                <img
                    src={image ? image : makeCDN(item?.link, 'IMAGE')}
                    alt=""
                    className={`popup_attach_photo_click_img ${+item.attachment_payed === 1 ? '' : ''}`}
                />
                {item?.attachment_payed && +item?.attachment_payed !== 1 ? (
                    <div className="click_to_view" onClick={onViewPhoto}>
                        Click to view the photo
                    </div>
                ) : (
                    <div />
                )}
            </div>
            <div className="pap-btn-close" onClick={closeModal}>
                <img src={IMAGES.cross_white} alt="" />
            </div>
        </>
    );
};
