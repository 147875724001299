import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as filesAction from '../../../../../store/actions/files';
import FilesApi from '../../../../../services/api/FilesApi';
import AttachAudioPlayer from '../../../../common/AttachAudioPlayer';
import { cutLengthString } from '../../../../../services/methods';
import * as LENGTH from '../../../../../constants/length';
import * as ATTACH from '../../../../../constants/attach';
import * as IMAGES from '../../../Images';
import * as loaderAction from '../../../../../store/actions/loader';
import * as alertAction from '../../../../../store/actions/alerts';
import * as ALERTS from '../../../../../store/constants/alerts';
import { sendErrToSentry } from '../../../../../services/sentry';

export default ({ checked, elementRef, data, opacity }) => {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState(false);

    const handleInputChangeAudio = (e) => {
        const checkBox = e.currentTarget.querySelector('input');
        if (checkBox) {
            setSelectedItem(!checkBox.checked);
            checkBox.checked = !checkBox.checked;
        }
    };

    const onDeleteItem = () => {
        dispatch(
            alertAction.setCustomConfirmAlert({
                type: ALERTS.SET_CUSTOM_CONFIRM_ALERT,
                title: `Are you sure you want to delete this file?`,
                btnName1: 'No',
                btnName2: 'Yes',
                successCallback: () => deleteItem(),
            }),
        );
    };

    const deleteItem = () => {
        dispatch(loaderAction.setActiveGifLoader(true));
        try {
            new FilesApi()
                .postDeleteMedia(data?.id, data?.external_id)
                .then((res) => {
                    if (res?.status) {
                        dispatch(filesAction.getAllAudios(data?.external_id));
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        } catch (error) {
            sendErrToSentry(error);
            dispatch(loaderAction.setActiveGifLoader(false));
        }
    };

    const handleSort = (e) => {
        const listItems = e.currentTarget.parentNode.querySelectorAll('.upload_popup_tabs_content_audio_item');
        const sortedArr = [];

        // for (let [key, item] of listItems.entries()) {
        // 	if (item.dataset.id) {
        // 		sortedArr.push({
        // 			id: item.dataset.id,
        // 			sort_order: key,
        // 		});
        // 	}
        // }

        for (let [, item] of listItems.entries()) {
            if (item.dataset.id) {
                sortedArr.push({
                    id: +item.dataset.id,
                });
            }
        }

        new FilesApi().postUpdateSortOrder(ATTACH.UPD_SORT_AUDIO, sortedArr, data?.external_id);
    };

    return (
        <div
            className={`upload_popup_tabs_content_audio_item ${selectedItem ? 'selected' : ''}`}
            ref={elementRef}
            style={{ opacity }}
            data-id={data.id}
            onDrop={handleSort}
        >
            <div className="popup_audio_item_top">
                <div className="popup_audio_item_play_btn">
                    <AttachAudioPlayer src={data.link} />
                </div>
                <div
                    className="no-drag popup_audio_item_check"
                    onClick={handleInputChangeAudio}
                    data-testid={data?.filename}
                >
                    <input
                        type="checkbox"
                        className="no-drag"
                        name={data.id}
                        checked={checked}
                        id={'ap' + data.id}
                        data-link={data.link}
                        data-filename={data.filename}
                        data-contentid={data.id}
                        onChange={handleInputChangeAudio}
                    />
                    <label htmlFor={'ap' + data.id} className="no-drag" />
                </div>
            </div>
            <div className="popup_audio_item_bottom">
                <div className="popup_audio_item_name">{cutLengthString(data.filename, LENGTH.MAX_AUDIO_TITLE)}</div>
                <div className="popup_audio_item_delete" onClick={onDeleteItem}>
                    <img src={IMAGES.popup_audio_del} alt="" />
                </div>
            </div>
        </div>
    );
};
