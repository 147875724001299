export function insertedMessageBeforeSync({ id, messageContent, externalId, type = 'SENT_TEXT', dateCreated = null }) {
    const insertedObj =
        type === 'SENT_IMAGE'
            ? {
                  message_popup: `${messageContent.split(`${externalId}/`)[0]}${externalId}/w-1920-h-1280-${messageContent.split(`${externalId}/`)[1]}`,
                  message_thumb: `${messageContent.split(`${externalId}/`)[0]}${externalId}/w-250-h-250-${messageContent.split(`${externalId}/`)[1]}`,
              }
            : {};

    return {
        ...{
            id: id,
            sender_external_id: externalId,
            date_created: dateCreated,
            message_content: messageContent,
            message_type: type,
        },
        ...insertedObj,
    };
}

export function insertedMessageAfterSync({
    id,
    messageContent,
    externalId,
    type = 'SENT_TEXT',
    dateCreated = new Date(),
}) {
    const insertedObj =
        type === 'SENT_IMAGE'
            ? {
                  message_popup: `${messageContent.split(`${externalId}/`)[0]}${externalId}/w-1920-h-1280-${messageContent.split(`${externalId}/`)[1]}`,
                  message_thumb: `${messageContent.split(`${externalId}/`)[0]}${externalId}/w-250-h-250-${messageContent.split(`${externalId}/`)[1]}`,
              }
            : {};

    return {
        ...{
            id: id,
            sender_external_id: externalId,
            date_created: dateCreated,
            message_content: messageContent,
            message_type: type,
        },
        ...insertedObj,
    };
}
