import React, { useEffect, useState } from 'react';
import AttachDraft from './AttachDraft';
import Styles from '../FormLetters/styles.module.scss';
import * as MENU from '../../../../constants/menu';
import { useDispatch, useSelector } from 'react-redux';
import Emoji from './Emoji';
import * as IMAGES from '../../../common/Images';
import AttachDataToLetter from '../../../common/AttachDataToLetter';
import useHandleLettersForm from '../../../../hooks/letters/useHandleLettersForm';
import useHandleSmileBlock from '../../../../hooks/emoji/smile/useHandleSmileBlock';
import useChatRoomHeaderFunctions from '../../../../hooks/chat/useChatRoomHeaderFunctions';
import Header from '../../../chat/ChatRoom/Header';
import * as mailboxAction from '../../../../store/actions/mailbox';
import Slider from 'react-slick';
import { settings } from '../../../../services/slick-carousel-config/settings';
import useGetConfig from '../../../../hooks/config/useGetConfig';
import useInitTranslate from '../../../../hooks/translate/useInitTranslate';
import Select from 'react-select';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default ({ closeWriteLetterModal }) => {
    const dispatch = useDispatch();
    const menuState = useSelector((state) => state.menu);
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const mailboxState = useSelector((state) => state.mailbox);
    const profilesState = useSelector((state) => state.profiles);

    const item = mailboxState?.mailList.filter((i) => i?.draft?.id === currentChatMailboxState?.draftId)[0]?.draft;
    const isUserOnline =
        +mailboxState?.checkedRecipients.find((el) => +el?.external_id === +item?.recipient_id)?.online ?? 0;

    const { configData } = useGetConfig(item?.recipient_id, item?.sender_id);

    const handleLettersForm = useHandleLettersForm(item?.sender_id, item?.recipient_id, configData);

    const {
        isOpenTranslateBlock,
        handleLetterContent,
        onSubmitMessage,
        textSourceTranslate,
        textTargetTranslate,
        messageContent,
        setTextSourceTranslate,
        setTextTargetTranslate,
        handleTextSourceTranslate,
        handleTextTargetTranslate,
        handleTranslateSwitcher,
        attachData,
        addEmoji,
        isDisabledButton,
        setIsDisabledButton,
        checkOnMaxLength,
        onEnterMessageContent,
    } = handleLettersForm;

    const {
        postTranslateText,
        translateOptions,
        activeSourceTranslate,
        selectSourceTranslate,
        activeTargetTranslate,
        selectTargetTranslate,
        switchTargetTranslate,
    } = useInitTranslate(
        isOpenTranslateBlock,
        isDisabledButton,
        setIsDisabledButton,
        textSourceTranslate,
        textTargetTranslate,
        setTextSourceTranslate,
        setTextTargetTranslate,
    );

    const sendMsg = () => {
        onSubmitMessage('SEND_DRAFT_LETTER', item);
        closeWriteLetterModal();
    };

    const [activeTypeFile, setActiveTypeFile] = useState('');
    const chatRoomHeaderFunctions = useChatRoomHeaderFunctions();

    const handleSmileBlock = useHandleSmileBlock();

    useEffect(() => {
        handleLetterContent(item.message_content);
        return () => {
            if (menuState.letters === MENU.LETTER_FOLDER_DRAFT) {
                dispatch(
                    mailboxAction.getMailDraftsWithoutReload({
                        userIds: profilesState?.active?.external_id
                            ? [profilesState?.active?.external_id]
                            : profilesState.list.map((item) => item?.external_id),
                        page: mailboxState.mailCurrentPage,
                    }),
                );
            }
        };
    }, [item]);

    return (
        <div className={`${Styles.popup_draft_form} popup_draft_form`}>
            <div className="chat_header_drafts">
                <Header
                    {...chatRoomHeaderFunctions}
                    currentChatInfo={{
                        ...item,
                        ...{
                            female_external_id: item?.sender_id,
                            photo_link: item?.recipient_image,
                            name: item?.recipient_name,
                            age: item?.recipient_age,
                            country_image: item?.recipient_country_icon,
                            city: item?.recipient_city,
                            favorite: item?.recipient_image,
                            male_external_id: item?.recipient_id,
                            isUserOnline,
                        },
                    }}
                    headerType={MENU.LETTER_FOLDER_DRAFT}
                    womanProfile={profilesState?.list.find((profile) => +profile?.external_id === +item?.sender_id)}
                />
            </div>
            <div className={Styles.clmn_3_chat_bottom}>
                {!isOpenTranslateBlock ? (
                    <textarea
                        className={Styles.clmn_3_chat_textarea}
                        value={messageContent}
                        autoFocus={true}
                        onKeyDown={onEnterMessageContent}
                        onChange={(e) => handleLetterContent(e.target.value)}
                    />
                ) : (
                    <div className={Styles.clmn_3_chat_textarea_double}>
                        <div className={Styles.clmn_3_chat_textarea_wrap}>
                            <div className={Styles.clmn_3_chat_textarea_translate_info}>
                                <Select
                                    className={Styles.clmn_3_chat_textarea_translate_select}
                                    defaultValue={activeSourceTranslate}
                                    value={activeSourceTranslate}
                                    onChange={selectSourceTranslate}
                                    options={translateOptions}
                                />
                                <div className={`${Styles.clmn_3_chat_textarea_translate_symbols}`}>
                                    {textSourceTranslate?.length} symbols
                                </div>
                            </div>
                            <textarea
                                className={Styles.clmn_3_chat_textarea_inner}
                                value={textSourceTranslate}
                                autoFocus={true}
                                onKeyDown={(e) => onEnterMessageContent(e, postTranslateText)}
                                onChange={(e) => handleTextSourceTranslate(e.target.value)}
                            />
                        </div>

                        <div className={Styles.clmn_3_chat_textarea_translate_arrow_wrap}>
                            <div
                                className={`${Styles.clmn_3_chat_textarea_translate_arrow} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                                onClick={switchTargetTranslate}
                            >
                                <CompareArrowsIcon />
                            </div>

                            <div
                                className={`${Styles.clmn_3_chat_textarea_translate_arrow} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                                onClick={postTranslateText}
                            >
                                <ArrowForwardIcon />
                            </div>
                        </div>

                        <div className={Styles.clmn_3_chat_textarea_wrap}>
                            <div className={Styles.clmn_3_chat_textarea_translate_info}>
                                <Select
                                    className={Styles.clmn_3_chat_textarea_translate_select}
                                    defaultValue={activeTargetTranslate}
                                    value={activeTargetTranslate}
                                    onChange={selectTargetTranslate}
                                    options={translateOptions}
                                />

                                <div
                                    className={`${Styles.clmn_3_chat_textarea_translate_symbols} ${!checkOnMaxLength(textTargetTranslate) ? Styles.error : ''}`}
                                >
                                    {textTargetTranslate?.length} symbols
                                </div>
                            </div>
                            <textarea
                                className={Styles.clmn_3_chat_textarea_inner}
                                value={textTargetTranslate}
                                onKeyDown={onEnterMessageContent}
                                onChange={(e) => handleTextTargetTranslate(e.target.value)}
                            />
                        </div>
                    </div>
                )}

                {attachData?.length > 0 ? (
                    <div className="c3_write_msg_textareas_files">
                        <div className="letters_attach_files_list">
                            <div className="carousel_container">
                                <Slider {...settings}>
                                    {attachData.map((item, key) => (
                                        <AttachDataToLetter {...handleLettersForm} item={item} index={key} key={key} />
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div />
                )}

                <div className={Styles.clmn_3_chat_typing}>
                    {/*<div className={`${styles.chat_was_online} ${styles[lastSeen?.status]}`}>*/}
                    {/*	{lastSeen?.message}*/}
                    {/*</div>*/}
                    <div className={Styles.chat_typing_right}>
                        {currentChatMailboxState?.info?.letter_limit || '0'} messages left
                    </div>
                </div>
                <div className={Styles.clmn_3_chat_bottom_nav}>
                    <div className={Styles.clmn_3_chat_bottom_nav_left}>
                        <div className={Styles.clmn_3_chat_bottom_options}>
                            <AttachDraft
                                {...handleLettersForm}
                                activeTypeFile={activeTypeFile}
                                setActiveTypeFile={setActiveTypeFile}
                                womanId={item?.sender_id}
                            />
                            <Emoji {...handleSmileBlock} addEmoji={addEmoji} />
                        </div>
                    </div>

                    {!isOpenTranslateBlock && (
                        <div className={Styles.clmn_3_chat_bottom_symbols}>
                            {messageContent?.length} symbols entered
                        </div>
                    )}

                    <div className={Styles.clmn_3_chat_bottom_nav_right}>
                        <div className={Styles.clmn_3_chat_bottom_translate}>
                            <div className={Styles.clmn_3_chat_bottom_translate_tmblr}>
                                <input
                                    type="checkbox"
                                    id="translate_tmblr"
                                    value={+isOpenTranslateBlock}
                                    className={Styles.clmn_3_chat_bottom_translate_tmblr_inp}
                                />
                                <label
                                    htmlFor="translate_tmblr"
                                    onClick={handleTranslateSwitcher}
                                    className={Styles.clmn_3_chat_bottom_translate_tmblr_label}
                                />
                            </div>
                            <div className={Styles.clmn_3_chat_bottom_translate_text}>Translate</div>
                        </div>
                        <div className={Styles.clmn_3_chat_bottom_send_btn} onClick={sendMsg}>
                            <img src={IMAGES.default.send_message} alt="" />
                            <span>Send</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
