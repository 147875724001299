import axios from 'axios';
import * as ERROR from '../../constants/error';

export default class Base {
    getUserInfo() {
        return localStorage.getItem('token');
    }

    getAuthHeader() {
        return {
            crossDomain: true,
            headers: {
                Authorization: `Bearer ${this.getUserInfo()}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': '"Origin, X-Requested-With, Content-Type, Accept"',
            },
        };
    }

    get(url, config) {
        const isCheckProfiles = localStorage.getItem('check_profiles');
        const isAdmin = JSON.parse(localStorage.getItem('admin'));
        if (isCheckProfiles) return new Promise((resolve) => resolve({ data: null }));

        return axios.get(url, { ...this.getAuthHeader(), ...config }).catch((error) => {
            if (axios.isCancel(error)) return;

            if (error.response.status === ERROR.UNAUTHORIZED_STATUS && !isAdmin) {
                localStorage.removeItem('operator');
                localStorage.removeItem('token');
                return (window.location.href = '/');
            }
        });
    }

    post(url, data, config = {}) {
        const isCheckProfiles = localStorage.getItem('check_profiles');
        const isAdmin = JSON.parse(localStorage.getItem('admin'));
        if (isCheckProfiles) return new Promise((resolve) => resolve({ data: null }));

        return axios.post(url, data, { ...this.getAuthHeader(), ...config }).catch((error) => {
            if (axios.isCancel(error)) return;

            if (error.response.status === ERROR.UNAUTHORIZED_STATUS && !isAdmin) {
                localStorage.removeItem('operator');
                localStorage.removeItem('token');
                return (window.location.href = '/');
            }
        });
    }
}
