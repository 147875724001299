import { useEffect, useState } from 'react';
import NoticeApi from '../../services/api/NoticeApi';
import * as noticeAction from '../../store/actions/notice';
import { useDispatch, useSelector } from 'react-redux';
import * as alertsAction from '../../store/actions/alerts';
import * as ERROR from '../../constants/error';
import * as LENGTH from '../../constants/length';
import { sendErrToSentry } from '../../services/sentry';

export default function useHandleNoticeEdit({ openNoticeList, closeNoticeList }) {
    const dispatch = useDispatch();
    const noticeState = useSelector((state) => state.notice);
    const currentChatState = useSelector((state) => state.currentChat);
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const [isActive, setIsActive] = useState(false);
    const [noticeData, setNoticeData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const openNoticeEdit = () => setIsActive(true);
    const closeNoticeEdit = () => setIsActive(false);

    const handleNotesData = (e) => {
        const { name, value } = e.currentTarget;

        if (LENGTH?.NOTES_MAX_LENGTH >= value?.length) {
            setNoticeData({ ...noticeData, ...{ [name]: value } });
        }
    };

    const deleteNotice = (noticeID) => {
        let noticeInfoData = null;

        if (currentChatState?.info?.chat_uid) noticeInfoData = currentChatState?.info;
        else if (currentChatMailboxState?.info?.chat_uid) noticeInfoData = currentChatMailboxState?.info;

        new NoticeApi()
            .postDelete({
                noticeId: noticeID,
                maleId: noticeInfoData?.male_external_id,
                femaleId: noticeInfoData?.female_external_id,
                chatId: noticeInfoData?.chat_uid,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(noticeAction.deleteNotice(noticeID));
                } else {
                    if (res?.error) dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                    else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                }
            });
    };

    const onSave = () => {
        if (isEdit) editNotice();
        else createNotice();
    };

    const editNotice = () => {
        let noticeInfoData = null;

        if (!noticeData?.notesName?.trim()?.length || !noticeData?.notesText?.trim()?.length) return;

        if (currentChatState?.info?.chat_uid) noticeInfoData = currentChatState?.info;
        else if (currentChatMailboxState?.info?.chat_uid) noticeInfoData = currentChatMailboxState?.info;

        try {
            new NoticeApi()
                .postEdit({
                    noticeId: isEdit,
                    maleId: noticeInfoData?.male_external_id,
                    femaleId: noticeInfoData?.female_external_id,
                    chatId: noticeInfoData?.chat_uid,
                    nameNotice: noticeData?.notesName,
                    textNotice: noticeData?.notesText,
                })
                .then((res) => {
                    if (res?.status) {
                        dispatch(
                            noticeAction.editNotice({
                                id: isEdit,
                                name: noticeData?.notesName,
                                description: noticeData?.notesText,
                            }),
                        );
                        setNoticeData(null);
                        closeNoticeEdit();
                        openNoticeList();
                    } else {
                        if (res?.error) dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                        else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                    }
                })
                .finally(() => setIsEdit(false));
        } catch (error) {
            sendErrToSentry(error);
        }
    };

    const createNotice = () => {
        let noticeInfoData = null;

        if (!noticeData?.notesName?.trim()?.length || !noticeData?.notesText?.trim()?.length) return;

        if (currentChatState?.info?.chat_uid) noticeInfoData = currentChatState?.info;
        else if (currentChatMailboxState?.info?.chat_uid) noticeInfoData = currentChatMailboxState?.info;

        new NoticeApi()
            .postCreate({
                maleId: noticeInfoData?.male_external_id,
                femaleId: noticeInfoData?.female_external_id,
                chatId: noticeInfoData?.chat_uid,
                nameNotice: noticeData?.notesName,
                textNotice: noticeData?.notesText,
            })
            .then((res) => {
                if (res?.status) {
                    dispatch(
                        noticeAction.addNotice({
                            id: res?.notice_id,
                            name: noticeData?.notesName,
                            description: noticeData?.notesText,
                        }),
                    );
                    setNoticeData(null);
                    closeNoticeEdit();
                    openNoticeList();
                } else {
                    if (res?.error) dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                    else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                }
            });
    };

    useEffect(() => {
        if (isEdit) {
            const editNotice = noticeState.list.find((item) => +item?.id === +isEdit);

            if (editNotice?.id) {
                setNoticeData({
                    notesName: editNotice?.name,
                    notesText: editNotice?.description,
                });
                openNoticeEdit();
                closeNoticeList();
            }
        }
    }, [isEdit]);

    return {
        noticeData,
        handleNotesData,
        isActive,
        openNoticeEdit,
        closeNoticeEdit,
        createNotice,
        editNotice,
        deleteNotice,
        setIsEdit,
        onSave,
    };
}
