import * as CHATS from '../constants/chats';
import ChatsAPI from '../../services/api-multi/ChatMultiApi';

// V3 ----------------------------------------------------
export function addCurrentChatHistory(chat_id, token) {
    return function (dispatch) {
        new ChatsAPI().getOptions({ chat_id }).then((res) => {
            if (res?.status) {
                return dispatch({
                    type: CHATS.ADD_CURRENT_CHAT_OPTIONS,
                    options: res?.response,
                });
            }
        });

        new ChatsAPI().getHistory({ chat_id }, token).then((res) => {
            if (res?.status) {
                return dispatch({
                    type: CHATS.ADD_CURRENT_CHAT_HISTORY,
                    history: res?.response,
                });
            }
        });
    };
}

export function setHidePageDown(hidePageDown, isDown = false) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.MAIN_CHAT_SET_HIDE_PAGE_DOWN,
            hidePageDown,
            isDown,
        });
    };
}

export function addCurrentChatHistoryWithPage(payload, isStart = false) {
    return function (dispatch) {
        dispatch({
            type: CHATS.MAIN_CHAT_ADD_HISTORY_PAGE_LOADING,
        });
        new ChatsAPI().getHistory(payload).then((res) => {
            if (res?.status) {
                return dispatch({
                    type: CHATS.ADD_CURRENT_CHAT_HISTORY_PAGE,
                    history: res?.response,
                    isStart,
                });
            }
        });
    };
}

export function reduceMessagesLeft() {
    return function (dispatch) {
        return dispatch({
            type: CHATS.REDUCE_MESSAGES_LEFT,
        });
    };
}

export function setSpecialStatus(specialStatus) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.SET_SPECIAL_STATUS,
            specialStatus,
        });
    };
}

export function setMaybeStatus(maybeStatus) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.SET_MAYBE_STATUS,
            maybeStatus,
        });
    };
}

export function setFavoriteStatus(favoriteStatus) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.SET_FAVORITE_STATUS,
            favoriteStatus,
        });
    };
}

export function removeCurrentChat() {
    return function (dispatch) {
        return dispatch({
            type: CHATS.REMOVE_CURRENT_CHAT,
        });
    };
}

export function addCurrentChat(currentChat) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.ADD_CURRENT_CHAT,
            currentChat,
        });
    };
}

export function addMessageToHistory(newMess, replyData = null) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CURRENT_CHAT_HISTORY_ADD_MESSAGE,
            newMess,
            replyData,
        });
    };
}

export function removeMessageInHistory(id) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CURRENT_CHAT_HISTORY_REMOVE_MESSAGE,
            id,
        });
    };
}

export function setLike() {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CURRENT_CHAT_SET_LIKE,
        });
    };
}

export function updateMessageInHistory(hashId, newMess) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CURRENT_CHAT_HISTORY_UPDATE_MESSAGE,
            newMess,
            hashId,
        });
    };
}

export function setReadLastMessages() {
    return function (dispatch) {
        return dispatch({
            type: CHATS.SET_READ_LAST_MESS,
        });
    };
}

export function updateMessageLimit(messageLimit, likeLimit) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.UPDATE_CURRENT_CHAT_MESSAGE_LIMIT,
            messageLimit,
            likeLimit,
        });
    };
}

export function setAllMediaListLetter(payload) {
    return function (dispatch) {
        new ChatsAPI().getHistoryMediaLetter(payload).then((res) => {
            return dispatch({
                type: CHATS.SET_ALL_MEDIA_LIST,
                data: res?.response,
            });
        });
    };
}

export function setAllMediaList(payload) {
    return function (dispatch) {
        new ChatsAPI().getHistoryMedia(payload).then((res) => {
            return dispatch({
                type: CHATS.SET_ALL_MEDIA_LIST,
                data: res?.response,
            });
        });
    };
}

export function updateAllMediaList(data) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.UPDATE_ALL_MEDIA_LIST,
            data,
        });
    };
}

export function setChatMediaListByPage(mediaPage) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.SET_MEDIA_LIST_BY_PAGE,
            mediaPage,
        });
    };
}

export function resetChatMediaListByPage() {
    return function (dispatch) {
        return dispatch({
            type: CHATS.RESET_MEDIA_LIST_BY_PAGE,
        });
    };
}

export function hideMessage(messageID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.MAIN_CHAT_HIDE_MESSAGE,
            messageID,
        });
    };
}

// To show operator current state of user block status
export function block() {
    return function (dispatch) {
        return dispatch({
            type: CHATS.BLOCK_USER,
        });
    };
}

export function unBlock() {
    return function (dispatch) {
        return dispatch({
            type: CHATS.UNBLOCK_USER,
        });
    };
}
