import { useDispatch, useSelector } from 'react-redux';
import * as chatsAction from '../../store/actions/chats';
import useCloseCurrentChat from './useCloseCurrentChat';

export default function useHandleChatFilters() {
    const dispatch = useDispatch();
    const closeCurrentChat = useCloseCurrentChat();
    const chatsState = useSelector((state) => state.chats);

    // Method for removing characters from the search input.
    const deleteSearchInput = () => {
        // Set initial state of the search string.
        dispatch(chatsAction.setChatListFilterSearch(''));
    };

    // Method for character-by-character search in the search input.
    const handleSearch = (value) => {
        // Close current chat.
        closeCurrentChat();
        // Set store search input.
        if (value.length < 3 && value.length > 10) return;
        dispatch(chatsAction.setChatListFilterSearch(value));
    };

    // Online status switch.
    const handleOnline = () => {
        closeCurrentChat();
        // Set store online (false/true).
        dispatch(chatsAction.setChatListFilterOnline(!chatsState?.filterOnline));
    };

    const handleFreeze = () => {
        closeCurrentChat();
        dispatch(chatsAction.setChatListFilterFreeze(!chatsState?.filterFreeze));
    };

    const pinFilter = (list) => {
        return [
            ...list.filter((item) => chatsState?.pinList.includes(item?.chat_uid)),
            ...list.filter((item) => !chatsState?.pinList.includes(item?.chat_uid)),
        ];
    };

    return {
        deleteSearchInput,
        handleSearch,
        handleOnline,
        handleFreeze,
        pinFilter,
        onlineStatus: chatsState?.filterOnline,
        searchInput: chatsState?.filterSearch,
    };
}
