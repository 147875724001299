import { makeCDN } from '../../../../../services/methods';
import React, { useEffect } from 'react';
import VideoPlayer from '../../../../common/VideoPlayer';
import { addBlur, removeBlur } from '../../../../../services/dom/letters';
import { stopAudio } from '../../../../../services/methods/chat';
import styles from './styles.module.scss';
import * as IMAGES from '../../../Images';
import { sendErrToSentry } from '../../../../../services/sentry';

export default ({ item, closeModal, onViewVideo }) => {
    useEffect(() => {
        stopAudio();
        addBlur();
        return () => removeBlur();
    }, []);

    try {
        return (
            <>
                <div className="popup_attach_photo_click_img_wrap">
                    <div
                        className={`popup_attach_photo_click_img ${item?.attachment_payed === '1' ? '' : 'blur_unpaid'}`}
                    >
                        <VideoPlayer data={makeCDN(item.link, 'VIDEO')} />
                    </div>
                    {item?.attachment_payed === '1' ? (
                        <div />
                    ) : (
                        <div className="click_to_view" onClick={onViewVideo}>
                            Click to view the photo
                        </div>
                    )}
                </div>
                <div className="pap-btn-close" onClick={closeModal}>
                    <img src={IMAGES.cross_white} alt="" />
                </div>
            </>
        );
    } catch (error) {
        sendErrToSentry(error);

        return (
            <>
                <div className={styles.video_error_wrap}>
                    <img src={IMAGES.video_error} className={styles.video_error_img} alt="" />
                    <div className={styles.video_error_text}>Failed to load video file</div>
                </div>
                <div className="pap-btn-close" onClick={closeModal}>
                    <img src={IMAGES.cross_white} alt="" />
                </div>
            </>
        );
    }
};
