import React from 'react';

import Styles from './Item.module.scss';

import { userAvatar } from '../../../../../services/methods';

export default ({ item, onActiveChat, onRemoveActiveChat, currentChat }) => {
    return (
        <div
            className={`c2_item online ${currentChat?.external_id === item.external_id ? 'current' : ''}`}
            onClick={() => (currentChat?.external_id === item.external_id ? onRemoveActiveChat() : onActiveChat(item))}
        >
            <div className="c2_item_top">
                <div className="c2_item_photo_wrap">
                    <div className="c2_item_photo_delete">
                        <img src="/img/c2-remove.svg" alt="" />
                    </div>
                    {userAvatar(
                        item?.photo_link || item?.photo_link?.length ? item?.photo_link : null,
                        item?.name,
                        'c2_item_photo',
                        Styles.user_avatar,
                    )}
                </div>
                <div className="c2_item_right">
                    <div className="c2_item_name">{`${item?.name}${item?.age != null ? `, ${item?.age}` : ``}`}</div>
                </div>
                <span className="c2_item_id" style={{ display: 'block' }}>{`ID ${item?.external_id}`}</span>
            </div>
        </div>
    );
};
