import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as MENU from '../../constants/menu';
import { initCancelTokenSource } from '../../services/methods';
import * as mailboxAction from '../../store/actions/mailbox';

export default function useEffectToLoadLetterList() {
    const dispatch = useDispatch();
    const profilesState = useSelector((state) => state.profiles);
    const location = useLocation();
    const menuState = useSelector((state) => state.menu);
    const letterFilterState = useSelector((state) => state.letterFilter);
    const lettersMenuType = useSelector((state) => state.menu.letters);
    const [canceledToken, setCanceledToken] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatchGetLetters = () => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);

        if (menuState?.letters !== MENU.LETTER_FOLDER_DRAFT) {
            dispatch(
                mailboxAction.getMails(
                    {
                        userId: profilesState.active.external_id ? profilesState.active.external_id : '',
                        folder: menuState.letters,
                        manFilter: letterFilterState.manFilter,
                        manSearchId: letterFilterState.manId,
                        letterFilter: letterFilterState.lettersFilter,
                        onlineFilter: lettersMenuType === 'trash' ? '' : letterFilterState.onlineFilter,
                        freeze: letterFilterState.freezeFilter,
                    },
                    source.token,
                ),
            );
        } else {
            dispatch(
                mailboxAction.getMailDrafts(
                    {
                        userIds: profilesState?.active?.external_id
                            ? [profilesState?.active?.external_id]
                            : profilesState.list.map((item) => item?.external_id),
                    },
                    source?.token,
                ),
            );
        }
    };

    const handleChangePage = (page) => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);
        setCurrentPage(page);
        if (menuState.letters !== 'drafts') {
            dispatch(
                mailboxAction.getMailsWithPage(
                    {
                        userId: profilesState.active.external_id ? profilesState.active.external_id : '',
                        folder: menuState.letters,
                        manFilter: letterFilterState.manFilter,
                        manSearchId: letterFilterState.manId,
                        letterFilter: letterFilterState.lettersFilter,
                        onlineFilter: lettersMenuType === 'trash' ? '' : letterFilterState.onlineFilter,
                        freeze: letterFilterState.freezeFilter,
                        page: page,
                    },
                    source.token,
                ),
            );
        } else {
            dispatch(
                mailboxAction.getDraftsWithPage(
                    {
                        userIds: profilesState.active.external_id
                            ? [profilesState.active.external_id]
                            : profilesState.list.map((item) => item.external_id),
                        page: page,
                    },
                    source.token,
                ),
            );
        }
    };

    useEffect(() => {
        if (profilesState?.list?.length && letterFilterState?.manFilter !== 'id') {
            dispatchGetLetters();
        } else {
            if (letterFilterState?.manId?.length > 0) {
                dispatchGetLetters();
            }
        }
    }, [profilesState.list, profilesState.active, menuState.letters, letterFilterState, location]);

    return {
        currentPage,
        setCurrentPage,
        handleChangePage,
    };
}
