import React from 'react';
import styles from '../../components/stat/styles.module.scss';
import useInitGiftStatisticTable from '../../hooks/statisticsTable/useInitGiftStatisticTable';

export default function GiftsPage() {
    const statisticTable = useInitGiftStatisticTable();

    return (
        <div className={styles.stats_clmn_3}>
            <div className={styles.gifts_search_head}>
                <div className={styles.gifts_search_input_wrap}>
                    <input
                        onChange={statisticTable?.handleInputChange}
                        type="text"
                        placeholder="Search by External ID"
                        className={styles.gifts_search_input}
                        value={statisticTable?.term}
                    />
                    {!!statisticTable?.term?.length && (
                        <div onClick={statisticTable?.clearTerm} className={styles.gifts_search_clear_btn} />
                    )}
                </div>
            </div>
            <div className={styles.stats_page_table_wrap}>
                <table className={`${styles.table} ${styles.sender_table} ${styles.gifts_table}`}>
                    <thead>
                        <tr className={styles.table_head_row}>{statisticTable.getTableHead()}</tr>
                    </thead>
                    <tbody>{statisticTable.GetColumns()}</tbody>
                </table>
            </div>
        </div>
    );
}
