import React, { useState } from 'react';
import Styles from '../styles.module.scss';
import Modal from 'react-modal';
import attachStyles from '../../../../../styles/react-modal/attach.style';
import AllMediaModal from '../../../../common/AllMediaModal';

export default function AllMedia() {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <>
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.media}`}
                onClick={openModal}
                data-testid="all-media-btn"
            />
            <Modal style={attachStyles} isOpen={modalIsOpen} onRequestClose={closeModal} ariaHideApp={false}>
                <AllMediaModal closeModal={closeModal} />
            </Modal>
        </>
    );
}
