import * as MODALS from '../constants/modals';

export function openAudioContentModal(item) {
    return function (dispatch) {
        return dispatch({
            type: MODALS.OPEN_AUDIO_CONTENT_MODAL,
            item,
        });
    };
}

export function closeAudioContentModal() {
    return function (dispatch) {
        return dispatch({
            type: MODALS.CLOSE_AUDIO_CONTENT_MODAL,
        });
    };
}

export function openVideoContentModal(item) {
    return function (dispatch) {
        return dispatch({
            type: MODALS.OPEN_VIDEO_CONTENT_MODAL,
            item,
        });
    };
}

export function closeVideoContentModal() {
    return function (dispatch) {
        return dispatch({
            type: MODALS.CLOSE_VIDEO_CONTENT_MODAL,
        });
    };
}

export function openPhotoContentModal(item) {
    return function (dispatch) {
        return dispatch({
            type: MODALS.OPEN_PHOTO_CONTENT_MODAL,
            item,
        });
    };
}

export function closePhotoContentModal() {
    return function (dispatch) {
        return dispatch({
            type: MODALS.CLOSE_PHOTO_CONTENT_MODAL,
        });
    };
}
