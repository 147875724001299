import { useState } from 'react';
import { useSelector } from 'react-redux';
import useTransition from '../transition/useTransition';
import useLogout from '../auth/useLogout';
import * as MENU from '../../constants/menu';

export default function useHandleBottomMenu() {
    const transition = useTransition();
    const logout = useLogout();

    const menuState = useSelector((state) => state.menu);
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const activeItem = (item) => {
        if (item === MENU.MENU_SIGN_OUT) logout();
        else transition.transitionPush(`/${item}`);
    };

    return {
        activeItem,
        activeItemName: menuState.main,
        isOpenMenu,
        setIsOpenMenu: () => setIsOpenMenu(!isOpenMenu),
        closeBottomMenu: () => setIsOpenMenu(false),
    };
}
