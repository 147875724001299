import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { initCancelTokenSource } from '../../services/methods';
import ChatsApi from '../../services/api/ChatsApi';
import * as currentChatAction from '../../store/actions/currentChat';
import * as chatsAction from '../../store/actions/chats';
import * as MENU from '../../constants/menu';
import useTransition from '../transition/useTransition';
import { useLocation } from 'react-router-dom';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as notifyAction from '../../store/actions/notify';
import * as SYNC from '../../constants/sync';
import { getLastOnlineMessage } from '../../services/time';

export default function useChatRoomHeaderFunctions() {
    const location = useLocation();
    const dispatch = useDispatch();
    const transition = useTransition();

    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const currentChatState = useSelector((state) => state.currentChat);
    const chatsState = useSelector((state) => state.chats);
    const profilesState = useSelector((state) => state.profiles);
    const previousPage = useSelector((state) => state.menu?.previousPage);

    const [canceledToken, setCanceledToken] = useState(null);
    const locationType = MENU.MENU_LIST.find((item) => location?.pathname.includes(item));
    const headerType = MENU.MENU_CHAT_LIST.includes(locationType) ? MENU.MENU_CHAT : locationType;

    const setMaybe = () => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);

        dispatch(currentChatAction.setMaybeStatus(!+switchCurrentInfo(headerType)?.maybe));
        dispatch(
            chatsAction.setMaybeStatus(!+switchCurrentInfo(headerType)?.maybe, switchCurrentInfo(headerType)?.chat_uid),
        );

        new ChatsApi()
            .postSetMaybe(
                switchCurrentInfo(headerType)?.female_external_id,
                switchCurrentInfo(headerType)?.chat_uid,
                !switchCurrentInfo(headerType)?.maybe,
                source?.token,
            )
            .then((res) => {
                if (!res?.status) {
                    dispatch(currentChatAction.setMaybeStatus(switchCurrentInfo(headerType)?.maybe));
                    dispatch(
                        chatsAction.setMaybeStatus(
                            switchCurrentInfo(headerType)?.maybe,
                            switchCurrentInfo(headerType)?.chat_uid,
                        ),
                    );
                }
            });
    };

    const setSpecial = () => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);

        dispatch(currentChatAction.setSpecialStatus(!+switchCurrentInfo(headerType)?.special));
        dispatch(
            chatsAction.setSpecialStatus(
                !+switchCurrentInfo(headerType)?.special,
                switchCurrentInfo(headerType)?.chat_uid,
            ),
        );

        new ChatsApi()
            .postSetSpecial(
                switchCurrentInfo(headerType)?.female_external_id,
                switchCurrentInfo(headerType)?.chat_uid,
                !switchCurrentInfo(headerType)?.special,
                source?.token,
            )
            .then((res) => {
                if (!res?.status) {
                    dispatch(currentChatAction.setSpecialStatus(switchCurrentInfo(headerType)?.special));
                    dispatch(
                        chatsAction.setSpecialStatus(
                            switchCurrentInfo(headerType)?.special,
                            switchCurrentInfo(headerType)?.chat_uid,
                        ),
                    );
                }
            });
    };

    const setFavorite = () => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);

        if (
            [
                MENU.MENU_CHAT,
                MENU.MENU_UNANSWERED,
                MENU.MENU_FAVORITE,
                MENU.MENU_DIALOGUES,
                MENU.MENU_MATCHES,
                MENU.MENU_MAYBE,
                MENU.MENU_SPECIAL,
            ].includes(headerType)
        ) {
            dispatch(currentChatAction.setFavoriteStatus(!+switchCurrentInfo(headerType)?.male_favorite));
            dispatch(
                chatsAction.setFavoriteStatus(
                    !+switchCurrentInfo(headerType)?.male_favorite,
                    switchCurrentInfo(headerType)?.chat_uid,
                ),
            );
        }
        if (headerType === MENU.MENU_LETTER) {
            dispatch(currentChatMailboxAction.setFavoriteStatus(!+switchCurrentInfo(headerType)?.male_favorite));
            dispatch(currentChatAction.setFavoriteStatus(!+switchCurrentInfo(headerType)?.male_favorite));
            dispatch(
                chatsAction.setFavoriteStatus(
                    !+switchCurrentInfo(headerType)?.male_favorite,
                    switchCurrentInfo(headerType)?.chat_uid,
                ),
            );
        }

        new ChatsApi()
            .postSetFavorite(
                switchCurrentInfo(headerType)?.male_external_id,
                switchCurrentInfo(headerType)?.chat_uid ||
                    currentChatMailboxState?.info?.chat_uid ||
                    currentChatMailboxState?.chatId?.chat_uid,
                !switchCurrentInfo(headerType)?.male_favorite,
                source?.token,
            )
            .then((res) => {
                if (!res?.status) {
                    if (
                        [
                            MENU.MENU_CHAT,
                            MENU.MENU_UNANSWERED,
                            MENU.MENU_FAVORITE,
                            MENU.MENU_DIALOGUES,
                            MENU.MENU_MATCHES,
                            MENU.MENU_MAYBE,
                            MENU.MENU_SPECIAL,
                        ].includes(headerType)
                    ) {
                        dispatch(currentChatAction.setFavoriteStatus(switchCurrentInfo(headerType)?.male_favorite));
                        dispatch(
                            chatsAction.setFavoriteStatus(
                                switchCurrentInfo(headerType)?.male_favorite,
                                switchCurrentInfo(headerType)?.chat_uid,
                            ),
                        );
                    }
                    if (headerType === MENU.MENU_LETTER) {
                        dispatch(
                            currentChatMailboxAction.setFavoriteStatus(switchCurrentInfo(headerType)?.male_favorite),
                        );
                    }
                }
            });
    };

    const setPin = () => {
        if (chatsState?.pinList.some((item) => item === currentChatState?.info?.chat_uid)) {
            dispatch(chatsAction.removePinChat(switchCurrentInfo(headerType)?.chat_uid));
        } else {
            dispatch(chatsAction.addPinChat(switchCurrentInfo(headerType)?.chat_uid));
        }
    };

    const setBlock = () => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);

        if (location?.pathname?.includes(MENU.MENU_BLOCK)) {
            new ChatsApi()
                .postRemoveBlackList(
                    switchCurrentInfo(headerType)?.male_external_id,
                    switchCurrentInfo(headerType)?.chat_uid,
                    source?.token,
                )
                .then((res) => {
                    if (res?.status) transition.transitionPush(`/${MENU.MENU_BLOCK}`);
                });
        } else {
            if (
                [
                    MENU.MENU_CHAT,
                    MENU.MENU_UNANSWERED,
                    MENU.MENU_FAVORITE,
                    MENU.MENU_DIALOGUES,
                    MENU.MENU_MATCHES,
                    MENU.MENU_MAYBE,
                    MENU.MENU_SPECIAL,
                ].includes(headerType)
            ) {
                dispatch(chatsAction.removeChat(switchCurrentInfo(headerType)?.chat_uid));
            }

            new ChatsApi()
                .postAddBlackList(
                    switchCurrentInfo(headerType)?.male_external_id,
                    switchCurrentInfo(headerType)?.chat_uid ||
                        currentChatMailboxState?.info?.chat_uid ||
                        currentChatMailboxState?.chatId?.chat_uid,
                    source?.token,
                )
                .then((res) => {
                    if (res?.status) {
                        if (headerType === MENU.MENU_LETTER) {
                            dispatch(currentChatMailboxAction.removeCurrentChatMailbox());
                        }
                    }
                });
        }
    };

    const openWomanProfile = () => {
        if (switchCurrentInfo(headerType)?.female_external_id) {
            window.open(`/${MENU.MENU_PROFILE_WOMAN}/${switchCurrentInfo(headerType)?.female_external_id}`);
            // transition.transitionPush(`/${MENU.MENU_PROFILE_WOMAN}/${switchCurrentInfo(headerType)?.female_external_id}`);
        }
    };

    const openManProfile = () => {
        if (switchCurrentInfo(headerType)?.male_external_id) {
            window.open(`/${MENU.MENU_PROFILE_MAN}/${switchCurrentInfo(headerType)?.male_external_id}`);
            // transition.transitionPush(`/${MENU.MENU_PROFILE_MAN}/${switchCurrentInfo(headerType)?.male_external_id}`);
        }
    };

    const openLetter = () => {
        if (currentChatState?.info?.chat_uid) {
            transition.transitionPush(`/letter/${currentChatState?.info?.chat_uid}`);
            dispatch(
                notifyAction.removePaidNotifyByChatUID(
                    currentChatState?.info?.chat_uid,
                    [SYNC.LETTER_NOTIFY_TYPE, SYNC.LIKED_NOTIFY_TYPE],
                    profilesState?.operatorId,
                ),
            );
            dispatch(currentChatAction.removeCurrentChat());
        }
    };

    const openChat = () => {
        if (currentChatMailboxState?.info?.chat_uid || currentChatMailboxState?.chatId?.chat_uid) {
            if (previousPage) {
                transition.transitionPush(
                    `/${previousPage}/${currentChatMailboxState?.info?.chat_uid || currentChatMailboxState?.chatId?.chat_uid}`,
                );
            } else {
                transition.transitionPush(
                    `/chat/${currentChatMailboxState?.info?.chat_uid || currentChatMailboxState?.chatId?.chat_uid}`,
                );
            }
            dispatch(
                notifyAction.removePaidNotifyByChatUID(
                    currentChatMailboxState?.mailChatInfo?.chat_uid,
                    [SYNC.MESSAGE_NOTIFY_TYPE, SYNC.LIKED_NOTIFY_TYPE],
                    profilesState?.operatorId,
                ),
            );
            dispatch(currentChatMailboxAction.removeCurrentChatMailbox());
        }
    };

    const isUserOnline = () => {
        if (headerType === MENU.MENU_LETTER) {
            return +currentChatMailboxState?.info?.online
                ? !!+currentChatMailboxState?.info?.online
                : !getLastOnlineMessage(
                      +currentChatMailboxState?.info?.operator === 0
                          ? currentChatMailboxState?.info?.sender_last_online
                          : currentChatMailboxState?.info?.recipient_last_online,
                  );
        } else if (headerType === MENU.MENU_CHAT) {
            return !!+currentChatState?.info?.online;
        }
    };

    const getCurrentInfoByChat = () => {
        if (!currentChatState?.info?.chat_uid) return {};
        else
            return {
                ...currentChatState?.info,
                ...{ isUserOnline: isUserOnline() },
            };
    };

    const getCurrentInfoByLetter = () => {
        if (!currentChatMailboxState?.info) return {};
        else {
            const userCountryIcon = () => {
                if (!+currentChatMailboxState?.info?.operator) {
                    return currentChatMailboxState?.info?.sender_country_code
                        ? `/assets/flags/${currentChatMailboxState?.info?.sender_country_code?.toLowerCase()}.jpg`
                        : currentChatMailboxState?.info?.country_image;
                } else {
                    return currentChatMailboxState?.info?.recipient_country_code
                        ? `/assets/flags/${currentChatMailboxState?.info?.recipient_country_code?.toLowerCase()}.jpg`
                        : currentChatMailboxState?.info?.country_image;
                }
            };

            return {
                ...currentChatMailboxState?.info,
                ...{
                    female_external_id: currentChatMailboxState?.info?.female_id,
                    photo_link: +currentChatMailboxState?.info?.operator
                        ? currentChatMailboxState?.info?.recipient_image
                        : currentChatMailboxState?.info?.sender_image,
                    name: +currentChatMailboxState?.info?.operator
                        ? currentChatMailboxState?.info?.recipient_name
                        : currentChatMailboxState?.info?.sender_name,
                    age: +currentChatMailboxState?.info?.operator
                        ? currentChatMailboxState?.info?.recipient_age
                        : currentChatMailboxState?.info?.sender_age,
                    country_image: userCountryIcon(),
                    city: +currentChatMailboxState?.info?.operator
                        ? currentChatMailboxState?.info?.recipient_city
                        : currentChatMailboxState?.info?.sender_city,
                    male_favorite: !!+currentChatMailboxState?.mailChatInfo?.male_favorite,
                    chat_uid: currentChatMailboxState?.mailChatInfo?.chat_uid,
                    isUserOnline: isUserOnline(),
                },
            };
        }
    };

    const switchCurrentInfo = (headerType) => {
        switch (headerType) {
            case MENU.MENU_CHAT:
            case MENU.MENU_UNANSWERED:
            case MENU.MENU_FAVORITE:
            case MENU.MENU_DIALOGUES:
            case MENU.MENU_MATCHES:
            case MENU.MENU_MAYBE:
            case MENU.MENU_SPECIAL:
                return getCurrentInfoByChat();
            case MENU.MENU_LETTER:
                return getCurrentInfoByLetter();
            default:
                return {};
        }
    };

    return {
        currentChatInfo: switchCurrentInfo(headerType),
        setMaybe,
        setSpecial,
        setFavorite,
        setBlock,
        setPin,
        isPin: chatsState?.pinList.some((item) => item === currentChatState?.info?.chat_uid),
        openWomanProfile,
        openManProfile,
        openLetter,
        openChat,
        headerType,
    };
}
