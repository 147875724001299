import Base from './Base';

export default class VirtualGiftsApi extends Base {
    getVirtualGiftsData(recipient_id) {
        return super
            .post(`virtual/gift/group/all`, { recipient_id })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }
}
