import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfileApi from '../../services/api/ProfileApi';

export default function MaleCard({ external_id, name, age, country_icon, city_name, photo_link, online = 0 }) {
    const history = useHistory();
    const profilesState = useSelector((state) => state.profiles);
    // const dispatch = useDispatch();
    const male_id = external_id;
    const id_operator =
        profilesState && profilesState.active && profilesState.active.external_id
            ? profilesState.active.external_id
            : null;

    const onlineStatus = +online ? 'online' : 'offline';

    const goToChat = () => {
        if (!profilesState.active.id) {
            alert('Please, select woman profile first!');
            return false;
        }

        new ProfileApi().getChatWithProfile(male_id, id_operator).then((response) => {
            //TODO переписать вторую вводную на внешний айди анкеты
            if (response.status === false) {
                return false;
            } else {
                history.push(`/chat/${response.chat_uid}`);
                return true;
            }
        });
    };

    const goProfile = () => {
        history.push(`profile-man/${external_id}`);
    };

    return (
        <div className="c3_woman_search_list_item">
            <div className="c3_woman_search_list_item_back">
                <div className="c3_woman_search_list_item_back_top">
                    {/*
					<div className="c3_woman_search_list_item_back_top_btn letter_btn" onClick={openLetter}>
						<span>Write letter</span>
					</div>

					<div className="c3_woman_search_list_item_back_top_btn heart_btn">
						<span>Like him</span>
					</div>
					*/}
                </div>
                <div className="c3_woman_search_list_item_back_bottom">
                    <div className="c3_woman_search_list_item_back_bottom_left">
                        <div className="c3_woman_search_list_item_back_bottom_profile_btn" onClick={goProfile} />
                        {/* <div className="c3_woman_search_list_item_back_bottom_photo_btn"></div> */}
                    </div>

                    <div
                        className={`c3_woman_search_list_item_back_bottom_chat_btn ${!id_operator ? 'ui-state-lock ui-state-disabled' : ''}`}
                        onClick={goToChat}
                    >
                        <img src="../../img/c4-reply.svg" alt="" />
                        <span>Chat</span>
                    </div>
                </div>
            </div>
            <div className="c3_woman_search_list_item_front">
                <img src={photo_link} alt="" className="c3_woman_search_list_item_photo" />
                <div className="c3_woman_search_list_item_name">
                    {name}, {age}
                    <div
                        className={`c3lh_search_item_profile_online_status c3lh_your_likes_item_profile_${onlineStatus}`}
                    >
                        {onlineStatus}
                    </div>
                </div>
                <div className="c3_woman_search_list_item_info">
                    <div className="c3_woman_search_list_item_id">ID {external_id}</div>
                    <div className="c3_woman_search_list_item_place">
                        <img src={country_icon} alt="" />
                        <span>{city_name}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
