import React from 'react';

export default function ImgContent({ item, closeModal, onViewPhoto }) {
    return (
        <React.Fragment>
            <div className="popup_attach_photo_click_img_wrap">
                <img
                    src={item.link}
                    alt=""
                    className={`popup_attach_photo_click_img ${item.attachment_payed === '1' ? '' : 'blur_unpaid'}`}
                />
                {item.attachment_payed && item.attachment_payed === '1' ? (
                    <div />
                ) : (
                    <div className="click_to_view" onClick={onViewPhoto}>
                        Click to view the photo
                    </div>
                )}
            </div>
            <div className="pap-btn-close" onClick={closeModal}>
                <img src="../../../../img/pap-btn-close.svg" alt="" />
            </div>
        </React.Fragment>
    );
}
