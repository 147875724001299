import React, { useEffect } from 'react';
import Styles from './styles.module.scss';
import * as LENGTH from '../../../../constants/length';

export default ({ noticeData, handleNotesData, closeNoticeEdit, onSave, setIsEdit }) => {
    useEffect(() => {
        return () => setIsEdit(false);
    }, []);

    return (
        <div className={Styles.edit_block}>
            <div className={Styles.head}>
                <div className={Styles.title}>Notes</div>
                <div className={Styles.close_btn} onClick={closeNoticeEdit} />
            </div>
            <div className={Styles.body}>
                <input
                    type="text"
                    className={Styles.input_title}
                    placeholder="Enter note name"
                    name="notesName"
                    value={noticeData?.notesName}
                    onChange={handleNotesData}
                />
                <div className={Styles.input_text_wrap}>
                    <textarea
                        className={Styles.input_text}
                        placeholder="Enter your note"
                        name="notesText"
                        maxLength={LENGTH.NOTES_MAX_LENGTH}
                        value={noticeData?.notesText}
                        onChange={handleNotesData}
                    />
                    <span>
                        {noticeData?.notesText?.length ?? 0}/{LENGTH.NOTES_MAX_LENGTH}
                    </span>
                </div>

                <div className={Styles.save_btn} onClick={onSave}>
                    <span>Save</span>
                </div>
            </div>
        </div>
    );
};
