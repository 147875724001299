import React from 'react';

import LeftProfile from './LeftProfile';

export default ({ info }) => {
    return (
        <div className="c3_top">
            <div className="c3_head">
                <LeftProfile info={info} />
            </div>
        </div>
    );
};
