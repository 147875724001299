import Base from '../Base';

export default class TemplatesApi extends Base {
    getOperatorSettings() {
        return super.get(`v3/operstat/templates/templates-settings`, {}).then((res) => res?.data);
    }

    getWomanTemplates(womanExternalID) {
        return super
            .get(`v3/operstat/templates/woman-templates?womanExternalID=${womanExternalID}`, {})
            .then((res) => res?.data);
    }
}
