import React from 'react';
import styles from '../styles.module.scss';
import ImageItem from './ImageItem';
import VideoItem from './VideoItem';
import AudioItem from './AudioItem';
import * as SENDER from '../../../../../constants/sender';
import * as CHAT from '../../../../../constants/chat';

export default ({ activeSenderType, messageAttachment, deleteFileAttachment }) => {
    const switchAttachmentType = (file, index) => {
        switch (file?.attachment_type) {
            case CHAT.MESSAGE_CONTENT_TYPE_IMAGE:
                return <ImageItem {...file} index={index} deleteFileAttachment={deleteFileAttachment} key={index} />;
            case CHAT.MESSAGE_CONTENT_TYPE_VIDEO:
                return <VideoItem {...file} index={index} deleteFileAttachment={deleteFileAttachment} key={index} />;
            case CHAT.MESSAGE_CONTENT_TYPE_AUDIO:
                return <AudioItem {...file} index={index} deleteFileAttachment={deleteFileAttachment} key={index} />;
            default:
                return '';
        }
    };

    return (
        activeSenderType === SENDER.SENDER_TYPE_LETTER && (
            <div className={styles.sender_forms_middle_right}>
                <div className={styles.sender_forms_middle_right_title}>Attachments</div>
                <div className={styles.sender_forms_middle_media_list}>
                    {!!messageAttachment?.length &&
                        messageAttachment.map((file, index) => switchAttachmentType(file, index))}
                </div>
            </div>
        )
    );
};
