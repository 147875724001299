import React from 'react';
import styles from './styles.module.scss';
import useHandleSenderHeader from '../../../hooks/sender/useHandleSenderHeader';
import * as SENDER from '../../../constants/sender';

export default () => {
    const { handleSenderHeader, activeSenderType } = useHandleSenderHeader();

    return (
        <div className={styles.header}>
            <div
                className={`${styles.item} ${styles.item_chat} ${activeSenderType === SENDER.SENDER_TYPE_CHAT ? styles.active : ''}`}
                onClick={() => handleSenderHeader(SENDER.SENDER_TYPE_CHAT)}
            >
                <div className={`${styles.item_inner} ${styles.item_inner_chat}`}>Chat</div>
            </div>
            <div
                className={`${styles.item} ${styles.item_letters} ${activeSenderType === SENDER.SENDER_TYPE_LETTER ? styles.active : ''}`}
                onClick={() => handleSenderHeader(SENDER.SENDER_TYPE_LETTER)}
            >
                <div className={`${styles.item_inner} ${styles.item_inner_letters}`}>Letters</div>
            </div>
        </div>
    );
};
