import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import SwitchLetterAttachment from '../../../../common/SwitchLetterAttachment';
import { objectToArray } from '../../../../../services/methods';
import { formatDate } from '../../../../../services/time';
import * as IMAGES from '../../../../common/Images';
import { settings } from '../../../../../services/slick-carousel-config/settings';
import styles from '../../styles.module.scss';
import { scrollTopToMainLetter } from '../../../../../services/dom';
import MailboxApi from '../../../../../services/api/MailboxApi';
import * as mailboxAction from '../../../../../store/actions/mailbox';
import * as alertAction from '../../../../../store/actions/alerts';
import * as ALERTS from '../../../../../store/constants/alerts';

export default function SenderMsg({ item, currentChatMailboxState, scrollPosition, sender, isUserOnline }) {
    const dispatch = useDispatch();
    const isActiveHideBtn = !!(!isUserOnline && !+item?.mail?.mail_read_status && sender);
    const isOperator = +item?.mail?.operator === 1;
    const isUserDelete =
        (!isOperator && item?.mail?.hide_for_sender === 1) || (isOperator && item?.mail?.hide_for_recipient === 1);

    const onHideMail = () => {
        if (isActiveHideBtn && item?.mail?.id) {
            dispatch(
                alertAction.setCustomConfirmAlert({
                    type: ALERTS.SET_CUSTOM_CONFIRM_ALERT,
                    title: `Are you sure?`,
                    btnName1: 'No',
                    btnName2: 'Yes',
                    successCallback: () => {
                        new MailboxApi().hideMail(item?.mail?.id).then((res) => {
                            if (res?.status) {
                                dispatch(mailboxAction.hideMail(item?.mail?.id));
                            } else {
                                dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                            }
                        });
                    },
                }),
            );
        }
    };

    useEffect(() => {
        if (currentChatMailboxState?.history?.length)
            scrollTopToMainLetter(
                currentChatMailboxState?.specialLetterId ? currentChatMailboxState?.specialLetterId : item?.mail?.id,
            );

        if (!+item?.mail?.operator && !+item?.mail?.mail_read_status) {
            new MailboxApi().postReadMail(item?.mail?.id, item?.mail?.female_id, item?.mail?.male_id).then((res) => {
                if (res?.status) {
                    dispatch(mailboxAction.getUnreadCount(item?.mail?.female_id ? item?.mail?.female_id : ''));
                }
            });
        }
        if (currentChatMailboxState.specialLetterId) {
            scrollPosition(currentChatMailboxState.specialLetterId);
        }
    }, [currentChatMailboxState.specialLetterId]);

    return (
        <div
            className={`${styles.letters_chat_item} ${styles[sender ? 'from_you' : 'to_you']}`}
            key={item?.mail?.id}
            id={`lett-${item?.mail?.id}`}
            data-testid={sender ? `sent-letter-${item?.mail?.id}` : `received-letter-${item?.mail?.id}`}
        >
            <div className={`${styles.letters_chat_item_top}`}>
                <div className={styles.letters_chat_item_head}>
                    <div className={styles.letters_chat_item_user}>
                        {!!sender && (
                            <span data-testid="letter-user-name">{`${item.mail.sender_name}, ${item.mail.sender_age}`}</span>
                        )}
                        <div
                            className={`${styles.letters_chat_item_user_photo} ${isUserOnline && styles.online}`}
                            data-testid="letter-user-photo"
                        >
                            <img src={item.mail.sender_image ?? IMAGES.profile_img_empty} alt="" />
                        </div>
                        {!sender && (
                            <span data-testid="letter-user-name">{`${item.mail.sender_name}, ${item.mail.sender_age}`}</span>
                        )}
                    </div>
                </div>
                <div className={styles.letters_chat_item_text} data-testid="letter-content">
                    <p>{item.mail.message_content}</p>
                </div>
                <div className={styles.letters_chat_item_bottom}>
                    <div className={styles.letters_chat_item_time} data-testid="letter-date">
                        {formatDate(item?.mail?.date_created)}
                    </div>

                    {!!sender && item?.mail?.mail_read_status && (
                        <div
                            className={`${styles.message_status} ${+item?.mail?.mail_read_status ? styles.readed : styles.send}`}
                            data-testid="letter-status"
                        />
                    )}

                    {isActiveHideBtn && (
                        <div className={styles.message_hide} data-testid="hide-btn" onClick={onHideMail}>
                            Hide
                        </div>
                    )}
                </div>
                {isUserDelete && (
                    <div
                        className={`${styles.letters_chat_item_label} ${isOperator ? styles.operator : ''}`}
                        data-testid="letter-status-delete"
                    >
                        Deleted by user
                    </div>
                )}
            </div>
            {item.attachments && objectToArray(item.attachments)?.length > 0 && (
                <div className={styles.letters_list_item_bottom} data-testid="letter-attachments">
                    <div className={styles.letters_list_item_media_slider}>
                        <Slider {...settings}>
                            {objectToArray(item.attachments).map((attach, key) => (
                                <SwitchLetterAttachment
                                    data={attach}
                                    key={attach.attachment_id + item.id}
                                    index={key}
                                    allData={objectToArray(item.attachments)}
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            )}
        </div>
    );
}
