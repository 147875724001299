import React from 'react';
import * as IMAGES from '../../common/Images';
import * as MENU from '../../../constants/menu';
import Styles from './styles.module.scss';
import * as SENDER from '../../../constants/sender';

export default ({
    activeProfile,
    giftsNotify,
    removeActiveProfile,
    activeFolder,
    activeFolderName,
    multiActiveBlockRef,
    isActiveSender,
    openWomanProfile,
    onSetActiveType,
}) => {
    return (
        <div
            className={Styles.clmn_1_profile_wrap}
            ref={multiActiveBlockRef}
            data-testid={`active-profile-${activeProfile?.external_id}`}
        >
            <div className={Styles.clmn_1_profile_back_to_mm} onClick={removeActiveProfile} data-testid="profiles-btn">
                <img src={IMAGES.default.heart_red} alt="" />
            </div>
            <div className={Styles.clmn_1_profile_top} data-testid="active-profile-photo">
                {activeProfile?.photo_link && (
                    <img
                        className={Styles.clmn_1_profile_photo}
                        src={activeProfile?.photo_link ?? ''}
                        alt=""
                        onClick={() => openWomanProfile(activeProfile?.external_id)}
                    />
                )}
                <div className={Styles.clmn_1_profile_right}>
                    <div className={Styles.clmn_1_profile_name} data-testid="active-profile-name">
                        {`${activeProfile?.name ?? ''}, ${activeProfile?.age ?? ''}`}
                    </div>
                    <div className={Styles.clmn_1_profile_id} data-testid="active-profile-external_id">
                        {`ID ${activeProfile?.external_id ?? ''}`}
                    </div>
                </div>
            </div>
            <div className={Styles.clmn_1_profile_bottom}>
                <div
                    className={`${Styles.clmn_1_profile_btn} ${Styles.sender}`}
                    onClick={() => {
                        activeFolder(MENU.MENU_SENDER);
                        onSetActiveType(SENDER.SENDER_TYPE_CHAT);
                    }}
                    data-testid="sender-btn"
                >
                    <img src={IMAGES.default.c1_sender} alt="" />
                    {isActiveSender(activeProfile?.external_id) && (
                        <div className={Styles.clmn_1_mm_chat_list_item_status_green} />
                    )}
                    {/*<div className={Styles.clmn_1_mm_chat_list_item_status_yellow}/>*/}
                </div>
                <div
                    className={`${Styles.clmn_1_profile_btn} ${activeFolderName === MENU.MENU_GIFTS ? Styles.gifts : ''}`}
                    onClick={() => activeFolder(MENU.MENU_GIFTS)}
                    data-testid="gifts-btn"
                >
                    <img src={IMAGES.default.c1_gifts} alt="" />
                    {giftsNotify && <div className={Styles.clmn_1_profile_btn_gifts_val}>{giftsNotify}</div>}
                </div>
            </div>
        </div>
    );
};
