import md5 from 'js-md5';
import moment from 'moment';
import * as AWS from '../../constants/aws';
import Resizer from 'react-image-file-resizer';
import AwsApi from '../../services/api/v3/AwsApi';
import * as POSTS from '../../constants/posts';

export default async (file, fileName, dir, bucketName) => {
    const newFileName = md5(moment().format() + fileName);
    const fileParts = file?.name?.split('.');
    const fileType = fileParts[fileParts?.length - 1];

    if (bucketName === AWS.IMG_BUCKET) {
        for (const size of AWS.AWS_THUMBS) {
            await new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file,
                    size.width,
                    size.height,
                    fileType,
                    90,
                    0,
                    async (uri) => {
                        const formData2 = new FormData();

                        formData2.append('file', uri);
                        formData2.append('newFileName', `w-${size.width}-h-${size.height}-${newFileName}.${fileType}`);
                        formData2.append('fileName', fileName);
                        formData2.append('dir', dir);
                        formData2.append('bucketName', bucketName);

                        resolve(await new AwsApi().generateLink(formData2));
                    },
                    'blob',
                );
            });
        }
    } else if (bucketName === AWS.POSTS_BUCKET) {
        if (fileName === POSTS.IMAGE_POST_NAME) {
            for (const size of AWS.AWS_POSTS_SIZE) {
                await new Promise((resolve) => {
                    Resizer.imageFileResizer(
                        file,
                        size.width,
                        size.height,
                        fileType,
                        90,
                        0,
                        async (uri) => {
                            const formData2 = new FormData();

                            formData2.append('file', uri);
                            formData2.append(
                                'newFileName',
                                `w-${size.width}-h-${size.height}-${newFileName}.${fileType}`,
                            );
                            formData2.append('fileName', fileName);
                            formData2.append('dir', dir);
                            formData2.append('bucketName', bucketName);

                            resolve(await new AwsApi().generateLink(formData2));
                        },
                        'blob',
                    );
                });
            }
        } else if (file?.type?.split('/')[0] === 'video') {
            const formData = new FormData();

            formData.append('file', file);
            formData.append('newFileName', `${newFileName}.${fileType}`);
            // formData.append('fileName', fileName);
            formData.append('dir', dir);
            formData.append('bucketName', bucketName);
            formData.append('useFullFlow', true);
            formData.append('sortOrder', 0);
            formData.append('filename', fileName);

            return new AwsApi().generateVideoLink(formData);
        }
    }

    const formData = new FormData();

    formData.append('file', file);
    formData.append('newFileName', `${newFileName}.${fileType}`);
    formData.append('fileName', fileName);
    formData.append('dir', dir);
    formData.append('bucketName', bucketName);

    switch (bucketName) {
        case AWS.VIDEO_BUCKET:
            return new AwsApi().generateVideoLink(formData);
        case AWS.AUDIO_BUCKET:
            return new AwsApi().generateAudioLink(formData);
        default:
            return new AwsApi().generateLink(formData);
    }
};
