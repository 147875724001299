import React from 'react';
import MenuItem from './MenuItem';
import * as CHAT from '../../../constants/chat';
import * as MENU from '../../../constants/menu';
import Styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import * as CONFIG from '../../../constants/config';
import SITE from '../../../config/site';

export default function MainMenu({ openFolder, activeFolder }) {
    const countersState = useSelector((state) => state.counters);
    const personalState = useSelector((state) => state.personal);
    const notifyState = useSelector((state) => state.notify);
    const profilesState = useSelector((state) => state.profiles);

    const isActivePost = profilesState?.active?.site_ids?.some((el) => CONFIG?.SHOW_SOCIAL.includes(+el));

    const getCounterByNotify = (menuTypeList) => {
        return notifyState?.listPaid?.filter(
            (item) =>
                menuTypeList.includes(item?.message_type) &&
                (profilesState?.active?.external_id
                    ? +profilesState?.active?.external_id === +item?.recipient_external_id
                    : true),
        )?.length;
    };

    return (
        <div className={Styles.clmn_1_menu_list} data-testid="main-menu-list">
            <div className={Styles.clmn_1_chat_folders}>
                <MenuItem
                    styleFolder={Styles.chat}
                    styleActive={activeFolder === MENU.MENU_CHAT ? Styles.active : ''}
                    countFolder={null}
                    titleFolder={'All chats'}
                    openFolder={() => openFolder(MENU.MENU_CHAT)}
                    countStyle={Styles.red}
                />
                <MenuItem
                    styleFolder={Styles.unanswered}
                    styleActive={activeFolder === MENU.MENU_UNANSWERED ? Styles.active : ''}
                    countFolder={
                        countersState?.chatCounter?.unanswered_online
                            ? countersState?.chatCounter?.unanswered_online
                            : getCounterByNotify(CHAT.CHAT_MESSAGE_CONTENT)
                    }
                    titleFolder={'Unanswered'}
                    openFolder={() => openFolder(MENU.MENU_UNANSWERED)}
                    countStyle={Styles.red}
                />
                <MenuItem
                    styleFolder={Styles.favorites}
                    styleActive={activeFolder === MENU.MENU_FAVORITE ? Styles.active : ''}
                    countFolder={null}
                    titleFolder={'Favorites'}
                    openFolder={() => openFolder(MENU.MENU_FAVORITE)}
                    countStyle={Styles.red}
                />
                <MenuItem
                    styleFolder={Styles.dialogues}
                    styleActive={activeFolder === MENU.MENU_DIALOGUES ? Styles.active : ''}
                    countFolder={null}
                    titleFolder={'Dialogues'}
                    openFolder={() => openFolder(MENU.MENU_DIALOGUES)}
                    countStyle={Styles.red}
                />
            </div>
            <div className={Styles.clmn_1_matches_folders}>
                <MenuItem
                    styleFolder={Styles.personal}
                    styleActive={activeFolder === MENU.MENU_PERSONAL ? Styles.active : ''}
                    countFolder={personalState?.personalManList?.length}
                    titleFolder={'Personal'}
                    openFolder={() => openFolder(MENU.MENU_PERSONAL)}
                />
                <MenuItem
                    styleFolder={Styles.matches}
                    styleActive={activeFolder === MENU.MENU_CHANCE ? Styles.active : ''}
                    countFolder={null}
                    titleFolder={'Chance'}
                    openFolder={() => openFolder(MENU.MENU_CHANCE)}
                />
            </div>
            <div className={Styles.clmn_1_maybe_folders}>
                <MenuItem
                    styleFolder={Styles.maybe}
                    styleActive={activeFolder === MENU.MENU_MAYBE ? Styles.active : ''}
                    countFolder={null}
                    titleFolder={'Maybe'}
                    openFolder={() => openFolder(MENU.MENU_MAYBE)}
                />
                <MenuItem
                    styleFolder={Styles.special}
                    styleActive={activeFolder === MENU.MENU_SPECIAL ? Styles.active : ''}
                    countFolder={null}
                    titleFolder={'Special'}
                    openFolder={() => openFolder(MENU.MENU_SPECIAL)}
                />
            </div>

            <div className={Styles.clmn_1_letters_folders}>
                <MenuItem
                    styleFolder={Styles.letters}
                    styleActive={activeFolder === MENU.MENU_LETTER ? Styles.active : ''}
                    countFolder={
                        +countersState?.letterCounter
                            ? +countersState?.letterCounter
                            : getCounterByNotify([CHAT.MESSAGE_CONTENT_TYPE_LETTER])
                    }
                    titleFolder={'Letters'}
                    openFolder={() => openFolder(MENU.MENU_LETTER)}
                    countStyle={Styles.red}
                />

                {SITE.ID === 1 && (
                    <MenuItem
                        styleFolder={Styles.posts}
                        styleActive={activeFolder === MENU.MENU_POSTS ? Styles.active : ''}
                        styleDisabled={!isActivePost ? Styles.disabled : ''}
                        // countFolder={}
                        titleFolder={'Posts'}
                        openFolder={() => isActivePost && openFolder(MENU.MENU_POSTS)}
                        countStyle={Styles.red}
                        titleHover={!isActivePost ? 'First, choose a profile, and it should be social' : ''}
                    />
                )}
            </div>
        </div>
    );
}
