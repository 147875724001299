import React from 'react';
import DnDContainer from '../DragAndDrop/Container';

export default ({ type, videos, isSortingDisabled }) => {
    return (
        <div className="upload_popup_tabs_content_middle video" id="uptcmv" data-testid="file-list">
            <DnDContainer type={type} data={videos} isSortingDisabled={isSortingDisabled} />
        </div>
    );
};
