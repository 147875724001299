import React, { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import { shifts } from '../../../constants/operators';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

export default ({ currentOperator }) => {
    const menuState = useSelector((state) => state.menu);

    const operators = JSON.parse(localStorage.getItem('operators'));

    const [filteredOperators, setFilteredOperators] = useState(operators);
    const [chosenShift] = useState(localStorage.getItem('chosenShift') || shifts[0].value);

    const handleFilterOperators = useCallback(
        (value) => {
            setFilteredOperators(operators.filter((operator) => operator.work_shift === value));
            localStorage.setItem('chosenShift', value);
        },
        [filteredOperators],
    );

    const handleChange = (e, option) => {
        if (!option?.token) return;

        localStorage.setItem('token', option?.token);
        localStorage.setItem('current_operator', JSON.stringify(option));
        localStorage.setItem('operator', JSON.stringify(+option?.id));
        localStorage.setItem('email', option?.email);

        window.location = `/${menuState?.main || 'chat'}`;
    };

    return (
        <div className={styles.options_selectors_container}>
            <Autocomplete
                id="combo-box"
                value={currentOperator || ''}
                options={filteredOperators}
                getOptionLabel={(option) => `${option?.firstname || ''} ${option?.lastname || ''}`}
                onChange={(e, option) => handleChange(e, option)}
                className={styles.options_list}
                renderInput={(params) => <TextField {...params} label="Search operator ID" variant="outlined" />}
            />
            <Select
                defaultValue={chosenShift}
                className={styles.shift_filter_options}
                onChange={(e) => handleFilterOperators(e.target.value)}
            >
                {shifts.map((shift) => (
                    <MenuItem key={shift.value} value={shift.value}>
                        {shift.text}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};
