import { useEffect, useRef, useState } from 'react';
import awsUploadFile from '../../controllers/aws/uploadFile';
import { useDispatch, useSelector } from 'react-redux';
import * as loaderAction from '../../store/actions/loader';
import * as ATTACH from '../../constants/attach';
import * as LENGTH from '../../constants/length';
import * as AWS from '../../constants/aws';
import * as alertAction from '../../store/actions/alerts';
import * as postsAction from '../../store/actions/posts';
import PostsApi from '../../services/api/PostsApi';
import * as POSTS from '../../constants/posts';
import {
    AWS_POSTS_BIG_SIZE_HEIGHT,
    AWS_POSTS_BIG_SIZE_WIDTH,
    AWS_POSTS_SMALL_SIZE_HEIGHT,
    AWS_POSTS_SMALL_SIZE_WIDTH,
} from '../../constants/aws';
import * as ALERTS from '../../store/constants/alerts';
import { sendErrToSentry } from '../../services/sentry';

export default function useInitPosts({ closeViewModal }) {
    const dispatch = useDispatch();
    const { active } = useSelector((state) => state.profiles);
    const postsState = useSelector((state) => state.posts);

    const initNewPostData = {
        text: '',
        files: [],
    };

    const [postsData, setPostsData] = useState([]); //to show posts
    const [newPostData, setNewPostData] = useState(initNewPostData); //to create or edit a post (selected post data)
    const [numberUploadedImg, setNumberUploadedImg] = useState(0); //Necessary for the loader to upload images

    const [activeFilterStatus, setActiveFilterStatus] = useState(0);
    const [isCreateAnother, setIsCreateAnother] = useState(false); //Checkbox Create another post
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);
    const [initPhotoURL, setInitPhotoURL] = useState(''); //Loaded image before cropping
    const [originUrlVideo, setOriginUrlVideo] = useState(''); //Required for an uploaded video for a post

    const [postPage, setPostPage] = useState(1);
    const [isBlockPost, setIsBlockPost] = useState(false);

    // START Modal logic
    const [createModalType, setCreateModalType] = useState(''); //create or edit
    const [isCropModal, setIsCropModal] = useState(false); //Crop Photo Modal

    const openCreateModal = (type = '', postData) => {
        if (type !== 'create' && type !== 'edit') return false;

        if (postData) {
            setNewPostData(postData);
            setNumberUploadedImg(postData?.files?.length);
        }
        setCreateModalType(type);
    };
    const closeCreateModal = () => {
        setCreateModalType('');
        setNewPostData(initNewPostData);
        setIsCreateAnother(false);
        setNumberUploadedImg(0);
    };

    const openCropModal = (photoUrl) => {
        setInitPhotoURL(photoUrl);
        setIsCropModal(true);
    };

    const closeCropModal = () => {
        setIsCropModal(false);
        setInitPhotoURL('');
        setOriginUrlVideo('');
    };
    // END Modal logic

    const createImgRowRef = useRef(null);
    const postScrollRef = useRef(null);

    const onSetActiveFilterStatus = (statusId) => {
        setActiveFilterStatus(statusId);
        onGetAllPosts({ status: statusId });
    };

    const checkUploadedImage = (file) => {
        const { type, size } = file;
        if (!type.includes('jpeg') && !type.includes('jpg')) return false;
        return size <= LENGTH.MAX_IMG_SIZE;
    };

    const checkUploadedVideo = (file) => {
        const { type, size } = file;
        if (!['video/mp4', 'video/mov', 'video/quicktime'].includes(type)) {
            return false;
        }
        return size <= LENGTH.MAX_VIDEO_SIZE;
    };

    const uploadImage = async (file, isCropped) => {
        if (!checkUploadedImage(file)) {
            dispatch(
                alertAction.setMessage('Image must be in jpg or jpeg format. Maximum image size - 5 MB.', 'Wrong file'),
            );
            return false;
        }

        dispatch(loaderAction.setActiveGifLoader(true));

        try {
            awsUploadFile(file, file?.name, active?.external_id, AWS.POSTS_BUCKET).then((res) => {
                if (res?.status && res?.success) {
                    if (isCropped) {
                        const parts = res?.data?.link?.split('/');
                        const baseUrl = parts.slice(0, -1).join('/');
                        const fileName = parts.slice(-1)[0];
                        const smallSizeFileName = `w-${AWS_POSTS_SMALL_SIZE_WIDTH}-h-${AWS_POSTS_SMALL_SIZE_HEIGHT}-${fileName}`;
                        const bigSizeFileName = `w-${AWS_POSTS_BIG_SIZE_WIDTH}-h-${AWS_POSTS_BIG_SIZE_HEIGHT}-${fileName}`;
                        const smallSizeUrl = baseUrl + '/' + smallSizeFileName;
                        const bigSizeUrl = baseUrl + '/' + bigSizeFileName;

                        setNewPostData({
                            ...newPostData,
                            ...{
                                files: [
                                    ...(newPostData?.files?.length ? newPostData.files : []),
                                    {
                                        imageSmallUrl: smallSizeUrl,
                                        imageLargeUrl: bigSizeUrl,
                                        originUrl: originUrlVideo || res?.data?.link,
                                        type: originUrlVideo ? 'video' : 'image',
                                    },
                                ],
                            },
                        });

                        setOriginUrlVideo('');
                        dispatch(loaderAction.setActiveGifLoader(false));
                    } else {
                        dispatch(loaderAction.setActiveGifLoader(false));
                        openCropModal(res?.data?.link);
                    }
                } else {
                    dispatch(loaderAction.setActiveGifLoader(false));
                    dispatch(alertAction.setMessage('', res?.error || 'Error uploading file'));
                    return false;
                }
            });
        } catch (error) {
            sendErrToSentry(error);
            dispatch(loaderAction.setActiveGifLoader(false));
            return false;
        }

        return true;
    };

    const uploadVideo = async (file) => {
        if (!checkUploadedVideo(file)) {
            dispatch(
                alertAction.setMessage('Video must be in mp4 or mov format. Maximum image size - 50 MB.', 'Wrong file'),
            );
            return false;
        }

        dispatch(loaderAction.setActiveGifLoader(true));

        try {
            awsUploadFile(file, file?.name, active?.external_id, AWS.POSTS_BUCKET).then((res) => {
                if (res?.status && res?.success) {
                    dispatch(loaderAction.setActiveGifLoader(false));
                    setOriginUrlVideo(res?.data?.link);
                    openCropModal(res?.data?.thumb_link);
                } else {
                    dispatch(loaderAction.setActiveGifLoader(false));
                    dispatch(alertAction.setMessage('', res?.error || 'Error uploading file'));
                    return false;
                }
            });
        } catch (error) {
            sendErrToSentry(error);
            dispatch(loaderAction.setActiveGifLoader(false));
            return false;
        }

        return true;
    };

    const uploadMedia = async (file, isCropped = false) => {
        if (!file) return false;

        if (newPostData?.files?.length === 5) {
            dispatch(alertAction.setMessage('', 'You can add no more than 5 media files'));
            return false;
        }

        if (file.type.split('/')[0] === ATTACH.TYPE_IMAGE) {
            await uploadImage(file, isCropped);
        } else if (file.type.split('/')[0] === ATTACH.TYPE_VIDEO) {
            await uploadVideo(file);
        }
    };

    const deleteUploadedImage = (smallImg) => {
        setNewPostData({
            ...newPostData,
            ...{ files: newPostData?.files?.filter((el) => el?.imageSmallUrl !== smallImg) },
        });
        updatedNumberUploadedImage('decrease');
    };

    const moveImage = (files) => {
        setNewPostData({ ...newPostData, ...{ files } });
    };

    const updatedNumberUploadedImage = (type = 'increase') => {
        if (type === 'decrease') {
            setNumberUploadedImg(numberUploadedImg - 1);
        } else {
            setNumberUploadedImg(numberUploadedImg + 1);
        }
    };

    const handleDescription = (value) => {
        setNewPostData({ ...newPostData, ...{ text: value } });
    };

    const addEmojiDescription = (emoji) => {
        const newValue = `${newPostData?.text}${emoji.native}`;

        if (newValue?.length <= POSTS.POST_TEXT_MAX_LENGTH) {
            handleDescription(newValue);
        }
    };

    // Check before save or edit post
    const checkValidPost = () => {
        if (isLoadingRequest) return false;

        if (!newPostData?.text?.trim()?.length || !newPostData?.files?.length) {
            dispatch(
                alertAction.setMessage(
                    'The description field must not be empty and the post must contain at least 1 image',
                    'Warning!',
                ),
            );
            return false;
        }

        return true;
    };

    const saveCreatePost = ({ expectedStatus = POSTS.STATUS_PROCESS }) => {
        if (!checkValidPost()) return false;
        setIsLoadingRequest(true);

        new PostsApi()
            .createPost({
                status: expectedStatus,
                womanId: +active?.external_id,
                text: newPostData?.text,
                files: newPostData?.files,
            })
            .then((res) => {
                if (res?.status) {
                    //Після створення поста (+draft) направляємо юзера в розділ In progress
                    onGetAllPosts({ status: expectedStatus });
                    setActiveFilterStatus(expectedStatus);

                    if (isCreateAnother) {
                        setNewPostData(initNewPostData);
                        setIsCreateAnother(false);
                    } else {
                        closeCreateModal();
                    }
                } else {
                    dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                }
            })
            .finally(() => setIsLoadingRequest(false));
    };

    const saveEditPost = ({ expectedStatus = POSTS.STATUS_PROCESS }) => {
        if (!newPostData?.id) return false;
        if (!checkValidPost()) return false;
        setIsLoadingRequest(true);

        if (!!newPostData?.text?.length && !!newPostData?.files?.length) {
            new PostsApi()
                .editPost({
                    status: expectedStatus,
                    id: newPostData?.id,
                    text: newPostData?.text,
                    files: newPostData?.files,
                })
                .then((res) => {
                    if (res?.status) {
                        //Після редагування поста направляємо юзера в розділ In progress
                        onGetAllPosts({ status: expectedStatus });
                        setActiveFilterStatus(expectedStatus);

                        closeCreateModal();
                        if (closeViewModal) closeViewModal();
                    } else {
                        dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                    }
                })
                .finally(() => setIsLoadingRequest(false));
        }
    };

    // We move from the approved status to the hidden status
    const hidePost = (postData) => {
        if (!postData?.id || postData?.status !== POSTS.STATUS_APPROVE) return false;

        new PostsApi()
            .editPost({
                status: POSTS.STATUS_HIDDEN,
                id: postData?.id,
            })
            .then((res) => {
                if (res?.status) {
                    //Після скриття поста направляємо юзера в розділ Hidden
                    onGetAllPosts({ status: POSTS.STATUS_HIDDEN });
                    setActiveFilterStatus(POSTS.STATUS_HIDDEN);

                    if (closeViewModal) closeViewModal();
                } else {
                    dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                }
            })
            .finally(() => setIsLoadingRequest(false));
    };

    // We move from the hidden status to the approved status
    const showPost = (postData) => {
        if (!postData?.id || postData?.status !== POSTS.STATUS_HIDDEN) return false;

        new PostsApi()
            .editPost({
                status: POSTS.STATUS_APPROVE,
                id: postData?.id,
            })
            .then((res) => {
                if (res?.status) {
                    //Після скриття поста направляємо юзера в розділ Approved
                    onGetAllPosts({ status: POSTS.STATUS_APPROVE });
                    setActiveFilterStatus(POSTS.STATUS_APPROVE);

                    if (closeViewModal) closeViewModal();
                } else {
                    dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                }
            })
            .finally(() => setIsLoadingRequest(false));
    };

    const deleteDraft = () => {
        if (!newPostData?.id) return false;

        new PostsApi().postDeleteDraft(newPostData?.id).then((res) => {
            if (res?.status) {
                const updatedPage = postsData?.length === 1 && postPage !== 1 ? postPage - 1 : postPage;
                onGetAllPosts({ status: activeFilterStatus, page: updatedPage });
                closeCreateModal();
            } else {
                dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
            }
        });
    };

    const onDeleteDraft = () => {
        dispatch(
            alertAction.setCustomConfirmAlert({
                type: ALERTS.SET_CUSTOM_CONFIRM_ALERT,
                title: `Are you sure you want to delete this draft?`,
                btnName1: 'No',
                btnName2: 'Yes',
                successCallback: () => deleteDraft(),
            }),
        );
    };

    const toggleIsCreateAnother = () => {
        setIsCreateAnother(!isCreateAnother);
    };

    //REQUEST TO RECEIVE ALL POSTS
    const onGetAllPosts = ({ status = 0, page = 1 }) => {
        if (active?.external_id) {
            dispatch(loaderAction.setActiveGifLoader(true));

            new PostsApi()
                .getAllPosts({
                    womanExternalId: +active?.external_id,
                    ...(status ? { status } : {}),
                    page,
                    count: POSTS.POST_COUNT,
                })
                .then((res) => {
                    dispatch(loaderAction.setActiveGifLoader(false));

                    if (res?.status) {
                        dispatch(postsAction.getAllPosts(res?.data));
                        setPostPage(res?.data?.page);
                        setIsBlockPost(false);
                        if (postScrollRef.current) postScrollRef.current.scrollTop = 0;
                    } else {
                        dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                        if (res?.data?.isBlockPost) setIsBlockPost(true);
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        }
    };

    // START pagination logic =========================================================================
    const handleChangePostPage = (event, activePage) => {
        if (activePage && postPage !== activePage) {
            onGetAllPosts({ status: activeFilterStatus, page: activePage });
        }
    };

    const switchCountAllPages = () => {
        switch (activeFilterStatus) {
            case POSTS.STATUS_PROCESS:
                return Math.ceil(postsState?.countStatuses?.process / POSTS.POST_COUNT);
            case POSTS.STATUS_APPROVE:
                return Math.ceil(postsState?.countStatuses?.approve / POSTS.POST_COUNT);
            case POSTS.STATUS_DECLINE:
                return Math.ceil(postsState?.countStatuses?.decline / POSTS.POST_COUNT);
            case POSTS.STATUS_REWORK:
                return Math.ceil(postsState?.countStatuses?.rework / POSTS.POST_COUNT);
            case POSTS.STATUS_DRAFT:
                return Math.ceil(postsState?.countStatuses?.draft / POSTS.POST_COUNT);
            case POSTS.STATUS_HIDDEN:
                return Math.ceil(postsState?.countStatuses?.hide / POSTS.POST_COUNT);

            default:
                return Math.ceil(postsState?.allCount / POSTS.POST_COUNT);
        }
    };

    // END pagination logic ===========================================================================

    useEffect(() => {
        if (active.external_id) {
            onGetAllPosts({ status: activeFilterStatus, page: postPage });
        } else {
            dispatch(postsAction.clearPosts());
        }
    }, [active.external_id]);

    useEffect(() => {
        setPostsData(postsState.posts);
    }, [postsState.posts]);

    useEffect(() => {
        if (numberUploadedImg !== newPostData?.files?.length) {
            dispatch(loaderAction.setActiveGifLoader(true));
        } else {
            dispatch(loaderAction.setActiveGifLoader(false));
        }
    }, [numberUploadedImg, newPostData.files]);

    return {
        createModalType,
        openCreateModal,
        closeCreateModal,
        isCropModal,
        openCropModal,
        closeCropModal,
        initPhotoURL,

        activeFilterStatus,
        onSetActiveFilterStatus,
        allCount: postsState?.allCount,
        countStatuses: postsState?.countStatuses,
        postsData,
        newPostData,
        uploadMedia,
        deleteUploadedImage,
        moveImage,
        updatedNumberUploadedImage,
        handleDescription,
        addEmojiDescription,
        activeProfile: active,
        saveCreatePost,
        isCreateAnother,
        toggleIsCreateAnother,
        saveEditPost,
        hidePost,
        showPost,
        onDeleteDraft,
        createImgRowRef,
        postScrollRef,
        postPage,
        handleChangePostPage,
        isBlockPost,
        switchCountAllPages,
    };
}
