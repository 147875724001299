import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SwitchLetterAttachment from '../../common/SwitchLetterAttachment';
import * as mailboxAction from '../../../store/actions/mailbox';
import MailboxApi from '../../../services/api/MailboxApi';
import * as IMAGES from '../../common/Images';
import { settings } from '../../../services/slick-carousel-config/settings';
import { objectToArray } from '../../../services/methods';
import { formatDate, getLastOnlineMessage } from '../../../services/time';
import useHandleLettersListItem from '../../../hooks/letters/useHandleLettersListItem';
import useHandleLettersListDraft from '../../../hooks/letters/useHandleLettersListDraft';
import Modal from 'react-modal';
import modalWriteLetter from '../../../styles/react-modal/modalWriteLetter.style';
import FormDraftsLetters from '../LettersNewChat/FormDraftsLetters';
import Loader from '../../common/LoaderGif';
import { sendErrToSentry } from '../../../services/sentry';

export default ({ item, currentPage, setCurrentPage }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [activeDelete, setActiveDelete] = useState(false);
    const [customItem, setCustomItem] = useState(null);
    const [freeze, setFreeze] = useState(null);
    const profilesState = useSelector((state) => state.profiles);
    const menuState = useSelector((state) => state.menu);
    const letterFilterState = useSelector((state) => state.letterFilter);
    const mailboxState = useSelector((state) => state.mailbox);
    const lettersMenuType = useSelector((state) => state.menu.letters);

    const isDrafts = menuState?.letters === 'drafts';
    const isOperator = isDrafts ? !+customItem?.operator : !!+customItem?.operator;
    const isUserDelete =
        (!isOperator && customItem?.hide_for_sender === 1) || (isOperator && customItem?.hide_for_recipient === 1);

    const handleLettersListDraft = useHandleLettersListDraft(item, currentPage, setCurrentPage);
    const LAST_ONLINE = getLastOnlineMessage(
        isDrafts
            ? customItem?.last_online
            : isOperator
              ? customItem?.recipient_last_online
              : customItem?.sender_last_online,
    );
    const handleLettersListItem = useHandleLettersListItem(customItem, isDrafts, handleLettersListDraft.openChat);

    const setTrashLetter = () => {
        setActiveDelete(true);
        if (menuState.letters === 'trash') {
            try {
                new MailboxApi()
                    .postRestoreMail({
                        userId: +customItem?.operator ? customItem.sender_id : customItem.recipient_id,
                        mailId: customItem?.id,
                        outbox: +customItem?.operator ? true : '',
                    })
                    .then((res) => {
                        if (res?.status) {
                            dispatch(
                                mailboxAction.getMailsWithoutReload(
                                    {
                                        userId: profilesState.active.external_id
                                            ? profilesState.active.external_id
                                            : '',
                                        folder: menuState.letters,
                                        manFilter: letterFilterState.manFilter,
                                        manId: letterFilterState.manId,
                                        letterFilter: letterFilterState.lettersFilter,
                                        onlineFilter: lettersMenuType === 'trash' ? '' : letterFilterState.onlineFilter,
                                        page: mailboxState.mailList?.length === 1 ? currentPage - 1 : currentPage,
                                    },
                                    () => mailboxState.mailList?.length === 1 && setCurrentPage(currentPage - 1),
                                ),
                            );
                            dispatch(mailboxAction.getUnreadCount(customItem.recipient_id));
                        }
                    })
                    .finally(() => setActiveDelete(false));
            } catch (error) {
                sendErrToSentry(error);
            }
        } else {
            if (isDrafts) handleLettersListDraft.deleteDraft(setActiveDelete);
            else {
                try {
                    new MailboxApi()
                        .postTrashMail({
                            userId: +customItem?.operator ? customItem.sender_id : customItem.recipient_id,
                            mailId: customItem?.id,
                            outbox: +customItem?.operator ? true : '',
                        })
                        .then((res) => {
                            if (res?.status) {
                                dispatch(
                                    mailboxAction.getMailsWithoutReload(
                                        {
                                            userId: profilesState.active.external_id
                                                ? profilesState.active.external_id
                                                : '',
                                            folder: menuState.letters,
                                            manFilter: letterFilterState.manFilter,
                                            manId: letterFilterState.manId,
                                            letterFilter: letterFilterState.lettersFilter,
                                            onlineFilter: letterFilterState.onlineFilter,
                                            page: mailboxState.mailList?.length === 1 ? currentPage - 1 : currentPage,
                                        },
                                        () => mailboxState.mailList?.length === 1 && setCurrentPage(currentPage - 1),
                                    ),
                                );
                                dispatch(mailboxAction.getUnreadCount(customItem.recipient_id));
                            }
                        })
                        .finally(() => setActiveDelete(false));
                } catch (error) {
                    sendErrToSentry(error);
                }
            }
        }
    };

    useEffect(() => {
        setCustomItem(isDrafts ? item?.draft : item?.mail);
        setFreeze(isDrafts ? !+item?.draft?.letter_limit : !+item?.mail?.letter_limit);
    }, [item]);

    return customItem?.id ? (
        <div
            className={`${styles.letters_list_item} ${!profilesState?.active?.external_id || isDrafts ? styles.with_woman : ''}`}
            data-testid={`letter-item-${item?.mail?.id}`}
        >
            <div
                className={`${styles.letters_list_item_main} ${!+customItem?.mail_read_status && menuState.letters === 'inbox' && !isOperator ? styles.unread : ''}`}
                onClick={handleLettersListItem.openChat}
            >
                <div className={styles.letters_list_item_top}>
                    <div className={styles.letters_list_item_man_profile}>
                        <div
                            className={`${styles.letters_list_item_man_photo_wrap} ${LAST_ONLINE ? '' : styles.online}`}
                            data-testid="letter-man-photo"
                        >
                            <img
                                src={
                                    isOperator
                                        ? (customItem?.recipient_image ?? IMAGES.profile_img_empty)
                                        : (customItem?.sender_image ?? IMAGES.profile_img_empty)
                                }
                                alt=""
                                className={styles.letters_list_item_man_photo}
                            />
                        </div>
                        <div className={styles.letters_list_item_man_info}>
                            <div
                                className={`${styles.letters_list_item_man_last_online} ${LAST_ONLINE?.status ? styles[LAST_ONLINE?.status] : ''}`}
                                data-testid="letter-man-online-status"
                            >
                                {!LAST_ONLINE
                                    ? 'Online' //(`ID ${isOperator === 1 ? customItem?.recipient_id : customItem?.sender_id}`)
                                    : `Seen ${LAST_ONLINE?.message ?? ''}`}
                            </div>
                            {/* } */}
                            <div className={styles.letters_list_item_man_name} data-testid="letter-man-name">
                                {`${isOperator ? customItem?.recipient_name : customItem?.sender_name}, ${isOperator ? customItem?.recipient_age : customItem?.sender_age}`}
                            </div>
                            <div className={styles.letters_list_item_man_place} data-testid="letter-man-place">
                                {isDrafts ? (
                                    <img src={customItem?.recipient_country_icon} alt="" />
                                ) : (
                                    <img
                                        src={`https://alpha.date/assets/flags/${isOperator ? customItem?.recipient_country_code?.toLowerCase() : customItem?.sender_country_code?.toLowerCase()}.jpg`}
                                        alt=""
                                    />
                                )}
                                <span>
                                    {`${isOperator ? customItem?.recipient_country_name : customItem?.sender_city}`}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.letters_list_item_middle}>
                        <div
                            className={
                                isOperator ? styles.letters_list_item_text : styles.letter_list_item_text_from_man
                            }
                            data-testid="letter-content"
                        >
                            <b>{!!isOperator && `You: `}</b>
                            {customItem?.message_content ? customItem?.message_content : ''}
                        </div>
                        <div className={styles.letters_list_item_status}>
                            <div className={styles.letters_list_item_status_title}>Status</div>
                            {freeze && (
                                <div className={`${styles.letters_list_item_status_freeze}`}>
                                    <img src={IMAGES.freeze_cube_full} alt="" />
                                </div>
                            )}
                            <div
                                className={`${styles.letters_list_item_status_name} ${isOperator ? styles.answered : +customItem?.answered ? styles.answered : styles.unanswered}`}
                                data-testid="letter-status"
                            >
                                {isOperator ? 'Answered' : +customItem?.answered ? 'Answered' : 'Unanswered'}
                            </div>

                            {isUserDelete && (
                                <div
                                    className={`${styles.letters_list_item_status_name} ${styles.delete}`}
                                    data-testid="letter-status-delete"
                                >
                                    Deleted by user
                                </div>
                            )}
                        </div>
                        <div className={styles.letters_list_item_time} data-testid="letter-date">
                            {formatDate(customItem?.date_created)}
                        </div>
                    </div>
                    {!profilesState?.active?.external_id && (
                        <div className={styles.letters_list_item_woman_profile}>
                            <div className={styles.letters_list_item_woman_info}>
                                <div
                                    className={styles.letters_list_item_woman_id}
                                    data-testid="letter-woman-id"
                                >{`ID ${isOperator ? customItem?.sender_id : customItem?.recipient_id}`}</div>
                                <div
                                    className={styles.letters_list_item_woman_name}
                                    data-testid="letter-woman-name"
                                >{`${isOperator ? customItem?.sender_name : customItem?.recipient_name},  ${isOperator ? customItem?.sender_age : customItem?.recipient_age}`}</div>
                                <div className={styles.letters_list_item_woman_place} data-testid="letter-woman-place">
                                    <img
                                        src={`/assets/flags/${isOperator ? customItem?.sender_country_code?.toLowerCase() : customItem?.recipient_country_code?.toLowerCase()}.jpg`}
                                        alt=""
                                    />
                                    <span>{isOperator ? customItem?.sender_city : customItem?.recipient_city}</span>
                                </div>
                            </div>
                            <div
                                className={`${styles.letters_list_item_woman_photo_wrap} ${+customItem.recipient_online ? styles.online : ''}`}
                                data-testid="letter-woman-photo"
                            >
                                <img
                                    src={
                                        isOperator
                                            ? (customItem?.sender_image ?? IMAGES.profile_img_empty)
                                            : (customItem?.recipient_image ?? IMAGES.profile_img_empty)
                                    }
                                    alt=""
                                    className={styles.letters_list_item_woman_photo}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {item?.attachments && objectToArray(item?.attachments)?.length > 0 ? (
                    <div className={styles.letters_list_item_bottom} data-testid="letter-attachments">
                        <div className={styles.letters_list_item_media_slider}>
                            <Slider {...settings}>
                                {objectToArray(item.attachments).map((attach, key) => (
                                    <SwitchLetterAttachment
                                        data={attach}
                                        key={key + attach?.attachment_id + item?.id}
                                        index={key}
                                        allData={objectToArray(item.attachments)}
                                    />
                                ))}
                            </Slider>
                        </div>
                    </div>
                ) : (
                    <div />
                )}
            </div>
            <div className={styles.letters_list_item_options}>
                <div
                    className={styles.letters_list_item_options_item}
                    onClick={handleLettersListItem.openChat}
                    data-testid="letter-reply-btn"
                >
                    <img src={IMAGES.letter_option_answer} alt="" />
                </div>
                <div
                    className={styles.letters_list_item_options_item}
                    onClick={() => history.push(`/profile-man/${customItem[isDrafts ? 'recipient_id' : 'male_id']}`)}
                    data-testid="letter-profile-btn"
                >
                    <img src={IMAGES.letter_option_profile} alt="" />
                </div>
                {!activeDelete ? (
                    <div
                        className={styles.letters_list_item_options_item}
                        onClick={setTrashLetter}
                        data-testid="letter-restore-delete-btn"
                    >
                        {menuState.letters === 'trash' ? (
                            <img src={IMAGES.letter_option_restore} alt="" />
                        ) : (
                            <img src={IMAGES.letter_option_delete} alt="" />
                        )}
                    </div>
                ) : (
                    <div className={styles.letters_list_item_options_item}>
                        <Loader mainStyle={{ left: 0, right: 0, top: 0, height: '100%', width: '100%' }} />
                    </div>
                )}

                {/* <div className={styles.letters_list_item_options_item}>
                    <img src={IMAGES.letter_option_restore} alt=""/>
                </div>
                <div className={styles.letters_list_item_options_item}>
                    <img src={IMAGES.letter_option_chat} alt=""/>
                </div> */}
            </div>
            <Modal
                style={modalWriteLetter}
                isOpen={handleLettersListDraft.modalWRLIsOpen}
                onRequestClose={handleLettersListDraft.closeWriteLetterModal}
                ariaHideApp={false}
            >
                <FormDraftsLetters
                    {...handleLettersListDraft}
                    sender={handleLettersListDraft.sender}
                    closeWriteLetterModal={handleLettersListDraft.closeWriteLetterModal}
                />
            </Modal>
        </div>
    ) : (
        <div />
    );
};
