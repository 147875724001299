import React, { useRef } from 'react';

import ActiveProfile from './ActiveProfile';
import ProfilesList from './ProfilesList';
import MainMenu from './MainMenu';
import BottomMenu from './BottomMenu';

import useHandleMultiChat from '../../hooks/side-menu/useHandleMultiChat';
import useHandleMainMenu from '../../hooks/side-menu/useHandleMainMenu';
import useHandleBottomMenu from '../../hooks/side-menu/useHandleBottomMenu';

import * as IMAGES from '../common/Images';
import Styles from './styles.module.scss';
import useOutsideClicker from '../../hooks/dom/useOutsideClicker';

export default function SideMenu() {
    const multiActiveBlockRef = useRef();
    const multiProfileListBlockRef = useRef();
    const bottomMenuBlockRef = useRef();

    const handleMultiChat = useHandleMultiChat();
    const handleMainMenu = useHandleMainMenu();
    const handleBottomMenu = useHandleBottomMenu();

    useOutsideClicker(multiActiveBlockRef, handleMultiChat.closeMultiMultiBlock, multiProfileListBlockRef);
    useOutsideClicker(bottomMenuBlockRef, handleBottomMenu.closeBottomMenu);

    return (
        <div className={`${Styles.clmn_1} clmn_1`}>
            <div className={Styles.clmn_1_logo} data-testid="main-logo">
                <img src={IMAGES.logo} alt="" />
            </div>
            {handleMultiChat?.isActiveProfileExist ? (
                <ActiveProfile {...handleMultiChat} multiActiveBlockRef={multiActiveBlockRef} />
            ) : (
                <ProfilesList {...handleMultiChat} multiProfileListBlockRef={multiProfileListBlockRef} />
            )}
            <MainMenu {...handleMainMenu} />
            <BottomMenu {...handleBottomMenu} bottomMenuBlockRef={bottomMenuBlockRef} />
        </div>
    );
}
