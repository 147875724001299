import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as senderAction from '../../store/actions/sender';

export default function useHandleSenderLeftBar() {
    const dispatch = useDispatch();
    const profilesState = useSelector((state) => state.profiles);
    const senderState = useSelector((state) => state.sender);

    useEffect(() => {
        if (profilesState?.active?.external_id)
            dispatch(senderAction.setActiveSenderProfile(+profilesState?.active?.external_id));
    }, [profilesState.active]);

    return {
        profilesList: profilesState?.list?.length > 0 ? profilesState?.list : [],
        activeSenderProfile: senderState?.activeProfile,
        setActiveProfile: (externalID) => {
            if (senderState?.activeProfile === externalID) return;
            dispatch(senderAction.setActiveSenderProfile(externalID));
        },
    };
}
