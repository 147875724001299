import React, { useState } from 'react';
import Modal from 'react-modal';

import { addBlur, removeBlur } from '../../../services/dom/letters';
import modalContentAttach from '../../../styles/react-modal/modalContent.style';
import ContentAttachModal from '../ContentAttachModal/ImgContent';
import VideoContentAttachModal from '../ContentAttachModal/VideoContent';
// import { cutLengthString } from '../../../services/methods';
// import * as LENGTH from '../../../constants/length';
import LetterAudioPlayer from '../LetterAudioPlayer';
import * as AWS from '../../../constants/aws';
// import * as IMAGES from '../Images';

export default function AttachDataToLetter({ item, deleteAttach, index }) {
    const [modalImgIsOpen, setModalImgIsOpen] = useState(false);
    const [modalVideoIsOpen, setModalVideoIsOpen] = useState(false);
    const [currItem, setCurrItem] = useState({});

    if (item?.message_type === 'SENT_IMAGE') {
        var thumbList = {};
        AWS.AWS_THUMBS.forEach((size) => {
            let thumb = item.link.split('/');
            thumb[thumb.length - 1] = `w-${size.width}-h-${size.height}-${thumb[thumb.length - 1]}`;
            thumb = thumb.join('/');
            thumbList[size.name] = thumb;
        });
        const openContentModal = () => {
            addBlur();
            setCurrItem({
                link: thumbList.big,
                attachment_payed: '1',
            });
            setModalImgIsOpen(true);
        };
        const closeContentModal = () => {
            removeBlur();
            setCurrItem({});
            setModalImgIsOpen(false);
        };
        return (
            <React.Fragment>
                <div className="letters_attach_files_item file_photo" key={index} id={`attach-${index}`}>
                    <img src={thumbList.small} alt="" onClick={openContentModal} />
                    <div className="c3_write_msg_textareas_files_close" data-id={index} onClick={deleteAttach} />
                </div>
                <Modal
                    style={modalContentAttach}
                    isOpen={modalImgIsOpen}
                    onRequestClose={closeContentModal}
                    ariaHideApp={false}
                >
                    <ContentAttachModal item={currItem} closeModal={closeContentModal} />
                </Modal>
            </React.Fragment>
        );
    } else if (item?.message_type === 'SENT_VIDEO') {
        const openContentModal = () => {
            addBlur();
            setCurrItem({
                link: item.link,
                attachment_payed: '1',
            });
            setModalVideoIsOpen(true);
        };

        const closeContentModal = () => {
            removeBlur();
            setCurrItem({});
            setModalVideoIsOpen(false);
        };

        return (
            <React.Fragment>
                <div className="letters_attach_files_item file_video" key={index} id={`attach-${index}`}>
                    <div className="file_video_inner_wrap" onClick={openContentModal}>
                        <video
                            width="96"
                            height="56"
                            id={'video-' + item.id}
                            controls={false}
                            preload="metadata"
                            playsInline
                        >
                            <source src={`${item.link}#t=0.6`} type="video/mp4" />
                        </video>
                    </div>
                    <div className="c3_write_msg_textareas_files_close" data-id={index} onClick={deleteAttach} />
                </div>
                <Modal
                    style={modalContentAttach}
                    isOpen={modalVideoIsOpen}
                    onRequestClose={closeContentModal}
                    ariaHideApp={false}
                >
                    <VideoContentAttachModal item={currItem} closeModal={closeContentModal} />
                </Modal>
            </React.Fragment>
        );
    } else if (item?.message_type === 'SENT_AUDIO') {
        return (
            <div className="letters_attach_files_item file_audio" key={index} id={`attach-${index}`}>
                <div className="file_audio_wrap">
                    <div className="letters_attach_files_item_audio_control">
                        {/*<div className="letters_attach_files_item_play_title">*/}
                        {/*    <img src={IMAGES.letter_audio_notes} alt="" />*/}
                        {/*    <span>{cutLengthString(item.title, LENGTH.ATTACH_LETTER_AUDIO_TITLE)}</span>*/}
                        {/*</div>*/}
                        <LetterAudioPlayer src={item.link} />
                    </div>
                    <div className="c3_write_msg_textareas_files_close" data-id={index} onClick={deleteAttach} />
                </div>
            </div>
        );
    } else {
        return <div />;
    }
}
