import React, { useState } from 'react';
import Item from './Item';
import Styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Filter from './Filter';
import useHandleChatFilters from '../../../../hooks/chat/useHandleChatFilters';
import LoaderGif from '../../../common/LoaderGif';
import useHandleMainMenu from '../../../../hooks/side-menu/useHandleMainMenu';
import * as MENU from '../../../../constants/menu';
import { useLocation } from 'react-router-dom';
import * as chatsAction from '../../../../store/actions/chats';
import { getChatListType } from '../../../../services/methods';
import * as IMAGES from '../../../common/Images';

export default () => {
    const currentChatState = useSelector((state) => state.currentChat);
    const chatsState = useSelector((state) => state.chats);
    const profilesState = useSelector((state) => state.profiles);

    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    const handleChatFilters = useHandleChatFilters();
    const handleMainMenu = useHandleMainMenu();
    const dispatch = useDispatch();
    const location = useLocation();

    const onLoadMore = () => {
        setLoadMoreLoading(true);
        dispatch(
            chatsAction.addChatListWithPage({
                user_id: +profilesState?.active?.external_id ? profilesState?.active?.external_id : '',
                isOnline: chatsState?.filterOnline,
                searchItem: chatsState?.filterSearch,
                limits: chatsState?.limits,
                freeze: chatsState?.filterFreeze,
                chatType: getChatListType(location?.pathname),
                page: +chatsState?.page,
            }),
        ).finally(() => setLoadMoreLoading(false));
    };

    return (
        <>
            <Filter
                {...handleChatFilters}
                isFreeze={chatsState?.filterFreeze}
                setFreeze={handleChatFilters?.handleFreeze}
            />
            <div className={Styles.clmn_2_chat_folders}>
                <div
                    className={`${Styles.clmn_2_chat_folder_item} ${handleMainMenu.activeFolder === MENU.MENU_CHAT ? Styles.active : ''}`}
                    onClick={() => {
                        handleMainMenu.openFolder(MENU.MENU_CHAT);
                    }}
                >
                    All <span>chats</span>
                </div>
                <div
                    className={`${Styles.clmn_2_chat_folder_item} ${handleMainMenu.activeFolder === MENU.MENU_UNANSWERED ? Styles.active : ''}`}
                    onClick={() => {
                        handleMainMenu.openFolder(MENU.MENU_UNANSWERED);
                    }}
                >
                    Unanswered
                </div>
                <div
                    className={`${Styles.clmn_2_chat_folder_item} ${handleMainMenu.activeFolder === MENU.MENU_FAVORITE ? Styles.active : ''}`}
                    onClick={() => {
                        handleMainMenu.openFolder(MENU.MENU_FAVORITE);
                    }}
                >
                    Favorites
                </div>
                <div
                    className={`${Styles.clmn_2_chat_folder_item} ${handleMainMenu.activeFolder === MENU.MENU_DIALOGUES ? Styles.active : ''}`}
                    onClick={() => {
                        handleMainMenu.openFolder(MENU.MENU_DIALOGUES);
                    }}
                >
                    Dialogues
                </div>
            </div>
            <div className={`${Styles.clmn_2_chat_block}`}>
                <div className={Styles.clmn_2_chat_block_head}>
                    <div className={Styles.clmn_2_chat_block_title}>{!chatsState?.filterFreeze ? 'All' : 'Active'}</div>
                </div>
                <div className={Styles.clmn_2_chat_block_list} data-testid="chat-list">
                    {chatsState?.list?.length
                        ? handleChatFilters
                              .pinFilter(chatsState?.list)
                              .filter((item) => !+item?.female_block)
                              .filter((item) => {
                                  if (chatsState?.filterFreeze) {
                                      return (
                                          (!!+item?.like_limit && !+item?.like) ||
                                          item?.message_limit > 0 ||
                                          item?.letter_limit > 0
                                      );
                                  } else return true;
                              })
                              .map((item, key) => (
                                  <Item
                                      key={key}
                                      item={item}
                                      isInActive={true}
                                      isCurrentChat={currentChatState?.info?.chat_uid === item?.chat_uid}
                                      womanProfile={profilesState?.list.find(
                                          (profile) => +profile?.external_id === +item?.female_external_id,
                                      )}
                                  />
                              ))
                        : chatsState?.isLoading && <LoaderGif />}
                    {!!(chatsState?.list?.length && !chatsState?.isLoadedAll && !chatsState?.isLoading) &&
                        (loadMoreLoading ? (
                            <div className={Styles.clmn_2_chat_loadmore_btn}>
                                <img src={IMAGES.loading_GIF} alt="" />
                                <span>Loading...</span>
                            </div>
                        ) : (
                            <div className={Styles.clmn_2_chat_loadmore_btn} onClick={onLoadMore}>
                                <img src={IMAGES.arrow_white} alt="" />
                                <span>Load more</span>
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};
