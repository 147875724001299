import Base from '../Base';

export default class TransactionApi extends Base {
    change(data) {
        return super
            .post('v3/transaction/update-personal-invite', data)
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    get() {
        return super
            .get('v3/transaction/personal-invites-list')
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }
}
