import { useRef, useState } from 'react';
import useOutsideClicker from '../../dom/useOutsideClicker';

export default function useHandleStickerBlock() {
    const stickerBoxRef = useRef(null);
    const [isStickerBlock, setIsStickerBlock] = useState(false);

    const openStickerBlock = () => {
        setIsStickerBlock(true);
    };

    const closeStickerBlock = () => {
        setIsStickerBlock(false);
    };

    useOutsideClicker(stickerBoxRef, setIsStickerBlock);

    return {
        isStickerBlock,
        openStickerBlock,
        closeStickerBlock,
        stickerBoxRef,
    };
}
