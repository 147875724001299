import React from 'react';
import styles from '../../styles.module.scss';
import * as IMAGES from '../../../../../common/Images';
import { useDispatch } from 'react-redux';
import * as modalsAction from '../../../../../../store/actions/modals';

export default function AudioItem({ filename, link }) {
    const dispatch = useDispatch();

    const openAudioModal = () => {
        dispatch(
            modalsAction.openAudioContentModal({
                link: link,
                attachment_payed: '1',
            }),
        );
    };

    return (
        <div className={`${styles.drafts_table_item_attach} ${styles.audio}`} onClick={openAudioModal}>
            <img src={IMAGES.sender_history_audio_play} alt="" />
            <span>{filename}</span>
        </div>
    );
}
