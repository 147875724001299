import React, { useState } from 'react';
import DnDContainer from '../DragAndDrop/Container';
import modalContentAttach from '../../../../styles/react-modal/modalContent.style';
import VideoContentAttachModal from '../../ContentAttachModal/VideoContent';
import Modal from 'react-modal';
import { removeBlur } from '../../../../services/dom/letters';
import Loader from '../Loader';

export default ({ type, videos, status, userId }) => {
    const [modalContentIsOpen, setModalContentIsOpen] = useState(false);
    const [currItem, setCurrItem] = useState({});

    const closeContentModal = () => {
        removeBlur();
        setCurrItem({});
        setModalContentIsOpen(false);
    };

    return (
        <div className="upload_popup_tabs_content_middle video" id="uptcmv">
            <DnDContainer
                userId={userId}
                type={type}
                data={videos}
                setModalContentIsOpen={setModalContentIsOpen}
                setCurrItem={setCurrItem}
            />
            <Loader status={status} />
            <Modal
                style={modalContentAttach}
                isOpen={modalContentIsOpen}
                onRequestClose={closeContentModal}
                ariaHideApp={false}
            >
                <VideoContentAttachModal item={currItem} closeModal={closeContentModal} />
            </Modal>
        </div>
    );
};
