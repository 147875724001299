import React, { useState } from 'react';
import { cutLengthString, getReadableFileSizeString } from '../../../../services/methods';
import * as LENGTH from '../../../../constants/length';
// import * as REGEXP from "../../../../constants/regExp";

export default ({ closeModalVideo, uploadVideo, setVideoFile, videoFile, handleUploadVideo }) => {
    const [videoName, setVideoName] = useState('');

    // const handleVideoName = (e) => {
    // 	if(REGEXP.ATTACH_FILENAME.test(e.target.value[e.target.value.length - 1])) {
    // 		setVideoName(e.target.value);
    // 	}
    // }

    const onSubmitVideo = () => {
        uploadVideo({ file: videoFile, fileName: videoName.length > 0 ? videoName : videoFile.name.split('.')[0] });

        closeModalVideo();
        setVideoFile({});
        setVideoName('');
    };

    return (
        <div className="add_file_popup_wrap">
            <div className="popup_add_file_head">New video</div>
            {videoFile && videoFile.name && videoFile.size ? (
                <React.Fragment>
                    {
                        videoFile && +videoFile.size > LENGTH.MAX_VIDEO_SIZE ? (
                            <div className={'popup_add_file_row oversize'}>
                                File you’ve uploaded is too big <span>(max {LENGTH.MAX_VIDEO_SIZE_TITLE} Mb)</span>
                            </div>
                        ) : null
                        // <div className="popup_add_file_row">
                        // 	<span>Video name</span>
                        // 	<input type="text"
                        // 		   placeholder="Enter video name here"
                        // 		   value={videoName}
                        // 		   required={true}
                        // 		   onChange={handleVideoName}/>
                        // </div>
                    }
                    {+videoFile.size <= LENGTH.MAX_VIDEO_SIZE ? (
                        <div className="popup_add_file_row uploaded">
                            <span>File uploaded</span>
                            <div className="popup_uploaded_info">
                                {cutLengthString(videoFile.name, LENGTH.ATTACH_TITLE_NAME)}
                                <span>({getReadableFileSizeString(videoFile.size)})</span>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                </React.Fragment>
            ) : (
                <div />
            )}
            {videoFile && videoFile.name && videoFile.size && +videoFile.size <= LENGTH.MAX_VIDEO_SIZE ? (
                <div className="popup_add_file_row add_btn">
                    <div
                        className="popup_add_file_upload_btn"
                        onClick={videoFile && videoFile.name ? onSubmitVideo : null}
                    >
                        Add Video
                    </div>
                </div>
            ) : (
                <div className="popup_add_file_row upload_btn">
                    <input type="file" accept="video/*" id="popup_inp_file1" onChange={handleUploadVideo} />
                    <label htmlFor="popup_inp_file1" className="popup_bottom_upload_btn">
                        <img src="../img/popup-upload.svg" alt="" />
                        Upload
                    </label>
                </div>
            )}
            <div className="popup_add_file_close" onClick={closeModalVideo}>
                <img src="/img/cross_white.svg" alt="" />
            </div>
        </div>
    );
};
