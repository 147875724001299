import Base from './Base';

export default class NoticeApi extends Base {
    postCreate({ maleId, femaleId, chatId, nameNotice, textNotice }) {
        return super
            .post(`notice/create`, {
                male_id: maleId,
                female_id: femaleId,
                chat_uid: chatId,
                name: nameNotice,
                description: textNotice,
            })
            .then((res) => res?.data);
    }

    postRead({ maleId, femaleId, chatId }, token = null) {
        return super
            .post(
                `notice/read`,
                {
                    male_id: maleId,
                    female_id: femaleId,
                    chat_uid: chatId,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    postDelete({ noticeId, maleId, femaleId, chatId }) {
        return super
            .post(`notice/delete`, {
                notice_id: noticeId,
                male_id: maleId,
                female_id: femaleId,
                chat_uid: chatId,
            })
            .then((res) => res?.data);
    }

    postEdit({ noticeId, maleId, femaleId, chatId, nameNotice, textNotice }) {
        return super
            .post(`notice/edit`, {
                notice_id: noticeId,
                male_id: maleId,
                female_id: femaleId,
                chat_uid: chatId,
                name: nameNotice,
                description: textNotice,
            })
            .then((res) => res?.data);
    }
}
