import React from 'react';
import styles from './styles.module.scss';
import PostsHeader from './PostsHeader';
import Post from './Post';
import * as IMAGES from '../common/Images';
import CreatePostModal from './CreatePostModal';
import useInitPosts from '../../hooks/posts/useInitPosts';
import ViewPostModal from './ViewPostModal';
import CropModal from '../common/CropModal';
import { Pagination, Stack } from '@mui/material';
import useViewPostModal from '../../hooks/posts/useViewPostModal';

export default function Posts() {
    const viewPostModal = useViewPostModal();
    const initPosts = useInitPosts({ closeViewModal: viewPostModal?.closeViewModal });

    return (
        <div className={styles.posts_page_wrap}>
            {initPosts.activeProfile?.external_id ? (
                <div className={styles.posts_page}>
                    <PostsHeader {...initPosts} />

                    <div className={styles.posts_page_body}>
                        <div className={styles.posts_page_body_inner} ref={initPosts.postScrollRef}>
                            <div className={styles.posts_page_grid}>
                                {!!initPosts?.postsData?.length &&
                                    initPosts?.postsData.map((post) => (
                                        <Post
                                            key={post?.id}
                                            post={post}
                                            {...initPosts}
                                            openViewModal={viewPostModal?.openViewModal}
                                        />
                                    ))}

                                {!!initPosts.switchCountAllPages() && (
                                    <Stack className={styles.posts_page_pagination_wrap} spacing={2}>
                                        <Pagination
                                            count={initPosts.switchCountAllPages()}
                                            variant="outlined"
                                            shape="rounded"
                                            page={initPosts?.postPage}
                                            onChange={initPosts.handleChangePostPage}
                                        />
                                    </Stack>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.posts_page_choose_profile}>
                    Choose profile
                    <img src={IMAGES.choose_profile ?? ''} alt="" width="440px" height="440px" />
                </div>
            )}

            <CreatePostModal {...initPosts} />
            <ViewPostModal
                {...viewPostModal}
                activeProfile={initPosts?.activeProfile}
                showPost={initPosts?.showPost}
                openCreateModal={initPosts?.openCreateModal}
                hidePost={initPosts?.hidePost}
            />
            <CropModal {...initPosts} />
        </div>
    );
}
