import React, { useState } from 'react';
import { getReadableFileSizeString } from '../../../../services/methods';
import * as LENGTH from '../../../../constants/length';
import * as REGEXP from '../../../../constants/regExp';
import * as IMAGES from '../../Images';

export default ({ closeModalAudio, uploadAudio, handleUploadAudio, audioFile, setAudioFile, audios }) => {
    const [audioName, setAudioName] = useState('');

    const handleAudioName = (e) => {
        if (REGEXP.ATTACH_FILENAME.test(e.target.value[e.target.value.length - 1])) {
            setAudioName(e.target.value);
        }
    };

    const onSubmitAudio = () => {
        const fileParts = audioFile?.name?.split('.');
        const fileType = fileParts[fileParts?.length - 1];

        uploadAudio({
            file: audioFile,
            fileName: audioName?.length > 0 ? audioName + '.' + fileType : audioFile?.name,
            index: audios?.length > 0 ? +audios[audios?.length - 1]?.sort_order + 1 : 0,
        });

        closeModalAudio();
        setAudioFile({});
        setAudioName('');
    };

    return (
        <div className="add_file_popup_wrap" data-testid="upload-audio-modal">
            <div className="popup_add_file_head">New audio</div>
            {audioFile && audioFile.name && audioFile.size ? (
                <React.Fragment>
                    {audioFile && +audioFile.size > LENGTH.MAX_AUDIO_SIZE ? (
                        <div className={'popup_add_file_row oversize'}>
                            File you’ve uploaded is too big <span>(max {LENGTH.MAX_AUDIO_SIZE_TITLE}Mb)</span>
                        </div>
                    ) : (
                        <div className="popup_add_file_row">
                            {/*<span>Audio name</span>*/}
                            <input
                                type="text"
                                data-testid="file-name"
                                placeholder="Enter audio name here"
                                value={audioName}
                                required={true}
                                onChange={handleAudioName}
                            />
                        </div>
                    )}
                    {+audioFile.size <= LENGTH.MAX_AUDIO_SIZE ? (
                        <div className="popup_add_file_row uploaded">
                            <span>File uploaded</span>
                            <div className="popup_uploaded_info">
                                {audioFile.name.length > 10 ? audioFile.name.substr(0, 10) : audioFile.name}
                                <span>({getReadableFileSizeString(audioFile.size)})</span>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                </React.Fragment>
            ) : (
                <div />
            )}

            {audioFile && audioFile.name && audioFile.size && +audioFile.size <= LENGTH.MAX_AUDIO_SIZE ? (
                <div className="popup_add_file_row add_btn">
                    <div
                        className="popup_add_file_upload_btn"
                        onClick={audioFile && audioFile.name ? onSubmitAudio : null}
                        data-testid="add-audio-btn"
                    >
                        Add Audio
                    </div>
                </div>
            ) : (
                <div className="popup_add_file_row upload_btn">
                    <input type="file" id="popup_inp_file1" accept="audio/*" onChange={handleUploadAudio} />
                    <label htmlFor="popup_inp_file1" className="popup_bottom_upload_btn">
                        <img src={IMAGES.popup_upload} alt="" />
                        <span>Upload</span>
                    </label>
                </div>
            )}
            <div className="popup_add_file_close" onClick={closeModalAudio} data-testid="close-btn">
                <img src={IMAGES.cross_white} alt="" />
            </div>
        </div>
    );
};
