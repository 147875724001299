import * as SENDER from '../../../constants/sender';

export function switchSenderTypeToRequest(senderType) {
    switch (senderType) {
        case SENDER.SENDER_TYPE_CHAT:
            return 'Chat';
        case SENDER.SENDER_TYPE_LETTER:
            return 'Letter';
        default:
            return '';
    }
}

export function switchSenderGroupToRequest(senderGroup) {
    switch (senderGroup) {
        case SENDER.SENDER_GROUP_ONLINE_NOW:
            return 'online';
        case SENDER.SENDER_GROUP_DIALOGUES:
            return 'dialogues';
        case SENDER.SENDER_GROUP_UNREAD:
            return 'unread';
        case SENDER.SENDER_GROUP_FAVORITE:
            return 'favorites';
        case SENDER.SENDER_GROUP_MAYBE:
            return 'maybe';
        case SENDER.SENDER_GROUP_SPECIAL:
            return 'special';
        case SENDER.SENDER_GROUP_LOOKING_AT_YOU:
            return 'looking_at_you';
        case SENDER.SENDER_GROUP_CONNECTIONS:
            return 'connections';
        case SENDER.SENDER_GROUP_WHO_SENT_GIFTS:
            return 'who_sent_gifts';
        default:
            return '';
    }
}
