import * as FILES from '../constants/files';
// import {sortListByDate} from "../../services/methods";

const initState = {
    images: [],
    videos: [],
    audios: [],
    imageFolders: {},
    imageFoldersList: [],
    videoFolders: {},
    videoFoldersList: [],
    audioFolders: {},
    audioFoldersList: [],
};

export default function (state = initState, action) {
    switch (action.type) {
        case FILES.FILES_IMG_GET:
            return {
                ...state,
                ...{
                    // images: sortListByDate(action?.data?.images, true) || [],
                    images: action?.data?.images || [],
                    imageFolders: action?.data?.folders || {},
                    imageFoldersList: action?.data?.foldersList || [],
                },
            };
        case FILES.FILES_VIDEO_GET:
            return {
                ...state,
                ...{
                    // videos: sortListByDate(action?.data?.videos, true) || [],
                    videos: action?.data?.videos || [],
                    videoFolders: action?.data?.folders || {},
                    videoFoldersList: action?.data?.foldersList || [],
                },
            };
        case FILES.FILES_AUDIO_GET:
            return {
                ...state,
                ...{
                    // audios: sortListByDate(action?.data?.audios, true) || [],
                    audios: action?.data?.audios || [],
                    audioFolders: action?.data?.folders || {},
                    audioFoldersList: action?.data?.foldersList || [],
                },
            };
        case FILES.CLEAR_ALL_FILES:
            return {
                ...state,
                ...{
                    images: [],
                    videos: [],
                    audios: [],
                    imageFolders: {},
                    imageFoldersList: [],
                    videoFolders: {},
                    videoFoldersList: [],
                    audioFolders: {},
                    audioFoldersList: [],
                },
            };
        case FILES.ADD_NEW_FOLDER: {
            const activeFolderList = `${action?.folderType}FoldersList`;

            return {
                ...state,
                ...{
                    [activeFolderList]: [...state[activeFolderList], action?.folder],
                },
            };
        }
        case FILES.RENAME_FOLDER: {
            const activeFolderList = `${action?.folderType}FoldersList`;

            return {
                ...state,
                ...{
                    [activeFolderList]: state[activeFolderList].map((item) =>
                        item?.id === action?.folder?.id ? action?.folder : item,
                    ),
                },
            };
        }
        // case FILES.EDIT_FOLDER: {
        // 	const activeFolders = `${action?.folderType}Folders`;
        //
        // 	return {
        // 		...state, ...{
        // 			[activeFolders]: {
        // 				...state[activeFolders], ...action?.folderFiles
        // 			}
        // 		}
        // 	}
        // }
        // case FILES.DELETE_FILE_ON_MOVE: {
        // 	const activeFolders = `${action?.folderType}Folders`;
        //
        // 	return {
        // 		...state, ...{
        // 			[activeFolders]: {
        // 				...state[activeFolders],
        // 				[action?.folderId]: {
        // 					...state[activeFolders][action?.folderId],
        // 					list: state[activeFolders][action?.folderId]?.list.filter(el => !action?.selectedFileId.includes(el?.id))
        // 				}
        // 			},
        // 		}
        // 	}
        // }
        case FILES.DELETE_FOLDER: {
            const activeFolderList = `${action?.folderType}FoldersList`;

            return {
                ...state,
                ...{
                    [activeFolderList]: state[activeFolderList].filter((item) => item?.id !== action?.folderId),
                },
            };
        }

        default:
            return state;
    }
}
