import React from 'react';

import Styles from '../styles.module.scss';

export default function MenuItem({ styleItem, styleActive, titleItem, activeItem }) {
    return (
        <div
            className={`${Styles.clmn_1_bottom_menu_item} ${styleItem ?? ''} ${styleActive ?? ''}`}
            onClick={activeItem}
            data-testid={`bottom-menu-item-${titleItem}`}
        >
            {titleItem ?? ''}
        </div>
    );
}
