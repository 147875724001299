import * as LOADER from '../constants/loader';

const initState = {
    isActive: false,
    activeGifLoader: false,
};

export default function (state = initState, action) {
    switch (action.type) {
        case LOADER.SET_ACTIVE_STATUS:
            return {
                ...state,
                ...{
                    isActive: action?.data,
                },
            };

        case LOADER.SET_ACTIVE_GIF_LOADER:
            return {
                ...state,
                ...{
                    activeGifLoader: action?.data,
                },
            };

        default:
            return state;
    }
}
