import * as IMAGES from '../components/common/Images';

//==========sticker-heart
//type
export const STICKER_HEART_TYPE = 'heart';
//title
export const STICKER_HEART_TITLE = 'Hearts';
//name
export const STICKER_HEART_NAME_ARROW = 'sticker-heart-arrow';
export const STICKER_HEART_NAME_BROKEN = 'sticker-heart-broken';
export const STICKER_HEART_NAME_CONFUSED = 'sticker-heart-confused';
export const STICKER_HEART_NAME_CRY = 'sticker-heart-cry';
export const STICKER_HEART_NAME_HI = 'sticker-heart-hi';
export const STICKER_HEART_NAME_HUG = 'sticker-heart-hug';
export const STICKER_HEART_NAME_LAUGH = 'sticker-heart-laugh';
export const STICKER_HEART_NAME_LIKE = 'sticker-heart-like';
export const STICKER_HEART_NAME_LOVE = 'sticker-heart-love';
export const STICKER_HEART_NAME_SAD = 'sticker-heart-sad';
//image
export const STICKER_HEART_IMG_ARROW = IMAGES.sticker_heart_arrow;
export const STICKER_HEART_IMG_BROKEN = IMAGES.sticker_heart_broken;
export const STICKER_HEART_IMG_CONFUSED = IMAGES.sticker_heart_confused;
export const STICKER_HEART_IMG_CRY = IMAGES.sticker_heart_cry;
export const STICKER_HEART_IMG_HI = IMAGES.sticker_heart_hi;
export const STICKER_HEART_IMG_HUG = IMAGES.sticker_heart_hug;
export const STICKER_HEART_IMG_LAUGH = IMAGES.sticker_heart_laugh;
export const STICKER_HEART_IMG_LIKE = IMAGES.sticker_heart_like;
export const STICKER_HEART_IMG_LOVE = IMAGES.sticker_heart_love;
export const STICKER_HEART_IMG_SAD = IMAGES.sticker_heart_sad;
//array
export const ARRAY_STICKER_HEART = [
    { name: STICKER_HEART_NAME_ARROW, image: STICKER_HEART_IMG_ARROW },
    { name: STICKER_HEART_NAME_BROKEN, image: STICKER_HEART_IMG_BROKEN },
    { name: STICKER_HEART_NAME_CONFUSED, image: STICKER_HEART_IMG_CONFUSED },
    { name: STICKER_HEART_NAME_CRY, image: STICKER_HEART_IMG_CRY },
    { name: STICKER_HEART_NAME_HI, image: STICKER_HEART_IMG_HI },
    { name: STICKER_HEART_NAME_HUG, image: STICKER_HEART_IMG_HUG },
    { name: STICKER_HEART_NAME_LAUGH, image: STICKER_HEART_IMG_LAUGH },
    { name: STICKER_HEART_NAME_LIKE, image: STICKER_HEART_IMG_LIKE },
    { name: STICKER_HEART_NAME_LOVE, image: STICKER_HEART_IMG_LOVE },
    { name: STICKER_HEART_NAME_SAD, image: STICKER_HEART_IMG_SAD },
];

//==========sticker-guy
//type
export const STICKER_GUY_TYPE = 'guy';
//title
export const STICKER_GUY_TITLE = 'Mature man';
//name
export const STICKER_GUY_NAME_ANGRY = 'sticker-guy-angry';
export const STICKER_GUY_NAME_CONFUSED = 'sticker-guy-confused';
export const STICKER_GUY_NAME_COOL = 'sticker-guy-cool';
export const STICKER_GUY_NAME_HAPPY = 'sticker-guy-happy';
export const STICKER_GUY_NAME_KISS = 'sticker-guy-kiss';
export const STICKER_GUY_NAME_LIKE = 'sticker-guy-like';
export const STICKER_GUY_NAME_PLEASE = 'sticker-guy-please';
export const STICKER_GUY_NAME_ROLL_EYES = 'sticker-guy-roll-eyes';
export const STICKER_GUY_NAME_SLEEP = 'sticker-guy-sleep';
export const STICKER_GUY_NAME_STRONG = 'sticker-guy-strong';
export const STICKER_GUY_NAME_SURPRIZE = 'sticker-guy-surprize';
export const STICKER_GUY_NAME_THINK = 'sticker-guy-think';
export const STICKER_GUY_NAME_WAVE = 'sticker-guy-wave';
//image
export const STICKER_GUY_IMG_ANGRY = IMAGES.sticker_guy_angry;
export const STICKER_GUY_IMG_CONFUSED = IMAGES.sticker_guy_confused;
export const STICKER_GUY_IMG_COOL = IMAGES.sticker_guy_cool;
export const STICKER_GUY_IMG_HAPPY = IMAGES.sticker_guy_happy;
export const STICKER_GUY_IMG_KISS = IMAGES.sticker_guy_kiss;
export const STICKER_GUY_IMG_LIKE = IMAGES.sticker_guy_like;
export const STICKER_GUY_IMG_PLEASE = IMAGES.sticker_guy_please;
export const STICKER_GUY_IMG_ROLL_EYES = IMAGES.sticker_guy_roll_eyes;
export const STICKER_GUY_IMG_SLEEP = IMAGES.sticker_guy_sleep;
export const STICKER_GUY_IMG_STRONG = IMAGES.sticker_guy_strong;
export const STICKER_GUY_IMG_SURPRIZE = IMAGES.sticker_guy_surprize;
export const STICKER_GUY_IMG_THINK = IMAGES.sticker_guy_think;
export const STICKER_GUY_IMG_WAVE = IMAGES.sticker_guy_wave;
//array
export const ARRAY_STICKER_GUY = [
    { name: STICKER_GUY_NAME_ANGRY, image: STICKER_GUY_IMG_ANGRY },
    { name: STICKER_GUY_NAME_CONFUSED, image: STICKER_GUY_IMG_CONFUSED },
    { name: STICKER_GUY_NAME_COOL, image: STICKER_GUY_IMG_COOL },
    { name: STICKER_GUY_NAME_HAPPY, image: STICKER_GUY_IMG_HAPPY },
    { name: STICKER_GUY_NAME_KISS, image: STICKER_GUY_IMG_KISS },
    { name: STICKER_GUY_NAME_LIKE, image: STICKER_GUY_IMG_LIKE },
    { name: STICKER_GUY_NAME_PLEASE, image: STICKER_GUY_IMG_PLEASE },
    { name: STICKER_GUY_NAME_ROLL_EYES, image: STICKER_GUY_IMG_ROLL_EYES },
    { name: STICKER_GUY_NAME_SLEEP, image: STICKER_GUY_IMG_SLEEP },
    { name: STICKER_GUY_NAME_STRONG, image: STICKER_GUY_IMG_STRONG },
    { name: STICKER_GUY_NAME_SURPRIZE, image: STICKER_GUY_IMG_SURPRIZE },
    { name: STICKER_GUY_NAME_THINK, image: STICKER_GUY_IMG_THINK },
    { name: STICKER_GUY_NAME_WAVE, image: STICKER_GUY_IMG_WAVE },
];

//==========sticker-animal
//type
export const STICKER_ANIMAL_TYPE = 'animal';
//title
export const STICKER_ANIMAL_TITLE = 'Animal';
//name
export const STICKER_ANIMAL_NAME_1 = 'sticker-animal-1';
export const STICKER_ANIMAL_NAME_2 = 'sticker-animal-2';
export const STICKER_ANIMAL_NAME_3 = 'sticker-animal-3';
export const STICKER_ANIMAL_NAME_4 = 'sticker-animal-4';
export const STICKER_ANIMAL_NAME_5 = 'sticker-animal-5';
export const STICKER_ANIMAL_NAME_6 = 'sticker-animal-6';
export const STICKER_ANIMAL_NAME_7 = 'sticker-animal-7';
export const STICKER_ANIMAL_NAME_8 = 'sticker-animal-8';
export const STICKER_ANIMAL_NAME_9 = 'sticker-animal-9';
export const STICKER_ANIMAL_NAME_10 = 'sticker-animal-10';
//image
export const STICKER_ANIMAL_IMG_1 = IMAGES.sticker_animal_1;
export const STICKER_ANIMAL_IMG_2 = IMAGES.sticker_animal_2;
export const STICKER_ANIMAL_IMG_3 = IMAGES.sticker_animal_3;
export const STICKER_ANIMAL_IMG_4 = IMAGES.sticker_animal_4;
export const STICKER_ANIMAL_IMG_5 = IMAGES.sticker_animal_5;
export const STICKER_ANIMAL_IMG_6 = IMAGES.sticker_animal_6;
export const STICKER_ANIMAL_IMG_7 = IMAGES.sticker_animal_7;
export const STICKER_ANIMAL_IMG_8 = IMAGES.sticker_animal_8;
export const STICKER_ANIMAL_IMG_9 = IMAGES.sticker_animal_9;
export const STICKER_ANIMAL_IMG_10 = IMAGES.sticker_animal_10;
//array
export const ARRAY_STICKER_ANIMAL = [
    { name: STICKER_ANIMAL_NAME_1, image: STICKER_ANIMAL_IMG_1 },
    { name: STICKER_ANIMAL_NAME_2, image: STICKER_ANIMAL_IMG_2 },
    { name: STICKER_ANIMAL_NAME_3, image: STICKER_ANIMAL_IMG_3 },
    { name: STICKER_ANIMAL_NAME_4, image: STICKER_ANIMAL_IMG_4 },
    { name: STICKER_ANIMAL_NAME_5, image: STICKER_ANIMAL_IMG_5 },
    { name: STICKER_ANIMAL_NAME_6, image: STICKER_ANIMAL_IMG_6 },
    { name: STICKER_ANIMAL_NAME_7, image: STICKER_ANIMAL_IMG_7 },
    { name: STICKER_ANIMAL_NAME_8, image: STICKER_ANIMAL_IMG_8 },
    { name: STICKER_ANIMAL_NAME_9, image: STICKER_ANIMAL_IMG_9 },
    { name: STICKER_ANIMAL_NAME_10, image: STICKER_ANIMAL_IMG_10 },
];

//==========sticker-tiger
//type
export const STICKER_TIGER_TYPE = 'tiger';
//title
export const STICKER_TIGER_TITLE = 'Tiger';
//name
export const STICKER_TIGER_NAME_1 = 'sticker-tiger-1';
export const STICKER_TIGER_NAME_2 = 'sticker-tiger-2';
export const STICKER_TIGER_NAME_3 = 'sticker-tiger-3';
export const STICKER_TIGER_NAME_4 = 'sticker-tiger-4';
export const STICKER_TIGER_NAME_5 = 'sticker-tiger-5';
export const STICKER_TIGER_NAME_6 = 'sticker-tiger-6';
export const STICKER_TIGER_NAME_7 = 'sticker-tiger-7';
export const STICKER_TIGER_NAME_8 = 'sticker-tiger-8';
export const STICKER_TIGER_NAME_9 = 'sticker-tiger-9';
export const STICKER_TIGER_NAME_10 = 'sticker-tiger-10';
//image
export const STICKER_TIGER_IMG_1 = IMAGES.sticker_tiger_1;
export const STICKER_TIGER_IMG_2 = IMAGES.sticker_tiger_2;
export const STICKER_TIGER_IMG_3 = IMAGES.sticker_tiger_3;
export const STICKER_TIGER_IMG_4 = IMAGES.sticker_tiger_4;
export const STICKER_TIGER_IMG_5 = IMAGES.sticker_tiger_5;
export const STICKER_TIGER_IMG_6 = IMAGES.sticker_tiger_6;
export const STICKER_TIGER_IMG_7 = IMAGES.sticker_tiger_7;
export const STICKER_TIGER_IMG_8 = IMAGES.sticker_tiger_8;
export const STICKER_TIGER_IMG_9 = IMAGES.sticker_tiger_9;
export const STICKER_TIGER_IMG_10 = IMAGES.sticker_tiger_10;
//array
export const ARRAY_STICKER_TIGER = [
    { name: STICKER_TIGER_NAME_1, image: STICKER_TIGER_IMG_1 },
    { name: STICKER_TIGER_NAME_2, image: STICKER_TIGER_IMG_2 },
    { name: STICKER_TIGER_NAME_3, image: STICKER_TIGER_IMG_3 },
    { name: STICKER_TIGER_NAME_4, image: STICKER_TIGER_IMG_4 },
    { name: STICKER_TIGER_NAME_5, image: STICKER_TIGER_IMG_5 },
    { name: STICKER_TIGER_NAME_6, image: STICKER_TIGER_IMG_6 },
    { name: STICKER_TIGER_NAME_7, image: STICKER_TIGER_IMG_7 },
    { name: STICKER_TIGER_NAME_8, image: STICKER_TIGER_IMG_8 },
    { name: STICKER_TIGER_NAME_9, image: STICKER_TIGER_IMG_9 },
    { name: STICKER_TIGER_NAME_10, image: STICKER_TIGER_IMG_10 },
];

//==========sticker-girl
//type
export const STICKER_GIRL_TYPE = 'girl';
//title
export const STICKER_GIRL_TITLE = 'Cute girl';
//name
export const STICKER_GIRL_NAME_ANGRY = 'sticker-girl-angry';
export const STICKER_GIRL_NAME_CONFUSE = 'sticker-girl-confuse';
export const STICKER_GIRL_NAME_CRY = 'sticker-girl-cry';
export const STICKER_GIRL_NAME_HAPPY = 'sticker-girl-happy';
export const STICKER_GIRL_NAME_KISS = 'sticker-girl-kiss';
export const STICKER_GIRL_NAME_LIKE = 'sticker-girl-like';
export const STICKER_GIRL_NAME_PLEASE = 'sticker-girl-please';
export const STICKER_GIRL_NAME_ROLL_EYES = 'sticker-girl-roll-eyes';
export const STICKER_GIRL_NAME_SLEEP = 'sticker-girl-sleep';
export const STICKER_GIRL_NAME_SURPRIZE = 'sticker-girl-surprize';
export const STICKER_GIRL_NAME_THINK = 'sticker-girl-think';
export const STICKER_GIRL_NAME_WAVE = 'sticker-girl-wave';
//image
export const STICKER_GIRL_IMG_ANGRY = IMAGES.sticker_girl_angry;
export const STICKER_GIRL_IMG_CONFUSE = IMAGES.sticker_girl_confuse;
export const STICKER_GIRL_IMG_CRY = IMAGES.sticker_girl_cry;
export const STICKER_GIRL_IMG_HAPPY = IMAGES.sticker_girl_happy;
export const STICKER_GIRL_IMG_KISS = IMAGES.sticker_girl_kiss;
export const STICKER_GIRL_IMG_LIKE = IMAGES.sticker_girl_like;
export const STICKER_GIRL_IMG_PLEASE = IMAGES.sticker_girl_please;
export const STICKER_GIRL_IMG_ROLL_EYES = IMAGES.sticker_girl_roll_eyes;
export const STICKER_GIRL_IMG_SLEEP = IMAGES.sticker_girl_sleep;
export const STICKER_GIRL_IMG_SURPRIZE = IMAGES.sticker_girl_surprize;
export const STICKER_GIRL_IMG_THINK = IMAGES.sticker_girl_think;
export const STICKER_GIRL_IMG_WAVE = IMAGES.sticker_girl_wave;
//array
export const ARRAY_STICKER_GIRL = [
    { name: STICKER_GIRL_NAME_ANGRY, image: STICKER_GIRL_IMG_ANGRY },
    { name: STICKER_GIRL_NAME_CONFUSE, image: STICKER_GIRL_IMG_CONFUSE },
    { name: STICKER_GIRL_NAME_CRY, image: STICKER_GIRL_IMG_CRY },
    { name: STICKER_GIRL_NAME_HAPPY, image: STICKER_GIRL_IMG_HAPPY },
    { name: STICKER_GIRL_NAME_KISS, image: STICKER_GIRL_IMG_KISS },
    { name: STICKER_GIRL_NAME_LIKE, image: STICKER_GIRL_IMG_LIKE },
    { name: STICKER_GIRL_NAME_PLEASE, image: STICKER_GIRL_IMG_PLEASE },
    { name: STICKER_GIRL_NAME_ROLL_EYES, image: STICKER_GIRL_IMG_ROLL_EYES },
    { name: STICKER_GIRL_NAME_SLEEP, image: STICKER_GIRL_IMG_SLEEP },
    { name: STICKER_GIRL_NAME_SURPRIZE, image: STICKER_GIRL_IMG_SURPRIZE },
    { name: STICKER_GIRL_NAME_THINK, image: STICKER_GIRL_IMG_THINK },
    { name: STICKER_GIRL_NAME_WAVE, image: STICKER_GIRL_IMG_WAVE },
];

//==========sticker-type1
//type
export const STICKER_TYPE1_TYPE = 'type1';
//title
export const STICKER_TYPE1_TITLE = 'Whishes';
//name
export const STICKER_TYPE1_NAME_1 = 'sticker-type1-1';
export const STICKER_TYPE1_NAME_2 = 'sticker-type1-2';
export const STICKER_TYPE1_NAME_3 = 'sticker-type1-3';
export const STICKER_TYPE1_NAME_4 = 'sticker-type1-4';
export const STICKER_TYPE1_NAME_5 = 'sticker-type1-5';
export const STICKER_TYPE1_NAME_6 = 'sticker-type1-6';
export const STICKER_TYPE1_NAME_7 = 'sticker-type1-7';
export const STICKER_TYPE1_NAME_8 = 'sticker-type1-8';
export const STICKER_TYPE1_NAME_9 = 'sticker-type1-9';
export const STICKER_TYPE1_NAME_10 = 'sticker-type1-10';
//image
export const STICKER_TYPE1_IMG_1 = IMAGES.sticker_type1_1;
export const STICKER_TYPE1_IMG_2 = IMAGES.sticker_type1_2;
export const STICKER_TYPE1_IMG_3 = IMAGES.sticker_type1_3;
export const STICKER_TYPE1_IMG_4 = IMAGES.sticker_type1_4;
export const STICKER_TYPE1_IMG_5 = IMAGES.sticker_type1_5;
export const STICKER_TYPE1_IMG_6 = IMAGES.sticker_type1_6;
export const STICKER_TYPE1_IMG_7 = IMAGES.sticker_type1_7;
export const STICKER_TYPE1_IMG_8 = IMAGES.sticker_type1_8;
export const STICKER_TYPE1_IMG_9 = IMAGES.sticker_type1_9;
export const STICKER_TYPE1_IMG_10 = IMAGES.sticker_type1_10;
//array
export const ARRAY_STICKER_TYPE1 = [
    { name: STICKER_TYPE1_NAME_1, image: STICKER_TYPE1_IMG_1 },
    { name: STICKER_TYPE1_NAME_2, image: STICKER_TYPE1_IMG_2 },
    { name: STICKER_TYPE1_NAME_3, image: STICKER_TYPE1_IMG_3 },
    { name: STICKER_TYPE1_NAME_4, image: STICKER_TYPE1_IMG_4 },
    { name: STICKER_TYPE1_NAME_5, image: STICKER_TYPE1_IMG_5 },
    { name: STICKER_TYPE1_NAME_6, image: STICKER_TYPE1_IMG_6 },
    { name: STICKER_TYPE1_NAME_7, image: STICKER_TYPE1_IMG_7 },
    { name: STICKER_TYPE1_NAME_8, image: STICKER_TYPE1_IMG_8 },
    { name: STICKER_TYPE1_NAME_9, image: STICKER_TYPE1_IMG_9 },
    { name: STICKER_TYPE1_NAME_10, image: STICKER_TYPE1_IMG_10 },
];

//==========sticker-type2
//type
export const STICKER_TYPE2_TYPE = 'type2';
//title
export const STICKER_TYPE2_TITLE = 'Reactions';
//name
export const STICKER_TYPE2_NAME_1 = 'sticker-type2-1';
export const STICKER_TYPE2_NAME_2 = 'sticker-type2-2';
export const STICKER_TYPE2_NAME_3 = 'sticker-type2-3';
export const STICKER_TYPE2_NAME_4 = 'sticker-type2-4';
export const STICKER_TYPE2_NAME_5 = 'sticker-type2-5';
export const STICKER_TYPE2_NAME_6 = 'sticker-type2-6';
export const STICKER_TYPE2_NAME_7 = 'sticker-type2-7';
export const STICKER_TYPE2_NAME_8 = 'sticker-type2-8';
export const STICKER_TYPE2_NAME_9 = 'sticker-type2-9';
export const STICKER_TYPE2_NAME_10 = 'sticker-type2-10';
//image
export const STICKER_TYPE2_IMG_1 = IMAGES.sticker_type2_1;
export const STICKER_TYPE2_IMG_2 = IMAGES.sticker_type2_2;
export const STICKER_TYPE2_IMG_3 = IMAGES.sticker_type2_3;
export const STICKER_TYPE2_IMG_4 = IMAGES.sticker_type2_4;
export const STICKER_TYPE2_IMG_5 = IMAGES.sticker_type2_5;
export const STICKER_TYPE2_IMG_6 = IMAGES.sticker_type2_6;
export const STICKER_TYPE2_IMG_7 = IMAGES.sticker_type2_7;
export const STICKER_TYPE2_IMG_8 = IMAGES.sticker_type2_8;
export const STICKER_TYPE2_IMG_9 = IMAGES.sticker_type2_9;
export const STICKER_TYPE2_IMG_10 = IMAGES.sticker_type2_10;
//array
export const ARRAY_STICKER_TYPE2 = [
    { name: STICKER_TYPE2_NAME_1, image: STICKER_TYPE2_IMG_1 },
    { name: STICKER_TYPE2_NAME_2, image: STICKER_TYPE2_IMG_2 },
    { name: STICKER_TYPE2_NAME_3, image: STICKER_TYPE2_IMG_3 },
    { name: STICKER_TYPE2_NAME_4, image: STICKER_TYPE2_IMG_4 },
    { name: STICKER_TYPE2_NAME_5, image: STICKER_TYPE2_IMG_5 },
    { name: STICKER_TYPE2_NAME_6, image: STICKER_TYPE2_IMG_6 },
    { name: STICKER_TYPE2_NAME_7, image: STICKER_TYPE2_IMG_7 },
    { name: STICKER_TYPE2_NAME_8, image: STICKER_TYPE2_IMG_8 },
    { name: STICKER_TYPE2_NAME_9, image: STICKER_TYPE2_IMG_9 },
    { name: STICKER_TYPE2_NAME_10, image: STICKER_TYPE2_IMG_10 },
];

//==========sticker-type3
//type
export const STICKER_TYPE3_TYPE = 'type3';
//title
export const STICKER_TYPE3_TITLE = 'Pets';
//name
export const STICKER_TYPE3_NAME_1 = 'sticker-type3-1';
export const STICKER_TYPE3_NAME_2 = 'sticker-type3-2';
export const STICKER_TYPE3_NAME_3 = 'sticker-type3-3';
export const STICKER_TYPE3_NAME_4 = 'sticker-type3-4';
export const STICKER_TYPE3_NAME_5 = 'sticker-type3-5';
export const STICKER_TYPE3_NAME_6 = 'sticker-type3-6';
export const STICKER_TYPE3_NAME_7 = 'sticker-type3-7';
export const STICKER_TYPE3_NAME_8 = 'sticker-type3-8';
export const STICKER_TYPE3_NAME_9 = 'sticker-type3-9';
export const STICKER_TYPE3_NAME_10 = 'sticker-type3-10';
//image
export const STICKER_TYPE3_IMG_1 = IMAGES.sticker_type3_1;
export const STICKER_TYPE3_IMG_2 = IMAGES.sticker_type3_2;
export const STICKER_TYPE3_IMG_3 = IMAGES.sticker_type3_3;
export const STICKER_TYPE3_IMG_4 = IMAGES.sticker_type3_4;
export const STICKER_TYPE3_IMG_5 = IMAGES.sticker_type3_5;
export const STICKER_TYPE3_IMG_6 = IMAGES.sticker_type3_6;
export const STICKER_TYPE3_IMG_7 = IMAGES.sticker_type3_7;
export const STICKER_TYPE3_IMG_8 = IMAGES.sticker_type3_8;
export const STICKER_TYPE3_IMG_9 = IMAGES.sticker_type3_9;
export const STICKER_TYPE3_IMG_10 = IMAGES.sticker_type3_10;
//array
export const ARRAY_STICKER_TYPE3 = [
    { name: STICKER_TYPE3_NAME_1, image: STICKER_TYPE3_IMG_1 },
    { name: STICKER_TYPE3_NAME_2, image: STICKER_TYPE3_IMG_2 },
    { name: STICKER_TYPE3_NAME_3, image: STICKER_TYPE3_IMG_3 },
    { name: STICKER_TYPE3_NAME_4, image: STICKER_TYPE3_IMG_4 },
    { name: STICKER_TYPE3_NAME_5, image: STICKER_TYPE3_IMG_5 },
    { name: STICKER_TYPE3_NAME_6, image: STICKER_TYPE3_IMG_6 },
    { name: STICKER_TYPE3_NAME_7, image: STICKER_TYPE3_IMG_7 },
    { name: STICKER_TYPE3_NAME_8, image: STICKER_TYPE3_IMG_8 },
    { name: STICKER_TYPE3_NAME_9, image: STICKER_TYPE3_IMG_9 },
    { name: STICKER_TYPE3_NAME_10, image: STICKER_TYPE3_IMG_10 },
];

//==========sticker-type4
//type
export const STICKER_TYPE4_TYPE = 'type4';
//title
export const STICKER_TYPE4_TITLE = 'Mood';
//name
export const STICKER_TYPE4_NAME_1 = 'sticker-type4-1';
export const STICKER_TYPE4_NAME_2 = 'sticker-type4-2';
export const STICKER_TYPE4_NAME_3 = 'sticker-type4-3';
export const STICKER_TYPE4_NAME_4 = 'sticker-type4-4';
export const STICKER_TYPE4_NAME_5 = 'sticker-type4-5';
export const STICKER_TYPE4_NAME_6 = 'sticker-type4-6';
export const STICKER_TYPE4_NAME_7 = 'sticker-type4-7';
export const STICKER_TYPE4_NAME_8 = 'sticker-type4-8';
export const STICKER_TYPE4_NAME_9 = 'sticker-type4-9';
export const STICKER_TYPE4_NAME_10 = 'sticker-type4-10';
//image
export const STICKER_TYPE4_IMG_1 = IMAGES.sticker_type4_1;
export const STICKER_TYPE4_IMG_2 = IMAGES.sticker_type4_2;
export const STICKER_TYPE4_IMG_3 = IMAGES.sticker_type4_3;
export const STICKER_TYPE4_IMG_4 = IMAGES.sticker_type4_4;
export const STICKER_TYPE4_IMG_5 = IMAGES.sticker_type4_5;
export const STICKER_TYPE4_IMG_6 = IMAGES.sticker_type4_6;
export const STICKER_TYPE4_IMG_7 = IMAGES.sticker_type4_7;
export const STICKER_TYPE4_IMG_8 = IMAGES.sticker_type4_8;
export const STICKER_TYPE4_IMG_9 = IMAGES.sticker_type4_9;
export const STICKER_TYPE4_IMG_10 = IMAGES.sticker_type4_10;
//array
export const ARRAY_STICKER_TYPE4 = [
    { name: STICKER_TYPE4_NAME_1, image: STICKER_TYPE4_IMG_1 },
    { name: STICKER_TYPE4_NAME_2, image: STICKER_TYPE4_IMG_2 },
    { name: STICKER_TYPE4_NAME_3, image: STICKER_TYPE4_IMG_3 },
    { name: STICKER_TYPE4_NAME_4, image: STICKER_TYPE4_IMG_4 },
    { name: STICKER_TYPE4_NAME_5, image: STICKER_TYPE4_IMG_5 },
    { name: STICKER_TYPE4_NAME_6, image: STICKER_TYPE4_IMG_6 },
    { name: STICKER_TYPE4_NAME_7, image: STICKER_TYPE4_IMG_7 },
    { name: STICKER_TYPE4_NAME_8, image: STICKER_TYPE4_IMG_8 },
    { name: STICKER_TYPE4_NAME_9, image: STICKER_TYPE4_IMG_9 },
    { name: STICKER_TYPE4_NAME_10, image: STICKER_TYPE4_IMG_10 },
];

//==========sticker-type5
//type
export const STICKER_TYPE5_TYPE = 'type5';
//title
export const STICKER_TYPE5_TITLE = 'Bee';
//name
export const STICKER_TYPE5_NAME_1 = 'sticker-type5-1';
export const STICKER_TYPE5_NAME_2 = 'sticker-type5-2';
export const STICKER_TYPE5_NAME_3 = 'sticker-type5-3';
export const STICKER_TYPE5_NAME_4 = 'sticker-type5-4';
export const STICKER_TYPE5_NAME_5 = 'sticker-type5-5';
export const STICKER_TYPE5_NAME_6 = 'sticker-type5-6';
export const STICKER_TYPE5_NAME_7 = 'sticker-type5-7';
export const STICKER_TYPE5_NAME_8 = 'sticker-type5-8';
export const STICKER_TYPE5_NAME_9 = 'sticker-type5-9';
export const STICKER_TYPE5_NAME_10 = 'sticker-type5-10';
//image
export const STICKER_TYPE5_IMG_1 = IMAGES.sticker_type5_1;
export const STICKER_TYPE5_IMG_2 = IMAGES.sticker_type5_2;
export const STICKER_TYPE5_IMG_3 = IMAGES.sticker_type5_3;
export const STICKER_TYPE5_IMG_4 = IMAGES.sticker_type5_4;
export const STICKER_TYPE5_IMG_5 = IMAGES.sticker_type5_5;
export const STICKER_TYPE5_IMG_6 = IMAGES.sticker_type5_6;
export const STICKER_TYPE5_IMG_7 = IMAGES.sticker_type5_7;
export const STICKER_TYPE5_IMG_8 = IMAGES.sticker_type5_8;
export const STICKER_TYPE5_IMG_9 = IMAGES.sticker_type5_9;
export const STICKER_TYPE5_IMG_10 = IMAGES.sticker_type5_10;
//array
export const ARRAY_STICKER_TYPE5 = [
    { name: STICKER_TYPE5_NAME_1, image: STICKER_TYPE5_IMG_1 },
    { name: STICKER_TYPE5_NAME_2, image: STICKER_TYPE5_IMG_2 },
    { name: STICKER_TYPE5_NAME_3, image: STICKER_TYPE5_IMG_3 },
    { name: STICKER_TYPE5_NAME_4, image: STICKER_TYPE5_IMG_4 },
    { name: STICKER_TYPE5_NAME_5, image: STICKER_TYPE5_IMG_5 },
    { name: STICKER_TYPE5_NAME_6, image: STICKER_TYPE5_IMG_6 },
    { name: STICKER_TYPE5_NAME_7, image: STICKER_TYPE5_IMG_7 },
    { name: STICKER_TYPE5_NAME_8, image: STICKER_TYPE5_IMG_8 },
    { name: STICKER_TYPE5_NAME_9, image: STICKER_TYPE5_IMG_9 },
    { name: STICKER_TYPE5_NAME_10, image: STICKER_TYPE5_IMG_10 },
];

//==========sticker-type6
//type
export const STICKER_TYPE6_TYPE = 'type6';
//title
export const STICKER_TYPE6_TITLE = 'Street Food';
//name
export const STICKER_TYPE6_NAME_1 = 'sticker-type6-1';
export const STICKER_TYPE6_NAME_2 = 'sticker-type6-2';
export const STICKER_TYPE6_NAME_3 = 'sticker-type6-3';
export const STICKER_TYPE6_NAME_4 = 'sticker-type6-4';
export const STICKER_TYPE6_NAME_5 = 'sticker-type6-5';
export const STICKER_TYPE6_NAME_6 = 'sticker-type6-6';
export const STICKER_TYPE6_NAME_7 = 'sticker-type6-7';
export const STICKER_TYPE6_NAME_8 = 'sticker-type6-8';
export const STICKER_TYPE6_NAME_9 = 'sticker-type6-9';
export const STICKER_TYPE6_NAME_10 = 'sticker-type6-10';
//image
export const STICKER_TYPE6_IMG_1 = IMAGES.sticker_type6_1;
export const STICKER_TYPE6_IMG_2 = IMAGES.sticker_type6_2;
export const STICKER_TYPE6_IMG_3 = IMAGES.sticker_type6_3;
export const STICKER_TYPE6_IMG_4 = IMAGES.sticker_type6_4;
export const STICKER_TYPE6_IMG_5 = IMAGES.sticker_type6_5;
export const STICKER_TYPE6_IMG_6 = IMAGES.sticker_type6_6;
export const STICKER_TYPE6_IMG_7 = IMAGES.sticker_type6_7;
export const STICKER_TYPE6_IMG_8 = IMAGES.sticker_type6_8;
export const STICKER_TYPE6_IMG_9 = IMAGES.sticker_type6_9;
export const STICKER_TYPE6_IMG_10 = IMAGES.sticker_type6_10;
//array
export const ARRAY_STICKER_TYPE6 = [
    { name: STICKER_TYPE6_NAME_1, image: STICKER_TYPE6_IMG_1 },
    { name: STICKER_TYPE6_NAME_2, image: STICKER_TYPE6_IMG_2 },
    { name: STICKER_TYPE6_NAME_3, image: STICKER_TYPE6_IMG_3 },
    { name: STICKER_TYPE6_NAME_4, image: STICKER_TYPE6_IMG_4 },
    { name: STICKER_TYPE6_NAME_5, image: STICKER_TYPE6_IMG_5 },
    { name: STICKER_TYPE6_NAME_6, image: STICKER_TYPE6_IMG_6 },
    { name: STICKER_TYPE6_NAME_7, image: STICKER_TYPE6_IMG_7 },
    { name: STICKER_TYPE6_NAME_8, image: STICKER_TYPE6_IMG_8 },
    { name: STICKER_TYPE6_NAME_9, image: STICKER_TYPE6_IMG_9 },
    { name: STICKER_TYPE6_NAME_10, image: STICKER_TYPE6_IMG_10 },
];

//==========sticker-type7
//type
export const STICKER_TYPE7_TYPE = 'type7';
//title
export const STICKER_TYPE7_TITLE = 'Koala Jim';
//name
export const STICKER_TYPE7_NAME_1 = 'sticker-type7-1';
export const STICKER_TYPE7_NAME_2 = 'sticker-type7-2';
export const STICKER_TYPE7_NAME_3 = 'sticker-type7-3';
export const STICKER_TYPE7_NAME_4 = 'sticker-type7-4';
export const STICKER_TYPE7_NAME_5 = 'sticker-type7-5';
export const STICKER_TYPE7_NAME_6 = 'sticker-type7-6';
export const STICKER_TYPE7_NAME_7 = 'sticker-type7-7';
export const STICKER_TYPE7_NAME_8 = 'sticker-type7-8';
export const STICKER_TYPE7_NAME_9 = 'sticker-type7-9';
export const STICKER_TYPE7_NAME_10 = 'sticker-type7-10';
//image
export const STICKER_TYPE7_IMG_1 = IMAGES.sticker_type7_1;
export const STICKER_TYPE7_IMG_2 = IMAGES.sticker_type7_2;
export const STICKER_TYPE7_IMG_3 = IMAGES.sticker_type7_3;
export const STICKER_TYPE7_IMG_4 = IMAGES.sticker_type7_4;
export const STICKER_TYPE7_IMG_5 = IMAGES.sticker_type7_5;
export const STICKER_TYPE7_IMG_6 = IMAGES.sticker_type7_6;
export const STICKER_TYPE7_IMG_7 = IMAGES.sticker_type7_7;
export const STICKER_TYPE7_IMG_8 = IMAGES.sticker_type7_8;
export const STICKER_TYPE7_IMG_9 = IMAGES.sticker_type7_9;
export const STICKER_TYPE7_IMG_10 = IMAGES.sticker_type7_10;
//array
export const ARRAY_STICKER_TYPE7 = [
    { name: STICKER_TYPE7_NAME_1, image: STICKER_TYPE7_IMG_1 },
    { name: STICKER_TYPE7_NAME_2, image: STICKER_TYPE7_IMG_2 },
    { name: STICKER_TYPE7_NAME_3, image: STICKER_TYPE7_IMG_3 },
    { name: STICKER_TYPE7_NAME_4, image: STICKER_TYPE7_IMG_4 },
    { name: STICKER_TYPE7_NAME_5, image: STICKER_TYPE7_IMG_5 },
    { name: STICKER_TYPE7_NAME_6, image: STICKER_TYPE7_IMG_6 },
    { name: STICKER_TYPE7_NAME_7, image: STICKER_TYPE7_IMG_7 },
    { name: STICKER_TYPE7_NAME_8, image: STICKER_TYPE7_IMG_8 },
    { name: STICKER_TYPE7_NAME_9, image: STICKER_TYPE7_IMG_9 },
    { name: STICKER_TYPE7_NAME_10, image: STICKER_TYPE7_IMG_10 },
];

//==========sticker-type8
//type
export const STICKER_TYPE8_TYPE = 'type8';
//title
export const STICKER_TYPE8_TITLE = 'Avocado';
//name
export const STICKER_TYPE8_NAME_1 = 'sticker-type8-1';
export const STICKER_TYPE8_NAME_2 = 'sticker-type8-2';
export const STICKER_TYPE8_NAME_3 = 'sticker-type8-3';
export const STICKER_TYPE8_NAME_4 = 'sticker-type8-4';
export const STICKER_TYPE8_NAME_5 = 'sticker-type8-5';
export const STICKER_TYPE8_NAME_6 = 'sticker-type8-6';
export const STICKER_TYPE8_NAME_7 = 'sticker-type8-7';
export const STICKER_TYPE8_NAME_8 = 'sticker-type8-8';
export const STICKER_TYPE8_NAME_9 = 'sticker-type8-9';
export const STICKER_TYPE8_NAME_10 = 'sticker-type8-10';
//image
export const STICKER_TYPE8_IMG_1 = IMAGES.sticker_type8_1;
export const STICKER_TYPE8_IMG_2 = IMAGES.sticker_type8_2;
export const STICKER_TYPE8_IMG_3 = IMAGES.sticker_type8_3;
export const STICKER_TYPE8_IMG_4 = IMAGES.sticker_type8_4;
export const STICKER_TYPE8_IMG_5 = IMAGES.sticker_type8_5;
export const STICKER_TYPE8_IMG_6 = IMAGES.sticker_type8_6;
export const STICKER_TYPE8_IMG_7 = IMAGES.sticker_type8_7;
export const STICKER_TYPE8_IMG_8 = IMAGES.sticker_type8_8;
export const STICKER_TYPE8_IMG_9 = IMAGES.sticker_type8_9;
export const STICKER_TYPE8_IMG_10 = IMAGES.sticker_type8_10;
//array
export const ARRAY_STICKER_TYPE8 = [
    { name: STICKER_TYPE8_NAME_1, image: STICKER_TYPE8_IMG_1 },
    { name: STICKER_TYPE8_NAME_2, image: STICKER_TYPE8_IMG_2 },
    { name: STICKER_TYPE8_NAME_3, image: STICKER_TYPE8_IMG_3 },
    { name: STICKER_TYPE8_NAME_4, image: STICKER_TYPE8_IMG_4 },
    { name: STICKER_TYPE8_NAME_5, image: STICKER_TYPE8_IMG_5 },
    { name: STICKER_TYPE8_NAME_6, image: STICKER_TYPE8_IMG_6 },
    { name: STICKER_TYPE8_NAME_7, image: STICKER_TYPE8_IMG_7 },
    { name: STICKER_TYPE8_NAME_8, image: STICKER_TYPE8_IMG_8 },
    { name: STICKER_TYPE8_NAME_9, image: STICKER_TYPE8_IMG_9 },
    { name: STICKER_TYPE8_NAME_10, image: STICKER_TYPE8_IMG_10 },
];

//==========sticker-type9
//type
export const STICKER_TYPE9_TYPE = 'type9';
//title
export const STICKER_TYPE9_TITLE = 'Love Heart';
//name
export const STICKER_TYPE9_NAME_1 = 'sticker-type9-1';
export const STICKER_TYPE9_NAME_2 = 'sticker-type9-2';
export const STICKER_TYPE9_NAME_3 = 'sticker-type9-3';
export const STICKER_TYPE9_NAME_4 = 'sticker-type9-4';
export const STICKER_TYPE9_NAME_5 = 'sticker-type9-5';
export const STICKER_TYPE9_NAME_6 = 'sticker-type9-6';
export const STICKER_TYPE9_NAME_7 = 'sticker-type9-7';
export const STICKER_TYPE9_NAME_8 = 'sticker-type9-8';
export const STICKER_TYPE9_NAME_9 = 'sticker-type9-9';
export const STICKER_TYPE9_NAME_10 = 'sticker-type9-10';
//image
export const STICKER_TYPE9_IMG_1 = IMAGES.sticker_type9_1;
export const STICKER_TYPE9_IMG_2 = IMAGES.sticker_type9_2;
export const STICKER_TYPE9_IMG_3 = IMAGES.sticker_type9_3;
export const STICKER_TYPE9_IMG_4 = IMAGES.sticker_type9_4;
export const STICKER_TYPE9_IMG_5 = IMAGES.sticker_type9_5;
export const STICKER_TYPE9_IMG_6 = IMAGES.sticker_type9_6;
export const STICKER_TYPE9_IMG_7 = IMAGES.sticker_type9_7;
export const STICKER_TYPE9_IMG_8 = IMAGES.sticker_type9_8;
export const STICKER_TYPE9_IMG_9 = IMAGES.sticker_type9_9;
export const STICKER_TYPE9_IMG_10 = IMAGES.sticker_type9_10;
//array
export const ARRAY_STICKER_TYPE9 = [
    { name: STICKER_TYPE9_NAME_1, image: STICKER_TYPE9_IMG_1 },
    { name: STICKER_TYPE9_NAME_2, image: STICKER_TYPE9_IMG_2 },
    { name: STICKER_TYPE9_NAME_3, image: STICKER_TYPE9_IMG_3 },
    { name: STICKER_TYPE9_NAME_4, image: STICKER_TYPE9_IMG_4 },
    { name: STICKER_TYPE9_NAME_5, image: STICKER_TYPE9_IMG_5 },
    { name: STICKER_TYPE9_NAME_6, image: STICKER_TYPE9_IMG_6 },
    { name: STICKER_TYPE9_NAME_7, image: STICKER_TYPE9_IMG_7 },
    { name: STICKER_TYPE9_NAME_8, image: STICKER_TYPE9_IMG_8 },
    { name: STICKER_TYPE9_NAME_9, image: STICKER_TYPE9_IMG_9 },
    { name: STICKER_TYPE9_NAME_10, image: STICKER_TYPE9_IMG_10 },
];

//==========sticker-type10
//type
export const STICKER_TYPE10_TYPE = 'type10';
//title
export const STICKER_TYPE10_TITLE = 'Tom Cat';
//name
export const STICKER_TYPE10_NAME_1 = 'sticker-type10-1';
export const STICKER_TYPE10_NAME_2 = 'sticker-type10-2';
export const STICKER_TYPE10_NAME_3 = 'sticker-type10-3';
export const STICKER_TYPE10_NAME_4 = 'sticker-type10-4';
export const STICKER_TYPE10_NAME_5 = 'sticker-type10-5';
export const STICKER_TYPE10_NAME_6 = 'sticker-type10-6';
export const STICKER_TYPE10_NAME_7 = 'sticker-type10-7';
export const STICKER_TYPE10_NAME_8 = 'sticker-type10-8';
export const STICKER_TYPE10_NAME_9 = 'sticker-type10-9';
export const STICKER_TYPE10_NAME_10 = 'sticker-type10-10';
//image
export const STICKER_TYPE10_IMG_1 = IMAGES.sticker_type10_1;
export const STICKER_TYPE10_IMG_2 = IMAGES.sticker_type10_2;
export const STICKER_TYPE10_IMG_3 = IMAGES.sticker_type10_3;
export const STICKER_TYPE10_IMG_4 = IMAGES.sticker_type10_4;
export const STICKER_TYPE10_IMG_5 = IMAGES.sticker_type10_5;
export const STICKER_TYPE10_IMG_6 = IMAGES.sticker_type10_6;
export const STICKER_TYPE10_IMG_7 = IMAGES.sticker_type10_7;
export const STICKER_TYPE10_IMG_8 = IMAGES.sticker_type10_8;
export const STICKER_TYPE10_IMG_9 = IMAGES.sticker_type10_9;
export const STICKER_TYPE10_IMG_10 = IMAGES.sticker_type10_10;
//array
export const ARRAY_STICKER_TYPE10 = [
    { name: STICKER_TYPE10_NAME_1, image: STICKER_TYPE10_IMG_1 },
    { name: STICKER_TYPE10_NAME_2, image: STICKER_TYPE10_IMG_2 },
    { name: STICKER_TYPE10_NAME_3, image: STICKER_TYPE10_IMG_3 },
    { name: STICKER_TYPE10_NAME_4, image: STICKER_TYPE10_IMG_4 },
    { name: STICKER_TYPE10_NAME_5, image: STICKER_TYPE10_IMG_5 },
    { name: STICKER_TYPE10_NAME_6, image: STICKER_TYPE10_IMG_6 },
    { name: STICKER_TYPE10_NAME_7, image: STICKER_TYPE10_IMG_7 },
    { name: STICKER_TYPE10_NAME_8, image: STICKER_TYPE10_IMG_8 },
    { name: STICKER_TYPE10_NAME_9, image: STICKER_TYPE10_IMG_9 },
    { name: STICKER_TYPE10_NAME_10, image: STICKER_TYPE10_IMG_10 },
];

//array all stickers ===================================================================
export const ARRAY_ALL_STICKER = [
    { stickerArray: ARRAY_STICKER_HEART, title: STICKER_HEART_TITLE },
    { stickerArray: ARRAY_STICKER_GIRL, title: STICKER_GUY_TITLE },
    { stickerArray: ARRAY_STICKER_ANIMAL, title: STICKER_ANIMAL_TITLE },
    { stickerArray: ARRAY_STICKER_TIGER, title: STICKER_TIGER_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE1, title: STICKER_TYPE1_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE2, title: STICKER_TYPE2_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE3, title: STICKER_TYPE3_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE4, title: STICKER_TYPE4_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE5, title: STICKER_TYPE5_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE6, title: STICKER_TYPE6_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE7, title: STICKER_TYPE7_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE8, title: STICKER_TYPE8_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE9, title: STICKER_TYPE9_TITLE },
    { stickerArray: ARRAY_STICKER_TYPE10, title: STICKER_TYPE10_TITLE },
];

//array all images stickers
export const ARRAY_ALL_IMAGES_STICKER = [
    ...ARRAY_STICKER_HEART,
    ...ARRAY_STICKER_GUY,
    ...ARRAY_STICKER_GIRL,
    ...ARRAY_STICKER_ANIMAL,
    ...ARRAY_STICKER_TIGER,
    ...ARRAY_STICKER_TYPE1,
    ...ARRAY_STICKER_TYPE2,
    ...ARRAY_STICKER_TYPE3,
    ...ARRAY_STICKER_TYPE4,
    ...ARRAY_STICKER_TYPE5,
    ...ARRAY_STICKER_TYPE6,
    ...ARRAY_STICKER_TYPE7,
    ...ARRAY_STICKER_TYPE8,
    ...ARRAY_STICKER_TYPE9,
    ...ARRAY_STICKER_TYPE10,
];
