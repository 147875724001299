import React from 'react';
import list from '../list.module.scss';
import * as IMAGES from '../../../common/Images';
import modalWriteLetter from '../../../../styles/react-modal/modalWriteLetter.style';
import StatSenderDetail from '../../StatSenderDetail';
import Modal from 'react-modal';
import useDetailStatistic from '../../../../hooks/statisticsTable/useDetailStatistic';

export default function StatSenderItem({ item }) {
    const getDetail = () => {
        detailStatistic.openDetailStatModal(item[0]?.sender_external_id);
    };
    const detailStatistic = useDetailStatistic(item);

    return (
        <div className={list.send_stat_item}>
            <div className={list.send_stat_profile}>
                <img
                    src={item[0]?.avatar?.length ? item[0]?.avatar : IMAGES.profile_img_empty}
                    className={list.send_stat_photo}
                    alt=""
                />
                <div className={list.send_stat_name}>{`${item[0]?.name}, ${item[0]?.age}`}</div>
                <div className={list.send_stat_id}>ID {item[0]?.sender_external_id}</div>
            </div>
            <div className={list.send_stat_item_right}>
                <div className={list.send_stat_item_list}>
                    <div className={list.send_stat_item_head}>
                        {/*<div className={`${list.send_stat_head_col} ${list.status}`}>Status</div>*/}
                        <div className={`${list.send_stat_head_col} ${list.content}`}>Message content</div>
                        <div className={`${list.send_stat_head_col} ${list.date}`}>Date</div>
                        <div className={`${list.send_stat_head_col} ${list.clear}`}>Clear</div>
                        <div className={`${list.send_stat_head_col} ${list.indirect}`}>Indirect</div>
                        <div className={`${list.send_stat_head_col} ${list.clear}`}>Not Answer</div>
                        <div className={`${list.send_stat_head_col} ${list.indirect}`}>Effective</div>
                    </div>
                    <div className={list.send_stat_item_body}>
                        {item?.map((msg) => (
                            <div key={msg?.id} className={list.send_stat_item_row}>
                                {/*<div className={`${list.send_stat_item_col} ${list.status}`}>*/}
                                {/*	<div className={`${list.send_stat_item_status} ${msg?.status ? list.active : ''}`}/>*/}
                                {/*</div>*/}
                                <div
                                    className={`${list.send_stat_item_col} ${list.content}`}
                                    title={msg?.message_content}
                                >
                                    {msg?.message_content}
                                </div>
                                <div className={`${list.send_stat_item_col} ${list.date}`}>{msg?.date}</div>
                                <div className={`${list.send_stat_item_col} ${list.clear}`}>{msg?.clear}</div>
                                <div className={`${list.send_stat_item_col} ${list.indirect}`}>{msg?.indirect}</div>
                                <div className={`${list.send_stat_item_col} ${list.not_answer}`}>{msg?.not_answer}</div>
                                <div className={`${list.send_stat_item_col} ${list.effective}`}>
                                    {(((+msg?.clear + +msg?.indirect) / +msg?.all_count) * 100).toFixed(2)}%
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={list.send_stat_item_detail_btn} onClick={getDetail}>
                    Detail
                </div>
            </div>
            <Modal
                style={modalWriteLetter}
                isOpen={detailStatistic?.detailStatOpen}
                onRequestClose={detailStatistic?.closeDetailStatModal}
                ariaHideApp={false}
            >
                <StatSenderDetail profile={item} closeWriteLetterModal={detailStatistic?.closeDetailStatModal} />
            </Modal>
        </div>
    );
}
