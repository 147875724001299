import React from 'react';
import { useDispatch } from 'react-redux';
import Emoji from 'react-emoji-render';
import AudioPlayer from '../../../common/AudioPlayer';
import * as modalsAction from '../../../../store/actions/modals';
import * as STICKERS from '../../../../constants/stickers';
import * as CHAT from '../../../../constants/chat';
import { stopAudio } from '../../../../services/methods/chat';

export default ({ data }) => {
    const dispatch = useDispatch();

    if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_IMAGE) {
        const openContentModal = () => {
            dispatch(
                modalsAction.openPhotoContentModal({
                    link: data?.message_popup,
                    attachment_payed: '1',
                }),
            );
        };

        return (
            <div className="message_text">
                <img src={data?.message_thumb} alt="" className="message_img" onClick={openContentModal} />
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_AUDIO) {
        return (
            <div className="message_text">
                <div className="message_audio_wrap">
                    <div className="c3_message_audio_title">{data?.filename}</div>
                    <AudioPlayer src={data?.message_content} viewedAudio={() => stopAudio(data?.message_content)} />
                </div>
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_VIDEO) {
        const openContentModal = () => {
            dispatch(
                modalsAction.openVideoContentModal({
                    link: data?.message_content,
                    attachment_payed: '1',
                }),
            );
        };

        return (
            <div className="message_text">
                <div className="message_video" onClick={openContentModal}>
                    <video width="320" height="113">
                        <source src={data?.message_content} type="video/mp4" />
                    </video>
                </div>
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_TEXT) {
        return (
            <div className="message_text">
                <Emoji text={data?.message_content} />
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_WINK) {
        return (
            <div className="message_text wink">
                <img src="/img/wink.svg" alt="" />
            </div>
        );
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_STICKER) {
        const selectedSticker = STICKERS.ARRAY_ALL_IMAGES_STICKER.find((e) =>
            e?.name?.includes(data?.message_content),
        )?.image;
        return <div className="message_text">{selectedSticker && <img src={selectedSticker} alt="" />}</div>;
    } else if (data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_LIKE) {
        return (
            <div className="message_text like">
                <img src="/img/c4-like.svg" alt="" />
                <Emoji text={data?.message_content} />
            </div>
        );
    } else if (
        data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_VIEW ||
        data?.message_type === CHAT.MESSAGE_CONTENT_TYPE_VIEW_PHOTOS
    ) {
        return (
            <div className="message_text like">
                <img src="/img/c4-viwed.svg" alt="" />
                <Emoji text={data?.message_content} />
            </div>
        );
    } else {
        return (
            <div className="message_text">
                <Emoji text={data?.message_content} />
            </div>
        );
    }
};
