export const PROFILES_ADD = 'PROFILES_ADD';
export const PROFILES_ADD_SENDER_INFO = 'PROFILES_ADD_SENDER_INFO';
export const PROFILES_REMOVE_ACTIVE = 'PROFILES_REMOVE_ACTIVE';
export const PROFILES_CHECK_SENDER_INFO = 'PROFILES_CHECK_SENDER_INFO';
export const SET_ALL_ONLINE_STATUS = 'SET_ALL_ONLINE_STATUS';
export const SET_ONLINE_STATUS_BY_EXTERNAL_ID = 'SET_ONLINE_STATUS_BY_EXTERNAL_ID';
export const PROFILES_SET_ACTIVE = 'PROFILES_SET_ACTIVE';
export const SET_OPERATOR_ID = 'SET_OPERATOR_ID';

export const PROFILES_GET = 'PROFILES_GET';
export const PROFILES_SET_ACTIVE_FOR_SENDER = 'PROFILES_SET_ACTIVE_FOR_SENDER';
export const SET_REFFERENCIES = 'SET_REFFERENCIES';
