import * as SYNC from '../constants/sync';

const initState = {
    provider: null,
};

export default function (state = initState, action) {
    switch (action.type) {
        case SYNC.INIT:
            return {
                ...state,
                ...{
                    provider: action.data,
                },
            };
        case SYNC.REMOVE:
            return {
                ...state,
                ...{
                    provider: action.data,
                },
            };
        default:
            return state;
    }
}
