import React from 'react';

import AttachAudioPlayer from '../../../../common/AttachAudioPlayer';

import FilesApi from '../../../../../services/api/FilesApi';
import { cutLengthString } from '../../../../../services/methods';
import * as LENGTH from '../../../../../constants/length';
import * as ATTACH from '../../../../../constants/attach';
import { makeCDN } from '../../../../../services/methods';

export default ({ checked, elementRef, data, opacity, userId }) => {
    const handleInputChangeAudio = (e) => {
        const checkBox = e.currentTarget.querySelector('input');
        if (checkBox) {
            checkBox.checked = !checkBox.checked;
        }
    };

    const handleSort = (e) => {
        const listItems = e.currentTarget.parentNode.querySelectorAll('.upload_popup_tabs_content_audio_item');
        const sortedArr = [];

        for (let [key, item] of listItems.entries()) {
            if (item.dataset.id) {
                sortedArr.push({
                    id: item.dataset.id,
                    sort_order: key,
                });
            }
        }

        new FilesApi().postUpdateSortOrder(ATTACH.UPD_SORT_AUDIO, sortedArr, userId);
    };

    return (
        <div
            className="upload_popup_tabs_content_audio_item"
            ref={elementRef}
            style={{ opacity }}
            data-id={data.id}
            onDrop={handleSort}
        >
            <div className="popup_audio_item_top">
                <div className="popup_audio_item_play_btn">
                    <AttachAudioPlayer src={makeCDN(data.link, 'AUDIO')} />
                </div>
                <div className="no-drag popup_audio_item_check" onClick={handleInputChangeAudio}>
                    <input
                        type="checkbox"
                        className="no-drag"
                        name={data.id}
                        checked={checked}
                        id={'ap' + data.id}
                        data-link={data.link}
                        data-filename={data.filename}
                        data-contentid={data.id}
                        onChange={handleInputChangeAudio}
                    />
                    <label htmlFor={'ap' + data.id} className="no-drag" />
                </div>
            </div>
            <div className="popup_audio_item_bottom">
                <div className="popup_audio_item_name">{cutLengthString(data.filename, LENGTH.MAX_AUDIO_TITLE)}</div>
            </div>
        </div>
    );
};
