import React from 'react';

import Header from './Header';
import ManList from './ManList';
import * as IMAGES from '../Images';

export default ({ openWriteLetterModal, closeComposeModal }) => {
    return (
        <div className="popup_compose_letter" data-testid="new-letter-modal">
            <Header />
            <ManList />
            <div className="popup_compose_add_men_btn" onClick={openWriteLetterModal} data-testid="add-btn">
                <span>Add men</span>
            </div>
            <div className="close_one_popup" onClick={closeComposeModal} data-testid="close-btn">
                <img src={IMAGES.popup_gift_close} alt="" />
            </div>
        </div>
    );
};
