import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import moment from 'moment';

import modalContentAttach from '../../../styles/react-modal/modalContent.style';
import HeaderProfile from '../ProfileManHeader';
import ProfileApi from '../../../services/api/ProfileApi';
import GallerySlider from '../../common/GallerySlider';
import PhotoPreview from '../../common/PhotoPreview';
import { tillBirth } from '../../../services/time';
import LoaderGif from '../../common/LoaderGif';
import * as IMAGES from '../../common/Images';

export default ({ external_id }) => {
    const [user_info, setuserInfo] = useState({});
    const [galleryContent, setGalleryContent] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [modalOpenGallery, setmodalOpenGallery] = useState(false);
    const [modalOpenPhoto, setModalOpenPhoto] = useState(false);

    useEffect(() => {
        new ProfileApi().getManProfileInfo(external_id).then((response) => {
            if (response?.status) {
                setuserInfo(response?.user_info);

                const {
                    user_info: { user_content = [] },
                } = response;
                const images = user_content.filter((item) => item?.content_type === 'image');
                setGalleryContent(images);
            }
        });
    }, []);

    const USER_HOBBY = () => {
        if (user_info.user_hobby && user_info.user_hobby.length > 0) {
            let hobby_names = [];
            user_info.user_hobby.map((item) => hobby_names.push(item.hobbie));
            return hobby_names.join(', ');
        } else {
            return <span className="list-item-value-danger">not specified</span>;
        }
    };
    const MATCH_HOBBY = () => {
        if (user_info.user_hobby_match && user_info.user_hobby_match.length > 0) {
            let hobby_names = [];
            user_info.user_hobby_match.map((item) => hobby_names.push(item.hobbie));
            return hobby_names.join(', ');
        } else {
            return <span className="list-item-value-danger">not specified</span>;
        }
    };

    const USER_AVATAR = () => {
        if (
            user_info.user_detail &&
            (user_info.user_detail.photo_link !== null || user_info.user_detail.photo_link !== '')
        ) {
            return <img src={user_info.user_detail.photo_link} alt="" className="man_profile_photo" />;
        } else {
            return [];
        }
    };

    const prevImage = () =>
        setGalleryContent([
            galleryContent[galleryContent.length - 1],
            ...galleryContent.filter((item, index) => index !== galleryContent.length - 1),
        ]);
    const nextImage = () =>
        setGalleryContent([...galleryContent.filter((item, index) => index !== 0), galleryContent[0]]);

    const onSelectImage = (index) => {
        setSelectedIndex(index);
        openGalleryModal();
    };

    const openGalleryModal = () => {
        setmodalOpenGallery(true);
    };
    const closeGalleryModal = () => {
        setmodalOpenGallery(false);
    };

    const closePhoto = () => {
        setModalOpenPhoto(false);
    };

    if (!user_info || !user_info.user_detail) return null;
    const photo_link = user_info.user_detail.photo_link;

    const onlineStatus = +user_info?.user_detail?.online ? `online` : `offline`;

    return (
        <>
            <div className="column-3 profile_man profile_flex">
                {user_info?.user_detail ? (
                    <>
                        <HeaderProfile user_info={user_info} />
                        <main>
                            <div className="container">
                                <div className="main-content">
                                    <div className="main-content-header">
                                        <div className="main-content-header-row-flex">
                                            <div className="man_profile_photo_wrap">{USER_AVATAR()}</div>
                                            <div className="content-info-header">
                                                <div className="content-info__initials">
                                                    <div className="info-firstline">
                                                        <span className="info__name" data-testid="name-age">
                                                            {user_info.user_detail ? user_info.user_detail.name : ''}
                                                            {user_info.user_detail &&
                                                            parseInt(user_info.user_detail.age) > 0
                                                                ? ', ' + user_info.user_detail.age
                                                                : ''}
                                                        </span>
                                                        <span style={{ display: 'none' }} className="info__id">
                                                            ID{' '}
                                                            {user_info.user_detail
                                                                ? user_info.user_detail.external_id
                                                                : ''}
                                                        </span>
                                                    </div>
                                                    <span className="info__id" data-testid={'man-id'}>
                                                        ID{' '}
                                                        {user_info.user_detail ? user_info.user_detail.external_id : ''}
                                                    </span>
                                                    <div className="info-date">
                                                        <span className="date-birthday" data-testid="date-birthday">
                                                            {user_info.user_detail
                                                                ? moment(
                                                                      user_info.user_detail.b_year +
                                                                          '-' +
                                                                          (+user_info.user_detail.b_month < 10
                                                                              ? '0' + user_info.user_detail.b_month
                                                                              : user_info.user_detail.b_month) +
                                                                          '-' +
                                                                          (+user_info.user_detail.b_day < 10
                                                                              ? '0' + user_info.user_detail.b_day
                                                                              : user_info.user_detail.b_day),
                                                                  ).format('MMMM D, YYYY')
                                                                : ''}
                                                        </span>
                                                        <span className="date-birthday-till">
                                                            {tillBirth(
                                                                moment(
                                                                    user_info.user_detail.b_year +
                                                                        '-' +
                                                                        (+user_info.user_detail.b_month < 10
                                                                            ? '0' + user_info.user_detail.b_month
                                                                            : user_info.user_detail.b_month) +
                                                                        '-' +
                                                                        (+user_info.user_detail.b_day < 10
                                                                            ? '0' + user_info.user_detail.b_day
                                                                            : user_info.user_detail.b_day),
                                                                ),
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="info-place">
                                                        {user_info.user_detail ? (
                                                            <img
                                                                src={user_info.user_detail.country_image}
                                                                alt=""
                                                                className="place-flag"
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                        <span className="place-info" data-testid="place">
                                                            {user_info.user_reference
                                                                ? user_info.user_reference.city_name
                                                                : ''}
                                                            ,{' '}
                                                            {user_info.user_detail
                                                                ? user_info.user_detail.country_name
                                                                : ''}
                                                        </span>
                                                    </div>
                                                </div>
                                                {onlineStatus === 'online' && (
                                                    <span className="info__id">
                                                        <div
                                                            className={`c3lh_your_likes_item_profile_${onlineStatus} display_online_on_profile_page`}
                                                        >
                                                            {onlineStatus}
                                                        </div>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="content-info-about">
                                            <div className="info-about-parts">
                                                <div className="info-about-block">
                                                    <lh className="list-header">Appearance</lh>
                                                    <ol className="list">
                                                        <li className="list-item">
                                                            <span className="list-item-header">Height: </span>
                                                            {user_info.user_reference &&
                                                            user_info.user_reference.height !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_reference.height}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </li>
                                                        <li className="list-item">
                                                            <span className="list-item-header">Body type: </span>
                                                            {user_info.user_reference &&
                                                            user_info.user_reference.body !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_reference.body}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </li>
                                                        <li className="list-item">
                                                            <span className="list-item-header">Hair color: </span>
                                                            {user_info.user_reference &&
                                                            user_info.user_reference.hair !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_reference.hair}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </li>
                                                        <li className="list-item">
                                                            <span className="list-item-header">Eyes color: </span>
                                                            {user_info.user_reference &&
                                                            user_info.user_reference.eye !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_reference.eye}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </li>
                                                        <li className="list-item">
                                                            <span className="list-item-header">Zodiac: </span>
                                                            {user_info.user_reference &&
                                                            user_info.user_reference.zodiac !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_reference.zodiac}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </li>
                                                    </ol>
                                                </div>
                                            </div>
                                            <div className="info-about-block block-2">
                                                <lh className="list-header">Hobby and Habbits</lh>
                                                <ol className="list">
                                                    <li className="list-item">
                                                        <span className="list-item-header">Hobby: </span>{' '}
                                                        <span className="list-item-value">{USER_HOBBY()}</span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">Alcohol: </span>
                                                        {user_info.user_reference &&
                                                        user_info.user_reference.alcohol !== null ? (
                                                            <span className="list-item-value">
                                                                {user_info.user_reference.alcohol}
                                                            </span>
                                                        ) : (
                                                            <span className="list-item-value-danger">
                                                                not specified
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">Smoking:</span>
                                                        {user_info.user_reference &&
                                                        user_info.user_reference.smoke !== null ? (
                                                            <span className="list-item-value">
                                                                {user_info.user_reference.smoke}
                                                            </span>
                                                        ) : (
                                                            <span className="list-item-value-danger">
                                                                not specified
                                                            </span>
                                                        )}
                                                    </li>
                                                </ol>
                                            </div>
                                            <div className="social-info">
                                                <lh className="list-header">Social information</lh>
                                                <ol className="list">
                                                    <li className="list-item">
                                                        <span className="list-item-header">Education: </span>
                                                        {user_info.user_reference &&
                                                        user_info.user_reference.education !== null ? (
                                                            <span className="list-item-value">
                                                                {user_info.user_reference.education}
                                                            </span>
                                                        ) : (
                                                            <span className="list-item-value-danger">
                                                                not specified
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">Job or Profession: </span>
                                                        {user_info.user_reference &&
                                                        user_info.user_reference.occupation !== null ? (
                                                            <span className="list-item-value">
                                                                {user_info.user_reference.occupation}
                                                            </span>
                                                        ) : (
                                                            <span className="list-item-value-danger">
                                                                not specified
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">Religion: </span>
                                                        {user_info.user_reference &&
                                                        user_info.user_reference.religion !== null ? (
                                                            <span className="list-item-value">
                                                                {user_info.user_reference.religion}
                                                            </span>
                                                        ) : (
                                                            <span className="list-item-value-danger">
                                                                not specified
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">Marital status: </span>
                                                        {user_info.user_reference &&
                                                        user_info.user_reference.marital !== null ? (
                                                            <span className="list-item-value">
                                                                {user_info.user_reference.marital}
                                                            </span>
                                                        ) : (
                                                            <span className="list-item-value-danger">
                                                                not specified
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">Number of children: </span>
                                                        {user_info.user_reference &&
                                                        user_info.user_reference.children !== null ? (
                                                            <span className="list-item-value">
                                                                {user_info.user_reference.children}
                                                            </span>
                                                        ) : (
                                                            <span className="list-item-value-danger">
                                                                not specified
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Want to have more children:{' '}
                                                        </span>
                                                        {user_info.user_reference &&
                                                        user_info.user_reference.morechildren !== null ? (
                                                            <span className="list-item-value">
                                                                {user_info.user_reference.morechildren}
                                                            </span>
                                                        ) : (
                                                            <span className="list-item-value-danger">
                                                                not specified
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Purpose of staying on the site:{' '}
                                                        </span>
                                                        {user_info.user_reference &&
                                                        user_info.user_reference.reason !== null ? (
                                                            <span className="list-item-value">
                                                                {user_info.user_reference.reason}
                                                            </span>
                                                        ) : (
                                                            <span className="list-item-value-danger">
                                                                not specified
                                                            </span>
                                                        )}
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="main-content-about">
                                    <div className="about-content-container">
                                        <div className="about-profile-text">
                                            <div className="text-node">
                                                <div className="node-header">MY self-summary</div>
                                                <div className="node-about">
                                                    {user_info.user_reference &&
                                                    user_info.user_reference.summary !== null
                                                        ? user_info.user_reference.summary
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="text-node">
                                                <div className="node-header">Who AM I looking for</div>
                                                <div className="node-about">
                                                    {user_info.user_reference &&
                                                    user_info.user_reference.looking !== null
                                                        ? user_info.user_reference.looking
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <lh className="node-header">My perfect Match</lh>
                                        <div className="list-of-lists">
                                            <div className="text-elem">
                                                <lh className="node-header__section">Girl’s appearance</lh>
                                                <ol className="list">
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Girl’s age: from&nbsp;
                                                            {user_info.user_match &&
                                                            user_info.user_match.girls_age_from !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.girls_age_from}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}{' '}
                                                            to&nbsp;
                                                            {user_info.user_match &&
                                                            user_info.user_match.girls_age_to !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.girls_age_to}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Height: from&nbsp;
                                                            {user_info.user_match &&
                                                            user_info.user_match.girls_height_from_ !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.girls_height_from_}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}{' '}
                                                            to&nbsp;
                                                            {user_info.user_match &&
                                                            user_info.user_match.girls_height_to_ !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.girls_height_to_}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Body type:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.body !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.body}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}{' '}
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Hair color:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.hair !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.hair}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}{' '}
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Eyes color:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.eye !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.eye}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}{' '}
                                                        </span>
                                                    </li>
                                                </ol>
                                            </div>
                                            <div className="text-elem">
                                                <lh className="node-header__section">Social preferences</lh>
                                                <ol className="list">
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Education:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.education !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.education}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Job or Profession:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.occupation !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.occupation}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}{' '}
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Religion:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.religion !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.religion}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}{' '}
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Have children:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.children !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.children}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Want to have more children:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.morechildren !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.morechildren}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </span>
                                                    </li>
                                                </ol>
                                            </div>
                                            <div className="text-elem">
                                                <lh className="node-header__section">Girl’s hobby and habits</lh>
                                                <ol className="list">
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Hobby:{' '}
                                                            <span className="list-item-value">{MATCH_HOBBY()}</span>
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Alcohol:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.alcohol !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.alcohol}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li className="list-item">
                                                        <span className="list-item-header">
                                                            Smoking:{' '}
                                                            {user_info.user_match &&
                                                            user_info.user_match.smoke !== null ? (
                                                                <span className="list-item-value">
                                                                    {user_info.user_match.smoke}
                                                                </span>
                                                            ) : (
                                                                <span className="list-item-value-danger">
                                                                    not specified
                                                                </span>
                                                            )}
                                                        </span>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                        <lh className="node-header">
                                            photos ({user_info.user_content ? user_info.user_content.length : 0})
                                        </lh>

                                        {/*
								<div className="content-slider">
									<div className="slider-btns">
										<button><img className="arrow-btn-left arrow-btn" src="/img/c3/arrow-btn.svg"
													 alt=""/></button>
										<button><img className="arrow-btn-right arrow-btn" src="/img/c3/btn-right.svg"
													 alt=""/></button>
									</div>
									{(user_info.user_content && user_info.user_content.length > 0) ?
										user_info.user_content.map((item, key) => ((
												<div key={key} className="slider-img"><img src={item.link} alt=""
																						   className="scrollbar-item"/>
												</div>
											)
										))
										:
										''
									}
								</div>
								*/}

                                        <div className="mp_slider_wrap">
                                            {galleryContent.length > 1 && (
                                                <div className="slider-btns">
                                                    <button onClick={prevImage}>
                                                        <img
                                                            className="arrow-btn-left arrow-btn"
                                                            src={IMAGES.arrow_btn}
                                                            alt=""
                                                        />
                                                    </button>
                                                    <button onClick={nextImage}>
                                                        <img
                                                            className="arrow-btn-right arrow-btn"
                                                            src={IMAGES.btn_right}
                                                            alt=""
                                                        />
                                                    </button>
                                                </div>
                                            )}
                                            <div className="content-slider">
                                                <div className="mp_slider_list">
                                                    {galleryContent.map((item, key) => (
                                                        <div
                                                            key={key}
                                                            className="slider-img"
                                                            onClick={() => onSelectImage(key)}
                                                        >
                                                            <img src={item.link} alt="" className="scrollbar-item" />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                ) : (
                    <LoaderGif />
                )}
            </div>

            <Modal
                style={modalContentAttach}
                isOpen={modalOpenGallery}
                onRequestClose={closeGalleryModal}
                ariaHideApp={false}
            >
                <GallerySlider
                    gallery={galleryContent}
                    selectedIndex={selectedIndex}
                    closeGalleryModal={closeGalleryModal}
                />
            </Modal>
            <Modal style={modalContentAttach} isOpen={modalOpenPhoto} onRequestClose={closePhoto} ariaHideApp={false}>
                <PhotoPreview image={photo_link} closePhoto={closePhoto} />
            </Modal>
        </>
    );
};
