import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import modalContentAttach from '../../../../styles/react-modal/modalContent.style';
import Modal from 'react-modal';
import * as modalsAction from '../../../../store/actions/modals';
import VideoContent from './VideoContent';

export default () => {
    const dispatch = useDispatch();
    const modalsState = useSelector((state) => state.modals);

    const onCloseVideoContentModal = () => {
        dispatch(modalsAction.closeVideoContentModal());
    };

    return (
        <Modal
            style={modalContentAttach}
            isOpen={modalsState?.videoContentModal?.isActive}
            onRequestClose={onCloseVideoContentModal}
            ariaHideApp={false}
        >
            <VideoContent item={modalsState?.videoContentModal?.item} closeModal={onCloseVideoContentModal} />
        </Modal>
    );
};
