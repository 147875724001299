import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as blockListAction from '../../store/actions/blockList';

export default function useEffectToLoadBlockList() {
    const dispatch = useDispatch();
    const profilesState = useSelector((state) => state.profiles);
    const blockListState = useSelector((state) => state.blockList);

    useEffect(() => {
        (async function () {
            dispatch(
                blockListAction.addBlockList({
                    userId: profilesState?.active?.external_id ? profilesState?.active?.external_id : '',
                    maleBlock: blockListState?.theyTab,
                    filterSearch: blockListState?.filterSearch,
                }),
            );
        })();
    }, [profilesState?.active, blockListState?.theyTab, blockListState?.filterSearch]);
}
