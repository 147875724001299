import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import attachStyles from '../../../../../../styles/react-modal/attach.style';
import AttachModal from '../../../../../common/AttachModal';
import * as filesAction from '../../../../../../store/actions/files';
import { addBlur, removeBlur } from '../../../../../../services/dom/letters';

export default function Attach() {
    const dispatch = useDispatch();
    const profilesState = useSelector((state) => state.profiles);

    const [attachModalIsOpen, setAttachModalIsOpen] = useState(false);

    const womanProfile = profilesState?.list?.find(
        (woman) => +woman.external_id === +profilesState.active?.external_id,
    );

    const openAttachModal = () => {
        addBlur();
        setAttachModalIsOpen(true);
        dispatch(filesAction.getAllImages(+womanProfile?.external_id));
        dispatch(filesAction.getAllVideos(+womanProfile?.external_id));
        dispatch(filesAction.getAllAudios(+womanProfile?.external_id));
    };

    const closeAttachModal = () => {
        removeBlur();
        setAttachModalIsOpen(false);
    };

    const sendFile = async () => {
        // const checkedList = e.currentTarget.parentNode.parentNode.querySelectorAll('input:checked');
        //
        // if (checkedList.length > 0) {
        // 	closeAttachModal();
        //
        // 	for (let i = 0; i < checkedList.length; i++) {
        // 		const hashId = md5(moment().format()) + i;
        //
        // 		dispatch(currentChatAction.addMessageToHistory(insertedMessageBeforeSync(hashId, checkedList[i].dataset.link, +womanProfile.external_id, type)));
        // 		await new ChatsApi()
        // 			.postMessage(+womanProfile.external_id, onlineManState.currentChat.external_id, checkedList[i].dataset.link, type, checkedList[i].dataset.filename)
        // 			.then(res => {
        // 				if (!res?.message_id?.msg?.message_object || !res?.message_id?.channel) {
        // 					dispatch(currentChatAction.deleteMessageInHistory(hashId));
        // 					if(res?.error) dispatch(errorsAction.setMessage(res?.error));
        // 					else dispatch(errorsAction.setMessage(ERROR.INTERNAL_ERROR_OCCURRED));
        // 					return;
        // 				}
        // 				if (res?.status) {
        // 					dispatch(currentChatAction.updateMessageInHistory(hashId, res.message_id.msg.message_object));
        // 					syncProvider.publish(res.message_id.channel, res.message_id.msg);
        // 				} else {
        // 					dispatch(currentChatAction.deleteMessageInHistory(hashId));
        // 					if(res?.error) dispatch(errorsAction.setMessage(res?.error));
        // 					else dispatch(errorsAction.setMessage(ERROR.INTERNAL_ERROR_OCCURRED));
        // 				}
        // 			})
        // 	}
        // }
    };

    return (
        <React.Fragment>
            <div className="c3_write_msg_ico_item c3_attach" onClick={openAttachModal}>
                <img src="../../../../../../img/c3-msg-attach.svg" alt="" />
            </div>
            <Modal
                style={attachStyles}
                isOpen={attachModalIsOpen}
                onRequestClose={closeAttachModal}
                ariaHideApp={false}
            >
                <AttachModal
                    closeAttachModal={closeAttachModal}
                    sendImg={(e) => sendFile(e, 'SENT_IMAGE')}
                    sendVideo={(e) => sendFile(e, 'SENT_VIDEO')}
                    sendAudio={(e) => sendFile(e, 'SENT_AUDIO')}
                    userId={+womanProfile?.external_id}
                />
            </Modal>
        </React.Fragment>
    );
}
