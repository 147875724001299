import React from 'react';
import styles from '../../../styles.module.scss';
import * as IMAGES from '../../../../common/Images';
import { makeCDN } from '../../../../../services/methods';

export default ({ data, elementRef, opacity, deleteUploadedImage }) => {
    return (
        <div className={styles.post_modal_img_wrap} ref={elementRef} style={{ opacity, cursor: 'grab' }}>
            <div className={styles.post_modal_img_delete_wrap}>
                <img
                    src={IMAGES.close_icon}
                    alt="Delete"
                    onClick={() => deleteUploadedImage(data?.file?.imageSmallUrl)}
                />
            </div>

            <img
                className={styles.post_modal_img}
                data-id={data?.id}
                src={makeCDN(data?.file?.imageSmallUrl, 'POSTS')}
                alt=""
            />
        </div>
    );
};
