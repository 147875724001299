import * as CHAT from '../../../constants/chat';
import { sendErrToSentry } from '../../sentry';

export function filterToHideChatHistoryByType(item) {
    return CHAT.CHAT_MESSAGE_CONTENT.includes(item?.message_type) && item?.message_content?.length;
}

export function stopAudio(src = null, isPlay = false) {
    try {
        const arrAudios = document.querySelectorAll('audio');

        for (let audio of arrAudios) {
            if (audio?.src === src) {
                if (isPlay) audio.play();
                continue;
            }
            audio.pause();
        }
    } catch (error) {
        sendErrToSentry(error);
    }
}
