import * as SETTINGS from '../constants/settings';

const initState = {
    multiChatList: null,
    interval_time: null,
};

export default function (state = initState, action) {
    switch (action.type) {
        case SETTINGS.MULTI_CHAT_LIST:
            return {
                ...state,
                ...{
                    multiChatList: action.multiChatList,
                },
            };
        case SETTINGS.INTERVAL_TIME:
            return {
                ...state,
                ...{
                    interval_time: action.interval_time,
                },
            };
        default:
            return state;
    }
}
