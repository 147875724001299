import ChatMultiApi from '../../services/api-multi/ChatMultiApi';
import moment from 'moment';

export default class ChatModel {
    static async getChatByChatUid(chat_uid) {
        const res = await new ChatMultiApi().chatInfoByChatUID(chat_uid);

        if (res?.status && res?.response) {
            return {
                chatList: [res.response],
            };
        }

        return {
            chatList: [],
        };
    }

    static async getChatLists(data) {
        const res = await new ChatMultiApi().getChatListByUserId(data);

        if (res?.status && res?.response?.length > 0) {
            return {
                chatList: res?.response,
                page: 1,
                listChatUid: res?.response?.map((item) => item?.chat_uid),
            };
        }

        return {
            chatList: [],
            page: 1,
            listChatUid: [],
        };
    }

    static async getUserDetail(chatUIds) {
        const res = await new ChatMultiApi().getUserDetail({ chat_uid: chatUIds });

        if (res?.status && res?.response?.length > 0) {
            let result = [];

            res.response.forEach((item) => {
                result[item?.chat_uid] = item;
            });

            return result;
        }

        return [];
    }

    static async getLastMessage(chatUIds) {
        const res = await new ChatMultiApi().getLastMessage({ chat_uid: chatUIds });

        if (res?.status && res?.response?.length > 0) {
            let result = [];

            res.response.forEach((item) => {
                result[item?.chat_uid] = item;
            });
            return result;
        }

        return [];
    }

    static async getUnreadCount(chatUIds) {
        const res = await new ChatMultiApi().getUnreadCount({ chat_uid: chatUIds });

        if (res?.status && res?.response?.length > 0) {
            let result = [];

            res.response.forEach((item) => {
                result[item?.chat_uid] = item;
            });
            return result;
        }

        return [];
    }

    static async getStoreOneChatList(chatUid) {
        const chatList = await ChatModel.getChatByChatUid(chatUid);

        if (!chatList?.chatList?.length) {
            return {
                chatList: chatList?.chatList,
            };
        }

        const asyncChatListInfo = await Promise.all([
            ChatModel.getUserDetail([chatUid]),
            ChatModel.getLastMessage([chatUid]),
            ChatModel.getUnreadCount([chatUid]),
        ]);

        const userDetails = asyncChatListInfo[0];
        const lastMessage = asyncChatListInfo[1];
        const unreadCount = asyncChatListInfo[2];

        return {
            chatList: chatList?.chatList
                .map((item) => ({
                    ...item,
                    ...userDetails[item?.chat_uid],
                    ...lastMessage[item?.chat_uid],
                    ...unreadCount[item?.chat_uid],
                    ...{ favorite: item?.male_favorite === 1 },
                    ...{ like: item?.male_like },
                    ...{ wink: +item?.wink === 1 },
                    ...{ last_message: lastMessage[item?.chat_uid]?.message_content },
                    ...{ last_message_date: lastMessage[item?.chat_uid]?.date_created },
                    ...{ last_message_type: lastMessage[item?.chat_uid]?.message_type },
                    ...{ recipient_id: lastMessage[item?.chat_uid]?.recipient_external_id },
                    ...{
                        id:
                            +lastMessage[item?.chat_uid]?.is_male === 1
                                ? lastMessage[item?.chat_uid]?.sender_external_id
                                : lastMessage[item?.chat_uid]?.recipient_external_id,
                    },
                    ...{
                        male_external_id:
                            +lastMessage[item?.chat_uid]?.is_male === 1
                                ? lastMessage[item?.chat_uid]?.sender_external_id
                                : lastMessage[item?.chat_uid]?.recipient_external_id,
                    },
                    ...{
                        female_external_id:
                            +lastMessage[item?.chat_uid]?.is_male === 0
                                ? lastMessage[item?.chat_uid]?.sender_external_id
                                : lastMessage[item?.chat_uid]?.recipient_external_id,
                    },
                    // unread_count: +item?.woman_unread_count,
                }))
                .sort((a, b) => {
                    if (moment(a.last_message_date) > moment(b.last_message_date)) return -1;
                    if (moment(a.last_message_date) < moment(b.last_message_date)) return 1;
                    return 0;
                }),
        };
    }

    static async getStoreChatList(data) {
        const chatList = await ChatModel.getChatLists(data);

        if (!chatList.listChatUid.length) {
            return {
                chatList: chatList.chatList,
                page: chatList.page,
            };
        }

        const asyncChatListInfo = await Promise.all([
            ChatModel.getUserDetail(chatList.listChatUid),
            ChatModel.getLastMessage(chatList.listChatUid),
            ChatModel.getUnreadCount(chatList.listChatUid),
        ]);

        const userDetails = asyncChatListInfo[0];
        const lastMessage = asyncChatListInfo[1];
        const unreadCount = asyncChatListInfo[2];

        return {
            chatList: chatList.chatList
                .map((item) => ({
                    ...item,
                    ...userDetails[item.chat_uid],
                    ...lastMessage[item.chat_uid],
                    ...unreadCount[item.chat_uid],
                    ...{ favorite: item.male_favorite === 1 },
                    ...{ like: item.male_like },
                    ...{ wink: +item.wink === 1 },
                    ...{ last_message: lastMessage[item.chat_uid]?.message_content },
                    ...{ last_message_date: lastMessage[item.chat_uid]?.date_created },
                    ...{ last_message_type: lastMessage[item?.chat_uid]?.message_type },
                    ...{ recipient_id: lastMessage[item.chat_uid]?.recipient_external_id },
                    ...{
                        id:
                            +lastMessage[item.chat_uid]?.is_male === 1
                                ? lastMessage[item.chat_uid]?.sender_external_id
                                : lastMessage[item.chat_uid]?.recipient_external_id,
                    },
                    ...{
                        male_external_id:
                            +lastMessage[item.chat_uid]?.is_male === 1
                                ? lastMessage[item.chat_uid]?.sender_external_id
                                : lastMessage[item.chat_uid]?.recipient_external_id,
                    },
                    ...{
                        female_external_id:
                            +lastMessage[item.chat_uid]?.is_male === 0
                                ? lastMessage[item.chat_uid]?.sender_external_id
                                : lastMessage[item.chat_uid]?.recipient_external_id,
                    },
                    // unread_count: +item?.woman_unread_count,
                }))
                .sort((a, b) => {
                    if (moment(a.last_message_date) > moment(b.last_message_date)) return -1;
                    if (moment(a.last_message_date) < moment(b.last_message_date)) return 1;
                    return 0;
                }),
            page: chatList.page,
        };
    }
}
