import * as currentChatAction from '../../store/actions/currentChat';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as chatsAction from '../../store/actions/chats';
import { useDispatch, useSelector } from 'react-redux';

export default function useLimitsSync() {
    const dispatch = useDispatch();
    const chatsState = useSelector((state) => state.chats);
    const currentChatState = useSelector((state) => state.currentChat);
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);

    return {
        getLimits: (message) => {
            // Update message limit in chat list if current chat exist
            const updChat = chatsState?.list.find(
                (item) =>
                    +item?.female_external_id === +message?.female_external_id &&
                    +item?.male_external_id === +message?.male_external_id,
            );
            if (updChat?.chat_uid) {
                dispatch(
                    chatsAction.updateMessageAndLikeLimit(
                        updChat?.chat_uid,
                        +message?.message_limit,
                        +message?.like_limit,
                        +message?.letter_limit,
                    ),
                );
            }

            // Update message limit in chat room if open current chat
            if (
                +currentChatState?.info?.female_external_id === +message?.female_external_id &&
                +currentChatState?.info?.male_external_id === +message?.male_external_id
            ) {
                dispatch(currentChatAction.updateMessageLimit(+message?.message_limit, +message?.like_limit));
            }

            // Update letter limit in letter room if open current Letter
            if (
                +currentChatMailboxState?.info?.female_id === +message?.female_external_id &&
                +currentChatMailboxState?.info?.male_id === +message?.male_external_id
            ) {
                dispatch(currentChatMailboxAction.updateLetterLimit(+message?.letter_limit));
            }
        },
    };
}
