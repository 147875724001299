import c3_msg_likeSVG from '../../../img/c3-msg-like.svg';
import c1_multi_heartSVG from '../../../img/c1-multi-heart.svg';
import c2_removeSVG from '../../../img/c2-remove.svg';
import c2_search_btnSVG from '../../../img/c2l-id-search-btn.svg';
import c4_replySVG from '../../../img/c4-reply.svg';
import profile_img_emptyJPG from '../../../img/profile_img_empty.jpg';
import sender_drafts_sendSVG from '../../../img/v2/sender_drafts_send.svg';
import sender_drafts_deleteSVG from '../../../img/v2/sender_drafts_delete.svg';
import sender_drafts_editSVG from '../../../img/v2/sender_drafts_edit.svg';
import sender_forms_saveSVG from '../../../img/v2/sender_forms_save.svg';
import sender_forms_photo_icoSVG from '../../../img/v2/sender_forms_photo_ico.svg';
import viewed_whiteSVG from '../../../img/v2/viewed-white.svg';
import liked_whiteSVG from '../../../img/v2/liked-white.svg';
import winked_whiteSVG from '../../../img/v2/winked-white.svg';
// import c4_reply_darkSVG from '../../../img/c4-reply-dark.svg';
import c3_opt_giftSVG from '../../../img/c3-opt-gift.svg';
import c3_opt_contactSVG from '../../../img/c3-opt-contact.svg';
import c3_opt_meetSVG from '../../../img/c3-opt-meet.svg';
import c3_opt_camSVG from '../../../img/c3-opt-cam.svg';
import logo_leftSVG from '../../../img/logo_left.svg';
import logo_right_whiteSVG from '../../../img/logo_right_white.svg';
import sub_menuSVG from '../../../img/v2/sub_menu.svg';
import check_circle_whiteSVG from '../../../img/check-circle-white.svg';
import popup_gift_closeSVG from '../../../img/popup-gift-close.svg';
import sender_forms_video_icoSVG from '../../../img/v2/sender_forms_video_ico.svg';
import sender_forms_audio_icoSVG from '../../../img/v2/sender_forms_audio_ico.svg';
import sender_forms_smile_icoSVG from '../../../img/v2/sender_forms_smile_ico.svg';
import sender_forms_sticker_icoSVG from '../../../img/v2/sender_forms_sticker_ico.svg';
import sender_forms_delete_mediaSVG from '../../../img/v2/sender_forms_delete_media.svg';
import sender_history_audio_playSVG from '../../../img/v2/sender_history_audio_play.svg';
import logoSVG from '../../../img/logo.svg';
// import avatar_defaultJPG from '../../../img/avatar_default.jpg';
import profile_icoSVG from '../../../img/profile_ico.svg';
import loadingGIF from '../../../img/loading.gif';
import c3_msg_smileSVG from '../../../img/c3-msg-smile.svg';
import c3_msg_stickerSVG from '../../../img/c3-msg-sticker.svg';
import arrow_left_purpleSVG from '../../../img/arrow-left-purple.svg';
import freeze_cubeSVG from '../../../img/v2/freeze-cube.svg';
import freeze_cube_fullSVG from '../../../img/v2/freeze-cube-full.svg';
import video_errorSVG from '../../../img/video-error.svg';
import c3_media_video_playSVG from '../../../img/c3-media-video-play.svg';
import viewed_greySVG from '../../../img/viewed-grey.svg';
import not_viewed_greySVG from '../../../img/not-viewed-grey.svg';
import alarmSVG from '../../../img/alarm.svg';
import c1b_profile_aSVG from '../../../img/v2/c1-menu/c1b-profile-a.svg';
import c1b_stats_aSVG from '../../../img/v2/c1-menu/c1b-stats-a.svg';
import c1b_sender_stats_aSVG from '../../../img/v2/c1-menu/c1b-sender-stats-a.svg';
import c1b_personal_aSVG from '../../../img/v2/c1-menu/c1b-personal-a.svg';
import c1b_on_off_aSVG from '../../../img/v2/c1-menu/c1b-on-off-a.svg';
import c1b_block_aSVG from '../../../img/v2/c1-menu/c1b-block-a.svg';
import c1b_tutorial_aSVG from '../../../img/v2/c1-menu/c1b-tutorial-a.svg';
import c1b_sign_out_aSVG from '../../../img/v2/c1-menu/c1b-sign-out-a.svg';
import left_arrowSVG from '../../../img/v2/left-arrow.svg';
import mob_filters_lettersSVG from '../../../img/v2/mob-filters-letters.svg';
import user_icoSVG from '../../../img/v2/user_ico.svg';
import calendarSVG from '../../../img/calendar.svg';
import popup_playSVG from '../../../img/popup-play.svg';
import stop_in_attach_fileSVG from '../../../img/stop_in_attach_file.svg';
import audio_volumeSVG from '../../../img/audio-volume.svg';
import chat_audio_volume_muteSVG from '../../../img/chat-audio-volume-mute.svg';
import popup_audio_delSVG from '../../../img/popup-audio-del.svg';
import slider_prevSVG from '../../../img/slider-prev.svg';
import slider_nextSVG from '../../../img/slider-next.svg';
import letter_audio_notesSVG from '../../../img/letter-audio-notes.svg';
import photo_fullSVG from '../../../img/photo-full.svg';
import trashSVG from '../../../img/trash.svg';
import moveSVG from '../../../img/move.svg';
import cross_whiteSVG from '../../../img/cross_white.svg';
import audio_playSVG from '../../../img/audio-play.svg';
import audio_pauseSVG from '../../../img/audio-pause.svg';
import c3l_add_more_men_btnSVG from '../../../img/c3l-add-more-men-btn.svg';
import popup_uploadSVG from '../../../img/popup-upload.svg';
import c4_likeSVG from '../../../img/c4-like.svg';
import c4_viwedSVG from '../../../img/c4-viwed.svg';
import c3wp_btn_prevSVG from '../../../img/c3wp-btn-prev.svg';
import c3wp_btn_nextSVG from '../../../img/c3wp-btn-next.svg';
import c3lh_item_big_closeSVG from '../../../img/c3lh-item-big-close.svg';
import arrow_btnSVG from '../../../img/arrow-left-purple.svg';
import btn_rightSVG from '../../../img/arrow-left-purple.svg';
import c3wp_tophotoSVG from '../../../img/c3wp-tophoto.svg';
import c3wp_tovideoSVG from '../../../img/c3wp-tovideo.svg';
import c4_write_attentionSVG from '../../../img/v2/c4_write_attention.svg';
import arrow_whiteSVG from '../../../img/v2/arrow-white.svg';

import heart_red from '../../../img/v2/heart-red.svg';
import c1_photo from '../../../img/v2/c1-photo.jpg';
import c1_sender from '../../../img/v2/c1-sender.svg';
import c1_gifts from '../../../img/v2/c1-gifts.svg';
import c1_letters from '../../../img/v2/c1-letters.svg';
import c1_mm_heart from '../../../img/v2/c1-mm-heart.svg';
import c1_mm_arrow from '../../../img/v2/c1-mm-arrow.svg';
import c4_head from '../../../img/v2/c4-head.svg';
import star from '../../../img/v2/star.svg';
import c4_play_audio_btn from '../../../img/v2/c4-play-audio-btn.svg';
import c4_audio_line from '../../../img/v2/c4-audio-line.svg';
import online_white from '../../../img/v2/online-white.svg';
import viewed_blue from '../../../img/v2/viewed-blue.svg';
import liked_blue from '../../../img/v2/liked-blue.svg';
import winked_blue from '../../../img/v2/winked-blue.svg';
import online_blue from '../../../img/v2/online-blue.svg';
import empty_chat from '../../../img/v2/empty_chat.svg';
import mob_typing from '../../../img/v2/mob-typing.svg';
import pc_typing from '../../../img/v2/pc-typing.svg';
import send_message from '../../../img/v2/send-message.svg';
import c2_chat_switch from '../../../img/v2/c2-chat-switch.svg';
import c2_profile from '../../../img/v2/c2-profile.svg';
import not_found_resultsSVG from '../../../img/v2/not_found_results.svg';
import chat_head_more_btnSVG from '../../../img/v2/chat_head_more_btn.svg';
import personal_empty_connectPNG from '../../../img/v2/personal_empty_connect.png';
import personal_empty_waitPNG from '../../../img/v2/personal_empty_wait.png';
import personal_connect_plusPNG from '../../../img/v2/personal_connect_plus.png';

import attach_close_icoSVG from '../../../img/attach-close-ico.svg';
import attach_file_drop_activeSVG from '../../../img/attach_file_drop_active.svg';
import attach_file_dropSVG from '../../../img/attach_file_drop.svg';
import attach_send_icoSVG from '../../../img/attach-send-ico.svg';
import attach_upload_icoSVG from '../../../img/attach-upload-ico.svg';

// import letter page
import c2_letter_whiteSVG from '../../../img/v2/c2-letter-white.svg';
import letter_option_answerSVG from '../../../img/v2/letter-option-answer.svg';
import letter_option_profileSVG from '../../../img/v2/letter-option-profile.svg';
import letter_option_deleteSVG from '../../../img/v2/letter-option-delete.svg';
import letter_option_restoreSVG from '../../../img/v2/letter-option-restore.svg';
import letter_option_chatSVG from '../../../img/v2/letter-option-chat.svg';
import letter_chat_deleteSVG from '../../../img/v2/letter-chat-delete.svg';
import letter_chat_bookmarkSVG from '../../../img/v2/letter-chat-bookmark.svg';
import letter_chat_bookmark_aSVG from '../../../img/v2/letter-chat-bookmark-a.svg';
import letter_restore_msg_SVG from '../../../img/c3l-restore-msg.svg';
import c1_flagSVG from '../../../img/v2/c1-flag.svg';
import c1_mm_heart_graySVG from '../../../img/v2/c1-mm-heart-grey.svg';
import c1_mm_lettersSVG from '../../../img/v2/c1-mm-letters.svg';
import c3ch_letters from '../../../img/v2/c3ch-letters.svg';
import sender_head_chat from '../../../img/v2/sender_head_chat.svg';
import muteSVG from '../../../img/mute.svg';
import mutedSVG from '../../../img/muted.svg';

//stickers

import sticker_heart_arrowSVG from '../../../img/stickers/heart/sticker-heart-arrow.svg';
import sticker_heart_brokenSVG from '../../../img/stickers/heart/sticker-heart-broken.svg';
import sticker_heart_confusedSVG from '../../../img/stickers/heart/sticker-heart-confused.svg';
import sticker_heart_crySVG from '../../../img/stickers/heart/sticker-heart-cry.svg';
import sticker_heart_hiSVG from '../../../img/stickers/heart/sticker-heart-hi.svg';
import sticker_heart_hugSVG from '../../../img/stickers/heart/sticker-heart-hug.svg';
import sticker_heart_laughSVG from '../../../img/stickers/heart/sticker-heart-laugh.svg';
import sticker_heart_likeSVG from '../../../img/stickers/heart/sticker-heart-like.svg';
import sticker_heart_loveSVG from '../../../img/stickers/heart/sticker-heart-love.svg';
import sticker_heart_sadSVG from '../../../img/stickers/heart/sticker-heart-sad.svg';

import sticker_girl_angrySVG from '../../../img/stickers/girl/sticker-girl-angry.svg';
import sticker_girl_confuseSVG from '../../../img/stickers/girl/sticker-girl-confuse.svg';
import sticker_girl_crySVG from '../../../img/stickers/girl/sticker-girl-cry.svg';
import sticker_girl_thinkSVG from '../../../img/stickers/girl/sticker-girl-think.svg';
import sticker_girl_waveSVG from '../../../img/stickers/girl/sticker-girl-wave.svg';
import sticker_girl_happySVG from '../../../img/stickers/girl/sticker-girl-happy.svg';
import sticker_girl_kissSVG from '../../../img/stickers/girl/sticker-girl-kiss.svg';
import sticker_girl_likeSVG from '../../../img/stickers/girl/sticker-girl-like.svg';
import sticker_girl_pleaseSVG from '../../../img/stickers/girl/sticker-girl-please.svg';
import sticker_girl_roll_eyesSVG from '../../../img/stickers/girl/sticker-girl-roll-eyes.svg';
import sticker_girl_sleepSVG from '../../../img/stickers/girl/sticker-girl-sleep.svg';
import sticker_girl_surprizeSVG from '../../../img/stickers/girl/sticker-girl-surprize.svg';

import sticker_guy_angrySVG from '../../../img/stickers/guy/sticker-guy-angry.svg';
import sticker_guy_confusedSVG from '../../../img/stickers/guy/sticker-guy-confused.svg';
import sticker_guy_coolSVG from '../../../img/stickers/guy/sticker-guy-cool.svg';
import sticker_guy_happySVG from '../../../img/stickers/guy/sticker-guy-happy.svg';
import sticker_guy_kissSVG from '../../../img/stickers/guy/sticker-guy-kiss.svg';
import sticker_guy_likeSVG from '../../../img/stickers/guy/sticker-guy-like.svg';
import sticker_guy_pleaseSVG from '../../../img/stickers/guy/sticker-guy-please.svg';
import sticker_guy_roll_eyesSVG from '../../../img/stickers/guy/sticker-guy-roll-eyes.svg';
import sticker_guy_sleepSVG from '../../../img/stickers/guy/sticker-guy-sleep.svg';
import sticker_guy_strongSVG from '../../../img/stickers/guy/sticker-guy-strong.svg';
import sticker_guy_surprizeSVG from '../../../img/stickers/guy/sticker-guy-surprize.svg';
import sticker_guy_thinkSVG from '../../../img/stickers/guy/sticker-guy-think.svg';
import sticker_guy_waveSVG from '../../../img/stickers/guy/sticker-guy-wave.svg';

import sticker_animal_1SVG from '../../../img/stickers/animal/sticker-animal-1.svg';
import sticker_animal_2SVG from '../../../img/stickers/animal/sticker-animal-2.svg';
import sticker_animal_3SVG from '../../../img/stickers/animal/sticker-animal-3.svg';
import sticker_animal_4SVG from '../../../img/stickers/animal/sticker-animal-4.svg';
import sticker_animal_5SVG from '../../../img/stickers/animal/sticker-animal-5.svg';
import sticker_animal_6SVG from '../../../img/stickers/animal/sticker-animal-6.svg';
import sticker_animal_7SVG from '../../../img/stickers/animal/sticker-animal-7.svg';
import sticker_animal_8SVG from '../../../img/stickers/animal/sticker-animal-8.svg';
import sticker_animal_9SVG from '../../../img/stickers/animal/sticker-animal-9.svg';
import sticker_animal_10SVG from '../../../img/stickers/animal/sticker-animal-10.svg';

import sticker_tiger_1SVG from '../../../img/stickers/tiger/sticker-tiger-1.png';
import sticker_tiger_2SVG from '../../../img/stickers/tiger/sticker-tiger-2.png';
import sticker_tiger_3SVG from '../../../img/stickers/tiger/sticker-tiger-3.png';
import sticker_tiger_4SVG from '../../../img/stickers/tiger/sticker-tiger-4.png';
import sticker_tiger_5SVG from '../../../img/stickers/tiger/sticker-tiger-5.png';
import sticker_tiger_6SVG from '../../../img/stickers/tiger/sticker-tiger-6.png';
import sticker_tiger_7SVG from '../../../img/stickers/tiger/sticker-tiger-7.png';
import sticker_tiger_8SVG from '../../../img/stickers/tiger/sticker-tiger-8.png';
import sticker_tiger_9SVG from '../../../img/stickers/tiger/sticker-tiger-9.png';
import sticker_tiger_10SVG from '../../../img/stickers/tiger/sticker-tiger-10.png';

import sticker_type1_1SVG from '../../../img/stickers/type1/sticker-type1-1.svg';
import sticker_type1_2SVG from '../../../img/stickers/type1/sticker-type1-2.svg';
import sticker_type1_3SVG from '../../../img/stickers/type1/sticker-type1-3.svg';
import sticker_type1_4SVG from '../../../img/stickers/type1/sticker-type1-4.svg';
import sticker_type1_5SVG from '../../../img/stickers/type1/sticker-type1-5.svg';
import sticker_type1_6SVG from '../../../img/stickers/type1/sticker-type1-6.svg';
import sticker_type1_7SVG from '../../../img/stickers/type1/sticker-type1-7.svg';
import sticker_type1_8SVG from '../../../img/stickers/type1/sticker-type1-8.svg';
import sticker_type1_9SVG from '../../../img/stickers/type1/sticker-type1-9.svg';
import sticker_type1_10SVG from '../../../img/stickers/type1/sticker-type1-10.svg';

import sticker_type2_1SVG from '../../../img/stickers/type2/sticker-type2-1.svg';
import sticker_type2_2SVG from '../../../img/stickers/type2/sticker-type2-2.svg';
import sticker_type2_3SVG from '../../../img/stickers/type2/sticker-type2-3.svg';
import sticker_type2_4SVG from '../../../img/stickers/type2/sticker-type2-4.svg';
import sticker_type2_5SVG from '../../../img/stickers/type2/sticker-type2-5.svg';
import sticker_type2_6SVG from '../../../img/stickers/type2/sticker-type2-6.svg';
import sticker_type2_7SVG from '../../../img/stickers/type2/sticker-type2-7.svg';
import sticker_type2_8SVG from '../../../img/stickers/type2/sticker-type2-8.svg';
import sticker_type2_9SVG from '../../../img/stickers/type2/sticker-type2-9.svg';
import sticker_type2_10SVG from '../../../img/stickers/type2/sticker-type2-10.svg';

import sticker_type3_1SVG from '../../../img/stickers/type3/sticker-type3-1.svg';
import sticker_type3_2SVG from '../../../img/stickers/type3/sticker-type3-2.svg';
import sticker_type3_3SVG from '../../../img/stickers/type3/sticker-type3-3.svg';
import sticker_type3_4SVG from '../../../img/stickers/type3/sticker-type3-4.svg';
import sticker_type3_5SVG from '../../../img/stickers/type3/sticker-type3-5.svg';
import sticker_type3_6SVG from '../../../img/stickers/type3/sticker-type3-6.svg';
import sticker_type3_7SVG from '../../../img/stickers/type3/sticker-type3-7.svg';
import sticker_type3_8SVG from '../../../img/stickers/type3/sticker-type3-8.svg';
import sticker_type3_9SVG from '../../../img/stickers/type3/sticker-type3-9.svg';
import sticker_type3_10SVG from '../../../img/stickers/type3/sticker-type3-10.svg';

import sticker_type4_1SVG from '../../../img/stickers/type4/sticker-type4-1.svg';
import sticker_type4_2SVG from '../../../img/stickers/type4/sticker-type4-2.svg';
import sticker_type4_3SVG from '../../../img/stickers/type4/sticker-type4-3.svg';
import sticker_type4_4SVG from '../../../img/stickers/type4/sticker-type4-4.svg';
import sticker_type4_5SVG from '../../../img/stickers/type4/sticker-type4-5.svg';
import sticker_type4_6SVG from '../../../img/stickers/type4/sticker-type4-6.svg';
import sticker_type4_7SVG from '../../../img/stickers/type4/sticker-type4-7.svg';
import sticker_type4_8SVG from '../../../img/stickers/type4/sticker-type4-8.svg';
import sticker_type4_9SVG from '../../../img/stickers/type4/sticker-type4-9.svg';
import sticker_type4_10SVG from '../../../img/stickers/type4/sticker-type4-10.svg';

import sticker_type5_1SVG from '../../../img/stickers/type5/sticker-type5-1.svg';
import sticker_type5_2SVG from '../../../img/stickers/type5/sticker-type5-2.svg';
import sticker_type5_3SVG from '../../../img/stickers/type5/sticker-type5-3.svg';
import sticker_type5_4SVG from '../../../img/stickers/type5/sticker-type5-4.svg';
import sticker_type5_5SVG from '../../../img/stickers/type5/sticker-type5-5.svg';
import sticker_type5_6SVG from '../../../img/stickers/type5/sticker-type5-6.svg';
import sticker_type5_7SVG from '../../../img/stickers/type5/sticker-type5-7.svg';
import sticker_type5_8SVG from '../../../img/stickers/type5/sticker-type5-8.svg';
import sticker_type5_9SVG from '../../../img/stickers/type5/sticker-type5-9.svg';
import sticker_type5_10SVG from '../../../img/stickers/type5/sticker-type5-10.svg';

import sticker_type6_1SVG from '../../../img/stickers/type6/sticker-type6-1.svg';
import sticker_type6_2SVG from '../../../img/stickers/type6/sticker-type6-2.svg';
import sticker_type6_3SVG from '../../../img/stickers/type6/sticker-type6-3.svg';
import sticker_type6_4SVG from '../../../img/stickers/type6/sticker-type6-4.svg';
import sticker_type6_5SVG from '../../../img/stickers/type6/sticker-type6-5.svg';
import sticker_type6_6SVG from '../../../img/stickers/type6/sticker-type6-6.svg';
import sticker_type6_7SVG from '../../../img/stickers/type6/sticker-type6-7.svg';
import sticker_type6_8SVG from '../../../img/stickers/type6/sticker-type6-8.svg';
import sticker_type6_9SVG from '../../../img/stickers/type6/sticker-type6-9.svg';
import sticker_type6_10SVG from '../../../img/stickers/type6/sticker-type6-10.svg';
import sticker_type7_1SVG from '../../../img/stickers/type7/sticker-type7-1.svg';
import sticker_type7_2SVG from '../../../img/stickers/type7/sticker-type7-2.svg';
import sticker_type7_3SVG from '../../../img/stickers/type7/sticker-type7-3.svg';
import sticker_type7_4SVG from '../../../img/stickers/type7/sticker-type7-4.svg';
import sticker_type7_5SVG from '../../../img/stickers/type7/sticker-type7-5.svg';
import sticker_type7_6SVG from '../../../img/stickers/type7/sticker-type7-6.svg';
import sticker_type7_7SVG from '../../../img/stickers/type7/sticker-type7-7.svg';
import sticker_type7_8SVG from '../../../img/stickers/type7/sticker-type7-8.svg';
import sticker_type7_9SVG from '../../../img/stickers/type7/sticker-type7-9.svg';
import sticker_type7_10SVG from '../../../img/stickers/type7/sticker-type7-10.svg';

import sticker_type8_1SVG from '../../../img/stickers/type8/sticker-type8-1.svg';
import sticker_type8_2SVG from '../../../img/stickers/type8/sticker-type8-2.svg';
import sticker_type8_3SVG from '../../../img/stickers/type8/sticker-type8-3.svg';
import sticker_type8_4SVG from '../../../img/stickers/type8/sticker-type8-4.svg';
import sticker_type8_5SVG from '../../../img/stickers/type8/sticker-type8-5.svg';
import sticker_type8_6SVG from '../../../img/stickers/type8/sticker-type8-6.svg';
import sticker_type8_7SVG from '../../../img/stickers/type8/sticker-type8-7.svg';
import sticker_type8_8SVG from '../../../img/stickers/type8/sticker-type8-8.svg';
import sticker_type8_9SVG from '../../../img/stickers/type8/sticker-type8-9.svg';
import sticker_type8_10SVG from '../../../img/stickers/type8/sticker-type8-10.svg';

import sticker_type9_1SVG from '../../../img/stickers/type9/sticker-type9-1.svg';
import sticker_type9_2SVG from '../../../img/stickers/type9/sticker-type9-2.svg';
import sticker_type9_3SVG from '../../../img/stickers/type9/sticker-type9-3.svg';
import sticker_type9_4SVG from '../../../img/stickers/type9/sticker-type9-4.svg';
import sticker_type9_5SVG from '../../../img/stickers/type9/sticker-type9-5.svg';
import sticker_type9_6SVG from '../../../img/stickers/type9/sticker-type9-6.svg';
import sticker_type9_7SVG from '../../../img/stickers/type9/sticker-type9-7.svg';
import sticker_type9_8SVG from '../../../img/stickers/type9/sticker-type9-8.svg';
import sticker_type9_9SVG from '../../../img/stickers/type9/sticker-type9-9.svg';
import sticker_type9_10SVG from '../../../img/stickers/type9/sticker-type9-10.svg';

import sticker_type10_1SVG from '../../../img/stickers/type10/sticker-type10-1.svg';
import sticker_type10_2SVG from '../../../img/stickers/type10/sticker-type10-2.svg';
import sticker_type10_3SVG from '../../../img/stickers/type10/sticker-type10-3.svg';
import sticker_type10_4SVG from '../../../img/stickers/type10/sticker-type10-4.svg';
import sticker_type10_5SVG from '../../../img/stickers/type10/sticker-type10-5.svg';
import sticker_type10_6SVG from '../../../img/stickers/type10/sticker-type10-6.svg';
import sticker_type10_7SVG from '../../../img/stickers/type10/sticker-type10-7.svg';
import sticker_type10_8SVG from '../../../img/stickers/type10/sticker-type10-8.svg';
import sticker_type10_9SVG from '../../../img/stickers/type10/sticker-type10-9.svg';
import sticker_type10_10SVG from '../../../img/stickers/type10/sticker-type10-10.svg';

// Import Posts page icons
import add_box_whiteSVG from '../../../img/posts/add_box_white.svg';
import save_draftSVG from '../../../img/posts/save_draft.svg';
import save_iconSVG from '../../../img/posts/save_icon.svg';
import post_commentSVG from '../../../img/posts/post_comment.svg';
import post_editSVG from '../../../img/posts/post_edit.svg';
import post_likeSVG from '../../../img/posts/post_like.svg';
import choose_profileSVG from '../../../img/posts/choose_profile.svg';
import close_iconSVG from '../../../img/posts/close_icon.svg';
import smile_iconSVG from '../../../img/posts/smile_icon.svg';
import send_iconSVG from '../../../img/posts/send_icon.svg';
import reply_iconSVG from '../../../img/posts/reply_icon.svg';
import trash_bin_iconSVG from '../../../img/posts/trash_bin_icon.svg';
import trash_bin_draftSVG from '../../../img/posts/trash_bin_draft.svg';
import show_iconSVG from '../../../img/posts/show_icon.svg';

//export stickers

export const profile_img_empty = profile_img_emptyJPG;
export const sticker_heart_arrow = sticker_heart_arrowSVG;
export const sticker_heart_broken = sticker_heart_brokenSVG;
export const sticker_heart_confused = sticker_heart_confusedSVG;
export const sticker_heart_cry = sticker_heart_crySVG;
export const sticker_heart_hi = sticker_heart_hiSVG;
export const sticker_heart_hug = sticker_heart_hugSVG;
export const sticker_heart_laugh = sticker_heart_laughSVG;
export const sticker_heart_like = sticker_heart_likeSVG;
export const sticker_heart_love = sticker_heart_loveSVG;
export const sticker_heart_sad = sticker_heart_sadSVG;

export const sticker_girl_angry = sticker_girl_angrySVG;
export const sticker_girl_confuse = sticker_girl_confuseSVG;
export const sticker_girl_cry = sticker_girl_crySVG;
export const sticker_girl_happy = sticker_girl_happySVG;
export const sticker_girl_kiss = sticker_girl_kissSVG;
export const sticker_girl_like = sticker_girl_likeSVG;
export const sticker_girl_please = sticker_girl_pleaseSVG;
export const sticker_girl_roll_eyes = sticker_girl_roll_eyesSVG;
export const sticker_girl_sleep = sticker_girl_sleepSVG;
export const sticker_girl_surprize = sticker_girl_surprizeSVG;
export const sticker_girl_think = sticker_girl_thinkSVG;
export const sticker_girl_wave = sticker_girl_waveSVG;

export const sticker_guy_angry = sticker_guy_angrySVG;
export const sticker_guy_confused = sticker_guy_confusedSVG;
export const sticker_guy_cool = sticker_guy_coolSVG;
export const sticker_guy_happy = sticker_guy_happySVG;
export const sticker_guy_kiss = sticker_guy_kissSVG;
export const sticker_guy_like = sticker_guy_likeSVG;
export const sticker_guy_please = sticker_guy_pleaseSVG;
export const sticker_guy_roll_eyes = sticker_guy_roll_eyesSVG;
export const sticker_guy_sleep = sticker_guy_sleepSVG;
export const sticker_guy_strong = sticker_guy_strongSVG;
export const sticker_guy_surprize = sticker_guy_surprizeSVG;
export const sticker_guy_think = sticker_guy_thinkSVG;
export const sticker_guy_wave = sticker_guy_waveSVG;

export const sticker_animal_1 = sticker_animal_1SVG;
export const sticker_animal_2 = sticker_animal_2SVG;
export const sticker_animal_3 = sticker_animal_3SVG;
export const sticker_animal_4 = sticker_animal_4SVG;
export const sticker_animal_5 = sticker_animal_5SVG;
export const sticker_animal_6 = sticker_animal_6SVG;
export const sticker_animal_7 = sticker_animal_7SVG;
export const sticker_animal_8 = sticker_animal_8SVG;
export const sticker_animal_9 = sticker_animal_9SVG;
export const sticker_animal_10 = sticker_animal_10SVG;

export const sticker_tiger_1 = sticker_tiger_1SVG;
export const sticker_tiger_2 = sticker_tiger_2SVG;
export const sticker_tiger_3 = sticker_tiger_3SVG;
export const sticker_tiger_4 = sticker_tiger_4SVG;
export const sticker_tiger_5 = sticker_tiger_5SVG;
export const sticker_tiger_6 = sticker_tiger_6SVG;
export const sticker_tiger_7 = sticker_tiger_7SVG;
export const sticker_tiger_8 = sticker_tiger_8SVG;
export const sticker_tiger_9 = sticker_tiger_9SVG;
export const sticker_tiger_10 = sticker_tiger_10SVG;

export const sticker_type1_1 = sticker_type1_1SVG;
export const sticker_type1_2 = sticker_type1_2SVG;
export const sticker_type1_3 = sticker_type1_3SVG;
export const sticker_type1_4 = sticker_type1_4SVG;
export const sticker_type1_5 = sticker_type1_5SVG;
export const sticker_type1_6 = sticker_type1_6SVG;
export const sticker_type1_7 = sticker_type1_7SVG;
export const sticker_type1_8 = sticker_type1_8SVG;
export const sticker_type1_9 = sticker_type1_9SVG;
export const sticker_type1_10 = sticker_type1_10SVG;

export const sticker_type2_1 = sticker_type2_1SVG;
export const sticker_type2_2 = sticker_type2_2SVG;
export const sticker_type2_3 = sticker_type2_3SVG;
export const sticker_type2_4 = sticker_type2_4SVG;
export const sticker_type2_5 = sticker_type2_5SVG;
export const sticker_type2_6 = sticker_type2_6SVG;
export const sticker_type2_7 = sticker_type2_7SVG;
export const sticker_type2_8 = sticker_type2_8SVG;
export const sticker_type2_9 = sticker_type2_9SVG;
export const sticker_type2_10 = sticker_type2_10SVG;

export const sticker_type3_1 = sticker_type3_1SVG;
export const sticker_type3_2 = sticker_type3_2SVG;
export const sticker_type3_3 = sticker_type3_3SVG;
export const sticker_type3_4 = sticker_type3_4SVG;
export const sticker_type3_5 = sticker_type3_5SVG;
export const sticker_type3_6 = sticker_type3_6SVG;
export const sticker_type3_7 = sticker_type3_7SVG;
export const sticker_type3_8 = sticker_type3_8SVG;
export const sticker_type3_9 = sticker_type3_9SVG;
export const sticker_type3_10 = sticker_type3_10SVG;

export const sticker_type4_1 = sticker_type4_1SVG;
export const sticker_type4_2 = sticker_type4_2SVG;
export const sticker_type4_3 = sticker_type4_3SVG;
export const sticker_type4_4 = sticker_type4_4SVG;
export const sticker_type4_5 = sticker_type4_5SVG;
export const sticker_type4_6 = sticker_type4_6SVG;
export const sticker_type4_7 = sticker_type4_7SVG;
export const sticker_type4_8 = sticker_type4_8SVG;
export const sticker_type4_9 = sticker_type4_9SVG;
export const sticker_type4_10 = sticker_type4_10SVG;

export const sticker_type5_1 = sticker_type5_1SVG;
export const sticker_type5_2 = sticker_type5_2SVG;
export const sticker_type5_3 = sticker_type5_3SVG;
export const sticker_type5_4 = sticker_type5_4SVG;
export const sticker_type5_5 = sticker_type5_5SVG;
export const sticker_type5_6 = sticker_type5_6SVG;
export const sticker_type5_7 = sticker_type5_7SVG;
export const sticker_type5_8 = sticker_type5_8SVG;
export const sticker_type5_9 = sticker_type5_9SVG;
export const sticker_type5_10 = sticker_type5_10SVG;

export const sticker_type6_1 = sticker_type6_1SVG;
export const sticker_type6_2 = sticker_type6_2SVG;
export const sticker_type6_3 = sticker_type6_3SVG;
export const sticker_type6_4 = sticker_type6_4SVG;
export const sticker_type6_5 = sticker_type6_5SVG;
export const sticker_type6_6 = sticker_type6_6SVG;
export const sticker_type6_7 = sticker_type6_7SVG;
export const sticker_type6_8 = sticker_type6_8SVG;
export const sticker_type6_9 = sticker_type6_9SVG;
export const sticker_type6_10 = sticker_type6_10SVG;

export const sticker_type7_1 = sticker_type7_1SVG;
export const sticker_type7_2 = sticker_type7_2SVG;
export const sticker_type7_3 = sticker_type7_3SVG;
export const sticker_type7_4 = sticker_type7_4SVG;
export const sticker_type7_5 = sticker_type7_5SVG;
export const sticker_type7_6 = sticker_type7_6SVG;
export const sticker_type7_7 = sticker_type7_7SVG;
export const sticker_type7_8 = sticker_type7_8SVG;
export const sticker_type7_9 = sticker_type7_9SVG;
export const sticker_type7_10 = sticker_type7_10SVG;

export const sticker_type8_1 = sticker_type8_1SVG;
export const sticker_type8_2 = sticker_type8_2SVG;
export const sticker_type8_3 = sticker_type8_3SVG;
export const sticker_type8_4 = sticker_type8_4SVG;
export const sticker_type8_5 = sticker_type8_5SVG;
export const sticker_type8_6 = sticker_type8_6SVG;
export const sticker_type8_7 = sticker_type8_7SVG;
export const sticker_type8_8 = sticker_type8_8SVG;
export const sticker_type8_9 = sticker_type8_9SVG;
export const sticker_type8_10 = sticker_type8_10SVG;

export const sticker_type9_1 = sticker_type9_1SVG;
export const sticker_type9_2 = sticker_type9_2SVG;
export const sticker_type9_3 = sticker_type9_3SVG;
export const sticker_type9_4 = sticker_type9_4SVG;
export const sticker_type9_5 = sticker_type9_5SVG;
export const sticker_type9_6 = sticker_type9_6SVG;
export const sticker_type9_7 = sticker_type9_7SVG;
export const sticker_type9_8 = sticker_type9_8SVG;
export const sticker_type9_9 = sticker_type9_9SVG;
export const sticker_type9_10 = sticker_type9_10SVG;

export const sticker_type10_1 = sticker_type10_1SVG;
export const sticker_type10_2 = sticker_type10_2SVG;
export const sticker_type10_3 = sticker_type10_3SVG;
export const sticker_type10_4 = sticker_type10_4SVG;
export const sticker_type10_5 = sticker_type10_5SVG;
export const sticker_type10_6 = sticker_type10_6SVG;
export const sticker_type10_7 = sticker_type10_7SVG;
export const sticker_type10_8 = sticker_type10_8SVG;
export const sticker_type10_9 = sticker_type10_9SVG;
export const sticker_type10_10 = sticker_type10_10SVG;

export const mute = muteSVG;
export const muted = mutedSVG;

export const winked_white = winked_whiteSVG;
export const liked_white = liked_whiteSVG;
export const viewed_white = viewed_whiteSVG;
export const c1_mm_letters = c1_mm_lettersSVG;
export const c1_mm_heart_gray = c1_mm_heart_graySVG;
export const c1_flag = c1_flagSVG;
export const logo = logoSVG;
export const sender_history_audio_play = sender_history_audio_playSVG;
export const sender_forms_delete_media = sender_forms_delete_mediaSVG;
export const sender_forms_sticker_ico = sender_forms_sticker_icoSVG;
export const sender_forms_video_ico = sender_forms_video_icoSVG;
export const sender_forms_smile_ico = sender_forms_smile_icoSVG;
export const sender_forms_audio_ico = sender_forms_audio_icoSVG;
export const sender_forms_photo_ico = sender_forms_photo_icoSVG;
export const sender_forms_save = sender_forms_saveSVG;
export const sender_drafts_edit = sender_drafts_editSVG;
export const sender_drafts_delete = sender_drafts_deleteSVG;
export const sender_drafts_send = sender_drafts_sendSVG;
export const c2_letter_white = c2_letter_whiteSVG;
export const letter_option_answer = letter_option_answerSVG;
export const letter_option_profile = letter_option_profileSVG;
export const letter_option_delete = letter_option_deleteSVG;
export const letter_option_restore = letter_option_restoreSVG;
export const letter_option_chat = letter_option_chatSVG;
export const letter_chat_delete = letter_chat_deleteSVG;
export const letter_chat_bookmark = letter_chat_bookmarkSVG;
export const letter_chat_bookmark_a = letter_chat_bookmark_aSVG;
export const letter_restore_msg = letter_restore_msg_SVG;
export const video_error = video_errorSVG;
export const c3_media_video_play = c3_media_video_playSVG;
export const viewed_grey = viewed_greySVG;
export const not_viewed_grey = not_viewed_greySVG;
export const not_found_results = not_found_resultsSVG;
export const chat_head_more_btn = chat_head_more_btnSVG;
export const alarm = alarmSVG;
export const c1b_profile_a = c1b_profile_aSVG;
export const c1b_stats_a = c1b_stats_aSVG;
export const c1b_sender_stats_a = c1b_sender_stats_aSVG;
export const c1b_personal_a = c1b_personal_aSVG;
export const c1b_on_off_a = c1b_on_off_aSVG;
export const c1b_block_a = c1b_block_aSVG;
export const c1b_tutorial_a = c1b_tutorial_aSVG;
export const c1b_sign_out_a = c1b_sign_out_aSVG;
export const left_arrow = left_arrowSVG;
export const mob_filters_letters = mob_filters_lettersSVG;
export const user_ico = user_icoSVG;
export const calendar = calendarSVG;
export const popup_play = popup_playSVG;
export const stop_in_attach_file = stop_in_attach_fileSVG;
export const audio_volume = audio_volumeSVG;
export const chat_audio_volume_mute = chat_audio_volume_muteSVG;
export const popup_audio_del = popup_audio_delSVG;
export const slider_prev = slider_prevSVG;
export const slider_next = slider_nextSVG;
export const letter_audio_notes = letter_audio_notesSVG;
export const photo_full = photo_fullSVG;
export const trash = trashSVG;
export const move = moveSVG;
export const cross_white = cross_whiteSVG;
export const audio_play = audio_playSVG;
export const audio_pause = audio_pauseSVG;
export const c3l_add_more_men_btn = c3l_add_more_men_btnSVG;
export const popup_upload = popup_uploadSVG;
export const c4_like = c4_likeSVG;
export const c4_viwed = c4_viwedSVG;
export const c3wp_btn_prev = c3wp_btn_prevSVG;
export const c3wp_btn_next = c3wp_btn_nextSVG;
export const c3lh_item_big_close = c3lh_item_big_closeSVG;
export const arrow_btn = arrow_btnSVG;
export const btn_right = btn_rightSVG;
export const c3wp_tophoto = c3wp_tophotoSVG;
export const c3wp_tovideo = c3wp_tovideoSVG;
export const personal_empty_connect = personal_empty_connectPNG;
export const personal_empty_wait = personal_empty_waitPNG;
export const personal_connect_plus = personal_connect_plusPNG;
export const c4_write_attention = c4_write_attentionSVG;

export default {
    sender_head_chat,
    c3ch_letters,
    c2_profile,
    c2_chat_switch,
    send_message,
    pc_typing,
    mob_typing,
    empty_chat,
    online_blue,
    winked_blue,
    liked_blue,
    viewed_blue,
    online_white,
    c4_audio_line,
    c4_play_audio_btn,
    star,
    c4_head,
    heart_red,
    c1_mm_arrow,
    c1_mm_heart,
    c1_photo,
    c1_sender,
    c1_gifts,
    c1_letters,
};

export const freeze_cube = freeze_cubeSVG;
export const freeze_cube_full = freeze_cube_fullSVG;
export const profile_ico = profile_icoSVG;
export const popup_gift_close = popup_gift_closeSVG;
export const check_circle_white = check_circle_whiteSVG;
export const loading_GIF = loadingGIF;
export const arrow_white = arrow_whiteSVG;
// export const avatar_default = avatar_defaultJPG;
export const sub_menu = sub_menuSVG;
export const logo_right_white = logo_right_whiteSVG;
export const logo_left = logo_leftSVG;
// export const c4_reply_dark = c4_reply_darkSVG;
export const c4_reply = c4_replySVG;
export const c2_remove = c2_removeSVG;
export const c2_search = c2_search_btnSVG;
export const c3_msg_like = c3_msg_likeSVG;
export const c1_multi_heart = c1_multi_heartSVG;
export const c3_opt_gift = c3_opt_giftSVG;
export const c3_opt_contact = c3_opt_contactSVG;
export const c3_opt_meet = c3_opt_meetSVG;
export const c3_opt_cam = c3_opt_camSVG;

export const c3_msg_smile = c3_msg_smileSVG;
export const c3_msg_sticker = c3_msg_stickerSVG;
export const arrow_left_purple = arrow_left_purpleSVG;

export const attach_close_ico = attach_close_icoSVG;
export const attach_file_drop_active = attach_file_drop_activeSVG;
export const attach_file_drop = attach_file_dropSVG;
export const attach_send_ico = attach_send_icoSVG;
export const attach_upload_ico = attach_upload_icoSVG;

// Export Posts page icons
export const add_box_white = add_box_whiteSVG;
export const save_draft = save_draftSVG;
export const save_icon = save_iconSVG;
export const post_comment = post_commentSVG;
export const post_edit = post_editSVG;
export const post_like = post_likeSVG;
export const choose_profile = choose_profileSVG;
export const close_icon = close_iconSVG;
export const smile_icon = smile_iconSVG;
export const send_icon = send_iconSVG;
export const reply_icon = reply_iconSVG;
export const trash_bin_icon = trash_bin_iconSVG;
export const trash_bin_draft = trash_bin_draftSVG;
export const show_icon = show_iconSVG;
