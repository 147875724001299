export default {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		background: 'rgba(55, 78, 108, 0.32)',
		backdropFilter: 'blur(3px)',
		zIndex: 9999,
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	content: {
		display: 'flex',
		position: 'static',
		left: 0,
		top: 0,
		width: 'auto',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		background: "transparent",
		textAlign: "center",
		border: "none",
		overflow: "unset",
		padding: "0"
		// minWidth: '50vw',
		// maxWidth: '90vw',
		// maxHeight: '90vh',
		// height: '650px',
		// width: '963px',
		// top: '50%',
		// left: '50%',
		// padding: 0,
		// transform: 'translate(-50%, -50%)'
	}
};
