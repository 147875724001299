import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import ChatsAPI from '../../services/api-multi/ChatMultiApi';
import TransactionApi from '../../services/api/v3/TransactionApi';
import * as PERSONAL from '../../constants/personal';
import { useDispatch, useSelector } from 'react-redux';
import * as personalAction from '../../store/actions/personal';
import * as chatsAction from '../../store/actions/chats';
import * as menuAction from '../../store/actions/menu';
import * as alertsAction from '../../store/actions/alerts';
import * as ERROR from '../../constants/error';

export default function useHandlePersonal() {
    const dispatch = useDispatch();
    const history = useHistory();
    const personalState = useSelector((state) => state.personal);

    const [currentWoman, setCurrentWoman] = useState(null);
    const [additionalWomanList, setAdditionalWomanList] = useState([]);
    const [currentMan, setCurrentMan] = useState(null);

    const linkToConnection = async (type, manExternalID, womanExternalID, id) => {
        if (!type?.length || !+manExternalID || !+womanExternalID) return;
        dispatch(chatsAction.clearChatList());

        const chatUID = await new ChatsAPI()
            .chatUIDByProfileAndUserIDs({
                user_external_id: +manExternalID,
                woman_external_id: +womanExternalID,
            })
            .then((res) => (res?.status ? res?.response?.chat_uid : null));
        if (!chatUID) return;

        switch (type) {
            case PERSONAL.PERSONALISED_INVITE:
                dispatch(menuAction.setMainMenu('chat'));
                return history.push(`/chat/${chatUID}?personal=${id}`);
            case PERSONAL.PERSONALISED_LETTER:
                dispatch(menuAction.setMainMenu('letter'));
                return history.push(`/letter/${chatUID}?personal=${id}`);
            default:
                return;
        }
    };

    const connect = async () => {
        await new TransactionApi()
            .change({
                id: currentMan?.id,
                woman_external_id: currentWoman?.external_id,
                status: 1,
                additional_woman_external_ids: additionalWomanList?.map((item) => item?.external_id),
            })
            .then((res) => {
                if (res?.status) {
                    setCurrentMan(null);
                    setCurrentWoman(null);
                    setAdditionalWomanList([]);

                    fetchPersonalManList();
                    linkToConnection(
                        currentMan?.type,
                        currentMan?.external_id,
                        currentWoman?.external_id,
                        currentMan?.id,
                    );
                } else {
                    if (res?.message) {
                        dispatch(alertsAction.setMessage('', res?.message));
                    } else {
                        dispatch(alertsAction.setMessage('', ERROR.ERROR_REQUEST));
                    }
                }
            });
    };

    const fetchPersonalManList = () => {
        return new TransactionApi().get().then((res) => dispatch(personalAction.setPersonal(res)));
    };

    const cancelConnect = () => {
        setCurrentMan(null);
        setCurrentWoman(null);
        setAdditionalWomanList([]);
    };

    return {
        fetchPersonalManList,
        currentWoman,
        setCurrentWoman,
        currentMan,
        setCurrentMan,
        additionalWomanList,
        setAdditionalWomanList,
        personalManList: personalState?.personalManList,
        personalHistoryList: personalState?.personalHistoryList,
        connect,
        linkToConnection,
        cancelConnect,
    };
}
