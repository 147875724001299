import React from 'react';
import styles from './styles.module.scss';
import SenderWomanList from './SenderWomanList';
import useHandleSenderLeftBar from '../../../hooks/sender/useHandleSenderLeftBar';
import { useDispatch, useSelector } from 'react-redux';
import { closeNotifyList } from '../../../services/dom';
import * as senderActions from '../../../store/actions/sender';

export default function SenderLeftBar() {
    const dispatch = useDispatch();

    const senderState = useSelector((state) => state.sender);

    const closeSenderList = () => {
        closeNotifyList();
        dispatch(senderActions.closeSendersList());
    };

    const handleSenderLeftBar = useHandleSenderLeftBar();
    return (
        <div className={`${styles.left_bar} ${senderState.isOpenSenderList ? styles.active : ''}`}>
            <SenderWomanList {...handleSenderLeftBar} />
            <div className={styles.left_bar_close} onClick={closeSenderList} />
            {/*<SenderHistory/>*/}
        </div>
    );
}
