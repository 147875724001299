import * as STATISTIC from '../constants/statistic';
import * as STATISTIC_LIST from '../../constants/statistic';

const initState = {
    statistic: [],

    res: [],
    list: [],
    total: 0,
    profileList: [],
    senderList: [],
    filterSenderList: [],
    senderDetailList: [],
    filterSenderDetailList: [],
    filterSearch: '',
    detailFilterSearch: '',
    isLoading: false,
    isLoadedAll: false,
    typesStatistic: [],
};

export default function (state = initState, action) {
    switch (action.type) {
        case STATISTIC.GET_OPERATOR_STATISTIC:
            return {
                ...state,
                ...{
                    statistic: action?.statistic?.filter(
                        (el) => !STATISTIC_LIST.NOT_IN_STATISTIC_TYPE_LIST.includes(el?.action_type),
                    ),
                    isLoading: false,
                },
            };
        case STATISTIC.LOADING_STAT_LIST:
            return {
                ...state,
                ...{
                    isLoading: action?.isLoading,
                    statistic: [],
                    senderList: [],
                    list: [],
                    res: [],
                },
            };
        case STATISTIC.SENDER_STAT_LIST:
            return {
                ...state,
                ...{
                    senderList: action?.list,
                    senderDetailList: action?.detailList,
                    isLoading: false,
                    filterSearch: '',
                    profileList: action?.profileList,
                },
            };
        case STATISTIC.SENDER_STAT_LIST_FILTER_SEARCH:
            return {
                ...state,
                ...{
                    filterSenderList: action?.filterSearch
                        ? state?.senderList?.filter((item) => {
                              return (
                                  item?.id?.includes(action?.filterSearch?.toString()) ||
                                  item?.name?.toLowerCase().includes(action?.filterSearch?.toString()?.toLowerCase())
                              );
                          })
                        : state?.senderList,
                    filterSearch: action?.filterSearch,
                    isLoading: false,
                },
            };
        case STATISTIC.SET_TYPES_STATISTIC:
            return {
                ...state,
                ...{
                    typesStatistic: action?.typesStatistic,
                },
            };
        case STATISTIC.SENDER_DETAIL_FILTER:
            return {
                ...state,
                ...{
                    filterSenderDetailList: state?.senderDetailList?.filter((item) => {
                        return +item?.id === action?.detailFilterSearch;
                    }),
                    detailFilterSearch: action?.detailFilterSearch,
                    isLoading: false,
                },
            };
        default:
            return state;
    }
}
