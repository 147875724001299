import React, { memo } from 'react';
import styles from '../styles.module.scss';
import { useDispatch } from 'react-redux';
import * as IMAGES from '../../Images';
import { cutLengthString, makeCDN } from '../../../../services/methods';
import * as modalsAction from '../../../../store/actions/modals';
import * as MEDIA from '../../../../constants/media';

export default memo(({ message_content, payed, thumb_link, filename }) => {
    const dispatch = useDispatch();

    const openVideoModal = () => {
        dispatch(
            modalsAction.openVideoContentModal({
                link: message_content,
                attachment_payed: '1',
            }),
        );
    };

    return (
        <>
            <div className={`${styles.media_list_item} ${styles.video}`} onClick={openVideoModal}>
                <img src={IMAGES.c3_media_video_play} alt="" className={styles.media_list_item_video_play} />
                {thumb_link?.length ? (
                    <img src={thumb_link} alt="" />
                ) : (
                    <video preload="metadata">
                        <source src={makeCDN(message_content, 'VIDEO')} type="video/mp4" />
                    </video>
                )}
                {!+payed && (
                    <div className={styles.media_list_item_unpaid_wrap}>
                        <div className={styles.media_list_item_unpaid_main}>
                            <img src={IMAGES.not_viewed_grey} alt="" />
                            <span>Unviewed</span>
                        </div>
                    </div>
                )}
                {!!filename?.length && (
                    <div className={styles.media_list_item_content_bottom} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.media_list_item_name}>
                            {cutLengthString(filename, MEDIA.VIDEO_TITLE_LENGTH)}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
});
