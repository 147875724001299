import React from 'react';
import Styles from '../styles.module.scss';
import { Tooltip } from '@mui/material';

export default ({
    styleFolder,
    styleActive,
    styleDisabled,
    titleFolder,
    countFolder,
    openFolder,
    countStyle = '',
    titleHover = '',
}) => {
    return (
        <Tooltip title={titleHover} disableInteractive={!titleHover?.length}>
            <div
                className={`${Styles.clmn_1_menu_item} ${styleFolder ?? ''} ${styleActive ?? ''} ${styleDisabled ?? ''}`}
                onClick={openFolder}
                data-testid={`main-menu-item-${titleFolder}`}
            >
                <div className={Styles.clmn_1_menu_item_left}>
                    <div className={Styles.clmn_1_menu_item_img} />
                    <div className={Styles.clmn_1_menu_item_text}>{titleFolder ?? ''}</div>
                </div>
                {!!+countFolder && (
                    <div className={`${Styles.clmn_1_menu_item_val} ${countStyle}`}>
                        {titleFolder === 'Personal' ? +countFolder : 'New'}
                    </div>
                )}
            </div>
        </Tooltip>
    );
};
