import React, { useEffect } from 'react';
import * as ATTACH from '../../../../constants/attach';
import { gotoById } from '../../../../services/dom';
import '../../../../styles/attach/style.scss';

export default ({ status }) => {
    useEffect(() => {
        if (status) {
            gotoById(ATTACH.GO_TO_LOADER);
        }
    }, [status]);

    return (
        <div className={`upload_popup_tabs_content_item ${status ? 'active' : 'hidden'}`} id={ATTACH.GO_TO_LOADER}>
            <div className="upload_popup_tabs_content_item_top">
                <img src="/img/loader_attach.gif" alt="" />
            </div>
        </div>
    );
};
