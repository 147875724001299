import { useState } from 'react';
import { addBlur, removeBlur } from '../../services/dom/letters';
import { useDispatch } from 'react-redux';
import * as statisticAction from '../../store/actions/statistics';

export default function useDetailStatistic() {
    const dispatch = useDispatch();
    const [detailStatOpen, setDetailStatOpen] = useState(false);

    const openDetailStatModal = (id) => {
        addBlur();
        dispatch(statisticAction.setDetailFilter(id));
        setDetailStatOpen(true);
    };

    const closeDetailStatModal = () => {
        removeBlur();
        setDetailStatOpen(false);
    };
    return {
        closeDetailStatModal,
        openDetailStatModal,
        detailStatOpen,
        setDetailStatOpen,
    };
}
