import React from 'react';
import * as IMAGES from '../../components/common/Images';

const NextArrow = () => (
    <div className="c3l_men_list_item_main_btn btn-prev">
        <img src={IMAGES.slider_prev} alt="prev" />
    </div>
);

const PrevArrow = () => (
    <div className="c3l_men_list_item_main_btn btn-next">
        <img src={IMAGES.slider_next} alt="next" />
    </div>
);

export const settings = {
    className: 'slider variable-width',
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <PrevArrow />,
    prevArrow: <NextArrow />,
};
