import * as PROFILES from '../constants/profiles';
import { diffWithCurrentTimeInSeconds } from '../../services/time';

const initState = {
    list: [],
    senderListInfo: [],
    active: {},
    operatorId: null,
    active_for_sender: null,
};

export default function (state = initState, action) {
    switch (action.type) {
        case PROFILES.PROFILES_ADD:
            return {
                ...state,
                ...{
                    list: action?.womanProfileList,
                },
            };

        case PROFILES.PROFILES_ADD_SENDER_INFO:
            return {
                ...state,
                ...{
                    senderListInfo: action?.womanProfileList,
                },
            };

        case PROFILES.PROFILES_CHECK_SENDER_INFO: {
            const filteredList = state.senderListInfo?.filter((item) => diffWithCurrentTimeInSeconds(item?.expired_at));

            if (state.senderListInfo?.length !== filteredList?.length) {
                return {
                    ...state,
                    ...{
                        senderListInfo: filteredList,
                    },
                };
            }
            return state;
        }

        case PROFILES.PROFILES_REMOVE_ACTIVE:
            return { ...state, ...{ active: {} } };

        case PROFILES.SET_ALL_ONLINE_STATUS:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => ({ ...item, ...{ online: action?.status } })),
                },
            };

        case PROFILES.SET_ONLINE_STATUS_BY_EXTERNAL_ID:
            return {
                ...state,
                ...{
                    list: state.list.map((item) =>
                        +item?.external_id === +action?.externalID ? { ...item, ...{ online: action?.status } } : item,
                    ),
                },
            };

        case PROFILES.SET_OPERATOR_ID:
            return {
                ...state,
                ...{
                    operatorId: action.operatorId,
                },
            };

        case PROFILES.PROFILES_SET_ACTIVE:
            return {
                ...state,
                ...{
                    active: action.data,
                },
            };

        case PROFILES.PROFILES_SET_ACTIVE_FOR_SENDER:
            return {
                ...state,
                ...{
                    active_for_sender: action?.data,
                },
            };

        default:
            return state;
    }
}
