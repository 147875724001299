import React, { useRef } from 'react';
import * as IMAGES from '../common/Images';

import styles from './styles.module.scss';
import useHandleBottomMenu from '../../hooks/side-menu/useHandleBottomMenu';
import * as MENU from '../../constants/menu';
import ActiveProfile from '../side-menu/ActiveProfile';
import ProfilesList from '../side-menu/ProfilesList';
import useHandleMultiChat from '../../hooks/side-menu/useHandleMultiChat';
import useHandleChatListItem from '../../hooks/chat/useHandleChatListItem';
import { useSelector } from 'react-redux';
import SITE from '../../config/site';

export default () => {
    const handleBottomMenu = useHandleBottomMenu();
    const handleMultiChat = useHandleMultiChat();
    const multiActiveBlockRef = useRef();
    const multiProfileListBlockRef = useRef();
    const { linkToWomanProfile } = useHandleChatListItem({ chatItem: '' });

    const profilesState = useSelector((state) => state.profiles);

    const isOlymp = SITE.ID === 2;

    return (
        <div className={`${styles.mobile_menu_page} mobile_menu_page`}>
            <div className={styles.mobile_menu_list}>
                {handleMultiChat?.isActiveProfileExist ? (
                    <ActiveProfile {...handleMultiChat} multiActiveBlockRef={multiActiveBlockRef} />
                ) : (
                    <ProfilesList {...handleMultiChat} multiProfileListBlockRef={multiProfileListBlockRef} />
                )}
                <div
                    onClick={() => linkToWomanProfile(profilesState.active.external_id)}
                    className={`${styles.mobile_menu_item} ${profilesState.active.external_id ? '' : styles.disable}`}
                >
                    <img src={IMAGES.c1b_profile_a} alt="" />
                    <span>Profile</span>
                </div>
                <div
                    className={styles.mobile_menu_item}
                    onClick={() => handleBottomMenu.activeItem(MENU.MENU_STATISTIC)}
                >
                    <img src={IMAGES.c1b_stats_a} alt="" />
                    <span>Statistic</span>
                </div>
                {!isOlymp && (
                    <div
                        className={styles.mobile_menu_item}
                        onClick={() => handleBottomMenu.activeItem(MENU.MENU_SENDER_STATISTIC)}
                    >
                        <img src={IMAGES.c1b_sender_stats_a} alt="" />
                        <span>Sender Statistics</span>
                    </div>
                )}
                <div
                    className={styles.mobile_menu_item}
                    onClick={() => handleBottomMenu.activeItem(MENU.MENU_PERSONAL)}
                >
                    <img src={IMAGES.c1b_personal_a} alt="" />
                    <span>Personal</span>
                </div>
                <div className={styles.mobile_menu_item} onClick={() => handleBottomMenu.activeItem(MENU.MENU_BLOCK)}>
                    <img src={IMAGES.c1b_block_a} alt="" />
                    <span>Block list</span>
                </div>
                {!isOlymp && (
                    <div className={styles.mobile_menu_item}>
                        <img src={IMAGES.c1b_tutorial_a} alt="" />
                        <span>Tutorial</span>
                    </div>
                )}
                <div
                    className={styles.mobile_menu_item}
                    onClick={() => handleBottomMenu.activeItem(MENU.MENU_SIGN_OUT)}
                >
                    <img src={IMAGES.c1b_sign_out_a} alt="" />
                    <span>Sign out</span>
                </div>
            </div>
        </div>
    );
};
