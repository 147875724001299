import React from 'react';
import styles from '../../styles.module.scss';
import { makeCDN } from '../../../../../../services/methods';
import * as modalsAction from '../../../../../../store/actions/modals';
import { useDispatch } from 'react-redux';
import * as IMAGES from '../../../../../common/Images';

export default ({ link, thumb_link }) => {
    const dispatch = useDispatch();

    const openVideoModal = () => {
        dispatch(
            modalsAction.openVideoContentModal({
                link: link,
                attachment_payed: '1',
            }),
        );
    };

    return (
        <div className={`${styles.drafts_table_item_attach} ${styles.video}`} onClick={openVideoModal}>
            <img src={IMAGES.c3_media_video_play} alt="" className={styles.drafts_table_item_attach_video_play} />
            {thumb_link?.length ? (
                <img src={thumb_link} alt="" />
            ) : (
                <video preload="metadata">
                    <source src={makeCDN(link, 'VIDEO')} type="video/mp4" />
                </video>
            )}
        </div>
    );
};
