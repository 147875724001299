import React from 'react';
import List from './List';
import Styles from './styles.module.scss';
import { useSelector } from 'react-redux';

export default function ChatList() {
    const chatState = useSelector((state) => state.chats);

    return (
        <div
            className={`${Styles.clmn_2} ${Styles.clmn_2_chat} ${chatState?.isOpenChatList ? Styles.active : ''}`}
            data-testid="chat-list-block"
        >
            <List />
        </div>
    );
}
