import Base from './Base';

export default class OperatorApi extends Base {
    getProfiles() {
        return super
            .get(`operator/profiles`)
            .then((res) => res?.data)
            .catch((err) => ({ error: true, message: err }));
    }

    getGifts() {
        return super
            .post(`operator/getGifts`, { operator_id: localStorage.getItem('operator') })
            .then((res) => res?.data);
    }

    checkClick() {
        return super
            .get(`operator/checkClick`)
            .then((res) => res?.data)
            .catch(() => null);
    }
}
