import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../styles.module.scss';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import messageIcon from '../../../../../assets/icons/icon_message.svg';
import messageFilledIcon from '../../../../../assets/icons/icon_message_filled.svg';
import letterIcon from '../../../../../assets/icons/icon_letter.svg';
import letterFilledIcon from '../../../../../assets/icons/icon_letter_filled.svg';
import heartIcon from '../../../../../assets/icons/icon_heart.svg';
import heartFilledIcon from '../../../../../assets/icons/icon_heart_filled.svg';
import * as IMAGES from '../../../../common/Images';
import * as chatsAction from '../../../../../store/actions/chats';
import useCloseCurrentChat from '../../../../../hooks/chat/useCloseCurrentChat';

export default function Filter({ searchInput, handleOnline, handleSearch, onlineStatus, setFreeze, isFreeze }) {
    const dispatch = useDispatch();
    const chatsState = useSelector((state) => state.chats);
    const closeCurrentChat = useCloseCurrentChat();

    const [filterType, setFilterType] = useState({
        messages: false,
        letters: false,
        likes: false,
    });

    useEffect(() => {
        if (!filterType.messages && !filterType.likes && !filterType.letters && chatsState?.limits) {
            dispatch(chatsAction.setChatListFilterType(null));
        }
    }, [filterType.messages, filterType.letters, filterType.likes]);

    const changeFilterType = (e) => {
        const {
            target: { name },
        } = e;
        const limits = {
            messages: 1,
            letters: 2,
            likes: 4,
        };
        closeCurrentChat();
        switch (name) {
            case 'messages':
                setFilterType({
                    messages: !filterType.messages,
                    letters: false,
                    likes: false,
                });
                break;
            case 'letters':
                setFilterType({
                    messages: false,
                    letters: !filterType.letters,
                    likes: false,
                });
                break;
            case 'likes':
                setFilterType({
                    messages: false,
                    letters: false,
                    likes: !filterType.likes,
                });
        }
        dispatch(chatsAction.setChatListFilterType(limits[name]));
    };

    return (
        <div className={Styles.clmn_2_chat_top_filters_container}>
            <div className={Styles.clmn_2_chat_top_filters} data-testid="chat-list-filter">
                <div className={Styles.clmn_2_chat_top_filters_freeze_cube} onClick={setFreeze}>
                    {isFreeze ? <img src={IMAGES.freeze_cube_full} alt="" /> : <img src={IMAGES.freeze_cube} alt="" />}
                </div>
                <div className={Styles.clmn_2_chat_top_filters_is_online} data-testid="online">
                    <input type="checkbox" id="clmn_is_online" checked={onlineStatus} />
                    <label htmlFor="clmn_is_online" onClick={handleOnline} />
                </div>
                <div className={Styles.clmn_2_chat_top_filters_search}>
                    <input
                        type="text"
                        id="clmn_is_online"
                        data-testid="idOrName"
                        value={searchInput}
                        onChange={(e) => handleSearch(e?.target?.value)}
                        placeholder="Search ID or name"
                    />
                    <button />
                </div>
            </div>
            <div className={Styles.clmn_2_chat_top_filters_type}>
                <FormControlLabel
                    control={
                        <Checkbox
                            icon={<img src={messageIcon} alt="" />}
                            checkedIcon={<img src={messageFilledIcon} alt="" />}
                            checked={filterType.messages}
                            onChange={changeFilterType}
                            name="messages"
                        />
                    }
                    label="Messages"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            icon={<img src={letterIcon} alt="" />}
                            checkedIcon={<img src={letterFilledIcon} alt="" />}
                            checked={filterType.letters}
                            onChange={changeFilterType}
                            name="letters"
                        />
                    }
                    label="Letters"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            icon={<img src={heartIcon} alt="" />}
                            checkedIcon={<img src={heartFilledIcon} alt="" />}
                            checked={filterType.likes}
                            onChange={changeFilterType}
                            name="likes"
                        />
                    }
                    label="Likes"
                />
            </div>
        </div>
    );
}
