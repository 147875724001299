// CHATS
export const LOADING_CHAT_LIST = 'LOADING_CHAT_LIST';
export const ADD_CHAT_LIST = 'ADD_CHAT_LIST';
export const ADD_CHAT_LIST_WITH_PAGE = 'ADD_CHAT_LIST_WITH_PAGE';
export const ADD_CHAT = 'ADD_CHAT';
export const REMOVE_CHAT = 'REMOVE_CHAT';
export const ADD_LAST_MESSAGE = 'ADD_LAST_MESSAGE';
export const UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE';
export const HIDE_CHAT = 'HIDE_CHAT';
export const UNHIDE_CHAT = 'UNHIDE_CHAT';
export const BLOCK_CHAT = 'BLOCK_CHAT';
export const UNBLOCK_CHAT = 'UNBLOCK_CHAT';
export const CHAT_LIST_FILTER_ONLINE = 'CHAT_LIST_FILTER_ONLINE';
export const CHAT_LIST_FILTER_SEARCH = 'CHAT_LIST_FILTER_SEARCH';
export const CHAT_LIST_FILTER_FREEZE = 'CHAT_LIST_FILTER_FREEZE';
export const CHAT_LIST_FILTER_TYPE = 'CHAT_LIST_FILTER_TYPE';
export const REMOVE_UNREAD_COUNT = 'REMOVE_UNREAD_COUNT';
export const ADD_UNREAD_COUNT = 'ADD_UNREAD_COUNT';
export const ADD_UNREAD_COUNT_LIST = 'ADD_UNREAD_COUNT_LIST';
export const SET_FAVORITE_STATUS_CHAT_LIST = 'SET_FAVORITE_STATUS_CHAT_LIST';
export const SET_MAYBE_STATUS_CHAT_LIST = 'SET_MAYBE_STATUS_CHAT_LIST';
export const SET_SPECIAL_STATUS_CHAT_LIST = 'SET_SPECIAL_STATUS_CHAT_LIST';
export const INIT_PIN_CHAT_LIST = 'INIT_PIN_CHAT_LIST';
export const ADD_PIN_CHAT_LIST = 'ADD_PIN_CHAT_LIST';
export const REMOVE_PIN_CHAT_LIST = 'REMOVE_PIN_CHAT_LIST';
export const CHAT_LIST_UPDATE_ACTIVE_CHAT = 'CHAT_LIST_UPDATE_ACTIVE_CHAT';
export const SET_READ_LAST_MESS_FROM_YOU = 'SET_READ_LAST_MESS_FROM_YOU';
export const CHAT_LIST_SET_LIKE = 'CHAT_LIST_SET_LIKE';
export const CHAT_LIST_UPDATE_MESSAGE_LIMIT = 'CHAT_LIST_UPDATE_MESSAGE_LIMIT';
export const DELETE_CHAT = 'DELETE_CHAT';
export const OPEN_CHAT_LIST = 'OPEN_CHAT_LIST';
export const CLOSE_CHAT_LIST = 'CLOSE_CHAT_LIST';
export const CLEAR_CHAT_LIST = 'CLEAR_CHAT_LIST';
export const CHAT_LIST_HIDE_MESSAGE = 'CHAT_LIST_HIDE_MESSAGE';

// CURRENT CHAT
export const ADD_CURRENT_CHAT_OPTIONS = 'ADD_CURRENT_CHAT_OPTIONS';
export const ADD_CURRENT_CHAT = 'ADD_CURRENT_CHAT';
export const CURRENT_CHAT_SET_LIKE = 'CURRENT_CHAT_SET_LIKE';
export const REMOVE_CURRENT_CHAT = 'REMOVE_CURRENT_CHAT';
export const ADD_CURRENT_CHAT_HISTORY = 'ADD_CURRENT_CHAT_HISTORY';
export const ADD_CURRENT_CHAT_HISTORY_PAGE = 'ADD_CURRENT_CHAT_HISTORY_PAGE';
export const SET_FAVORITE_STATUS = 'SET_FAVORITE_STATUS';
export const SET_MAYBE_STATUS = 'SET_MAYBE_STATUS';
export const SET_SPECIAL_STATUS = 'SET_SPECIAL_STATUS';
export const CURRENT_CHAT_HISTORY_ADD_MESSAGE = 'CURRENT_CHAT_HISTORY_ADD_MESSAGE';
export const CURRENT_CHAT_HISTORY_REMOVE_MESSAGE = 'CURRENT_CHAT_HISTORY_REMOVE_MESSAGE';
export const CURRENT_CHAT_HISTORY_UPDATE_MESSAGE = 'CURRENT_CHAT_HISTORY_UPDATE_MESSAGE';
export const REDUCE_MESSAGES_LEFT = 'REDUCE_MESSAGES_LEFT';
export const INCREASE_MESSAGES_LEFT = 'INCREASE_MESSAGES_LEFT';
export const SET_READ_LAST_MESS = 'SET_READ_LAST_MESS';
export const UPDATE_CURRENT_CHAT_MESSAGE_LIMIT = 'UPDATE_CURRENT_CHAT_MESSAGE_LIMIT';
export const SET_ALL_MEDIA_LIST = 'SET_ALL_MEDIA_LIST';
export const UPDATE_ALL_MEDIA_LIST = 'UPDATE_ALL_MEDIA_LIST';
export const SET_MEDIA_LIST_BY_PAGE = 'SET_MEDIA_LIST_BY_PAGE';
export const RESET_MEDIA_LIST_BY_PAGE = 'RESET_MEDIA_LIST_BY_PAGE';
export const MAIN_CHAT_START_PAGE = 'MAIN_CHAT_START_PAGE';
export const MAIN_CHAT_SET_HIDE_PAGE_DOWN = 'MAIN_CHAT_SET_HIDE_PAGE_DOWN';
export const MAIN_CHAT_ADD_HISTORY_PAGE_LOADING = 'MAIN_CHAT_ADD_HISTORY_PAGE_LOADING';
export const MAIN_CHAT_HIDE_MESSAGE = 'MAIN_CHAT_HIDE_MESSAGE';
export const BLOCK_USER = 'BLOCK_USER';
export const UNBLOCK_USER = 'UNBLOCK_USER';
