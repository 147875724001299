import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as LENGTH from '../../constants/length';
import * as REGEXP from '../../constants/regExp';
import { checkUrl, hideStringStar } from '../../services/methods';
import * as alertsAction from '../../store/actions/alerts';
import * as senderAction from '../../store/actions/sender';
import SenderApi from '../../services/api/SenderApi';
import { switchSenderGroupToRequest, switchSenderTypeToRequest } from '../../services/methods/sender';
import * as ERROR from '../../constants/error';
import * as profilesAction from '../../store/actions/profiles';
import * as SENDER from '../../constants/sender';
import useHandleSenderHeader from './useHandleSenderHeader';
import * as errorsAction from '../../store/actions/alerts';

export default function useHandleSenderForm() {
    const dispatch = useDispatch();
    const { activeSenderType } = useHandleSenderHeader();

    const profilesState = useSelector((state) => state.profiles);
    const senderState = useSelector((state) => state.sender);

    const [isOpenTranslateBlock, setIsOpenTranslateBlock] = useState(false);
    const [messageContent, setMessageContent] = useState('');
    const [textSourceTranslate, setTextSourceTranslate] = useState('');
    const [textTargetTranslate, setTextTargetTranslate] = useState('');
    const [editID, setEditID] = useState(null);
    const [messageAttachment, setMessageAttachment] = useState([]);
    const [isDisabledButton, setIsDisabledButton] = useState(false);

    const maxLengthSymbols =
        activeSenderType === SENDER.SENDER_TYPE_CHAT ? LENGTH.MESSAGE_MAX_LENGTH : LENGTH.LETTER_MAX_LENGTH_SENDER;
    const minLengthSymbols =
        activeSenderType === SENDER.SENDER_TYPE_CHAT ? LENGTH.MESSAGE_MIN_LENGTH : LENGTH.LETTER_MIN_LENGTH_SENDER;

    const checkOnMaxLength = (value) => +value?.length <= maxLengthSymbols;
    const checkOnMinLength = (value) => +value?.length >= minLengthSymbols;

    const checkOnCyrillicLetters = (value) => (value?.length > 0 ? REGEXP.INPUT_OPERATOR.test(value) : true);

    const handleMessageContent = (value) => {
        if (!+value.length) {
            setTextSourceTranslate('');
            setTextTargetTranslate('');
        }

        if (checkOnCyrillicLetters(value) && checkOnMaxLength(value)) setMessageContent(value);
    };

    const addEmoji = (emoji) => {
        if (checkOnMaxLength((isOpenTranslateBlock ? textSourceTranslate : messageContent) + emoji.native)) {
            setMessageContent((isOpenTranslateBlock ? textSourceTranslate : messageContent) + emoji.native);
        }
    };

    const addMessageAttachment = (file, attachment_type) => {
        setMessageAttachment((prevState) => [...prevState, { ...file, attachment_type }]);
    };

    const deleteFileAttachment = (index) => {
        setMessageAttachment(messageAttachment.filter((e) => messageAttachment.indexOf(e) !== index));
    };

    const handleTextSourceTranslate = (value) => {
        if (!+value.length) setTextTargetTranslate('');
        if (checkOnMaxLength(value)) setTextSourceTranslate(value);
    };

    const handleTextTargetTranslate = (value) => {
        if (checkOnCyrillicLetters(value) && checkOnMaxLength(value)) {
            setTextTargetTranslate(value);
            // setMessageContent(value);
        }
    };

    const handleTranslateSwitcher = () => {
        if (isOpenTranslateBlock) setMessageContent(textTargetTranslate);
        else setTextSourceTranslate(messageContent);

        setIsOpenTranslateBlock(!isOpenTranslateBlock);
    };

    const backToInitMessageContent = () => {
        setMessageContent('');
        setTextSourceTranslate('');
        setTextTargetTranslate('');
        setEditID(null);
        setMessageAttachment([]);
    };

    const onEnterMessageContent = (event, callback) => {
        if (event.key === 'Enter') {
            const value = event.target.value;
            const lines = value.split('\n');

            //Enter + Shift
            if (event.shiftKey) {
                if (lines?.length < LENGTH.MESSAGE_MAX_NUMBER_LINE) return false;
                else return event.preventDefault();
            }
            //Enter + Ctrl
            if (event.ctrlKey && isOpenTranslateBlock && callback) return callback();

            event.preventDefault();
            onSaveSender(activeSenderType, messageAttachment);
        }
    };

    const getSubmitMessageWithValidation = () => {
        let submitMessageContent = isOpenTranslateBlock ? textTargetTranslate : messageContent;

        submitMessageContent = hideStringStar(submitMessageContent.trim(), REGEXP.NUMBER_IN_STRING);
        if (!submitMessageContent?.length) {
            backToInitMessageContent();
            return null;
        }

        if (checkUrl(submitMessageContent)) {
            dispatch(alertsAction.setMessage('You cannot send url', 'Invalid message'));
            return null;
        }

        return submitMessageContent;
    };

    const onSaveSender = (activeSenderType, messageAttachment = []) => {
        if (isDisabledButton) return false;

        const womanProfile = profilesState.list.find((item) => +item?.external_id === +senderState?.activeProfile);
        const submitMessage = getSubmitMessageWithValidation();

        const myAttachments = messageAttachment?.map((el) => {
            return { link: el?.link, attachment_type: el?.attachment_type };
        });

        if (!checkOnMinLength(submitMessage?.trim()))
            return dispatch(errorsAction.setMessage('', `Letter min size ${minLengthSymbols} symbols`));
        if (!checkOnCyrillicLetters(submitMessage) || !checkOnMaxLength(submitMessage)) {
            return dispatch(
                alertsAction.setMessage(
                    `You cannot send this message. Only Latin alphabet and the maximum number of symbols is ${maxLengthSymbols}.`,
                    'Invalid message',
                ),
            );
        }

        if (womanProfile?.external_id && submitMessage && activeSenderType) {
            setIsDisabledButton(true);

            if (editID) {
                if (activeSenderType === SENDER.SENDER_TYPE_CHAT) {
                    new SenderApi()
                        .postEditInviteList({
                            id: editID,
                            external_id: womanProfile?.external_id,
                            woman_id: womanProfile?.id,
                            message_content: submitMessage,
                            message_type: SENDER.SENT_TEXT,
                            sender_type: switchSenderTypeToRequest(senderState?.activeType).toLowerCase(),
                        })
                        .then((res) => {
                            setIsDisabledButton(false);

                            if (res?.status) {
                                if (res?.inviteList?.length)
                                    dispatch(senderAction.setActiveSenderProfileSendList(res?.inviteList));
                            } else {
                                if (res?.message) dispatch(alertsAction.setMessage('', res?.message));
                                else dispatch(alertsAction.setMessage('', ERROR.ERROR_REQUEST));
                            }
                        });
                }
                if (activeSenderType === SENDER.SENDER_TYPE_LETTER) {
                    new SenderApi()
                        .postEditInviteList({
                            id: editID,
                            external_id: womanProfile?.external_id,
                            woman_id: womanProfile?.id,
                            message_content: { message_content: submitMessage, attachments: myAttachments },
                            message_type: SENDER.SENT_TEXT,
                            sender_type: switchSenderTypeToRequest(senderState?.activeType).toLowerCase(),
                        })
                        .then((res) => {
                            setIsDisabledButton(false);

                            if (res?.status) {
                                if (res?.inviteList?.length) {
                                    dispatch(
                                        senderAction.addActiveSenderProfileSendList(
                                            womanProfile?.external_id,
                                            switchSenderTypeToRequest(senderState?.activeType),
                                        ),
                                    );
                                    dispatch(senderAction.setActiveSenderProfileSendList(res?.inviteList));
                                }
                            } else {
                                if (res?.message) dispatch(alertsAction.setMessage('', res?.message));
                                else dispatch(alertsAction.setMessage('', ERROR.ERROR_REQUEST));
                            }
                        });
                }
            } else {
                if (activeSenderType === SENDER.SENDER_TYPE_CHAT) {
                    new SenderApi()
                        .postAddInviteList({
                            external_id: womanProfile?.external_id,
                            woman_id: womanProfile?.id,
                            message_content: submitMessage,
                            message_type: SENDER.SENT_TEXT,
                            sender_type: switchSenderTypeToRequest(senderState?.activeType).toLowerCase(),
                        })
                        .then((res) => {
                            setIsDisabledButton(false);

                            if (res?.status) {
                                if (res?.inviteList?.length)
                                    dispatch(senderAction.setActiveSenderProfileSendList(res?.inviteList));
                            } else {
                                if (res?.message) dispatch(alertsAction.setMessage('', res?.message));
                                else dispatch(alertsAction.setMessage('', ERROR.ERROR_REQUEST));
                            }
                        });
                } else if (activeSenderType === SENDER.SENDER_TYPE_LETTER) {
                    new SenderApi()
                        .postAddInviteList({
                            external_id: womanProfile?.external_id,
                            woman_id: womanProfile?.id,
                            message_content: { message_content: submitMessage, attachments: myAttachments },
                            message_type: SENDER.SENT_TEXT,
                            sender_type: switchSenderTypeToRequest(senderState?.activeType).toLowerCase(),
                        })
                        .then((res) => {
                            setIsDisabledButton(false);

                            if (res?.status) {
                                if (res?.inviteList?.length) {
                                    dispatch(
                                        senderAction.addActiveSenderProfileSendList(
                                            womanProfile?.external_id,
                                            switchSenderTypeToRequest(senderState?.activeType),
                                        ),
                                    );
                                    dispatch(senderAction.setActiveSenderProfileSendList(res?.inviteList));
                                }
                            } else {
                                if (res?.message) dispatch(alertsAction.setMessage('', res?.message));
                                else dispatch(alertsAction.setMessage('', ERROR.ERROR_REQUEST));
                            }
                        });
                }
            }
            backToInitMessageContent();
        }
    };

    const onEditSender = (messageID) => {
        const sendItem = senderState?.activeProfileSendList.find((item) => +item?.id === +messageID);
        const womanProfile = profilesState.list.find((item) => +item?.external_id === +senderState?.activeProfile);

        if (womanProfile?.external_id && sendItem?.id) {
            setEditID(sendItem?.id);
            setMessageContent(sendItem?.message_content);
            if (sendItem?.attachments?.length) setMessageAttachment(sendItem?.attachments);
        }
    };

    const onDeleteSender = (messageID) => {
        const womanProfile = profilesState.list.find((item) => +item?.external_id === +senderState?.activeProfile);

        if (womanProfile?.external_id && messageID) {
            new SenderApi()
                .deleteInvite({
                    external_id: womanProfile?.external_id,
                    id: messageID,
                    mail_type: switchSenderTypeToRequest(senderState?.activeType),
                })
                .then((res) => {
                    setIsDisabledButton(false);

                    if (res) {
                        dispatch(senderAction.setActiveSenderProfileSendList(res));
                    }
                });
        }
    };

    const onSendSender = (activeSenderType, message, attachmentsContent = [], inviteID) => {
        const womanProfile = profilesState.list.find((item) => +item?.external_id === +senderState?.activeProfile);
        const submitMessage = message?.length ? message : getSubmitMessageWithValidation();
        const myAttachments = attachmentsContent?.map((el) => {
            return { link: el?.link, attachment_type: el?.attachment_type };
        });

        if (!+womanProfile?.online) return dispatch(alertsAction.setMessage('(Error)', 'Current operator is offline!'));

        if (womanProfile?.external_id && submitMessage && activeSenderType) {
            if (activeSenderType === SENDER.SENDER_TYPE_CHAT) {
                new SenderApi()
                    .postRunSender({
                        agencyId: womanProfile?.agency_id,
                        audience: switchSenderGroupToRequest(senderState?.activeGroups[0]),
                        excludeAudience:
                            senderState?.activeGroups?.length > 1
                                ? senderState?.activeGroups
                                      .filter((item, key) => key !== 0)
                                      .map((item) => switchSenderGroupToRequest(item))
                                      .join()
                                : null,
                        messageContent: submitMessage,
                        messageType: SENDER.SENT_TEXT,
                        operatorId: profilesState?.operatorId,
                        senderType: switchSenderTypeToRequest(senderState?.activeType),
                        womanExternalId: womanProfile?.external_id,
                        womanId: womanProfile?.id,
                        inviteID,
                    })
                    .then((res) => {
                        setIsDisabledButton(false);

                        if (+res?.success) {
                            dispatch(alertsAction.setMessage('', 'Sender run successfully'));
                            dispatch(
                                profilesAction.addActiveSenderInfo(
                                    profilesState.list?.map((item) => +item?.external_id),
                                ),
                            );
                        } else {
                            if (res?.error)
                                dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                            else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                        }
                    });
            } else if (activeSenderType === SENDER.SENDER_TYPE_LETTER) {
                new SenderApi()
                    .postRunLetterSender({
                        agencyId: womanProfile?.agency_id,
                        audience: switchSenderGroupToRequest(senderState?.activeGroups[0]),
                        excludeAudience:
                            senderState?.activeGroups?.length > 1
                                ? senderState?.activeGroups
                                      .filter((item, key) => key !== 0)
                                      .map((item) => switchSenderGroupToRequest(item))
                                      .join()
                                : null,
                        messageContent: submitMessage,
                        attachmentsContent: myAttachments,
                        messageType: SENDER.SENT_TEXT,
                        operatorId: profilesState?.operatorId,
                        senderType: switchSenderTypeToRequest(senderState?.activeType),
                        womanExternalId: womanProfile?.external_id,
                        womanId: womanProfile?.id,
                        inviteID,
                    })
                    .then((res) => {
                        setIsDisabledButton(false);

                        if (+res?.success) {
                            dispatch(alertsAction.setMessage('', 'Sender run successfully'));
                            dispatch(
                                profilesAction.addActiveSenderInfo(
                                    profilesState.list?.map((item) => +item?.external_id),
                                ),
                            );
                        } else {
                            if (res?.error)
                                dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                            else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                        }
                    });
            }

            backToInitMessageContent();
        }
    };

    const checkActiveSender = (itemSavedMess) => {
        return profilesState?.senderListInfo.some(
            (itemSender) =>
                +itemSender?.woman_external_id === +itemSavedMess?.profile_external_id &&
                +itemSavedMess?.id === +itemSender?.invite_id,
        );
    };

    useEffect(() => {
        if (isOpenTranslateBlock) setTextSourceTranslate(messageContent);
    }, [messageContent]);

    useEffect(() => {
        if (activeSenderType && senderState?.activeProfile) {
            setMessageContent('');
            setMessageAttachment([]);
        }
    }, [activeSenderType, senderState.activeProfile]);

    return {
        isOpenTranslateBlock,

        messageContent,
        textTargetTranslate,
        textSourceTranslate,
        messageAttachment,
        setTextSourceTranslate,
        setTextTargetTranslate,

        handleMessageContent,
        handleTextSourceTranslate,
        handleTextTargetTranslate,
        onEnterMessageContent,
        handleTranslateSwitcher,
        addMessageAttachment,
        deleteFileAttachment,

        onSendSender,
        onEditSender,
        onDeleteSender,
        onSaveSender,
        backToInitMessageContent,

        addEmoji,
        checkActiveSender,
        submitMessageContent: isOpenTranslateBlock ? textTargetTranslate.trim() : messageContent.trim(),
        isDisabledButton,
        setIsDisabledButton,
        checkOnMaxLength,
    };
}
