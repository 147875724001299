import * as SENDER from '../constants/sender';
import SenderApi from '../../services/api/SenderApi';

export function setActiveSenderProfileSendList(sendList) {
    return function (dispatch) {
        return dispatch({
            type: SENDER.SET_ACTIVE_SENDER_PROFILE_SEND_LIST,
            sendList,
        });
    };
}

export function addActiveSenderProfileSendList(activeProfile, activeType) {
    return function (dispatch) {
        dispatch({
            type: SENDER.REMOVE_ACTIVE_SENDER_PROFILE_SEND_LIST,
        });

        new SenderApi()
            .getInviteList({
                external_id: activeProfile,
                mail_type: activeType,
            })
            .then((res) => {
                if (res?.length) {
                    return dispatch({
                        type: SENDER.ADD_ACTIVE_SENDER_PROFILE_SEND_LIST,
                        sendList: res,
                    });
                }
            });
    };
}

export function setActiveSenderProfile(externalID) {
    return function (dispatch) {
        return dispatch({
            type: SENDER.SET_ACTIVE_SENDER_PROFILE,
            externalID,
        });
    };
}

export function setActiveSenderType(senderType) {
    return function (dispatch) {
        return dispatch({
            type: SENDER.SET_ACTIVE_SENDER_TYPE,
            senderType,
        });
    };
}

export function addActiveSenderGroup(senderGroup) {
    return function (dispatch) {
        return dispatch({
            type: SENDER.ADD_ACTIVE_SENDER_GROUP,
            senderGroup,
        });
    };
}

export function removeActiveSenderGroup(senderGroup) {
    return function (dispatch) {
        return dispatch({
            type: SENDER.REMOVE_ACTIVE_SENDER_GROUP,
            senderGroup,
        });
    };
}

export function setSenderSendingTime(sendingTime) {
    return function (dispatch) {
        return dispatch({
            type: SENDER.SET_SENDING_TIME,
            sendingTime,
        });
    };
}

export function setSenderSendingTimeDate(sendingTimeDate) {
    return function (dispatch) {
        return dispatch({
            type: SENDER.SET_SENDING_TIME_DATE,
            sendingTimeDate,
        });
    };
}

export function setSenderWillExpire(willExpire) {
    return function (dispatch) {
        return dispatch({
            type: SENDER.SET_WILL_EXPIRE,
            willExpire,
        });
    };
}

export function setSenderWillExpireDate(willExpireSetTimeNum) {
    return function (dispatch) {
        return dispatch({
            type: SENDER.SET_WILL_EXPIRE_DATE,
            willExpireSetTimeNum,
        });
    };
}

export function openSendersList() {
    return function (dispatch) {
        return dispatch({
            type: SENDER.OPEN_SENDER_LIST,
        });
    };
}

export function closeSendersList() {
    return function (dispatch) {
        return dispatch({
            type: SENDER.CLOSE_SENDER_LIST,
        });
    };
}
