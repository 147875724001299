import React from 'react';

export default function Header() {
    return (
        <div className="c3_woman_profile_head">
            {/*<ProfileHeaderTabsButton classType={'c3wphi_letter'}*/}
            {/*						 titleButton={'Write a letter'}*/}
            {/*						 onPressButton={openLetterChat}/>*/}
            {/*<ProfileHeaderTabsButton classType={'c3wphi_chat'}*/}
            {/*						 titleButton={'Go to chat'}*/}
            {/*						 onPressButton={openChat}/>*/}
        </div>
    );
}
