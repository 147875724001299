import Base from './Base';

export default class SenderApi extends Base {
    constructor(base) {
        super(base);
        this.url_sender = '/api/v2';
    }

    postRunSender({
        operatorId,
        agencyId,
        womanId,
        womanExternalId,
        senderType,
        messageType,
        messageContent,
        audience,
        excludeAudience,
        inviteID,
    }) {
        const formData = new FormData();
        const obj = {
            operator_id: operatorId,
            bearer: this.getUserInfo(),
            agency_id: agencyId,
            woman_id: womanId,
            woman_external_id: womanExternalId,
            sender_type: senderType,
            message_type: messageType,
            message_content: messageContent,
            audience: audience,
            exclude_audience: excludeAudience,
            invite_id: inviteID,
        };

        for (let item in obj) {
            formData.append(item, obj[item]);
        }

        return super
            .post(`sender/create`, formData)
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    postRunLetterSender({
        operatorId,
        agencyId,
        womanId,
        womanExternalId,
        senderType,
        messageType,
        messageContent,
        attachmentsContent,
        audience,
        excludeAudience,
        inviteID,
    }) {
        return super
            .post(`sender/create`, {
                operator_id: operatorId,
                bearer: this.getUserInfo(),
                agency_id: agencyId,
                woman_id: womanId,
                woman_external_id: womanExternalId,
                sender_type: senderType,
                message_type: messageType,
                message_content: messageContent,
                attachments_content: attachmentsContent,
                audience: audience,
                exclude_audience: excludeAudience,
                invite_id: inviteID,
            })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    postSaveSender({
        operatorId,
        agencyId,
        womanId,
        womanExternalId,
        senderType,
        messageType,
        messageContent,
        audience,
        excludeAudience,
    }) {
        return super.postThirdParty(
            `${this.url_sender}/sender/save`,
            {
                operator_id: operatorId,
                agency_id: agencyId,
                woman_id: womanId,
                woman_external_id: womanExternalId,
                sender_type: senderType,
                message_type: messageType,
                message_content: messageContent,
                audience: audience,
                exclude_audience: excludeAudience,
            },
            this.getAuthHeader(),
        );
    }

    getInviteList({ external_id, mail_type }) {
        return super
            .get(`sender/inviteList?external_id=${external_id}&mail_type=${mail_type}`)
            .then((res) => res?.data)
            .catch(() => []);
    }

    postAddInviteList({ external_id, woman_id, message_content, message_type, sender_type }) {
        return super
            .post(`sender/addInvite`, {
                external_id,
                woman_id,
                message_content,
                message_type,
                sender_type,
            })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    postEditInviteList({ id, external_id, woman_id, message_content, message_type, sender_type }) {
        return super
            .post(`sender/editInvite`, {
                id,
                external_id,
                woman_id,
                message_content,
                message_type,
                sender_type,
            })
            .then((res) => res?.data)
            .catch(() => []);
    }

    getSenderList() {
        return super.get(`sender/senderByOperatorId`).then((res) => res?.data);
    }

    deleteInvite({ external_id, id, mail_type }) {
        return super
            .get(`sender/deleteInvite?external_id=${external_id}&id=${id}&mail_type=${mail_type}`)
            .then((res) => res?.data);
    }
}
