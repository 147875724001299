export const FILES_LIMIT = 'error_files_limit';
export const LETTER_LENGTH = 'error_letter_length';
export const ENOUGH_CREDITS = 'error_enough_credits';
export const NOT_EXIST_OR_BLOCK = 'not_exist_or_block';
export const ENOUGH_CREDITS_TO_VIEW = 'enough_credit_to_view';
export const ENOUGH_CREDITS_TO_LISTEN = 'enough_credit_to_listen';
export const MAIL_SENT_SUCCESSFULLY = 'MAIL_SENT_SUCCESSFULLY';
export const INTERNAL_ERROR_OCCURRED = 'Internal server occurred';
export const NEW_MESSAGE = 'NEW_MESSAGE';

export const ERROR_REQUEST = 'Something went wrong!';

//errors from server side
export const AUTH_ERROR = 'Authorization Required';
export const NEW_AUTH_ERROR = 'Wrong token or not verified';
export const UNAUTHORIZED_STATUS = 401;

export const INVALID_TYPE_VIDEO = 'Video must be in mp4 format';
