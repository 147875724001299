export const DEFAULT_CONFIG = {
    letter_max_size_operator: '1500',
    letter_min_size_operator: '1',
    message_max_size_operator: '300',
    message_min_size_operator: '1',
};

//type_1 = 18+
export const COMPLAINT_TYPE_1 = 'complaint_type_1';
//type_2 = country
export const COMPLAINT_TYPE_2 = 'complaint_type_2';
//type_3 = contact
export const COMPLAINT_TYPE_3 = 'complaint_type_3';
//type_4 = child
export const COMPLAINT_TYPE_4 = 'complaint_type_4';

// Show "No contacts" label in chat by site id
export const SHOW_NO_CONTACTS = [2, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22];

// Show "Social" label in chat by site id
export const SHOW_SOCIAL = [23];
