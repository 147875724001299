import React from 'react';
import { DefaultPlayer as Video } from 'react-html5video';
//custom style
import 'react-html5video/dist/styles.css';
import '../../../styles/video-player/style.scss';

export default ({ data }) => {
    return (
        <React.Fragment>
            <Video
                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                // poster="http://sourceposter.jpg"
                autoPlay
            >
                <source src={data} type="video/mp4" />
            </Video>
        </React.Fragment>
    );
};
