import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../components/common/AllMediaModal/styles.module.scss';
import * as currentChatAction from '../../store/actions/currentChat';
import { formatDateMedia } from '../../services/time';
import * as MEDIA from '../../constants/media';
import * as CHAT from '../../constants/chat';
import { useLocation } from 'react-router-dom';

export default function useInitMediaContent({
    activeTypeMedia,
    activeTypeSender,
    activeSenderAllMedia,
    setActiveSenderAllMedia,
    mapMediaListItem,
    setMapMediaList,
    mapMediaList,
}) {
    const dispatch = useDispatch();
    const location = useLocation();

    const currentChatState = useSelector((state) => state.currentChat);
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);

    const [isLoading, setIsLoading] = useState(true);

    const scrollTopMediaList = () => {
        const mediaListMonth = document.querySelector(`.${styles.media_list_month}`);

        if (mediaListMonth) mediaListMonth.scrollIntoView();
    };

    const filterByFileType = (mediaListType, item) => {
        if (mediaListType === MEDIA.TAB_ALL_MEDIA) return true;
        else if (mediaListType === MEDIA.TAB_IMAGE)
            return [CHAT.MESSAGE_CONTENT_TYPE_IMAGE, CHAT.MESSAGE_CONTENT_TYPE_IMAGE_MAIL]?.includes(
                item?.message_type,
            );
        else if (mediaListType === MEDIA.TAB_VIDEO)
            return [CHAT.MESSAGE_CONTENT_TYPE_VIDEO, CHAT.MESSAGE_CONTENT_TYPE_VIDEO_MAIL]?.includes(
                item?.message_type,
            );
        else if (mediaListType === MEDIA.TAB_AUDIO)
            return [CHAT.MESSAGE_CONTENT_TYPE_AUDIO, CHAT.MESSAGE_CONTENT_TYPE_AUDIO_MAIL]?.includes(
                item?.message_type,
            );
        return false;
    };

    const mapFormatDateMedia = (item) => {
        if (item?.date_created) return { ...item, date_created_str: formatDateMedia(new Date(item?.date_created)) };
        return item;
    };

    const handleScroll = (e) => {
        const getNextPage = e.target.scrollTop > e.target.scrollHeight - e.target.offsetHeight - 100 * 2;

        if (
            getNextPage &&
            !isLoading &&
            MEDIA.MEDIA_LIST_QUANTITY * currentChatState?.mediaPage <= mapMediaList?.length
        ) {
            dispatch(currentChatAction.setChatMediaListByPage(+currentChatState?.mediaPage + 1));
            setIsLoading(true);
        }
    };

    useMemo(() => {
        if (Array.isArray(activeSenderAllMedia)) {
            setMapMediaList(
                activeSenderAllMedia
                    .map(mapFormatDateMedia)
                    .filter((item) => filterByFileType(activeTypeMedia, item))
                    .slice(0, MEDIA.MEDIA_LIST_QUANTITY * currentChatState?.mediaPage)
                    .map(mapMediaListItem),
            );
        }
    }, [activeTypeMedia, activeTypeSender, activeSenderAllMedia, currentChatState.mediaPage]);

    useEffect(() => {
        if (!isLoading) {
            scrollTopMediaList();
            dispatch(currentChatAction.resetChatMediaListByPage());
        }
    }, [activeTypeSender, activeTypeMedia]);

    useEffect(() => {
        if (currentChatState?.allMediaList?.length) {
            if (location?.pathname?.includes('letter')) {
                if (activeTypeSender === MEDIA.TYPE_WOMAN) {
                    setActiveSenderAllMedia(currentChatState?.allMediaList?.filter((el) => +el?.operator === 1));
                } else {
                    setActiveSenderAllMedia(currentChatState?.allMediaList?.filter((el) => +el?.operator === 0));
                }
            } else {
                if (activeTypeSender === MEDIA.TYPE_WOMAN) {
                    setActiveSenderAllMedia(currentChatState?.allMediaList?.filter((el) => +el?.is_male === 0));
                } else {
                    setActiveSenderAllMedia(currentChatState?.allMediaList?.filter((el) => +el?.is_male === 1));
                }
            }
        }
    }, [currentChatState.allMediaList, activeTypeSender]);

    useEffect(() => {
        // if (!currentChatState?.isAllMediaLoaded) {
        if (location?.pathname?.includes('letter')) {
            if (currentChatMailboxState?.mailChatInfo?.chat_uid) {
                dispatch(currentChatAction.setAllMediaListLetter(currentChatMailboxState?.mailChatInfo?.chat_uid));
            }
        } else {
            if (currentChatState?.info?.chat_uid) {
                dispatch(currentChatAction.setAllMediaList(currentChatState?.info?.chat_uid));
            }
        }
        // }
    }, [currentChatMailboxState?.mailChatInfo?.chat_uid, currentChatState?.info?.chat_uid]);

    useEffect(() => {
        setIsLoading(false);
    }, [currentChatState.mediaPage]);

    return {
        handleScroll,
    };
}
