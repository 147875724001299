// ATTACH
export const ATTACH_VIDEO_TITLE = 12;
export const ATTACH_LETTER_AUDIO_TITLE = 20;
export const SUPPORT_IMG_MAX_LENGTH = 12;
export const ATTACH_TITLE_NAME = 12;

export const MAX_IMG_SIZE = 5000000;
export const MAX_VIDEO_SIZE = 50000000;
export const MAX_AUDIO_SIZE = 10000000;

export const MAX_IMG_SIZE_TITLE = 5;
export const MAX_VIDEO_SIZE_TITLE = 50;
export const MAX_AUDIO_SIZE_TITLE = 10;

export const MAX_AUDIO_TITLE = 70;
export const MAX_ATTACHMENTS_TO_LETTER = 5;

// MESSAGE TEXT
export const LETTER_MIN_LENGTH = 150;
export const LETTER_MAX_LENGTH = 5000;
export const LETTER_MIN_LENGTH_SENDER = 300;
export const LETTER_MAX_LENGTH_SENDER = 5000;
export const MESSAGE_MIN_LENGTH = 1;
export const MESSAGE_MAX_LENGTH = 300;
export const MESSAGE_MAX_NUMBER_LINE = 20;

// SIZE SCREEN
export const MIN_DESKTOP_SIZE_SCREEN = 1000;

// NOTES
export const NOTES_MAX_LENGTH = 255;
