import { useDispatch, useSelector } from 'react-redux';
import * as notifyAction from '../../store/actions/notify';
import { useEffect } from 'react';
import * as TIMER from '../../constants/timer';

export default function useInitNotification() {
    const dispatch = useDispatch();
    const profilesState = useSelector((state) => state.profiles);

    // UseEffect to check expired date in notification
    useEffect(() => {
        // Initialisation interval
        const timer = setInterval(() => {
            // Check notification in localStorage
            dispatch(notifyAction.checkPaidNotifyInLocalStorage(profilesState?.operatorId));
            // Check notification expire date
            dispatch(notifyAction.checkNotifyExpDate(profilesState?.operatorId));
        }, TIMER.CHECK_NOTICE_EXP_DATE);

        return () => {
            // Clear old interval
            clearInterval(timer);
        };
    }, [profilesState?.operatorId]);
}
