import React, { useState } from 'react';
import Modal from 'react-modal';
// import 'emoji-mart/css/emoji-mart.css'
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import smileStyle from '../../../../../../styles/react-modal/smile.style';

export default ({ addEmoji }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return (
        <React.Fragment>
            <div className="c3_write_msg_ico_item c3_smile" onClick={() => setModalIsOpen(true)}>
                <img src="../img/c3-msg-smile.svg" alt="" />
            </div>
            <Modal
                style={smileStyle}
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Emoji Woman Modal"
                ariaHideApp={false}
            >
                <Picker
                    data={data}
                    onEmojiSelect={addEmoji}
                    emojiSize={32}
                    theme="light"
                    navPosition="none"
                    previewPosition="none"
                    searchPosition="none"
                />
            </Modal>
        </React.Fragment>
    );
};
