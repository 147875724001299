export const AUDIENCE_WOMAN = 'woman';
export const AUDIENCE_MAN = 'man';
export const CHAT_LIST_LENGTH = 30;
export const MESSAGE_CONTENT_TYPE_LETTER = 'SENT_LETTER';
export const MESSAGE_CONTENT_TYPE_TEXT = 'SENT_TEXT';
export const MESSAGE_CONTENT_TYPE_ASK_VIDEO = 'ASK_VIDEO';
export const MESSAGE_CONTENT_TYPE_IMAGE = 'SENT_IMAGE';
export const MESSAGE_CONTENT_TYPE_IMAGE_MAIL = 'SENT_IMAGE_MAIL';
export const MESSAGE_CONTENT_TYPE_VIDEO = 'SENT_VIDEO';
export const MESSAGE_CONTENT_TYPE_VIDEO_MAIL = 'SENT_VIDEO_MAIL';
export const MESSAGE_CONTENT_TYPE_AUDIO = 'SENT_AUDIO';
export const MESSAGE_CONTENT_TYPE_AUDIO_MAIL = 'SENT_AUDIO_MAIL';
export const MESSAGE_CONTENT_TYPE_WINK = 'SENT_WINK';
export const MESSAGE_CONTENT_TYPE_STICKER = 'SENT_STICKER';
export const MESSAGE_CONTENT_TYPE_LIKE = 'SENT_LIKE';
export const MESSAGE_CONTENT_TYPE_VIEW = 'SENT_VIEW';
export const MESSAGE_CONTENT_TYPE_REPLY = 'REPLY_POST';
export const MESSAGE_CONTENT_TYPE_ONLINE = 'SENT_ONLINE';
export const MESSAGE_CONTENT_TYPE_VIEW_GALLERY = 'SENT_VIEW_GALLERY';
export const MESSAGE_CONTENT_TYPE_VIEW_PHOTOS = 'SENT_VIEW_PHOTOS';
export const MESSAGE_CONTENT_TYPE_VIRTUAL_GIFT = 'SENT_VIRTUAL_GIFT';

export const NOTIFY_ALL_MEDIA_LIST = [
    MESSAGE_CONTENT_TYPE_IMAGE,
    MESSAGE_CONTENT_TYPE_VIDEO,
    MESSAGE_CONTENT_TYPE_AUDIO,
];

export const PAID_MESSAGE_CONTENT = [
    MESSAGE_CONTENT_TYPE_TEXT,
    MESSAGE_CONTENT_TYPE_IMAGE,
    MESSAGE_CONTENT_TYPE_VIDEO,
    MESSAGE_CONTENT_TYPE_AUDIO,
    MESSAGE_CONTENT_TYPE_STICKER,
    MESSAGE_CONTENT_TYPE_WINK,
    MESSAGE_CONTENT_TYPE_LIKE,
    MESSAGE_CONTENT_TYPE_LETTER,
    MESSAGE_CONTENT_TYPE_ASK_VIDEO,
    MESSAGE_CONTENT_TYPE_VIRTUAL_GIFT,
];
export const CHAT_MESSAGE_CONTENT = [
    MESSAGE_CONTENT_TYPE_TEXT,
    MESSAGE_CONTENT_TYPE_IMAGE,
    MESSAGE_CONTENT_TYPE_VIDEO,
    MESSAGE_CONTENT_TYPE_AUDIO,
    MESSAGE_CONTENT_TYPE_STICKER,
    MESSAGE_CONTENT_TYPE_WINK,
    MESSAGE_CONTENT_TYPE_LIKE,
    MESSAGE_CONTENT_TYPE_ASK_VIDEO,
    MESSAGE_CONTENT_TYPE_VIRTUAL_GIFT,
    MESSAGE_CONTENT_TYPE_REPLY,
];
export const FREE_MESSAGE_CONTENT = [MESSAGE_CONTENT_TYPE_ONLINE];

export const LOOKING_MESSAGE_CONTENT = [MESSAGE_CONTENT_TYPE_VIEW, MESSAGE_CONTENT_TYPE_VIEW_GALLERY];
export const RECONNECTION_MESSAGE_CONTENT = [MESSAGE_CONTENT_TYPE_LIKE, MESSAGE_CONTENT_TYPE_WINK];

export const NOTIFICATION_TYPE_MESSAGE = 'message';
export const NOTIFICATION_TYPE_LIKED = 'liked';
export const NOTIFICATION_TYPE_VIEWED = 'viewed';

export const NOTIFICATION_TYPES = [NOTIFICATION_TYPE_MESSAGE, NOTIFICATION_TYPE_LIKED, NOTIFICATION_TYPE_VIEWED];
