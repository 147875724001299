import React from 'react';
import styles from '../../styles.module.scss';
import moment from 'moment';
import * as modalsAction from '../../../../store/actions/modals';
import { useDispatch } from 'react-redux';

export default ({ moderationResponse }) => {
    const dispatch = useDispatch();

    const openImageModal = (link) => {
        dispatch(
            modalsAction.openPhotoContentModal({
                link,
                attachment_payed: '1',
            }),
        );
    };

    return (
        <>
            <div className={styles.mod_response}>
                <h3 className={styles.mod_response_title}>Moderation response:</h3>
                {moderationResponse.map((el, index) => (
                    <div className={styles.mod_response_inner} key={el?.id}>
                        <div className={styles.mod_response_data}>
                            <span>{+index + 1})&nbsp;</span>
                            <span>{moment(el?.createdAt).format('DD.MM.YYYY')}</span>
                            <span>{moment(el?.createdAt).format('HH:mm')}</span>
                        </div>
                        <p className={styles.mod_response_text}>{el?.message}</p>

                        {!!el?.files?.length &&
                            el?.files?.map((file) => (
                                <div className={styles.mod_response_img_wrap} key={file?.id}>
                                    <img src={file?.url} alt="Not loading" onClick={() => openImageModal(file?.url)} />
                                </div>
                            ))}
                    </div>
                ))}
            </div>
        </>
    );
};
