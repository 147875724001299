import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { switchSenderTypeToRequest } from '../../services/methods/sender';
import * as senderAction from '../../store/actions/sender';
import useHandleSenderHeader from './useHandleSenderHeader';

export default function useHandleSavedSenderList() {
    const dispatch = useDispatch();
    const senderState = useSelector((state) => state.sender);
    const { activeSenderType } = useHandleSenderHeader();

    useEffect(() => {
        if (senderState?.activeProfile && switchSenderTypeToRequest(senderState?.activeType)) {
            dispatch(
                senderAction.addActiveSenderProfileSendList(
                    senderState?.activeProfile,
                    switchSenderTypeToRequest(senderState?.activeType),
                ),
            );
        }
    }, [senderState?.activeProfile, activeSenderType]);

    return {
        savedSenderList: senderState.activeProfileSendList,
    };
}
