import { useRef, useState } from 'react';
import useOutsideClicker from '../../dom/useOutsideClicker';

export default function useHandleSmileBlock() {
    const smileBoxRef = useRef(null);
    const [isSmileBlock, setIsSmileBlock] = useState(false);

    const openSmileBlock = () => {
        setIsSmileBlock(true);
    };

    const closeSmileBlock = () => {
        setIsSmileBlock(false);
    };

    useOutsideClicker(smileBoxRef, setIsSmileBlock);

    return {
        isSmileBlock,
        openSmileBlock,
        closeSmileBlock,
        smileBoxRef,
    };
}
