// CHAT PAGES GY FILTER TYPE
export const CHAT_FILTERS_PAGE = ['chat', 'dialogues', 'unanswered', 'favorite', 'likes'];

//LETTER MENU
export const LETTER_FOLDER_INBOX = 'inbox';
export const LETTER_FOLDER_DRAFT = 'drafts';

//SIDE MENU FOLDERS
export const MENU_CHAT = 'chat';
export const MENU_UNANSWERED = 'unanswered';
export const MENU_FAVORITE = 'favorite';
export const MENU_DIALOGUES = 'dialogues';
export const MENU_ADD_YOU_TO_FAVORITE = 'add_you_to_fav';
export const MENU_LOOKING_AT_YOU = 'looking_at_you';
export const MENU_RECONNECTIONS = 'reconnections';
export const MENU_NEXT_TIME = 'next_time';
export const MENU_NEW_MAN = 'new_man';
export const MENU_MAYBE = 'maybe';
export const MENU_SPECIAL = 'special';
export const MENU_MATCHES = 'matches';
export const MENU_CHANCE = 'chance';
export const MENU_SEARCH = 'search';
export const MENU_PROFILE = 'operator-profiles';
export const MENU_STATISTIC = 'operator-statistic';
export const MENU_SENDER_STATISTIC = 'sender-statistic';
export const MENU_ONLINE_OFFLINE = 'online_offline';
export const MENU_BLOCK = 'block-list';
export const MENU_TUTORIAL = 'tutorial';
export const MENU_SIGN_OUT = 'sign_out';
export const MENU_LETTER = 'letter';
export const MENU_SENDER = 'sender';
export const MENU_GIFTS = 'operator-gifts';
export const MENU_PROFILE_MAN = 'profile-man';
export const MENU_PROFILE_WOMAN = 'profile-woman';
export const MENU_LOGIN = 'login';
export const MENU_MENU = 'menu';
export const MENU_PERSONAL = 'personal';
export const MENU_POSTS = 'posts';

export const MENU_LIST = [
    MENU_POSTS,
    MENU_CHAT,
    MENU_PERSONAL,
    MENU_UNANSWERED,
    MENU_MATCHES,
    MENU_FAVORITE,
    MENU_DIALOGUES,
    MENU_ADD_YOU_TO_FAVORITE,
    MENU_LOOKING_AT_YOU,
    MENU_RECONNECTIONS,
    MENU_NEXT_TIME,
    MENU_NEW_MAN,
    MENU_MAYBE,
    MENU_SPECIAL,
    MENU_SEARCH,
    MENU_PROFILE,
    MENU_STATISTIC,
    MENU_SENDER_STATISTIC,
    MENU_ONLINE_OFFLINE,
    MENU_BLOCK,
    MENU_TUTORIAL,
    MENU_SIGN_OUT,
    MENU_LETTER,
    MENU_SENDER,
    MENU_GIFTS,
    MENU_PROFILE_MAN,
    MENU_PROFILE_WOMAN,
    MENU_LOGIN,
    MENU_CHANCE,
    MENU_MENU,
];

export const MENU_CHAT_LIST = [
    MENU_CHAT,
    MENU_UNANSWERED,
    MENU_MATCHES,
    MENU_FAVORITE,
    MENU_DIALOGUES,
    MENU_ADD_YOU_TO_FAVORITE,
    MENU_LOOKING_AT_YOU,
    MENU_RECONNECTIONS,
    MENU_NEXT_TIME,
    MENU_NEW_MAN,
    MENU_MAYBE,
    MENU_SPECIAL,
    MENU_CHANCE,
];

//COUNTER
// export const MENU_CHAT = 'chat';
// export const MENU_LETTER = 'letter';
// export const MENU_SENDER = 'sender';
// export const MENU_SEARCH = 'search';
//
// export const MENU_DIALOGUES = 'dialogues';
// export const MENU_DIALOGUES_ONLINE = 'dialogues_online';
//
// export const MENU_UNANSWERED = 'unanswered';
// export const MENU_UNANSWERED_ONLINE = 'unanswered_online';

// export const MENU_FAVORITE = 'favorite';
// export const MENU_FAVORITE_ONLINE = 'favorite_online';

// export const MENU_LIKE = 'like';
// export const MENU_LIKE_ONLINE = 'like_online';

// export const MENU_MAYBE = 'maybe';
// export const MENU_MAYBE_ONLINE = 'maybe_online';

// export const MENU_SPECIAL = 'special';
// export const MENU_SPECIAL_ONLINE = 'special_online';

export const MENU_LOOKING = 'looking';
// export const MENU_LOOKING_ONLINE = 'looking_online';

export const MENU_CONNECTIONS = 'connections';
// export const MENU_CONNECTIONS_ONLINE = 'connections_online';

// export const MENU_BLOCK = 'block_list';

//GET CHAT LIST
export const GET_CHANCE = 'CHANCE';
export const GET_UNREAD = 'UNREAD';
export const GET_LIKE = 'LIKE';
export const GET_FAVORITE = 'FAVORITE';
export const GET_DIALOGUES = 'DIALOGUES';
export const GET_MAYBE = 'MAYBE';
export const GET_SPECIAL = 'SPECIAL';
export const GET_LOOKING_AT_YOU = 'LOOKING_AT_YOU';
export const GET_CONNECTION = 'CONNECTION';
export const GET_MATCHES = 'MATCH';
export const GET_LONG_CHATS = 'LONG';
export const GET_ALL = 'DEFAULT';

//------------------------------------------------
// ------------- WOMAN PROFILE -------------------
//------------------------------------------------
// ANCHOR WOMAN PROFILE
export const ANCHOR_PHOTO = 'photo';
export const ANCHOR_VIDEO = 'video';

// TABS WOMAN PROFILE
export const TAB_ABOUT = 'ABOUT';
export const TAB_BLOG = 'BLOG';

// FLAGS
export const OPEN_CHAT = 'open_chat';
