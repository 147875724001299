import * as BLOCK from '../constants/blockList';
import BlockApi from '../../services/api/BlockApi';
import moment from 'moment';

export function addBlockList({ maleBlock, filterSearch }) {
    return function (dispatch) {
        dispatch({
            type: BLOCK.LOADING_BLOCK_LIST,
        });
        new BlockApi().getBlockList(maleBlock).then((res) => {
            if (res?.blockList?.length) {
                return dispatch({
                    type: BLOCK.ADD_BLOCK_LIST,
                    list: res?.blockList.sort((a, b) => {
                        if (moment(a.last_message) > moment(b.last_message)) return -1;
                        if (moment(a.last_message) < moment(b.last_message)) return 1;
                        return 0;
                    }),
                    filterSearch,
                });
            } else {
                return dispatch({
                    type: BLOCK.ADD_BLOCK_LIST,
                    list: [],
                });
            }
        });
    };
}

export function setBlockListBySearch(filterSearch) {
    return function (dispatch) {
        return dispatch({
            type: BLOCK.BLOCK_LIST_FILTER_SEARCH,
            filterSearch,
        });
    };
}

export function unBlockUser(chatUID) {
    return function (dispatch) {
        return dispatch({
            type: BLOCK.UNBLOCK,
            chatUID,
        });
    };
}

export function changeTab(theyTab) {
    return function (dispatch) {
        return dispatch({
            type: BLOCK.CHANGE_TAB,
            theyTab,
        });
    };
}
