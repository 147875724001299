import React, { useEffect, useState } from 'react';
import Styles from '../styles.module.scss';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
// import makeStyles from '@mui/styles/makeStyles';
import TemplatesApi from '../../../../../services/api/v3/TemplatesApi';

// const useStyles = makeStyles({
// 	option: {
// 		"&:hover": {
// 			background: "linear-gradient(111.09deg,#357f89 4.33%,#1c4b72 98.64%) !important",
// 			color: "#fff"
// 		},
// 	},
// 	groupLabel: {
// 		fontSize: "18px",
// 		fontWeight: "bold",
// 		color: "#1c4b72",
// 	},
// });

export default ({ femaleExternalId, handleTemplateSelector, messageContent, templateContent }) => {
    // const styles = useStyles();

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const { response } = await new TemplatesApi().getWomanTemplates(femaleExternalId);

            if (active) {
                setOptions([...response]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="woman-templates"
            disabled={messageContent}
            value={templateContent}
            groupBy={(option) => option.category}
            options={options.sort((a, b) => a.category - b.category)}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.template === value.template}
            loading={loading}
            getOptionLabel={(option) => option.template}
            onChange={(e, option) => handleTemplateSelector(e, option)}
            // classes={{
            // 	option: styles.option,
            // 	groupLabel: styles.groupLabel
            // }}
            className={Styles.clmn_3_chat_select_input}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    label="Woman Templates"
                    variant="outlined"
                />
            )}
        />
    );
};
