import * as NOTICE from '../constants/notice';

const initState = {
    list: [],
    status: false,
};

export default function (state = initState, action) {
    switch (action.type) {
        case NOTICE.NOTICE_GET:
            return {
                ...state,
                ...{
                    list: action?.data?.notices,
                    status: action?.data?.status,
                },
            };

        case NOTICE.NOTICE_DELETE:
            return {
                ...state,
                ...{
                    list: state.list.filter((item) => item?.id !== action?.noticeID),
                },
            };

        case NOTICE.NOTICE_ADD:
            return {
                ...state,
                ...{
                    list: [...[action?.notice], ...state.list],
                },
            };

        case NOTICE.NOTICE_EDIT:
            return {
                ...state,
                ...{
                    list: [...[action?.notice], ...state.list.filter((item) => +item?.id !== +action?.notice?.id)],
                },
            };

        default:
            return state;
    }
}
