import React from 'react';
import { useDispatch } from 'react-redux';
import FilesApi from '../../../../../services/api/FilesApi';
import * as AWS from '../../../../../constants/aws';
import * as ATTACH from '../../../../../constants/attach';
import { makeCDN } from '../../../../../services/methods';
import * as modalsAction from '../../../../../store/actions/modals';

export default ({ checked, elementRef, data, opacity, userId }) => {
    const dispatch = useDispatch();

    const handleInputChangeImg = (e) => {
        const checkBox = e.currentTarget.querySelector('input');
        checkBox.checked = !checkBox.checked;
    };

    let thumbList = {};
    AWS.AWS_THUMBS.forEach((size) => {
        let thumb = data?.link.split('/');
        thumb[thumb.length - 1] = `w-${size.width}-h-${size.height}-${thumb[thumb.length - 1]}`;
        thumb = thumb.join('/');
        thumbList[size.name] = thumb;
    });

    const openContentModal = (e) => {
        dispatch(
            modalsAction.openPhotoContentModal({
                link: e.currentTarget?.dataset?.link,
                attachment_payed: '1',
            }),
        );
    };

    const handleSort = (e) => {
        const listItems = e.currentTarget.parentNode.querySelectorAll('.upload_popup_tabs_content_item');
        const sortedArr = [];

        for (let [key, item] of listItems.entries()) {
            if (item.dataset.id) {
                sortedArr.push({
                    id: item.dataset.id,
                    sort_order: key,
                });
            }
        }

        new FilesApi().postUpdateSortOrder(ATTACH.UPD_SORT_IMAGE, sortedArr, userId);
    };

    return (
        <div
            className="upload_popup_tabs_content_item"
            ref={elementRef}
            style={{ opacity }}
            data-id={data?.id}
            onDrop={handleSort}
        >
            <div className="no-drag upload_popup_tabs_content_item_top" onClick={handleInputChangeImg}>
                <input
                    className="no-drag"
                    type="checkbox"
                    name={data?.id}
                    checked={checked}
                    data-link={data?.link}
                    data-filename={data?.filename}
                    data-contentid={data.id}
                    onChange={handleInputChangeImg}
                />
                <label htmlFor="pp1" className="no-drag">
                    <img src={makeCDN(thumbList.small, 'IMAGE')} alt="" />
                </label>
            </div>
            <div className="upload_popup_tabs_content_item_bottom">
                <div className="popup_full_img" data-link={makeCDN(thumbList.big, 'IMAGE')} onClick={openContentModal}>
                    <img src="/img/photo-full.svg" alt="" />
                </div>
            </div>
        </div>
    );
};
