import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import LettersNewList from '../LettersNewList';
import LettersNewChat from '../LettersNewChat';
// import useLettersHelper from "../../../hooks/letters/useLettersHelper";

export default () => {
    // const {isCurrentLetterInfo} = useLettersHelper();
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    return (
        <div className={styles.clmn_3_letters} data-testid="letter-list">
            {currentChatMailboxState.info.id ? <LettersNewChat /> : <LettersNewList />}
        </div>
    );
};
