export default {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(85,85,85,0.75)',
        zIndex: '999',
    },
    content: {
        width: 'max-content',
        height: 'max-content',
        top: '50%',
        left: '50%',
        padding: 0,
        overflow: 'unset',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0px',
        border: 'none !important',
        background: 'transparent',
    },
};
