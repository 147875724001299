import React from 'react';

export default ({ onPressButton = null, titleButton = '', svgButton = null, dataTestId = '' }) => {
    return (
        <div className="c3_woman_profile_main_block_btn" data-testid={dataTestId} onClick={onPressButton}>
            {svgButton && <img src={svgButton} alt="" />}
            <span>{titleButton}</span>
        </div>
    );
};
