import { useEffect } from 'react';

//Hook that alerts clicks outside of the passed ref
export default function useOutsideClicker(ref, callback, exceptionRef = null) {
    useEffect(() => {
        // Alert if clicked on outside of element
        function handleClickOutside(event) {
            if (!ref?.current?.contains(event?.target)) {
                if (exceptionRef) {
                    if (!exceptionRef?.current?.contains(event?.target)) {
                        callback(false);
                    }
                } else {
                    callback(false);
                }
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback, exceptionRef]);
}
