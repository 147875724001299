import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as currentChatAction from '../../store/actions/currentChat';
import * as loaderAction from '../../store/actions/loader';
import * as chatsAction from '../../store/actions/chats';
import { initCancelTokenSource } from '../../services/methods';
import ChatsApi from '../../services/api/ChatsApi';
import ChatModel from '../../models/chat';
import useCloseCurrentChat from './useCloseCurrentChat';
import { sendErrToSentry } from '../../services/sentry';

export default function useOpenChatEffect(match) {
    const dispatch = useDispatch();
    const closeCurrentChat = useCloseCurrentChat();
    const chatsState = useSelector((state) => state.chats);

    const [canceledToken, setCanceledToken] = useState(null);

    const openChat = (chatInfo, token) => {
        if (chatInfo?.chat_uid) {
            dispatch(currentChatAction.addCurrentChat(chatInfo));
            dispatch(currentChatAction.addCurrentChatHistory(chatInfo?.chat_uid, token));
            dispatch(chatsAction.removeUnreadCount(chatInfo?.chat_uid));
        }
    };

    useEffect(() => {
        if (match?.params?.chat_uid) {
            const source = initCancelTokenSource(canceledToken, setCanceledToken);
            const existCurrentChat = chatsState?.list.find((item) => item?.chat_uid === match?.params?.chat_uid);
            const currentChatId = chatsState?.list.find((el) => el?.chat_uid === match?.params?.chat_uid)?.chat_id;

            dispatch(loaderAction.setActiveGifLoader(true));
            if (existCurrentChat?.chat_uid && existCurrentChat?.site_id) {
                openChat(existCurrentChat, source?.token);
                dispatch(loaderAction.setActiveGifLoader(false));
            } else {
                new ChatsApi().postEnableChat(match?.params?.chat_uid, currentChatId, source.token).then((res) => {
                    if (res?.status) {
                        try {
                            ChatModel.getStoreOneChatList(match?.params?.chat_uid)
                                .then((res) => {
                                    dispatch(chatsAction.addChat(res.chatList[0]));
                                    openChat(res.chatList[0], source?.token);
                                })
                                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
                        } catch (error) {
                            sendErrToSentry(error);
                        }
                    } else dispatch(loaderAction.setActiveGifLoader(false));
                });
            }

            return () => initCancelTokenSource(source, setCanceledToken);
        }
        if (!match?.params?.chat_uid) closeCurrentChat();
    }, [match.params.chat_uid, chatsState.status]);
}
