import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as menuAction from '../../store/actions/menu';
import * as MENU from '../../constants/menu';

export default function useTransition() {
    const dispatch = useDispatch();
    const history = useHistory();

    const getLocationName = (location) =>
        MENU.MENU_LIST.find((item) => location.toLowerCase().includes(item.toLowerCase())) ?? '';

    const setByLocationMenu = (locationName) => {
        dispatch(menuAction.setMainMenu(getLocationName(locationName)));
    };

    const transitionPush = (transitionUrl) => {
        setByLocationMenu(transitionUrl);
        history.push(transitionUrl);
    };

    return { transitionPush, setByLocationMenu };
}
