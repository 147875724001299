import React from 'react';
import styles from '../styles.module.scss';
import * as IMAGES from '../../common/Images';
import * as POSTS from '../../../constants/posts';

export default function PostsHeader({
    activeFilterStatus,
    onSetActiveFilterStatus,
    openCreateModal,
    isBlockPost,
    allCount,
    countStatuses,
}) {
    return (
        <div className={styles.posts_page_header}>
            <div className={styles.posts_page_header_row}>
                <div className={`${styles.posts_page_header_list} ${isBlockPost ? styles.disabled : ''}`}>
                    <div
                        className={`${styles.posts_page_header_item} ${activeFilterStatus === 0 ? styles.active : ''}`}
                        onClick={() => onSetActiveFilterStatus(0)}
                    >
                        All Posts {allCount ?? 0}
                    </div>
                    <div
                        className={`${styles.posts_page_header_item} ${activeFilterStatus === POSTS?.STATUS_PROCESS ? styles.active : ''}`}
                        onClick={() => onSetActiveFilterStatus(POSTS?.STATUS_PROCESS)}
                    >
                        In progress {countStatuses?.process ?? 0}
                    </div>
                    <div
                        className={`${styles.posts_page_header_item} ${activeFilterStatus === POSTS?.STATUS_APPROVE ? styles.active : ''}`}
                        onClick={() => onSetActiveFilterStatus(POSTS?.STATUS_APPROVE)}
                    >
                        Approved {countStatuses?.approve ?? 0}
                    </div>
                    <div
                        className={`${styles.posts_page_header_item} ${activeFilterStatus === POSTS?.STATUS_DECLINE ? styles.active : ''}`}
                        onClick={() => onSetActiveFilterStatus(POSTS?.STATUS_DECLINE)}
                    >
                        Declined {countStatuses?.decline ?? 0}
                    </div>
                    <div
                        className={`${styles.posts_page_header_item} ${activeFilterStatus === POSTS?.STATUS_REWORK ? styles.active : ''}`}
                        onClick={() => onSetActiveFilterStatus(POSTS?.STATUS_REWORK)}
                    >
                        Rework {countStatuses?.rework ?? 0}
                    </div>
                    <div
                        className={`${styles.posts_page_header_item} ${activeFilterStatus === POSTS?.STATUS_DRAFT ? styles.active : ''}`}
                        onClick={() => onSetActiveFilterStatus(POSTS?.STATUS_DRAFT)}
                    >
                        Draft {countStatuses?.draft ?? 0}
                    </div>
                    <div
                        className={`${styles.posts_page_header_item} ${activeFilterStatus === POSTS?.STATUS_HIDDEN ? styles.active : ''}`}
                        onClick={() => onSetActiveFilterStatus(POSTS?.STATUS_HIDDEN)}
                    >
                        Hidden {countStatuses?.hide ?? 0}
                    </div>
                </div>

                <button
                    className={`${styles.posts_page_create_btn} ${isBlockPost ? styles.disabled : ''}`}
                    onClick={() => !isBlockPost && openCreateModal('create')}
                >
                    <img src={IMAGES.add_box_white} alt="" />
                    Create new post
                </button>
            </div>
        </div>
    );
}
