export const IMG_BUCKET = 'chats-images.cdndate.net';
export const VIDEO_BUCKET = 'chats-videos';
export const AUDIO_BUCKET = 'chats-audios.cdndate.net';
export const POSTS_BUCKET = 's3-posts-files';
export const AWS_THUMBS_SMALL_SIZE_WIDTH = 250;
export const AWS_THUMBS_SMALL_SIZE_HEIGHT = 250;
export const AWS_THUMBS_BIG_SIZE_WIDTH = 1920;
export const AWS_THUMBS_BIG_SIZE_HEIGHT = 1280;
export const AWS_THUMBS_DEFAULT_TYPE = 'small';
export const AWS_POSTS_SMALL_SIZE_WIDTH = 420;
export const AWS_POSTS_SMALL_SIZE_HEIGHT = 420;
export const AWS_POSTS_BIG_SIZE_WIDTH = 1080;
export const AWS_POSTS_BIG_SIZE_HEIGHT = 1080;
export const AWS_THUMBS = [
    { name: 'small', width: AWS_THUMBS_SMALL_SIZE_WIDTH, height: AWS_THUMBS_SMALL_SIZE_HEIGHT },
    { name: 'big', width: AWS_THUMBS_BIG_SIZE_WIDTH, height: AWS_THUMBS_BIG_SIZE_HEIGHT },
];
export const AWS_POSTS_SIZE = [
    { name: 'small', width: AWS_POSTS_SMALL_SIZE_WIDTH, height: AWS_POSTS_SMALL_SIZE_HEIGHT },
    { name: 'big', width: AWS_POSTS_BIG_SIZE_WIDTH, height: AWS_POSTS_BIG_SIZE_HEIGHT },
];

export const AWS_CLOUDFRONT_IMAGE = {
    origin: 'chats-images.s3.us-east-2.amazonaws.com',
    cdn: 'd2aeesjlhbqzwc.cloudfront.net',
};
export const AWS_CLOUDFRONT_VIDEO = {
    origin: 'chats-videos.s3.us-east-2.amazonaws.com',
    cdn: 'd30l6m4x9yzl15.cloudfront.net',
};
export const AWS_CLOUDFRONT_AUDIO = {
    origin: 'chats-audios.s3.us-east-2.amazonaws.com',
    cdn: 'd305sqv25mhsh3.cloudfront.net',
};
export const AWS_CLOUDFRONT_POSTS = {
    origin: 's3-posts-files.s3.us-east-2.amazonaws.com',
    cdn: 'd1lv9y2b0i6pmo.cloudfront.net',
};
