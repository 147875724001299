import React from 'react';
import styles from '../styles.module.scss';
import * as IMAGES from '../../../../common/Images';
import { useSelector } from 'react-redux';
import useHandleSenderHeader from '../../../../../hooks/sender/useHandleSenderHeader';
import * as CHAT from '../../../../../constants/chat';
import ImageItem from './ImageItem';
import VideoItem from './VideoItem';
import AudioItem from './AudioItem';

export default function SenderDraftItem({ item, onSendSender, onEditSender, onDeleteSender, checkActiveSender }) {
    const { activeSenderType } = useHandleSenderHeader();

    const profilesState = useSelector((state) => state.profiles);
    const senderState = useSelector((state) => state.sender);
    const online = profilesState.list.filter((profile) => +profile?.external_id === +item?.profile_external_id)[0]
        .online;
    const textLines = item?.message_content?.split('\n') ?? [];

    const onSend = () => {
        if (checkActiveSender(item) && senderState?.activeProfile) return;
        senderState?.activeProfile &&
            senderState?.activeGroups?.length &&
            onSendSender(activeSenderType, item?.message_content, item?.attachments, item?.id);
    };

    const switchAttachmentType = (file, index) => {
        switch (file?.attachment_type) {
            case CHAT.MESSAGE_CONTENT_TYPE_IMAGE:
                return <ImageItem {...file} key={index} />;
            case CHAT.MESSAGE_CONTENT_TYPE_VIDEO:
                return <VideoItem {...file} key={index} />;
            case CHAT.MESSAGE_CONTENT_TYPE_AUDIO:
                return <AudioItem {...file} key={index} />;
            default:
                return '';
        }
    };

    return (
        <div
            className={`${styles.drafts_table_item} ${checkActiveSender(item) && senderState?.activeProfile && online ? styles.active : ''}`}
        >
            <div className={styles.drafts_table_item_top}>
                <div className={styles.drafts_table_item_text}>
                    {!!+textLines?.length &&
                        textLines.map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                </div>
                {/*<div className={styles.drafts_table_item_reciever}>*/}
                {/*	<div className={styles.drafts_table_item_reciever_item}>Reconnections</div>*/}
                {/*	<div className={styles.drafts_table_item_reciever_item}>Favorites</div>*/}
                {/*</div>*/}
                {/*<div className={styles.drafts_table_item_settings}>*/}
                {/*    <div className={styles.drafts_table_item_settings_no_spec}>No specific settings</div>*/}
                {/*</div>*/}
                <div className={styles.drafts_table_item_options}>
                    <div className={styles.drafts_table_item_option_edit} onClick={() => onEditSender(item?.id)}>
                        <img src={IMAGES.sender_drafts_edit} alt="" />
                    </div>
                    <div className={styles.drafts_table_item_option_delete} onClick={() => onDeleteSender(item?.id)}>
                        <img src={IMAGES.sender_drafts_delete} alt="" />
                    </div>
                </div>
            </div>
            <div className={styles.drafts_table_item_media_list_scroll}>
                <div className={styles.drafts_table_item_media_list}>
                    {!!item?.attachments?.length &&
                        item?.attachments?.map((file, index) => switchAttachmentType(file, index))}
                </div>
            </div>
            <div className={`${styles.drafts_table_item_bottom}`}>
                {/*<div className={styles.drafts_table_item_time}>1:32 pm</div>*/}
                <div
                    className={`${styles.drafts_table_item_send_btn} ${(checkActiveSender(item) && senderState?.activeProfile) || !online ? styles.not_active : ''}`}
                    onClick={onSend}
                >
                    <img src={IMAGES.sender_drafts_send} alt="" />
                    <span>Send</span>
                </div>
            </div>
        </div>
    );
}
