import React from 'react';

export default function LargeSelect({
    selectTitle,
    defaultName,
    arrayFields,
    openBox,
    formData,
    onOpenBlock,
    handleClick,
    index,
}) {
    return arrayFields && arrayFields.length > 0 ? (
        <div className="fill_data_form_select_item_wrap">
            <div className="fill_data_form_select_item_name">{selectTitle}</div>
            <div className="fill_data_form_select_item">
                <div className="cl2h_tab_wide_filter_item_wrap">
                    <div className={`cl2h_tab_wide_filter_item ${openBox ? 'open' : ''}`}>
                        <div className={`like_select_head ${formData ? 'selected' : ''}`} onClick={onOpenBlock}>
                            {formData ? formData : defaultName}
                        </div>
                        <div className={`like_select_list ${openBox ? 'open' : ''}`}>
                            {arrayFields.map((item, key) => (
                                <React.Fragment key={key}>
                                    <input
                                        type="radio"
                                        name={`like_sel-${key}`}
                                        id={`main-reg-${key}`}
                                        className="like_select"
                                    />
                                    <label
                                        htmlFor={`like_sel-${key}`}
                                        className="like_select_label"
                                        type="button"
                                        onClick={() => handleClick(item)}
                                    >
                                        {item[`${index}`] ? item[`${index}`] : ''}
                                    </label>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}
