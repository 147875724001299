import React from 'react';
import ProfileMan from '../../components/profile/ProfileMan';
import PropTypes from 'prop-types';

ManProfilePage.propTypes = {
    match: PropTypes.object,
};
export default function ManProfilePage({ match }) {
    return <ProfileMan external_id={match?.params?.external_id} />;
}
