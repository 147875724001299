import React from 'react';
import DnDContainer from '../DragAndDrop/Container';

export default ({ type, audios, isSortingDisabled }) => {
    return (
        <div className="upload_popup_tabs_content_middle audio" data-testid="file-list">
            <DnDContainer type={type} data={audios} isSortingDisabled={isSortingDisabled} />
        </div>
    );
};
