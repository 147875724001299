import React from 'react';
import styles from './styles.module.scss';
import { formatDate } from '../../../../../../services/time';
import { stringToColor, substrName } from '../../../../../../services/methods';

const VirtualGiftRow = ({ message, isRecipientSide = false, womanInfo, userInfo, setImageOnLoad }) => {
    return (
        <div
            className={`${styles.message_gift} ${isRecipientSide ? styles.recipient : ''}`}
            data-testid={isRecipientSide ? 'received-message' : 'sent-message'}
        >
            <div className={styles.message_gift_content} data-testid="message-content">
                <span className={styles.message_gift_name}>{message?.filename}</span>

                {isRecipientSide ? (
                    <div className={styles.message_gift_info}>
                        <div className={styles.message_gift_image_wrapper} data-testid="message-avatar">
                            {userInfo?.photo_link?.length ? (
                                <img src={userInfo?.photo_link} width="40" height="40" alt="" />
                            ) : (
                                <div style={{ backgroundColor: stringToColor(userInfo?.name) }}>
                                    {substrName(userInfo?.name)}
                                </div>
                            )}
                        </div>
                        <div className={styles.message_gift_image_wrapper} data-testid="message-avatar">
                            <img src={womanInfo?.photo_link} width="40" height="40" alt="" />
                        </div>
                        <span className={styles.message_gift_text}>
                            <span className={styles.message_gift_user_name}>{userInfo?.name}</span> sent a Virtual Gift
                            for you
                        </span>
                    </div>
                ) : (
                    <div className={styles.message_gift_info}>
                        <div className={styles.message_gift_image_wrapper} data-testid="message-avatar">
                            <img src={womanInfo?.photo_link} width="40" height="40" alt="" />
                        </div>
                        <div className={styles.message_gift_image_wrapper} data-testid="message-avatar">
                            {userInfo?.photo_link?.length ? (
                                <img src={userInfo?.photo_link} width="40" height="40" alt="" />
                            ) : (
                                <div style={{ backgroundColor: stringToColor(userInfo?.name) }}>
                                    {substrName(userInfo?.name)}
                                </div>
                            )}
                        </div>
                        <span className={styles.message_gift_text}>You sent a Virtual Gift for {userInfo?.name}</span>
                    </div>
                )}
            </div>

            <img
                className={`${styles.message_gift_image} ${isRecipientSide ? styles.recipient : ''}`}
                src={message?.message_content}
                width="389"
                height="389"
                alt={message?.filename ?? ''}
                onLoad={() => setImageOnLoad(true)}
                data-testid={message?.message_content}
            />

            <div className={`${styles.message_bottom} ${isRecipientSide ? styles.recipient : ''}`}>
                {message?.date_created && (
                    <div className={styles.message_date} data-testid="message-date">
                        {formatDate(message?.date_created)}
                    </div>
                )}
                {!!(message?.read_status !== undefined && !isRecipientSide) && (
                    <div
                        className={`${styles.message_status} ${+message?.read_status === 0 ? styles.send : styles.read}`}
                        data-testid="message-status"
                    />
                )}
            </div>
        </div>
    );
};

export default VirtualGiftRow;
