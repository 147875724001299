import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './styles.module.scss';
import * as IMAGES from '../../../common/Images';
import * as MENU from '../../../../constants/menu';
import * as CONFIG from '../../../../constants/config';
import useOutsideClicker from '../../../../hooks/dom/useOutsideClicker';
import * as menuActions from '../../../../store/actions/menu';
import ConfirmModal from '../../../common/modals/ConfirmModal';
import blackListIcon from '../../../../img/v2/c3ch-black-list.svg';
import blackListActiveIcon from '../../../../img/v2/с3ch-black-list-active.svg';
import { initCancelTokenSource } from '../../../../services/methods';
import * as blockListAction from '../../../../store/actions/blockList';
import * as currentChatActions from '../../../../store/actions/currentChat';
import * as currentChatMailboxActions from '../../../../store/actions/currentChatMailbox';
import ChatsApi from '../../../../services/api/ChatsApi';
import { useLocation } from 'react-router-dom';

export default function Header({
    currentChatInfo,
    womanProfile,
    setFavorite,
    setMaybe,
    setSpecial,
    isPin,
    openWomanProfile,
    openManProfile,
    openLetter,
    openChat,
    setPin,
    setBlock,
    headerType,
    complaintsArray,
    sendUserComplaint,
    configHeader,
}) {
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const currentChatState = useSelector((state) => state.currentChat);
    const currentMailChatState = useSelector((state) => state.currentChatMailbox);
    const dispatch = useDispatch();
    const location = useLocation();

    const [isOpenHeadNav, setIsOpenHeadNav] = useState(false);
    const [isOpenComplainNav, setIsOpenComplainNav] = useState(false);
    const [isOpenDuplicateNav, setIsOpenDuplicateNav] = useState(false);
    const [isMaleFavorite, setIsMaleFavorite] = useState(false);
    const [isConfirmBlockModalOpen, setIsConfirmBlockModalOpen] = useState(false);
    const [canceledToken, setCanceledToken] = useState(null);

    const btnComplainWrapRef = useRef(null);
    const mobNavBtnRef = useRef(null);
    const siteId =
        headerType === MENU.MENU_CHAT ? currentChatState?.info?.site_id : currentChatMailboxState?.info?.site_id;
    const isSocial = CONFIG.SHOW_SOCIAL.includes(+siteId);
    const isNoContacts = CONFIG.SHOW_NO_CONTACTS.includes(+siteId);
    const headerColorList = {
        0: '',
        1: Styles.clmn_3_chat_head_red,
        2: '',
        3: Styles.clmn_3_chat_head_green,
    };
    useOutsideClicker(btnComplainWrapRef, setIsOpenComplainNav);
    useOutsideClicker(mobNavBtnRef, setIsOpenHeadNav);

    const openHeadNav = () => {
        setIsOpenHeadNav(!isOpenHeadNav);
        setIsOpenComplainNav(false);
    };
    const openComplainNav = () => {
        setIsOpenHeadNav(false);
        setIsOpenComplainNav(!isOpenComplainNav);
    };

    const countryImg = 'https://alpha.date/assets' + currentChatInfo?.country_image?.split('/assets')[1];

    const blockUser = () => {
        setBlock();
        dispatch(currentChatActions.block());
    };

    const unblockUser = () => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);
        dispatch(blockListAction.unBlockUser(currentChatState?.info?.chat_uid));
        dispatch(currentChatMailboxActions.unblockUser());
        dispatch(currentChatActions.unBlock());
        if (location.pathname === '/letter') {
            new ChatsApi().postRemoveBlackList(
                currentMailChatState?.info?.male_external_id,
                currentMailChatState?.mailChatInfo?.chat_uid,
                source.token,
            );
        } else
            new ChatsApi().postRemoveBlackList(
                currentChatState?.info?.male_external_id,
                currentChatState?.info?.chat_uid,
                source.token,
            );
    };

    const complaintSwitcher = (complaint_type, index) => {
        switch (complaint_type) {
            case CONFIG.COMPLAINT_TYPE_1:
                return (
                    <div
                        title="Send a complaint"
                        className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.complaint_banana}`}
                        key={`${index}_${complaint_type}`}
                        onClick={() => sendUserComplaint(complaint_type)}
                    />
                );
            case CONFIG.COMPLAINT_TYPE_2:
                return (
                    <div
                        title="Send a complaint"
                        className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.complaint_country}`}
                        key={`${index}_${complaint_type}`}
                        onClick={() => sendUserComplaint(complaint_type)}
                    />
                );
            case CONFIG.COMPLAINT_TYPE_3:
                return (
                    <div
                        title="Send a complaint"
                        className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.complaint_contact}`}
                        key={`${index}_${complaint_type}`}
                        onClick={() => sendUserComplaint(complaint_type)}
                    />
                );
            case CONFIG.COMPLAINT_TYPE_4:
                return (
                    <div
                        title="Send a complaint"
                        className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.complaint_child}`}
                        key={`${index}_${complaint_type}`}
                        onClick={() => sendUserComplaint(complaint_type)}
                    />
                );

            default:
                return '';
        }
    };

    const labelSwitcher = () => {
        if (isSocial) {
            return <div className={`${Styles.clmn_3_chat_head_labal} ${Styles.social}`}>SOCIAL</div>;
        } else if (isNoContacts) {
            return <div className={`${Styles.clmn_3_chat_head_labal} ${Styles.no_contacts}`}>NO CONTACTS</div>;
        }

        return null;
    };

    const labelBlockSwitcher = () => {
        const className = `${Styles.clmn_3_chat_head_labal} ${Styles.no_contacts} ${isSocial || isNoContacts ? Styles.second : ''}`;
        let title = '';

        if (currentChatState?.info?.female_block || currentMailChatState?.info.female_block)
            title = 'YOU HAVE BEEN BLOCKED BY THE USER';
        else if (currentChatState?.info?.male_block || currentMailChatState?.info?.male_block)
            title = 'YOU BLOCKED USER';
        else if (
            currentChatState?.options?.disabledContentConfig?.needDisabled ||
            currentChatMailboxState?.disabledContentConfig?.needDisabled
        )
            title = `User temporary can't receive content: ${currentChatState?.options?.disabledContentConfig?.disabledContent?.audioInChat || currentChatMailboxState?.disabledContentConfig?.disabledContent?.audioInLetters ? 'audio' : ''} ${currentChatState?.options?.disabledContentConfig?.disabledContent?.photoInChat || currentChatMailboxState?.disabledContentConfig?.disabledContent?.photoInLetters ? 'photo' : ''} ${currentChatState?.options?.disabledContentConfig?.disabledContent?.videoInChat || currentChatMailboxState?.disabledContentConfig?.disabledContent?.videoInLetters ? 'video' : ''}`;

        return title ? <div className={className}>{title}</div> : null;
    };

    useEffect(() => {
        if (headerType === MENU.MENU_CHAT || headerType === MENU.MENU_CHANCE) {
            setIsMaleFavorite(!!+currentChatState?.info?.male_favorite);
        } else if (headerType === MENU.MENU_LETTER) {
            setIsMaleFavorite(!!+currentChatMailboxState?.mailChatInfo?.male_favorite);
        }
    }, [currentChatMailboxState.mailChatInfo.male_favorite, currentChatState.info.male_favorite, headerType]);

    return (
        <div className={`${Styles.clmn_3_chat_head} ${headerColorList[configHeader]}`} data-testid="chat-header">
            {labelSwitcher()}
            {labelBlockSwitcher()}
            <div className={`${Styles.clmn_3_chat_head_profile} ${Styles.left}`}>
                <div
                    onClick={openManProfile}
                    className={`${Styles.clmn_3_chat_head_profile_photo} ${currentChatInfo?.isUserOnline && Styles.online}`}
                    data-testid="man-photo"
                >
                    <img
                        alt=""
                        src={
                            currentChatInfo?.photo_link?.length ? currentChatInfo?.photo_link : IMAGES.profile_img_empty
                        }
                    />
                </div>
                <div className={Styles.clmn_3_chat_head_profile_info}>
                    <div className={Styles.clmn_3_chat_head_profile_name} data-testid="man-name">
                        {`${currentChatInfo?.name ?? ''}, ${currentChatInfo?.age ?? ''}`}
                        {/*{configData?.man_pay_mark && <span>h</span>}*/}
                    </div>
                    <div className={Styles.clmn_3_chat_head_profile_id} data-testid="man-external_id">
                        {`ID ${currentChatInfo?.male_external_id ?? ''}`}
                    </div>
                    <div className={Styles.clmn_3_chat_head_profile_place} data-testid="man-place">
                        <img src={countryImg ?? ''} alt="" />
                        <span>{currentChatInfo?.city ?? ''}</span>
                    </div>
                </div>
            </div>
            <div ref={mobNavBtnRef} className={Styles.clmn_3_chat_head_middle} data-testid="options-btn">
                <div title="Open options" className={Styles.clmn_3_chat_head_mob_nav_btn} onClick={openHeadNav}>
                    <img src={IMAGES.chat_head_more_btn} alt="" />
                </div>
                <div
                    className={`${Styles.clmn_3_chat_head_nav} ${isOpenHeadNav ? Styles.active : ''}`}
                    data-testid="options-block"
                >
                    {headerType === MENU.MENU_CHAT && (
                        <div
                            title="Pin chat"
                            className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.pin} ${isPin ? Styles.pinned : ''}`}
                            onClick={setPin}
                            data-testid="pin-chat-btn"
                        />
                    )}

                    {(headerType === MENU.MENU_CHAT ||
                        headerType === MENU.MENU_LETTER ||
                        headerType === MENU.MENU_CHANCE) && (
                        <div
                            title="Set/Unset favorite"
                            className={`${Styles.clmn_3_chat_head_nav_btn} ${isMaleFavorite ? Styles.favs2 : Styles.favs}`}
                            onClick={setFavorite}
                            data-testid="set-favorite-btn"
                        />
                    )}
                    {headerType === MENU.MENU_CHAT && (
                        <div
                            title="Send letter"
                            className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.letters}`}
                            onClick={() => {
                                dispatch(menuActions.setPreviousPage(location.pathname.split('/')[1]));
                                openLetter();
                            }}
                            data-testid="send-letter-btn"
                        />
                    )}
                    {headerType === MENU.MENU_LETTER && (
                        <div
                            title="Open chat"
                            className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.chat}`}
                            onClick={openChat}
                            data-testid="open-chat-btn"
                        />
                    )}
                    {headerType === MENU.MENU_CHAT && (
                        <div
                            title="Set maybe"
                            onClick={setMaybe}
                            className={`${Styles.clmn_3_chat_head_nav_btn} ${+currentChatInfo?.maybe ? Styles.folder2 : Styles.folder}`}
                            data-testid="set-maybe-btn"
                        />
                    )}
                    {headerType === MENU.MENU_CHAT && (
                        <div
                            title="Set special"
                            onClick={setSpecial}
                            className={`${Styles.clmn_3_chat_head_nav_btn} ${+currentChatInfo?.special ? Styles.double_folder2 : Styles.double_folder}`}
                            data-testid="set-special-btn"
                        />
                    )}
                    {(headerType === MENU.MENU_CHAT || headerType === MENU.MENU_LETTER) && (
                        <div
                            title={`${currentChatState?.info?.male_block || currentChatMailboxState?.info?.male_block ? 'Remove from' : 'Add to'} block list`}
                            style={{
                                backgroundImage: `${currentChatState?.info?.male_block || currentChatMailboxState?.info?.male_block ? `url(${blackListActiveIcon})` : `url(${blackListIcon})`}`,
                            }}
                            className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.black_list}`}
                            onClick={() => setIsConfirmBlockModalOpen(true)}
                            data-testid="add-block-list-btn"
                        />
                    )}

                    {(headerType === MENU.MENU_CHAT || headerType === MENU.MENU_LETTER) &&
                        !!complaintsArray?.length && (
                            <div className={Styles.clmn_3_chat_head_complain_mob}>
                                {!!complaintsArray?.length &&
                                    complaintsArray?.map((el, index) => complaintSwitcher(el, index))}
                            </div>
                        )}
                </div>
                {(headerType === MENU.MENU_CHAT || headerType === MENU.MENU_LETTER) && !!complaintsArray?.length && (
                    <div ref={btnComplainWrapRef} className={Styles.clmn_3_chat_head_nav_btn_wrap}>
                        <div
                            title="Complain"
                            className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.complain}`}
                            onClick={openComplainNav}
                            data-testid="complain-btn"
                        />
                        {isOpenComplainNav && (
                            <div className={Styles.clmn_3_chat_head_complain_nav}>
                                {(headerType === MENU.MENU_CHAT || headerType === MENU.MENU_LETTER) &&
                                    !!complaintsArray?.length &&
                                    !!complaintsArray?.length &&
                                    complaintsArray?.map((el, index) => complaintSwitcher(el, index))}
                            </div>
                        )}
                    </div>
                )}
                {!!currentChatState?.options?.duplicates?.length && (
                    <div className={Styles.clmn_3_chat_head_nav_btn_wrap}>
                        <div
                            title="Man duplicate accounts"
                            className={`${Styles.clmn_3_chat_head_nav_btn} ${Styles.duplicates}`}
                            onClick={() => {
                                setIsOpenDuplicateNav(!isOpenDuplicateNav);
                            }}
                        />
                        {isOpenDuplicateNav && (
                            <div className={`${Styles.clmn_3_chat_head_complain_nav} ${Styles.duplicates}`}>
                                {currentChatState?.options?.duplicates.map((maleId, key) => (
                                    <div key={key}>
                                        <a
                                            style={{ color: '#fff' }}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={`/profile-man/${maleId}`}
                                        >
                                            {maleId}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={`${Styles.clmn_3_chat_head_profile} ${Styles.right}`}>
                <div className={Styles.clmn_3_chat_head_profile_info}>
                    <div className={Styles.clmn_3_chat_head_profile_name} data-testid="woman-name">
                        {`${womanProfile?.name ?? ''}, ${womanProfile?.age ?? ''}`}
                    </div>
                    <div className={Styles.clmn_3_chat_head_profile_id} data-testid="woman-external_id">
                        {`ID ${womanProfile?.external_id ?? ''}`}
                    </div>
                    <div className={Styles.clmn_3_chat_head_profile_place} data-testid="woman-place">
                        <img src={womanProfile?.country_image ?? ''} alt="" />
                        <span>{womanProfile?.city ?? ''}</span>
                    </div>
                </div>
                <div
                    onClick={openWomanProfile}
                    className={`${Styles.clmn_3_chat_head_profile_photo} ${+womanProfile?.online ? Styles.online : ''}`}
                    data-testid="woman-photo"
                >
                    <img
                        src={womanProfile?.photo_link?.length ? womanProfile?.photo_link : IMAGES.profile_img_empty}
                        alt=""
                    />
                </div>
            </div>
            <ConfirmModal
                isModalOpen={isConfirmBlockModalOpen}
                onConfirm={
                    currentChatState?.info?.male_block || currentChatMailboxState?.info?.male_block
                        ? unblockUser
                        : blockUser
                }
                onClose={() => setIsConfirmBlockModalOpen(false)}
                title={`Are you sure you want to ${currentChatState?.info?.male_block || currentChatMailboxState?.info?.male_block ? 'unblock' : 'block'} this user?`}
            />
        </div>
    );
}
