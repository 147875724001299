import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkFile, checkFileAndShowAlert } from '../../services/methods';
import awsUploadFile from '../../controllers/aws/uploadFile';
import FilesAPI from '../../services/api/FilesApi';
import * as alertAction from '../../store/actions/alerts';
import VideoConverterApi from '../../services/api/v3/VideoConverter';
import * as loaderAction from '../../store/actions/loader';
import * as filesAction from '../../store/actions/files';
import * as AWS from '../../constants/aws';
import * as REGEXP from '../../constants/regExp';
import * as ERROR from '../../constants/error';
import * as ATTACH from '../../constants/attach';
import * as LENGTH from '../../constants/length';
import { sendErrToSentry } from '../../services/sentry';

export default function useHandleAttachModal({ setActiveTypeFile, userId }) {
    const dispatch = useDispatch();

    const filesState = useSelector((state) => state.files);

    const [modalIsOpenImg, setModalIsOpenImg] = useState(false);
    const [modalIsOpenVideo, setModalIsOpenVideo] = useState(false);
    const [modalIsOpenAudio, setModalIsOpenAudio] = useState(false);
    const [imgFile, setImgFile] = useState({});
    const [videoFile, setVideoFile] = useState({});
    const [audioFile, setAudioFile] = useState({});

    const openModalImg = () => setModalIsOpenImg(true);
    const closeModalImg = () => setModalIsOpenImg(false);

    const openModalVideo = () => setModalIsOpenVideo(true);
    const closeModalVideo = () => {
        setVideoFile({});
        setModalIsOpenVideo(false);
    };

    const openModalAudio = () => setModalIsOpenAudio(true);
    const closeModalAudio = () => {
        setAudioFile({});
        setModalIsOpenAudio(false);
    };

    const handleUploadImg = (files = []) => {
        if (!files?.length || !files) return dispatch(alertAction.setMessage('File must be image'));

        let fileList = [];

        for (let item of files) {
            fileList = [...fileList, ...[item]];
        }

        const filteredFileList = fileList
            ?.filter((item) => +item?.size <= LENGTH.MAX_IMG_SIZE)
            ?.filter((item) => checkFile(item, ATTACH.TYPE_IMAGE));
        if (+filteredFileList.length > 10)
            return dispatch(alertAction.setMessage('10 uploaded photos is the maximum number.', 'Warning'));

        if (fileList?.length) {
            fileList.forEach((item) => {
                if (!checkFile(item, ATTACH.TYPE_IMAGE) || +item?.size > LENGTH.MAX_IMG_SIZE) {
                    dispatch(
                        alertAction.setMessage(
                            'Image must be in png, jpg or jpeg format. Maximum image size - 5 MB.',
                            'Wrong file',
                        ),
                    );
                }
            });
        }

        openModalImg();
        setImgFile(fileList);
    };

    const handleUploadVideo = (files = []) => {
        if (!files?.length || !files) return dispatch(alertAction.setMessage('File must be video'));

        let fileList = [];

        for (let item of files) {
            fileList = [...fileList, ...[item]];
        }

        const filteredFileList = fileList
            ?.filter((item) => +item?.size <= LENGTH.MAX_VIDEO_SIZE)
            ?.filter((item) => checkFile(item, ATTACH.TYPE_VIDEO));
        if (+filteredFileList.length > 10)
            return dispatch(alertAction.setMessage('10 uploaded videos is the maximum number.', 'Warning'));

        if (fileList?.length) {
            fileList.forEach((item) => {
                if (!checkFile(item, ATTACH.TYPE_VIDEO) || +item?.size > LENGTH.MAX_VIDEO_SIZE) {
                    dispatch(
                        alertAction.setMessage(
                            'Video must be in mp4, mov or avi format. Maximum video size - 50 MB.',
                            'Wrong file',
                        ),
                    );
                }
            });
        }

        openModalVideo();
        setVideoFile(fileList);
    };

    const handleUploadAudio = (files = []) => {
        if (!files?.length || !files) return dispatch(alertAction.setMessage('File must be audio'));

        if (
            !checkFileAndShowAlert(files[0], ATTACH.TYPE_AUDIO, (message, title) => {
                dispatch(alertAction.setMessage(message, title));
            })
        )
            return;

        if (!checkFile(files[0], ATTACH.TYPE_AUDIO) || +files[0]?.size > LENGTH.MAX_AUDIO_SIZE) {
            return dispatch(
                alertAction.setMessage('Audio must be in mp3 or ogg format. Maximum audio size - 10 MB.', 'Wrong file'),
            );
        }

        // let fileList = [];
        //
        // for (let item of files) {
        // 	fileList = [...fileList, ...[item]]
        // }

        openModalAudio();
        setAudioFile(files[0]);
    };

    const handleDrop = (file) => {
        if (!file && !file[0] && !file[0]?.type) return dispatch(alertAction.setMessage('', ERROR.ERROR_REQUEST));

        switch (file[0]?.type?.split('/')[0]) {
            case ATTACH.TYPE_IMAGE:
                setActiveTypeFile(ATTACH.TAB_IMAGE);
                return handleUploadImg(file);
            case ATTACH.TYPE_VIDEO:
                setActiveTypeFile(ATTACH.TAB_VIDEO);
                return handleUploadVideo(file);
            case ATTACH.TYPE_AUDIO:
                setActiveTypeFile(ATTACH.TAB_AUDIO);
                return handleUploadAudio(file);
            default:
                return '';
        }
    };

    const uploadImg = async ({ file, fileName }) => {
        fileName = fileName?.replaceAll(REGEXP.IMAGE_FILENAME, 'a')?.replace(/\.[^.]+$/, '');
        try {
            await awsUploadFile(file, fileName, userId, AWS.IMG_BUCKET).then((res) => {
                if (res?.success) {
                    return new FilesAPI().postImage({
                        external_id: userId,
                        filename: res?.data?.filename,
                        link: res?.data?.link,
                    });
                } else {
                    if (res?.message || res?.error) {
                        dispatch(alertAction.setMessage('', res?.message || res?.error));
                    } else {
                        dispatch(alertAction.setMessage('', ERROR.ERROR_REQUEST));
                    }
                }
            });
        } catch (error) {
            sendErrToSentry(error);
        }
    };

    const uploadVideo = async ({ file, fileName }) => {
        fileName = fileName?.replaceAll(REGEXP.IMAGE_FILENAME, 'a')?.replace(/\.[^.]+$/, '');
        try {
            await awsUploadFile(file, fileName, userId, AWS.VIDEO_BUCKET).then((res) => {
                if (res?.success) {
                    return new FilesAPI()
                        .postVideo({
                            external_id: userId,
                            filename: res?.data?.filename,
                            link: res?.data?.link,
                        })
                        .then((resVideo) => {
                            if (resVideo?.status)
                                return new VideoConverterApi().postVideoLinkToConvert(res?.data?.link);
                        });
                } else {
                    if (res?.message || res?.error) {
                        dispatch(alertAction.setMessage('', res?.message || res?.error));
                    } else {
                        dispatch(alertAction.setMessage('', ERROR.ERROR_REQUEST));
                    }
                }
            });
        } catch (error) {
            sendErrToSentry(error);
        }
    };

    const uploadAudio = async ({ file, fileName }) => {
        fileName = fileName?.replaceAll(REGEXP.IMAGE_FILENAME, 'a')?.replace(/\.[^.]+$/, '');
        try {
            dispatch(loaderAction.setActiveGifLoader(true));
            await awsUploadFile(file, fileName, userId, AWS.AUDIO_BUCKET).then((res) => {
                if (res?.message || res?.error) {
                    dispatch(alertAction.setMessage('', res?.message || res?.error));
                }

                return new FilesAPI().postAudio({
                    external_id: userId,
                    filename: res?.data?.filename,
                    link: res?.data?.link,
                });
            });
            dispatch(filesAction.getAllAudios(userId));
            dispatch(loaderAction.setActiveGifLoader(false));
        } catch (error) {
            sendErrToSentry(error);
        }
    };

    return {
        filesState,
        imgFile,
        videoFile,
        audioFile,
        setImgFile,
        setVideoFile,
        setAudioFile,
        modalIsOpenImg,
        modalIsOpenVideo,
        modalIsOpenAudio,
        openModalImg,
        openModalVideo,
        openModalAudio,
        closeModalImg,
        closeModalVideo,
        closeModalAudio,
        handleUploadImg,
        handleUploadVideo,
        handleUploadAudio,
        handleDrop,
        uploadImg,
        uploadVideo,
        uploadAudio,
    };
}
