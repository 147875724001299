import moment from 'moment';

export function getDateListInRange(dateStart, dateEnd) {
    let dateStartFormatted = moment(dateStart).format('YYYY-MM-DD');
    const dateEndFormatted = moment(dateEnd).format('YYYY-MM-DD');
    const result = [dateStartFormatted];

    while (moment(dateStartFormatted).diff(moment(dateEndFormatted), 'days') < 0) {
        dateStartFormatted = moment(dateStartFormatted).add(1, 'day').format('YYYY-MM-DD');
        result.push(dateStartFormatted);
    }

    return result;
}

export function diffWithCurrentTimeInSecondsWithoutUTC(date) {
    const firstDate = new Date();
    const secondDate = new Date(date);

    return secondDate - firstDate >= 0;
}

export function diffWithCurrentTimeInSeconds(date) {
    const firstDate = new Date();
    const secondDate = parseUTC(date);

    return secondDate - firstDate >= 0;
}

export function getLastSeen(list) {
    const historyList = [...list];
    const resultMessDate = historyList.reverse().find((item) => item?.is_male || item?.read_status);
    if (!parseUTC(resultMessDate?.date_created) || !resultMessDate?.date_created) return '';

    const fromNow = moment(moment(parseUTC(resultMessDate?.date_created)).format('YYYY/MM/DD-HH:mm')).fromNow();
    return {
        message: switchLastOnlineMessage(fromNow, 'Seen'),
        status: switchLastOnlineStatus(switchLastOnlineMessage(fromNow, 'Seen ')),
    };
}

export function nowDateUTCFormat() {
    return moment.utc().format('YYYY-MM-DD HH:mm:ss');
}

// Parse YYYY-MM-DDTHH:mm:ss as UTC
export function parseUTC(s) {
    try {
        if (typeof s === 'object') s = moment(s).format('YYYY-MM-DD HH:mm:ss');
        if (s?.includes('Z') || s?.includes('T')) s = moment(s)?.utc()?.format('YYYY-MM-DD HH:mm:ss');

        const b = s?.split(/\D/);
        return b.length ? new Date(Date.UTC(+b[0], --b[1], +b[2], +b[3], +b[4], +b[5])) : '';
    } catch (e) {
        return new Date();
    }
}

export function formatDate(date) {
    if (!date) return;
    if (!parseUTC(date)) return;

    return moment().diff(moment(parseUTC(date).toString()).format(), 'days') > 0
        ? moment(parseUTC(date).toString()).locale('en').format('DD/MM (LT)').toLowerCase()
        : moment(parseUTC(date).toString()).locale('en').format('LT').toLowerCase();
}

export function formatDateOnlineNotify(date) {
    if (!date) return;

    return moment().diff(moment(date).format(), 'days') > 0
        ? moment(date).locale('en').format('DD/MM').toLowerCase()
        : moment(date).locale('en').format('LT').toLowerCase();
}

export function daysDiffBetweenDates(date1, date2) {
    const diffDays = moment(date2).diff(moment(date1), 'days');
    const diffHour = moment(date2).diff(moment(date1), 'hour');

    if (+diffDays < 0) return ` (${365 - Math.abs(diffDays)} days till birthday)`;
    if (+diffDays === 0) return diffHour > 0 ? ` (${diffHour} hour till birthday)` : ` Happy BirthDay!`;

    return ` (${diffDays} days till birthday)`;
}

export function tillBirth(dataBirth) {
    let date_of_birth = new Date(dataBirth);
    let current_date = new Date();

    date_of_birth.setFullYear(current_date.getFullYear());
    return daysDiffBetweenDates(current_date, date_of_birth);
}

export function getDataProfile(date) {
    return moment(new Date(date)).format('MMMM D, YYYY');
}

export function getLastOnlineTime(lastOnline) {
    return moment.unix(lastOnline);
}

export function getLastOnlineMessage(lastOnline) {
    if (getLastOnlineTime(lastOnline) - moment() >= 0) return null;
    const fromNow = moment(getLastOnlineTime(lastOnline).format('YYYY/MM/DD HH:mm')).fromNow();
    return {
        message: switchLastOnlineMessage(fromNow),
        status: switchLastOnlineStatus(switchLastOnlineMessage(fromNow)),
    };
}

export function switchLastOnlineStatus(date) {
    if (date.includes('week')) {
        return 'week';
    } else if (date.includes('month') || date.includes('year')) {
        return 'month';
    } else {
        return 'green';
    }
}

export function switchLastOnlineMessage(data, text = '') {
    if (data.includes('days')) {
        const day = data.split(/\D/)[0];
        if (Math.floor(+day / 7) === 1) {
            return `${text} a week ago`;
        } else if (Math.floor(+day / 7) >= 2) {
            return `${text} ${Math.floor(+day / 7)}weeks ago`;
        } else {
            return `${text} ${data}`;
        }
    }
    return `${text} ${data}`;
}

export function formatDateMedia(date) {
    if (!date) return;
    if (!parseUTC(date)) return;

    return moment(parseUTC(date).toString()).locale('en').format('MMMM D, YYYY');
}

export function diffDaysByUnix(unix) {
    return moment.unix(unix).diff(moment(new Date()), 'minutes');
}
