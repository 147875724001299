import axios from 'axios';
import * as SYNC from '../../constants/sync';
import * as ERROR from '../../constants/error';
import { v4 as uuidv4 } from 'uuid';
import { sendErrToSentry } from '../sentry';

const RESEND_COUNT = 3;

export default class Base {
    constructor() {
        const BASE_URL = '/';
        this.url = BASE_URL + 'api';
    }

    setSyncProvider(syncProvider) {
        localStorage.setItem(SYNC.PROVIDER_STORAGE_KEY, syncProvider);
    }

    getUrl(path) {
        return `${this.url}/${path}`;
    }

    getTimeout(startTime) {
        try {
            return Math.round((new Date().getTime() - startTime) / 1000) + ' sec';
        } catch (err) {
            return null;
        }
    }

    resendRequest(status, callback, resend) {
        if (+status === 502 && resend > 0) {
            return callback(resend - 1);
        }
    }

    async botRequest(status, message, headers, headersReq) {
        if (!window.origin?.includes('stage') && +status >= 500 && +status < 600 && +status !== 501) {
            const traceData = await axios
                .get(`${window.location.origin}/cdn-cgi/trace`)
                .then((res) => res?.data)
                .catch(() => null);

            const cfRay = headers?.['cf-ray'] || '';
            if (!cfRay) return;

            const externalID = localStorage.getItem('external_id');
            const xRequestID = headersReq?.headers?.['X-Request-ID'] || '';
            const traceMessage = traceData ? traceData?.toString()?.replaceAll('=', ': ') : '';
            const messageData = `${message}\nExternalID: ${externalID}\n\nX-Request-ID: ${xRequestID}\nCf-Ray: ${cfRay}\n${traceMessage}`;

            const extraData = {
                externalID,
                xRequestID,
                cfRay,
                traceMessage,
            };
            sendErrToSentry(new Error(message), status, extraData);

            await axios
                .post(`https://www.sofiadate.com/api/sent/500x_error`, { message: messageData })
                .catch(() => null);
        }
    }

    get(url, config, resend = RESEND_COUNT) {
        const isCheckProfiles = localStorage.getItem('check_profiles');
        const isAdmin = JSON.parse(localStorage.getItem('admin'));
        if (isCheckProfiles && url !== 'operator/profiles') return new Promise((resolve) => resolve({ data: null }));

        const headers = this.getAuthHeader();

        return axios
            .get(this.getUrl(url), { ...headers, ...config, startTime: new Date().getTime() })
            .then((res) => {
                this.resendRequest(+res?.status, (resend) => this.get(url, config, resend), resend);
                this.setSyncProvider(res.headers['socket-provider']);

                const message = `Endpoint: ${this.getUrl(url)},\nResponse: ${res?.status},\nMethod: 'GET',\nTimeout: ${this.getTimeout(res?.config?.startTime)},\n`;
                this.botRequest(+res?.status, message, res?.headers, headers);

                return res;
            })
            .catch((error) => {
                try {
                    const message = `Endpoint: ${this.getUrl(url)},\nResponse: ${error},\nMethod: 'GET',\nTimeout: ${this.getTimeout(error?.response?.config?.startTime)},\n`;
                    this.botRequest(+error?.response?.status, message, error?.response?.headers, headers);
                } catch (e) {
                    /* empty */
                }
                this.resendRequest(+error?.response?.status, (resend) => this.get(url, config, resend), resend);
                if (axios?.isCancel(error)) return;

                if (error.response.status === ERROR.UNAUTHORIZED_STATUS && !isAdmin) {
                    localStorage.removeItem('operator');
                    localStorage.removeItem('token');
                    return (window.location.href = '/');
                }
            });
    }

    post(url, data, config = {}, resend = RESEND_COUNT) {
        const isCheckProfiles = localStorage.getItem('check_profiles');
        const isAdmin = JSON.parse(localStorage.getItem('admin'));
        if (isCheckProfiles) return new Promise((resolve) => resolve({ data: null }));

        const headers = this.getAuthHeader();

        return axios
            .post(this.getUrl(url), data, {
                ...headers,
                ...config,
                startTime: new Date().getTime(),
            })
            .then((res) => {
                this.resendRequest(+res?.status, (resend) => this.post(url, data, config, resend), resend);
                this.setSyncProvider(res.headers['socket-provider']);

                const message = `Endpoint: ${this.getUrl(url)},\nResponse: ${res?.status},\nMethod: 'GET',\nTimeout: ${this.getTimeout(res?.config?.startTime)},\n`;
                this.botRequest(+res?.status, message, res?.headers, headers);

                return res;
            })
            .catch((error) => {
                try {
                    const message = `Endpoint: ${this.getUrl(url)},\nResponse: ${error},\nMethod: 'GET',\nTimeout: ${this.getTimeout(error?.response?.config?.startTime)},\n`;
                    this.botRequest(+error?.response?.status, message, error?.response?.headers, headers);
                } catch (e) {
                    /* empty */
                }
                this.resendRequest(+error?.response?.status, (resend) => this.post(url, data, config, resend), resend);
                if (axios?.isCancel(error)) return;

                if (error.response.status === ERROR.UNAUTHORIZED_STATUS && !isAdmin) {
                    localStorage.removeItem('operator');
                    localStorage.removeItem('token');
                    return (window.location.href = '/');
                }

                return error?.response || null;
            });
    }

    postWithoutToken(url, data) {
        return axios
            .post(this.getUrl(url), data, {
                crossDomain: true,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                    'Access-Control-Allow-Headers': '"Origin, X-Requested-With, Content-Type, Accept"',
                },
            })
            .then((res) => res);
    }

    getXRequestID() {
        let uuid;

        try {
            uuid = uuidv4() || '';
        } catch (err) {
            uuid = '';
        }

        return '-' + uuid;
    }

    getAuthHeader() {
        return {
            crossDomain: true,
            headers: {
                Authorization: `Bearer ${this.getUserInfo()}`,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                'Access-Control-Allow-Headers': '"Origin, X-Requested-With, Content-Type, Accept"',
                'X-Request-ID': `${this.getXRequestID()}`,
            },
        };
    }

    getUserInfo() {
        return localStorage.getItem('token');
    }

    getThirdParty(url, header) {
        return axios.get(url, header).then((res) => res?.data);
    }

    postThirdParty(url, data, header) {
        return axios.post(url, data, header).then((res) => res?.data);
    }
}
