export const TYPE_WOMAN = 'Woman';
export const TYPE_MAN = 'Man';

export const TAB_ALL_MEDIA = 'All media';
export const TAB_IMAGE = 'Photo';
export const TAB_VIDEO = 'Video';
export const TAB_AUDIO = 'Audio';

export const MEDIA_LIST_QUANTITY = 40;
export const VIDEO_TITLE_LENGTH = 15;
export const AUDIO_TITLE_LENGTH = 25;
