import React from 'react';

import Styles from './styles.module.scss';

export default ({ item, deleteNotice, setIsEdit }) => {
    return (
        <div className={Styles.item}>
            <div className={Styles.head}>
                <div className={Styles.title}>{item?.name ?? ''}</div>
                <div className={Styles.buttons}>
                    <div className={`${Styles.button} ${Styles.edit}`} onClick={() => setIsEdit(item?.id)} />
                    <div className={`${Styles.button} ${Styles.delete}`} onClick={() => deleteNotice(item?.id)} />
                </div>
            </div>
            <div className={Styles.content}>{item?.description ?? ''}</div>
        </div>
    );
};
