export const GET_PAID_NOTIFY = 'GET_PAID_NOTIFY';

export const CHECK_FREE_NOTIFY = 'CHECK_FREE_NOTIFY';
export const CHECK_PAID_NOTIFY_IN_LOCAL_STORAGE = 'CHECK_PAID_NOTIFY_IN_LOCAL_STORAGE';
export const CHECK_EXP_DATE = 'CHECK_EXP_DATE';

export const OPEN_MOB_NOTIFY = 'OPEN_MOB_NOTIFY';
export const CLOSE_MOB_NOTIFY = 'CLOSE_MOB_NOTIFY';

export const ADD_FREE_NOTIFY = 'ADD_FREE_NOTIFY';
export const ADD_PAID_NOTIFY = 'ADD_PAID_NOTIFY';

export const REMOVE_FREE_NOTIFY_BY_ID = 'REMOVE_FREE_NOTIFY_BY_ID';
export const REMOVE_PAID_NOTIFY_BY_ID = 'REMOVE_PAID_NOTIFY_BY_ID';
export const REMOVE_PAID_NOTIFY_BY_CHATUID = 'REMOVE_PAID_NOTIFY_BY_CHATUID';
export const REMOVE_LETTER_NOTIFY_BY_WOMANID_AND_MANID = 'REMOVE_LETTER_NOTIFY_BY_WOMANID_AND_MANID';

export const REMOVE_ALL_FREE_NOTIFY = 'REMOVE_ALL_FREE_NOTIFY';
export const REMOVE_ALL_PAID_NOTIFY = 'REMOVE_ALL_PAID_NOTIFY';

export const UPDATE_FREE_ONLINE_NOTIFY = 'UPDATE_FREE_ONLINE_NOTIFY';
