import React from 'react';
import '../../../../../styles/error-mess/style.scss';
import * as ERROR from '../../../../../constants/error';
import * as ALERTS from '../../../../../store/constants/alerts';
import * as IMAGES from '../../../Images';

export default ({
    type,
    closeMessModal,
    title = '',
    text = '',
    btnName1 = '',
    btnName2 = '',
    successCallback = {},
}) => {
    const fixMessageContent = (message) => message.split('.').join(`.<br/>`);

    return (
        <div className="popup_error_wrap" data-testid="alertMain-modal">
            <div className="popup_error">
                {(() => {
                    switch (type) {
                        case ALERTS.SET_MESSAGE:
                            return (
                                <>
                                    {title?.length ? (
                                        <>
                                            <div className="popup_error_title">{title}</div>
                                            <div className="popup_error_text">{text}</div>
                                        </>
                                    ) : (
                                        <div
                                            className="popup_error_title"
                                            dangerouslySetInnerHTML={{ __html: fixMessageContent(text) }}
                                        />
                                    )}
                                    <div className="popup_error_btn" onClick={closeMessModal}>
                                        <img src={IMAGES.check_circle_white} alt="" />
                                        <span>Got it</span>
                                    </div>
                                </>
                            );
                        case ERROR.MAIL_SENT_SUCCESSFULLY:
                            return (
                                <>
                                    <div className="popup_error_title">Mail was sent!</div>
                                    <div className="popup_error_text">Your Mail was sent successfully!</div>
                                    <div className="popup_error_btn" onClick={closeMessModal}>
                                        <img src={IMAGES.check_circle_white} alt="" />
                                        <span>Got it</span>
                                    </div>
                                </>
                            );
                        case ERROR.NOT_EXIST_OR_BLOCK:
                            return (
                                <>
                                    <div className="popup_error_title">Oops, en error occurred?</div>
                                    <div className="popup_error_text">
                                        The chat does not exist or the user is blocked.
                                    </div>
                                    <div className="popup_error_btn" onClick={closeMessModal}>
                                        <img src={IMAGES.check_circle_white} alt="" />
                                        <span>Got it</span>
                                    </div>
                                </>
                            );
                        case ERROR.FILES_LIMIT:
                            return (
                                <>
                                    <div className="popup_error_title">Attached files limit is exceeded</div>
                                    <div className="popup_error_text">
                                        You can attach maximum 5 files <br />
                                        to one letter
                                    </div>
                                    <div className="popup_error_btn" onClick={closeMessModal}>
                                        <img src={IMAGES.check_circle_white} alt="" />
                                        <span>Got it</span>
                                    </div>
                                </>
                            );
                        case ERROR.LETTER_LENGTH:
                            return (
                                <>
                                    <div className="popup_error_title">Some limits for letter length</div>
                                    <div className="popup_error_text">
                                        To send a letter, please enter <br />
                                        from 500 up to 1500 characters
                                    </div>
                                    <div className="popup_error_btn" onClick={closeMessModal}>
                                        <img src={IMAGES.check_circle_white} alt="" />
                                        <span>Got it</span>
                                    </div>
                                </>
                            );
                        case ERROR.ENOUGH_CREDITS:
                            return (
                                <>
                                    <div className="popup_error_title">
                                        You don’t have enough credits <br />
                                        to view this content
                                    </div>
                                    <div className="popup_error_text" />
                                    <div className="popup_error_btn" onClick={closeMessModal}>
                                        <img src={IMAGES.check_circle_white} alt="" />
                                        <span>Got it</span>
                                    </div>
                                </>
                            );
                        case ERROR.INTERNAL_ERROR_OCCURRED:
                            return (
                                <>
                                    <div className="popup_error_title">{type}</div>
                                    <div className="popup_error_text" />
                                    <div className="popup_error_btn" onClick={closeMessModal}>
                                        <img src={IMAGES.check_circle_white} alt="" />
                                        <span>Got it</span>
                                    </div>
                                </>
                            );
                        case ALERTS.SET_CUSTOM_CONFIRM_ALERT:
                            return (
                                <>
                                    {!!title?.length && <div className="popup_error_title">{title}</div>}
                                    {!!text?.length && <div className="popup_error_text">{text}</div>}
                                    <div className="popup_error_btn_wrap">
                                        {!!btnName1?.length && (
                                            <div
                                                className="popup_error_btn transparent"
                                                data-testid="no-btn"
                                                onClick={closeMessModal}
                                            >
                                                <span>{btnName1}</span>
                                            </div>
                                        )}
                                        {!!btnName2?.length && (
                                            <div
                                                className="popup_error_btn"
                                                data-testid="yes-btn"
                                                onClick={() => {
                                                    typeof successCallback === 'function' && successCallback();
                                                    closeMessModal();
                                                }}
                                            >
                                                <img src={IMAGES.check_circle_white} alt="" />
                                                <span>{btnName2}</span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            );

                        default:
                            return <div />;
                    }
                })()}
            </div>
            <div className="popup_error_close" data-testid="close-btn" onClick={closeMessModal}>
                <img src={IMAGES.popup_gift_close} alt="" />
            </div>
        </div>
    );
};
