export const STATUS_PROCESS = 1;
export const STATUS_APPROVE = 2;
export const STATUS_DECLINE = 3;
export const STATUS_REWORK = 4;
export const STATUS_DRAFT = 5;
export const STATUS_HIDDEN = 6;

export const EDIT_POST_TYPE = [STATUS_REWORK, STATUS_DRAFT];

export const POST_TEXT_MAX_LENGTH = 2500;
export const COMMENT_MAX_LENGTH = 500;
export const FILES_MAX_LENGTH = 5;

export const POST_COUNT = 12;
export const COMMENT_COUNT = 12;

export const IMAGE_POST_NAME = 'image_post.jpeg';
