import React, { useState } from 'react';
import '../../../../styles/popup-photo-gallery/style.scss';
import * as IMAGE from '../../Images';

export default ({ gallery, photo_link, currentPhoto = 0, closeGalleryModal }) => {
    const [selectedImage, changeSelectedImage] = useState(currentPhoto);

    const changeImage = (e, index) => {
        e.preventDefault();
        gallery[index] && changeSelectedImage(index);
    };

    const nextImage = (e) => {
        e.preventDefault();
        changeSelectedImage(selectedImage + 1 > gallery.length - 1 ? 0 : selectedImage + 1);
    };

    const prevImage = (e) => {
        e.preventDefault();
        changeSelectedImage(selectedImage - 1 < 0 ? gallery.length - 1 : selectedImage - 1);
    };

    return (
        gallery.length > 0 && (
            <div className="popup_likeher_search_photos">
                <div className="popup_likeher_search_photos_content">
                    <div className={`popup_likeher_search_photos_big_photo`}>
                        {gallery[selectedImage] && <img src={gallery[selectedImage]} alt="" />}
                        {!gallery[selectedImage] && <img src={photo_link} alt="" />}
                    </div>
                    <div className="popup_likeher_search_photos_list_wrap">
                        <div className="popup_likeher_search_photos_list">
                            {gallery.map((item, index) => (
                                <div
                                    key={index}
                                    className={`popup_likeher_search_photos_list_item`}
                                    onClick={(e) => changeImage(e, index)}
                                >
                                    <img src={item} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="popup_likeher_search_photos_prev" onClick={prevImage}>
                    <img src={IMAGE.slider_prev} alt="" />
                </div>
                <div className="popup_likeher_search_photos_next" onClick={nextImage}>
                    <img src={IMAGE.slider_next} alt="" />
                </div>
                <div className="popup_likeher_search_photos_close" onClick={closeGalleryModal}>
                    <img src={IMAGE.cross_white} alt="" />
                </div>
            </div>
        )
    );
};
