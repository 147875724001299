import * as POSTS from '../constants/posts';

export function getAllPosts(data) {
    return function (dispatch) {
        return dispatch({
            type: POSTS.GET_ALL_POSTS,
            data,
        });
    };
}

export function updateCurrentPost(data) {
    return function (dispatch) {
        return dispatch({
            type: POSTS.UPDATE_CURRENT_POST,
            data,
        });
    };
}

export function clearPosts() {
    return function (dispatch) {
        return dispatch({
            type: POSTS.CLEAR_POSTS,
        });
    };
}
