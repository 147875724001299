import React, { useRef } from 'react';
import Styles from '../styles.module.scss';
import * as IMAGES from '../../../../../common/Images';
import useOutsideClicker from '../../../../../../hooks/dom/useOutsideClicker';

export default ({
    innerStickerList,
    innerStickerTitle,
    isStickerListOpen,
    closeInnerStickerList,
    openStickerBlock,
    onSelectSticker,
}) => {
    const stickersListOpenRef = useRef();
    useOutsideClicker(stickersListOpenRef, closeInnerStickerList);

    const innerStickerListMap =
        !!innerStickerList?.length &&
        innerStickerList?.map((sticker, index) => (
            <div
                className={Styles.c3_stickers_list_item}
                key={`${sticker.name}_${index}`}
                onClick={(e) => {
                    e.stopPropagation();
                    closeInnerStickerList();
                    onSelectSticker(sticker?.name);
                }}
            >
                <img src={sticker?.image} alt="" />
            </div>
        ));

    return (
        <div
            className={`${Styles.c3_stickers_list_open} ${isStickerListOpen ? Styles.active : ''}`}
            onClick={closeInnerStickerList}
            ref={stickersListOpenRef}
        >
            <div className={Styles.c3_stickers_list_open_head}>
                <div className={Styles.c3_stickers_list_open_back_btn} onClick={openStickerBlock}>
                    <img src={IMAGES.arrow_left_purple} alt="" />
                    <span>Back</span>
                </div>
                <div className={Styles.c3_stickers_list_open_title}>{innerStickerTitle ?? 'Stickers'}</div>
            </div>
            <div className={Styles.c3_stickers_list_open_list}>{innerStickerListMap}</div>
        </div>
    );
};
