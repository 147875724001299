import React, { useEffect, useState } from 'react';
import SwitchMessageContent from './SwitchMessageContent';
import Styles from './styles.module.scss';
import { formatDate } from '../../../../../services/time';
import { scrollTopToMainChat } from '../../../../../services/dom';
import { useDispatch, useSelector } from 'react-redux';
import * as IMAGES from '../../../../common/Images';
import * as CHAT from '../../../../../constants/chat';
import { filterToHideChatHistoryByType } from '../../../../../services/methods/chat';
import VirtualGiftRow from './VirtualGiftRow';
import ChatsApi from '../../../../../services/api/ChatsApi';
import * as currentChatAction from '../../../../../store/actions/currentChat';
import * as chatsAction from '../../../../../store/actions/chats';
import * as alertAction from '../../../../../store/actions/alerts';
import * as ALERTS from '../../../../../store/constants/alerts';

export default function Message({ item, isFromYou }) {
    const currentChatState = useSelector((state) => state.currentChat);
    const profilesState = useSelector((state) => state.profiles);

    const [imageOnLoad, setImageOnLoad] = useState(false);

    const dispatch = useDispatch();

    const womanProfile = profilesState?.list.find(
        (profile) => +profile?.external_id === +currentChatState?.info?.female_external_id,
    );
    const profileAvatar = womanProfile?.photo_link?.length ? womanProfile?.photo_link : IMAGES.profile_img_empty;
    const isHideMessage = !filterToHideChatHistoryByType(item);
    const isActiveHideBtn = !!(isFromYou && !currentChatState?.info?.online && !item?.read_status);

    const onHideMessage = () => {
        if (isActiveHideBtn && item?.id && item?.chat_uid) {
            dispatch(
                alertAction.setCustomConfirmAlert({
                    type: ALERTS.SET_CUSTOM_CONFIRM_ALERT,
                    title: `Are you sure?`,
                    btnName1: 'No',
                    btnName2: 'Yes',
                    successCallback: () => {
                        new ChatsApi().hideMessage(item?.id).then((res) => {
                            if (res?.status) {
                                dispatch(currentChatAction.hideMessage(item?.id));
                                dispatch(chatsAction.hideMessage(item?.chat_uid));
                            } else {
                                dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                            }
                        });
                    },
                }),
            );
        }
    };

    useEffect(() => {
        if (currentChatState?.history?.length) scrollTopToMainChat(currentChatState?.lastMessageId);
    }, [item, imageOnLoad]);

    return (
        <div
            className={`${Styles.clmn_3_chat_message} ${isFromYou ? Styles.right : Styles.left}`}
            id={`mess-${item?.id ?? ''}`}
            data-testid={isFromYou ? `sent-message-${item?.id}` : `received-message-${item?.id}`}
        >
            {item?.message_type === CHAT.MESSAGE_CONTENT_TYPE_VIRTUAL_GIFT ? (
                <VirtualGiftRow
                    isRecipientSide={!isFromYou}
                    message={item}
                    setImageOnLoad={setImageOnLoad}
                    womanInfo={womanProfile}
                    userInfo={currentChatState?.info}
                    // currentUser={currentUser}
                />
            ) : (
                <>
                    <div
                        className={`${Styles.clmn_3_chat_message_content} ${item?.message_type === 'SENT_STICKER' ? Styles.sticker : ''} ${isHideMessage ? Styles.clmn_3_chat_message_hide : ''}`}
                    >
                        <SwitchMessageContent data={item} setImageOnLoad={setImageOnLoad} womanInfo={womanProfile} />
                        <div className={Styles.clmn_3_chat_message_info}>
                            <div className={Styles.clmn_3_chat_message_time} data-testid="message-date">
                                {formatDate(item?.date_created ?? '')}
                            </div>
                            {isFromYou && item?.read_status !== undefined && (
                                <div
                                    className={`${Styles.message_status} ${+item?.read_status ? Styles.readed : Styles.send}`}
                                    data-testid="message-status"
                                />
                            )}
                            {isActiveHideBtn && (
                                <div className={Styles.message_hide} data-testid="hide-btn" onClick={onHideMessage}>
                                    Hide
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`${Styles.clmn_3_chat_message_avatar_wrap} ${isHideMessage ? Styles.clmn_3_chat_message_hide : ''}`}
                        data-testid="message-avatar"
                    >
                        {isFromYou ? (
                            <img src={profileAvatar} alt="" className={Styles.clmn_3_chat_message_avatar} />
                        ) : (
                            <img
                                src={currentChatState?.info?.photo_link ?? IMAGES.profile_img_empty}
                                alt=""
                                className={Styles.clmn_3_chat_message_avatar}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
