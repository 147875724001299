import { useState } from 'react';
import * as filesAction from '../../store/actions/files';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'js-md5';
import moment from 'moment';
import * as currentChatAction from '../../store/actions/currentChat';
import { insertedMessageBeforeSync } from '../../services/init';
import ChatsApi from '../../services/api/ChatsApi';
import * as alertsAction from '../../store/actions/alerts';
import * as ERROR from '../../constants/error';
import * as chatsAction from '../../store/actions/chats';

export default function useInitAttachModal() {
    const dispatch = useDispatch();

    const profilesState = useSelector((state) => state.profiles);
    const currentChatState = useSelector((state) => state.currentChat);
    const userState = useSelector((state) => state.user);
    const womanProfile = profilesState?.list?.find(
        (woman) => +woman.external_id === +currentChatState?.info.female_external_id,
    );
    const filesState = useSelector((state) => state.files);

    const [isAttachModal, setIsAttachModal] = useState(false);

    const openAttachModal = () => {
        setIsAttachModal(true);
        dispatch(filesAction.getAllImages(+womanProfile?.external_id));
        dispatch(filesAction.getAllVideos(+womanProfile?.external_id));
        dispatch(filesAction.getAllAudios(+womanProfile?.external_id));
    };

    const closeAttachModal = () => {
        setIsAttachModal(false);
    };

    const onSubmitMessageContent = async (e, messageType) => {
        const checkedList = e.currentTarget.parentNode.parentNode.querySelectorAll('input:checked');

        if (checkedList?.length > 0) {
            closeAttachModal();

            for (const checkedItem of checkedList) {
                if (messageType === 'SENT_IMAGE') {
                    const imgBelongsToWoman = filesState?.images.find(({ link }) => link === checkedItem.dataset.link);
                    if (!imgBelongsToWoman) {
                        return;
                    }
                }

                const hashId = md5(moment().format());

                dispatch(
                    currentChatAction.addMessageToHistory(
                        insertedMessageBeforeSync({
                            id: hashId,
                            messageContent: checkedItem.dataset.link,
                            externalId: userState?.info?.external_id,
                            type: messageType,
                        }),
                    ),
                );

                await new ChatsApi()
                    .postMessage(
                        +currentChatState?.info?.female_external_id,
                        +currentChatState?.info?.male_external_id,
                        checkedItem?.dataset?.link,
                        messageType,
                        checkedItem?.dataset?.filename,
                        null,
                        false,
                        +checkedItem?.dataset?.contentid,
                    )
                    .then((res) => {
                        function badRequest() {
                            dispatch(currentChatAction.removeMessageInHistory(hashId));

                            if (res?.error) {
                                if (res?.error?.includes('no connection')) {
                                    dispatch(currentChatAction.updateMessageLimit(0, 0));
                                    return dispatch(alertsAction.setMessage('', 'Message limit reached'));
                                }
                                dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                            } else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                        }

                        if (!res?.response?.message_object || !res?.status) return badRequest();
                        else if (res?.status) {
                            dispatch(
                                currentChatAction.updateMessageLimit(
                                    res?.response?.chat_list_object?.message_limit,
                                    res?.response?.chat_list_object?.like_limit,
                                ),
                            );
                            dispatch(currentChatAction.updateMessageInHistory(hashId, res?.response?.message_object));
                            dispatch(
                                chatsAction.updateActiveChat({
                                    ...res?.response?.chat_list_object,
                                    ...{ sender_external_id: res?.response?.message_object?.sender_external_id },
                                }),
                            );
                            dispatch(currentChatAction.updateAllMediaList(res?.response?.message_object));
                        }
                    });
            }
        }
    };

    return { isAttachModal, openAttachModal, closeAttachModal, onSubmitMessageContent, womanProfile };
}
