import Base from '../Base';

export default class AwsApi extends Base {
    generateLink(data) {
        return super.post('v3/click-history/aws/generate-link', data).then((res) => res?.data);
    }

    generateVideoLink(data) {
        return super.post('v3/video_converter/new-video-convert', data).then((res) => res?.data);
    }

    generateAudioLink(data) {
        return super.post('v3/video_converter/audio-convert', data).then((res) => res?.data);
    }
}
