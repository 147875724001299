import * as CHAT from '../../constants/chat';
import { useDispatch, useSelector } from 'react-redux';
import * as notifyAction from '../../store/actions/notify';
import * as chatsAction from '../../store/actions/chats';
import * as currentChatAction from '../../store/actions/currentChat';
import ChatsApi from '../../services/api/ChatsApi';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import useMakeNotificationSound from '../sound/useMakeNotificationSound';

export default function useChatSync() {
    const dispatch = useDispatch();
    const { makeSound } = useMakeNotificationSound();

    const notifyState = useSelector((state) => state.notify);
    const profilesState = useSelector((state) => state.profiles);
    const chatsState = useSelector((state) => state.chats);
    const currentChatState = useSelector((state) => state.currentChat);
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);

    const checkSocketMessage = (recipientExternalID) => {
        return profilesState?.list?.some((item) => +item?.external_id === +recipientExternalID);
    };

    return {
        getMessage: (message) => {
            const chatData = message?.chat_list_object;
            const messageData = message?.message_object;
            const notificationData = message?.notification_object;
            const replyData = message?.reply_object;

            if (!checkSocketMessage(notificationData?.recipient_external_id)) return;

            if (notificationData?.chat_uid && messageData?.chat_uid !== currentChatState?.info?.chat_uid) {
                const isPaidNotify = CHAT.PAID_MESSAGE_CONTENT.includes(notificationData?.message_type);
                const isMessageLimit =
                    !!+notificationData?.message_limit ||
                    !!+notificationData?.like_limit ||
                    !!+notificationData?.letter_limit;

                if (isPaidNotify || isMessageLimit) {
                    if (!notifyState?.listPaid.find((item) => item?.id === notificationData?.id)?.id) {
                        makeSound();
                        dispatch(notifyAction.addPaidNotify(notificationData, profilesState?.operatorId));
                    }
                }
            }

            if (chatData?.chat_uid) {
                if (
                    chatsState?.list?.length &&
                    chatsState?.list.some((item) => item?.chat_uid === chatData?.chat_uid)
                ) {
                    dispatch(
                        chatsAction.updateActiveChat({
                            ...chatData,
                            ...{
                                is_male: 1,
                                woman_reply: 0,
                                message_limit: +notificationData?.message_limit,
                                like_limit: +notificationData?.like_limit,
                                letter_limit: +notificationData?.letter_limit,
                            },
                        }),
                    );
                } else {
                    const chat = { ...chatData, ...messageData, ...notificationData };

                    if (window?.location?.href?.includes('chat') || window?.location?.href?.includes('unanswered')) {
                        if (profilesState?.active?.external_id) {
                            if (+profilesState?.active?.external_id === +chat.recipient_external_id) {
                                dispatch(
                                    chatsAction.addChat({
                                        ...chat,
                                        id: chat.recipient_external_id,
                                        male_external_id: chat.sender_external_id,
                                        female_external_id: chat.recipient_external_id,
                                    }),
                                );
                            }
                        } else {
                            dispatch(
                                chatsAction.addChat({
                                    ...chat,
                                    id: chat.recipient_external_id,
                                    male_external_id: chat.sender_external_id,
                                    female_external_id: chat.recipient_external_id,
                                }),
                            );
                        }
                    }

                    // dispatch(chatsAction.addChatListWithoutReload({
                    // 	user_id: profilesState?.active?.external_id ? profilesState?.active?.external_id : '',
                    // 	isOnline: chatsState?.filterOnline,
                    // 	freeze: chatsState?.filterFreeze,
                    // 	searchItem: chatsState?.filterSearch,
                    // 	chatType: getChatListType(location?.pathname),
                    // }));
                }
            }

            if (messageData?.chat_uid === currentChatState?.info?.chat_uid) {
                if (!currentChatState.history.some((item) => item?.id === messageData?.id)) {
                    if (CHAT.NOTIFY_ALL_MEDIA_LIST.some((el) => el === messageData?.message_type)) {
                        dispatch(currentChatAction.updateAllMediaList(messageData));
                    }
                    dispatch(currentChatAction.addMessageToHistory(messageData, replyData));
                    dispatch(
                        currentChatAction.updateMessageLimit(
                            +notificationData?.message_limit,
                            +notificationData?.like_limit,
                        ),
                    );
                    new ChatsApi().postReadChat(messageData?.chat_uid, messageData?.recipient_external_id);
                }
            }

            // Update letter limit in letter room if open current Letter
            if (
                +currentChatMailboxState?.info?.female_id === +notificationData?.recipient_external_id &&
                +currentChatMailboxState?.info?.male_id === +notificationData?.sender_external_id
            ) {
                dispatch(currentChatMailboxAction.updateLetterLimit(+notificationData?.letter_limit));
            }
        },

        readChat: (message) => {
            if (currentChatState?.info?.chat_uid === message?.chat_uid)
                dispatch(currentChatAction.setReadLastMessages());
            dispatch(chatsAction.setReadLastMessagesFromYou(message?.chat_uid));
        },
    };
}
