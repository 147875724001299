import * as USER from '../constants/user';
import { SET_REFFERENCIES } from '../constants/profiles';
import UserApi from '../../services/api/UserApi';

export function getInfo() {
    return function (dispatch) {
        new UserApi().getInfo().then((res) => {
            return dispatch({
                type: USER.USER_INFO_GET,
                data: res,
            });
        });
    };
}

export function getRefferencies() {
    return function (dispatch) {
        new UserApi().getReferencies().then((res) => {
            const data = res && res?.result && res?.result?.referencies_list ? res?.result?.referencies_list : {};
            return dispatch({
                type: SET_REFFERENCIES,
                data,
            });
        });
    };
}
