//TYPE
export const SENDER_TYPE_CHAT = 'SENDER_TYPE_CHAT';
export const SENDER_TYPE_LETTER = 'SENDER_TYPE_LETTER';

//GROUP
export const SENDER_GROUP_ONLINE_NOW = 'ONLINE_NOW';
export const SENDER_GROUP_DIALOGUES = 'DIALOGUES';
export const SENDER_GROUP_UNREAD = 'UNREAD';
export const SENDER_GROUP_FAVORITE = 'FAVORITE';
export const SENDER_GROUP_MAYBE = 'MAYBE';
export const SENDER_GROUP_SPECIAL = 'SPECIAL';
export const SENDER_GROUP_LOOKING_AT_YOU = 'LOOKING_AT_YOU';
export const SENDER_GROUP_CONNECTIONS = 'CONNECTIONS';
export const SENDER_GROUP_WHO_SENT_GIFTS = 'WHO_SENT_GIFTS';
// export const SENDER_GROUP_LIKED_YOU = 'LIKED_YOU';
// export const SENDER_GROUP_NEXT_TIME = 'NEXT_TIME';
// export const SENDER_GROUP_RECONNECTION = 'RECONNECTION';

//SENDING TIME
export const SENDER_SENDING_TIME_IMMEDIATELY = 'IMMEDIATELY';
export const SENDER_SENDING_TIME_SET_DELIEVERY_TIME = 'SET_DELIEVERY_TIME';

//WILL EXPIRE
export const SENDER_WILL_EXPIRE_NEVER = 'NEVER';
export const SENDER_WILL_EXPIRE_SET_TIME = 'SET_TIME';

//ATTACHMENT TYPE
export const SENT_TEXT = 'SENT_TEXT';
export const SENT_MAIL = 'SENT_MAIL';
