import styles from '../../components/stat/styles.module.scss';
import React, { useEffect, useState } from 'react';
import { getDateListInRange } from '../../services/time';
import * as STATISTIC from '../../constants/statistic';
import { useDispatch, useSelector } from 'react-redux';
import * as format from '../../services/helpers/format';
import * as manAction from '../../store/actions/statistics';
import { initCancelTokenSource } from '../../services/methods';

export default function useInitStatisticTable() {
    const dispatch = useDispatch();

    const statisticState = useSelector((state) => state.statistic);

    const [sortBy, setSortBy] = useState('DESC');
    const [sortTarget, setSortTarget] = useState('date');
    const [selectStartDate, setSelectStartDate] = useState(format.firstDayDateFormat(new Date()));
    const [selectEndDate, setSelectEndDate] = useState(format.dateFormat(new Date()));
    const [profile_id, setProfile_id] = useState(null);
    const [statistic, setStatistic] = useState([]);
    const [canceledToken, setCanceledToken] = useState(null);

    const countPriceMail = (func, currentDate) => {
        const result = {};
        STATISTIC.STATISTIC_TYPE_LIST.forEach((statisticType) => {
            if (
                statisticType === 'SENT_MAIL_FIRST' ||
                statisticType === 'SENT_MAIL_SECOND' ||
                statisticType === 'SENT_MAIL'
            ) {
                return (result['SENT_MAIL'] =
                    func(
                        statistic?.filter(
                            (item) => statisticType === item?.action_type && item?.date_created?.includes(currentDate),
                        ),
                        +result['SENT_MAIL'] || 0,
                    )?.toFixed(2) || 0);
            }
            if (statisticType === 'GET_AUDIO_NEW' || statisticType === 'GET_AUDIO') {
                return (result['GET_AUDIO'] =
                    func(
                        statistic?.filter(
                            (item) => statisticType === item?.action_type && item?.date_created?.includes(currentDate),
                        ),
                        +result['GET_AUDIO'] || 0,
                    )?.toFixed(2) || 0);
            }
            if (statisticType === 'GET_AUDIO_MAIL_NEW' || statisticType === 'GET_AUDIO_MAIL') {
                return (result['GET_AUDIO_MAIL'] =
                    func(
                        statistic?.filter(
                            (item) => statisticType === item?.action_type && item?.date_created?.includes(currentDate),
                        ),
                        +result['GET_AUDIO_MAIL'] || 0,
                    )?.toFixed(2) || 0);
            }
            if (statisticType === 'GET_VIDEO_NEW' || statisticType === 'GET_VIDEO') {
                return (result['GET_VIDEO'] =
                    func(
                        statistic?.filter(
                            (item) => statisticType === item?.action_type && item?.date_created?.includes(currentDate),
                        ),
                        +result['GET_VIDEO'] || 0,
                    )?.toFixed(2) || 0);
            }
            if (statisticType === 'GET_VIDEO_MAIL_NEW' || statisticType === 'GET_VIDEO_MAIL') {
                return (result['GET_VIDEO_MAIL'] =
                    func(
                        statistic?.filter(
                            (item) => statisticType === item?.action_type && item?.date_created?.includes(currentDate),
                        ),
                        +result['GET_VIDEO_MAIL'] || 0,
                    )?.toFixed(2) || 0);
            }
            result[statisticType] =
                func(
                    statistic?.filter(
                        (item) => statisticType === item?.action_type && item?.date_created?.includes(currentDate),
                    ),
                )?.toFixed(2) || 0;
        });
        return result;
    };

    const initTableData = () => {
        if (!statistic?.length) return [];

        const countPrice = (list, startedValue) =>
            list?.reduce((acc, val) => acc + +val?.operator_price, startedValue || 0);

        const resultList = getDateListInRange(selectStartDate, selectEndDate)?.map((currentDate) => {
            const result = countPriceMail(countPrice, currentDate);

            return {
                date: currentDate ?? '',
                ...result,
                total:
                    countPrice(
                        statistic?.filter(
                            (item) =>
                                STATISTIC.STATISTIC_TYPE_LIST.includes(item?.action_type) &&
                                item?.date_created?.includes(currentDate),
                        ),
                    )?.toFixed(2) || 0,
            };
        });

        const getTotalData = () => {
            const result = {};

            STATISTIC.STATISTIC_TYPE_LIST.forEach((statisticType) => {
                if (
                    statisticType === 'SENT_MAIL_FIRST' ||
                    statisticType === 'SENT_MAIL_SECOND' ||
                    statisticType === 'SENT_MAIL'
                ) {
                    return (result['SENT_MAIL'] =
                        countPrice(
                            statistic?.filter((item) => statisticType === item?.action_type),
                            +result['SENT_MAIL'] || 0,
                        )?.toFixed(2) || 0);
                }
                if (statisticType === 'GET_AUDIO_NEW' || statisticType === 'GET_AUDIO') {
                    return (result['GET_AUDIO'] =
                        countPrice(
                            statistic?.filter((item) => statisticType === item?.action_type),
                            +result['GET_AUDIO'] || 0,
                        )?.toFixed(2) || 0);
                }
                if (statisticType === 'GET_AUDIO_MAIL_NEW' || statisticType === 'GET_AUDIO_MAIL') {
                    return (result['GET_AUDIO_MAIL'] =
                        countPrice(
                            statistic?.filter((item) => statisticType === item?.action_type),
                            +result['GET_AUDIO_MAIL'] || 0,
                        )?.toFixed(2) || 0);
                }
                if (statisticType === 'GET_VIDEO_NEW' || statisticType === 'GET_VIDEO') {
                    return (result['GET_VIDEO'] =
                        countPrice(
                            statistic?.filter((item) => statisticType === item?.action_type),
                            +result['GET_VIDEO'] || 0,
                        )?.toFixed(2) || 0);
                }
                if (statisticType === 'GET_VIDEO_MAIL_NEW' || statisticType === 'GET_VIDEO_MAIL') {
                    return (result['GET_VIDEO_MAIL'] =
                        countPrice(
                            statistic?.filter((item) => statisticType === item?.action_type),
                            +result['GET_VIDEO_MAIL'] || 0,
                        )?.toFixed(2) || 0);
                }

                result[statisticType] =
                    countPrice(statistic?.filter((item) => statisticType === item?.action_type))?.toFixed(2) || 0;
            });

            return {
                date: 'Total',
                ...result,
                total:
                    resultList
                        ?.map((item) => +item?.total)
                        ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                        ?.toFixed(2) || 0,
            };
        };

        return [...resultList, ...[getTotalData()]];
    };
    const initTableColumns = () => {
        const resultArray = [];
        resultArray.push({
            name: 'Date',
            selector: 'date',
            sortable: true,
        });

        resultArray.push(
            ...STATISTIC.STATISTIC_TYPE_COLUMN.map((type, i) => {
                return {
                    name: STATISTIC.STATIC_TYPE_NAME[i] || type,
                    selector: type,
                    sortable: true,
                };
            }),
        );
        resultArray.push({
            name: 'Total',
            selector: 'total',
        });
        return resultArray;
    };

    const getTableHead = () => {
        return initTableColumns().map((item, key) => (
            <th
                className={`${styles.table_head_item} ${item.selector === sortTarget ? styles.active : ''} ${sortBy === 'DESC' ? styles.desc : styles.asc}`}
                key={key}
                onClick={() => {
                    clickHead(item.selector);
                }}
            >
                <span>{item.name}</span>
            </th>
        ));
    };
    const clickHead = (target) => {
        if (sortTarget === target) {
            if (sortBy === 'DESC') setSortBy('ASC');
            else if (sortBy === 'ASC') setSortBy('DESC');
        } else {
            setSortBy('DESC');
        }
        setSortTarget(target);
    };
    const GetColumns = () => {
        return sortValue(sortTarget, sortBy).map((item, key) => (
            <tr className={styles.table_row} key={key}>
                {Object.keys(item)
                    .filter((item) => item !== 'woman_external_id_gifts')
                    .map((keyItem, key) => (
                        <td key={key * 11} className={`${styles.table_col}`}>
                            {item[keyItem] === '0.00' ? '-' : item[keyItem]}
                        </td>
                    ))}
            </tr>
        ));
    };
    const sortValue = (name, sortBy) => {
        return initTableData().sort((a, b) => {
            if (a['date'] !== 'Total' && b['date'] !== 'Total') {
                if (sortBy === 'DESC') return b[name] - a[name];
                else return a[name] - b[name];
            } else return true;
        });
    };
    const searchButton = (dateStart, dateEnd) => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);

        if (dateStart) dispatch(manAction.getOperatorStatistic(dateStart, selectEndDate, source?.token));
        else if (dateEnd) dispatch(manAction.getOperatorStatistic(selectStartDate, dateEnd, source?.token));
        else dispatch(manAction.getOperatorStatistic(selectStartDate, selectEndDate, source?.token));
    };
    const countAllTotal = () => {
        const allTotalList = initTableData().map((item) => +item?.total);
        if (!allTotalList?.length) return 0;
        return (
            allTotalList
                ?.slice(0, allTotalList?.length - 1)
                ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                ?.toFixed(2) || 0
        );
    };

    useEffect(() => {
        if (profile_id)
            setStatistic(statisticState?.statistic?.filter((item) => +item?.profile_external_id === +profile_id));
        else setStatistic(statisticState?.statistic);
    }, [statisticState?.statistic, profile_id]);

    return {
        statistic,
        searchButton,
        total: countAllTotal() || 0,

        getTableHead,
        clickHead,
        GetColumns,
        sortValue,

        selectStartDate,
        setSelectStartDate,
        selectEndDate,
        setSelectEndDate,
        profile_id,
        setProfile_id,
    };
}
