import React from 'react';

import Attach from './Attach';
import ButtonSend from './ButtonSend';
import Input from './Input';
import Smile from './Smile';
import Translate from './Translate';

export default ({
    checkTranslate,
    onTranslateChange,
    inputMessage,
    translatedText,
    handleTranslating,
    handleTranslated,
    onSubmitMessageEnter,
    addEmoji,
    onSubmitMessage,
}) => {
    return (
        <div className="c3_write_msg">
            <div className="c3_write_msg_left">
                <Translate checkTranslate={checkTranslate} onTranslateChange={onTranslateChange} />
                <Input
                    inputMessage={inputMessage}
                    translatedText={translatedText}
                    handleTranslating={handleTranslating}
                    handleTranslated={handleTranslated}
                    onSubmitMessageEnter={onSubmitMessageEnter}
                />
            </div>
            <div className="c3_write_msg_middle">
                <Attach />
                <Smile addEmoji={addEmoji} />
            </div>
            <ButtonSend onSubmitMessage={onSubmitMessage} />
        </div>
    );
};
