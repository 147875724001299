import styles from '../../components/stat/styles.module.scss';
import React, { useEffect, useState } from 'react';
import OperatorApi from '../../services/api/OperatorApi';

export default function useInitGiftStatisticTable() {
    const [sortBy, setSortBy] = useState('DESC');
    const [sortTarget, setSortTarget] = useState('date');
    const [statistic, setStatistic] = useState([]);
    const [term, setTerm] = useState('');

    const initTableData = () => {
        if (!statistic?.length) return [];
        return statistic
            .map((item) => ({
                created_at: item?.created_at,
                user_external_id: (
                    <a
                        style={{ textDecoration: 'underline' }}
                        href={`/profile-man/${item?.user_external_id}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {item?.man_name + ' / ' + item?.user_external_id}
                    </a>
                ),
                female_external_id: (
                    <a
                        style={{ textDecoration: 'underline' }}
                        href={`/profile-woman/${item?.female_external_id}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {item?.woman_name + ' / ' + item?.female_external_id}
                    </a>
                ),
                product_img: <img style={{ width: '64px' }} src={item?.product_img} alt="" />,
                name: item?.name,
                special: +item?.special,
                status:
                    +item?.status === 0
                        ? 'In progress'
                        : +item?.status === 1
                          ? 'Approved'
                          : +item?.status === 2
                            ? 'Declined'
                            : '',
                updated_at: +item?.status === 0 ? '' : item?.updated_at,
                woman_external_id_gifts: item?.female_external_id,
            }))
            .filter((item) => item?.woman_external_id_gifts?.toString()?.includes(term?.toString()));
    };
    const initTableColumns = () => {
        return [
            {
                name: 'Date created',
                selector: 'created_at',
                sortable: true,
                grow: 1.5,
                wrap: true,
            },
            {
                name: 'Man',
                selector: 'user_external_id',
                sortable: true,
                wrap: true,
            },
            {
                name: 'Girl',
                selector: 'female_external_id',
                sortable: true,
                wrap: true,
            },
            {
                name: 'Gift image',
                selector: 'name',
                sortable: true,
                grow: 0.5,
                cell: (d) => (
                    <img style={{ borderRadius: '50%' }} width="64px" alt={d.product_img} src={d.product_img} />
                ),
            },
            {
                name: 'Gift',
                selector: 'name',
                sortable: true,
                grow: 2,
                wrap: true,
            },
            {
                name: 'Bonuses',
                selector: 'special',
                sortable: true,
                wrap: true,
            },
            {
                name: 'Status',
                selector: 'status',
                sortable: true,
                wrap: true,
            },
            {
                name: 'Approved / Declined Date',
                selector: 'updated_at',
                sortable: true,
                grow: 1.5,
                wrap: true,
            },
        ];
    };
    const getTableHead = () => {
        return initTableColumns().map((item, key) => (
            <th
                className={`${styles.table_head_item} ${item.selector === sortTarget ? styles.active : ''} ${sortBy === 'DESC' ? styles.desc : styles.asc}`}
                key={key}
                onClick={() => {
                    clickHead(item.selector);
                }}
            >
                <span>{item.name}</span>
            </th>
        ));
    };
    const clickHead = (target) => {
        if (sortTarget === target) {
            if (sortBy === 'DESC') setSortBy('ASC');
            else if (sortBy === 'ASC') setSortBy('DESC');
        } else {
            setSortBy('DESC');
        }
        setSortTarget(target);
    };
    const GetColumns = () => {
        return sortValue(sortTarget, sortBy).map((item, key) => (
            <tr className={styles.table_row} key={key}>
                {Object.keys(item)
                    .filter((item) => item !== 'woman_external_id_gifts')
                    .map((keyItem, key) => (
                        <td key={key * 11} className={styles.table_col}>
                            {item[keyItem]}
                        </td>
                    ))}
            </tr>
        ));
    };
    const sortValue = (name, sortBy) => {
        return initTableData().sort((a, b) => {
            if (a['date'] !== 'Total' && b['date'] !== 'Total') {
                if (sortBy === 'DESC') return b[name] - a[name];
                else return a[name] - b[name];
            } else return true;
        });
    };
    const handleInputChange = (e) => setTerm(e?.target?.value);
    const clearTerm = () => setTerm('');

    useEffect(() => {
        new OperatorApi().getGifts().then((res) => setStatistic(res?.result));
    }, []);

    return {
        term,
        getTableHead,
        GetColumns,
        handleInputChange,
        clearTerm,
    };
}
