import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './routes';
import { Provider } from 'react-redux';
import { configureStore } from './store';
import * as Sentry from '@sentry/react';
import { isInjectedCode, sendErrToSentry } from './services/sentry';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    beforeSend(event) {
        if (isInjectedCode(event)) return null;

        return event;
    },
    // Filter out specific integrations to disable automatic error capturing
    integrations: (integrations) => [
        ...integrations.filter(
            (integration) =>
                integration.name !== 'GlobalHandlers' && // Disables global error capturing (window.onerror)
                integration.name !== 'TryCatch', // Disables automatic try-catch error capturing in async code
        ),
        Sentry.replayIntegration({
            networkDetailAllowUrls: [window.location.origin],
            networkRequestHeaders: ['X-Request-ID'],
            networkResponseHeaders: ['X-Request-ID'],
        }),
    ],
    release: process.env.REACT_APP_PRODUCT_VERSION,
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
});

const store = configureStore();

import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);

    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </Provider>,
    );
} else {
    sendErrToSentry(new Error('Null container'));
}
