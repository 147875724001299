import * as FILTER from '../constants/filter';

const initState = {
    manFilter: 'all',
    lettersFilter: 'all',
    manId: '',
    onlineFilter: 'online',
    freezeFilter: true,
};

export default function (state = initState, action) {
    switch (action.type) {
        case FILTER.SET_GIRL_FILTER:
            return {
                ...state,
                ...{
                    manFilter: action?.data,
                    manId: '',
                },
            };
        case FILTER.SET_INIT_FILTER:
            return {
                ...state,
                ...initState,
            };
        case FILTER.SET_LETTERS_FILTER:
            return {
                ...state,
                ...{
                    lettersFilter: action?.data,
                },
            };

        case FILTER.SET_FILTER_BY_ID:
            return {
                ...state,
                ...{
                    manFilter: action?.data?.filterName,
                    manId: action?.data?.id,
                },
            };

        case FILTER.SET_ONLINE_FILTER:
            return {
                ...state,
                ...{
                    onlineFilter: action?.data,
                },
            };
        case FILTER.SET_FREEZE_FILTER:
            return {
                ...state,
                ...{
                    freezeFilter: action?.data,
                },
            };
        default:
            return state;
    }
}
