import React, { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import * as IMAGES from '../../../Images';
import styles from './styles.module.scss';

export default function ({ closeAddToFolderModal, folderList, saveFileToFolder, activeFolder }) {
    const [selectedFolderId, setSelectedFolderId] = useState(0);
    const [errorText, setErrorText] = useState('');

    const handleSelectFolder = (folderId) => {
        if (activeFolder?.id === folderId) return false;

        setSelectedFolderId(folderId);
        setErrorText('');
    };

    const handleSaveBtn = () => {
        if (selectedFolderId) {
            saveFileToFolder(selectedFolderId);
        } else {
            setErrorText('Please select a folder!');
        }
    };

    return (
        <div className={styles.folder_modal_wrap}>
            <div className={styles.folder_modal_content}>
                <h2 className={styles.folder_modal_title}>Select the folder where you want to add the file</h2>

                <div className={styles.folder_modal_list}>
                    <FormGroup>
                        {!!folderList?.length &&
                            folderList?.map((item) => (
                                <FormControlLabel
                                    key={item?.id}
                                    control={<Checkbox checked={selectedFolderId === item?.id} />}
                                    label={item?.name}
                                    disabled={activeFolder?.id === item?.id}
                                    onClick={() => handleSelectFolder(item?.id)}
                                />
                            ))}
                    </FormGroup>
                </div>

                <div className={styles.folder_modal_btn_wrap}>
                    {!!errorText?.length && <span>{errorText}</span>}

                    <button className={styles.folder_modal_btn} onClick={handleSaveBtn}>
                        Save
                    </button>
                </div>

                <div className="popup_error_close" onClick={closeAddToFolderModal}>
                    <img src={IMAGES.popup_gift_close} alt="" />
                </div>
            </div>
        </div>
    );
}
