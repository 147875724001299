import React from 'react';

import Item from './Item';

import useHandleOnlineManListItem from '../../../../hooks/online-man/useHandleOnlineManListItem';

export default ({ chatsState }) => {
    const handleOnlineManListItem = useHandleOnlineManListItem();

    return (
        <div className="c2_list_wrap_2">
            <div className="c2_list_wrap">
                <div className="c2_list">
                    {chatsState.list?.map((item, key) => (
                        <Item key={key} item={item} currentChat={chatsState.currentChat} {...handleOnlineManListItem} />
                    ))}
                </div>
            </div>
        </div>
    );
};
