import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as profilesAction from '../../store/actions/profiles';
import useTransition from '../transition/useTransition';
import ProfileApi from '../../services/api/ProfileApi';
import * as alertsAction from '../../store/actions/alerts';
import * as ERROR from '../../constants/error';
import * as loaderAction from '../../store/actions/loader';
import * as MENU from '../../constants/menu';
import * as senderAction from '../../store/actions/sender';
import { sendErrToSentry } from '../../services/sentry';

export default function useHandleMultiChat() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const profilesState = useSelector((state) => state.profiles);

    const [isOpenListProfiles, setIsOpenListProfiles] = useState(false);
    const [isAllOnlineStatus, setIsAllOnlineStatus] = useState(false);
    const [offlineProfilesAmount, setOfflineProfilesAmount] = useState(null);
    const [searchName, setSearchName] = useState('');
    const [listProfiles, setListProfiles] = useState([]);

    const activeFolder = (folder) => transition.transitionPush(`/${folder}`);

    const setActiveProfile = (profile) => dispatch(profilesAction.setActiveProfile(profile));

    const isActiveSender = (womanExternalID) => {
        return profilesState.senderListInfo.some((item) => +item?.woman_external_id === +womanExternalID);
    };

    const setOnlineStatus = (externalID, onlineStatus) => {
        try {
            dispatch(loaderAction.setActiveGifLoader(true));
            dispatch(profilesAction.addActiveSenderInfo(profilesState.list?.map((item) => +item?.external_id)));
            new ProfileApi()
                .setProfileOnline({
                    external_id: externalID,
                    operator_id: profilesState?.operatorId,
                    status: +!+onlineStatus,
                })
                .then((res) => {
                    if (res?.status) dispatch(profilesAction.setOnlineStatusByExternalID(externalID, +!+onlineStatus));
                    else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        } catch (error) {
            sendErrToSentry(error);
        }
    };

    const setAllOnlineStatus = () => {
        try {
            dispatch(loaderAction.setActiveGifLoader(true));
            dispatch(profilesAction.addActiveSenderInfo(profilesState.list?.map((item) => +item?.external_id)));
            new ProfileApi()
                .setProfileOnline({
                    external_id: -1,
                    operator_id: profilesState?.operatorId,
                    status: +!isAllOnlineStatus,
                })
                .then((res) => {
                    if (res?.status) dispatch(profilesAction.setAllOnlineStatus(+!isAllOnlineStatus));
                    else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        } catch (error) {
            sendErrToSentry(error);
        }
    };

    const removeActiveProfile = () => {
        dispatch(profilesAction.removeActiveProfile());
        setIsOpenListProfiles(true);
    };

    const handleSearchName = (value) => {
        setSearchName(value);
        setListProfiles(
            profilesState?.list?.filter(
                (item) =>
                    item?.name?.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
                    item?.external_id?.toString().includes(value.toString()),
            ),
        );
    };

    const openWomanProfile = (womanExternalID) => {
        if (womanExternalID) window.open(`/${MENU.MENU_PROFILE_WOMAN}/${womanExternalID}`);
    };

    const onSetActiveType = (senderType) => dispatch(senderAction.setActiveSenderType(senderType));

    useEffect(() => {
        if (profilesState?.list?.length) {
            setListProfiles(profilesState.list);
            setIsAllOnlineStatus(!profilesState?.list?.filter((item) => !+item?.online)?.length);
            setOfflineProfilesAmount(profilesState?.list?.filter((item) => !+item?.online)?.length);
        }
    }, [profilesState?.list]);

    return {
        isActiveProfileExist: !!profilesState?.active?.external_id,
        setIsOpenListProfiles: () => {
            setIsOpenListProfiles(!isOpenListProfiles);
        },
        closeMultiMultiBlock: () => setIsOpenListProfiles(false),
        activeFolder,
        offlineProfilesAmount,
        isAllOnlineStatus,
        activeProfile: profilesState.active,
        giftsNotify: null,
        lettersNotify: null,
        isOpenListProfiles,
        listProfiles,
        searchName,
        setOnlineStatus,
        setAllOnlineStatus,
        handleSearchName,
        setActiveProfile,
        removeActiveProfile,
        isActiveSender,
        openWomanProfile,
        onSetActiveType,
    };
}
