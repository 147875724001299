import React, { useState } from 'react';
import { cutLengthString, getReadableFileSizeString } from '../../../../services/methods';
import * as LENGTH from '../../../../constants/length';
import * as REGEXP from '../../../../constants/regExp';

export default ({ closeModalAudio, uploadAudio, handleUploadAudio, audioFile, setAudioFile }) => {
    const [audioName, setAudioName] = useState('');

    const handleAudioName = (e) => {
        if (REGEXP.ATTACH_FILENAME.test(e.target.value[e.target.value.length - 1])) {
            setAudioName(e.target.value);
        }
    };

    const onSubmitAudio = () => {
        uploadAudio({ file: audioFile, fileName: audioName.length > 0 ? audioName : audioFile.name.split('.')[0] });

        closeModalAudio();
        setAudioFile({});
        setAudioName('');
    };

    return (
        <div className="add_file_popup_wrap">
            <div className="popup_add_file_head">New audio</div>
            {audioFile && audioFile.name && audioFile.size ? (
                <React.Fragment>
                    {audioFile && +audioFile.size > LENGTH.MAX_AUDIO_SIZE ? (
                        <div className={'popup_add_file_row oversize'}>
                            File you’ve uploaded is too big <span>(max {LENGTH.MAX_AUDIO_SIZE_TITLE} Mb)</span>
                        </div>
                    ) : (
                        <div className="popup_add_file_row">
                            <span>Audio name</span>
                            <input
                                type="text"
                                placeholder="Enter audio name here"
                                value={audioName}
                                required={true}
                                onChange={handleAudioName}
                            />
                        </div>
                    )}
                    {+audioFile.size <= LENGTH.MAX_IMG_SIZE ? (
                        <div className="popup_add_file_row uploaded">
                            <span>File uploaded</span>
                            <div className="popup_uploaded_info">
                                {cutLengthString(audioFile.name, LENGTH.ATTACH_TITLE_NAME)}
                                <span>({getReadableFileSizeString(audioFile.size)})</span>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                </React.Fragment>
            ) : (
                <div />
            )}
            {audioFile && audioFile.name && audioFile.size && +audioFile.size <= LENGTH.MAX_AUDIO_SIZE ? (
                <div className="popup_add_file_row add_btn">
                    <div
                        className="popup_add_file_upload_btn"
                        onClick={audioFile && audioFile.name ? onSubmitAudio : null}
                    >
                        Add Audio
                    </div>
                </div>
            ) : (
                <div className="popup_add_file_row upload_btn">
                    <input type="file" accept="audio/*" id="popup_inp_file1" onChange={handleUploadAudio} />
                    <label htmlFor="popup_inp_file1" className="popup_bottom_upload_btn">
                        <img src="/img/popup-upload.svg" alt="" />
                        Upload
                    </label>
                </div>
            )}
            <div className="popup_add_file_close" onClick={closeModalAudio}>
                <img src="/img/cross_white.svg" alt="" />
            </div>
        </div>
    );
};
