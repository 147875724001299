import * as MAILBOX from '../constants/mailbox';
import MailboxApi from '../../services/api/MailboxApi';

export function getMails(data, token) {
    return function (dispatch) {
        dispatch({
            type: MAILBOX.FETCH_MAILBOX_GET,
        });
        new MailboxApi().postMails(data, token).then((res) => {
            if (res?.canceled) return;
            return dispatch({
                type: MAILBOX.MAILBOX_GET,
                data: res,
            });
        });
    };
}

export function getMailsWithoutReload(data, callback = null) {
    return function (dispatch) {
        new MailboxApi().postMails(data).then((res) => {
            callback && callback();
            return dispatch({
                type: MAILBOX.MAILBOX_GET,
                data: res,
            });
        });
    };
}

export function getMailsWithPage(data, token) {
    return function (dispatch) {
        dispatch({
            type: MAILBOX.FETCH_MAILBOX_GET_WITH_PAGE,
            data,
        });
        new MailboxApi().postMails(data, token).then((res) => {
            if (res?.canceled) return;
            return dispatch({
                type: MAILBOX.MAILBOX_GET,
                data: res,
            });
        });
    };
}

export function getMailDrafts(data, token) {
    return function (dispatch) {
        dispatch({
            type: MAILBOX.FETCH_MAILBOX_GET,
        });
        new MailboxApi().postDrafts(data, token).then((res) => {
            if (res?.canceled) return;
            return dispatch({
                type: MAILBOX.MAILBOX_GET_DRAFTS,
                data: res,
            });
        });
    };
}

export function getMailDraftsWithoutReload(data, callback = null) {
    return function (dispatch) {
        new MailboxApi().postDrafts(data).then((res) => {
            callback && callback();
            return dispatch({
                type: MAILBOX.MAILBOX_GET_DRAFTS,
                data: res,
            });
        });
    };
}

export function getDraftsWithPage(data, token) {
    return function (dispatch) {
        dispatch({
            type: MAILBOX.FETCH_MAILBOX_GET_WITH_PAGE,
            data,
        });
        new MailboxApi().postDrafts(data, token).then((res) => {
            if (res?.canceled) return;
            return dispatch({
                type: MAILBOX.MAILBOX_GET_DRAFTS,
                data: res,
            });
        });
    };
}

export function addRecipient(data) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.ADD_CHECKED_RECIPIENTS,
            data: data,
        });
    };
}

export function removeRecipient(data) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.REMOVE_CHECKED_RECIPIENTS,
            data: data,
        });
    };
}

export function removeAllRecipient() {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.REMOVE_ALL_CHECKED_RECIPIENTS,
        });
    };
}

export function getUnreadCount(userId) {
    return function (dispatch) {
        new MailboxApi().getUnreadCount(userId).then((res) => {
            return dispatch({
                type: MAILBOX.MAILBOX_GET_UNREAD_COUNT,
                data: res,
            });
        });
    };
}

export function getMaleList(data) {
    return function (dispatch) {
        new MailboxApi().postMales(data).then((res) => {
            return dispatch({
                type: MAILBOX.MAILBOX_GET_MALE_LIST,
                data: res,
            });
        });
    };
}

export function setSender(data) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.ADD_CHECKED_SENDER,
            data: data,
        });
    };
}

export function updateDraft(data) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.UPDATE_DRAFT_BY_ID,
            data: data,
        });
    };
}

export function hideMail(mailID) {
    return function (dispatch) {
        return dispatch({
            type: MAILBOX.CURRENT_CHAT_MAILBOX_HIDE_MAIL,
            mailID,
        });
    };
}
