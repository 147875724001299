import { useEffect } from 'react';
import * as profilesAction from '../../store/actions/profiles';
import * as TIMER from '../../constants/timer';
import { useDispatch } from 'react-redux';

export default function useCheckActiveSender() {
    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(profilesAction.checkActiveSenderInfo());
        }, TIMER.CHECK_ACTIVE_SENDER);

        return () => {
            clearInterval(timer);
        };
    }, []);
}
