import * as NOTICE from '../constants/notice';
import NoticeApi from '../../services/api/NoticeApi';

export function getNotice(payload, token) {
    return function (dispatch) {
        new NoticeApi().postRead(payload, token).then((res) => {
            if (res?.canceled) return;
            return dispatch({
                type: NOTICE.NOTICE_GET,
                data: res,
            });
        });
    };
}

export function addNotice(notice) {
    return function (dispatch) {
        return dispatch({
            type: NOTICE.NOTICE_ADD,
            notice,
        });
    };
}

export function editNotice(notice) {
    return function (dispatch) {
        return dispatch({
            type: NOTICE.NOTICE_EDIT,
            notice,
        });
    };
}

export function deleteNotice(noticeID) {
    return function (dispatch) {
        return dispatch({
            type: NOTICE.NOTICE_DELETE,
            noticeID,
        });
    };
}
