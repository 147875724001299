import React from 'react';
import SenderDrafts from './SenderDrafts';
import SenderForms from './SenderForms';
import styles from './styles.module.scss';
import useHandleSavedSenderList from '../../../hooks/sender/useHandleSavedSenderList';
import useHandleSenderForm from '../../../hooks/sender/useHandleSenderForm';

export default () => {
    const handleSavedSenderList = useHandleSavedSenderList();
    const handleSenderForm = useHandleSenderForm();

    return (
        <div className={styles.right_bar}>
            <SenderDrafts {...handleSavedSenderList} {...handleSenderForm} />
            <SenderForms {...handleSenderForm} />
        </div>
    );
};
