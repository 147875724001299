import * as PERSONAL from '../constants/personal';

const initState = {
    personalManList: [],
    personalHistoryList: [],
};

export default function (state = initState, action) {
    switch (action.type) {
        case PERSONAL.SET_PERSONAL:
            return {
                ...state,
                ...{
                    personalManList: action?.data?.newList?.length ? action?.data?.newList : [],
                    personalHistoryList: action?.data?.historyList?.length ? action?.data?.historyList : [],
                },
            };

        default:
            return state;
    }
}
