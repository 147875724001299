import * as Sentry from '@sentry/react';

/* disable errors originating from injected scripts such as Google Tag Manager */
export function isInjectedCode(event) {
    const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

    if (!frames || frames?.length === 0) return false;

    const firstFrame = frames?.[0];
    if (firstFrame?.filename === '<anonymous>') {
        return true;
    }

    const lastFrame = frames?.[frames?.length - 1];
    if (
        typeof lastFrame?.filename === 'string' &&
        (lastFrame?.filename === window?.location?.pathname ||
            (lastFrame?.filename?.startsWith(window?.location?.origin) &&
                // static file should not be considered as injected code. We use react-script currently, and all js-generated files are in this "static" directory.
                !lastFrame?.filename?.includes('/static/')))
    ) {
        return true;
    }

    const externalScripts = ['https://www.googletagmanager.com', 'https://googleads.', 'https://sgtm.'];

    const isExternalScript = frames.some((frame) => externalScripts.some((url) => frame?.filename?.startsWith(url)));

    if (isExternalScript) {
        return true;
    }

    return false;
}

export function sendErrToSentry(error, statusCode, extraData) {
    const options = {};

    if (statusCode) {
        options.tags = { status_code: statusCode.toString(), status_code_500x: 'true' };
    }

    if (extraData) {
        options.extra = extraData;
    }

    if (Sentry?.captureException) {
        Sentry.captureException(error, options);
    }
}
