import { useState } from 'react';
import { addBlur, removeBlur } from '../../services/dom/letters';
import { useDispatch, useSelector } from 'react-redux';
import MailboxApi from '../../services/api/MailboxApi';
import * as mailboxAction from '../../store/actions/mailbox';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as MENU from '../../constants/menu';
import { sendErrToSentry } from '../../services/sentry';

export default function useHandleLettersListDraft(item, currentPage, setCurrentPage) {
    const dispatch = useDispatch();
    const menuState = useSelector((state) => state.menu);
    const profilesState = useSelector((state) => state.profiles);

    const [sender, setSender] = useState({});
    const [modalWRLIsOpen, setModalWRLIsOpen] = useState(false);

    const openWriteLetterModal = () => {
        addBlur();
        setModalWRLIsOpen(true);
    };

    const closeWriteLetterModal = () => {
        removeBlur();
        setModalWRLIsOpen(false);
    };

    const deleteDraft = (callback) => {
        callback(true);
        if (menuState.letters === MENU.LETTER_FOLDER_DRAFT) {
            try {
                new MailboxApi()
                    .postDeleteDraft({
                        userId: item?.draft?.sender_id,
                        draftId: [item?.draft?.id],
                    })
                    .then((res) => {
                        if (res?.status) {
                            dispatch(
                                mailboxAction.getMailDraftsWithoutReload(
                                    {
                                        userIds: profilesState?.active?.external_id
                                            ? [profilesState?.active?.external_id]
                                            : profilesState.list.map((item) => item?.external_id),
                                        page: 1,
                                    },
                                    () => setCurrentPage(1),
                                ),
                            );
                        }
                    })
                    .finally(() => callback(false));
            } catch (error) {
                sendErrToSentry(error);
            }
        }
    };

    const openChat = () => {
        dispatch(currentChatMailboxAction.addDraftId(item?.draft?.id));

        new MailboxApi()
            .postMales({
                personFilter: '',
                userId: profilesState?.active?.external_id ? profilesState?.active?.external_id : '',
                search: item?.draft?.recipient_id,
            })
            .then((res) => {
                if (res?.status) {
                    if (res?.result[0]) {
                        dispatch(mailboxAction.removeAllRecipient());
                        dispatch(mailboxAction.addRecipient(res?.result));
                        dispatch(currentChatMailboxAction.addDraftId(item?.draft?.id));
                        setSender(profilesState.list.find((girl) => +girl?.external_id === +item?.draft?.sender_id));
                        openWriteLetterModal();
                    }
                }
            });
    };

    return {
        modalWRLIsOpen,
        sender,
        openWriteLetterModal,
        closeWriteLetterModal,
        deleteDraft,
        openChat,
    };
}
