export const STATISTIC_TYPE_LIST = [
    'SENT_TEXT',
    'SENT_MAIL',
    'SENT_IMAGE',
    'SENT_IMAGE_MAIL',
    'SENT_STICKER',
    'SENT_VIRTUAL_GIFT',
    'GET_IMAGE_MAIL',
    'SENT_AUDIO',
    'GET_AUDIO',
    'GET_AUDIO_MAIL',
    'SENT_AUDIO_MAIL',
    'GET_VIDEO',
    'SENT_VIDEO',
    'GET_VIDEO_MAIL',
    'SENT_VIDEO_MAIL',
    'MAKE_ORDER_APPROVE',
    'GET_VIDEO_SHOW',
    'GET_CONTACT_APPROVE',
    'READ_MAIL',
    'GET_AUDIO_NEW',
    'GET_VIDEO_NEW',
    'SENT_MAIL_FIRST',
    'SENT_MAIL_SECOND',
    'GET_AUDIO_MAIL_NEW',
    'GET_VIDEO_MAIL_NEW',
];

export const STATISTIC_TYPE_COLUMN = [
    'SENT_TEXT',
    'SENT_MAIL',
    'SENT_IMAGE',
    'SENT_IMAGE_MAIL',
    'SENT_STICKER',
    'SENT_VIRTUAL_GIFT',
    'GET_IMAGE_MAIL',
    'SENT_AUDIO',
    'GET_AUDIO',
    'GET_AUDIO_MAIL',
    'SENT_AUDIO_MAIL',
    'GET_VIDEO',
    'SENT_VIDEO',
    'GET_VIDEO_MAIL',
    'SENT_VIDEO_MAIL',
    'MAKE_ORDER_APPROVE',
    'GET_VIDEO_SHOW',
    'GET_CONTACT_APPROVE',
    'READ_MAIL',
];

export const STATIC_TYPE_NAME = [
    'Chats',
    'Received emails',
    'Received photos in chat',
    'Received photos in emails',
    'Sent sticker in Chat',
    'Sent photos in emails',
    'Sent audio in Chat',
    'Received audio in chat',
    'Sent audio in emails',
    'Received audio in emails',
    'Sent video in chats',
    'Received video in chats',
    'Sent video in emails',
    'Received video in emails',
    'Gifts',
    'Video Show',
    'Get contact',
    'Read mail',
];

export const NOT_IN_STATISTIC_TYPE_LIST = ['GET_CONTACT', 'GET_MEETING', 'MAKE_ORDER'];
