import React from 'react';
import { checkFile, cutLengthString, getReadableFileSizeString } from '../../../../services/methods';
import * as LENGTH from '../../../../constants/length';
import * as IMAGES from '../../Images';
import * as ATTACH from '../../../../constants/attach';
import * as loaderAction from '../../../../store/actions/loader';
import * as filesAction from '../../../../store/actions/files';
import { useDispatch } from 'react-redux';

export default ({
    closeModalVideo,
    openModalVideo,
    uploadVideo,
    setVideoFile,
    videoFile,
    handleUploadVideo,
    userId,
}) => {
    const dispatch = useDispatch();

    const onSubmitVideo = async () => {
        const videoListToUpload = videoFile
            ?.filter((item) => +item?.size <= LENGTH.MAX_VIDEO_SIZE)
            ?.filter((item) => checkFile(item, ATTACH.TYPE_VIDEO));

        closeModalVideo();
        setVideoFile({});

        if (videoListToUpload?.length) {
            dispatch(loaderAction.setActiveGifLoader(true));
            for (const item of videoListToUpload) {
                await uploadVideo({
                    file: item,
                    fileName: item?.name,
                    index: 0,
                });
            }
            dispatch(filesAction.getAllVideos(userId));
            dispatch(loaderAction.setActiveGifLoader(false));
        } else {
            openModalVideo();
        }
    };

    return (
        <div className="add_file_popup_wrap" id="add_file_popup_wrap" data-testid="upload-video-modal">
            <div className="popup_add_file_head">New video</div>
            {!!videoFile?.length && videoFile?.length > 1 ? (
                <>
                    <div className="popup_add_file_body">
                        {videoFile?.map((item, key) => (
                            <React.Fragment key={key}>
                                <div
                                    className={`popup_add_file_row uploaded ${+item?.size > LENGTH.MAX_VIDEO_SIZE || !checkFile(item, ATTACH.TYPE_VIDEO) ? 'warning' : ''}`}
                                >
                                    <span>File uploaded</span>
                                    <div className="popup_uploaded_info">
                                        {cutLengthString(item?.name, LENGTH.ATTACH_TITLE_NAME)}
                                        <span>({getReadableFileSizeString(item?.size)})</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="popup_add_file_row upload_btn">
                        <div className="popup_add_file_upload_btn" onClick={onSubmitVideo} data-testid="add-video-btn">
                            Add videos
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {videoFile?.length && (
                        <div
                            className={`popup_add_file_row uploaded ${+videoFile[0]?.size > LENGTH.MAX_VIDEO_SIZE || !checkFile(videoFile[0], ATTACH.TYPE_VIDEO) ? 'warning' : ''}`}
                        >
                            <span>File uploaded</span>
                            <div className="popup_uploaded_info">
                                {cutLengthString(videoFile[0]?.name, LENGTH.ATTACH_TITLE_NAME)}
                                <span>({getReadableFileSizeString(videoFile[0]?.size)})</span>
                            </div>
                        </div>
                    )}
                    {+videoFile[0]?.size <= LENGTH.MAX_VIDEO_SIZE && checkFile(videoFile[0], ATTACH.TYPE_VIDEO) ? (
                        <div className="popup_add_file_row add_btn">
                            <div
                                className="popup_add_file_upload_btn"
                                onClick={onSubmitVideo}
                                data-testid="add-video-btn"
                            >
                                Add video
                            </div>
                        </div>
                    ) : (
                        <div className="popup_add_file_row upload_btn">
                            <input type="file" id="popup_inp_file1" accept="video/*" onChange={handleUploadVideo} />
                            <label htmlFor="popup_inp_file1" className="popup_bottom_upload_btn">
                                <img src={IMAGES.popup_upload} alt="" />
                                <span>Upload</span>
                            </label>
                        </div>
                    )}
                </>
            )}
            <div className="popup_add_file_close" onClick={closeModalVideo} data-testid="close-btn">
                <img src={IMAGES.cross_white} alt="" />
            </div>
        </div>
    );
};
