import * as currentMailboxAction from '../../store/actions/currentChatMailbox';
import useTransition from '../transition/useTransition';
import { useDispatch, useSelector } from 'react-redux';

export default function useCloseCurrentLetter() {
    const dispatch = useDispatch();
    const transition = useTransition();
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);

    return () => {
        if (currentChatMailboxState?.info?.id) {
            const link = window.location.href
                .replace(window.location.origin, '')
                .replace(`/${currentChatMailboxState?.info?.chat_uid}`, '');
            transition.transitionPush(link);
            dispatch(currentMailboxAction.removeCurrentChatMailbox());
            return link;
        }
    };
}
