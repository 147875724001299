import React from 'react';
import * as ATTACH from '../../../../constants/attach';

export default () => {
    return (
        <div className={`upload_popup_tabs_content_item active`} id={ATTACH.GO_TO_LOADER}>
            <div className="upload_popup_tabs_content_item_top">
                <img src="/img/loader_attach.gif" alt="" className="attach_loader_gif" />
            </div>
        </div>
    );
};
