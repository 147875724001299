import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ChatPage from '../pages/ChatPage';
import WomanLettersPage from '../pages/WomanLettersPage';
import LoginPage from '../pages/LoginPage';
import SenderPage from '../pages/SenderPage';
import ManProfilePage from '../pages/ManProfilePage';
import WomanProfilePage from '../pages/WomanProfilePage';
import SenderStat from '../pages/SenderStat';
import OperatorStat from '../components/stat';
import OperatorBlock from '../pages/BlockPage';
import Search from '../pages/Search';
import ProfilesPage from '../pages/ProfilesPage';
import GiftsPage from '../pages/GiftsPage';
import OnlineManListPage from '../pages/OnlineManListPage';
import MenuMobilePage from '../pages/MenuMobilePage';
import PersonalPage from '../pages/PersonalPage';
import PostsPage from '../pages/PostsPage';
import * as MENU from '../constants/menu';
import UserRoute from './UserRoute';

export const Router = () => {
    return (
        <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path={`/${MENU.MENU_LOGIN}`} component={LoginPage} />

            <UserRoute exact path={`/${MENU.MENU_CHAT}`} component={ChatPage} />
            <UserRoute exact path={`/${MENU.MENU_CHAT}/:chat_uid`} component={ChatPage} />

            <UserRoute exact path={`/${MENU.MENU_UNANSWERED}`} component={ChatPage} />
            <UserRoute exact path={`/${MENU.MENU_UNANSWERED}/:chat_uid`} component={ChatPage} />

            <UserRoute exact path={`/${MENU.MENU_FAVORITE}`} component={ChatPage} />
            <UserRoute exact path={`/${MENU.MENU_FAVORITE}/:chat_uid`} component={ChatPage} />

            <UserRoute exact path={`/${MENU.MENU_DIALOGUES}`} component={ChatPage} />
            <UserRoute exact path={`/${MENU.MENU_DIALOGUES}/:chat_uid`} component={ChatPage} />

            <UserRoute exact path={`/${MENU.MENU_MATCHES}`} component={ChatPage} />
            <UserRoute exact path={`/${MENU.MENU_MATCHES}/:chat_uid`} component={ChatPage} />

            <UserRoute exact path={`/${MENU.MENU_CHANCE}`} component={ChatPage} />
            <UserRoute exact path={`/${MENU.MENU_CHANCE}/:chat_uid`} component={ChatPage} />

            <UserRoute exact path={`/${MENU.MENU_MAYBE}`} component={ChatPage} />
            <UserRoute exact path={`/${MENU.MENU_MAYBE}/:chat_uid`} component={ChatPage} />

            <UserRoute exact path={`/${MENU.MENU_SPECIAL}`} component={ChatPage} />
            <UserRoute exact path={`/${MENU.MENU_SPECIAL}/:chat_uid`} component={ChatPage} />

            <UserRoute exact path={`/${MENU.MENU_SENDER}`} component={SenderPage} />
            <UserRoute exact path={`/${MENU.MENU_SENDER_STATISTIC}`} component={SenderStat} />
            <UserRoute exact path={`/${MENU.MENU_PERSONAL}`} component={PersonalPage} />
            <UserRoute exact path={`/${MENU.MENU_POSTS}`} component={PostsPage} />

            <UserRoute exact path="/online-man" component={OnlineManListPage} />
            <UserRoute exact path="/letter" component={WomanLettersPage} />
            <UserRoute exact path="/letter/:letter_uid" component={WomanLettersPage} />

            <UserRoute exact path="/search" component={Search} />
            <UserRoute exact path="/operator-profiles" component={ProfilesPage} />
            <UserRoute exact path="/operator-gifts" component={GiftsPage} />
            <UserRoute exact path="/profile-man/:external_id" component={ManProfilePage} />
            <UserRoute exact path="/profile-woman/:external_id" component={WomanProfilePage} />
            <UserRoute exact path="/operator-statistic" component={OperatorStat} />
            <UserRoute exact path="/block-list" component={OperatorBlock} />
            <UserRoute exact path="/menu" component={MenuMobilePage} />
        </Switch>
    );
};
