import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import MailboxApi from '../../services/api/MailboxApi';
import { addBlur, removeBlur, scrollSmoothDownInLetterChat } from '../../services/dom/letters';
import { checkUrl, hideStringStar, objectToArray } from '../../services/methods';
import * as filesAction from '../../store/actions/files';
import * as errorsAction from '../../store/actions/alerts';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as mailboxAction from '../../store/actions/mailbox';
import * as chatsAction from '../../store/actions/chats';
import * as ERROR from '../../constants/error';
import * as REGEXP from '../../constants/regExp';
import * as LENGTH from '../../constants/length';
import * as MENU from '../../constants/menu';
import * as alertsAction from '../../store/actions/alerts';
import useDebounce from '../useDebounce';

export default function useHandleLettersForm(external_id, male_external_id) {
    const dispatch = useDispatch();

    const profilesState = useSelector((state) => state.profiles);
    const menuState = useSelector((state) => state.menu);
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const filesState = useSelector((state) => state.files);
    const mailboxState = useSelector((state) => state.mailbox);
    const getTranslateBlockOpenDefault = localStorage.getItem('isTranslateOpen') === 'true';

    const [attachModalIsOpen, setAttachModalIsOpen] = useState(false);
    const [smileModalIsOpen, setSmileModalIsOpen] = useState(false);
    const [draftId, setDraftId] = useState([]);
    const [attachData, setAttachData] = useState([]);
    const [isOpenTranslateBlock, setIsOpenTranslateBlock] = useState(getTranslateBlockOpenDefault);
    const [messageContent, setMessageContent] = useState('');
    const [isSendEmail, setIsSendEmail] = useState(false);
    const [isRealEmail, setIsRealEmail] = useState(false);
    const [isDisabledButton, setIsDisabledButton] = useState(false);
    const [textSourceTranslate, setTextSourceTranslate] = useState('');
    const [textTargetTranslate, setTextTargetTranslate] = useState('');

    const debMessageContent = useDebounce(messageContent, 500);
    const debAttachData = useDebounce(attachData, 500);

    const checkOnCyrillicLetters = (value) => (value?.length > 0 ? REGEXP.INPUT_OPERATOR.test(value) : true);
    const checkOnMaxLength = (value) => +value?.length <= LENGTH.LETTER_MAX_LENGTH;
    const checkOnMinLength = (value) => +value?.length >= LENGTH.LETTER_MIN_LENGTH;
    const femaleExternalId = external_id ? external_id : currentChatMailboxState?.info?.female_id;
    const recipientsId =
        Array.isArray(male_external_id) && male_external_id?.length
            ? male_external_id
            : [male_external_id || currentChatMailboxState?.info?.male_id];

    const openSmileModal = () => {
        addBlur();
        setSmileModalIsOpen(true);
    };

    const closeSmileModal = () => {
        removeBlur();
        setSmileModalIsOpen(false);
    };

    const openAttachModal = () => {
        addBlur();
        setAttachModalIsOpen(true);
        dispatch(filesAction.getAllImages(femaleExternalId));
        dispatch(filesAction.getAllVideos(femaleExternalId));
        dispatch(filesAction.getAllAudios(femaleExternalId));
    };

    const closeAttachModal = () => {
        removeBlur();
        setAttachModalIsOpen(false);
    };

    const handleLetterContent = (value) => {
        if (!+value?.length) {
            setTextSourceTranslate('');
            setTextTargetTranslate('');
        }

        if (checkOnCyrillicLetters(value) && checkOnMaxLength(value)) {
            setMessageContent(value);
        }
    };

    const handleTextSourceTranslate = (value) => {
        if (!+value.length) setTextTargetTranslate('');
        if (checkOnMaxLength(value)) setTextSourceTranslate(value);
    };

    const handleTextTargetTranslate = (value) => {
        if (!+value.length) setTextTargetTranslate('');

        if (checkOnMaxLength(value)) {
            setTextTargetTranslate(value);
        }
    };

    const backToInitMessageContent = () => {
        setMessageContent('');
        setTextSourceTranslate('');
        setTextTargetTranslate('');
    };

    const handleTranslateSwitcher = () => {
        if (isOpenTranslateBlock) setMessageContent(textTargetTranslate.slice(0, LENGTH.LETTER_MAX_LENGTH));
        else setTextSourceTranslate(messageContent);

        // setTextTargetTranslate('');
        setIsOpenTranslateBlock(!isOpenTranslateBlock);
    };

    const addEmoji = (emoji) => {
        if (isOpenTranslateBlock) {
            if (checkOnMaxLength(textSourceTranslate + emoji.native)) {
                setTextSourceTranslate(textSourceTranslate + emoji.native);
            }
        } else {
            if (checkOnMaxLength(messageContent + emoji.native)) {
                setMessageContent(messageContent + emoji.native);
            }
        }
    };

    const onSubmitMessage = (type, item, fromChat = false, closeWriteLetterModal) => {
        if (isDisabledButton) return false;

        let _inputData;

        if (isOpenTranslateBlock) _inputData = textTargetTranslate;
        else _inputData = messageContent;

        _inputData = hideStringStar(_inputData, REGEXP.NUMBER_IN_STRING);

        if (_inputData.trim().length === 0) {
            // backToInitMessageContent();
            return dispatch(errorsAction.setMessage('Invalid message', 'The letter must not be empty!'));
        }

        if (currentChatMailboxState?.info?.id && !+currentChatMailboxState?.info?.letter_limit)
            return dispatch(errorsAction.setMessage('', 'Message limit reached'));
        if (_inputData.includes('`'))
            return dispatch(errorsAction.setMessage(`Forbidden symbol: \``, 'Invalid message'));
        if (checkUrl(_inputData)) return dispatch(errorsAction.setMessage('Exist url', 'Invalid message'));
        if (!checkOnMinLength(_inputData.trim()))
            return dispatch(errorsAction.setMessage('', `Letter min size ${LENGTH.LETTER_MIN_LENGTH} symbols`));
        if (!checkOnCyrillicLetters(_inputData) || !checkOnMaxLength(_inputData)) {
            return dispatch(
                alertsAction.setMessage(
                    `You cannot send this message. Only Latin alphabet and the maximum number of symbols is ${LENGTH.LETTER_MAX_LENGTH}.`,
                    'Invalid message',
                ),
            );
        }

        setIsDisabledButton(true);

        switch (type) {
            case 'SEND_LETTER':
                new MailboxApi()
                    .postMail({
                        userId: femaleExternalId,
                        recipients: recipientsId,
                        messageContent: _inputData,
                        messageType: 'SENT_TEXT',
                        attachments: attachData.filter((item) => !item.hidden),
                        parentMailId: !currentChatMailboxState?.info?.openFromChat
                            ? currentChatMailboxState?.info?.id
                            : null,
                        isSendEmail,
                    })
                    .then((res) => {
                        setIsDisabledButton(false);

                        function badRequest() {
                            if (res?.error)
                                dispatch(errorsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                            else
                                dispatch(
                                    errorsAction.setMessage(
                                        'You are in male block list',
                                        ERROR.INTERNAL_ERROR_OCCURRED,
                                    ),
                                );
                        }

                        if (!res?.status) return badRequest();
                        else if (res?.status) {
                            setAttachData([]);
                            setIsSendEmail(false);
                            !fromChat &&
                                dispatch(
                                    errorsAction.setMessage(
                                        `${res?.message_id?.length} mail${res?.message_id?.length > 1 ? 's' : ''} was sent successfully!`,
                                        'Mail was sent!',
                                    ),
                                );
                            dispatch(currentChatMailboxAction.reduceLetterLeft());
                            dispatch(
                                currentChatMailboxAction.getMailboxChatHistorySendMess({
                                    userId: femaleExternalId,
                                    folder: 'dialog',
                                    manId: currentChatMailboxState.info.male_id,
                                }),
                            );
                            dispatch(
                                chatsAction.updateMessageAndLikeLimit(
                                    currentChatMailboxState?.mailChatInfo?.chat_uid,
                                    null,
                                    null,
                                    +currentChatMailboxState?.info?.letter_limit - 1,
                                ),
                            );

                            new MailboxApi()
                                .postDeleteDraft({
                                    userId: femaleExternalId,
                                    draftId: draftId,
                                })
                                .then((res) => {
                                    if (res?.status) {
                                        setDraftId([]);
                                        dispatch(mailboxAction.removeAllRecipient());
                                    }
                                });

                            if (res?.blocked_ids?.length) {
                                dispatch(errorsAction.setMessage(`ID: ${res?.blocked_ids}`, 'Letter not sent'));
                            }

                            if (closeWriteLetterModal) closeWriteLetterModal();
                            backToInitMessageContent();
                        }
                    });

                break;
            case 'SEND_DRAFT_LETTER':
                new MailboxApi()
                    .postMail({
                        userId: item.sender_id,
                        recipients: [item.recipient_id],
                        messageContent: _inputData,
                        messageType: 'SENT_TEXT',
                        attachments: attachData.filter((item) => !item.hidden),
                        isSendEmail,
                    })
                    .then((res) => {
                        setIsDisabledButton(false);

                        function badRequest() {
                            if (res?.error)
                                dispatch(errorsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                            else
                                dispatch(
                                    errorsAction.setMessage(
                                        'You are in male block list',
                                        ERROR.INTERNAL_ERROR_OCCURRED,
                                    ),
                                );
                        }

                        if (!res?.status) return badRequest();
                        if (res?.status) {
                            setAttachData([]);
                            setIsSendEmail(false);
                            dispatch(
                                errorsAction.setMessage(
                                    `${res?.message_id?.length} mail${res?.message_id?.length > 1 ? 's' : ''} was sent successfully!`,
                                    'Mail was sent!',
                                ),
                            );
                            dispatch(
                                chatsAction.updateMessageAndLikeLimit(
                                    currentChatMailboxState?.mailChatInfo?.chat_uid,
                                    null,
                                    null,
                                    +currentChatMailboxState?.info?.letter_limit - 1,
                                ),
                            );
                            if (!res?.blocked_ids?.length) {
                                new MailboxApi()
                                    .postDeleteDraft({
                                        userId: item?.sender_id,
                                        draftId: [item?.id],
                                    })
                                    .then((res) => {
                                        if (res?.status) {
                                            setDraftId([]);
                                            dispatch(mailboxAction.removeAllRecipient());
                                            if (menuState.letters === MENU.LETTER_FOLDER_DRAFT) {
                                                dispatch(
                                                    mailboxAction.getMailDraftsWithoutReload({
                                                        userIds: profilesState?.active?.external_id
                                                            ? [profilesState?.active?.external_id]
                                                            : profilesState.list.map((item) => item?.external_id),
                                                    }),
                                                );
                                            }
                                        }
                                    });
                            }
                            backToInitMessageContent();
                        }
                    });

                break;
            default:
                setIsDisabledButton(false);
                break;
        }
    };

    const onEnterMessageContent = (event, callback) => {
        if (event.key === 'Enter') {
            const value = event.target.value;
            const lines = value.split('\n');

            //Enter + Shift
            if (event.shiftKey) {
                if (lines?.length < LENGTH.MESSAGE_MAX_NUMBER_LINE) return false;
                else return event.preventDefault();
            }
            //Enter + Ctrl
            if (event.ctrlKey && isOpenTranslateBlock && callback) return callback();

            event.preventDefault();
            onSubmitMessage('SEND_LETTER', null, true);
        }
    };

    const attachFile = (e, type) => {
        e.preventDefault();
        const checkedList = e.currentTarget.parentNode.parentNode.querySelectorAll('input:checked');

        let arr = [];

        if (checkedList?.length > 0) {
            for (let i = 0; i < checkedList?.length; i++) {
                const foundVideoFile =
                    type === 'SENT_VIDEO' &&
                    filesState?.videos?.find((el) => el?.link === checkedList[i]?.dataset?.link);

                arr.push({
                    title: checkedList[i]?.dataset?.filename,
                    link: checkedList[i]?.dataset?.link,
                    message_type: type,
                    ...(foundVideoFile && { id: +foundVideoFile?.id }),
                });
            }
        }
        if (+attachData?.filter((item) => !item?.hidden)?.length + +arr?.length <= LENGTH.MAX_ATTACHMENTS_TO_LETTER) {
            setAttachData(attachData.concat(arr));
            closeAttachModal();
        } else {
            dispatch(errorsAction.setMessage('', ERROR.FILES_LIMIT));
        }
    };
    const deleteAttach = (e) => {
        const newAttach = attachData;
        newAttach.forEach((item, key) => {
            if (key === +e.currentTarget.dataset.id) {
                item.hidden = true;
                document.querySelector(`#attach-${e.currentTarget.dataset.id}`).parentNode.style.display = 'none';
            }
        });
        setAttachData([...newAttach]);
    };

    useEffect(() => {
        if (currentChatMailboxState?.draftId) {
            setDraftId([currentChatMailboxState?.draftId]);
            new MailboxApi()
                .postDrafts({
                    userIds: [femaleExternalId],
                    draftId: currentChatMailboxState?.draftId,
                })
                .then((res) => {
                    if (res?.status) {
                        const draft = objectToArray(res?.drafts)[0]?.draft;
                        const attachments = objectToArray(objectToArray(res?.drafts)[0]?.attachments);

                        if (attachments?.length) {
                            setAttachData(
                                attachments.map((attach) => ({
                                    title: attach?.title,
                                    link: attach?.link,
                                    message_type: attach?.message_type,
                                })),
                            );
                        }
                        if (draft?.message_content?.length) {
                            dispatch(currentChatMailboxAction.updateLetterLimit(+draft?.letter_limit));
                            setTextSourceTranslate(draft?.message_content);
                            setMessageContent(draft?.message_content);
                        }
                    }
                });
        }
    }, [currentChatMailboxState.draftId]);

    useEffect(() => {
        (function () {
            // if (menuState.letters === MENU.LETTER_FOLDER_DRAFT) return;

            const draftContent = hideStringStar(messageContent, REGEXP.NUMBER_IN_STRING);

            if (draftContent?.trim()?.length || attachData?.length) {
                if (draftContent?.trim()?.length > 1 && draftId?.length === 0) {
                    setDraftId([draftContent]);
                    new MailboxApi()
                        .postAddDraft({
                            userId: femaleExternalId,
                            recipients: recipientsId,
                            messageContent: draftContent,
                            attachments: attachData.filter((item) => !item.hidden),
                        })
                        .then((res) => {
                            if (res?.status) setDraftId(res?.result);
                        });
                } else {
                    const existDraftContent = mailboxState?.mailList?.some(
                        (item) => item?.draft?.message_content === draftContent,
                    );
                    if (draftContent?.length && !existDraftContent) {
                        new MailboxApi().postUpdateDraft({
                            userId: femaleExternalId,
                            draftIds: draftId,
                            messageContent: draftContent,
                            attachments: attachData.filter((item) => !item.hidden),
                        });
                    }
                }
            }
        })();
    }, [debMessageContent, debAttachData]);

    useEffect(() => {
        currentChatMailboxState.historyCurrentPage === 1 && scrollSmoothDownInLetterChat();
    }, [currentChatMailboxState.historyCurrentPage]);

    useEffect(() => {
        new MailboxApi().postAllowNotify(currentChatMailboxState.info.male_id).then((res) => {
            if (res?.status) setIsRealEmail(true);
        });
    }, []);

    return {
        isRealEmail,
        isOpenTranslateBlock,
        attachModalIsOpen,
        smileModalIsOpen,
        attachData,
        textSourceTranslate,
        textTargetTranslate,
        setTextSourceTranslate,
        setTextTargetTranslate,
        onEnterMessageContent,
        isSendEmail,
        setIsSendEmail,
        closeAttachModal,
        attachFile,
        deleteAttach,
        onSubmitMessage,
        messageContent,
        handleLetterContent,
        addEmoji,
        openAttachModal,
        setAttachModalIsOpen,
        closeSmileModal,
        openSmileModal,
        handleTextSourceTranslate,
        handleTextTargetTranslate,
        handleTranslateSwitcher,
        isDisabledButton,
        setIsDisabledButton,
        checkOnMaxLength,
    };
}
