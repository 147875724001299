export const FILES_IMG_GET = 'files_img_get';
export const FILES_VIDEO_GET = 'files_video_get';
export const FILES_AUDIO_GET = 'files_audio_get';
export const WINK_IMG = window.location.origin + '/content/chat/smiles/WINK.gif';
export const CLEAR_ALL_FILES = 'CLEAR_ALL_FILES';

export const ADD_NEW_FOLDER = 'ADD_NEW_FOLDER';
export const RENAME_FOLDER = 'RENAME_FOLDER';
// export const EDIT_FOLDER = 'EDIT_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';
// export const DELETE_FILE_ON_MOVE = 'DELETE_FILE_ON_MOVE';
