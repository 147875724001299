import React from 'react';

import MessageList from './MessageList';
import MessageForm from './MessageForm';

import useHandleChatForm from '../../../../hooks/chat-old/useHandleChatForm';

export default () => {
    const handleChatForm = useHandleChatForm(true);

    return (
        <div className="c3_chat_wrap">
            <MessageList />
            <MessageForm {...handleChatForm} />
        </div>
    );
};
