import React, { useState } from 'react';

export default ({ searchValue = '', handleSearch }) => {
    const [value, setValue] = useState(searchValue);

    const onChange = (e) => {
        const value = e.target.value;
        setValue(value);
        handleSearch && handleSearch(value);
    };

    const deleteSearch = () => {
        setValue('');
        handleSearch && handleSearch('');
    };

    return (
        <div className="c3_woman_search_head_search_wrap">
            <input
                type="text"
                className="c3_woman_search_head_search_input"
                name="searchField"
                value={value}
                onChange={onChange}
                placeholder="Search ID or name"
            />
            <button
                type="button"
                onClick={() => (value.length > 0 ? deleteSearch() : null)}
                className="c3_woman_search_head_search_btn icon_hide"
            >
                {/* value.length > 0 ?
						<img src="/img/c2-remove.svg" alt=""/> :
						<img src="/img/c2_search_btn.svg" alt=""/>
						*/}
            </button>
        </div>
    );
};
