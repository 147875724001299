import Base from './Base';
import { objectToArray } from '../methods';

export default class MailboxApi extends Base {
    postAllowNotify(recipient_id) {
        return super.post(`mailbox/allownotify`, { recipient_id }).then((res) => res?.data);
    }

    postMails(
        { userId, manSearchId, mailId, folder, manFilter, manId, letterFilter, onlineFilter, page, freeze },
        token = null,
    ) {
        return super
            .post(
                `mailbox/mails`,
                {
                    user_id: userId,
                    folder: folder,
                    men_filter: manFilter,
                    man_id: manId,
                    mail_id: mailId,
                    man_search_id: manSearchId,
                    letter_filter: letterFilter,
                    online_filter: onlineFilter,
                    page: page ? page : 1,
                    freeze: freeze,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) =>
                res?.data?.status
                    ? {
                          mails: objectToArray(res?.data?.response?.mails),
                          chat: res?.data?.response?.chat,
                          pages: res?.data?.response?.pages,
                          page: res?.data?.response?.current,
                          status: res?.data?.status,
                          ...(res?.data?.response?.disabledContentConfig
                              ? { disabledContentConfig: res?.data?.response?.disabledContentConfig }
                              : {}),
                      }
                    : {
                          response: null,
                          status: res?.data?.status,
                      },
            )
            .catch(() => ({ canceled: true }));
    }

    postDrafts({ userIds, draftId, page = 1 }, token = null) {
        return super
            .post(
                `mailbox/drafts`,
                {
                    user_ids: userIds,
                    draft_id: draftId ? draftId : '',
                    page: page ? page : 1,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => ({
                drafts: objectToArray(res?.data?.result),
                pages: res?.data?.pages,
                status: res?.data?.status,
            }))
            .catch(() => ({ canceled: true }));
    }

    getUnreadCount(userId, token = null) {
        return super
            .get(`mailbox/count?user_id=${userId ? userId : ''}`, token ? { cancelToken: token } : {})
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    postRestoreMail({ userId, mailId, outbox }) {
        return super
            .post(`mailbox/restoremail`, {
                user_id: userId,
                mail_id: mailId,
                outbox: outbox,
            })
            .then((res) => res?.data);
    }

    postTrashMail({ userId, mailId, outbox }) {
        return super
            .post(`mailbox/trashmail`, {
                user_id: userId,
                mail_id: mailId,
                outbox: outbox,
            })
            .then((res) => res?.data);
    }

    postSetBookmark({ userId, mailId }) {
        return super
            .post(`mailbox/setbookmark`, {
                user_id: userId,
                mail_id: mailId,
            })
            .then((res) => res?.data);
    }

    postUnsetBookmark({ userId, mailId }) {
        return super
            .post(`mailbox/unsetbookmark`, {
                user_id: userId,
                mail_id: mailId,
            })
            .then((res) => res?.data);
    }

    postAddDraft({ userId, recipients, messageContent, attachments }) {
        return super
            .post(`mailbox/adddraft`, {
                user_id: userId,
                recipients: recipients,
                message_content: messageContent,
                attachments: attachments,
            })
            .then((res) => res?.data);
    }

    postUpdateDraft({ userId, draftIds, recipients, messageContent, attachments }) {
        return super
            .post(`mailbox/updatedraft`, {
                user_id: userId,
                draft_ids: draftIds,
                recipients: recipients,
                message_content: messageContent,
                attachments: attachments,
            })
            .then((res) => res?.data);
    }

    postDeleteDraft({ userId, draftId }) {
        return super
            .post(`mailbox/deletedraft`, {
                user_id: userId,
                draft_ids: draftId,
            })
            .then((res) => res?.data);
    }

    postMales({ personFilter, userId, search }) {
        return super
            .post(`mailbox/males`, {
                person_filter: personFilter,
                user_id: userId,
                search: search,
            })
            .then((res) => res?.data);
    }

    postMail({ userId, recipients, messageContent, messageType, attachments, parentMailId, isSendEmail = false }) {
        return super
            .post(`mailbox/mail`, {
                user_id: userId,
                recipients: recipients,
                message_content: messageContent,
                message_type: messageType,
                attachments: attachments,
                parent_mail_id: parentMailId,
                is_send_email: isSendEmail,
            })
            .then((res) => res?.data);
    }

    postDeleteMail({ userId, mailId }) {
        return super
            .post(`mailbox/deletemail`, {
                user_id: userId,
                mail_id: mailId,
            })
            .then((res) => res?.data);
    }

    postReadMail(mailId, userId, maleId = null) {
        return super
            .post(`mailbox/readmail`, {
                user_id: userId,
                mail_id: mailId,
                male_id: maleId,
            })
            .then((res) => res?.data);
    }

    postChat({ userId, maleId }) {
        return super
            .post(`mailbox/chat`, {
                user_id: userId,
                male_id: maleId,
            })
            .then((res) => res?.data);
    }

    updateMailCache(mailId, femaleId) {
        return super.post(`mailbox/updateMailCache`, { mail_id: mailId, female_id: femaleId }).then((res) => res?.data);
    }

    createChatWithProfile(male_id, female_id) {
        return super
            .get(`operator/chatWithProfile?male_id=` + male_id + `&female_id=` + female_id)
            .then((res) => res?.data);
    }

    hideMail(mailID) {
        return super
            .post(`mailbox/hideMail`, {
                mail_id: mailID,
            })
            .then((res) => res?.data);
    }

    checkDisabledContentConfig(externalIds) {
        return super.post('mailbox/checkDisabledContentConfig', { userIds: externalIds }).then((res) => res.data);
    }
}
