import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as mailboxAction from '../../store/actions/mailbox';

export default function useEffectToGetUnreadLetters() {
    const dispatch = useDispatch();
    const profilesState = useSelector((state) => state.profiles);

    useEffect(() => {
        if (profilesState.list.length > 0) {
            dispatch(
                mailboxAction.getUnreadCount(profilesState.active.external_id ? profilesState.active.external_id : ''),
            );
        }
    }, [profilesState]);
}
