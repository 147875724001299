import * as MENU from '../../constants/menu';
import * as CHAT from '../../constants/chat';
import { useDispatch, useSelector } from 'react-redux';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import * as mailboxAction from '../../store/actions/mailbox';
import * as currentChatAction from '../../store/actions/currentChat';
import * as chatsAction from '../../store/actions/chats';
import MailboxApi from '../../services/api/MailboxApi';
import * as notifyAction from '../../store/actions/notify';
import ChatModel from '../../models/chat';
import useMakeNotificationSound from '../sound/useMakeNotificationSound';

export default function useChatSync() {
    const dispatch = useDispatch();
    const { makeSound } = useMakeNotificationSound();

    const profilesState = useSelector((state) => state.profiles);
    const mailboxState = useSelector((state) => state.mailbox);
    const menuState = useSelector((state) => state.menu);
    const letterFilterState = useSelector((state) => state.letterFilter);
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const currentChatState = useSelector((state) => state.currentChat);
    const chatsState = useSelector((state) => state.chats);

    return {
        getLetter: (message) => {
            // Add letter notification to store
            if (
                !(
                    +currentChatMailboxState?.info?.female_id === +message?.female_external_id &&
                    +currentChatMailboxState?.info?.male_id === +message?.male_external_id
                )
            ) {
                new MailboxApi()
                    .createChatWithProfile(+message?.male_external_id, +message?.female_external_id)
                    .then((res) => {
                        if (res?.status && res?.chat_uid) {
                            ChatModel.getStoreOneChatList(res?.chat_uid).then((chatRes) => {
                                if (chatRes?.chatList?.length) chatRes.chatList[0].read_status = 0;
                                makeSound();
                                dispatch(
                                    notifyAction.addPaidNotify(
                                        {
                                            ...message,
                                            ...{
                                                ...chatRes.chatList[0],
                                                notification_type: 'letter',
                                                message_type: CHAT.MESSAGE_CONTENT_TYPE_LETTER,
                                                recipient_external_id: message?.female_external_id,
                                                sender_external_id: message?.male_external_id,
                                                date_created: new Date().toUTCString(),
                                            },
                                        },
                                        profilesState?.operatorId,
                                    ),
                                );
                            });
                        }
                    });
            }

            // Add "new message" button in letter room
            if (
                +currentChatMailboxState?.info?.female_id === +message?.female_external_id &&
                +currentChatMailboxState?.info?.male_id === +message?.male_external_id
            ) {
                dispatch(
                    currentChatMailboxAction.getChatId({
                        userId: +message?.female_external_id,
                        maleId: +message?.male_external_id,
                    }),
                );
                dispatch(
                    currentChatMailboxAction.getMailboxChatHistory({
                        userId: +message?.female_external_id,
                        folder: 'dialog',
                        manId: +message?.male_external_id,
                    }),
                );
            }

            // Update letter list in "mailbox(inbox)" folder
            if (menuState?.letters === MENU.LETTER_FOLDER_INBOX && +mailboxState?.mailListPage === 1) {
                dispatch(
                    mailboxAction.getMailsWithoutReload({
                        userId: profilesState?.active?.external_id ? profilesState?.active?.external_id : '',
                        folder: menuState?.letters,
                        girlFilter: letterFilterState?.girlFilter,
                        girlId: letterFilterState?.girlId,
                        letterFilter: letterFilterState?.lettersFilter,
                        onlineFilter: letterFilterState?.onlineFilter,
                        page: 1,
                    }),
                );
            }

            // Update letter list in "mailbox(inbox)" folder
            if (menuState?.letters === MENU.LETTER_FOLDER_DRAFT) {
                dispatch(
                    mailboxAction.getMailDraftsWithoutReload({
                        userIds: profilesState?.active?.external_id
                            ? [profilesState?.active?.external_id]
                            : profilesState.list.map((item) => item?.external_id),
                    }),
                );
            }

            // Update message limit in chat room if open current chat
            if (
                +currentChatState?.info?.female_external_id === +message?.female_external_id &&
                +currentChatState?.info?.male_external_id === +message?.male_external_id
            ) {
                dispatch(currentChatAction.updateMessageLimit(+message?.message_limit, +message?.like_limit));
            }

            // Update message limit in chat list if current chat exist
            const updChat = chatsState?.list.find(
                (item) =>
                    +item?.female_external_id === +message?.female_external_id &&
                    +item?.male_external_id === +message?.male_external_id,
            );
            if (updChat?.chat_uid) {
                dispatch(
                    chatsAction.updateMessageAndLikeLimit(
                        updChat?.chat_uid,
                        +message?.message_limit,
                        +message?.like_limit,
                        +message?.letter_limit,
                    ),
                );
            }

            // Update letter limit in letter room if open current Letter
            if (
                +currentChatMailboxState?.info?.female_id === +message?.female_external_id &&
                +currentChatMailboxState?.info?.male_id === +message?.male_external_id
            ) {
                dispatch(currentChatMailboxAction.updateLetterLimit(+message?.letter_limit));
            }
        },

        readLetter: (message) => {
            if (
                +currentChatMailboxState?.info?.female_id === +message?.female_external_id &&
                +currentChatMailboxState?.info?.male_id === +message?.male_external_id &&
                message?.mailIds?.length
            ) {
                dispatch(currentChatMailboxAction.setReadStatus(message?.mailIds));
            }
        },
    };
}
