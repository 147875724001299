import Modal from 'react-modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Alert from './Alert/index';

import * as alertsAction from '../../../../store/actions/alerts';
import messErrorStyle from '../../../../styles/react-modal/mess-error.style';

export default () => {
    const dispatch = useDispatch();
    const alertsState = useSelector((state) => state.alerts);

    const [modalMessErrorIsActive, setModalMessErrorIsActive] = useState(false);

    const openMessErrorModal = () => setModalMessErrorIsActive(true);
    const closeMessErrorModal = () => {
        dispatch(alertsAction.clearMessage());
        setModalMessErrorIsActive(false);
    };

    useEffect(() => {
        if (alertsState?.type?.length) openMessErrorModal();
    }, [alertsState.type]);

    return (
        <Modal
            style={messErrorStyle}
            isOpen={modalMessErrorIsActive}
            onRequestClose={closeMessErrorModal}
            ariaHideApp={false}
        >
            <Alert
                type={alertsState.type}
                closeMessModal={closeMessErrorModal}
                text={alertsState.text}
                title={alertsState.title}
                btnName1={alertsState.btnName1}
                btnName2={alertsState.btnName2}
                successCallback={alertsState.successCallback}
            />
        </Modal>
    );
};
