export const SET_ACTIVE_SENDER_PROFILE = 'SET_ACTIVE_SENDER_PROFILE';
export const SET_ACTIVE_SENDER_TYPE = 'SET_ACTIVE_SENDER_TYPE';
export const ADD_ACTIVE_SENDER_GROUP = 'ADD_ACTIVE_SENDER_GROUP';
export const REMOVE_ACTIVE_SENDER_GROUP = 'REMOVE_ACTIVE_SENDER_GROUP';
export const SET_SENDING_TIME = 'SET_SENDING_TIME';
export const SET_SENDING_TIME_DATE = 'SET_SENDING_TIME_DATE';
export const SET_WILL_EXPIRE = 'SET_WILL_EXPIRE';
export const SET_WILL_EXPIRE_DATE = 'SET_WILL_EXPIRE_DATE';
export const ADD_ACTIVE_SENDER_PROFILE_SEND_LIST = 'ADD_ACTIVE_SENDER_PROFILE_SEND_LIST';
export const REMOVE_ACTIVE_SENDER_PROFILE_SEND_LIST = 'REMOVE_ACTIVE_SENDER_PROFILE_SEND_LIST';
export const SET_ACTIVE_SENDER_PROFILE_SEND_LIST = 'SET_ACTIVE_SENDER_PROFILE_SEND_LIST';
export const OPEN_SENDER_LIST = 'OPEN_SENDER_LIST';
export const CLOSE_SENDER_LIST = 'CLOSE_SENDER_LIST';
