import React from 'react';
import styles from './styles.module.scss';
import SenderHeader from './SenderHeader';
import SenderLeftBar from './SenderLeftBar';
import SenderRightBar from './SenderRightBar';

export default () => {
    return (
        <>
            <div className={styles.clmn_sender}>
                <SenderHeader />
                <div className={styles.sender_body}>
                    <SenderLeftBar />
                    <SenderRightBar />
                </div>
            </div>
        </>
    );
};
