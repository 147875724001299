import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import modalComposeStyle from '../../../../styles/react-modal/modalCompose.style';
import modalWriteLetter from '../../../../styles/react-modal/modalWriteLetter.style';
import styles from '../styles.module.scss';
import * as IMAGES from '../../../common/Images';
import * as mailboxAction from '../../../../store/actions/mailbox';

import ComposeModal from '../../../common/ComposeModal';
import ComposeWriteLetterModal from '../../../common/ComposeWriteLetterModal';

import { addBlur, removeBlur } from '../../../../services/dom/letters';

export default ({ sender }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalWRLIsOpen, setModalWRLIsOpen] = useState(false);
    const dispatch = useDispatch();

    const mailboxState = useSelector((state) => state.mailbox);
    const { checkedRecipients } = mailboxState;

    const openWriteLetterModal = () => {
        if (checkedRecipients.length > 0) {
            addBlur();
            setModalWRLIsOpen(true);
            setModalIsOpen(false);
        }
    };
    const closeWriteLetterModal = () => {
        removeBlur();
        setModalWRLIsOpen(false);
        dispatch(mailboxAction.removeAllRecipient());
    };
    const openComposeModal = () => {
        // addBlur();
        setModalIsOpen(true);
        setModalWRLIsOpen(false);
        // dispatch(mailboxAction.removeAllRecipient());
        // closeMobChatList();
    };
    const closeComposeModal = () => {
        removeBlur();
        setModalIsOpen(false);
        dispatch(mailboxAction.removeAllRecipient());
    };

    return (
        <>
            <div className={styles.create_letter_btn} onClick={openComposeModal} data-testid="new-letter-btn">
                <img src={IMAGES.c2_letter_white} alt="" />
                <span>Create a letter</span>
            </div>
            <Modal
                style={modalComposeStyle}
                isOpen={modalIsOpen}
                onRequestClose={closeComposeModal}
                ariaHideApp={false}
            >
                <ComposeModal closeComposeModal={closeComposeModal} openWriteLetterModal={openWriteLetterModal} />
            </Modal>
            <Modal
                style={modalWriteLetter}
                isOpen={modalWRLIsOpen}
                onRequestClose={closeWriteLetterModal}
                ariaHideApp={false}
            >
                <ComposeWriteLetterModal
                    closeWriteLetterModal={closeWriteLetterModal}
                    openComposeModal={openComposeModal}
                    sender={sender}
                />
            </Modal>
        </>
    );
};
