import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as currentChatMailboxAction from '../../store/actions/currentChatMailbox';
import { initCancelTokenSource } from '../../services/methods';
import ChatModel from '../../models/chat';
import * as loaderAction from '../../store/actions/loader';
import { sendErrToSentry } from '../../services/sentry';

export default function useEffectToOpenLettersChat(match) {
    const dispatch = useDispatch();
    const [canceledToken, setCanceledToken] = useState(null);

    useEffect(() => {
        if (match?.params?.letter_uid) {
            const source = initCancelTokenSource(canceledToken, setCanceledToken);
            try {
                const letterUid = match?.params?.letter_uid;

                dispatch(loaderAction.setActiveGifLoader(true));

                ChatModel.getStoreOneChatList(letterUid)
                    .then((res) => {
                        if (res?.chatList[0]?.id) {
                            dispatch(
                                currentChatMailboxAction.addMailboxChatInfo({
                                    ...res?.chatList[0],
                                    ...{
                                        sender_image: res?.chatList[0]?.photo_link,
                                        sender_name: res?.chatList[0]?.name,
                                        sender_age: res?.chatList[0]?.age,
                                        sender_city: res?.chatList[0]?.city,
                                        sender_country_icon: res?.chatList[0]?.country_image,
                                        sender_id: res?.chatList[0]?.id,
                                        sender_gifts: res?.chatList[0]?.gift,
                                        female_external_id: res?.chatList[0]?.female_external_id,
                                        female_id: res?.chatList[0]?.female_external_id,
                                        male_id: res?.chatList[0]?.male_external_id,
                                        openFromChat: true,
                                    },
                                }),
                            );
                            dispatch(
                                currentChatMailboxAction.getMailboxChatHistory({
                                    userId: res?.chatList[0]?.female_external_id,
                                    folder: 'dialog',
                                    manId: res?.chatList[0]?.male_external_id,
                                }),
                            );
                        }
                    })
                    .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
            } catch (error) {
                sendErrToSentry(error);
            }

            return () => {
                initCancelTokenSource(source, setCanceledToken);
            };
        }
    }, [match.params]);
}
