import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import * as format from '../../../services/helpers/format';
import styles from './styles.module.scss';
import * as IMAGES from '../../common/Images';

export default ({ searchButton, placeholder, selectDate, setSelectDate }) => {
    const [open, setOpen] = useState('none');
    const calendarDisplay = () => {
        if (open === 'none') {
            setOpen('block');
        } else {
            setOpen('none');
        }
    };

    const calendarChange = (e) => {
        setSelectDate(format.dateFormat(e));
        setOpen('none');
        searchButton(format.dateFormat(e));
    };
    return (
        <React.Fragment>
            <div className={styles.calendar_wrap}>
                <img onClick={calendarDisplay} src={IMAGES.calendar} alt="" />
                <input
                    type="text"
                    className="form-control"
                    value={selectDate}
                    onChange={calendarChange}
                    onClick={calendarDisplay}
                    placeholder={placeholder}
                />
                <div className={styles.calendar} style={{ display: open }}>
                    <Calendar minDate={new Date('2021-1-1')} onChange={calendarChange} />
                </div>
            </div>
        </React.Fragment>
    );
};
