export const MAILBOX_GET = 'MAILBOX_GET';
export const MAILBOX_GET_DRAFTS = 'MAILBOX_GET_DRAFTS';
export const MAILBOX_CHAT_ADD_INFO = 'MAILBOX_CHAT_ADD_INFO';
export const MAILBOX_CHAT_ADD_INFO_FORCE = 'MAILBOX_CHAT_ADD_INFO_FORCE';
export const MAILBOX_CHAT_ADD_SPECIAL_LETTER = 'MAILBOX_CHAT_ADD_SPECIAL_LETTER';
export const MAILBOX_CHAT_GET_HISTORY = 'MAILBOX_CHAT_GET_HISTORY';
export const MAILBOX_CHAT_GET_HISTORY_FORCE = 'MAILBOX_CHAT_GET_HISTORY_FORCE';
export const MAILBOX_CHAT_GET_HISTORY_SEND_MESS = 'MAILBOX_CHAT_GET_HISTORY_SEND_MESS';
export const MAILBOX_CHAT_GET_HISTORY_TIMER = 'MAILBOX_CHAT_GET_HISTORY_TIMER';
export const MAILBOX_ADD_DRAFT_ID = 'MAILBOX_ADD_DRAFT_ID';
export const MAILBOX_SET_LIKE = 'MAILBOX_SET_LIKE';
export const MAILBOX_SET_FAVORITE = 'MAILBOX_SET_FAVORITE';
export const FETCH_MAILBOX_GET = 'FETCH_MAILBOX_GET';
export const FETCH_MAILBOX_GET_WITH_PAGE = 'FETCH_MAILBOX_GET_WITH_PAGE';
export const MAILBOX_GET_ADD_HISTORY_PAGE = 'MAILBOX_GET_ADD_HISTORY_PAGE';
export const MAILBOX_GET_DRAFTS_WITH_PAGE = 'MAILBOX_GET_DRAFTS_WITH_PAGE';
export const MAILBOX_CHAT_GET_HISTORY_PAGE = 'MAILBOX_CHAT_GET_HISTORY_PAGE';
export const MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_UP = 'MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_UP';
export const MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_DOWN = 'MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_DOWN';
export const SET_IS_NEW_MESS = 'SET_IS_NEW_MESS';
export const MAILBOX_GET_UNREAD_COUNT = 'MAILBOX_GET_UNREAD_COUNT';
export const MAILBOX_GET_MALE_LIST = 'MAILBOX_GET_MALE_LIST';
export const ADD_CHECKED_RECIPIENTS = 'ADD_CHECKED_RECIPIENTS';
export const REMOVE_CHECKED_RECIPIENTS = 'REMOVE_CHECKED_RECIPIENTS';
export const REMOVE_ALL_CHECKED_RECIPIENTS = 'REMOVE_ALL_CHECKED_RECIPIENTS';
export const ADD_CHECKED_SENDER = 'ADD_CHECKED_SENDER';
export const GET_CHAT_ID = 'GET_CHAT_ID';
export const MAILBOX_SET_READ_LETTER = 'MAILBOX_SET_READ_LETTER';
export const SET_INFO_CURRENT_TARGET = 'SET_INFO_CURRENT_TARGET';
export const SET_WINK = 'SET_WINK';
export const UPDATE_DRAFT_BY_ID = 'UPDATE_DRAFT_BY_ID';
export const REMOVE_CURRENT_MAILBOX = 'REMOVE_CURRENT_MAILBOX';
export const UPDATE_CURRENT_CHAT_MAILBOX_LETTER_LIMIT = 'UPDATE_CURRENT_CHAT_MAILBOX_LETTER_LIMIT';
export const CURRENT_CHAT_MAILBOX_SET_FAVORITE = 'CURRENT_CHAT_MAILBOX_SET_FAVORITE';
export const REDUCE_LETTER_LEFT = 'REDUCE_LETTER_LEFT';
export const OPEN_LETTER_FILTERS = 'OPEN_LETTER_FILTERS';
export const CLOSE_LETTER_FILTERS = 'CLOSE_LETTER_FILTERS';
export const CURRENT_CHAT_MAILBOX_HIDE_MAIL = 'CURRENT_CHAT_MAILBOX_HIDE_MAIL';

// Block user
export const MAILBOX_UNBLOCK_MAN = 'MAILBOX_UNBLOCK_MAN';
