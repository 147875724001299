import * as TEMPLATES from '../constants/chanceTemplate';

const initState = {
    templateSettings: {
        canText: 1,
        canTemplate: 0,
    },
    womanTemplates: [],
};

export default function (state = initState, action) {
    switch (action.type) {
        case TEMPLATES.ADD_OPERATOR_SETTINGS:
            return {
                ...state,
                templateSettings: action.templateSettings,
            };
        case TEMPLATES.ADD_WOMAN_TEMPLATES:
            return {
                ...state,
                womanTemplates: action.womanTemplates,
            };
        default:
            return state;
    }
}
