import React from 'react';
import * as IMAGES from '../../Images';

export default ({ item, closeModal }) => {
    return (
        <>
            <div className="popup_attach_photo_click_img_wrap">
                <div className={`popup_attach_photo_click_img`}>
                    <iframe src={item} title={'Iframe'} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                </div>
            </div>
            <div className="pap-btn-close" onClick={closeModal}>
                <img src={IMAGES.cross_white} alt="" />
            </div>
        </>
    );
};
