import * as SYNC from '../constants/sync';
import * as CONF from '../../constants/sync';

import syncService from '../../services/sync';

export function init(providerActive) {
    return function (dispatch) {
        return dispatch({
            type: SYNC.INIT,
            data: syncService(providerActive ?? CONF.PROVIDER_ACTIVE),
        });
    };
}

export function remove() {
    return function (dispatch) {
        return dispatch({
            type: SYNC.REMOVE,
            data: null,
        });
    };
}
