export default {
    overlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        zIndex: 99999,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(85,85,85,0.0)',
    },
    content: {
        zIndex: '99999',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        maxWidth: '100% !important',
        maxHidth: '100% !important',
        overflow: 'visible !important',
        background: 'rgba(255, 255, 255, 0.0)',
		border: 'none',
    }
};
