import Base from './Base';
// import axios from "axios";

export default class SenderApi extends Base {
    constructor(base) {
        super(base);
        this.url = '/api/v3';
    }

    getSenderStatistics(date_from, date_to) {
        return super
            .get(
                `operatorstatistic/sender?date_from=${date_from}&date_to=${date_to}&operator_id=${localStorage.getItem('operator')}`,
            )
            .then((res) => res?.data)
            .catch(() => []);
    }

    getProfileAction(date_from, date_to) {
        return super
            .get(`operatorstatistic/profileAction?date_from=${date_from}&date_to=${date_to}`)
            .then((res) => res?.data)
            .catch(() => null);
    }

    getTypesStatistic(token = null) {
        return super
            .get(`operstat/getTypes`, token ? { cancelToken: token } : {})
            .then((res) => res?.data)
            .catch(() => null);
    }

    getOperatorStatistic(date_from, date_to, token = null) {
        return super
            .get(
                `operstat/profileAction?date_from=${date_from}&date_to=${date_to}`,
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }
}
