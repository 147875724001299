import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
    getMenBySearch,
    nextPage,
    clearConfigSearch,
    setMultipleFilters,
    setSearchFilter,
} from '../../store/actions/search';
import { getRefferencies } from '../../store/actions/user';
import MaleCard from '../../components/MaleCard';
// import SearchFilters from "./SearchFilters";
import SearchFilters from './SearchToolBar';
import SearchInput from '../../components/SearchInput';
import '../../styles/woman-search.scss';
import '../../styles/ws-select.scss';

export default function Search() {
    const dispatch = useDispatch();
    const searchList = useSelector((state) => state.search);
    const [openFilter, toggleOpenFilter] = useState(false);

    const initStateFormDataLooking = {
        girls_age_from: null,
        girls_age_to: null,
        girls_height_from: null,
        girls_height_to: null,
        body_id: null,
        eye_id: null,
        religion_id: null,
        children_id: null,
        morechildren_id: null,
        alcohol_id: null,
        smoke_id: null,
        education_id: null,
        occupation_id: null,
    };
    const initStateFormDataMainProfile = {
        name: null,
        country: null,
        date: null,
        zodiac: null,
        hobby: null,
        aboutMatch: null,
        photos: null,
        city_id: null,
        height_id: null,
        body_id: null,
        eye_id: null,
        hair_id: null,
        marital_id: null,
        children_id: null,
        morechildren_id: null,
        religion_id: null,
        smoke_id: null,
        alcohol_id: null,
        education_id: null,
        occupation_id: null,
    };

    const [formDataMainProfile] = useState(initStateFormDataMainProfile);
    const [formDataLooking, setFormDataLooking] = useState(initStateFormDataLooking);
    const refferencies = useSelector((state) => state.profiles.refferencies);
    const { list, filter, page, loading } = searchList;
    const [storeDropbox, setStoreDropbox] = useState({
        // AGE_FROM: {selected: false, value: null, id: null},
        // AGE_TO: {selected: false, value: null, id: null},
        ONLINE: { value: false, id: false },
    });
    /*
	const spliceFilter = _.pickBy(filter,function(value, key){
		return !['AGE_FROM', 'AGE_TO', 'NAME', 'ONLINE'].includes(key)
	}, 'NAME');

	const selectedFilters = Object.values(spliceFilter).filter(item => !!item).length;
	*/

    useEffect(() => {
        dispatch(getRefferencies());
        return () => {
            dispatch(clearConfigSearch());
        };
    }, []);

    useEffect(() => {
        dispatch(getMenBySearch(filter, page));
    }, [filter, page]);

    const onScrollWrapper = (e) => {
        const getNextPage = e.target.scrollTop > e.target.scrollHeight - e.target.offsetHeight - 200;
        if (getNextPage && !loading) {
            dispatch(nextPage());
        }
    };

    const toggleOpenBar = () => {
        toggleOpenFilter(!openFilter);
    };

    const submitButton = () => {
        toggleOpenBar();
        submitSearch();
    };

    const handleChangeOnLine = (value) => {
        setStoreDropbox((prevState) => ({
            ...prevState,
            ONLINE: {
                value,
                id: value,
            },
        }));
        dispatch(setSearchFilter('ONLINE', value));
    };

    const submitSearch = () => {
        const result = {};

        [
            'ONLINE',
            'BODY',
            'EYE',
            'HAIR',
            'HEIGHT_FROM',
            'HEIGHT_TO',
            'STATUS',
            'CHILDREN',
            'ALCOHOL',
            'EDUCATION',
            'MORECHILDREN',
            'SMOKING',
        ].forEach((item) => {
            result[item] = formDataLooking[item] ? formDataLooking[item]['id'] : null;
        });

        // result['AGE_FROM'] = storeDropbox['AGE_FROM'] ? storeDropbox['AGE_FROM'].value : null;
        // result['AGE_TO'] = storeDropbox['AGE_TO'] ? storeDropbox['AGE_TO'].value : null;
        // result['NAME'] = storeDropbox['NAME'] ? storeDropbox['NAME'].value : null;
        // result['ONLINE'] = storeDropbox['ONLINE'] ? storeDropbox['ONLINE'].value : null;

        dispatch(setMultipleFilters(result));
    };

    const setFilter = _.debounce(function (name, value) {
        dispatch(setSearchFilter(name, value));
    }, 500);

    return (
        <>
            <div className="column-3">
                <div className="c3_woman_search_wrap" onScroll={onScrollWrapper}>
                    <div className="c3_woman_search_head">
                        <div className="c3_woman_search_head_range"></div>
                        {/* <div className="c3_woman_search_head_range">
							<span className="c3_woman_search_head_range_text">Man’s age range</span>
							<div className="c3_woman_search_head_range_select_wrap">
								<div className="cl2h_tab_wide_filter_item_wrap">
									<div className="cl2h_tab_wide_filter_item">
										<div className="like_select_head">From</div>
										<div className="like_select_list" style="display: none;">
											<input type="radio" name="like_sel-5" id="main-reg-1" className="like_select" checked="">
												<label htmlFor="like_sel-13" className="like_select_label" type="button">01</label>
												<input type="radio" name="like_sel-5" id="main-reg-2" className="like_select">
													<label htmlFor="like_sel-14" className="like_select_label">02</label>
													<input type="radio" name="like_sel-5" id="main-reg-3" className="like_select">
														<label htmlFor="like_sel-15" className="like_select_label">03</label>
										</div>
									</div>
								</div>
							</div>
							<div className="c3_woman_search_head_range_select_wrap">
								<div className="cl2h_tab_wide_filter_item_wrap">
									<div className="cl2h_tab_wide_filter_item">
										<div className="like_select_head">To</div>
										<div className="like_select_list" style="display: none;">
											<input type="radio" name="like_sel-5" id="main-reg-1" className="like_select" checked="">
												<label htmlFor="like_sel-13" className="like_select_label" type="button">01</label>
												<input type="radio" name="like_sel-5" id="main-reg-2" className="like_select">
													<label htmlFor="like_sel-14" className="like_select_label">02</label>
													<input type="radio" name="like_sel-5" id="main-reg-3" className="like_select">
														<label htmlFor="like_sel-15" className="like_select_label">03</label>
										</div>
									</div>
								</div>
							</div>
							<div className="c3_woman_search_head_range_confirm_btn">
								<img src="img/check-blue.svg" alt="">
							</div>
						</div>  */}

                        <div
                            className={`c3_woman_search_head_filter_btn ${openFilter ? 'active' : ''}`}
                            onClick={toggleOpenBar}
                        >
                            <span>Detailed filter</span>
                            {/* <b>{selectedFilters}</b>  */}
                        </div>

                        {/*
						<div className="c3_woman_search_head_search_wrap">
							<input type="text" className="c3_woman_search_head_search_input" placeholder="Search ID or name" />
							<button className="c3_woman_search_head_search_btn"></button>
						</div>
						*/}
                        <div className="c3lh_search_filter_item_online_status_inp">
                            <div className="c3lh_search_filter_item_online_status_inp_text">Man online</div>
                            <input
                                type="checkbox"
                                id="csfiosi_1"
                                checked={storeDropbox['ONLINE'].value}
                                onChange={() => handleChangeOnLine(!storeDropbox['ONLINE'].value)}
                            />
                            <label htmlFor="csfiosi_1" />
                        </div>
                        <SearchInput handleSearch={(value) => setFilter('NAME', value)} />
                    </div>
                    <div className="c3_woman_search_list_wrap">
                        {openFilter && (
                            <div className="c3_woman_search_filters_list">
                                <SearchFilters
                                    toggleOpenBar={toggleOpenBar}
                                    formDataLooking={formDataLooking}
                                    setFormDataLooking={setFormDataLooking}
                                    heightList={refferencies['height_list']}
                                    bodyList={refferencies['body_list']}
                                    hairList={refferencies['hair_list']}
                                    eyeList={refferencies['eye_list']}
                                    maritalList={refferencies['marital_list']}
                                    alcoholList={refferencies['alcohol_list']}
                                    smokeList={refferencies['smoke_list']}
                                    educationList={refferencies['education_list']}
                                    childrenList={refferencies['children_list']}
                                    moreChildrenList={refferencies['morechildren_list']}
                                    formDataMainProfile={formDataMainProfile}
                                />
                                <div className="c3_woman_search_filters_show_btn" onClick={submitButton}>
                                    <span>Show matches</span>
                                </div>
                                <div className="c3_woman_search_filters_matches_text"></div>
                            </div>
                        )}

                        <div className="c3_woman_search_list_block">
                            {list.map((item, key) => (
                                <MaleCard key={key} {...item} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
