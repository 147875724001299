import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useGetChatListDebounce from './useGetChatListDebounce';

export default function useEffectToLoadChatList() {
    const profilesState = useSelector((state) => state.profiles);
    const chatsState = useSelector((state) => state.chats);
    const { debouncedGetChatList, debounceTimeoutRef } = useGetChatListDebounce();

    useEffect(() => {
        debouncedGetChatList();

        return () => {
            clearTimeout(debounceTimeoutRef.current);
        };
    }, [
        chatsState?.filterOnline,
        chatsState?.filterSearch,
        chatsState?.filterFreeze,
        chatsState?.limits,
        profilesState?.active,
    ]);
}
