export default {
    alpha: {
        ID: 1,
        key: 'alpha',
        brand: 'Alpha Date',
        name: 'AlphaDate',
    },
    olymp: {
        ID: 2,
        key: 'olymp',
        brand: 'Olymp Date',
        name: 'OlympDate',
    },
}[process.env.REACT_APP_SITE];
