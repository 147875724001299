import React from 'react';
import * as modalsAction from '../../../../store/actions/modals';
import styles from '../styles.module.scss';
import { makeCDN } from '../../../../services/methods';
import { useDispatch } from 'react-redux';
import * as IMAGES from '../../Images';

export default ({ message_content, message_popup, payed }) => {
    const dispatch = useDispatch();

    const openContentModal = () => {
        dispatch(
            modalsAction.openPhotoContentModal({
                link: message_popup,
                attachment_payed: '1',
            }),
        );
    };

    return (
        <div className={`${styles.media_list_item} ${styles.photo}`}>
            {!+payed && (
                <div className={styles.media_list_item_unpaid_wrap}>
                    <div className={styles.media_list_item_unpaid_main}>
                        <img src={IMAGES.not_viewed_grey} alt="" />
                        <span>Unviewed</span>
                    </div>
                </div>
            )}
            <img
                alt=""
                src={makeCDN(message_content, 'IMAGE')}
                className={styles.media_list_item_img}
                onClick={openContentModal}
            />
        </div>
    );
};
