import React from 'react';

import PrimaryButtonWithSVG from '../../common/buttons/PrimaryButtonWithSVG';

import { getDataProfile } from '../../../services/time';
import * as MENU from '../../../constants/menu';
import { displayDaysUntilBirthdayOnProfilePage } from '../../../services/time/DateUtils';
import * as IMAGES from '../../common/Images';

export default function MainProfile({ girlProfileInfo, anchorTo }) {
    return (
        <div className="c3_woman_profile_main_block" data-testid="woman-profile-main-block">
            <div className="c3_woman_profile_main_block_photo" data-testid="woman-main-photo">
                <img
                    src={girlProfileInfo?.search_img ? girlProfileInfo.search_img : girlProfileInfo.photo_link}
                    alt=""
                />
            </div>
            <div className="c3_woman_profile_main_block_info">
                <div className="c3_woman_profile_main_block_top">
                    <div className="c3_woman_profile_main_block_name" data-testid="woman-name-age">
                        {`${girlProfileInfo.name}, ${girlProfileInfo.age}`}
                    </div>
                    <div className="c3_woman_profile_main_block_id" data-testid="woman-id">
                        {`ID ${girlProfileInfo.external_id}`}
                    </div>
                    <div className="c3_woman_profile_main_block_dob" data-testid="date-birthday">
                        {getDataProfile(
                            `${girlProfileInfo.year_birth}-${girlProfileInfo.month_birth < 10 ? '0' + girlProfileInfo.month_birth : girlProfileInfo.month_birth}-${girlProfileInfo.day_birth < 10 ? '0' + girlProfileInfo.day_birth : girlProfileInfo.day_birth}`,
                        )}
                        <span>
                            {displayDaysUntilBirthdayOnProfilePage(
                                girlProfileInfo.month_birth,
                                girlProfileInfo.day_birth,
                            )}
                        </span>
                    </div>
                    <div className="c3_woman_profile_main_block_place" data-testid="woman-place">
                        <img src={girlProfileInfo.country_icon} alt="" />
                        <span>{`${girlProfileInfo.city_name}, ${girlProfileInfo.country_name}`}</span>
                    </div>
                </div>
                <div className="c3_woman_profile_main_block_list">
                    <div className="c3_woman_profile_main_block_list_title" data-testid="appearance-block">
                        Appearance
                    </div>
                    {girlProfileInfo.height_sm && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Height: <span>{girlProfileInfo.height_sm} Cm</span>
                        </div>
                    )}
                    {girlProfileInfo.body_type && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Body type: <span>{girlProfileInfo.body_type}</span>
                        </div>
                    )}
                    {girlProfileInfo.hair_color && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Hair color: <span>{girlProfileInfo.hair_color}</span>
                        </div>
                    )}
                    {girlProfileInfo.eye_color && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Eyes color: <span>{girlProfileInfo.eye_color}</span>
                        </div>
                    )}
                    {girlProfileInfo.zodiac_sign && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Zodiac: <span>{girlProfileInfo.zodiac_sign}</span>
                        </div>
                    )}
                </div>
                <div className="c3_woman_profile_main_block_list" data-testid="social-information-block">
                    <div className="c3_woman_profile_main_block_list_title">Social information</div>
                    {girlProfileInfo.education && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Education: <span>{girlProfileInfo.education}</span>
                        </div>
                    )}
                    {girlProfileInfo.occupation && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Job or Profession: <span>{girlProfileInfo.occupation}</span>
                        </div>
                    )}
                    {girlProfileInfo.religion && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Religion: <span>{girlProfileInfo.religion}</span>
                        </div>
                    )}
                    {girlProfileInfo.marital && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Marital status: <span>{girlProfileInfo.marital}</span>
                        </div>
                    )}
                    {girlProfileInfo.children && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Number of children: <span>{girlProfileInfo.children}</span>
                        </div>
                    )}
                    {girlProfileInfo.morechildren && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Want to have more children: <span> {girlProfileInfo.morechildren}</span>
                        </div>
                    )}
                    {girlProfileInfo.reason && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Purpose of staying on the site: <span>{girlProfileInfo.reason}</span>
                        </div>
                    )}
                    {girlProfileInfo.hobbies.length > 0 && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Hobby: <span>{girlProfileInfo.hobbies.join(', ')}</span>
                        </div>
                    )}
                    {girlProfileInfo.alcohol && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Alcohol: <span>{girlProfileInfo.alcohol}</span>
                        </div>
                    )}
                    {girlProfileInfo.smoking && (
                        <div className="c3_woman_profile_main_block_list_item">
                            Smoking: <span>{girlProfileInfo.smoking}</span>
                        </div>
                    )}
                </div>
                <div className="c3_woman_profile_main_block_buttons">
                    <PrimaryButtonWithSVG
                        onPressButton={() => anchorTo(MENU.ANCHOR_PHOTO)}
                        titleButton={'Go to photo'}
                        svgButton={IMAGES.c3wp_tophoto}
                        dataTestId={'go-to-photo'}
                    />
                    <PrimaryButtonWithSVG
                        onPressButton={() => anchorTo(MENU.ANCHOR_VIDEO)}
                        titleButton={'Go to video'}
                        svgButton={IMAGES.c3wp_tovideo}
                        dataTestId={'go-to-video'}
                    />
                </div>
            </div>
        </div>
    );
}
