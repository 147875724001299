import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import ButtonCreateLetter from './ButtonCreateLetter';

import useEffectToGetUnreadLetters from '../../../hooks/letters/useEffectToGetUnreadLetters';
import useHandleLettersFilters from '../../../hooks/letters/useHandleLettersFilters';
import LetterFolders from './LetterFolders';
import Filters from './Filters';
import { closeNotifyList } from '../../../services/dom';
import * as filterActions from '../../../store/actions/currentChatMailbox';

export default () => {
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const mailboxState = useSelector((state) => state.mailbox);
    const menuState = useSelector((state) => state.menu);
    const profiles = useSelector((state) => state.profiles);
    const isMailBox = menuState.letters === 'inbox';
    const dispatch = useDispatch();

    const handleLettersFilters = useHandleLettersFilters();
    useEffectToGetUnreadLetters();

    const closeLetterFilters = () => {
        closeNotifyList();
        dispatch(filterActions.closeLetterFilters());
    };

    return (
        <div
            className={`${styles.clmn_2_letters} ${currentChatMailboxState?.isOpenLetterFilter ? styles.active : ''}`}
            data-testid="letter-filter-block"
        >
            <div className={styles.clmn_2_letters_scroll}>
                {profiles?.active?.external_id && <ButtonCreateLetter sender={profiles?.active} />}
                <LetterFolders
                    {...handleLettersFilters}
                    activeFolderName={menuState.letters}
                    countUnread={mailboxState.countUnread}
                />
                {currentChatMailboxState.info.id || !isMailBox ? <div /> : <Filters {...handleLettersFilters} />}
            </div>
            <div className={styles.clmn_2_letters_close} onClick={closeLetterFilters} />
        </div>
    );
};
