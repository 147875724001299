import React from 'react';
import Modal from 'react-modal';
import messErrorStyle from '../../../../styles/react-modal/modalContent.style';
import AddToFolder from './AddToFolder';

export default ({ isAddToFolderModal, closeAddToFolderModal, folderList, saveFileToFolder, activeFolder }) => {
    return (
        <Modal
            style={messErrorStyle}
            isOpen={isAddToFolderModal}
            onRequestClose={closeAddToFolderModal}
            ariaHideApp={false}
        >
            <AddToFolder
                closeAddToFolderModal={closeAddToFolderModal}
                folderList={folderList?.filter((el) => el?.id !== 0)}
                saveFileToFolder={saveFileToFolder}
                activeFolder={activeFolder}
            />
        </Modal>
    );
};
