import { useState } from 'react';
import PostsApi from '../../services/api/PostsApi';
import * as POSTS from '../../constants/posts';
import * as alertAction from '../../store/actions/alerts';
import * as LENGTH from '../../constants/length';
import * as postsAction from '../../store/actions/posts';
import { useDispatch } from 'react-redux';
import { sendErrToSentry } from '../../services/sentry';

export default function useViewPostModal() {
    const dispatch = useDispatch();

    const [isViewModal, setIsViewModal] = useState(false);
    const [selectedPostData, setSelectedPostData] = useState({}); //Data when open viewModal

    const [comments, setComments] = useState([]); //all comments to the post
    const [newComment, setNewComment] = useState('');
    const [parentComment, setParentComment] = useState({}); //Selected comment to reply
    const [repliesData, setRepliesData] = useState({}); //{[commentId]: {}}
    const [moderationResponse, setModerationResponse] = useState([]);
    const [commentPage, setCommentPage] = useState(0);
    const [commentPageAll, setCommentPageAll] = useState(0);
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const openViewModal = (postData) => {
        if (!postData?.id) return;

        getPostComments(postData?.id);
        getHistoryPost(postData?.id, postData?.status);
        setSelectedPostData(postData);
        setIsViewModal(true);
    };

    const openViewModalById = (postId) => {
        if (!postId) return;
        getCurrentPost(postId);
        setIsViewModal(true);
    };

    const closeViewModal = () => {
        setIsViewModal(false);
        setSelectedPostData({});
        setComments([]);
        setParentComment({});
        setNewComment('');
        setRepliesData({});
        setModerationResponse([]);
        setCommentPage(0);
        setCommentPageAll(0);
    };

    const getHistoryPost = (postId, status) => {
        if (!postId || !status) return false;
        if (status === POSTS.STATUS_DECLINE || status === POSTS.STATUS_REWORK) {
            new PostsApi().getHistoryPost(postId).then((res) => {
                if (res?.status) {
                    setModerationResponse(res?.data);
                } else {
                    dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                }
            });
        }
    };

    const getPostComments = async (postId, activePage = commentPage + 1, isDefault = false) => {
        try {
            await new PostsApi()
                .getAllComments({
                    postId,
                    page: activePage,
                    count: POSTS.COMMENT_COUNT,
                })
                .then((res) => {
                    if (res?.status) {
                        const resComments = res?.data?.comments?.length ? res.data.comments : [];
                        const newComments = isDefault ? resComments : [...comments, ...resComments];
                        setComments(newComments);
                        setCommentPage(res?.data?.page);
                        setCommentPageAll(res?.data?.allPages);
                    } else {
                        dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                    }
                });
        } catch (error) {
            sendErrToSentry(error);
        }
    };

    const handleComment = (value) => {
        setNewComment(value);

        const commentTextarea = document.getElementById('comment-textarea');
        commentTextarea.style.height = 'auto';
        commentTextarea.style.height = commentTextarea.scrollHeight + 'px';
        commentTextarea.scrollTop = commentTextarea.scrollHeight;
    };

    const addEmojiComment = (emoji) => {
        const newValue = `${newComment}${emoji.native}`;

        if (newValue?.length <= POSTS.COMMENT_MAX_LENGTH) {
            handleComment(newValue);
        }
    };

    const sendComment = () => {
        if (isLoadingRequest) return false;
        if (!newComment?.trim()?.length) {
            return dispatch(alertAction.setMessage('', 'The comment field must not be empty'));
        }
        setIsLoadingRequest(true);

        new PostsApi()
            .createComment({
                postId: selectedPostData?.id,
                text: newComment,
                parentId: parentComment?.id,
            })
            .then((res) => {
                if (res?.status) {
                    const updatedComments = comments.map((el) =>
                        el?.id === parentComment?.id
                            ? {
                                  ...el,
                                  countReplies: +el?.countReplies + 1,
                              }
                            : el,
                    );
                    setNewComment('');
                    setParentComment({});
                    setComments(updatedComments);
                    getCurrentPost(selectedPostData?.id);
                    if (parentComment?.id) onGetAllReplies(parentComment?.id);
                } else {
                    dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
                }

                setIsLoadingRequest(false);
            });
    };

    const onEnterSendComment = (event) => {
        if (event.key === 'Enter') {
            const value = event.target.value;
            const lines = value.split('\n');

            //Enter + Shift
            if (event.shiftKey) {
                if (lines?.length < LENGTH.MESSAGE_MAX_NUMBER_LINE) return false;
                else return event.preventDefault();
            }

            event.preventDefault();
            sendComment();
        }
    };

    const onDeleteComment = (commentId, parentCommentId) => {
        new PostsApi().deleteComment(commentId).then((res) => {
            if (res?.status) {
                if (parentCommentId) {
                    onGetAllReplies(parentCommentId);
                } else {
                    //Clear parentComment if deleted comment selected to reply
                    if (parentComment?.id === commentId) setParentComment({});
                    getPostComments(selectedPostData?.id, 1, true);
                }

                getCurrentPost(selectedPostData?.id);
            } else {
                dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
            }
        });
    };

    const onSetParentComment = (comment) => {
        setParentComment(comment);
    };

    // Update selected post
    const getCurrentPost = (postId) => {
        if (!postId) return false;

        new PostsApi().getCurrentPost(postId).then((res) => {
            if (res?.status && res?.data) {
                dispatch(postsAction.updateCurrentPost(res?.data));
                setSelectedPostData(res?.data);
            } else {
                dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
            }
        });
    };

    // We get replies to the selected comment
    const onGetAllReplies = (commentId) => {
        if (!commentId) return false;

        new PostsApi().getAllReplies({ commentId }).then((res) => {
            if (res?.status) {
                setRepliesData({ ...repliesData, [commentId]: res?.data ?? [] });
            } else {
                dispatch(alertAction.setMessage('', res?.error || 'Something went wrong!'));
            }
        });
    };

    const handleScrollComment = async (e) => {
        if (!e.currentTarget) return false;
        const commentList = e.currentTarget;
        const scrollHeight = commentList?.scrollHeight;
        const currentHeight = Math.round(commentList?.scrollTop + commentList?.offsetHeight);

        if (currentHeight + 50 >= scrollHeight && commentPageAll > commentPage) {
            if (isLoadingRequest) return false;
            setIsLoadingRequest(true);
            await getPostComments(selectedPostData?.id, commentPage + 1);
            setIsLoadingRequest(false);
        }
    };

    return {
        isViewModal,
        openViewModal,
        openViewModalById,
        closeViewModal,
        selectedPostData,
        comments,
        newComment,
        parentComment,
        repliesData,
        onGetAllReplies,
        handleComment,
        moderationResponse,
        handleScrollComment,
        addEmojiComment,
        sendComment,
        onEnterSendComment,
        onDeleteComment,
        onSetParentComment,
    };
}
