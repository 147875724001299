import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as noticeAction from '../../store/actions/notice';

export default function useEffectToLoadNotice() {
    const dispatch = useDispatch();
    const currentChatState = useSelector((state) => state.currentChat);
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);

    useEffect(() => {
        let noticeData = null;

        if (currentChatState?.info?.chat_uid) noticeData = currentChatState?.info;
        else if (currentChatMailboxState?.info?.chat_uid) noticeData = currentChatMailboxState?.info;

        if (noticeData?.chat_uid)
            dispatch(
                noticeAction.getNotice({
                    maleId: noticeData?.male_external_id,
                    femaleId: noticeData?.female_external_id,
                    chatId: noticeData?.chat_uid,
                }),
            );
    }, [currentChatState?.info?.id, currentChatMailboxState?.info?.id]);
}
