import React from 'react';
import Styles from './styles.module.scss';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

export default function SenderEmoji({ addEmoji, isSmileBlock, openSmileBlock, closeSmileBlock, smileBoxRef }) {
    const onSmileWrapActive = () => (isSmileBlock ? closeSmileBlock() : openSmileBlock());

    return (
        <div className={Styles.c3_smile_btn_wrap}>
            <div
                className={`${Styles.c3_smile_btn_wrap2} ${Styles.smiles} ${isSmileBlock ? Styles.active : ''}`}
                onClick={onSmileWrapActive}
                ref={smileBoxRef}
            >
                <div
                    className={Styles.c3_smiles_list_wrap}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div className={Styles.c3_smiles_list}>
                        <Picker
                            data={data}
                            onEmojiSelect={addEmoji}
                            emojiSize={32}
                            theme="light"
                            navPosition="none"
                            previewPosition="none"
                            searchPosition="none"
                        />
                    </div>
                </div>
            </div>
            {/*<div className={`${Styles.c3_sticker_wrap} ${(isStickerBlock || innerStickerList) ? Styles.active : ''}`}*/}
            {/*	 ref={stickerBoxRef}*/}
            {/*	 onClick={() => isActive && onStickerWrapActive()}>*/}
            {/*	<div className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.sticker} ${isActive ? '' : Styles.dis_active}`}/>*/}
            {/*	<div className={Styles.c3_stickers_list_wrap}>*/}
            {/*		<div className={`${Styles.c3_stickers_list_rows_wrap} ${innerStickerList ? Styles.hide : ''}`}>*/}
            {/*			<div className={Styles.c3_stickers_list_row}>*/}
            {/*				<div className={Styles.c3_stickers_list_head}>*/}
            {/*					<div className={Styles.c3_stickers_list_head_title}>Mature man</div>*/}
            {/*				</div>*/}
            {/*				<div className={Styles.c3_stickers_list}>*/}
            {/*					{favoriteGirlStickerMap}*/}
            {/*					<div className={Styles.c3_stickers_list_all_btn}*/}
            {/*						 onClick={() => setInnerStickerList(STICKERS.STICKER_GIRL_TYPE)}>All*/}
            {/*					</div>*/}
            {/*				</div>*/}
            {/*			</div>*/}
            {/*			<div className={Styles.c3_stickers_list_row}>*/}
            {/*				<div className={Styles.c3_stickers_list_head}>*/}
            {/*					<div className={Styles.c3_stickers_list_head_title}>Hearts</div>*/}
            {/*				</div>*/}
            {/*				<div className={Styles.c3_stickers_list}>*/}
            {/*					{favoriteHeartStickerMap}*/}
            {/*					<div className={Styles.c3_stickers_list_all_btn}*/}
            {/*						 onClick={() => setInnerStickerList(STICKERS.STICKER_HEART_TYPE)}>All*/}
            {/*					</div>*/}
            {/*				</div>*/}
            {/*			</div>*/}
            {/*		</div>*/}
            {/*		<StickersListOpen*/}
            {/*			innerStickerList={innerStickerList}*/}
            {/*			setInnerStickerList={setInnerStickerList}*/}
            {/*			onSelectSticker={onSelectSticker}*/}
            {/*			openStickerBlock={openStickerBlock}*/}
            {/*		/>*/}
            {/*	</div>*/}
            {/*</div>*/}
        </div>
    );
}
