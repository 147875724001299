export const PROVIDER_SOCKET = 'SocketSender';
export const PROVIDER_STORAGE_KEY = 'sync-provider';
export let PROVIDER_ACTIVE = localStorage.getItem(PROVIDER_STORAGE_KEY) ?? PROVIDER_SOCKET; // Set active provider
export const MESSAGE_EVENT = 'message';
export const UPD_PERSONAL = 'update_personal_invites';
export const REACTION_LIMITS = 'REACTION_LIMITS';
export const MESS_ACTION = 'message';
export const VIEW_ACTION = 'viewed';
export const LIKE_ACTION = 'liked';
export const MESS_WINKED = 'winked';
export const OPEN_CHAT_ACTION = 'open_chat';
export const MAIL_ACTION = 'mail';
export const READ_MAIL_ACTION = 'read_mail';
export const LOGOUT = 'logout';
export const VIEWED_PHOTOS = 'viewed_photos';
export const USER_ONLINE = 'user_online';
export const ONLINE_STATUS = 'online_status';

export const MESSAGE_NOTIFY_TYPE = 'message';
export const LIKED_NOTIFY_TYPE = 'liked';
export const LETTER_NOTIFY_TYPE = 'letter';
//http://192.168.120.182:3308
export const SOCKET_URI = window.location.origin.includes('localhost')
    ? 'https://stage.alpha.date/'
    : window.location.origin;
export const SOCKET_PATH = '/api/v2/ws';
