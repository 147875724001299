import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { closeMobChatList } from '../../services/dom/letters';
import * as menuAction from '../../store/actions/menu';
import * as letterFilterAction from '../../store/actions/letterFilter';
import useCloseCurrentLetter from './useCloseCurrentLetter';
import * as filterActions from '../../store/actions/currentChatMailbox';

export default function useHandleLettersFilters() {
    const dispatch = useDispatch();
    const menuState = useSelector((state) => state.menu);
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const letterFilterState = useSelector((state) => state.letterFilter);
    const closeCurrentLetter = useCloseCurrentLetter();
    const [inputId, setInputId] = useState('');

    const onSetLettersMenu = (folderTitle) => {
        // switching folders
        if (menuState.letters !== folderTitle) dispatch(menuAction.setLettersMenu(folderTitle));
        if (folderTitle !== 'inbox') dispatch(letterFilterAction.setInitStateFilter());
        // closing letter chat
        if (currentChatMailboxState?.info?.id) closeCurrentLetter();
        // mobile animation
        dispatch(filterActions.closeLetterFilters());
        closeMobChatList();
    };

    const setOnline = (e) => {
        dispatch(letterFilterAction.setOnlineFilter(e.currentTarget.checked ? 'online' : ''));
    };

    const handleInputId = (e) => {
        e.preventDefault();
        setInputId(e.target.value);
    };

    const handleChangeFilterMen = (name) => {
        dispatch(letterFilterAction.setGirlFilter(name));
        // dispatch(letterFilterAction.setGirlFilter(e.currentTarget.dataset.name));
    };

    const setFreeze = (freeze) => {
        dispatch(letterFilterAction.setFreezeFilter(freeze));
    };

    const filterById = () => {
        dispatch(
            letterFilterAction.setFilterById({
                id: inputId,
                filterName: letterFilterState.manFilter,
            }),
        );
    };

    const handleChangeFilterLetters = (name) => {
        // dispatch(letterFilterAction.setLettersFilter(e.currentTarget.dataset.name));
        dispatch(letterFilterAction.setLettersFilter(name));
    };

    return {
        letterFilterState,
        inputId,
        onSetLettersMenu,
        setOnline,
        handleInputId,
        handleChangeFilterMen,
        filterById,
        handleChangeFilterLetters,
        setFreeze,
    };
}
