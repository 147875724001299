import React, { useState } from 'react';
import styles from './styles.module.scss';
import LetterAttachment from './LetterAttachment';
import * as IMAGES from '../../../common/Images';
import { useSelector } from 'react-redux';
import Styles from '../../../chat/ChatRoom/Form/styles.module.scss';
import SenderEmoji from './SenderEmoji';
import useHandleStickerBlock from '../../../../hooks/emoji/sticker/useHandleStickerBlock';
import useHandleSmileBlock from '../../../../hooks/emoji/smile/useHandleSmileBlock';
import SenderAttach from './SenderAttach';
import useHandleSenderHeader from '../../../../hooks/sender/useHandleSenderHeader';
import { SENDER_TYPE_LETTER } from '../../../../constants/sender';
import Select from 'react-select';
import useInitTranslate from '../../../../hooks/translate/useInitTranslate';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default ({
    onSendSender,
    onSaveSender,
    isOpenTranslateBlock,
    messageContent,
    textSourceTranslate,
    textTargetTranslate,
    setTextSourceTranslate,
    setTextTargetTranslate,
    handleMessageContent,
    handleTextSourceTranslate,
    handleTextTargetTranslate,
    handleTranslateSwitcher,
    onEnterMessageContent,
    backToInitMessageContent,
    addEmoji,
    addMessageAttachment,
    messageAttachment,
    deleteFileAttachment,
    isDisabledButton,
    setIsDisabledButton,
    checkOnMaxLength,
}) => {
    const senderState = useSelector((state) => state.sender);
    const { activeSenderType } = useHandleSenderHeader();

    const [activeTypeFile, setActiveTypeFile] = useState('');

    const handleStickerBlock = useHandleStickerBlock();
    const handleSmileBlock = useHandleSmileBlock();

    const {
        postTranslateText,
        translateOptions,
        activeSourceTranslate,
        selectSourceTranslate,
        activeTargetTranslate,
        selectTargetTranslate,
        switchTargetTranslate,
    } = useInitTranslate(
        isOpenTranslateBlock,
        isDisabledButton,
        setIsDisabledButton,
        textSourceTranslate,
        textTargetTranslate,
        setTextSourceTranslate,
        setTextTargetTranslate,
    );

    return (
        <div className={styles.sender_forms}>
            {!senderState?.activeProfile && (
                <div className={styles.sender_forms_inner_error}>Select a profile first</div>
            )}

            <div className={styles.sender_forms_top}>
                {/*<FormSettings/>*/}
                <div className={styles.sender_forms_top_right}>
                    {/*<div className={styles.sender_forms_top_save_btn}>*/}
                    {/*	<img src={IMAGES.sender_forms_save} alt=""/>*/}
                    {/*	<span>Save</span>*/}
                    {/*</div>*/}
                    <div className={styles.sender_forms_top_clear_btn} onClick={backToInitMessageContent}>
                        Clear all
                    </div>
                </div>
            </div>
            <div className={styles.sender_forms_middle}>
                <div className={styles.sender_forms_middle_left}>
                    {!isOpenTranslateBlock && (
                        <textarea
                            className={Styles.clmn_3_chat_textarea}
                            value={messageContent}
                            // autoFocus={true}
                            onKeyDown={onEnterMessageContent}
                            onChange={(e) => handleMessageContent(e.target.value)}
                        />
                    )}
                    {isOpenTranslateBlock && (
                        <div className={Styles.clmn_3_chat_textarea_double}>
                            <div className={Styles.clmn_3_chat_textarea_wrap}>
                                <div className={Styles.clmn_3_chat_textarea_translate_info}>
                                    <Select
                                        className={Styles.clmn_3_chat_textarea_translate_select}
                                        defaultValue={activeSourceTranslate}
                                        value={activeSourceTranslate}
                                        onChange={selectSourceTranslate}
                                        options={translateOptions}
                                    />

                                    <div className={`${Styles.clmn_3_chat_textarea_translate_symbols}`}>
                                        {textSourceTranslate?.length} symbols
                                    </div>
                                </div>
                                <textarea
                                    className={Styles.clmn_3_chat_textarea_inner}
                                    value={textSourceTranslate}
                                    autoFocus={true}
                                    onKeyDown={(e) => onEnterMessageContent(e, postTranslateText)}
                                    onChange={(e) => handleTextSourceTranslate(e.target.value)}
                                />
                            </div>

                            <div className={Styles.clmn_3_chat_textarea_translate_arrow_wrap}>
                                <div
                                    className={`${Styles.clmn_3_chat_textarea_translate_arrow} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                                    onClick={switchTargetTranslate}
                                >
                                    <CompareArrowsIcon />
                                </div>

                                <div
                                    className={`${Styles.clmn_3_chat_textarea_translate_arrow} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                                    onClick={postTranslateText}
                                >
                                    <ArrowForwardIcon />
                                </div>
                            </div>

                            <div className={Styles.clmn_3_chat_textarea_wrap}>
                                <div className={Styles.clmn_3_chat_textarea_translate_info}>
                                    <Select
                                        className={Styles.clmn_3_chat_textarea_translate_select}
                                        defaultValue={activeTargetTranslate}
                                        value={activeTargetTranslate}
                                        onChange={selectTargetTranslate}
                                        options={translateOptions}
                                    />

                                    <div
                                        className={`${Styles.clmn_3_chat_textarea_translate_symbols} ${!checkOnMaxLength(textTargetTranslate) ? Styles.error : ''}`}
                                    >
                                        {textTargetTranslate?.length} symbols
                                    </div>
                                </div>
                                <textarea
                                    className={Styles.clmn_3_chat_textarea_inner}
                                    value={textTargetTranslate}
                                    onKeyDown={onEnterMessageContent}
                                    onChange={(e) => handleTextTargetTranslate(e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <LetterAttachment
                    activeSenderType={activeSenderType}
                    messageAttachment={messageAttachment}
                    deleteFileAttachment={deleteFileAttachment}
                />
            </div>
            <div className={styles.sender_forms_bottom}>
                <div className={styles.sender_forms_bottom_left}>
                    <SenderEmoji
                        isActive={senderState?.activeProfile && senderState?.activeGroups?.length}
                        onSendSender={onSendSender}
                        {...handleStickerBlock}
                        {...handleSmileBlock}
                        addEmoji={addEmoji}
                    />
                    {activeSenderType === SENDER_TYPE_LETTER && (
                        <SenderAttach
                            isActive={senderState?.activeProfile && senderState?.activeGroups?.length}
                            onSendSender={onSendSender}
                            activeTypeFile={activeTypeFile}
                            setActiveTypeFile={setActiveTypeFile}
                            messageAttachment={messageAttachment}
                            addMessageAttachment={addMessageAttachment}
                            activeSenderType={activeSenderType}
                        />
                    )}
                </div>

                {!isOpenTranslateBlock && (
                    <div className={Styles.clmn_3_chat_bottom_symbols}>{messageContent?.length} symbols entered</div>
                )}

                <div className={styles.sender_forms_bottom_right}>
                    <div className={styles.sender_forms_bottom_translate}>
                        <input type="checkbox" id="form_translate" value={+isOpenTranslateBlock} />
                        <label htmlFor="form_translate" onClick={handleTranslateSwitcher}>
                            Translate
                        </label>
                    </div>
                    <div
                        onClick={() => senderState?.activeProfile && onSaveSender(activeSenderType, messageAttachment)}
                        className={`${styles.sender_forms_top_save_btn} ${senderState?.activeProfile ? '' : styles.not_active}`}
                    >
                        <img src={IMAGES.sender_forms_save} alt="" />
                        <span>Save</span>
                    </div>
                    {/*<div*/}
                    {/*	onClick={() => (senderState?.activeProfile && senderState?.activeGroups?.length) && onSendSender(CHAT.MESSAGE_CONTENT_TYPE_TEXT)}*/}
                    {/*	className={`${styles.sender_forms_bottom_send_btn} ${(senderState?.activeProfile && senderState?.activeGroups?.length) ? '' : styles.not_active}`}>*/}
                    {/*	<img src={IMAGES.sender_drafts_send} alt=""/>*/}
                    {/*	<span>Send</span>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};
