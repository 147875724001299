import { c3_opt_gift, c3_opt_contact, c3_opt_meet, c3_opt_cam } from '../components/common/Images';

export const SERVICE_LIST = [
    {
        serviceName: 'gifts',
        serviceText: 'Send gift',
        imageObject: c3_opt_gift,
        toolTipText: 'This Woman Can Get Gifts',
    },
    {
        serviceName: 'contact',
        serviceText: 'Contact Request',
        imageObject: c3_opt_contact,
        toolTipText: 'This Woman Can Get Contact Request',
    },
    {
        serviceName: 'meeting',
        serviceText: 'Meeting Request',
        imageObject: c3_opt_meet,
        toolTipText: 'This Woman Can Get Meeting Request',
    },
    {
        serviceName: 'webcam',
        serviceText: 'Webcam Request',
        imageObject: c3_opt_cam,
        toolTipText: 'This Woman Can Get Webcam Request',
    },
];
