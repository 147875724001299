import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import modalContentAttach from '../../../../styles/react-modal/modalContent.style';
import Modal from 'react-modal';
import * as modalsAction from '../../../../store/actions/modals';
import AudioContent from './AudioContent';

export default function AudioContentModal() {
    const dispatch = useDispatch();
    const modalsState = useSelector((state) => state.modals);

    const onCloseAudioContentModal = () => {
        dispatch(modalsAction.closeAudioContentModal());
    };

    return (
        <Modal
            style={modalContentAttach}
            isOpen={modalsState?.audioContentModal?.isActive}
            onRequestClose={onCloseAudioContentModal}
            ariaHideApp={false}
        >
            <AudioContent item={modalsState?.audioContentModal?.item} closeModal={onCloseAudioContentModal} />
        </Modal>
    );
}
