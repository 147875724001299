import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

const customMiddleware = () => {
    const logger = createLogger();

    if (localStorage.getItem('developMode')) return applyMiddleware(thunk, logger);
    return applyMiddleware(thunk);
};

export function configureStore() {
    return createStore(rootReducer, composeWithDevTools(customMiddleware()));
}
