import Base from './Base';

export default class UserAPI extends Base {
    getInfo() {
        return super.get(`user/info`).then((res) =>
            res?.data?.status
                ? {
                      userInfo: res?.data?.user_info,
                      status: res?.data?.status,
                      message: 'OK',
                  }
                : {
                      userInfo: null,
                      status: res?.data?.status,
                      message: res?.data?.message,
                  },
        );
    }

    searchMen(data) {
        return super.post(`search/males`, data).then((res) => res?.data);
    }

    getReferencies() {
        return super.post(`operator/getReferencies`).then((res) => res?.data);
    }
}
