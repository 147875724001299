import React, { useRef, useState } from 'react';
import styles from './styles.module.scss';
import useOutsideClicker from '../../../hooks/dom/useOutsideClicker';
import * as statisticAction from '../../../store/actions/statistics';
import { useDispatch } from 'react-redux';

export default ({ user_list, setProfile_id, isDetail }) => {
    const dispatch = useDispatch();
    const [isOpenList, setIsOpenList] = useState(false);
    const [selectedOperator, setSelectedOperator] = useState('');
    const [term, setTerm] = useState('');

    const selectWrap = useRef(null);

    const openList = () => {
        setIsOpenList(true);
    };

    const closeList = () => {
        setIsOpenList(false);
    };

    const selectOperator = (id) => {
        dispatch(statisticAction.setSenderStatListBySearch(id));
        setSelectedOperator(id);
        setProfile_id(id);
        closeList();
    };

    const searchID = (value, term) => {
        if (!term) return value;
        return value.filter((number) => number.toString().includes(term));
    };

    const sortedID = searchID(user_list, term);

    const handleInputChange = (e) => {
        setProfile_id(e.target.value);
        setTerm(e.target.value);
        setSelectedOperator(e.target.value);
    };

    const clearInput = () => {
        dispatch(statisticAction.setSenderStatListBySearch(''));
        setProfile_id('');
        setSelectedOperator('');
        setTerm('');
        !isDetail && setProfile_id('');
        searchID(user_list, term);
    };

    useOutsideClicker(selectWrap, setIsOpenList);

    return (
        <div className={`${styles.select_wrap} ${isOpenList ? styles.open : ''}`} ref={selectWrap}>
            <div className={styles.select_head}>
                <input
                    onFocus={openList}
                    onChange={handleInputChange}
                    id="select_head_inp"
                    className={styles.select_input_text}
                    type="text"
                    placeholder="All profiles"
                    value={selectedOperator}
                    disabled={!user_list?.length}
                />
                {selectedOperator && <button onClick={clearInput} className={styles.clear_input_btn} />}
                <button
                    onClick={() => {
                        setIsOpenList(!isOpenList);
                    }}
                    htmlFor="select_head_inp"
                    className={styles.select_arrow}
                />
            </div>
            <div className={`${styles.select_list_wrap}`}>
                <div className={styles.select_list}>
                    {user_list?.length &&
                        sortedID.map((el, key) => {
                            return (
                                <div className={styles.select_list_item} key={key}>
                                    <input
                                        value={el}
                                        id={`op_${key}`}
                                        type="radio"
                                        name="stat_list_op"
                                        onChange={() => selectOperator(el)}
                                    />
                                    <label htmlFor={`op_${key}`}>{el}</label>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};
