import React from 'react';
import Chat from '../../components/chat';
import useEffectToLoadChatList from '../../hooks/chat/useEffectToLoadChatList';
import useOpenChatEffect from '../../hooks/chat/useOpenChatEffect';
import '../../styles/global.scss';
import PropTypes from 'prop-types';

ChatPage.propTypes = {
    match: PropTypes.object,
};
export default function ChatPage({ match }) {
    useOpenChatEffect(match);
    useEffectToLoadChatList(match);

    return <Chat />;
}
