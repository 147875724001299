import React from 'react';
import { Route } from 'react-router-dom';
import UserLayout from '../../layouts/UserLayout';
import PropTypes from 'prop-types';

UserRoute.propTypes = {
    component: PropTypes.object,
};
export default function UserRoute({ component: Component, ...rest }) {
    return (
        <UserLayout>
            <Route {...rest} render={(props) => <Component {...props} />} />
        </UserLayout>
    );
}
