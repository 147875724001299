import Base from './Base';
import * as VIMEO from '../../constants/vimeo';

export default class VimeoApi extends Base {
    constructor(base) {
        super(base);
        this.url = 'https://api.vimeo.com';
    }

    getAuthHeader() {
        return {
            headers: {
                Authorization: `bearer ${VIMEO.VIMEO_ACCESS_TOKEN2}`,
                Accept: `application/vnd.vimeo.*+json;version=3.4`,
            },
        };
    }

    getThumbnail(videoId) {
        return super.getThirdParty(`${this.url}/videos/${videoId}/pictures`, this.getAuthHeader()).catch(() => null);
    }
}
