import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as mailboxAction from '../../../../../store/actions/mailbox';
import { userAvatar } from '../../../../../services/methods';
import Styles from './styles.module.scss';

export default ({ item }) => {
    const dispatch = useDispatch();
    const mailboxState = useSelector((state) => state.mailbox);

    const handleChange = () => {
        if (mailboxState.checkedRecipients.find((find) => find.external_id === item.external_id)) {
            dispatch(mailboxAction.removeRecipient(item));
        } else {
            dispatch(mailboxAction.addRecipient(item));
        }
    };

    return (
        <div
            className={`popup_compose_man_item 
			${+item?.online === 1 ? 'online' : ''}
			`}
            data-id={item.external_id}
            onClick={handleChange}
            data-testid={`result-item-${item.external_id}`}
        >
            <input
                type="checkbox"
                name="pcmi-1"
                checked={!!mailboxState.checkedRecipients.find((find) => find.external_id === item.external_id)}
                onChange={handleChange}
            />
            <label htmlFor="pcmi-1" className="popup_compose_man_item_label">
                <div className="popup_compose_man_item_photo" data-testid="item-photo">
                    {userAvatar(item.image, item.name, '', Styles.user_avatar)}
                </div>
                <div className="popup_compose_man_item_right">
                    <div
                        className="popup_compose_man_item_id"
                        data-testid="item-external_id"
                    >{`ID ${item.external_id}`}</div>
                    <div
                        className="popup_compose_man_item_name"
                        data-testid="item-name"
                    >{`${item.name}, ${item.age}`}</div>
                    <div className="popup_compose_man_item_place" data-testid="item-city">
                        <img src={item.country_image} alt="" />
                        {item.city_name ? item.city_name : ''}
                    </div>
                </div>
            </label>
        </div>
    );
};
