import React from 'react';
import LettersNew from '../../components/letters-new/LettersNew';
import LettersNewMenu from '../../components/letters-new/LettersNewMenu';
import useEffectToOpenLettersChat from '../../hooks/letters/useEffectToOpenLettersChat';
import PropTypes from 'prop-types';

WomanLettersPage.propTypes = {
    match: PropTypes.object,
};
export default function WomanLettersPage({ match }) {
    useEffectToOpenLettersChat(match);

    return (
        <>
            <LettersNewMenu />
            <LettersNew />
        </>
    );
}
