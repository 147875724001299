import React from 'react';
import modalWriteLetter from '../../../../styles/react-modal/modalWriteLetter.style';
import Modal from 'react-modal';
import Styles from './styles.module.scss';

export default function ConfirmModal({ isModalOpen, onClose, onConfirm, title }) {
    return (
        <Modal style={modalWriteLetter} isOpen={isModalOpen} onRequestClose={onClose} ariaHideApp={false}>
            <div className={Styles.block_modal}>
                <div className={Styles.block_modal__title}>{title}</div>
                <div className={Styles.block_modal__buttons_container}>
                    <button
                        className={Styles.block_modal__buttons_container__button_ok}
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                    >
                        OK
                    </button>
                    <button className={Styles.block_modal__buttons_container__button_cancel} onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
}
