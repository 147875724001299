import {
    SET_LIST_BY_SEARCH,
    SET_FILTER_BY_SEARCH,
    SET_FILTERS_SEARCH,
    SET_NEXT_SEARCH_PAGE,
    SET_LOADING,
    SET_FINISH_LOADING,
    CLEAR_FILTERS_SEARCH,
} from '../constants/search';

const initState = {
    list: [],
    filter: {},
    page: 1,
    loading: false,
};

export default function (state = initState, action) {
    switch (action.type) {
        case SET_LIST_BY_SEARCH: {
            return {
                ...state,
                list: [...state.list, ...action.data],
                // hook for stop when request is empty
                loading: action.data.length <= 0,
            };
        }

        case SET_FILTER_BY_SEARCH: {
            return {
                ...state,
                filter: {
                    ...state.filters,
                    [action.name]: action.value,
                },
                // reset data
                list: [],
                page: 1,
            };
        }

        case SET_FILTERS_SEARCH: {
            return {
                ...state,
                filter: {
                    ...state.filters,
                    ...action.data,
                },
                // reset data
                list: [],
                page: 1,
            };
        }

        case SET_NEXT_SEARCH_PAGE: {
            return {
                ...state,
                page: state.page + 1,
                loading: true,
            };
        }

        case SET_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }

        case SET_FINISH_LOADING: {
            return {
                ...state,
                loading: false,
            };
        }

        case CLEAR_FILTERS_SEARCH: {
            return initState;
        }

        default:
            return state;
    }
}
