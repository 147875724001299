import * as MAILBOX from '../constants/mailbox';

const initState = {
    info: {},

    history: [],
    mailChatInfo: {},
    specialLetterId: null,
    historyAllPages: null,
    historyCurrentPage: null,

    chatId: '',
    draftId: null,
    isNewMessage: false,
    disabledContentConfig: null,
};

export default function (state = initState, action) {
    switch (action.type) {
        case MAILBOX.FETCH_MAILBOX_GET:
            return {
                ...state,
                ...{
                    historyAllPages: null,
                    historyCurrentPage: null,
                    history: [],
                    mailChatInfo: {},
                    specialLetterId: null,
                },
            };

        case MAILBOX.UPDATE_CURRENT_CHAT_MAILBOX_LETTER_LIMIT:
            return {
                ...state,
                ...{
                    info: { ...state.info, ...{ letter_limit: +action?.letterLimit || 0 } },
                },
            };

        case MAILBOX.CURRENT_CHAT_MAILBOX_SET_FAVORITE:
            return {
                ...state,
                ...{
                    info: { ...state.info, ...{ male_favorite: action?.favoriteStatus } },
                    mailChatInfo: {
                        ...state.mailChatInfo,
                        ...{ male_favorite: action?.favoriteStatus },
                    },
                },
            };

        case MAILBOX.SET_WINK:
            return {
                ...state,
                ...{
                    info: { ...action.info, ...{ wink: 0 } },
                },
            };

        case MAILBOX.REMOVE_CURRENT_MAILBOX:
            return {
                ...state,
                ...initState,
            };

        case MAILBOX.MAILBOX_ADD_DRAFT_ID:
            return {
                ...state,
                ...{
                    draftId: action.data,
                },
            };

        case MAILBOX.MAILBOX_SET_READ_LETTER:
            return {
                ...state,
                ...{
                    history: state.history.map((item) => {
                        if (action?.mailIDList?.includes(+item?.mail?.id)) {
                            return {
                                ...item,
                                mail: { ...item?.mail, mail_read_status: '1' },
                            };
                        } else return item;
                    }),
                },
            };

        case MAILBOX.SET_INFO_CURRENT_TARGET:
            return {
                ...state,
                ...{
                    info: { ...state.info, ...{ actionTarget: action.data } },
                },
            };

        case MAILBOX.MAILBOX_CHAT_ADD_SPECIAL_LETTER:
            return {
                ...state,
                ...{
                    specialLetterId: action.data,
                },
            };

        case MAILBOX.MAILBOX_CHAT_ADD_INFO_FORCE:
            return {
                ...state,
                ...{
                    info: action.data,
                },
            };

        case MAILBOX.MAILBOX_CHAT_ADD_INFO:
            return {
                ...state,
                ...{
                    info: action.data,
                    specialLetterId: null,
                    draftId: null,
                },
            };

        case MAILBOX.MAILBOX_CHAT_GET_HISTORY:
            return {
                ...state,
                ...{
                    historyAllPages: action.data.pages,
                    historyCurrentPage: action.data.page,
                    history: action.data.mails,
                    mailChatInfo: action.data.chat,
                    disabledContentConfig: action.data.disabledContentConfig,
                },
            };

        case MAILBOX.MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_UP:
            if (action.data.mails.length !== 0) {
                return {
                    ...state,
                    ...{
                        history: [...action.data.mails, ...state.history],
                    },
                };
            } else {
                return state;
            }

        case MAILBOX.MAILBOX_CHAT_GET_ADD_HISTORY_PAGE_DOWN:
            if (action.data.mails.length !== 0) {
                return {
                    ...state,
                    ...{
                        history: [...state.history, ...action.data.mails],
                    },
                };
            } else {
                return state;
            }

        case MAILBOX.MAILBOX_CHAT_GET_HISTORY_FORCE:
            return {
                ...state,
                ...{
                    history: action.data.mails,
                },
            };

        case MAILBOX.MAILBOX_CHAT_GET_HISTORY_SEND_MESS:
            return {
                ...state,
                ...{
                    history: action.data.mails,
                    specialLetterId: action.data.mails[action.data.mails.length - 1].id,
                },
            };

        case MAILBOX.GET_CHAT_ID:
            return {
                ...state,
                ...{
                    chatId: action?.data?.result,
                },
            };

        case MAILBOX.MAILBOX_SET_LIKE:
            return {
                ...state,
                ...{
                    mailChatInfo: {
                        ...state?.mailChatInfo,
                        ...{ male_like: +state?.mailChatInfo?.male_like === 0 ? 1 : 0 },
                    },
                },
            };

        case MAILBOX.MAILBOX_SET_FAVORITE:
            return {
                ...state,
                ...{
                    mailChatInfo: {
                        ...state.mailChatInfo,
                        ...{ male_favorite: +state.mailChatInfo.male_favorite === 0 ? 1 : 0 },
                    },
                },
            };

        case MAILBOX.SET_IS_NEW_MESS:
            return {
                ...state,
                ...{
                    isNewMessage: action.data,
                },
            };

        case MAILBOX.REDUCE_LETTER_LEFT:
            return {
                ...state,
                ...{
                    info: {
                        ...state?.info,
                        ...{
                            letter_limit: +state?.info?.letter_limit ? +state?.info?.letter_limit - 1 : 0,
                        },
                    },
                },
            };

        case MAILBOX.OPEN_LETTER_FILTERS:
            return { ...state, ...{ isOpenLetterFilter: true } };

        case MAILBOX.CLOSE_LETTER_FILTERS:
            return { ...state, ...{ isOpenLetterFilter: false } };

        case MAILBOX.CURRENT_CHAT_MAILBOX_HIDE_MAIL:
            return {
                ...state,
                ...{
                    history: state.history.filter((item) => +item?.mail?.id !== action?.mailID),
                },
            };

        case MAILBOX.MAILBOX_UNBLOCK_MAN:
            return {
                ...state,
                info: {
                    ...state.info,
                    male_block: 0,
                },
            };

        default:
            return state;
    }
}
