import React from 'react';
import { useSelector } from 'react-redux';

import MessageRight from '../../../../common/MessageBlocks/MessageRight';

import * as MENU from '../../../../../constants/menu';

import useScrollChatMessageList from '../../../../../hooks/chat-old/helpers/useScrollChatMessageList';

export default () => {
    const menuState = useSelector((state) => state.menu);
    const currentChatState = useSelector((state) => state.currentChat);
    const onlineManState = useSelector((state) => state.onlineMan);
    const profilesState = useSelector((state) => state.profiles);
    const { history } = currentChatState;

    const onScrollList = useScrollChatMessageList();

    return (
        <div className={`c3_chat_scroll ${menuState.sub === MENU.MENU_BLOCK ? 'blocked' : ''}`} onScroll={onScrollList}>
            <div className="c3_chat">
                {history.length > 0 &&
                    history.map((item, key) => (
                        <MessageRight
                            key={key}
                            message={item}
                            info={{ female_external_id: profilesState.active?.external_id }}
                            history={currentChatState.history}
                            currentChatState={onlineManState?.currentChat}
                        />
                    ))}
            </div>
        </div>
    );
};
