// PostsHeader
export const HEADER_TITLE = 'Fill out information about yourself to help us find your perfect match';
export const HEADER_LATER_BUTTON = 'I’ll do it later';

// Looking for block
export const LOOKING_TITLE = 'What kind of girl are you looking for?';
export const LOOKING_SUB_TITLE_ACTIVITY = 'What about her social activity and interests?';
export const LOOKING_SUB_TITLE_MORE_YOU = 'Tell us a little bit more about your perfect match';
export const LOOKING_FROM = 'From';
export const LOOKING_TO = 'To';
export const LOOKING_CHOOSE = 'Choose option';
export const LOOKING_SELECT_AGE = 'Age range';
export const LOOKING_SELECT_HEIGHT = 'height range';
export const LOOKING_SELECT_BODY = 'Body Type';
export const LOOKING_SELECT_EYE = 'Eye color';
export const LOOKING_SELECT_RELIGION = 'Religion';
export const LOOKING_SELECT_NUM_CHILD = 'Number of children';
export const LOOKING_SELECT_MORE_CHILD = 'Want to have more children';
export const LOOKING_SELECT_ALCOHOL = 'Alcohol';
export const LOOKING_SELECT_SMOKING = 'Smoking';
export const LOOKING_SELECT_EDUCATION = 'Education';
export const LOOKING_SELECT_JOB = 'Job or profession';
export const LOOKING_SELECT_HOBBIES = 'Hobbies';

export const LOOKING_AGE_START = 18;
export const LOOKING_AGE_END = 50;
export const LOOKING_HEIGHT_START = 150;
export const LOOKING_HEIGHT_END = 200;

// Profile info
export const PROFILE_INFO_TITLE = 'Set your main profile information';
export const PROFILE_INFO_SUB_TITLE_HABITS = 'What about your habits?';
export const PROFILE_INFO_SUB_TITLE_TELL = 'What else do you want to tell your potential match?';
export const PROFILE_INFO_SUB_TITLE_PHOTOS = 'Download your photos';
export const PROFILE_INFO_ADD_PHOTO_BUTTON = 'Add photo';
export const PROFILE_INFO_FROM = 'From';
export const PROFILE_INFO_TO = 'To';
export const PROFILE_INFO_CHOOSE = 'Choose option';
export const PROFILE_INFO_SELECT_NAME = 'Name';
export const PROFILE_INFO_SELECT_COUNTRY = 'Country';
export const PROFILE_INFO_SELECT_TOWN = 'Town';
export const PROFILE_INFO_SELECT_HEIGHT = 'Height';
export const PROFILE_INFO_SELECT_BODY_TYPE = 'Body type';
export const PROFILE_INFO_SELECT_EYE = 'Eye color';
export const PROFILE_INFO_SELECT_HAIR = 'Hair color';
export const PROFILE_INFO_SELECT_BIRTH = 'Date fo birth';
export const PROFILE_INFO_SELECT_ZODIAC = 'Zodiac';
export const PROFILE_INFO_SELECT_MARITAL = 'Marital status';
export const PROFILE_INFO_SELECT_NUM_CHILD = 'Number of children';
export const PROFILE_INFO_SELECT_MORE_CHILD = 'want to have more children';
export const PROFILE_INFO_SELECT_RELIGION = 'Religion';
export const PROFILE_INFO_SELECT_SMOKING = 'Smoking';
export const PROFILE_INFO_SELECT_ALCOHOL = 'Alcohol';
export const PROFILE_INFO_SELECT_EDUCATION = 'Education';
export const PROFILE_INFO_SELECT_JOB = 'Job or profession';
export const PROFILE_INFO_SELECT_HOBBIES = 'Hobbies';
export const PROFILE_INFO_SET_PHOTO = 'Profile photo set';
export const PROFILE_INFO_CHOOSE_PHOTO_FIRST = 'Choose ';
export const PROFILE_INFO_CHOOSE_PHOTO_SECOND = 'profile photo';

// Form
export const FORM_SUBMIT_BUTTON = 'Find your match';
