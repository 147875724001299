import OperatorApi from '../../services/api/OperatorApi';
import * as profilesAction from '../../store/actions/profiles';
import { useDispatch } from 'react-redux';
import * as alertsAction from '../../store/actions/alerts';

export default function useHandleWomanProfile() {
    const dispatch = useDispatch();

    const fetchWomanProfileList = async () => {
        const profilesRes = await new OperatorApi().getProfiles();

        if (!profilesRes?.length) {
            localStorage.setItem('check_profiles', '1');
            return dispatch(alertsAction.setMessage('', `You don't have profiles assigned`));
        } else {
            localStorage.removeItem('check_profiles');
        }

        dispatch(profilesAction.addProfiles(profilesRes));
        dispatch(profilesAction.addActiveSenderInfo(profilesRes?.map((item) => +item?.external_id)));
    };

    return {
        fetchWomanProfileList,
    };
}
