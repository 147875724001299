import * as CHATS from '../constants/chats';
import { uniqueArrayByID } from '../../services/methods';
import { filterToHideChatHistoryByType } from '../../services/methods/chat';

const HISTORY_LENGTH = 20;

const initState = {
    info: {},
    history: [],
    historyPage: 2,
    hidePageDown: 0,
    historyPageLoading: false,
    status: false,
    isLoadedAll: false,
    lastMessageId: '',
    openFromNotification: null,
    allMediaList: [],
    isAllMediaLoaded: false,
    mediaPage: 1,
    options: {},
};

export default function (state = initState, action) {
    switch (action.type) {
        case CHATS.ADD_CURRENT_CHAT_HISTORY: {
            const historyListNew = uniqueArrayByID(action?.history)?.filter(filterToHideChatHistoryByType) ?? [];

            return {
                ...state,
                ...{
                    history: historyListNew,
                    status: true,
                    historyPageLoading: false,
                    hidePageDown: 0,
                    lastMessageId: historyListNew[historyListNew?.length - 1]?.id,
                    isLoadedAll: action?.history?.length < HISTORY_LENGTH,
                },
            };
        }

        case CHATS.ADD_CURRENT_CHAT_OPTIONS: {
            return {
                ...state,
                ...{ options: action?.options },
            };
        }

        case CHATS.MAIN_CHAT_SET_HIDE_PAGE_DOWN: {
            const newPage = action?.isDown ? state?.historyPage - 1 : state?.historyPage;

            return {
                ...state,
                ...{
                    hidePageDown: action?.hidePageDown,
                    lastMessageId:
                        (action?.isDown ? '-' : '') +
                        state?.history[state?.history?.length - +state?.hidePageDown * 20 - 1]?.id,
                    isLoadedAll: false,

                    historyPage: newPage,
                    historyPageLoading: false,
                    // history: uniqueArrayByID(action?.isDown ? state?.history.slice(20 + state?.history?.length % HISTORY_LENGTH) : state?.history),
                    history: action?.isDown ? state?.history.slice(HISTORY_LENGTH) : state?.history,
                },
            };
        }

        case CHATS.ADD_CURRENT_CHAT_HISTORY_PAGE: {
            const historyList =
                uniqueArrayByID([...(action?.history || []), ...state.history])?.filter(
                    filterToHideChatHistoryByType,
                ) ?? [];

            return {
                ...state,
                ...{
                    history: historyList,
                    historyPage:
                        action?.history?.length % HISTORY_LENGTH > 0 ? state.historyPage : state.historyPage + 1,
                    historyPageLoading: false,
                    lastMessageId: action?.isStart ? historyList[historyList?.length - 1]?.id : state?.history[0]?.id,
                    isLoadedAll: action?.history?.length < HISTORY_LENGTH,
                },
            };
        }

        case CHATS.MAIN_CHAT_START_PAGE: {
            return {
                ...state,
                ...{
                    history: uniqueArrayByID([...state.history.slice(state.history.length - 20, state.history.length)]),
                    hidePageDown: 0,
                    historyPage: 2,
                    isLoadedAll: false,
                    historyPageLoading: false,
                    lastMessageId: state?.history[state?.history?.length - 1]?.id,
                },
            };
        }

        case CHATS.MAIN_CHAT_ADD_HISTORY_PAGE_LOADING: {
            return {
                ...state,
                ...{
                    historyPageLoading: true,
                },
            };
        }

        case CHATS.INCREASE_MESSAGES_LEFT: {
            return {
                ...state,
                ...{
                    info: { ...state.info, ...{ message_limit: +state?.info?.message_limit + 1 } },
                },
            };
        }

        case CHATS.REDUCE_MESSAGES_LEFT: {
            return {
                ...state,
                ...{
                    info: {
                        ...state.info,
                        ...{
                            message_limit: +state?.info?.message_limit ? +state?.info?.message_limit - 1 : 0,
                        },
                    },
                },
            };
        }

        case CHATS.REMOVE_CURRENT_CHAT: {
            return {
                ...state,
                ...initState,
            };
        }

        case CHATS.ADD_CURRENT_CHAT: {
            return {
                ...state,
                ...{ info: action?.currentChat },
            };
        }

        case CHATS.CURRENT_CHAT_SET_LIKE: {
            return {
                ...state,
                ...{ info: { ...state?.info, ...{ like: true, like_limit: 0 } } },
            };
        }

        case CHATS.SET_FAVORITE_STATUS: {
            return {
                ...state,
                ...{ info: { ...state.info, ...{ male_favorite: action?.favoriteStatus } } },
            };
        }

        case CHATS.SET_MAYBE_STATUS: {
            return {
                ...state,
                ...{ info: { ...state.info, ...{ maybe: action?.maybeStatus } } },
            };
        }

        case CHATS.SET_SPECIAL_STATUS: {
            return {
                ...state,
                ...{ info: { ...state.info, ...{ special: action?.specialStatus } } },
            };
        }

        case CHATS.CURRENT_CHAT_HISTORY_ADD_MESSAGE: {
            const newData = action?.replyData ? [action?.replyData, action?.newMess] : [action?.newMess];

            return {
                ...state,
                ...{
                    history: uniqueArrayByID([...(state?.history || []), ...newData]),
                    lastMessageId: action?.newMess?.id,
                },
            };
        }

        case CHATS.CURRENT_CHAT_HISTORY_REMOVE_MESSAGE: {
            return {
                ...state,
                ...{
                    history: uniqueArrayByID(state?.history?.filter((item) => item?.id !== action?.id)),
                    lastMessageId: action?.id,
                },
            };
        }

        case CHATS.CURRENT_CHAT_HISTORY_UPDATE_MESSAGE: {
            return {
                ...state,
                ...{
                    history: uniqueArrayByID([
                        ...state.history.filter((item) => item?.id !== action?.hashId),
                        ...[action.newMess],
                    ]),
                    lastMessageId: action?.newMess?.id,
                },
            };
        }

        case CHATS.SET_READ_LAST_MESS: {
            return {
                ...state,
                ...{
                    history: uniqueArrayByID(state.history.map((item) => ({ ...item, ...{ read_status: '1' } }))),
                },
            };
        }

        case CHATS.UPDATE_CURRENT_CHAT_MESSAGE_LIMIT: {
            return {
                ...state,
                ...{
                    info: {
                        ...state.info,
                        ...{
                            message_limit: +action?.messageLimit ? action?.messageLimit : 0,
                            like_limit: +action?.likeLimit ? action?.likeLimit : 0,
                        },
                    },
                },
            };
        }

        case CHATS.SET_ALL_MEDIA_LIST: {
            return {
                ...state,
                ...{
                    allMediaList: action?.data,
                    isAllMediaLoaded: true,
                },
            };
        }

        case CHATS.UPDATE_ALL_MEDIA_LIST: {
            return {
                ...state,
                ...{
                    allMediaList: [action?.data, ...state.allMediaList],
                },
            };
        }

        case CHATS.SET_MEDIA_LIST_BY_PAGE: {
            return {
                ...state,
                ...{
                    mediaPage: action?.mediaPage,
                },
            };
        }

        case CHATS.RESET_MEDIA_LIST_BY_PAGE: {
            return {
                ...state,
                ...{
                    mediaPage: 1,
                },
            };
        }

        case CHATS.MAIN_CHAT_HIDE_MESSAGE: {
            return {
                ...state,
                ...{
                    history: state.history.filter((item) => item?.id !== action?.messageID),
                },
            };
        }

        case CHATS.BLOCK_USER:
            return {
                ...state,
                info: {
                    ...state.info,
                    male_block: 1,
                },
            };
        case CHATS.UNBLOCK_USER:
            return {
                ...state,
                info: {
                    ...state.info,
                    male_block: 0,
                },
            };

        default: {
            return state;
        }
    }
}
