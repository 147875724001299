import React from 'react';
import StatCalendar from '../StatCalendar';
import Button from '../StatElements/Button';
import styles from '../styles.module.scss';
import StatSelect from '../StatSelect';
import { useSelector } from 'react-redux';

export default function StatSearchParams({
    profile_id,
    setProfile_id,
    selectStartDate,
    setSelectStartDate,
    selectEndDate,
    setSelectEndDate,
    searchButton,
    isLoading,
}) {
    const profilesState = useSelector((state) => state.profiles);

    return (
        <>
            <div className={styles.stats_page_head_left}>
                <StatSelect
                    user_list={profilesState?.list?.length ? profilesState?.list?.map((item) => item?.external_id) : []}
                    profile_id={profile_id}
                    setProfile_id={setProfile_id}
                    classNames={'stats_autoselect'}
                />
                <StatCalendar
                    selectDate={selectStartDate}
                    setSelectDate={setSelectStartDate}
                    placeholder="Select Start Date"
                    searchButton={(date) => searchButton(date)}
                />
                <StatCalendar
                    selectDate={selectEndDate}
                    setSelectDate={setSelectEndDate}
                    placeholder="Select End Date"
                    searchButton={(date) => searchButton(null, date)}
                />
                <Button
                    isLoading={isLoading}
                    onClick={() => searchButton()}
                    text="Show"
                    classes={styles.stats_page_show_btn}
                />
            </div>
        </>
    );
}
