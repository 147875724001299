import * as MENU from '../constants/menu';

export function setMainMenu(menuName) {
    return function (dispatch) {
        return dispatch({
            type: MENU.SET_MAIN_MENU,
            data: menuName,
        });
    };
}

export function setSubMenu(menuName) {
    return function (dispatch) {
        return dispatch({
            type: MENU.SET_SUB_MENU,
            data: menuName,
        });
    };
}

export function setLettersMenu(menuName) {
    return function (dispatch) {
        return dispatch({
            type: MENU.SET_LETTERS_MENU,
            data: menuName,
        });
    };
}

export function setComposeLetterFilter(menuName) {
    return function (dispatch) {
        return dispatch({
            type: MENU.SET_COMPOSE_LETTER_FILTER,
            data: menuName,
        });
    };
}

export function setComposeLetterSearch(searchField) {
    return function (dispatch) {
        return dispatch({
            type: MENU.SET_COMPOSE_LETTER_SEARCH,
            data: searchField,
        });
    };
}

export function setPreviousPage(previousPage) {
    return function (dispatch) {
        return dispatch({
            type: MENU.SET_PREVIOUS_PAGE,
            data: previousPage,
        });
    };
}
