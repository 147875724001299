import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'js-md5';
import moment from 'moment';
import * as REGEXP from '../../constants/regExp';
import * as CHAT from '../../constants/chat';
import * as ERROR from '../../constants/error';
import * as alertsAction from '../../store/actions/alerts';
import * as currentChatAction from '../../store/actions/currentChat';
import * as chatsAction from '../../store/actions/chats';
import * as LENGTH from '../../constants/length';
import ChatsApi from '../../services/api/ChatsApi';
import * as errorsAction from '../../store/actions/alerts';
import { insertedMessageBeforeSync } from '../../services/init';

export default function useHandleChatRoomForm() {
    const dispatch = useDispatch();

    const currentChatState = useSelector((state) => state.currentChat);
    const getTranslateBlockOpenDefault = localStorage.getItem('isTranslateOpen') === 'true';

    const [isOpenTranslateBlock, setIsOpenTranslateBlock] = useState(getTranslateBlockOpenDefault);
    const [messageContent, setMessageContent] = useState('');
    const [templateContent, setTemplateContent] = useState(null);
    const [textSourceTranslate, setTextSourceTranslate] = useState('');
    const [textTargetTranslate, setTextTargetTranslate] = useState('');
    const [isDisabledButton, setIsDisabledButton] = useState(false);

    const checkOnMaxLength = (value) => {
        return +value?.length <= LENGTH.MESSAGE_MAX_LENGTH;
    };

    const checkOnCyrillicLetters = (value) => {
        return value?.length > 0 ? REGEXP.INPUT_OPERATOR.test(value) : true;
    };

    const handleMessageContent = (value) => {
        if (!+value.length) {
            setTextSourceTranslate('');
            setTextTargetTranslate('');
        }

        if (checkOnCyrillicLetters(value)) setMessageContent(value);
    };

    const addEmoji = (emoji) => {
        setMessageContent((isOpenTranslateBlock ? textSourceTranslate : messageContent) + emoji.native);
    };

    const handleTemplateSelector = (event, option) => {
        setTemplateContent(option);
    };

    const handleTextSourceTranslate = (value) => {
        if (!+value.length) setTextTargetTranslate('');
        setTextSourceTranslate(value);
    };

    const handleTextTargetTranslate = (value) => {
        setTextTargetTranslate(value);
    };

    const handleTranslateSwitcher = () => {
        if (isOpenTranslateBlock) setMessageContent(textTargetTranslate.slice(0, LENGTH.MESSAGE_MAX_LENGTH));
        else setTextSourceTranslate(messageContent);

        setIsOpenTranslateBlock(!isOpenTranslateBlock);
        localStorage.setItem('isTranslateOpen', !isOpenTranslateBlock);
    };

    const backToInitMessageContent = () => {
        setMessageContent('');
        setTextSourceTranslate('');
        setTextTargetTranslate('');
        setTemplateContent(null);
    };

    const onSubmitMessageContent = (messageType = CHAT.MESSAGE_CONTENT_TYPE_TEXT, template = templateContent) => {
        if (isDisabledButton) return false;

        const hashId = md5(moment().format());
        const isChanceFolder = window.location.pathname.split('/')[1] === 'chance';

        let submitMessageContent = isOpenTranslateBlock ? textTargetTranslate : messageContent;
        if (template?.template) submitMessageContent = template.template;

        if (!submitMessageContent?.trim()?.length) {
            return dispatch(errorsAction.setMessage('Invalid message', 'The message must not be empty!'));
        }

        dispatch(
            currentChatAction.addMessageToHistory(
                insertedMessageBeforeSync({
                    id: hashId,
                    messageContent: submitMessageContent,
                    externalId: +currentChatState?.info?.female_external_id,
                    type: messageType,
                }),
            ),
        );

        setIsDisabledButton(true);

        new ChatsApi()
            .postMessage(
                +currentChatState?.info?.female_external_id,
                +currentChatState?.info?.male_external_id,
                submitMessageContent,
                messageType,
                '',
                '',
                isChanceFolder,
            )
            .then((res) => {
                setIsDisabledButton(false);

                function badRequest() {
                    dispatch(currentChatAction.removeMessageInHistory(hashId));

                    if (res?.error) {
                        if (res?.error?.includes('no connection')) {
                            dispatch(currentChatAction.updateMessageLimit(0, 0));
                            return dispatch(alertsAction.setMessage('', 'Message limit reached'));
                        }
                        dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                    } else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                }

                if (!res?.response?.message_object || !res?.status) return badRequest();
                else if (res?.status) {
                    dispatch(
                        currentChatAction.updateMessageLimit(
                            res?.response?.chat_list_object?.message_limit,
                            res?.response?.chat_list_object.like_limit,
                        ),
                    );
                    dispatch(currentChatAction.updateMessageInHistory(hashId, res?.response?.message_object));
                    dispatch(
                        chatsAction.updateActiveChat({
                            ...res?.response?.chat_list_object,
                            ...{
                                sender_external_id: res?.response?.message_object?.sender_external_id,
                            },
                        }),
                    );
                    backToInitMessageContent();
                }
            });
    };

    const onEnterMessageContent = (event, callback) => {
        if (event.key === 'Enter') {
            const value = event.target.value;
            const lines = value.split('\n');

            //Enter + Shift
            if (event.shiftKey) {
                if (lines?.length < LENGTH.MESSAGE_MAX_NUMBER_LINE) return false;
                else return event.preventDefault();
            }
            //Enter + Ctrl
            if (event.ctrlKey && isOpenTranslateBlock && callback) return callback();

            event.preventDefault();
            onSubmitMessageContent(CHAT.MESSAGE_CONTENT_TYPE_TEXT);
        }
    };

    useEffect(() => {
        if (isOpenTranslateBlock) setTextSourceTranslate(messageContent);
    }, [messageContent]);

    useEffect(() => {
        return () => backToInitMessageContent();
    }, [currentChatState.info.chat_uid]);

    return {
        isOpenTranslateBlock,

        messageContent,
        templateContent,
        textTargetTranslate,
        textSourceTranslate,
        setTextSourceTranslate,
        setTextTargetTranslate,

        handleMessageContent,
        handleTextSourceTranslate,
        handleTextTargetTranslate,
        handleTranslateSwitcher,

        onSubmitMessageContent,
        onEnterMessageContent,

        handleTemplateSelector,

        addEmoji,
        isDisabledButton,
        setIsDisabledButton,
        checkOnMaxLength,
    };
}
