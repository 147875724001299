import * as COUNTERS from '../constants/counters';

const initState = {
    letterCounter: null,
    chatCounter: {},
};

export default function (state = initState, action) {
    switch (action.type) {
        case COUNTERS.CHAT_COUNTER_GET:
            return {
                ...state,
                ...{
                    chatCounter: action?.data,
                },
            };

        case COUNTERS.LETTER_COUNTER_GET:
            return {
                ...state,
                ...{
                    letterCounter: action?.data,
                },
            };

        case COUNTERS.CHAT_COUNTER_REMOVE:
            return {
                ...state,
                ...{
                    chatCounter: null,
                },
            };

        case COUNTERS.LETTER_COUNTER_REMOVE:
            return {
                ...state,
                ...{
                    letterCounter: null,
                },
            };

        default:
            return state;
    }
}
