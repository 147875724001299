import React from 'react';
import styles from '../../styles.module.scss';
import * as IMAGES from '../../../../../common/Images';
import { useDispatch } from 'react-redux';
import * as modalsAction from '../../../../../../store/actions/modals';

export default ({ filename, link, index, deleteFileAttachment }) => {
    const dispatch = useDispatch();

    const openAudioModal = () => {
        dispatch(
            modalsAction.openAudioContentModal({
                link: link,
                attachment_payed: '1',
            }),
        );
    };

    const onDelete = (event) => {
        event.stopPropagation();
        deleteFileAttachment(index);
    };

    return (
        <div className={`${styles.sender_forms_middle_media_item} ${styles.audio}`} onClick={openAudioModal}>
            <img src={IMAGES.sender_history_audio_play} alt="" />
            <span>{filename}</span>
            <div className={`${styles.sender_forms_middle_media_item_delete}`} onClick={onDelete}>
                <img src={IMAGES.sender_forms_delete_media} alt="" />
            </div>
        </div>
    );
};
