import React from 'react';
import DnDContainer from '../DragAndDrop/Container';

export default ({ type, images, isSortingDisabled }) => {
    return (
        <div className="upload_popup_tabs_content_middle photo" id="uptcmp" data-testid="file-list">
            <DnDContainer type={type} data={images} isSortingDisabled={isSortingDisabled} />
        </div>
    );
};
