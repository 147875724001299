import React, { useEffect } from 'react';

import { scrollTopToMainChat } from '../../../../services/dom';
import SwitchMessageContent from '../SwitchMessageContent';
import { formatDate } from '../../../../services/time';
import { useSelector } from 'react-redux';
export default ({ message, info, history, currentChatState }) => {
    const profilesState = useSelector((state) => state.profiles);
    const womanProfile = profilesState?.list?.find((woman) => +woman?.external_id === +info?.female_external_id);

    useEffect(() => {
        history?.length && scrollTopToMainChat(currentChatState?.lastMessageId);
    }, [history]);

    if (message?.message_content?.length > 0) {
        return (
            <div
                className={`chat_message_wrap right ${message?.message_type === 'SENT_STICKER' ? 'sticker' : ''}`}
                id={`mess-${message?.id}`}
            >
                <div className="chat_message">
                    <div className="message_content">
                        <SwitchMessageContent data={message} />
                        <div className="message_bottom">
                            <div className="message_date">{formatDate(message?.date_created)}</div>
                            <div className={'message_status' + (+message?.read_status ? ' readed' : ' send')} />
                        </div>
                    </div>
                    <div className="message_avatar">
                        <img src={womanProfile?.photo_link} alt="" />
                    </div>
                </div>
            </div>
        );
    } else {
        return false;
    }
};
