import * as MAILBOX from '../constants/mailbox';

const initState = {
    mailList: [],
    mailChatInfo: {},
    mailListPage: 1,
    mailListAllPages: 1,
    mailCurrentPage: 1,
    status: false,
    isLoading: false,

    maleList: [],
    checkedRecipients: [],
    checkedSender: {},

    countUnread: 0,
};

export default function (state = initState, action) {
    let newArr = [];

    switch (action.type) {
        case MAILBOX.FETCH_MAILBOX_GET:
            return {
                ...state,
                ...{
                    mailList: [],
                    mailListPage: 1,
                    mailListAllPages: 1,
                    mailChatInfo: {},
                    status: false,
                    isLoading: true,
                },
            };

        case MAILBOX.FETCH_MAILBOX_GET_WITH_PAGE:
            return {
                ...state,
                ...{
                    mailList: [],
                    mailCurrentPage: action?.data?.page,
                },
            };

        case MAILBOX.MAILBOX_GET:
            return {
                ...state,
                ...{
                    mailList: action?.data?.mails,
                    mailChatInfo: action?.data?.chat,
                    mailListPage: 1,
                    mailListAllPages: +action?.data?.pages === 0 ? state?.mailListAllPages : action?.data?.pages,
                    status: action?.data?.status,
                    isLoading: false,
                },
            };

        case MAILBOX.MAILBOX_GET_ADD_HISTORY_PAGE:
            if (action?.data?.mails?.length !== 0) {
                return {
                    ...state,
                    ...{
                        mailList: [...action.data.mails, ...state.mailList],
                        mailListPage: action?.mailListPage,
                        mailListAllPages: action?.data?.pages,
                    },
                };
            } else {
                return state;
            }

        case MAILBOX.MAILBOX_GET_DRAFTS:
            return {
                ...state,
                ...{
                    mailList: action?.data?.drafts,
                    status: action?.data?.status,
                    mailListPage: 1,
                    mailListAllPages: action?.data?.pages,
                    isLoading: false,
                },
            };

        case MAILBOX.MAILBOX_GET_DRAFTS_WITH_PAGE:
            if (action?.data?.drafts?.length !== 0) {
                return {
                    ...state,
                    ...{
                        mailList: [...action.data.drafts, ...state.mailList],
                        mailListPage: action?.mailListPage,
                        mailListAllPages: action?.data?.pages,
                    },
                };
            } else {
                return state;
            }

        case MAILBOX.ADD_CHECKED_RECIPIENTS:
            return {
                ...state,
                ...{
                    checkedRecipients: state.checkedRecipients.concat(action?.data),
                },
            };

        case MAILBOX.REMOVE_CHECKED_RECIPIENTS:
            state.checkedRecipients.forEach((item) => {
                if (item?.external_id !== action?.data?.external_id) {
                    newArr.push(item);
                }
            });
            return {
                ...state,
                ...{
                    checkedRecipients: newArr,
                },
            };

        case MAILBOX.REMOVE_ALL_CHECKED_RECIPIENTS:
            return {
                ...state,
                ...{
                    checkedRecipients: [],
                },
            };

        case MAILBOX.MAILBOX_GET_UNREAD_COUNT:
            return {
                ...state,
                ...{
                    countUnread: action?.data?.result,
                },
            };

        case MAILBOX.MAILBOX_GET_MALE_LIST:
            return {
                ...state,
                ...{
                    maleList: action?.data?.result,
                },
            };

        case MAILBOX.ADD_CHECKED_SENDER:
            return {
                ...state,
                ...{
                    checkedSender: action?.data,
                },
            };

        case MAILBOX.UPDATE_DRAFT_BY_ID:
            state.mailList.forEach((item) => {
                if (+item?.draft?.id === +action?.data?.draftIds) {
                    if (action?.data?.messageContent) {
                        item.draft.message_content = action?.data?.messageContent;
                    }
                    if (action?.data?.attachments?.length) {
                        item.attachments = action?.data?.attachments;
                    }
                }
            });
            return {
                ...state,
                ...{
                    mailList: state?.mailList,
                },
            };
        default:
            return state;
    }
}
