import React from 'react';
import Styles from '../styles.module.scss';
import Modal from 'react-modal';
import AttachModalNew from '../../../../common/AttachModalNew';
import attachStyles from '../../../../../styles/react-modal/attach.style';
import * as ATTACH from '../../../../../constants/attach';
import useInitAttachModal from '../../../../../hooks/attachModal/useInitAttachModal';

export default function Attach({ activeTypeFile, setActiveTypeFile }) {
    const { isAttachModal, openAttachModal, closeAttachModal, onSubmitMessageContent, womanProfile } =
        useInitAttachModal();

    return (
        <>
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.image}`}
                onClick={() => {
                    setActiveTypeFile(ATTACH.TAB_IMAGE);
                    openAttachModal();
                }}
                data-testid="add-image-btn"
            />
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.video}`}
                onClick={() => {
                    setActiveTypeFile(ATTACH.TAB_VIDEO);
                    openAttachModal();
                }}
                data-testid="add-video-btn"
            />
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.audio}`}
                onClick={() => {
                    setActiveTypeFile(ATTACH.TAB_AUDIO);
                    openAttachModal();
                }}
                data-testid="add-audio-btn"
            />
            <Modal style={attachStyles} isOpen={isAttachModal} onRequestClose={closeAttachModal} ariaHideApp={false}>
                <AttachModalNew
                    closeModal={closeAttachModal}
                    sendImg={(e) => onSubmitMessageContent(e, 'SENT_IMAGE')}
                    sendVideo={(e) => onSubmitMessageContent(e, 'SENT_VIDEO')}
                    sendAudio={(e) => onSubmitMessageContent(e, 'SENT_AUDIO')}
                    userId={+womanProfile?.external_id}
                    activeTypeFile={activeTypeFile}
                    setActiveTypeFile={setActiveTypeFile}
                />
            </Modal>
        </>
    );
}
