import * as NOTIFY from '../constants/notify';
import { generationDateOnFewMinutesLonger } from '../../services/methods';
import * as TIMER from '../../constants/timer';

export function checkNotifyExpDate(operatorId) {
    return function (dispatch) {
        return dispatch({
            type: NOTIFY.CHECK_EXP_DATE,
            operatorId,
        });
    };
}

export function openMobNotify() {
    return function (dispatch) {
        return dispatch({ type: NOTIFY.OPEN_MOB_NOTIFY });
    };
}

export function closeMobNotify() {
    return function (dispatch) {
        return dispatch({ type: NOTIFY.CLOSE_MOB_NOTIFY });
    };
}

export function addFreeNotify(notify) {
    return function (dispatch) {
        return dispatch({ type: NOTIFY.ADD_FREE_NOTIFY, notify });
    };
}

export function addPaidNotify(notify, operatorId) {
    return function (dispatch) {
        return dispatch({
            type: NOTIFY.ADD_PAID_NOTIFY,
            notify: {
                ...notify,
                ...{ expires_at: generationDateOnFewMinutesLonger(TIMER.PAID_NOTIFY_EXP_DATE_MIN) },
            },
            operatorId,
        });
    };
}

export function removeAllFreeNotify() {
    return function (dispatch) {
        return dispatch({ type: NOTIFY.REMOVE_ALL_FREE_NOTIFY });
    };
}

export function removeAllPaidNotify(operatorId) {
    return function (dispatch) {
        return dispatch({ type: NOTIFY.REMOVE_ALL_PAID_NOTIFY, operatorId });
    };
}

export function removeFreeNotifyByID(notifyID) {
    return function (dispatch) {
        return dispatch({ type: NOTIFY.REMOVE_FREE_NOTIFY_BY_ID, notifyID });
    };
}

export function removePaidNotifyByID(notifyID, operatorId) {
    return function (dispatch) {
        return dispatch({ type: NOTIFY.REMOVE_PAID_NOTIFY_BY_ID, notifyID, operatorId });
    };
}

export function removePaidNotifyByChatUID(chatUID, notificationTypeArr, operatorId) {
    return function (dispatch) {
        return dispatch({
            type: NOTIFY.REMOVE_PAID_NOTIFY_BY_CHATUID,
            chatUID,
            notificationTypeArr,
            operatorId,
        });
    };
}

// export function removeLetterNotifyByWomanIDAndManID(womanID, manID) {
// 	return function (dispatch) {
// 		return dispatch({type: NOTIFY.REMOVE_LETTER_NOTIFY_BY_WOMANID_AND_MANID, womanID, manID})
// 	}
// }

export function checkPaidNotifyInLocalStorage(operatorId) {
    return function (dispatch) {
        return dispatch({ type: NOTIFY.CHECK_PAID_NOTIFY_IN_LOCAL_STORAGE, operatorId });
    };
}

export function checkFreeNotify() {
    return function (dispatch) {
        return dispatch({ type: NOTIFY.CHECK_FREE_NOTIFY });
    };
}

export function getPaidNotify(operatorId) {
    return function (dispatch) {
        const notifyList = JSON.parse(localStorage.getItem(`notify_${operatorId}`));
        if (notifyList?.length > 0) return dispatch({ type: NOTIFY.GET_PAID_NOTIFY, notifyList });
    };
}

export function updateFreeOnlineNotify(notifyID, updatedNotify) {
    return function (dispatch) {
        if (notifyID) return dispatch({ type: NOTIFY.UPDATE_FREE_ONLINE_NOTIFY, notifyID, updatedNotify });
    };
}
