import React, { useState } from 'react';
import AttachDataToLetter from '../../AttachDataToLetter';
import Styles from './MessageForm.module.scss';
import * as IMAGES from '../../Images';
import useHandleLettersForm from '../../../../hooks/letters/useHandleLettersForm';
import useInitTranslate from '../../../../hooks/translate/useInitTranslate';
import useHandleSmileBlock from '../../../../hooks/emoji/smile/useHandleSmileBlock';
import Select from 'react-select';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Slider from 'react-slick';
import { settings } from '../../../../services/slick-carousel-config/settings';
import Emoji from '../../../letters-new/LettersNewChat/FormLetters/Emoji';
import Attach from '../../../letters-new/LettersNewChat/FormLetters/Attach';

export default ({ checkedRecipientsID, closeWriteLetterModal, sender }) => {
    const handleLettersForm = useHandleLettersForm(sender?.external_id, checkedRecipientsID);

    const {
        isOpenTranslateBlock,
        handleLetterContent,
        onSubmitMessage,
        textSourceTranslate,
        textTargetTranslate,
        messageContent,
        setTextSourceTranslate,
        setTextTargetTranslate,
        handleTextSourceTranslate,
        handleTextTargetTranslate,
        handleTranslateSwitcher,
        attachData,
        addEmoji,
        isDisabledButton,
        setIsDisabledButton,
        checkOnMaxLength,
        onEnterMessageContent,
    } = handleLettersForm;

    const {
        postTranslateText,
        translateOptions,
        activeSourceTranslate,
        selectSourceTranslate,
        activeTargetTranslate,
        selectTargetTranslate,
        switchTargetTranslate,
    } = useInitTranslate(
        isOpenTranslateBlock,
        isDisabledButton,
        setIsDisabledButton,
        textSourceTranslate,
        textTargetTranslate,
        setTextSourceTranslate,
        setTextTargetTranslate,
    );

    const [activeTypeFile, setActiveTypeFile] = useState('');

    const handleSmileBlock = useHandleSmileBlock();

    return (
        <div className={Styles.clmn_3_chat_bottom}>
            {!isOpenTranslateBlock ? (
                <textarea
                    className={Styles.clmn_3_chat_textarea}
                    data-testid="message"
                    value={messageContent}
                    autoFocus={true}
                    onKeyDown={onEnterMessageContent}
                    onChange={(e) => handleLetterContent(e.target.value)}
                />
            ) : (
                <div className={Styles.clmn_3_chat_textarea_double}>
                    <div className={Styles.clmn_3_chat_textarea_wrap}>
                        <div className={Styles.clmn_3_chat_textarea_translate_info}>
                            <Select
                                className={Styles.clmn_3_chat_textarea_translate_select}
                                defaultValue={activeSourceTranslate}
                                value={activeSourceTranslate}
                                onChange={selectSourceTranslate}
                                options={translateOptions}
                            />
                            <div className={`${Styles.clmn_3_chat_textarea_translate_symbols}`}>
                                {textSourceTranslate?.length} symbols
                            </div>
                        </div>
                        <textarea
                            className={Styles.clmn_3_chat_textarea_inner}
                            value={textSourceTranslate}
                            autoFocus={true}
                            onKeyDown={(e) => onEnterMessageContent(e, () => postTranslateText())}
                            onChange={(e) => handleTextSourceTranslate(e.target.value)}
                        />
                    </div>

                    <div className={Styles.clmn_3_chat_textarea_translate_arrow_wrap}>
                        <div
                            className={`${Styles.clmn_3_chat_textarea_translate_arrow} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                            onClick={switchTargetTranslate}
                        >
                            <CompareArrowsIcon />
                        </div>

                        <div
                            className={`${Styles.clmn_3_chat_textarea_translate_arrow} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                            onClick={postTranslateText}
                        >
                            <ArrowForwardIcon />
                        </div>
                    </div>

                    <div className={Styles.clmn_3_chat_textarea_wrap}>
                        <div className={Styles.clmn_3_chat_textarea_translate_info}>
                            <Select
                                className={Styles.clmn_3_chat_textarea_translate_select}
                                defaultValue={activeTargetTranslate}
                                value={activeTargetTranslate}
                                onChange={selectTargetTranslate}
                                options={translateOptions}
                            />

                            <div
                                className={`${Styles.clmn_3_chat_textarea_translate_symbols} ${!checkOnMaxLength(textTargetTranslate) ? Styles.error : ''}`}
                            >
                                {textTargetTranslate?.length} symbols
                            </div>
                        </div>
                        <textarea
                            className={Styles.clmn_3_chat_textarea_inner}
                            value={textTargetTranslate}
                            onChange={(e) => handleTextTargetTranslate(e.target.value)}
                            onKeyDown={onEnterMessageContent}
                        />
                    </div>
                </div>
            )}

            {attachData?.length > 0 ? (
                <div className="c3_write_msg_textareas_files">
                    <div className="letters_attach_files_list">
                        <div className="carousel_container">
                            <Slider {...settings}>
                                {attachData.map((item, key) => (
                                    <AttachDataToLetter key={key} {...handleLettersForm} item={item} index={key} />
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            ) : (
                <div />
            )}
            <div className={Styles.clmn_3_chat_bottom_nav}>
                <div className={Styles.clmn_3_chat_bottom_nav_left}>
                    <div className={Styles.clmn_3_chat_bottom_options}>
                        <Emoji {...handleSmileBlock} addEmoji={addEmoji} />
                        <Attach
                            {...handleLettersForm}
                            activeTypeFile={activeTypeFile}
                            setActiveTypeFile={setActiveTypeFile}
                            femaleExternalId={sender?.external_id}
                        />
                    </div>

                    {!isOpenTranslateBlock && (
                        <div className={Styles.clmn_3_chat_bottom_symbols} data-testid="entered-symbols">
                            {messageContent?.length} symbols entered
                        </div>
                    )}
                </div>
                <div className={Styles.clmn_3_chat_bottom_nav_right}>
                    <div className={Styles.clmn_3_chat_bottom_translate}>
                        <div className={Styles.clmn_3_chat_bottom_translate_tmblr} data-testid="open-translator">
                            <input
                                type="checkbox"
                                id="translate_tmblr"
                                value={+isOpenTranslateBlock}
                                className={Styles.clmn_3_chat_bottom_translate_tmblr_inp}
                            />
                            <label
                                htmlFor="translate_tmblr"
                                onClick={handleTranslateSwitcher}
                                className={Styles.clmn_3_chat_bottom_translate_tmblr_label}
                            />
                        </div>
                        <div className={Styles.clmn_3_chat_bottom_translate_text}>Translate</div>
                    </div>
                    <div
                        className={`${Styles.clmn_3_chat_bottom_send_btn} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                        onClick={() => onSubmitMessage('SEND_LETTER', null, true, closeWriteLetterModal)}
                        data-testid="send-btn"
                    >
                        <img src={IMAGES.default.send_message} alt="" />
                        <span>Send</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
