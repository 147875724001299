import React, { useState } from 'react';

import * as MENU from '../../../constants/menu';

export default function SecondProfile({ girlProfileInfo }) {
    const [activeTab, setActiveTab] = useState(MENU.TAB_ABOUT);

    return (
        <div className="c3_woman_profile_tabs_wrap">
            <div className="c3_woman_profile_tabs_links_wrap">
                <button
                    className={`c3_woman_profile_tabs_link ${activeTab === MENU.TAB_ABOUT ? 'active' : ''}`}
                    onClick={() => setActiveTab(MENU.TAB_ABOUT)}
                >
                    About me
                </button>
                <button
                    className={`c3_woman_profile_tabs_link ${activeTab === MENU.TAB_BLOG ? 'active' : ''}`}
                    onClick={() => setActiveTab(MENU.TAB_BLOG)}
                >
                    blog
                </button>
            </div>
            <div
                className={`c3_woman_profile_tab_block ${activeTab === MENU.TAB_ABOUT ? 'active' : ''}`}
                id="tab_about"
            >
                <div className="c3_woman_profile_tab_about">
                    {girlProfileInfo.about && (
                        <div className="c3_woman_profile_tab_about_item">
                            <div className="c3_woman_profile_tab_about_item_title" data-testid="woman-self-summary">
                                MY self-summary
                            </div>
                            <div className="c3_woman_profile_tab_about_item_text">{girlProfileInfo.about}</div>
                        </div>
                    )}
                    {girlProfileInfo.seeking && (
                        <div className="c3_woman_profile_tab_about_item">
                            <div className="c3_woman_profile_tab_about_item_title" data-testid="woman-looking-for">
                                Who Am I looking for
                            </div>
                            <div className="c3_woman_profile_tab_about_item_text">{girlProfileInfo.seeking}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className={`c3_woman_profile_tab_block ${activeTab === MENU.TAB_BLOG ? 'active' : ''}`} id="tab_blog">
                <div className="c3_woman_profile_tab_blog"></div>
            </div>
        </div>
    );
}
