import * as MENU from '../../constants/menu';
import * as REGEXP from '../../constants/regExp';
import React from 'react';
import axios from 'axios';
import * as AWS from '../../constants/aws';
import * as ATTACH from '../../constants/attach';
import moment from 'moment';

export function sortListByDate(list, type) {
    if (type) return list?.sort((a, b) => Date.parse(b?.date_created) - Date.parse(a?.date_created));
    else return list?.sort((a, b) => Date.parse(a?.date_created) - Date.parse(b?.date_created));
}

export function getValueFromLocalStorage(localStorageKeyName = '', indexFromLocalStorage = '') {
    return (
        (localStorage.getItem(localStorageKeyName) &&
            JSON.parse(localStorage.getItem(localStorageKeyName))?.[indexFromLocalStorage]) ??
        ''
    );
}

export function generationDateOnFewMinutesLonger(minutes) {
    const expDate = new Date();
    expDate.setMinutes(expDate.getMinutes() + +minutes);
    return moment(expDate).format('YYYY/MM/DD HH:mm:ss');
}

export function uniqueArrayByID(arr) {
    if (!Array.isArray(arr)) return null;
    let tmp = [];
    let result = [];

    for (let item of arr) {
        if (!tmp.includes(+item?.id)) {
            tmp.push(+item?.id);
            result.push(item);
        }
    }

    return result;
}

export function uniqueArrayByField(arr, field) {
    if (!Array.isArray(arr)) return null;
    let tmp = [];
    let result = [];

    for (let item of arr) {
        if (!tmp.includes(+item[field])) {
            tmp.push(+item[field]);
            result.push(item);
        }
    }

    return result;
}

export function uniqueArrayByChatUid(arr) {
    if (!Array.isArray(arr)) return null;
    let tmp = [];
    let result = [];

    for (let item of arr) {
        if (!tmp.includes(item?.chat_uid)) {
            tmp.push(item?.chat_uid);
            result.push(item);
        }
    }

    return result;
}

export function unique(arr) {
    if (!Array.isArray(arr)) return null;
    let result = [];

    for (let str of arr) {
        if (!result.includes(str)) {
            result.push(str);
        }
    }

    return result;
}

export function objectToArray(obj) {
    if (obj === 'a:0:{}') return [];
    const arr = [];

    for (let key in obj) {
        arr.push({ ...{ id: key }, ...obj[key] });
    }

    return arr;
}

export function cutLengthString(str, maxLength) {
    return str?.length <= maxLength ? str : str?.substr(0, maxLength)?.trim() + '...';
}

export function getReadableFileSizeString(fileSizeInBytes) {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export function getChatListMenuType(menu) {
    if (menu?.includes(MENU.MENU_FAVORITE)) return MENU.MENU_FAVORITE;
    else if (menu?.includes(MENU.MENU_DIALOGUES)) return MENU.MENU_DIALOGUES;
    else if (menu?.includes(MENU.MENU_MAYBE)) return MENU.MENU_MAYBE;
    else if (menu?.includes(MENU.MENU_SPECIAL)) return MENU.MENU_SPECIAL;
    else if (menu?.includes(MENU.MENU_MATCHES)) return MENU.MENU_MATCHES;
    else if (menu?.includes(MENU.MENU_UNANSWERED)) return MENU.MENU_UNANSWERED;
    else if (menu?.includes(MENU.MENU_CHAT)) return MENU.MENU_CHAT;
    else return '';
}

export function getChatListType(menu) {
    if (menu?.includes(MENU.MENU_FAVORITE)) return MENU.GET_FAVORITE;
    else if (menu?.includes(MENU.MENU_DIALOGUES)) return MENU.GET_DIALOGUES;
    else if (menu?.includes(MENU.MENU_MAYBE)) return MENU.GET_MAYBE;
    else if (menu?.includes(MENU.MENU_SPECIAL)) return MENU.GET_SPECIAL;
    else if (menu?.includes(MENU.MENU_MATCHES)) return MENU.GET_MATCHES;
    else if (menu?.includes(MENU.MENU_UNANSWERED)) return MENU.GET_UNREAD;
    else if (menu?.includes(MENU.MENU_CHANCE)) return MENU.GET_CHANCE;
    else return MENU.GET_ALL;
}

export function switchChatList(menu) {
    switch (menu) {
        case MENU.MENU_FAVORITE:
            return MENU.GET_FAVORITE;
        case MENU.MENU_DIALOGUES:
            return MENU.GET_DIALOGUES;
        case MENU.MENU_MAYBE:
            return MENU.GET_MAYBE;
        case MENU.MENU_SPECIAL:
            return MENU.GET_SPECIAL;
        case MENU.MENU_LOOKING:
            return MENU.GET_LOOKING_AT_YOU;
        case MENU.MENU_CONNECTIONS:
            return MENU.GET_CONNECTION;
        case MENU.MENU_UNANSWERED:
            return MENU.GET_UNREAD;
        default:
            return MENU.GET_ALL;
    }
}

export function renderLastMessage(type, message, isFromMan, manName) {
    let renderData;

    switch (type) {
        case 'SENT_IMAGE':
            renderData = 'sent image ' /*+ item.filename*/;
            break;
        case 'SENT_AUDIO':
            renderData = 'sent audio ' /*+ item.filename*/;
            break;
        case 'SENT_VIDEO':
            renderData = 'sent video ' /*+ item.filename*/;
            break;
        case 'SENT_WINK':
            renderData = isFromMan ? `${manName} winked you` : 'You sent a wink';
            break;
        case 'SENT_STICKER':
            renderData = 'sent sticker';
            break;
        case 'SENT_VIRTUAL_GIFT':
            renderData = 'sent a virtual gift';
            break;
        case 'SENT_LIKE':
            renderData = isFromMan ? `${manName} liked your profile` : `You like ${manName} profile`;
            break;
        case 'NEW_GIRL':
        case 'PROFILE_UPDATE':
        case 'STRANGER':
        case 'POSSIBLE_MATCH':
        case 'SEARCH_PARTNER':
        case 'MATCH':
        case 'LIKE':
        case 'VIEW_PROFILE':
        case 'SENT_VIEW_PHOTOS':
        case 'SENT_VIEW':
            // renderData = isFromMan ? ' Viewed your profile' : ' viewed a profile';
            renderData = '';
            break;
        default:
            renderData = message;
            break;
    }
    return renderData;
}

export function isTopPositionInArrHistoryMails(arr, index) {
    let posIndex = null;
    arr.forEach((item, key) => {
        if (+item?.mail?.id === +index) {
            posIndex = key;
        }
    });
    return arr?.length / 2 >= posIndex;
}

export function firstCharacterToUppercase(str) {
    return str[0].toUpperCase() + str.slice(1);
}

export function checkUrl(str) {
    return str.search(REGEXP.URL) >= 0 || str.search(REGEXP.URL_DOMAIN) >= 0;
}

export function stringToColor(str) {
    let hash = 0;
    let color = '#';
    let i;
    let value;
    let strLength;

    if (!str) {
        return color + '333333';
    }

    strLength = str.length;

    for (i = 0; i < strLength; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    for (i = 0; i < 3; i++) {
        value = (hash >> (i * 8)) & 0xff;
        color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
}

export function substrName(name = '') {
    return name ? name.substr(0, 2) : name;
}

export function userAvatar(image, name, stylesString, stylesObj) {
    if (image) {
        return <img src={getAvatarMiniature(image)} alt="" className={stylesString} />;
    } else {
        return (
            <div className={stylesObj} style={{ backgroundColor: stringToColor(name) }}>
                {substrName(name)}
            </div>
        );
    }
}

export function initCancelTokenSource(state, setState, message = 'Operation canceled by the user.') {
    state && state.cancel(message);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setState(source);

    return source;
}

export function getAvatarMiniature(link = null, sizeName = AWS.AWS_THUMBS_DEFAULT_TYPE) {
    let splitLink = link.split(`/`);

    AWS.AWS_THUMBS.forEach((item) => {
        if (item.name === sizeName) {
            splitLink[splitLink.length - 1] = `w-${item.width}-h-${item.height}-${splitLink[splitLink.length - 1]}`;
            link = splitLink.join('/');
        }
    });

    return link;
}

export function hideStringStar(string = '', rules = REGEXP.NUMBER_IN_STRING) {
    const foundStr = string.match(rules);

    foundStr?.length > 0 &&
        foundStr.forEach((item) => {
            string = string.replace(item, '*'.repeat(item.length));
        });

    return string;
}

export function makeCDN(image, type = 'IMAGE') {
    switch (type) {
        case 'POSTS':
            return image?.replace(AWS.AWS_CLOUDFRONT_POSTS?.origin, AWS.AWS_CLOUDFRONT_POSTS?.cdn);
        case 'VIDEO':
            return image?.replace(AWS.AWS_CLOUDFRONT_VIDEO?.origin, AWS.AWS_CLOUDFRONT_VIDEO?.cdn);
        case 'AUDIO':
            return image?.replace(AWS.AWS_CLOUDFRONT_AUDIO?.origin, AWS.AWS_CLOUDFRONT_AUDIO?.cdn);
        case 'IMAGE':
        default:
            return image?.replace(AWS.AWS_CLOUDFRONT_IMAGE?.origin, AWS.AWS_CLOUDFRONT_IMAGE?.cdn);
    }
}

export function checkFileAndShowAlert({ type, name }, fileType = '', callback) {
    switch (fileType) {
        case ATTACH.TYPE_IMAGE:
            if (!type.includes('jpeg') && !type.includes('jpg') && !type.includes('png')) {
                callback(`Please upload a file of jpg or png format`, 'Wrong file format');
                return false;
            }
            if (type.split('/')[0] !== ATTACH.TYPE_IMAGE) {
                callback(`Probably, you've uploaded not an image file. File must be an image`, `Wrong file attached`);
                return false;
            }

            break;

        case ATTACH.TYPE_AUDIO:
            if (type?.split('/')[0] !== ATTACH.TYPE_AUDIO) {
                callback(`Probably, you've uploaded not an audio file. File must be an audio`, `Wrong file attached`);
                return false;
            }

            break;

        case ATTACH.TYPE_VIDEO:
            if (type?.split('/')[0] !== 'video') {
                callback(`Probably, you've uploaded not a video file. File must be a video`, `Wrong file attached`);
                return false;
            }

            break;

        default:
            break;
    }

    if (fileType !== ATTACH.TYPE_VIDEO && fileType !== ATTACH.TYPE_AUDIO) {
        if (name.search(REGEXP.IMAGE_FILENAME) >= 0) {
            callback(`File name doesn't have to contain special characters`, 'Wrong file name');
            return false;
        }
    }

    return true;
}

export function checkFile(file, fileType = '') {
    if (!file) return false;
    const { type } = file;

    switch (fileType) {
        case ATTACH.TYPE_IMAGE:
            if (!type.includes('jpeg') && !type.includes('jpg') && !type.includes('png')) {
                return false;
            }
            if (type.split('/')[0] !== ATTACH.TYPE_IMAGE) {
                return false;
            }

            break;

        case ATTACH.TYPE_AUDIO:
            if (!type.includes('mp3') && !type.includes('mpeg') && !type.includes('ogg')) {
                return false;
            }
            if (type?.split('/')[0] !== ATTACH.TYPE_AUDIO) {
                return false;
            }

            break;

        case ATTACH.TYPE_VIDEO:
            if (type?.split('/')[0] !== 'video') {
                return false;
            }

            if (!['video/mp4', 'video/mov', 'video/avi', 'video/quicktime', 'video/x-msvideo'].includes(type)) {
                return false;
            }

            break;

        default:
            break;
    }

    return true;
}

export function getUrlParameter(sParam) {
    let sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam)
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
}
