import React from 'react';
import DnDContainer from '../DragAndDrop/Container';
import Loader from '../Loader';

export default ({ type, images, status, userId }) => {
    return (
        <div className="upload_popup_tabs_content_middle photo" id="uptcmp">
            <DnDContainer userId={userId} type={type} data={images} />
            <Loader status={status} />
        </div>
    );
};
