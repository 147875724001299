import { useSelector } from 'react-redux';
import useTransition from '../transition/useTransition';
import useCloseCurrentLetter from '../letters/useCloseCurrentLetter';
import * as MENU from '../../constants/menu';
import useGetChatListDebounce from '../chat/useGetChatListDebounce';

export default function useHandleMainMenu() {
    const menuState = useSelector((state) => state.menu);
    const transition = useTransition();
    const closeCurrentMailbox = useCloseCurrentLetter();
    const { debouncedGetChatList } = useGetChatListDebounce();

    const openFolder = (folder) => {
        closeCurrentMailbox();
        transition.transitionPush(`/${folder}`);
        if (MENU.MENU_CHAT_LIST?.includes(folder)) {
            debouncedGetChatList();
        }
    };

    return {
        activeFolder: menuState.main,
        openFolder,
    };
}
