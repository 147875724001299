import Base from './Base';
import { objectToArray } from '../methods';

export default class BlockApi extends Base {
    getBlockList(maleBlock = false, token = null) {
        return super
            .post(
                `chat/blockListByUserID`,
                { male_block: Number(!maleBlock), female_block: Number(maleBlock) },
                token ? { cancelToken: token } : {},
            )
            .then((res) =>
                res?.data?.status
                    ? {
                          blockList: objectToArray(res?.data?.chats),
                          status: res?.data?.status,
                          message: 'OK',
                      }
                    : {
                          blockList: [],
                          status: res?.data?.status,
                          message: res?.data?.error,
                      },
            )
            .catch(() => ({ canceled: true }));
    }
}
