import * as POSTS from '../constants/posts';

const initState = {
    posts: [],
    page: 1,
    allCount: 0,
    countStatuses: {},
};

export default function (state = initState, action) {
    switch (action.type) {
        case POSTS.GET_ALL_POSTS:
            return {
                ...state,
                ...{
                    posts: action?.data?.posts,
                    page: action?.data?.page,
                    allCount: Object.values(action?.data?.countStatuses).reduce(
                        (acc, currentValue) => acc + currentValue,
                        0,
                    ),
                    countStatuses: action?.data?.countStatuses,
                },
            };

        case POSTS.UPDATE_CURRENT_POST:
            return {
                ...state,
                ...{
                    posts: state.posts.map((item) => (item?.id === action?.data?.id ? action?.data : item)),
                },
            };

        case POSTS.CLEAR_POSTS:
            return initState;

        default:
            return state;
    }
}
