import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as filesAction from '../../../../../store/actions/files';
import * as modalsAction from '../../../../../store/actions/modals';
import * as loaderAction from '../../../../../store/actions/loader';
import * as alertAction from '../../../../../store/actions/alerts';
import FilesApi from '../../../../../services/api/FilesApi';
import * as ATTACH from '../../../../../constants/attach';
import * as AWS from '../../../../../constants/aws';
import * as IMAGES from '../../../Images';
import * as ALERTS from '../../../../../store/constants/alerts';
import { sendErrToSentry } from '../../../../../services/sentry';

export default ({ checked, elementRef, data, opacity }) => {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState(false);

    const handleInputChangeImg = (e) => {
        const checkBox = e.currentTarget.querySelector('input');
        setSelectedItem(!checkBox.checked);
        checkBox.checked = !checkBox.checked;
    };

    let thumbList = {};
    AWS.AWS_THUMBS.forEach((size) => {
        let thumb = data.link.split('/');
        thumb[thumb?.length - 1] = `w-${size.width}-h-${size.height}-${thumb[thumb.length - 1]}`;
        thumb = thumb.join('/');
        thumbList[size.name] = thumb;
    });

    const openContentModal = (e) => {
        dispatch(loaderAction.setActiveStatus(true));
        dispatch(
            modalsAction.openPhotoContentModal({
                link: e.currentTarget?.dataset?.link,
                attachment_payed: '1',
            }),
        );
    };

    const onDeleteItem = () => {
        dispatch(
            alertAction.setCustomConfirmAlert({
                type: ALERTS.SET_CUSTOM_CONFIRM_ALERT,
                title: `Are you sure you want to delete this file?`,
                btnName1: 'No',
                btnName2: 'Yes',
                successCallback: () => deleteItem(),
            }),
        );
    };

    const deleteItem = () => {
        dispatch(loaderAction.setActiveGifLoader(true));
        try {
            new FilesApi()
                .postDeleteMedia(data?.id, data?.external_id)
                .then((res) => {
                    if (res?.status) {
                        dispatch(filesAction.getAllImages(data?.external_id));
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        } catch (error) {
            sendErrToSentry(error);
            dispatch(loaderAction.setActiveGifLoader(false));
        }
    };

    const handleSort = (e) => {
        const listItems = e.currentTarget.parentNode.querySelectorAll('.upload_popup_tabs_content_item');
        const sortedArr = [];

        // for (let [key, item] of listItems.entries()) {
        // 	if (item.dataset.id) {
        // 		sortedArr.push({
        // 			id: item.dataset.id,
        // 			sort_order: key,
        // 		});
        // 	}
        // }

        for (let [, item] of listItems.entries()) {
            if (item.dataset.id) {
                sortedArr.push({
                    id: +item.dataset.id,
                });
            }
        }

        new FilesApi().postUpdateSortOrder(ATTACH.UPD_SORT_IMAGE, sortedArr, data?.external_id);
    };

    return (
        <div
            className={`upload_popup_tabs_content_item ${selectedItem ? 'selected' : ''}`}
            ref={elementRef}
            style={{ opacity }}
            data-id={data?.id}
            onDrop={handleSort}
            data-testid={data?.filename}
        >
            <div className="no-drag upload_popup_tabs_content_item_top" onClick={handleInputChangeImg}>
                <input
                    className="no-drag"
                    type="checkbox"
                    name={data?.id}
                    checked={checked}
                    data-link={data?.link}
                    data-filename={data?.filename}
                    data-contentid={data.id}
                    onChange={handleInputChangeImg}
                />
                <label htmlFor="pp1" className="no-drag">
                    <img src={thumbList.small} alt="" />
                </label>
            </div>
            <div className="upload_popup_tabs_content_item_bottom">
                <div className="popup_full_img" data-link={thumbList.big} onClick={openContentModal}>
                    <img src={IMAGES.photo_full} alt="" />
                </div>
                <div className="popup_trash" onClick={onDeleteItem}>
                    <img src={IMAGES.trash} alt="" />
                </div>
            </div>
        </div>
    );
};
