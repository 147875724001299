import { useDispatch, useSelector } from 'react-redux';
import md5 from 'js-md5';
import moment from 'moment';
import ChatsAPI from '../../../services/api/ChatsApi';
import * as currentChatAction from '../../../store/actions/currentChat';
import * as chatsAction from '../../../store/actions/chats';
import * as alertsAction from '../../../store/actions/alerts';
import * as ERROR from '../../../constants/error';
import * as TYPES from '../../../constants/chat';

export default function useSendSticker() {
    const dispatch = useDispatch();

    const chatsState = useSelector((state) => state.chats);
    const currentChatState = useSelector((state) => state.currentChat);

    return (selectedSticker) => {
        const hashId = md5(moment().format());

        new ChatsAPI()
            .postMessage(
                +currentChatState?.info?.female_external_id,
                +currentChatState?.info?.male_external_id,
                selectedSticker,
                TYPES.MESSAGE_CONTENT_TYPE_STICKER,
            )
            .then((res) => {
                function badRequest() {
                    dispatch(currentChatAction.removeMessageInHistory(hashId));

                    if (res?.error) {
                        if (res?.error?.includes('no connection')) {
                            dispatch(currentChatAction.updateMessageLimit(0, 0));
                            return dispatch(alertsAction.setMessage('', 'Message limit reached'));
                        }
                        dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                    } else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                }

                if (!res?.response?.message_object || !res?.status) return badRequest();
                if (res?.status) {
                    dispatch(
                        currentChatAction.updateMessageLimit(
                            res?.response?.chat_list_object?.message_limit,
                            res?.response?.chat_list_object?.like_limit,
                        ),
                    );
                    dispatch(currentChatAction.updateMessageInHistory(hashId, res?.response?.message_object));
                    dispatch(
                        chatsAction.updateActiveChat({ ...chatsState?.activeChat, ...res?.response?.chat_list_object }),
                    );
                } else {
                    dispatch(alertsAction.setMessage('', ERROR.NOT_EXIST_OR_BLOCK));
                }
            });
    };
}
