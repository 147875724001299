export const SET_LIST_BY_SEARCH = 'SET_LIST_BY_SEARCH';
export const SET_FILTER_BY_SEARCH = 'SET_FILTER_BY_SEARCH';
export const SET_FILTERS_SEARCH = 'SET_FILTERS_SEARCH';
export const SET_NEXT_SEARCH_PAGE = 'SET_NEXT_SEARCH_PAGE';
export const SET_LOADING = 'SET_LOADING';
export const SET_FINISH_LOADING = 'SET_FINISH_LOADING';
export const SET_VIDEO_LIST = 'SET_VIDEO_LIST';
export const SET_FILTER_BY_VIDEO = 'SET_FILTER_BY_VIDEO';
export const SET_FILTERS_VIDEO = 'SET_FILTERS_VIDEO';
export const CLEAR_FILTERS_SEARCH = 'CLEAR_FILTERS_SEARCH';
