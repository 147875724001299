import React, { useState } from 'react';
import * as IMAGES from '../Images';

export default ({ gallery, selectedIndex = 0, closeGalleryModal }) => {
    const [selectedImage, changeSelectedImage] = useState(selectedIndex);

    // const changeImage = (e, index) => {
    // 	e.preventDefault();
    // 	gallery[index] && changeSelectedImage(index);
    // };

    const nextImage = (e) => {
        e.preventDefault();
        changeSelectedImage(selectedImage + 1 > gallery.length - 1 ? 0 : selectedImage + 1);
    };

    const prevImage = (e) => {
        e.preventDefault();
        changeSelectedImage(selectedImage - 1 < 0 ? gallery.length - 1 : selectedImage - 1);
    };

    return (
        <div className="popup_likeher_search_photos">
            <div className="popup_likeher_search_photos_content">
                <div className="popup_likeher_search_photos_big_photo">
                    {/*
						<div className="popup_likeher_photo_unpaid_warning">
							You need to purschase any paid content
							<span>to open private photo</span>
						</div>
						*/}
                    {gallery[selectedImage] && <img src={gallery[selectedImage].link} alt="" />}
                </div>
            </div>
            <div className="popup_likeher_search_photos_prev" onClick={prevImage}>
                <img src={IMAGES.slider_prev} alt="" />
            </div>
            <div className="popup_likeher_search_photos_next" onClick={nextImage}>
                <img src={IMAGES.slider_next} alt="" />
            </div>

            <div className="popup_likeher_search_photos_close" onClick={closeGalleryModal}>
                <img src={IMAGES.c3lh_item_big_close} alt="" />
            </div>
        </div>
    );
};
