import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import BlockItem from './BlockItem';

export default function BlockTable({ data }) {
    const profilesState = useSelector((state) => state.profiles);

    return (
        <div className={styles.block_page_content_table}>
            <div className={styles.block_page_content_table_list}>
                {data?.length &&
                    data?.map((item, key) => (
                        <BlockItem
                            key={key}
                            item={item?.male_list}
                            isInActive={true}
                            womanProfile={profilesState?.list.find((profile) => +profile?.external_id === +item?.id)}
                        />
                    ))}
            </div>
        </div>
    );
}
