import md5 from 'js-md5';
import moment from 'moment';
import * as currentChatAction from '../../store/actions/currentChat';
import { insertedMessageAfterSync, insertedMessageBeforeSync } from '../../services/init';
import * as chatsAction from '../../store/actions/chats';
import ChatsApi from '../../services/api/ChatsApi';
import * as alertsAction from '../../store/actions/alerts';
import * as ERROR from '../../constants/error';
import { useDispatch, useSelector } from 'react-redux';

export default function useSendLike() {
    const dispatch = useDispatch();

    const profilesState = useSelector((state) => state.profiles);
    const currentChatState = useSelector((state) => state.currentChat);

    return ({ senderExternalID, recipientExternalID, chatUID }) => {
        const hashId = md5(moment().format());
        const womanProfile = profilesState.list.find((item) => +item?.external_id === +senderExternalID);

        if (!womanProfile?.external_id) return;
        const messageContent = `${womanProfile?.name} liked your profile`;

        new ChatsApi()
            .postMessage(+womanProfile?.external_id, +recipientExternalID, messageContent, 'SENT_LIKE')
            .then((res) => {
                function badRequest() {
                    dispatch(currentChatAction.removeMessageInHistory(hashId));

                    if (res?.error) dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                    else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                }

                if (!res?.response?.message_object || !res?.status) return badRequest();
                else if (res?.status) {
                    if (+currentChatState?.info?.female_external_id === +senderExternalID) {
                        dispatch(
                            currentChatAction.updateMessageInHistory(
                                hashId,
                                insertedMessageAfterSync({
                                    id: hashId,
                                    messageContent,
                                    externalId: +womanProfile?.external_id,
                                    type: 'SENT_LIKE',
                                    dateCreated: new Date().toUTCString(),
                                }),
                            ),
                        );
                    }

                    dispatch(
                        chatsAction.updateActiveChat({
                            sender_external_id: +recipientExternalID,
                            last_message: messageContent,
                            last_message_date: new Date().toUTCString(),
                            last_message_type: 'SENT_LIKE',
                        }),
                    );

                    if (+currentChatState?.info?.female_external_id === +senderExternalID) {
                        dispatch(
                            currentChatAction.addMessageToHistory(
                                insertedMessageBeforeSync({
                                    id: hashId,
                                    messageContent,
                                    externalId: +womanProfile?.external_id,
                                    type: 'SENT_LIKE',
                                    dateCreated: new Date().toUTCString(),
                                }),
                            ),
                        );
                    }
                    dispatch(chatsAction.setLike(chatUID));
                    dispatch(currentChatAction.setLike(chatUID));
                }
            });
    };
}
