import React, { useEffect, useState } from 'react';

import VimeoApi from '../../../services/api/VimeoApi';
import * as IMAGES from '../../common/Images';

export default ({ openVideoModal, item }) => {
    const [videoPoster, setVideoPoster] = useState('');

    useEffect(() => {
        new VimeoApi().getThumbnail(item.split('/')[3]).then((vimeoRes) => {
            if (vimeoRes) setVideoPoster(vimeoRes?.data[0]?.sizes[3]?.link ?? '');
        });
    }, []);

    return (
        <div className="">
            <div onClick={() => openVideoModal(`https://player.vimeo.com/video/${item.split('/')[3]}/`)}>
                <img src={videoPoster} alt="video-poster" className="c3mp_video_list_item_img" />
                <div className="c3mp_video_list_item_play_btn">
                    <img src={IMAGES.c3_media_video_play} alt="logo-play" />
                </div>
            </div>
        </div>
    );
};
