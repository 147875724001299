import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import moment from 'moment';
import * as chatsAction from '../../store/actions/chats';
import { getChatListType } from '../../services/methods';

export default function useGetChatListDebounce() {
    const dispatch = useDispatch();
    const chatsState = useSelector((state) => state.chats);
    const profilesState = useSelector((state) => state.profiles);

    const debounceTimeoutRef = useRef(null);
    const lastUpdateRef = useRef(moment());
    const hasPendingRequestRef = useRef(false);
    const isFirstRenderRef = useRef(true);

    const getChatList = () => {
        const pinChatListFromLocalStorage = JSON.parse(localStorage.getItem('pinList')) ?? [];
        dispatch(
            chatsAction.addChatList({
                user_id: profilesState?.active?.external_id || '',
                isOnline: chatsState?.filterOnline,
                searchItem: chatsState?.filterSearch,
                limits: chatsState?.limits,
                freeze: chatsState?.filterFreeze,
                chatType: getChatListType(location?.pathname),
            }),
        );

        pinChatListFromLocalStorage.length && dispatch(chatsAction.initPinChatList(pinChatListFromLocalStorage));
    };

    const debouncedGetChatList = () => {
        const now = moment();
        const timeSinceLastUpdate = now.diff(lastUpdateRef.current, 'milliseconds');

        if ((isFirstRenderRef.current || timeSinceLastUpdate > 1000) && !hasPendingRequestRef.current) {
            //Sends request without delay, first request or after 1 sec delay
            getChatList();
            isFirstRenderRef.current = false;
            lastUpdateRef.current = now;
        } else {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }

            hasPendingRequestRef.current = true;

            debounceTimeoutRef.current = setTimeout(() => {
                if (hasPendingRequestRef.current) {
                    //Sends after stopping spam re-rendering useEffect
                    getChatList();
                    lastUpdateRef.current = moment();
                    hasPendingRequestRef.current = false;
                }
            }, 1000);
        }
    };

    return {
        debouncedGetChatList,
        debounceTimeoutRef,
    };
}
