import React from 'react';

export default ({ inputMessage, translatedText, handleTranslating, handleTranslated, onSubmitMessageEnter }) => {
    return (
        <div className="c3_write_msg_textareas">
            <textarea
                name=""
                placeholder="Type in to chat"
                value={inputMessage}
                onChange={handleTranslating}
                onKeyDown={onSubmitMessageEnter}
            />
            <textarea
                name=""
                placeholder="Translate"
                value={translatedText}
                onChange={handleTranslated}
                onKeyDown={onSubmitMessageEnter}
            />
        </div>
    );
};
