import Base from './Base';

export default class ChatsAPI extends Base {
    getOptions({ chat_id }, token = null) {
        return super
            .post(
                `/api/chatList/chatOptions`,
                {
                    chat_id,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    chatUIDByProfileAndUserIDs(data) {
        return super
            .post(`/api/chatList/chatUidByProfileAndUserIds`, data)
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    getHistory({ chat_id, page = 1 }, token = null) {
        return super
            .post(
                `/api/chatList/chatHistory`,
                {
                    chat_id,
                    page,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    chatInfoByChatUID(chat_uid) {
        return super
            .post(`/api/chatList/chatInfoByChatUID`, { chat_uid: chat_uid })
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    getChatListByUserId({
        user_id,
        freeze,
        isOnline,
        searchItem,
        chatType,
        token = null,
        page = 1,
        chat_uid = false,
        limits,
    }) {
        return super
            .post(
                `/api/chatList/chatListByUserID`,
                {
                    user_id,
                    chat_uid,
                    page,
                    freeze,
                    limits,
                    ONLINE_STATUS: isOnline ? 1 : 0,
                    SEARCH: searchItem,
                    CHAT_TYPE: chatType,
                },
                token ? { cancelToken: token } : {},
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    getUserDetail({ chat_uid }) {
        return super.post(`/api/chatList/userDetail`, { chat_uid }).then((res) => res?.data);
    }

    getLastMessage({ chat_uid }) {
        return super.post(`/api/chatList/lastMessage`, { chat_uid }).then((res) => res?.data);
    }

    getUnreadCount({ chat_uid }) {
        return super.post(`/api/chatList/unreadCount`, { chat_uid }).then((res) => res?.data);
    }

    getHistoryMedia(chatId, token = null) {
        return super
            .post(
                `/api/chatList/operatorMedia`,
                { chat_id: chatId },
                token ? { ...this.getAuthHeader(), ...{ cancelToken: token } } : this.getAuthHeader(),
            )
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }

    getHistoryMediaLetter(chatId, token = null) {
        return super
            .post(`/api/chatList/operatorMediaLetters`, { chat_id: chatId }, token ? { cancelToken: token } : {})
            .then((res) => res?.data)
            .catch(() => ({ canceled: true }));
    }
}
