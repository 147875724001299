import React from 'react';
import styles from './styles.module.scss';

export default function VirtualGiftsList({
    closeVirtualGifts,
    virtualGiftsData,
    sendVirtualGift,
    virtualGiftListRef,
    userName,
}) {
    return (
        <div className={styles.virtual_gifts} ref={virtualGiftListRef}>
            <p className={styles.virtual_gifts_title}>Choose a Virtual Gift for {userName}</p>
            <div className={styles.virtual_gifts_container}>
                {!!virtualGiftsData?.length &&
                    virtualGiftsData?.map((category) => {
                        return (
                            <React.Fragment key={category?.categoryId}>
                                <p className={styles.virtual_gifts_text}>{category?.categoryName}</p>
                                <ul className={styles.virtual_gifts_list}>
                                    {!!category.virtualGifts?.length &&
                                        category.virtualGifts?.map((gift) => {
                                            return (
                                                <li
                                                    key={gift?.id}
                                                    className={styles.virtual_gift_card}
                                                    onClick={() => sendVirtualGift(gift)}
                                                >
                                                    <button className={styles.virtual_gift_card_button}>
                                                        <span className={styles.virtual_gift_card_name}>
                                                            {gift?.name}
                                                        </span>
                                                        <img
                                                            className={styles.virtual_gift_card_image}
                                                            src={gift?.img}
                                                            width="100"
                                                            height="100"
                                                            alt={gift.name ?? ''}
                                                        />
                                                    </button>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </React.Fragment>
                        );
                    })}
            </div>
            <button className={styles.virtual_gifts_close_button} type="button" onClick={closeVirtualGifts} />
        </div>
    );
}
