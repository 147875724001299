import Base from './Base';

export default class FilesAPI extends Base {
    getAllImages(externalId) {
        return super.get(`files/images?external_id=${externalId}`).then((res) => res?.data);
    }

    getAllVideos(externalId) {
        return super.get(`files/videos?external_id=${externalId}`).then((res) => res?.data);
    }

    getAllAudios(externalId) {
        return super.get(`files/audios?external_id=${externalId}`).then((res) => res?.data);
    }

    postImage(data) {
        return super.post(`files/image`, data).then((res) => res?.data);
    }

    postVideo(data) {
        return super.post(`files/video`, data).then((res) => res?.data);
    }

    postAudio(data) {
        return super.post(`files/audio`, data).then((res) => res?.data);
    }

    postDeleteMedia(id, userId) {
        return super
            .post(`files/deleteMedia`, {
                id: id,
                user_id: userId,
            })
            .then((res) => res?.data);
    }

    postUpdateSortOrder(contentType, list, externalId) {
        return super
            .post(`files/updateSortOrder`, {
                external_id: externalId,
                content_type: contentType,
                list: list,
            })
            .then((res) => res?.data);
    }

    postCreateFolder(external_id, type = '', name = '') {
        return super
            .post(`files/createFolder`, { external_id, type, name })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    postEditFolder({ id, name = '', addList = [], deleteList = [] }) {
        return super
            .post(`files/editFolder`, {
                id,
                ...(name?.length ? { name } : {}),
                ...(addList?.length ? { addList } : {}),
                ...(deleteList?.length ? { deleteList } : {}),
            })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    postDeleteFolder(id) {
        return super
            .post(`files/deleteFolder`, { id })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }
}
