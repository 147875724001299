export function openNotifyBlock() {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.add('dark');
        blockOverlay.classList.add('active');
    }
}

export function closeSubMenuBlock() {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
    }

    const blockColumn2 = document.querySelector('.column-2');
    if (blockColumn2) blockColumn2.classList.remove('active');
}

export function closeMainMenuBlock() {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
    }

    const blockColumn1 = document.querySelector('.column-1');
    if (blockColumn1) blockColumn1.classList.remove('active');
}

export function scrollTopToMainChat(index = null, flag) {
    if (index === -1) return;

    const blockChatScroll = document.querySelector('.c3_chat_scroll');
    if (!index && blockChatScroll) return blockChatScroll.scrollTo(99999, 99999);

    if (index?.toString()?.includes('-') && index?.toString()?.split('-')[1]) {
        const blockByMessageID = document.getElementById(`mess-${index?.toString()?.split('-')[1]}`);
        if (blockByMessageID) return blockByMessageID.scrollIntoView(false);
        else return flag && blockChatScroll.scrollTo(99999, 99999);
    } else {
        const blockByMessageID = document.getElementById(`mess-${index}`);
        if (blockByMessageID) {
            const messDiv = document.getElementById(`mess-${index}`);
            if (messDiv) return messDiv.scrollIntoView({ block: 'end', inline: 'nearest' });
        } else return flag && blockChatScroll.scrollTo(99999, 99999);
    }
}

export function scrollTopToMainLetter(index = null) {
    if (document.getElementById(`lett-${index}`)) {
        return document.getElementById(`lett-${index}`).scrollIntoView();
    }
}

export function scrollTopToNotifyMessWithPagination(index) {
    if (document.getElementById(`mess-notify-${index}`)) {
        document.getElementById(`mess-notify-${index}`).scrollIntoView();
    }
}

export function openMobNotify() {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.add('dark');
        blockOverlay.classList.add('active');
    }

    const blockColumn4 = document.querySelector('.column-4');
    if (blockColumn4) blockColumn4.classList.add('active');
}

export function closeMobChatList() {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
    }

    const blockColumn2 = document.querySelector('.column-2');
    if (blockColumn2) blockColumn2.classList.remove('active');
}

export function closeNotifyList() {
    const blockOverlay = document.querySelector('.overlay');
    if (blockOverlay) {
        blockOverlay.classList.remove('dark');
        blockOverlay.classList.remove('active');
    }

    const blockColumn4 = document.querySelector('.column-4');
    if (blockColumn4) blockColumn4.classList.remove('active');
}

export function gotoById(domId) {
    if (document.getElementById(domId)) {
        document.getElementById(domId).scrollIntoView();
    }
}
