import React, { useState } from 'react';
import Styles from './styles.module.scss';
// import 'emoji-mart/css/emoji-mart.css';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import StickersListOpen from './StickersListOpen';
import useSendSticker from '../../../../../hooks/emoji/sticker/useSendSticker';
import * as STICKERS from '../../../../../constants/stickers';

export default function Emoji({
    addEmoji,
    isSmileBlock,
    openSmileBlock,
    closeSmileBlock,
    smileBoxRef,
    isStickerBlock,
    openStickerBlock,
    closeStickerBlock,
    stickerBoxRef,
    templateContent,
    canText,
}) {
    const sendSticker = useSendSticker();
    const initInnerStickersList = { stickerList: [], title: '' };

    const [innerStickerList, setInnerStickerList] = useState(initInnerStickersList);
    const [isStickerListOpen, setIsStickerListOpen] = useState(false);

    const openInnerStickerList = (stickerArray, title) => {
        setInnerStickerList({ stickerList: stickerArray, title: title });
        setIsStickerListOpen(true);
    };

    const closeInnerStickerList = () => {
        setInnerStickerList(initInnerStickersList);
        setIsStickerListOpen(false);
    };

    const stickerListMap = (stickerArray) => {
        const stickerArraySlice = stickerArray?.slice(0, 4);

        return (
            !!stickerArraySlice?.length &&
            stickerArraySlice?.map((sticker, index) => (
                <div
                    className={Styles.c3_stickers_list_item}
                    onClick={() => onSelectSticker(sticker?.name)}
                    key={`${sticker?.name}_${index}`}
                    data-testid={sticker?.name}
                >
                    <img src={sticker?.image} alt="" />
                </div>
            ))
        );
    };

    const onSelectSticker = (stickerName) => {
        closeStickerBlock();
        sendSticker(stickerName);
    };

    const onSmileWrapActive = () => {
        if (templateContent) return;
        isSmileBlock ? closeSmileBlock() : openSmileBlock();
    };

    const onStickerWrapActive = () => {
        if (isStickerBlock && !isStickerListOpen) closeStickerBlock();
        else openStickerBlock();
    };

    return (
        <>
            {canText ? (
                <div
                    className={`${Styles.c3_smile_btn_wrap2} ${Styles.smiles} ${isSmileBlock ? Styles.active : ''}`}
                    onClick={onSmileWrapActive}
                    ref={smileBoxRef}
                    data-testid="emoji-btn"
                >
                    <div
                        className={Styles.c3_smiles_list_wrap}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <div className={Styles.c3_smiles_list} data-testid="emoji-list">
                            <Picker
                                data={data}
                                onEmojiSelect={addEmoji}
                                emojiSize={32}
                                theme="light"
                                navPosition="none"
                                previewPosition="none"
                                searchPosition="none"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className={Styles.c3_sticker_main_wrap}>
                <button
                    type="button"
                    className={`${Styles.c3_sticker_wrap} ${Styles.sticker}`}
                    onClick={onStickerWrapActive}
                    data-testid="sticker-btn"
                />
                <div
                    className={`${Styles.c3_stickers_list_wrap} ${isStickerBlock || isStickerListOpen ? Styles.active : ''}`}
                    data-testid="sticker-list"
                    ref={stickerBoxRef}
                >
                    <div className={`${Styles.c3_stickers_list_rows_wrap}`}>
                        {STICKERS.ARRAY_ALL_STICKER.map((item, index) => (
                            <div className={Styles.c3_stickers_list_row} key={`${index}_${item?.title}`}>
                                <div className={Styles.c3_stickers_list_head}>
                                    <div className={Styles.c3_stickers_list_head_title}>{item?.title}</div>
                                </div>
                                <div className={Styles.c3_stickers_list}>
                                    {stickerListMap(item?.stickerArray)}
                                    <div
                                        className={Styles.c3_stickers_list_all_btn}
                                        onClick={() => openInnerStickerList(item?.stickerArray, item?.title)}
                                    >
                                        All
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <StickersListOpen
                        innerStickerList={innerStickerList?.stickerList}
                        innerStickerTitle={innerStickerList?.title}
                        isStickerListOpen={isStickerListOpen}
                        closeInnerStickerList={closeInnerStickerList}
                        onSelectSticker={onSelectSticker}
                        openStickerBlock={openStickerBlock}
                    />
                </div>
            </div>
        </>
    );
}
