import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Styles from './styles.module.scss';

import Emoji from './Emoji';
import Attach from './Attach';
import AllMedia from './AllMedia';
import ChanceTemplates from './ChanceTempletes';
import * as IMAGES from '../../../common/Images';
import useHandleChatRoomForm from '../../../../hooks/chat/useHandleChatRoomForm';
import * as CHAT from '../../../../constants/chat';
import useHandleStickerBlock from '../../../../hooks/emoji/sticker/useHandleStickerBlock';
import useHandleSmileBlock from '../../../../hooks/emoji/smile/useHandleSmileBlock';
import useSendLike from '../../../../hooks/like/useSendLike';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Select from 'react-select';
import useInitTranslate from '../../../../hooks/translate/useInitTranslate';
import VirtualGiftsList from './VirtualGiftsList';
import useVirtualGifts from '../../../../hooks/virtualGifts/useVirtualGifts';

export default function Form({ configData }) {
    const {
        isOpenTranslateBlock,
        messageContent,
        templateContent,
        textSourceTranslate,
        textTargetTranslate,
        setTextSourceTranslate,
        setTextTargetTranslate,
        handleMessageContent,
        handleTextSourceTranslate,
        handleTextTargetTranslate,
        handleTranslateSwitcher,
        onSubmitMessageContent,
        onEnterMessageContent,
        addEmoji,
        isDisabledButton,
        setIsDisabledButton,
        handleTemplateSelector,
        checkOnMaxLength,
    } = useHandleChatRoomForm(configData);

    const {
        postTranslateText,
        translateOptions,
        activeSourceTranslate,
        selectSourceTranslate,
        activeTargetTranslate,
        selectTargetTranslate,
        switchTargetTranslate,
    } = useInitTranslate(
        isOpenTranslateBlock,
        isDisabledButton,
        setIsDisabledButton,
        textSourceTranslate,
        textTargetTranslate,
        setTextSourceTranslate,
        setTextTargetTranslate,
    );

    const currentChatState = useSelector((state) => state.currentChat);
    const chanceTemplateState = useSelector((state) => state.chanceTemplate);

    let { canText, canTemplate } = chanceTemplateState.templateSettings;

    const [activeTypeFile, setActiveTypeFile] = useState('');

    const handleStickerBlock = useHandleStickerBlock();
    const handleSmileBlock = useHandleSmileBlock();
    const sendLike = useSendLike();
    const virtualGifts = useVirtualGifts({
        womanExternalId: currentChatState?.info?.female_external_id,
        userExternalId: currentChatState?.info?.male_external_id,
    });

    const isChanceFolder = window.location.pathname.split('/')[1] === 'chance';
    if (!isChanceFolder) canText = 1;

    return (
        <div className={Styles.clmn_3_chat_bottom} data-testid="chat-actions">
            {isChanceFolder && canTemplate ? (
                <ChanceTemplates
                    femaleExternalId={+currentChatState?.info?.female_external_id}
                    handleTemplateSelector={handleTemplateSelector}
                    messageContent={messageContent}
                    templateContent={templateContent}
                />
            ) : (
                <></>
            )}
            {canText ? (
                !isOpenTranslateBlock ? (
                    <textarea
                        className={Styles.clmn_3_chat_textarea}
                        value={messageContent}
                        data-testid="message"
                        autoFocus={true}
                        disabled={templateContent}
                        onKeyDown={onEnterMessageContent}
                        maxLength={5000}
                        onChange={(e) => handleMessageContent(e.target.value)}
                    />
                ) : (
                    <div className={Styles.clmn_3_chat_textarea_double} data-testid="translate-block">
                        <div className={Styles.clmn_3_chat_textarea_wrap} data-testid="real-text-block">
                            <div className={Styles.clmn_3_chat_textarea_translate_info}>
                                <Select
                                    className={Styles.clmn_3_chat_textarea_translate_select}
                                    defaultValue={activeSourceTranslate}
                                    value={activeSourceTranslate}
                                    onChange={selectSourceTranslate}
                                    options={translateOptions}
                                />

                                <div className={`${Styles.clmn_3_chat_textarea_translate_symbols}`} data-testid="count">
                                    {textSourceTranslate?.length} symbols
                                </div>
                            </div>
                            <textarea
                                className={Styles.clmn_3_chat_textarea_inner}
                                value={textSourceTranslate}
                                autoFocus={true}
                                data-testid="text"
                                disabled={templateContent}
                                onKeyDown={(e) => onEnterMessageContent(e, postTranslateText)}
                                onChange={(e) => handleTextSourceTranslate(e.target.value)}
                            />
                        </div>

                        <div className={Styles.clmn_3_chat_textarea_translate_arrow_wrap}>
                            <div
                                className={`${Styles.clmn_3_chat_textarea_translate_arrow} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                                onClick={switchTargetTranslate}
                                data-testid="change-languages-btn"
                            >
                                <CompareArrowsIcon />
                            </div>

                            <div
                                className={`${Styles.clmn_3_chat_textarea_translate_arrow} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                                onClick={postTranslateText}
                                data-testid="translate-btn"
                            >
                                <ArrowForwardIcon />
                            </div>
                        </div>

                        <div className={Styles.clmn_3_chat_textarea_wrap} data-testid="translated-text-block">
                            <div className={Styles.clmn_3_chat_textarea_translate_info}>
                                <Select
                                    className={Styles.clmn_3_chat_textarea_translate_select}
                                    defaultValue={activeTargetTranslate}
                                    value={activeTargetTranslate}
                                    onChange={selectTargetTranslate}
                                    options={translateOptions}
                                />

                                <div
                                    className={`${Styles.clmn_3_chat_textarea_translate_symbols} ${!checkOnMaxLength(textTargetTranslate) ? Styles.error : ''}`}
                                    data-testid="count"
                                >
                                    {textTargetTranslate?.length} symbols
                                </div>
                            </div>
                            <textarea
                                className={Styles.clmn_3_chat_textarea_inner}
                                value={textTargetTranslate}
                                disabled={templateContent}
                                data-testid="text"
                                onChange={(e) => handleTextTargetTranslate(e.target.value)}
                                onKeyDown={onEnterMessageContent}
                            />
                        </div>
                    </div>
                )
            ) : (
                <></>
            )}
            <div className={Styles.clmn_3_chat_bottom_nav}>
                <div className={Styles.clmn_3_chat_bottom_options}>
                    <Emoji
                        {...handleStickerBlock}
                        {...handleSmileBlock}
                        addEmoji={addEmoji}
                        templateContent={templateContent}
                        canText={canText}
                    />
                    <Attach activeTypeFile={activeTypeFile} setActiveTypeFile={setActiveTypeFile} />
                    <AllMedia />

                    {!!virtualGifts?.virtualGiftsData?.length && (
                        <div
                            className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.virtual_gift}`}
                            ref={virtualGifts.virtualGiftBtnRef}
                            onClick={virtualGifts.toggleVirtualGifts}
                            data-testid="virtual-gift-btn"
                        />
                    )}

                    {+currentChatState?.info?.like_limit && !+currentChatState?.info?.like ? (
                        <div
                            className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.like}`}
                            onClick={() =>
                                sendLike({
                                    senderExternalID: currentChatState?.info?.female_external_id,
                                    recipientExternalID: currentChatState?.info?.male_external_id,
                                    chatUID: currentChatState?.info?.chat_uid,
                                })
                            }
                            data-testid="like-btn"
                        />
                    ) : (
                        <div />
                    )}
                </div>

                {!isOpenTranslateBlock && (
                    <div className={Styles.clmn_3_chat_bottom_symbols} data-testid="message-length">
                        {messageContent?.length} symbols
                    </div>
                )}

                <div className={Styles.clmn_3_chat_bottom_nav_right}>
                    <div className={Styles.clmn_3_chat_bottom_translate}>
                        <div className={Styles.clmn_3_chat_bottom_translate_tmblr} data-testid="open-translator">
                            <input
                                type="checkbox"
                                id="translate_tmblr"
                                checked={isOpenTranslateBlock}
                                value={+isOpenTranslateBlock}
                                className={Styles.clmn_3_chat_bottom_translate_tmblr_inp}
                            />
                            <label
                                htmlFor="translate_tmblr"
                                onClick={handleTranslateSwitcher}
                                className={Styles.clmn_3_chat_bottom_translate_tmblr_label}
                            />
                        </div>
                        <div className={Styles.clmn_3_chat_bottom_translate_text}>Translate</div>
                    </div>
                    <div
                        className={`${Styles.clmn_3_chat_bottom_send_btn} ${isDisabledButton ? Styles.disabled_btn : ''}`}
                        onClick={() => onSubmitMessageContent(CHAT.MESSAGE_CONTENT_TYPE_TEXT)}
                        data-testid="send-btn"
                    >
                        <img src={IMAGES.default.send_message} alt="" />
                        <span>Send</span>
                    </div>
                </div>
            </div>

            {virtualGifts.isOpenVirtualGifts && (
                <VirtualGiftsList
                    closeVirtualGifts={virtualGifts.toggleVirtualGifts}
                    virtualGiftsData={virtualGifts.virtualGiftsData}
                    sendVirtualGift={virtualGifts.sendVirtualGift}
                    virtualGiftListRef={virtualGifts.virtualGiftListRef}
                    userName={currentChatState?.info?.name}
                />
            )}
        </div>
    );
}
