import React from 'react';

export default ({ checkTranslate, onTranslateChange }) => {
    return (
        <div className="c3_write_msg_change_translate">
            <div className="c3_write_msg_change_col">
                <input
                    type="checkbox"
                    name="translate"
                    id="translate1"
                    checked={checkTranslate}
                    onChange={onTranslateChange}
                />
                <label htmlFor="translate1" />
                <span className="translate_title">Translate</span>
                <span className="translate_from">from:</span>
                <div className="c3_select_language">
                    Русский
                    <img src="../img/c3-language-arrow.svg" alt="" />
                </div>
            </div>
            <div className="c3_write_msg_change_col">
                <span className="translate_from">to:</span>
                <div className="c3_select_language">
                    english
                    <img src="../img/c3-language-arrow.svg" alt="" />
                </div>
            </div>
        </div>
    );
};
