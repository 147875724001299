import React from 'react';
import styles from './styles.module.scss';
import personal from '../personal.module.scss';
import { useSelector } from 'react-redux';
import femaleImg from '../../../img/icon_female.svg';
import maleImg from '../../../img/icon_male.svg';
import moment from 'moment';

export default ({ currentMan, currentWoman, setCurrentWoman, additionalWomanList, setAdditionalWomanList }) => {
    const profilesState = useSelector((state) => state.profiles);

    const checkBySiteID = (siteIDs) => {
        const isStartDate = moment(currentMan?.created_at).utc().isAfter('2024-09-26T00:00:00Z');
        const isEndDate = moment(currentMan?.created_at).utc().isBefore('2024-11-26T00:00:00Z');

        const isStartDate2 = moment(currentMan?.created_at).utc().isAfter('2024-11-26T00:00:00Z');
        const isEndDate2 = moment(currentMan?.created_at).utc().isBefore('2024-12-04T00:00:00Z');
        const isBTest = currentMan?.abtest?.toUpperCase() === 'B';

        if (isStartDate && isEndDate) return true;
        if (isStartDate2 && isEndDate2 && isBTest) return true;

        return siteIDs?.includes(currentMan?.site_id?.toString());
    };

    const allList = profilesState?.list?.filter(
        (item) =>
            checkBySiteID(item?.site_ids) &&
            !currentMan?.blockList?.includes(+item?.id) &&
            currentMan?.gender === item?.man_profile,
    );

    const euFemaleList = allList?.filter((item) => !item?.man_profile && item?.region === 'euro');
    const euMaleList = allList?.filter((item) => item?.man_profile && item?.region === 'euro');
    const asiaFemaleList = allList?.filter((item) => !item?.man_profile && item?.region === 'asia');
    const asiaMaleList = allList?.filter((item) => item?.man_profile && item?.region === 'asia');
    const lAmericaFemaleList = allList?.filter((item) => !item?.man_profile && item?.region === 'latina');
    const lAmericaMaleList = allList?.filter((item) => item?.man_profile && item?.region === 'latina');
    const otherFemaleList = allList?.filter((item) => !item?.man_profile && item?.region === 'other');
    const otherMaleList = allList?.filter((item) => item?.man_profile && item?.region === 'other');

    const onSetCurrentWoman = (item) => {
        if (currentWoman?.external_id) {
            if (currentWoman?.external_id !== item?.external_id) {
                if (additionalWomanList?.some((woman) => woman?.external_id === item?.external_id)) {
                    setAdditionalWomanList(
                        additionalWomanList?.filter((woman) => woman?.external_id !== item?.external_id),
                    );
                } else {
                    if (currentMan?.connect - 1 > additionalWomanList?.length) {
                        setAdditionalWomanList([...additionalWomanList, item]);
                    }
                }
            }
        } else {
            setCurrentWoman(item);
        }
    };

    const mapGirlItem = (item, key) => {
        const isAdditionalWoman = additionalWomanList?.some((woman) => woman?.external_id === item?.external_id);

        return (
            <div
                className={`${personal.personal_user_item} ${styles.choose_girl_item} ${isAdditionalWoman ? styles.choose_girl_item__blue : ''} ${currentWoman?.external_id === item?.external_id ? personal.active : ''}`}
                key={key}
                onClick={() => onSetCurrentWoman(item)}
            >
                <img src={item?.photo_link} alt="" />
                <span className={personal.personal_long_name}>{`${item.name} ${item.age}`}</span>
            </div>
        );
    };

    return (
        <div className={styles.clmn_2_personal}>
            <div className={styles.personal_choose_girl}>
                <div className={personal.personal_title}>Your profile for connect</div>
                <div className={`${personal.personal_scroll} ${styles.personal_choose_girl_list}`}>
                    {currentMan?.external_id ? (
                        <>
                            {!!(!!euFemaleList?.length || !!euMaleList?.length) && (
                                <div className={styles.country_block}>
                                    <p className={styles.country_title}>Europe</p>
                                    {!!euFemaleList?.length && (
                                        <>
                                            <div className={`${styles.country_subtitle} ${styles.female_title}`}>
                                                <img src={femaleImg} alt={'female'} />
                                                <span>Female</span>
                                            </div>
                                            <div className={styles.country_content}>
                                                {euFemaleList?.map(mapGirlItem)}
                                            </div>
                                        </>
                                    )}
                                    {!!euMaleList?.length && (
                                        <>
                                            <div className={`${styles.country_subtitle} ${styles.male_title}`}>
                                                <img src={maleImg} alt={'male'} />
                                                <span>Male</span>
                                            </div>
                                            <div className={styles.country_content}>{euMaleList?.map(mapGirlItem)}</div>
                                        </>
                                    )}
                                </div>
                            )}

                            {!!(!!asiaFemaleList?.length || !!asiaMaleList?.length) && (
                                <div className={styles.country_block}>
                                    <p className={styles.country_title}>Asia</p>
                                    {!!asiaFemaleList?.length && (
                                        <>
                                            <div className={`${styles.country_subtitle} ${styles.female_title}`}>
                                                <img src={femaleImg} alt={'female'} />
                                                <span>Female</span>
                                            </div>
                                            <div className={styles.country_content}>
                                                {asiaFemaleList?.map(mapGirlItem)}
                                            </div>
                                        </>
                                    )}
                                    {!!asiaMaleList?.length && (
                                        <>
                                            <div className={`${styles.country_subtitle} ${styles.male_title}`}>
                                                <img src={maleImg} alt={'male'} />
                                                <span>Male</span>
                                            </div>
                                            <div className={styles.country_content}>
                                                {asiaMaleList?.map(mapGirlItem)}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}

                            {!!(!!lAmericaFemaleList?.length || !!lAmericaMaleList?.length) && (
                                <div className={styles.country_block}>
                                    <p className={styles.country_title}>Latin America</p>
                                    {!!lAmericaFemaleList?.length && (
                                        <>
                                            <div className={`${styles.country_subtitle} ${styles.female_title}`}>
                                                <img src={femaleImg} alt={'female'} />
                                                <span>Female</span>
                                            </div>
                                            <div className={styles.country_content}>
                                                {lAmericaFemaleList?.map(mapGirlItem)}
                                            </div>
                                        </>
                                    )}

                                    {!!lAmericaMaleList?.length && (
                                        <>
                                            <div className={`${styles.country_subtitle} ${styles.male_title}`}>
                                                <img src={maleImg} alt={'male'} />
                                                <span>Male</span>
                                            </div>
                                            <div className={styles.country_content}>
                                                {lAmericaMaleList?.map(mapGirlItem)}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}

                            {!!(!!otherFemaleList?.length || !!otherMaleList?.length) && (
                                <div className={styles.country_block}>
                                    <p className={styles.country_title}>Other</p>
                                    {!!otherFemaleList?.length && (
                                        <>
                                            <div className={`${styles.country_subtitle} ${styles.female_title}`}>
                                                <img src={femaleImg} alt={'female'} />
                                                <span>Female</span>
                                            </div>
                                            <div className={styles.country_content}>
                                                {otherFemaleList?.map(mapGirlItem)}
                                            </div>
                                        </>
                                    )}

                                    {!!otherMaleList?.length && (
                                        <>
                                            <div className={`${styles.country_subtitle} ${styles.male_title}`}>
                                                <img src={maleImg} alt={'male'} />
                                                <span>Male</span>
                                            </div>
                                            <div className={styles.country_content}>
                                                {otherMaleList?.map(mapGirlItem)}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <div className={`${personal.personal_user_item}`}>
                            <span className={personal.personal_long_name}>Need to pick a person</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
