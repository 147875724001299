import React, { useState } from 'react';
import modalContent from '../../../styles/react-modal/modalContent.style';
import { Box, Button, DialogActions, DialogContent, Slider, Typography } from '@mui/material';
import Cropper from 'react-easy-crop';
import { Cancel, Crop } from '@mui/icons-material';
import Modal from 'react-modal';
import styles from './styles.module.scss';
import getCroppedImg from './utils/cropImage';
import * as loaderAction from '../../../store/actions/loader';
import { useDispatch } from 'react-redux';
import { makeCDN } from '../../../services/methods';
import * as alertAction from '../../../store/actions/alerts';
import { sendErrToSentry } from '../../../services/sentry';

export default function CropModal({
    isCropModal,
    closeCropModal,
    initPhotoURL,
    uploadMedia,
    updatedNumberUploadedImage,
}) {
    const dispatch = useDispatch();

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const cropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const zoomPercent = (value) => `${Math.round(value * 100)}%`;

    const cropImage = async () => {
        dispatch(loaderAction.setActiveGifLoader(true));

        try {
            const { file } = await getCroppedImg(initPhotoURL, croppedAreaPixels);
            if (file) {
                if (uploadMedia(file, true)) {
                    updatedNumberUploadedImage();
                }
                closeCropModal();
                setZoom(1);
                setCrop({ x: 0, y: 0 });
                setCroppedAreaPixels(null);
            }
        } catch (error) {
            sendErrToSentry(error);
            dispatch(alertAction.setMessage('', 'Something went wrong!'));
        }

        dispatch(loaderAction.setActiveGifLoader(false));
    };

    return (
        <Modal style={modalContent} isOpen={isCropModal} ariaHideApp={false}>
            <div className={styles.crop_modal}>
                <DialogContent
                    dividers
                    sx={{
                        background: '#333',
                        position: 'relative',
                        height: 500,
                        width: 'auto',
                        minWidth: { sm: 600 },
                    }}
                >
                    <Cropper
                        image={makeCDN(initPhotoURL, 'POSTS')}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={cropComplete}
                    />
                </DialogContent>

                <DialogActions sx={{ flexDirection: 'colum', mx: 3, my: 2 }}>
                    <Box sx={{ width: '100%', mb: 1 }}>
                        <Box>
                            <Typography>Zoom: {zoomPercent(zoom)}</Typography>
                            <Slider
                                valueLabelDisplay="auto"
                                valueLabelFormat={zoomPercent}
                                min={1}
                                max={3}
                                step={0.1}
                                value={zoom}
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 2,
                                flexWrap: 'wrap',
                            }}
                        >
                            <Button variant="outlined" startIcon={<Cancel />} onClick={closeCropModal}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<Crop />}
                                onClick={cropImage}
                                disabled={!croppedAreaPixels || !initPhotoURL?.length}
                            >
                                Crop
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </div>
        </Modal>
    );
}
