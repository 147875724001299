import * as FILES from '../constants/files';
import FilesApi from '../../services/api/FilesApi';

export function getAllImages(id) {
    return function (dispatch) {
        new FilesApi().getAllImages(id).then((res) => {
            return dispatch({
                type: FILES.FILES_IMG_GET,
                data: res,
            });
        });
    };
}

export function getAllVideos(id) {
    return function (dispatch) {
        new FilesApi().getAllVideos(id).then((res) => {
            return dispatch({
                type: FILES.FILES_VIDEO_GET,
                data: res,
            });
        });
    };
}

export function getAllAudios(id) {
    return function (dispatch) {
        new FilesApi().getAllAudios(id).then((res) => {
            return dispatch({
                type: FILES.FILES_AUDIO_GET,
                data: res,
            });
        });
    };
}

export function clearAllFiles() {
    return function (dispatch) {
        return dispatch({ type: FILES.CLEAR_ALL_FILES });
    };
}

export function addNewFolder(folderType, folder) {
    return function (dispatch) {
        return dispatch({
            type: FILES.ADD_NEW_FOLDER,
            folderType,
            folder,
        });
    };
}

export function renameFolder(folderType, folder) {
    return function (dispatch) {
        return dispatch({
            type: FILES.RENAME_FOLDER,
            folderType,
            folder,
        });
    };
}

// export function editFolder(folderType, folderFiles = {}) {
// 	return function (dispatch) {
// 		return dispatch({
// 			type: FILES.EDIT_FOLDER,
// 			folderType,
// 			folderFiles
// 		})
// 	}
// }
//
// export function deleteFileOnMove(folderType, folderId, selectedFileId = []) {
// 	return function (dispatch) {
// 		return dispatch({
// 			type: FILES.DELETE_FILE_ON_MOVE,
// 			folderType,
// 			folderId,
// 			selectedFileId
// 		})
// 	}
// }

export function deleteFolder(folderType, folderId) {
    return function (dispatch) {
        return dispatch({
            type: FILES.DELETE_FOLDER,
            folderType,
            folderId,
        });
    };
}
