import React from 'react';
import { useDispatch } from 'react-redux';
import * as modalsAction from '../../../../../store/actions/modals';
import FilesApi from '../../../../../services/api/FilesApi';
import { cutLengthString } from '../../../../../services/methods';
import * as LENGTH from '../../../../../constants/length';
import * as ATTACH from '../../../../../constants/attach';
import { makeCDN } from '../../../../../services/methods';
import * as IMAGES from '../../../Images';

export default ({ checked, elementRef, data, opacity, userId }) => {
    const dispatch = useDispatch();

    const handleInputChangeVideo = (e) => {
        const checkBox = e.currentTarget.querySelector('input');
        if (checkBox) {
            checkBox.checked = !checkBox.checked;
        }
    };

    const openContentModal = () => {
        dispatch(
            modalsAction.openVideoContentModal({
                link: data?.link,
                attachment_payed: '1',
            }),
        );
    };

    const handleSort = (e) => {
        const listItems = e.currentTarget.parentNode.querySelectorAll('.upload_popup_tabs_content_item');
        const sortedArr = [];

        for (let [key, item] of listItems.entries()) {
            if (item.dataset.id) {
                sortedArr.push({
                    id: item.dataset.id,
                    sort_order: key,
                });
            }
        }

        new FilesApi().postUpdateSortOrder(ATTACH.UPD_SORT_VIDEO, sortedArr, userId);
    };

    return (
        <div
            className="upload_popup_tabs_content_item"
            ref={elementRef}
            style={{ opacity }}
            data-id={data?.id}
            onDrop={handleSort}
        >
            <div className="no-drag upload_popup_tabs_content_item_top" onClick={handleInputChangeVideo}>
                <input
                    type="checkbox"
                    className="no-drag"
                    name={data?.id}
                    checked={checked}
                    onChange={handleInputChangeVideo}
                    data-link={data?.link}
                    data-filename={data?.filename}
                    data-contentid={data.id}
                    id={'vp' + data?.id}
                />
                <label htmlFor={'vp' + data?.id} className="no-drag">
                    <video width="214" height="115" id={'video-' + data?.id} controls={false} preload="metadata">
                        <source src={`${makeCDN(data?.link, 'VIDEO')}#t=0.6`} type="video/mp4" />
                    </video>
                </label>
            </div>
            <div className="upload_popup_tabs_content_item_bottom">
                <div className="popup_full_img" onClick={openContentModal}>
                    <img src={IMAGES.photo_full} alt="" />
                </div>
                <div className="popup_time">{cutLengthString(data?.filename, LENGTH.ATTACH_VIDEO_TITLE)}</div>
            </div>
        </div>
    );
};
