import * as PROFILES from '../constants/profiles';
import SenderActiveApi from '../../services/api/v3/SenderActiveApi';

// V3 --------------------------------------------------------
export function addProfiles(womanProfileList) {
    return function (dispatch) {
        return dispatch({
            type: PROFILES.PROFILES_ADD,
            womanProfileList,
        });
    };
}

export function addActiveSenderInfo(womanProfileList) {
    return function (dispatch) {
        new SenderActiveApi().getActiveSenderList(womanProfileList).then((res) => {
            if (res?.status && res?.response) {
                return dispatch({
                    type: PROFILES.PROFILES_ADD_SENDER_INFO,
                    womanProfileList: res?.response,
                });
            }
        });
    };
}

export function checkActiveSenderInfo() {
    return function (dispatch) {
        return dispatch({
            type: PROFILES.PROFILES_CHECK_SENDER_INFO,
        });
    };
}

export function removeActiveProfile() {
    return function (dispatch) {
        localStorage.removeItem('active_profile');
        return dispatch({
            type: PROFILES.PROFILES_REMOVE_ACTIVE,
        });
    };
}

export function setOperatorId(operatorId) {
    return function (dispatch) {
        return dispatch({
            type: PROFILES.SET_OPERATOR_ID,
            operatorId: operatorId,
        });
    };
}

export function setActiveProfile(payload) {
    return function (dispatch) {
        // localStorage.setItem('active_profile', JSON.stringify(payload));
        return dispatch({
            type: PROFILES.PROFILES_SET_ACTIVE,
            data: payload,
        });
    };
}

export function setAllOnlineStatus(status) {
    return function (dispatch) {
        return dispatch({
            type: PROFILES.SET_ALL_ONLINE_STATUS,
            status,
        });
    };
}

export function setOnlineStatusByExternalID(externalID, status) {
    return function (dispatch) {
        return dispatch({
            type: PROFILES.SET_ONLINE_STATUS_BY_EXTERNAL_ID,
            status,
            externalID,
        });
    };
}

//OLD VERSION ------------------------------------------------
// export function getProfiles() {
// 	return function (dispatch) {
// 		(new OperatorApi()).getProfiles().then(res => {
// 			if(res.error) return ;
// 			return dispatch({
// 				type: PROFILES.PROFILES_GET,
// 				data: res
// 			});
// 		});
// 	};
// }

export function setActiveProfileForSender(payload) {
    return function (dispatch) {
        return dispatch({
            type: PROFILES.PROFILES_SET_ACTIVE_FOR_SENDER,
            data: payload,
        });
    };
}
