import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import '../../../styles/audio-player/style.scss';
import * as IMAGES from '../Images';

export default ({ src, viewedAudio }) => {
    return (
        <AudioPlayer
            src={src}
            onPlay={viewedAudio}
            customIcons={{
                play: <img src={IMAGES.audio_play} alt="" />,
                pause: <img src={IMAGES.audio_pause} alt="" />,
                volume: <img src={IMAGES.audio_volume} alt="" />,
                volumeMute: <img src={IMAGES.chat_audio_volume_mute} alt="" />,
            }}
        />
    );
};
