import Styles from '../styles.module.scss';
import { cutLengthString, userAvatar } from '../../../../services/methods';
import * as IMAGES from '../../../common/Images';
import { formatDate } from '../../../../services/time';
import React from 'react';
import * as CHAT from '../../../../constants/chat';
import { useSelector } from 'react-redux';

export default ({ item, openChat }) => {
    const profilesState = useSelector((state) => state.profiles);
    const womanProfile = profilesState.list.find((profile) => +profile?.external_id === +item?.recipient_external_id);

    return (
        <div
            className={Styles.clmn_4_block_paid_item}
            onClick={() => openChat(item)}
            data-testid={`notification-${item?.id}`}
        >
            <div
                className={`${Styles.clmn_4_block_paid_item_man_photo_wrap} ${+item?.online ? Styles.online : ''}`}
                data-testid="notification-user-photo"
            >
                {userAvatar(
                    item?.photo_link || item?.photo_link?.length ? item.photo_link : null,
                    item?.name,
                    Styles.clmn_4_block_paid_item_man_photo,
                    Styles.user_avatar,
                )}
                {!!+item?.male_favorite && (
                    <img src={IMAGES.default.star} alt="" className={Styles.clmn_4_block_paid_item_man_photo_fav} />
                )}
            </div>
            <div className={Styles.clmn_4_block_paid_item_middle}>
                <div className={Styles.clmn_4_block_paid_item_name} data-testid="notification-user-name">
                    {`${item?.name ?? ''}, ${item?.age ?? ''}`}
                </div>
                <div className={Styles.clmn_4_block_paid_item_content} data-testid="notification-content">
                    {(function () {
                        switch (item?.message_type) {
                            case CHAT.MESSAGE_CONTENT_TYPE_LETTER:
                                return <span className={Styles.c4_item_text_letter_title}>Sent a letter</span>;
                            case CHAT.MESSAGE_CONTENT_TYPE_VIEW:
                                return <span className={Styles.c4_item_text_viwed_title}>View profile</span>;
                            case CHAT.MESSAGE_CONTENT_TYPE_LIKE:
                                return <span className={Styles.c4_item_text_like_title}>Liked your profile</span>;
                            case CHAT.MESSAGE_CONTENT_TYPE_WINK:
                                return <span>Sent a wink</span>;
                            case CHAT.MESSAGE_CONTENT_TYPE_ASK_VIDEO:
                            case CHAT.MESSAGE_CONTENT_TYPE_TEXT:
                                return <span>{cutLengthString(item?.message_content ?? '', 40)}</span>;
                            case CHAT.MESSAGE_CONTENT_TYPE_IMAGE:
                                return (
                                    <>
                                        <span>Sent a photo</span>
                                        <img src={item?.message_content ?? ''} alt="" />
                                    </>
                                );
                            case CHAT.MESSAGE_CONTENT_TYPE_STICKER:
                                return <span>Sent a sticker</span>;
                            case CHAT.MESSAGE_CONTENT_TYPE_VIDEO:
                                return (
                                    <>
                                        <span>Sent a video</span>
                                        <div className={Styles.clmn_4_block_paid_item_content_video}>
                                            <video
                                                preload="metadata"
                                                className={Styles.clmn_4_block_paid_item_content_video_img}
                                            >
                                                <source src={`${item.message_content}#t=0.6`} type="video/mp4" />
                                            </video>
                                        </div>
                                    </>
                                );
                            case CHAT.MESSAGE_CONTENT_TYPE_AUDIO:
                                return (
                                    <>
                                        <span>Sent an audio</span>
                                        <div className={Styles.clmn_4_block_paid_item_content_audio}>
                                            <img src={IMAGES.default.c4_play_audio_btn} alt="" />
                                            <img src={IMAGES.default.c4_audio_line} alt="" />
                                        </div>
                                    </>
                                );
                            case CHAT.MESSAGE_CONTENT_TYPE_VIRTUAL_GIFT:
                                return <span>Sent a virtual gift</span>;
                            default:
                                return <div />;
                        }
                    })()}
                </div>
            </div>
            <div className={Styles.clmn_4_block_paid_item_right}>
                <div
                    className={`${Styles.clmn_4_block_paid_item_woman_photo_wrap} ${+womanProfile?.online ? Styles.online : ''}`}
                    data-testid="notification-woman-photo"
                >
                    <img
                        src={womanProfile?.photo_link ?? ''}
                        alt=""
                        className={Styles.clmn_4_block_paid_item_woman_photo}
                    />
                    {+item?.female_favorite ? (
                        <img
                            src={IMAGES.default.star}
                            alt=""
                            className={Styles.clmn_4_block_paid_item_woman_photo_fav}
                        />
                    ) : (
                        <div />
                    )}
                </div>
                <div className={Styles.clmn_4_block_paid_item_time} data-testid="notification-date">
                    {formatDate(item?.date_created ?? '')}
                </div>
            </div>
        </div>
    );
};
