import * as ONLINE_MAN from '../constants/onlineMan';

const initState = {
    list: null,
    currentChat: null,
};

export default function (state = initState, action) {
    switch (action.type) {
        case ONLINE_MAN.GET_ONLINE_MAN_LIST:
            return {
                ...state,
                ...{
                    list: action.list,
                },
            };

        case ONLINE_MAN.GET_CURRENT_ONLINE_MAN_CHAT:
            return {
                ...state,
                ...{
                    currentChat: action?.data,
                },
            };

        case ONLINE_MAN.REMOVE_CURRENT_ONLINE_MAN_CHAT:
            return {
                ...state,
                ...{
                    currentChat: null,
                },
            };

        case ONLINE_MAN.REMOVE_ONLINE_MAN_LIST:
            return {
                ...state,
                ...{
                    list: null,
                },
            };

        default:
            return state;
    }
}
