import React from 'react';

export default ({ image, closePhoto }) => {
    return (
        <div className="popup_likeher_search_photos">
            <div className="popup_likeher_search_photos_content">
                <div className="popup_likeher_search_photos_big_photo">
                    <img src={image} alt="" />
                </div>
            </div>
            <div className="popup_likeher_search_photos_close" onClick={closePhoto}>
                <img src="/img/c3lh-item-big-close.svg" alt="" />
            </div>
        </div>
    );
};
