import { useDispatch, useSelector } from 'react-redux';
// import LoginApi from "../../services/api/LoginApi";
import * as syncAction from '../../store/actions/sync';
// import * as alertsAction from "../../store/actions/alerts";
// import * as ERROR from "../../constants/error";

export default function useLogout() {
    const dispatch = useDispatch();
    const sync = useSelector((state) => state.sync.provider);
    // const profilesState = useSelector(state => state.profiles);

    return () => {
        localStorage.removeItem('check_profiles');

        // new LoginApi()
        // 	.postLogout({operator_id: profilesState?.operatorId ?? ''})
        // 	.then(res => {
        // 		if (res?.status) {
        // 			localStorage.removeItem('operator');
        // 			localStorage.removeItem('token');
        // 			localStorage.removeItem('admin');
        // 			if (sync) sync.unsubscribeAll();
        // 			dispatch(syncAction.remove());
        // 			window.location.href = '/';
        // 		}else{
        // 			if (res?.error) dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
        // 			else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
        // 		}
        // 	});

        localStorage.removeItem('operator');
        localStorage.removeItem('token');
        localStorage.removeItem('admin');
        localStorage.removeItem('chosenShift');
        if (sync) sync.unsubscribeAll();
        dispatch(syncAction.remove());
        window.location.href = '/';
    };
}
