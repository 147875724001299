import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import '../../styles/woman-profile.scss';
import modalContentAttach from '../../styles/react-modal/modalContent.style';
import Header from './Header';
import MainProfile from './MainProfile';
import SecondProfile from './SecondProfile';
import PhotoGallery from './PhotoGallery';
import VideoGallery from './VideoGallery';
import PhotoGalleryModal from '../../components/common/modals/PhotoGallery';
import IframeContentModal from '../../components/common/ContentAttachModal/IframeContent';
import * as MENU from '../../constants/menu';
import { unique } from '../../services/methods';

export default function WomanProfile({ girlProfileInfo, girlProfileImages, girlProfileVideos }) {
    const photoPanel = useRef(null);
    const videoPanel = useRef(null);

    const [image, setImage] = useState(null);
    const [currentPhoto, setCurrentPhoto] = useState(null);
    const [modalOpenGallery, setModalOpenGallery] = useState(false);
    const [modalVideoIsOpen, setModalVideoIsOpen] = useState(false);
    const [iframeItem, setIframeItem] = useState('');
    const [girlImages, setGirlImages] = useState(girlProfileImages);

    const anchorTo = (anchorType) => {
        switch (anchorType) {
            case MENU.ANCHOR_PHOTO:
                photoPanel.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: 'nearest' });
                break;
            case MENU.ANCHOR_VIDEO:
                videoPanel.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: 'nearest' });
                break;
            default:
                break;
        }
    };

    const openGalleryModal = (url, index) => {
        setImage(url);
        setCurrentPhoto(index);
        setModalOpenGallery(true);
    };

    const closeIframeModal = () => {
        setModalVideoIsOpen(false);
        setIframeItem('');
    };

    const openIframeModal = (src) => {
        setIframeItem(src);
        setModalVideoIsOpen(true);
    };

    return (
        <>
            <Header />
            <div className="c3_woman_profile_page_wrap2">
                <MainProfile girlProfileInfo={girlProfileInfo} anchorTo={anchorTo} />
                <div className="c3_woman_profile_second_block" data-testid="woman-profile-second-block">
                    <SecondProfile girlProfileInfo={girlProfileInfo} />
                    <PhotoGallery
                        photoPanel={photoPanel}
                        setGirlImages={setGirlImages}
                        girlImages={unique(girlImages)}
                        openGalleryModal={openGalleryModal}
                    />
                    <VideoGallery
                        videoPanel={videoPanel}
                        girlProfileVideos={girlProfileVideos}
                        openIframeModal={openIframeModal}
                    />
                </div>
            </div>
            <Modal
                style={modalContentAttach}
                isOpen={modalOpenGallery}
                onRequestClose={() => setModalOpenGallery(false)}
                ariaHideApp={false}
            >
                <PhotoGalleryModal
                    gallery={unique(girlImages)}
                    setGirlImages={setGirlImages}
                    photo_link={image}
                    currentPhoto={currentPhoto ?? 0}
                    closeGalleryModal={() => setModalOpenGallery(false)}
                />
            </Modal>
            <Modal
                style={modalContentAttach}
                isOpen={modalVideoIsOpen}
                onRequestClose={closeIframeModal}
                ariaHideApp={false}
            >
                <IframeContentModal item={iframeItem} closeModal={closeIframeModal} />
            </Modal>
        </>
    );
}
