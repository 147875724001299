import * as NOTIFY from '../constants/notify';
import moment from 'moment';
import { generationDateOnFewMinutesLonger } from '../../services/methods';
import * as CHAT from '../../constants/chat';
import * as TIMER from '../../constants/timer';
import { diffWithCurrentTimeInSecondsWithoutUTC } from '../../services/time';

const initState = {
    listPaid: [],
    listFree: [],
    listPage: 0,
    listTimer: 0,
    status: false,
    isOpenMobNotify: false,
};

export default function (state = initState, action) {
    switch (action.type) {
        case NOTIFY.CHECK_EXP_DATE: {
            const newNotifyList = state.listPaid?.filter((item) =>
                item?.expires_at ? diffWithCurrentTimeInSecondsWithoutUTC(item?.expires_at) : false,
            );

            if (!document.hidden) {
                localStorage.setItem(`notify_${action?.operatorId}`, JSON.stringify(newNotifyList));
            }

            if (state.listPaid?.length !== newNotifyList?.length) {
                return {
                    ...state,
                    ...{
                        listPaid: newNotifyList,
                    },
                };
            }
            return state;
        }

        case NOTIFY.OPEN_MOB_NOTIFY:
            return { ...state, ...{ isOpenMobNotify: true } };

        case NOTIFY.CLOSE_MOB_NOTIFY:
            return { ...state, ...{ isOpenMobNotify: false } };

        case NOTIFY.ADD_FREE_NOTIFY:
            if (action?.notify?.id) {
                const onlineNotify = state?.listFree.find(
                    (notify) =>
                        notify?.recipient_external_id === action?.notify?.recipient_external_id &&
                        notify?.sender_external_id === action?.notify?.sender_external_id &&
                        action?.notify?.message_type === CHAT.MESSAGE_CONTENT_TYPE_ONLINE,
                );

                if (onlineNotify?.id) {
                    return {
                        ...state,
                        ...{
                            listFree: [
                                ...[
                                    {
                                        ...action?.notify,
                                        ...{
                                            expires_at: generationDateOnFewMinutesLonger(
                                                TIMER.FREE_NOTIFY_EXP_DATE_MIN,
                                            ),
                                        },
                                    },
                                ],
                                ...state.listFree.filter((notify) => notify?.id !== onlineNotify?.id),
                            ],
                        },
                    };
                } else {
                    return {
                        ...state,
                        ...{
                            listFree: [
                                ...[
                                    {
                                        ...action?.notify,
                                        ...{
                                            expires_at: generationDateOnFewMinutesLonger(
                                                TIMER.FREE_NOTIFY_EXP_DATE_MIN,
                                            ),
                                        },
                                    },
                                ],
                                ...state.listFree,
                            ],
                        },
                    };
                }
            }
            return state;

        case NOTIFY.ADD_PAID_NOTIFY:
            if (action?.notify?.chat_uid) {
                const filteredListPaid = state?.listPaid?.filter((notify) => {
                    if (
                        notify?.chat_uid === action?.notify?.chat_uid &&
                        notify?.notification_type !== action?.notify?.notification_type
                    ) {
                        return true;
                    }

                    return notify?.chat_uid !== action?.notify?.chat_uid;
                });

                localStorage.setItem(
                    `notify_${action?.operatorId}`,
                    JSON.stringify([...[action?.notify], ...filteredListPaid]),
                );
                return {
                    ...state,
                    ...{
                        listPaid: [...[action?.notify], ...filteredListPaid],
                    },
                };
            }
            return state;

        case NOTIFY.REMOVE_ALL_FREE_NOTIFY:
            return { ...state, ...{ listFree: [] } };

        case NOTIFY.REMOVE_ALL_PAID_NOTIFY:
            localStorage.setItem(`notify_${action?.operatorId}`, JSON.stringify([]));
            return { ...state, ...{ listPaid: [] } };

        case NOTIFY.REMOVE_FREE_NOTIFY_BY_ID:
            if (action?.notifyID) {
                return {
                    ...state,
                    ...{
                        listFree: state.listFree.filter((notify) => notify?.id !== action?.notifyID),
                    },
                };
            }
            return state;

        case NOTIFY.REMOVE_PAID_NOTIFY_BY_ID:
            if (action?.notifyID) {
                localStorage.setItem(
                    `notify_${action?.operatorId}`,
                    JSON.stringify(state.listPaid.filter((notify) => notify?.id !== action?.notifyID)),
                );
                return {
                    ...state,
                    ...{
                        listPaid: state.listPaid.filter((notify) => notify?.id !== action?.notifyID),
                    },
                };
            }
            return state;

        case NOTIFY.REMOVE_PAID_NOTIFY_BY_CHATUID:
            if (action?.chatUID) {
                const filteredListPaid = state?.listPaid?.filter((notify) => {
                    return !(
                        notify?.chat_uid === action?.chatUID &&
                        action?.notificationTypeArr?.includes(notify?.notification_type)
                    );
                });
                localStorage.setItem(`notify_${action?.operatorId}`, JSON.stringify(filteredListPaid));
                return {
                    ...state,
                    ...{
                        listPaid: filteredListPaid,
                    },
                };
            }
            return state;

        // case NOTIFY.REMOVE_LETTER_NOTIFY_BY_WOMANID_AND_MANID:
        // 	if (action?.womanID && action?.manID) {
        // 		const removeFreeNotify = state.listFree.find(notify => +notify?.sender_external_id === +action?.manID && +notify?.recipient_external_id === +action?.womanID && notify?.notification_type === "letter");
        // 		const removePaidNotify = state.listPaid.find(notify => +notify?.sender_external_id === +action?.manID && +notify?.recipient_external_id === +action?.womanID && notify?.notification_type === "letter");
        //
        // 		if (removeFreeNotify?.chat_uid || removePaidNotify?.chat_uid) {
        // 			console.error('test--> ',state.listPaid.filter(notify => (notify?.notification_type !== removePaidNotify?.notification_type && notify?.chat_uid !== removePaidNotify?.chat_uid)))
        // 			if (removePaidNotify?.chat_uid) {
        // 				localStorage.setItem('notify', JSON.stringify(state.listPaid.filter(notify => ((notify?.chat_uid !== removePaidNotify?.chat_uid) && (notify?.notification_type === removePaidNotify?.notification_type)))));
        // 			}
        // 			return {
        // 				...state, ...{
        // 					listPaid: state.listPaid.filter(notify => notify?.chat_uid !== removePaidNotify?.chat_uid && notify?.notification_type !== removePaidNotify?.notification_type),
        // 					listFree: state.listFree.filter(notify => notify?.chat_uid !== removeFreeNotify?.chat_uid && notify?.notification_type !== removePaidNotify?.notification_type),
        // 				}
        // 			};
        // 		}
        // 	}
        // 	return state;

        case NOTIFY.CHECK_FREE_NOTIFY: {
            const filteredList = state?.listFree?.filter((notify) => {
                if (notify?.expires_at) return !(moment() >= moment(notify?.expires_at));
                else return true;
            });

            if (state?.listFree?.length !== filteredList?.length) {
                return { ...state, ...{ listFree: filteredList } };
            }
            return state;
        }

        case NOTIFY.CHECK_PAID_NOTIFY_IN_LOCAL_STORAGE: {
            const paidNotifyInLocalStorage = JSON.parse(localStorage.getItem(`notify_${action?.operatorId}`));

            if (state?.listPaid?.length > paidNotifyInLocalStorage?.length)
                return { ...state, ...{ listPaid: paidNotifyInLocalStorage } };
            return state;
        }

        case NOTIFY.GET_PAID_NOTIFY:
            if (action?.notifyList?.length > 0) {
                return {
                    ...state,
                    ...{
                        listPaid: [...action.notifyList],
                        listPage: 1,
                        status: true,
                    },
                };
            }
            return state;

        case NOTIFY.UPDATE_FREE_ONLINE_NOTIFY:
            if (action?.notifyID) {
                return {
                    ...state,
                    ...{
                        listFree: state?.listFree.map((notify) => {
                            if (notify?.id === action?.notifyID) return { ...notify, ...action?.updatedNotify };
                            else return notify;
                        }),
                    },
                };
            }
            return state;

        default:
            return state;
    }
}
