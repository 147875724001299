import Base from './Base';

export default class LoginApi extends Base {
    postLoginToken(email, password) {
        return super
            .postWithoutToken(`login/login`, {
                email: email,
                password: password,
            })
            .then((res) => res?.data)
            .then((res) => {
                localStorage.setItem('email', email);
                localStorage.setItem('operator', res?.admin ? res?.operators_list[0]?.id : res?.operator_id);
                return res;
            });
    }

    postLogout({ operator_id }) {
        return super
            .post(`login/logout`, { operator_id })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }
}
