import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChatList from './ChatList';
import ChatRoom from './ChatRoom';
import ChatRoomEmpty from './ChatRoomEmpty';
import * as chatActions from '../../store/actions/chats';

export default function Chat() {
    const currentChatState = useSelector((state) => state.currentChat);
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.innerWidth < 1000) {
            if (!currentChatState?.info?.chat_uid) {
                dispatch(chatActions.openChatList());
            } else {
                dispatch(chatActions.closeChatList());
            }
        }
    }, [currentChatState.info]);

    return (
        <>
            <ChatList />
            {currentChatState?.info?.chat_uid ? <ChatRoom /> : <ChatRoomEmpty />}
        </>
    );
}
