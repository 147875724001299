import * as CHATS from '../constants/chats';
import ChatModel from '../../models/chat';

export function updateMessageAndLikeLimit(chatUID, messageLimit, likeLimit, letterLimit) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CHAT_LIST_UPDATE_MESSAGE_LIMIT,
            messageLimit,
            chatUID,
            likeLimit,
            letterLimit,
        });
    };
}

export function setLike(chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CHAT_LIST_SET_LIKE,
            chatUID,
        });
    };
}

export function addPinChat(chatUID) {
    const pinChatListFromLocalStorage = JSON.parse(localStorage.getItem('pinList')) ?? [];
    localStorage.setItem('pinList', JSON.stringify([...pinChatListFromLocalStorage, ...[chatUID]]));

    return function (dispatch) {
        return dispatch({
            type: CHATS.ADD_PIN_CHAT_LIST,
            chatUID,
        });
    };
}

export function removePinChat(chatUID) {
    const pinChatListFromLocalStorage = JSON.parse(localStorage.getItem('pinList')) ?? [];
    localStorage.setItem('pinList', JSON.stringify(pinChatListFromLocalStorage.filter((item) => item !== chatUID)));

    return function (dispatch) {
        return dispatch({
            type: CHATS.REMOVE_PIN_CHAT_LIST,
            chatUID,
        });
    };
}

export function initPinChatList(pinChatList) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.INIT_PIN_CHAT_LIST,
            pinChatList,
        });
    };
}

export function setSpecialStatus(specialStatus, chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.SET_SPECIAL_STATUS_CHAT_LIST,
            specialStatus,
            chatUID,
        });
    };
}

export function setMaybeStatus(maybeStatus, chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.SET_MAYBE_STATUS_CHAT_LIST,
            maybeStatus,
            chatUID,
        });
    };
}

export function setFavoriteStatus(favoriteStatus, chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.SET_FAVORITE_STATUS_CHAT_LIST,
            favoriteStatus,
            chatUID,
        });
    };
}

export function addChatList(chatOptions) {
    return function (dispatch) {
        dispatch({
            type: CHATS.LOADING_CHAT_LIST,
        });

        ChatModel.getStoreChatList(chatOptions).then((res) => {
            if (res?.chatList?.length) {
                return dispatch({
                    type: CHATS.ADD_CHAT_LIST,
                    chatList: res?.chatList,
                });
            } else {
                return dispatch({
                    type: CHATS.ADD_CHAT_LIST,
                    chatList: [],
                });
            }
        });
    };
}

export function addChatListWithoutReload(chatOptions) {
    return function (dispatch) {
        ChatModel.getStoreChatList(chatOptions).then((res) => {
            if (res?.chatList?.length) {
                return dispatch({
                    type: CHATS.ADD_CHAT_LIST,
                    chatList: res?.chatList,
                });
            } else {
                return dispatch({
                    type: CHATS.ADD_CHAT_LIST,
                    chatList: [],
                });
            }
        });
    };
}

export function addChatListWithPage(chatOptions) {
    return function (dispatch) {
        return ChatModel.getStoreChatList(chatOptions).then((res) => {
            if (res?.chatList?.length) {
                return dispatch({
                    type: CHATS.ADD_CHAT_LIST_WITH_PAGE,
                    chatList: res?.chatList,
                });
            } else {
                return dispatch({
                    type: CHATS.ADD_CHAT_LIST_WITH_PAGE,
                    chatList: [],
                });
            }
        });
    };
}

export function addChat(chat) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.ADD_CHAT,
            chat,
        });
    };
}

export function removeChat(chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.REMOVE_CHAT,
            chatUID,
        });
    };
}

export function addLastMessage(chatUID, lastMessage) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.ADD_LAST_MESSAGE,
            chatUID,
            lastMessage,
        });
    };
}

export function updateLastMessage(hashID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.UPDATE_LAST_MESSAGE,
            hashID,
        });
    };
}

export function addUnreadCountList(chatListUnread) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.ADD_UNREAD_COUNT_LIST,
            chatListUnread,
        });
    };
}

export function addUnreadCount(chatUID, unread) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.ADD_UNREAD_COUNT,
            chatUID,
            unread,
        });
    };
}

export function removeUnreadCount(chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.REMOVE_UNREAD_COUNT,
            chatUID,
        });
    };
}

export function blockChat(chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.BLOCK_CHAT,
            chatUID,
        });
    };
}

export function unblockChat(chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.UNBLOCK_CHAT,
            chatUID,
        });
    };
}

export function hideChat(chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.HIDE_CHAT,
            chatUID,
        });
    };
}

export function unHideChat(chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.UNHIDE_CHAT,
            chatUID,
        });
    };
}

export function setChatListFilterOnline(filterOnline) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CHAT_LIST_FILTER_ONLINE,
            filterOnline,
        });
    };
}

export function setChatListFilterSearch(filterSearch) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CHAT_LIST_FILTER_SEARCH,
            filterSearch,
        });
    };
}

export function setChatListFilterFreeze(filterFreeze) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CHAT_LIST_FILTER_FREEZE,
            filterFreeze,
        });
    };
}

export function setChatListFilterType(limits) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CHAT_LIST_FILTER_TYPE,
            limits,
        });
    };
}

export function updateActiveChat(newChat) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.CHAT_LIST_UPDATE_ACTIVE_CHAT,
            newChat,
        });
    };
}

export function setReadLastMessagesFromYou(chatId) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.SET_READ_LAST_MESS_FROM_YOU,
            chatId: chatId,
        });
    };
}

export function deleteChat(chatUID) {
    return function (dispatch) {
        return dispatch({
            type: CHATS.DELETE_CHAT,
            chatUID,
        });
    };
}

export function openChatList() {
    return function (dispatch) {
        return dispatch({ type: CHATS.OPEN_CHAT_LIST });
    };
}

export function closeChatList() {
    return function (dispatch) {
        return dispatch({ type: CHATS.CLOSE_CHAT_LIST });
    };
}

export function clearChatList() {
    return function (dispatch) {
        return dispatch({ type: CHATS.CLEAR_CHAT_LIST });
    };
}

export function hideMessage(chatUID) {
    return function (dispatch) {
        return dispatch({ type: CHATS.CHAT_LIST_HIDE_MESSAGE, chatUID });
    };
}
