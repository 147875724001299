import * as currentChatAction from '../../store/actions/currentChat';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { uniqueArrayByID } from '../../services/methods';
import { filterToHideChatHistoryByType } from '../../services/methods/chat';

export default function useScrollHistory() {
    const dispatch = useDispatch();
    const currentChatState = useSelector((state) => state.currentChat);
    const [isActiveSmartScroll, setIsActiveSmartScroll] = useState(true);
    const START_SLICE_PAGE = 3;
    const COUNT_ON_PAGE = 20;

    const scrollHistory = (event) => {
        const isScrollUp =
            !currentChatState?.isLoadedAll && event?.target?.scrollTop === 0 && currentChatState?.history?.length >= 10;
        const isScrollDown = event.target.scrollTop > event.target.scrollHeight - event.target.offsetHeight - 100;

        // Pagination on scroll up
        if (isScrollUp && !currentChatState?.historyPageLoading) {
            if (isActiveSmartScroll && +currentChatState?.historyPage > START_SLICE_PAGE) {
                dispatch(currentChatAction.setHidePageDown(+currentChatState?.historyPage - START_SLICE_PAGE));
            }

            dispatch(
                currentChatAction.addCurrentChatHistoryWithPage({
                    chat_id: currentChatState?.info?.chat_uid,
                    page: currentChatState?.historyPage,
                }),
            );
        }

        // Pagination on scroll down
        if (isScrollDown && !currentChatState?.historyPageLoading) {
            if (isActiveSmartScroll && +currentChatState?.hidePageDown > 0) {
                dispatch(currentChatAction.setHidePageDown(+currentChatState?.hidePageDown - 1, true));
            }
        }
    };

    const filterByPageScrollList = (item, key, list) => {
        if (isActiveSmartScroll && +currentChatState?.hidePageDown > 0) {
            return key < list?.length - +currentChatState?.hidePageDown * COUNT_ON_PAGE;
        } else return true;
    };

    useMemo(() => {
        const historyListFilter = uniqueArrayByID(currentChatState?.history?.filter(filterToHideChatHistoryByType));

        if (!currentChatState?.isLoadedAll && historyListFilter?.length && historyListFilter?.length < 15) {
            setIsActiveSmartScroll(false);
            dispatch(
                currentChatAction.addCurrentChatHistoryWithPage(
                    {
                        chat_id: currentChatState?.info?.chat_uid,
                        page: currentChatState?.historyPage,
                    },
                    true,
                ),
            );
        }
    }, [currentChatState.history]);

    return { scrollHistory, filterByPageScrollList };
}
