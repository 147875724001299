import { useEffect, useState } from 'react';
import ChatsApi from '../../services/api/ChatsApi';

export default function useInitTranslate(
    isOpenTranslateBlock,
    isDisabledButton,
    setIsDisabledButton,
    textSourceTranslate,
    textTargetTranslate,
    setTextSourceTranslate,
    setTextTargetTranslate,
) {
    const translateOptions = [
        { value: 'ru', label: 'Русский' },
        { value: 'en', label: 'English' },
        { value: 'uk', label: 'Українська' },
        { value: 'de', label: 'German' },
        { value: 'fr', label: 'French' },
        { value: 'es', label: 'Spanish' },
        { value: 'ro', label: 'Romanian' },
        { value: 'bg', label: 'Bulgarian' },
        { value: 'kk', label: 'Kazakh' },
        { value: 'hi', label: 'Hindi' },
    ];

    const getDefaultSourceLanguageOption = JSON.parse(localStorage.getItem('chosenTranslateSourceLanguage'));
    const [activeSourceTranslate, setActiveSourceTranslate] = useState(
        getDefaultSourceLanguageOption || translateOptions[0],
    );
    const [activeTargetTranslate, setActiveTargetTranslate] = useState(translateOptions[1]);

    const selectSourceTranslate = (data) => {
        localStorage.setItem('chosenTranslateSourceLanguage', JSON.stringify(data));
        setActiveSourceTranslate(data);

        if (data.value === activeTargetTranslate.value) {
            setActiveTargetTranslate(activeSourceTranslate);
        }
    };

    const selectTargetTranslate = (data) => {
        setActiveTargetTranslate(data);

        if (data.value === activeSourceTranslate.value) {
            setActiveSourceTranslate(activeTargetTranslate);
        }
    };

    const switchTargetTranslate = () => {
        setTextSourceTranslate(textTargetTranslate);
        setTextTargetTranslate(textSourceTranslate);
        setActiveSourceTranslate(activeTargetTranslate);
        setActiveTargetTranslate(activeSourceTranslate);
    };

    const postTranslateText = () => {
        if (textSourceTranslate?.length > 0 && !isDisabledButton) {
            setIsDisabledButton(true);
            new ChatsApi()
                .postTranslate(textSourceTranslate, activeSourceTranslate?.value, activeTargetTranslate?.value)
                .then((res) => {
                    setIsDisabledButton(false);
                    if (res?.status && res?.text) setTextTargetTranslate(res?.text);
                });
        }
    };

    useEffect(() => {
        if (!isOpenTranslateBlock) {
            setActiveSourceTranslate(getDefaultSourceLanguageOption || translateOptions[0]);
            setActiveTargetTranslate(translateOptions[1]);
        }
    }, [isOpenTranslateBlock]);

    return {
        postTranslateText,
        translateOptions,
        activeSourceTranslate,
        selectSourceTranslate,
        activeTargetTranslate,
        selectTargetTranslate,
        switchTargetTranslate,
    };
}
