import React, { useEffect, useState } from 'react';

import WomanProfile from '../../components/woman-profile';
import ProfileApi from '../../services/api/ProfileApi';
import LoaderGif from '../../components/common/LoaderGif';
import PropTypes from 'prop-types';

WomanProfilePage.propTypes = {
    match: PropTypes.object,
};
export default function WomanProfilePage({ match }) {
    const [girlProfileInfo, setGirlProfileInfo] = useState(null);

    useEffect(() => {
        if (match?.params?.external_id) {
            new ProfileApi().getProfileInfo(match?.params?.external_id).then((res) => {
                if (res?.status) setGirlProfileInfo(res?.result);
            });
        }
    }, [match.params.external_id]);

    return (
        <div className="column-3 profile_man">
            {girlProfileInfo?.profile ? (
                <WomanProfile
                    girlProfileInfo={girlProfileInfo.profile}
                    girlProfileImages={girlProfileInfo.images}
                    girlProfileVideos={girlProfileInfo.videos}
                />
            ) : (
                <LoaderGif />
            )}
        </div>
    );
}
