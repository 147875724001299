import * as REFERENCES from '../constants/references';

const initState = {
    list: {},
};

export default function (state = initState, action) {
    switch (action.type) {
        case REFERENCES.GET_REFERENCES:
            return { ...state, ...{ list: action.list } };

        default:
            return state;
    }
}
