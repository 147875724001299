import React, { useEffect, useState } from 'react';
import StatSender from '../../components/stat/StatSender';
import * as statisticAction from '../../store/actions/statistics';
import { useDispatch } from 'react-redux';
import * as format from '../../services/helpers/format';

export default function SenderStat() {
    const dispatch = useDispatch();
    const [selectStartDate, setSelectStartDate] = useState(format.firstDayDateFormat(new Date()));
    const [selectEndDate, setSelectEndDate] = useState(format.dateFormat(new Date()));

    useEffect(() => {
        dispatch(statisticAction.senderStatistic(selectStartDate, selectEndDate, null));
    }, []);

    return (
        <StatSender
            setSelectStartDate={setSelectStartDate}
            setSelectEndDate={setSelectEndDate}
            selectStartDate={selectStartDate}
            selectEndDate={selectEndDate}
        />
    );
}
