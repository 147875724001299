import React from 'react';

import Item from './Item';

import Styles from './styles.module.scss';
import { useSelector } from 'react-redux';

export default ({ closeNoticeList, openNoticeEdit, deleteNotice, setIsEdit }) => {
    const noticeState = useSelector((state) => state.notice);

    return (
        <div className={Styles.list_block}>
            <div className={Styles.head}>
                <div className={Styles.title} onClick={closeNoticeList}>
                    Notes
                </div>
                <div
                    className={Styles.create_btn}
                    onClick={() => {
                        openNoticeEdit();
                        closeNoticeList();
                    }}
                />
            </div>
            <div className={Styles.list}>
                {noticeState?.list.map((item, key) => (
                    <Item key={key} item={item} deleteNotice={deleteNotice} setIsEdit={setIsEdit} />
                ))}
            </div>
        </div>
    );
};
