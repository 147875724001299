import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function useHandleNoticeList() {
    const noticeState = useSelector((state) => state.notice);
    const [isActive, setIsActive] = useState(false);

    const openNoticeList = () => noticeState?.list?.length && setIsActive(true);
    const closeNoticeList = () => setIsActive(false);

    return {
        isActive,
        openNoticeList,
        closeNoticeList,
    };
}
