import Modal from 'react-modal';
import React, { useState } from 'react';
import modalContent2 from '../../../styles/react-modal/modalContent2.style';
import styles from '../styles.module.scss';
import * as IMAGES from '../../common/Images';
import * as POSTS from '../../../constants/posts';
import { makeCDN } from '../../../services/methods';
import moment from 'moment';
// import Comment from './Comment';
// import { Picker } from 'emoji-mart';
// import useOutsideClicker from '../../../hooks/dom/useOutsideClicker';
import ModerationResponse from './ModerationResponse';
import * as ATTACH from '../../../constants/attach';
import * as modalsAction from '../../../store/actions/modals';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// import data from '@emoji-mart/data';

export default ({
    isViewModal,
    closeViewModal,
    selectedPostData,
    moderationResponse,
    activeProfile,
    openCreateModal,
    hidePost,
    showPost,
    // comments,
    // handleComment,
    // newComment,
    // sendComment,
    // onEnterSendComment,
    // onSetParentComment,
    // parentComment,
    // onGetAllReplies,
    // repliesData,
    // onDeleteComment,
    // addEmojiComment,
    // handleScrollComment,
}) => {
    const dispatch = useDispatch();
    const { chat_uid } = useParams();

    const [currentImg, setCurrentImg] = useState(0);
    // const [isOpenedSmile, setIsOpenedSmile] = useState(false);

    const nextImage = () => {
        setCurrentImg((prev) => (prev === selectedPostData?.files?.length - 1 ? 0 : prev + 1));
    };
    const prevImage = () => {
        setCurrentImg((prev) => (prev === 0 ? selectedPostData?.files?.length - 1 : prev - 1));
    };

    // const smileBlockRef = useRef(null);
    // const textareaWrapRef = useRef(null);

    // useOutsideClicker(smileBlockRef, setIsOpenedSmile, textareaWrapRef);

    // const toggleSmileBlock = () => {
    //     setIsOpenedSmile(!isOpenedSmile);
    // };
    //
    // const pressEnterSendComment = (e) => {
    //     onEnterSendComment(e);
    //     if (isOpenedSmile) setIsOpenedSmile(false);
    // };

    // const replyName = parentComment?.user?.name;
    // const placeholderTextarea = replyName?.length ? `➦ Reply to: ${replyName}` : 'First, select a comment to reply';
    const postLines = selectedPostData?.text?.split('\n');
    const currentFile = selectedPostData?.files?.[currentImg];

    const onCloseModal = () => {
        closeViewModal();
        setCurrentImg(0);
    };

    const openVideoModal = () => {
        dispatch(
            modalsAction.openVideoContentModal({
                link: currentFile?.originUrl,
                attachment_payed: '1',
            }),
        );
    };

    if (!selectedPostData?.id) return <div />;
    return (
        <Modal style={modalContent2} isOpen={isViewModal} ariaHideApp={false} onRequestClose={closeViewModal}>
            <div className={`${styles.post_modal_view}`}>
                <img
                    className={styles.post_modal_close}
                    src={IMAGES.close_icon ?? ''}
                    alt="Close"
                    onClick={onCloseModal}
                />

                <div className={styles.post_modal_left_wrap}>
                    <div className={styles.post_modal_left}>
                        <div className={`${styles.post_modal_header} ${styles.view}`}>
                            <div className={styles.post_modal_profile_box}>
                                <img src={activeProfile?.photo_link ?? ''} alt="" />

                                <div className={styles.post_modal_profile_info}>
                                    {activeProfile?.name ?? ''}&nbsp;{activeProfile?.age ?? ''}
                                    <span>ID {activeProfile?.external_id ?? ''}</span>
                                </div>
                            </div>

                            <div className={styles.posts_page_grid_activity_item_wrap}>
                                <div className={styles.posts_page_grid_activity_item}>
                                    <img src={IMAGES.post_like ?? ''} alt="" />
                                    {selectedPostData?.countLikes ?? ''}
                                </div>

                                {!chat_uid && (
                                    <>
                                        {selectedPostData?.status === POSTS.STATUS_REWORK ? (
                                            <div
                                                className={`${styles.posts_page_grid_activity_item} ${styles.action}`}
                                                onClick={() =>
                                                    openCreateModal && openCreateModal('edit', selectedPostData)
                                                }
                                            >
                                                <img src={IMAGES.post_edit ?? ''} alt="" />
                                                Edit post
                                            </div>
                                        ) : (
                                            selectedPostData?.status === POSTS.STATUS_APPROVE && (
                                                <div
                                                    className={`${styles.posts_page_grid_activity_item} ${styles.action}`}
                                                    onClick={() => hidePost && hidePost(selectedPostData)}
                                                >
                                                    <img src={IMAGES.trash_bin_icon ?? ''} alt="" />
                                                    Hide post
                                                </div>
                                            )
                                        )}

                                        {selectedPostData?.status === POSTS.STATUS_HIDDEN && (
                                            <div
                                                className={`${styles.posts_page_grid_activity_item} ${styles.action}`}
                                                onClick={() => showPost && showPost(selectedPostData)}
                                            >
                                                <img src={IMAGES.show_icon ?? ''} alt="" />
                                                Show post
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        <div className={styles.post_modal_left_body}>
                            <div className={styles.post_modal_view_img_wrap}>
                                <span className={styles.post_modal_view_img_counter}>
                                    {currentImg + 1}/{selectedPostData?.files?.length ?? ''}
                                </span>

                                {selectedPostData?.files?.length > 1 && (
                                    <>
                                        <span
                                            className={`${styles.posts_page_icon_arrow} ${styles.left}`}
                                            onClick={prevImage}
                                        />
                                        <span
                                            className={`${styles.posts_page_icon_arrow} ${styles.right}`}
                                            onClick={nextImage}
                                        />
                                    </>
                                )}

                                {currentFile?.type === ATTACH.TYPE_VIDEO && (
                                    <span className={styles.icon_play} onClick={openVideoModal} />
                                )}

                                <img src={makeCDN(currentFile?.imageLargeUrl, 'POSTS')} alt="" />

                                <div className={styles.posts_page_icon_dots_row}>
                                    {selectedPostData?.files?.length > 1 &&
                                        selectedPostData?.files.map((el, index) => (
                                            <span
                                                key={index}
                                                className={`${styles.posts_page_icon_dot} ${index === currentImg ? styles.active : ''}`}
                                            />
                                        ))}
                                </div>
                            </div>

                            <p className={styles.post_modal_left_body_text}>
                                {!!postLines?.length &&
                                    postLines.map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                            </p>

                            <div className={styles.post_modal_left_body_data}>
                                <span>{moment(selectedPostData?.createdAt).format('DD.MM.YYYY')}</span>
                                <span>{moment(selectedPostData?.createdAt).format('HH:mm')}</span>
                            </div>

                            {!!moderationResponse?.length && (
                                <ModerationResponse moderationResponse={moderationResponse} />
                            )}
                        </div>
                    </div>
                </div>

                {/*<div className={styles.post_modal_right}>*/}
                {/*    <div className={styles.post_modal_right_header}>*/}
                {/*        <div className={styles.post_modal_comments_title}>*/}
                {/*            Comments&nbsp;<span>{selectedPostData?.countComments}</span>*/}
                {/*        </div>*/}

                {/*        <div className={`${styles.post_modal_input_row} ${!parentComment?.id ? styles.disabled : ''}`}>*/}
                {/*            <div className={styles.post_modal_comments_textarea_wrap} ref={textareaWrapRef}>*/}
                {/*                <textarea*/}
                {/*                    className={styles.post_modal_comments_textarea}*/}
                {/*                    id="comment-textarea"*/}
                {/*                    type="text"*/}
                {/*                    placeholder={placeholderTextarea}*/}
                {/*                    maxLength={POSTS.COMMENT_MAX_LENGTH}*/}
                {/*                    value={newComment}*/}
                {/*                    onChange={(e) => handleComment(e.target.value)}*/}
                {/*                    onKeyDown={pressEnterSendComment}*/}
                {/*                />*/}

                {/*                <img*/}
                {/*                    className={styles.post_modal_smile_icon}*/}
                {/*                    src={IMAGES.smile_icon}*/}
                {/*                    alt=""*/}
                {/*                    onClick={toggleSmileBlock}*/}
                {/*                />*/}

                {/*                {isOpenedSmile && (*/}
                {/*                    <div className={styles.post_modal_smiles_block} ref={smileBlockRef}>*/}
                {/*                        <Picker*/}
                {/*                            data={data}*/}
                {/*                            onEmojiSelect={addEmojiComment}*/}
                {/*                            emojiSize={32}*/}
                {/*                            theme="light"*/}
                {/*                            navPosition="none"*/}
                {/*                            previewPosition="none"*/}
                {/*                            searchPosition="none"*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                )}*/}

                {/*                <span className={styles.post_modal_comment_text_counter}>*/}
                {/*                    {newComment?.length ?? 0}/{POSTS.COMMENT_MAX_LENGTH}*/}
                {/*                </span>*/}
                {/*            </div>*/}

                {/*            <img*/}
                {/*                className={styles.post_modal_send_icon}*/}
                {/*                src={IMAGES.send_icon}*/}
                {/*                alt=""*/}
                {/*                onClick={sendComment}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className={styles.post_modal_comments_list} onScroll={handleScrollComment}>*/}
                {/*        {!!comments?.length &&*/}
                {/*            comments.map((item) => (*/}
                {/*                <Comment*/}
                {/*                    item={item}*/}
                {/*                    key={item?.id}*/}
                {/*                    parentComment={parentComment}*/}
                {/*                    repliesData={repliesData}*/}
                {/*                    onSetParentComment={onSetParentComment}*/}
                {/*                    onGetAllReplies={onGetAllReplies}*/}
                {/*                    onDeleteComment={onDeleteComment}*/}
                {/*                />*/}
                {/*            ))}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </Modal>
    );
};
