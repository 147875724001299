import React from 'react';
import Styles from './styles.module.scss';
import { formatDate } from '../../../../../services/time';
import { renderLastMessage } from '../../../../../services/methods';
import * as IMAGES from '../../../../common/Images';
import useHandleChatListItem from '../../../../../hooks/chat/useHandleChatListItem';
import useHandleMultiChat from '../../../../../hooks/side-menu/useHandleMultiChat';
import LimitIcon from './LimitIcon';

export default function Item({ item, womanProfile, isCurrentChat, isInActive }) {
    const { openChat, linkToWomanProfile, isPin, onDeleteChat } = useHandleChatListItem({ chatItem: item });
    const { setActiveProfile, activeProfile } = useHandleMultiChat();
    const womanPhoto = womanProfile?.photo_link?.length ? womanProfile?.photo_link : IMAGES.profile_img_empty;

    return (
        <div
            onClick={() => item?.chat_uid && openChat(item?.chat_uid)}
            className={`${Styles.clmn_2_chat_block_item} ${isInActive ? Styles.in_active : ''} ${isCurrentChat ? Styles.active : !+item?.woman_reply && +item?.is_male ? Styles.unanswered : ''}`}
            data-testid={item?.chat_uid}
        >
            {item?.name?.toLowerCase()?.includes('test') && (
                <div
                    className={Styles.clmn_2_chat_block_item_delete}
                    onClick={(e) => onDeleteChat(e, item?.chat_uid, womanProfile?.external_id)}
                    data-testid="delete-btn"
                />
            )}
            <LimitIcon item={item} isCurrentChat={isCurrentChat} />
            <div
                className={`${Styles.clmn_2_chat_block_item_left} ${+item?.online ? Styles.online : ''}`}
                data-testid="man-photo"
            >
                <img
                    src={item?.photo_link?.length ? item?.photo_link : IMAGES.profile_img_empty}
                    alt=""
                    className={Styles.clmn_2_chat_block_item_left_photo}
                />
                {!!+item?.male_favorite && (
                    <img src={IMAGES.default.star} alt="" className={Styles.clmn_2_chat_block_item_left_photo_fav} />
                )}
            </div>
            <div className={Styles.clmn_2_chat_block_item_middle}>
                <div className={Styles.clmn_2_chat_block_item_middle_top}>
                    <div className={Styles.clmn_2_chat_block_item_middle_name} data-testid="man-name">
                        {`${item?.name ?? ''}, ${item?.age ?? ''}`}
                    </div>
                    <div className={Styles.clmn_2_chat_block_item_middle_time} data-testid="date">
                        <div className={`${Styles.message_status} ${isCurrentChat ? Styles.send : ''}`} />
                        <span>{formatDate(item?.last_message_date ?? '')}</span>
                    </div>
                </div>
                <div
                    className={`${Styles.clmn_2_chat_block_item_middle_text} ${!+item?.woman_reply && +item?.is_male ? Styles.from_man : ''}`}
                    data-testid="content"
                >
                    {renderLastMessage(
                        item?.last_message_type ?? '',
                        item?.last_message ?? '',
                        !!+item?.is_male,
                        item?.name,
                    )}
                </div>
            </div>
            <div className={Styles.clmn_2_chat_block_item_right}>
                <div className={Styles.clmn_2_chat_block_item_profile}>
                    <div
                        className={`${Styles.clmn_2_chat_block_item_profile_photo_wrap} ${+womanProfile?.online ? Styles.online : ''}`}
                        data-testid="woman-photo"
                    >
                        <img alt="" src={womanPhoto} className={Styles.clmn_2_chat_block_item_profile_photo} />
                        {!!+item?.female_favorite && (
                            <img
                                src={IMAGES.default.star}
                                alt=""
                                className={Styles.clmn_2_chat_block_item_profile_photo_fav}
                            />
                        )}
                    </div>
                    <div className={Styles.clmn_2_chat_block_item_profile_inner}>
                        <div className={Styles.clmn_2_chat_block_item_profile_middle}>
                            <div className={Styles.clmn_2_chat_block_item_profile_name} data-testid="woman-name">
                                {`${womanProfile?.name ?? ''}, ${womanProfile?.age ?? ''}`}
                            </div>
                            <div className={Styles.clmn_2_chat_block_item_profile_id} data-testid="woman-external_id">
                                {`ID ${womanProfile?.external_id ?? ''}`}
                            </div>
                            <div className={Styles.clmn_2_chat_block_item_profile_place} data-testid="woman-place">
                                <img
                                    src={`../../../../../assets/flags/${womanProfile?.country_code?.length && womanProfile?.country_code?.toLowerCase()}.jpg`}
                                    alt=""
                                />
                                <span>{womanProfile?.city ?? ''}</span>
                            </div>
                        </div>
                        <div className={Styles.clmn_2_chat_block_item_profile_right}>
                            {+activeProfile?.external_id !== +womanProfile?.external_id && (
                                <div
                                    className={Styles.clmn_2_chat_block_item_profile_switch_btn}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        setActiveProfile(womanProfile);
                                    }}
                                    data-testid="activate-profile-btn"
                                >
                                    <img src={IMAGES.default.c2_chat_switch} alt="" />
                                </div>
                            )}
                            <div
                                className={Styles.clmn_2_chat_block_item_profile_prof_btn}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if (womanProfile?.external_id) {
                                        linkToWomanProfile(womanProfile?.external_id);
                                    }
                                }}
                                data-testid="profile-btn"
                            >
                                <img src={IMAGES.default.c2_profile} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isPin && <div className={Styles.clmn_2_chat_block_item_pinned} />}
        </div>
    );
}
