import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useTransition from './transition/useTransition';
import * as profilesAction from '../store/actions/profiles';
import * as notifyAction from '../store/actions/notify';
import useHandlePersonal from './personal/useHandlePersonal';
import useHandleWomanProfile from './profiles/useHandleWomanProfile';

export default function useInitFirstLoadProject() {
    const dispatch = useDispatch();
    const location = useLocation();
    const transition = useTransition();
    const handlePersonal = useHandlePersonal();
    const handleWomanProfile = useHandleWomanProfile();

    useEffect(() => {
        (async function () {
            const operatorId = localStorage.getItem('operator');
            await handleWomanProfile.fetchWomanProfileList();

            dispatch(notifyAction.getPaidNotify(+operatorId));
            handlePersonal.fetchPersonalManList();

            if (+operatorId) dispatch(profilesAction.setOperatorId(+operatorId));
            transition.setByLocationMenu(location?.pathname);
        })();
    }, []);
}
