import React from 'react';
import Styles from '../../FormLetters/Emoji/styles.module.scss';
// import 'emoji-mart/css/emoji-mart.css';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

export default ({ addEmoji, isSmileBlock, openSmileBlock, closeSmileBlock, smileBoxRef }) => {
    const onSmileWrapActive = () => (isSmileBlock ? closeSmileBlock() : openSmileBlock());

    return (
        <div className={Styles.c3_smile_btn_wrap}>
            <div
                className={`${Styles.c3_smile_btn_wrap2} ${Styles.smiles} ${isSmileBlock ? Styles.active : ''}`}
                onClick={onSmileWrapActive}
                ref={smileBoxRef}
            >
                <div
                    className={Styles.c3_smiles_list_wrap}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div className={Styles.c3_smiles_list}>
                        <Picker
                            data={data}
                            onEmojiSelect={addEmoji}
                            emojiSize={32}
                            theme="light"
                            navPosition="none"
                            previewPosition="none"
                            searchPosition="none"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
