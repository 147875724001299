import React from 'react';

export default ({ onSubmitMessage }) => {
    return (
        <div className="c3_send_btn" onClick={onSubmitMessage}>
            <img src="../img/c3-msg-send.svg" alt="" />
            <span>Send</span>
        </div>
    );
};
