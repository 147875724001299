import React from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StatsDatatable from '../../components/stat/StatDatatable';
import styles from './ProfilePage.module.scss';
import * as PROFILE_SERVICE from '../../constants/profile_services';

export default function ProfilesPage() {
    const history = useHistory();
    const profiles = useSelector((state) => state.profiles);

    const tableColumns = [
        {
            name: 'Avatar',
            selector: 'image.medium',
            cell: (d) => <img style={{ borderRadius: '50%' }} width="64px" alt={d.photo_link} src={d.photo_link} />,
            style: { flexGrow: '0.2' },
            maxWidth: '100px',
        },
        {
            name: 'Name / age',
            selector: 'name',
            sortable: true,
            maxWidth: '510px',
        },
        {
            name: 'external_id',
            selector: 'external_id',
            sortable: true,
            maxWidth: '490px',
        },
        {
            name: 'Allowed Services',
            selector: 'allowed_services',
            sortable: true,
            maxWidth: '490px',
        },
        // {
        // 	name: 'Status',
        // 	selector: 'status',
        // 	sortable: true,
        // 	maxWidth: '335px',
        // 	padding: '0px',
        // },
        {
            name: 'Status',
            selector: 'online',
            sortable: true,
            button: true,
            cell: (d) => (
                <button style={+d.online === 1 ? { backgroundColor: 'green' } : {}} className={'c3_send_btn'}>
                    {+d.online === 1 ? 'Online' : 'Offline'}
                </button>
            ),
            grow: '0',
        },
    ];
    const data = profiles.list.map((item, key) => {
        const returnStatisticObject = {};

        returnStatisticObject.id = key;
        returnStatisticObject.name = (
            <div
                onClick={() => history.push(`profile-woman/${item.external_id}`)}
                className={styles.profile_link}
                data-for={'react_tooltip'}
                data-tip={`Go to ${item.name} Profile`}
                data-iscapture={true}
                data-background-color={'#446077'}
                data-text-color={'white'}
                data-offset="{'right': 30}"
            >
                {item.name} , {item.age}
            </div>
        );
        returnStatisticObject.external_id = item.external_id;
        returnStatisticObject.photo_link = item.photo_link;
        returnStatisticObject.online = item.online;
        returnStatisticObject.allowed_services = (
            <>
                {PROFILE_SERVICE.SERVICE_LIST.map((service, key) => {
                    return item[service.serviceName] ? (
                        <img
                            key={key}
                            className={`${styles.padding_right_10_px} ${!+item[service.serviceName] ? styles.disabled : ''}`}
                            src={service.imageObject}
                            alt={service.toolTipText}
                            data-for={'react_tooltip'}
                            data-tip={service.toolTipText}
                            data-iscapture={true}
                            data-background-color={'#446077'}
                            data-text-color={'white'}
                            data-offset="{'left': 3}"
                        />
                    ) : null;
                })}
                <ReactTooltip id="react_tooltip" place={'bottom'} type={'light'} effect={'solid'} />
            </>
        );

        return returnStatisticObject;
    });

    return (
        <>
            <div className={'column-3 profile_man'}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        backgroundColor: '#fff',
                        padding: '20px',
                    }}
                >
                    <button className={'c3_send_btn'} style={{ backgroundColor: 'green' }}>
                        All to online
                    </button>
                    <button className={'c3_send_btn'} style={{ backgroundColor: 'red' }}>
                        All to offline
                    </button>
                </div>

                <StatsDatatable
                    data={data}
                    title="Operator Profiles"
                    defaultSortField="online"
                    defaultSortAsc={false}
                    tableColumns={tableColumns}
                />
            </div>
        </>
    );
}
