import React from 'react';
import { loading_GIF } from '../Images';
import styles from './LoaderGif.module.scss';

export default ({ mainStyle }) => {
    return (
        <div className={styles.loaderDiv}>
            <img
                className={mainStyle ? '' : styles.loaderImage}
                style={mainStyle ?? {}}
                src={loading_GIF}
                alt={'Loading'}
                data-testid="loader"
            />
        </div>
    );
};
