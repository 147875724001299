// import * as currentChatAction from "../../../store/actions/currentChat";
import { useSelector } from 'react-redux';

export default function useScrollChatMessageList() {
    // const dispatch = useDispatch();
    const currentChatState = useSelector((state) => state.currentChat);

    return (event) => {
        if (event.target.scrollTop === 0 && currentChatState?.history?.length >= 10) {
            // dispatch(currentChatAction.addMainChatHistoryWithPage(currentChatState.info.chat_uid, currentChatState.historyPage));
        }
    };
}
