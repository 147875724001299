import React from 'react';
import styles from '../../styles.module.scss';
import { makeCDN } from '../../../../../../services/methods';
import * as modalsAction from '../../../../../../store/actions/modals';
import { useDispatch } from 'react-redux';

export default ({ link }) => {
    const dispatch = useDispatch();

    const openContentModal = () => {
        dispatch(
            modalsAction.openPhotoContentModal({
                link: link,
                attachment_payed: '1',
            }),
        );
    };

    return (
        <div className={`${styles.drafts_table_item_attach} ${styles.photo}`}>
            <img src={makeCDN(link, 'IMAGE')} alt="" onClick={openContentModal} />
        </div>
    );
};
