import * as ALERTS from '../constants/alerts';

const initState = {
    type: '',
    text: '',
    title: '',
    btnName1: '',
    btnName2: '',
    successCallback: null,
};

export default function (state = initState, action) {
    switch (action.type) {
        case ALERTS.CLEAR_MESSAGE:
            return { ...state, ...initState };

        case ALERTS.SET_MESSAGE:
            return {
                ...state,
                ...{
                    type: action?.type?.length ? action.type : '',
                    text: action?.text?.length ? action.text : '',
                    title: action?.title?.length ? action.title : '',
                },
            };

        case ALERTS.SET_CUSTOM_CONFIRM_ALERT:
            return {
                ...state,
                ...{
                    type: action?.type?.length ? action.type : '',
                    title: action?.title?.length ? action.title : '',
                    text: action?.text?.length ? action.text : '',
                    btnName1: action?.btnName1?.length ? action.btnName1 : '',
                    btnName2: action?.btnName2?.length ? action.btnName2 : '',
                    successCallback: typeof action?.successCallback === 'function' ? action.successCallback : null,
                },
            };

        default:
            return state;
    }
}
