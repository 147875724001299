import React, { useEffect, useState } from 'react';
import Card from '../Card';
import update from 'immutability-helper';

const Container = ({ type, data, isSortingDisabled = false }) => {
    const [cards, setCards] = useState([]);
    const moveCard = (dragIndex, hoverIndex) => {
        //Добалили таку перевірку щоб швидко прибрати можливість сортувати контент в папках
        if (isSortingDisabled) return false;

        const dragCard = cards[dragIndex];
        setCards(
            update(cards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            }),
        );
    };

    useEffect(() => {
        setCards(data);
    }, [data]);

    return (
        <React.Fragment>
            {cards &&
                cards.map((card, i) => (
                    <Card type={type} key={card.id} index={i} id={card.id} data={card} moveCard={moveCard} />
                ))}
        </React.Fragment>
    );
};
export default Container;
