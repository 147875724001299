import React, { useEffect, useState } from 'react';
import Card from '../Card';
import update from 'immutability-helper';

const Container = ({ type, data, userId }) => {
    const [cards, setCards] = useState([]);
    const moveCard = (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        setCards(
            update(cards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            }),
        );
    };

    useEffect(() => {
        setCards(data);
    }, [data]);

    return (
        <React.Fragment>
            {cards.map((card, i) => (
                <Card
                    userId={userId}
                    type={type}
                    key={card.id}
                    index={i}
                    id={card.id}
                    data={card}
                    moveCard={moveCard}
                />
            ))}
        </React.Fragment>
    );
};
export default Container;
