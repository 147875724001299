import React, { useState } from 'react';

import GirlVideoBlock from '../../common/GirlVideoBlock';

export default function VideoGallery({ videoPanel, girlProfileVideos, openIframeModal }) {
    const [videoContent, setVideoContent] = useState(girlProfileVideos);

    const prevVideo = () =>
        setVideoContent([
            videoContent[videoContent.length - 1],
            ...videoContent.filter((item, index) => index !== videoContent.length - 1),
        ]);
    const nextVideo = () => setVideoContent([...videoContent.filter((item, index) => index !== 0), videoContent[0]]);

    return (
        <div className="c3_woman_profile_gallery_video_wrap" data-testid="woman-profile-video-gallery" ref={videoPanel}>
            <div className="c3_woman_profile_page_title">videos ({videoContent?.length ?? '0'})</div>
            {videoContent && videoContent.length > 0 && (
                <div className="c3_woman_profile_gallery_video_list_wrap">
                    <div className="c3_woman_profile_gallery_video_list">
                        {videoContent.map((item, key) => (
                            <div key={key} className="c3_woman_profile_gallery_video_list_item">
                                <GirlVideoBlock item={item} openVideoModal={openIframeModal} />
                            </div>
                        ))}
                    </div>
                    {videoContent.length > 1 && (
                        <div className="c3_woman_profile_gallery_photo_btn btn_prev" onClick={prevVideo}>
                            <img src="/img/c3wp-btn-prev.svg" alt="" />
                        </div>
                    )}
                    {videoContent.length > 1 && (
                        <div className="c3_woman_profile_gallery_photo_btn btn_next" onClick={nextVideo}>
                            <img src="/img/c3wp-btn-next.svg " alt="" />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
