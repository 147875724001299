import React from 'react';
import styles from '../styles.module.scss';
import BlockItemList from './BlockItemList';
import * as IMAGES from '../../../common/Images';

export default function BlockItem({ item, womanProfile }) {
    return (
        <>
            <div className={styles.item}>
                <div className={styles.item_photo}>
                    <img
                        src={womanProfile?.photo_link?.length ? womanProfile?.photo_link : IMAGES.profile_img_empty}
                        alt=""
                        className={styles.letters_list_item_man_photo}
                    />
                </div>
                <div className={styles.item_info}>
                    <div className={styles.item_name}>{`${womanProfile?.name}, ${womanProfile?.age}`}</div>
                    {item?.length && item?.map((el, key) => <BlockItemList key={key} el={el} />)}
                </div>
            </div>
        </>
    );
}
