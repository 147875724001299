import React, { useState, useEffect } from 'react';
import '../../styles/main.scss';
import LoginStyles from './LoginPage.module.scss';
import LoginButtonSVG from './img/login/wm-login-ico.svg';
import LoginApi from '../../services/api/LoginApi';
import AlertModal from '../../components/common/modals/AlertModal';
import { useDispatch } from 'react-redux';
import * as alertsAction from '../../store/actions/alerts';
import { PRODUCT_VERSION } from '../../store/constants/sync';

export default function LoginPage() {
    const dispatch = useDispatch();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }, []);

    const loginForm = (e) => {
        e.preventDefault();
        new LoginApi()
            .postLoginToken(login, password)
            .then((res) => {
                if (res?.status) {
                    localStorage.removeItem('notify');
                    localStorage.removeItem('online_notifications');
                    localStorage.setItem('admin', res?.admin);
                    if (res?.admin) {
                        localStorage.setItem('token', res?.operators_list[0]?.token);
                        localStorage.setItem('operators', JSON.stringify(res?.operators_list));
                    } else {
                        localStorage.setItem('token', res?.token);
                    }
                    window.location.href = '/chat';
                }
            })
            .catch((res) => {
                dispatch(alertsAction.setMessage('', res?.response?.data?.message));
            });
    };

    useEffect(() => {
        if (localStorage.getItem('token')) {
            window.location.href = '/chat';
        }
    }, []);

    return (
        <div>
            <div className={LoginStyles.wm_main_wrap}>
                <div className={LoginStyles.wm_main_right}>
                    <div className={LoginStyles.wm_main_left_logo}>ALPHA.DATE</div>
                    <small>Product Version: {PRODUCT_VERSION}</small>
                </div>
                <div className={LoginStyles.wm_main_left}>
                    <form className={LoginStyles.wm_main_form}>
                        <div className={LoginStyles.wm_main_form_row}>
                            <span>Your email</span>
                            <input
                                onChange={(e) => setLogin(e.target.value)}
                                value={login}
                                type="text"
                                name="login"
                                data-testid="email"
                                placeholder="email@alpha.date"
                            />
                        </div>
                        <div className={LoginStyles.wm_main_form_row}>
                            <span>Password</span>
                            <input
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                type="password"
                                name="password"
                                data-testid="password"
                                placeholder="Password"
                            />
                        </div>
                        <button className={LoginStyles.wm_main_form_btn} onClick={loginForm} data-testid="submit-btn">
                            <img src={LoginButtonSVG} alt="Log in" />
                            <span>Log in</span>
                        </button>
                    </form>
                </div>
            </div>
            <AlertModal />
        </div>
    );
}
