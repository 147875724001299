import React from 'react';
import styles from '../../styles.module.scss';
import * as IMAGES from '../../../../../common/Images';
import { makeCDN } from '../../../../../../services/methods';
import * as modalsAction from '../../../../../../store/actions/modals';
import { useDispatch } from 'react-redux';

export default ({ link, index, deleteFileAttachment }) => {
    const dispatch = useDispatch();

    const openContentModal = () => {
        dispatch(
            modalsAction.openPhotoContentModal({
                link: link,
                attachment_payed: '1',
            }),
        );
    };

    return (
        <div className={`${styles.sender_forms_middle_media_item} ${styles.photo}`}>
            <img src={makeCDN(link, 'IMAGE')} alt="" onClick={openContentModal} />
            <div
                className={`${styles.sender_forms_middle_media_item_delete}`}
                onClick={(e) => {
                    e.stopPropagation();
                    deleteFileAttachment(index);
                }}
            >
                <img src={IMAGES.sender_forms_delete_media} alt="" />
            </div>
        </div>
    );
};
