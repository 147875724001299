import * as MENU from '../constants/menu';
import * as MENU_CONST from '../../constants/menu';

const initState = {
    main: 'chat',
    sub: '',
    letters: 'inbox',
    composeLetterFilter: '',
    composeLetterSearch: '',
    previousPage: '',
};

export default function (state = initState, action) {
    switch (action.type) {
        case MENU.SET_MAIN_MENU:
            if (action?.data === 'chat') {
                return {
                    ...state,
                    ...{
                        main: action?.data,
                        sub: '',
                    },
                };
            } else {
                return {
                    ...state,
                    ...{
                        main: action?.data,
                        sub: '',
                    },
                };
            }

        case MENU.SET_SUB_MENU:
            if (action?.data === MENU_CONST.MENU_BLOCK) {
                return {
                    ...state,
                    ...{
                        main: '',
                        sub: action?.data,
                    },
                };
            } else {
                return {
                    ...state,
                    ...{
                        main: 'chat',
                        sub: action?.data,
                    },
                };
            }

        case MENU.SET_LETTERS_MENU:
            return {
                ...state,
                ...{
                    letters: action?.data,
                },
            };

        case MENU.SET_COMPOSE_LETTER_FILTER:
            return {
                ...state,
                ...{
                    composeLetterFilter: action?.data,
                },
            };

        case MENU.SET_COMPOSE_LETTER_SEARCH:
            return {
                ...state,
                ...{
                    composeLetterSearch: action?.data,
                },
            };

        case MENU.SET_PREVIOUS_PAGE:
            return {
                ...state,
                previousPage: action?.data,
            };

        default:
            return state;
    }
}
