import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlockTable from './BlockTable';
import styles from './styles.module.scss';
import useHandleBlockList from '../../hooks/block/useHandleBlockList';
import * as blockListAction from '../../store/actions/blockList';
import LoaderGif from '../common/LoaderGif';
import * as IMAGES from './../common/Images';

export default function Block() {
    const dispatch = useDispatch();
    const blockListState = useSelector((state) => state.blockList);

    const { groupBlockList, handleSearch } = useHandleBlockList();

    const [data, setData] = useState(null);

    const onChangeTabCallBack = (tab) => {
        dispatch(blockListAction.changeTab(tab));
    };
    useEffect(() => {
        blockListState?.list?.length &&
            setData(groupBlockList(blockListState?.filterSearch ? blockListState?.filterList : blockListState?.list));
        return () => {
            setData(null);
        };
    }, [blockListState]);

    return (
        <>
            <div className={styles.block_page_wrap}>
                <div className={styles.block_page_content}>
                    <div className={styles.block_page_content_head}>
                        <div className={styles.block_page_content_head_left}>
                            <div
                                className={`${styles.block_page_content_head_tab_btn} ${!blockListState?.theyTab ? styles.active : ''}`}
                                onClick={() => onChangeTabCallBack(false)}
                            >
                                You
                            </div>
                            <div
                                className={`${styles.block_page_content_head_tab_btn} ${blockListState?.theyTab ? styles.active : ''}`}
                                onClick={() => onChangeTabCallBack(true)}
                            >
                                They
                            </div>
                        </div>
                        <div className={styles.clmn_2_chat_top_filters_search}>
                            <input
                                type="text"
                                id="clmn_is_online"
                                className={styles.block_page_search_input}
                                value={blockListState?.filterSearch}
                                onChange={(e) => handleSearch(e?.target?.value)}
                                placeholder="Search ID or name"
                            />
                        </div>
                        <div onClick={() => handleSearch(blockListState?.filterSearch)}>
                            <div className={styles.block_page_content_head_show_btn}>Show</div>
                        </div>
                    </div>
                    {data?.length ? (
                        <BlockTable data={data} />
                    ) : blockListState?.isLoading ? (
                        <LoaderGif />
                    ) : (
                        <div className={styles.block_page_empty}>
                            <img src={IMAGES.not_found_results} alt="" />
                            <div>Your block list is empty</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
