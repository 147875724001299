import React, { useState } from 'react';
import DoubleSelect from '../../components/select/DoubleSelect';
import LargeSelect from '../../components/select/LargeSelect';
import * as DICTIONARY_CREATE_PROFILE_PAGE from '.././../dictionary/create-profile-page';

import '../../styles/woman-search.scss';
import '../../styles/ws-select.scss';
import PropTypes from 'prop-types';

SearchToolBar.propTypes = {
    bodyList: PropTypes.array,
    eyeList: PropTypes.array,
    hairList: PropTypes.array,
    religionList: PropTypes.array,
    childrenList: PropTypes.array,
    moreChildrenList: PropTypes.array,
    alcoholList: PropTypes.array,
    smokeList: PropTypes.array,
    educationList: PropTypes.array,
    maritalList: PropTypes.array,
    heightList: PropTypes.array,
    fetchTmpData: PropTypes.object,
    formDataLooking: PropTypes.object,
    setFormDataLooking: PropTypes.func,
};
export default function SearchToolBar({
    bodyList,
    eyeList,
    hairList,
    religionList,
    childrenList,
    moreChildrenList,
    alcoholList,
    smokeList,
    educationList,
    maritalList,
    heightList,
    fetchTmpData,
    formDataLooking,
    setFormDataLooking,
}) {
    const [openAgeFromBox, setOpenAgeFromBox] = useState(false);
    const [openAgeToBox, setOpenAgeToBox] = useState(false);
    const [openHeightFromBox, setOpenHeightFromBox] = useState(false);
    const [openHeightToBox, setOpenHeightToBox] = useState(false);
    const [openBodyTypeBox, setOpenBodyTypeBox] = useState(false);
    const [openEyeBox, setOpenEyeBox] = useState(false);
    const [openReligionBox, setOpenReligionBox] = useState(false);
    const [openChildNumBox, setOpenChildNumBox] = useState(false);
    const [openChildMoreBox, setOpenChildMoreBox] = useState(false);
    const [openAlcoholBox, setOpenAlcoholBox] = useState(false);
    const [openSmokingBox, setOpenSmokingBox] = useState(false);
    const [openEducationBox, setOpenEducationBox] = useState(false);
    const [openJobBox, setOpenJobBox] = useState(false);
    const [openHobbyBox, setOpenHobbyBox] = useState(false);
    const [openHairBox, setOpenHairBox] = useState(false);
    const [openMaritalBox, setOpenMaritalBox] = useState(false);

    const closeAllSelects = () => {
        if (
            openAgeFromBox ||
            openAgeToBox ||
            openHeightFromBox ||
            openHeightToBox ||
            openBodyTypeBox ||
            openEyeBox ||
            openReligionBox ||
            openChildNumBox ||
            openChildMoreBox ||
            openAlcoholBox ||
            openSmokingBox ||
            openEducationBox ||
            openJobBox ||
            openHobbyBox ||
            openHairBox ||
            openMaritalBox
        ) {
            setOpenAgeFromBox(false);
            setOpenAgeToBox(false);
            setOpenHeightFromBox(false);
            setOpenHeightToBox(false);
            setOpenBodyTypeBox(false);
            setOpenEyeBox(false);
            setOpenReligionBox(false);
            setOpenChildNumBox(false);
            setOpenChildMoreBox(false);
            setOpenAlcoholBox(false);
            setOpenSmokingBox(false);
            setOpenEducationBox(false);
            setOpenJobBox(false);
            setOpenHobbyBox(false);
            setOpenHairBox(false);
            setOpenMaritalBox(false);
        }
    };

    return (
        <React.Fragment>
            <div className="c3_woman_search_filters_list" onClick={closeAllSelects}>
                <div className="c3_woman_search_filters_list_item_wrap two_sel">
                    <DoubleSelect
                        index="height_inch"
                        formDataFrom={formDataLooking.HEIGHT_FROM}
                        formDataTo={formDataLooking.HEIGHT_TO}
                        handleClickFrom={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ HEIGHT_FROM: item } });
                            setOpenHeightFromBox(false);
                        }}
                        handleClickTo={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ HEIGHT_TO: item } });
                            setOpenHeightToBox(false);
                        }}
                        onOpenBlockFrom={() => setOpenHeightFromBox(true)}
                        onOpenBlockTo={() => setOpenHeightToBox(true)}
                        openBoxFrom={openHeightFromBox}
                        openBoxTo={openHeightToBox}
                        arrayFieldsFrom={heightList}
                        arrayFieldsTo={heightList}
                        defaultNameFrom={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_FROM}
                        defaultNameTo={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_TO}
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_HEIGHT}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="eye"
                        formData={
                            formDataLooking.EYE && formDataLooking.EYE.eye
                                ? formDataLooking.EYE.eye
                                : formDataLooking.EYE
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ EYE: item } });
                            setOpenEyeBox(false);
                        }}
                        onOpenBlock={() => setOpenEyeBox(true)}
                        openBox={openEyeBox}
                        arrayFields={eyeList}
                        defaultName={
                            fetchTmpData && fetchTmpData.EYE
                                ? fetchTmpData.EYE
                                : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_EYE}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="body"
                        formData={
                            formDataLooking.BODY && formDataLooking.BODY.body
                                ? formDataLooking.BODY.body
                                : formDataLooking.BODY
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ BODY: item } });
                            setOpenBodyTypeBox(false);
                        }}
                        onOpenBlock={() => setOpenBodyTypeBox(true)}
                        openBox={openBodyTypeBox}
                        arrayFields={bodyList}
                        defaultName={
                            fetchTmpData && fetchTmpData.BODY
                                ? fetchTmpData.BODY
                                : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_BODY}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="hair"
                        formData={
                            formDataLooking.HAIR && formDataLooking.HAIR.hair
                                ? formDataLooking.HAIR.hair
                                : formDataLooking.HAIR
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ HAIR: item } });
                            setOpenHairBox(false);
                        }}
                        onOpenBlock={() => setOpenHairBox(true)}
                        openBox={openHairBox}
                        arrayFields={hairList}
                        defaultName={
                            fetchTmpData && fetchTmpData.HAIR
                                ? fetchTmpData.HAIR
                                : DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_SELECT_HAIR}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="marital"
                        formData={
                            formDataLooking.STATUS && formDataLooking.STATUS.marital
                                ? formDataLooking.STATUS.marital
                                : formDataLooking.STATUS
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ STATUS: item } });
                            setOpenMaritalBox(false);
                        }}
                        onOpenBlock={() => setOpenMaritalBox(true)}
                        openBox={openMaritalBox}
                        arrayFields={maritalList}
                        defaultName={
                            fetchTmpData && fetchTmpData.marital
                                ? fetchTmpData.marital
                                : DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.PROFILE_INFO_SELECT_MARITAL}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="education"
                        formData={
                            formDataLooking.EDUCATION && formDataLooking.EDUCATION.education
                                ? formDataLooking.EDUCATION.education
                                : formDataLooking.EDUCATION
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ EDUCATION: item } });
                            setOpenEducationBox(false);
                        }}
                        onOpenBlock={() => setOpenEducationBox(true)}
                        openBox={openEducationBox}
                        arrayFields={educationList}
                        defaultName={
                            fetchTmpData && fetchTmpData.education
                                ? fetchTmpData.education
                                : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_EDUCATION}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="religion"
                        formData={
                            formDataLooking.religion_id && formDataLooking.religion_id.religion
                                ? formDataLooking.religion_id.religion
                                : formDataLooking.religion_id
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ religion_id: item } });
                            setOpenReligionBox(false);
                        }}
                        onOpenBlock={() => setOpenReligionBox(true)}
                        openBox={openReligionBox}
                        arrayFields={religionList}
                        defaultName={
                            fetchTmpData && fetchTmpData.religion
                                ? fetchTmpData.religion
                                : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_RELIGION}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="children"
                        formData={
                            formDataLooking.CHILDREN && formDataLooking.CHILDREN.children
                                ? formDataLooking.CHILDREN.children
                                : formDataLooking.CHILDREN
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ CHILDREN: item } });
                            setOpenChildNumBox(false);
                        }}
                        onOpenBlock={() => setOpenChildNumBox(true)}
                        openBox={openChildNumBox}
                        arrayFields={childrenList}
                        defaultName={
                            fetchTmpData && fetchTmpData.CHILDREN
                                ? fetchTmpData.CHILDREN
                                : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_NUM_CHILD}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="children"
                        formData={
                            formDataLooking.MORECHILDREN && formDataLooking.MORECHILDREN.children
                                ? formDataLooking.MORECHILDREN.children
                                : formDataLooking.MORECHILDREN
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ MORECHILDREN: item } });
                            setOpenChildMoreBox(false);
                        }}
                        onOpenBlock={() => setOpenChildMoreBox(true)}
                        openBox={openChildMoreBox}
                        arrayFields={moreChildrenList}
                        defaultName={
                            fetchTmpData && fetchTmpData.MORECHILDREN
                                ? fetchTmpData.MORECHILDREN
                                : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_MORE_CHILD}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="alcohol"
                        formData={
                            formDataLooking.ALCOHOL && formDataLooking.ALCOHOL.alcohol
                                ? formDataLooking.ALCOHOL.alcohol
                                : formDataLooking.ALCOHOL
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ ALCOHOL: item } });
                            setOpenAlcoholBox(false);
                        }}
                        onOpenBlock={() => setOpenAlcoholBox(true)}
                        openBox={openAlcoholBox}
                        arrayFields={alcoholList}
                        defaultName={
                            fetchTmpData && fetchTmpData.alcohol
                                ? fetchTmpData.alcohol
                                : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_ALCOHOL}
                    />
                </div>
                <div className="c3_woman_search_filters_list_item_wrap">
                    <LargeSelect
                        index="smoke"
                        formData={
                            formDataLooking.SMOKING && formDataLooking.SMOKING.smoke
                                ? formDataLooking.SMOKING.smoke
                                : formDataLooking.SMOKING
                        }
                        handleClick={(item) => {
                            setFormDataLooking({ ...formDataLooking, ...{ SMOKING: item } });
                            setOpenSmokingBox(false);
                        }}
                        onOpenBlock={() => setOpenSmokingBox(true)}
                        openBox={openSmokingBox}
                        arrayFields={smokeList}
                        defaultName={
                            fetchTmpData && fetchTmpData.smoke
                                ? fetchTmpData.smoke
                                : DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_CHOOSE
                        }
                        selectTitle={DICTIONARY_CREATE_PROFILE_PAGE.LOOKING_SELECT_SMOKING}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
