import React from 'react';
import MenuItem from './MenuItem';
import Styles from './styles.module.scss';
import * as MENU from '../../../constants/menu';
import SITE from '../../../config/site';
import { useSelector } from 'react-redux';

export default function BottomMenu({ activeItem, activeItemName, isOpenMenu, setIsOpenMenu, bottomMenuBlockRef }) {
    const profilesState = useSelector((state) => state.profiles);

    const isOlymp = SITE.ID === 2;
    const isActiveProfile = !!profilesState?.active?.external_id;

    const onOpenWomanProfile = () => {
        if (isActiveProfile) window.open(`/${MENU.MENU_PROFILE_WOMAN}/${profilesState?.active?.external_id}`);
    };

    return (
        <div
            className={`${Styles.clmn_1_bottom_nav} ${isOpenMenu ? Styles.opened : ''}`}
            ref={bottomMenuBlockRef}
            data-testid="bottom-menu-btn"
        >
            <div className={Styles.clmn_1_bottom_menu_wrap} onClick={setIsOpenMenu}>
                <div className={Styles.clmn_1_bottom_menu_list} data-testid="bottom-menu-list">
                    {!isOlymp && (
                        <MenuItem
                            styleItem={`${Styles.search} ${Styles.dis_active}`}
                            styleActive={activeItemName === MENU.MENU_SEARCH ? Styles.active : ''}
                            titleItem={'Search'}
                        />
                    )}
                    {isOlymp ? (
                        <MenuItem
                            styleItem={`${Styles.profile} ${isActiveProfile ? '' : Styles.dis_active}`}
                            styleActive={activeItemName === MENU.MENU_PROFILE ? Styles.active : ''}
                            titleItem={'Profile'}
                            activeItem={onOpenWomanProfile}
                        />
                    ) : (
                        <MenuItem
                            styleItem={`${Styles.profile} ${Styles.dis_active}`}
                            styleActive={activeItemName === MENU.MENU_PROFILE ? Styles.active : ''}
                            titleItem={'Profile'}
                        />
                    )}
                    <MenuItem
                        styleItem={`${Styles.stats}`}
                        styleActive={activeItemName === MENU.MENU_STATISTIC ? Styles.active : ''}
                        titleItem={'Statistic'}
                        activeItem={() => activeItem(MENU.MENU_STATISTIC)}
                    />
                    {!isOlymp && (
                        <MenuItem
                            styleItem={`${Styles.sender_stats}`}
                            styleActive={activeItemName === MENU.MENU_SENDER_STATISTIC ? Styles.active : ''}
                            titleItem={'Sender Statistics'}
                            activeItem={() => activeItem(MENU.MENU_SENDER_STATISTIC)}
                        />
                    )}
                    <MenuItem
                        styleItem={`${Styles.black_list}`}
                        styleActive={activeItemName === MENU.MENU_BLOCK ? Styles.active : ''}
                        titleItem={'Block list'}
                        activeItem={() => activeItem(MENU.MENU_BLOCK)}
                    />
                    {!isOlymp && (
                        <MenuItem
                            styleItem={`${Styles.tutorial} ${Styles.dis_active}`}
                            styleActive={activeItemName === MENU.MENU_TUTORIAL ? Styles.active : ''}
                            titleItem={'Tutorial'}
                        />
                    )}
                    <MenuItem
                        styleItem={Styles.sign_out}
                        styleActive={activeItemName === MENU.MENU_SIGN_OUT ? Styles.active : ''}
                        titleItem={'Sign out'}
                        activeItem={() => activeItem(MENU.MENU_SIGN_OUT)}
                    />
                </div>
                <div className={Styles.clmn_1_bottom_menu_btn}>Menu</div>
            </div>
            <div className={`${Styles.clmn_1_bottom_hide_btn}`} onClick={() => activeItem(MENU.MENU_SIGN_OUT)} />
        </div>
    );
}
