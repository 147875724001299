import React from 'react';
import Styles from '../../FormLetters/styles.module.scss';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import AttachModalNew from '../../../../common/AttachModalNew';
import attachStyles from '../../../../../styles/react-modal/attach.style';
import * as filesAction from '../../../../../store/actions/files';
import * as ATTACH from '../../../../../constants/attach';

export default ({
    activeTypeFile,
    setActiveTypeFile,
    setAttachModalIsOpen,
    attachFile,
    attachModalIsOpen,
    womanId,
}) => {
    const dispatch = useDispatch();

    const profilesState = useSelector((state) => state.profiles);
    // const currentMailboxState = useSelector(state => state.currentChatMailbox);

    // const { info } = currentMailboxState;

    const womanProfile = profilesState?.list?.find((woman) => +woman.external_id === +womanId);
    const openModal = () => {
        setAttachModalIsOpen(true);
        dispatch(filesAction.getAllImages(+womanProfile?.external_id));
        dispatch(filesAction.getAllVideos(+womanProfile?.external_id));
        dispatch(filesAction.getAllAudios(+womanProfile?.external_id));
    };

    const closeModal = () => {
        setAttachModalIsOpen(false);
    };

    return (
        <>
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.image}`}
                onClick={() => {
                    setActiveTypeFile(ATTACH.TAB_IMAGE);
                    openModal();
                }}
            />
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.video}`}
                onClick={() => {
                    setActiveTypeFile(ATTACH.TAB_VIDEO);
                    openModal();
                }}
            />
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.audio}`}
                onClick={() => {
                    setActiveTypeFile(ATTACH.TAB_AUDIO);
                    openModal();
                }}
            />
            <Modal style={attachStyles} isOpen={attachModalIsOpen} onRequestClose={closeModal} ariaHideApp={false}>
                <AttachModalNew
                    closeModal={closeModal}
                    sendImg={(e) => attachFile(e, 'SENT_IMAGE')}
                    sendVideo={(e) => attachFile(e, 'SENT_VIDEO')}
                    sendAudio={(e) => attachFile(e, 'SENT_AUDIO')}
                    userId={+womanProfile?.external_id}
                    activeTypeFile={activeTypeFile}
                    setActiveTypeFile={setActiveTypeFile}
                />
            </Modal>
        </>
    );
};
