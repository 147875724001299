import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './styles.module.scss';
import LettersNewItem from '../LettersNewItem';
import { useSelector } from 'react-redux';
// import useLettersHelper from "../../../hooks/letters/useLettersHelper";
import useEffectToLoadLetterList from '../../../hooks/letters/useEffectToLoadLetterList';
import LoaderGif from '../../common/LoaderGif';

export default () => {
    const mailboxState = useSelector((state) => state.mailbox);
    const menuState = useSelector((state) => state.menu);
    const handleLettersList = useEffectToLoadLetterList();

    return (
        <div className={styles.letters_list_wrap}>
            <div className={styles.letters_list}>
                {!mailboxState?.isLoading && mailboxState?.mailList?.length > 0
                    ? mailboxState.mailList.map((item, key) => (
                          <LettersNewItem
                              key={key + item?.id}
                              item={item}
                              type={menuState.letters}
                              {...handleLettersList}
                          />
                      ))
                    : mailboxState?.isLoading && <LoaderGif />}
                {!mailboxState?.isLoading && +mailboxState.mailListAllPages > 1 && (
                    <div className="letter-pagination">
                        <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={mailboxState.mailListAllPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={(event) => handleLettersList.handleChangePage(event.selected + 1)}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
