import React, { useEffect } from 'react';
import { addBlur, removeBlur } from '../../../../../services/dom/letters';
import { stopAudio } from '../../../../../services/methods/chat';
import styles from './styles.module.scss';
import * as IMAGES from '../../../Images';
import AudioPlayer from '../../../AudioPlayer';
import { sendErrToSentry } from '../../../../../services/sentry';

export default function AudioContent({ item, closeModal }) {
    useEffect(() => {
        stopAudio();
        addBlur();
        return () => removeBlur();
    }, []);

    try {
        return (
            <>
                <div className="popup_attach_photo_click_img_wrap">
                    <div className="popup_attach_photo_click_img audio">
                        <AudioPlayer src={item?.link} viewedAudio={() => stopAudio(item?.link)} />
                    </div>
                </div>
                <div className="pap-btn-close" onClick={closeModal}>
                    <img src={IMAGES.cross_white} alt="" />
                </div>
            </>
        );
    } catch (error) {
        sendErrToSentry(error);

        return (
            <>
                <div className={styles.video_error_wrap}>
                    <img src={IMAGES.video_error} className={styles.video_error_img} alt="" />
                    <div className={styles.video_error_text}>Failed to load audio file</div>
                </div>
                <div className="pap-btn-close" onClick={closeModal}>
                    <img src={IMAGES.cross_white} alt="" />
                </div>
            </>
        );
    }
}
