import React from 'react';

import Styles from './styles.module.scss';
import * as IMAGES from '../../common/Images';

export default function ChatRoomEmpty() {
    return (
        <div className={Styles.clmn_3_chat_empty}>
            <img src={IMAGES.default.empty_chat} alt="" />
        </div>
    );
}
