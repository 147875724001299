import { combineReducers } from 'redux';

import profiles from './profiles';
import menu from './menu';
import chats from './chats';
import currentChat from './currentChat';
import files from './files';
import notify from './notify';
import notice from './notice';
import mailbox from './mailbox';
import letterFilter from './letterFilter';
import currentChatMailbox from './currentChatMailbox';
import counters from './counters';
import alerts from './alerts';
import sync from './sync';
import sender from './sender';
import statistic from './statistic';
import search from './search';
import axiosCancelToken from './axiosCancelToken';
import loader from './loader';
import settings from './settings';
import onlineMan from './onlineMan';
import references from './references';
import modals from './modals';
import blockList from './blockList';
import personal from './personal';
import chanceTemplate from './chanceTemplate';
import virtualGifts from './virtualGifts';
import posts from './posts';

export default combineReducers({
    personal,
    references,
    onlineMan,
    settings,
    axiosCancelToken,
    profiles,
    loader,
    menu,
    chats,
    currentChat,
    files,
    notify,
    notice,
    mailbox,
    letterFilter,
    currentChatMailbox,
    counters,
    sync,
    sender,
    statistic,
    chanceTemplate,

    // senderStatistic,
    alerts,
    search,
    modals,
    blockList,
    virtualGifts,
    posts,
});
