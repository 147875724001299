import React from 'react';
import Paid from './Paid';
import Notes from './Notes';
import Styles from './styles.module.scss';
import * as IMAGES from '../common/Images';
import useInitNotification from '../../hooks/notification/useInitNotification';
// import NotifyNotes from "../notify/NotifyNotes";
import { useSelector, useDispatch } from 'react-redux';
import * as notifyAction from '../../store/actions/notify';
import { closeNotifyList } from '../../services/dom';

export default () => {
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const currentChatState = useSelector((state) => state.currentChat);
    const notifyState = useSelector((state) => state.notify);
    const dispatch = useDispatch();

    useInitNotification();

    const closeNotify = () => {
        closeNotifyList();
        dispatch(notifyAction.closeMobNotify());
    };

    return (
        <div
            className={`${Styles.clmn_4} ${notifyState?.isOpenMobNotify ? Styles.active : ''}`}
            data-testid="notification-block"
        >
            <div className={Styles.clmn_4_head}>
                <img src={IMAGES.default.c4_head} alt="" />
                <span>Notifications</span>
            </div>
            <Paid />
            {(currentChatState?.info?.id || currentChatMailboxState?.info?.id) && <Notes />}
            <div className={Styles.close_clmn_4} onClick={closeNotify} data-testid="close-btn" />
        </div>
    );
};
