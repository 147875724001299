import React from 'react';
import styles from '../styles.module.scss';
import * as IMAGES from '../../Images';
import { cutLengthString, makeCDN } from '../../../../services/methods';
import AudioPlayer from '../../AudioPlayer';
import { stopAudio } from '../../../../services/methods/chat';
import * as MEDIA from '../../../../constants/media';

export default function ListAudios({ message_content, payed, filename }) {
    const viewedAudio = () => {
        stopAudio(makeCDN(message_content, 'AUDIO'));
    };

    return (
        <div className={`${styles.media_list_item} ${styles.audio}`} onClick={viewedAudio}>
            {!!filename?.length && (
                <div className={`${styles.media_list_item_audio_text}`}>
                    {cutLengthString(filename, MEDIA.AUDIO_TITLE_LENGTH)}
                </div>
            )}
            {!+payed && (
                <div className={styles.media_list_item_unpaid_wrap}>
                    <div className={styles.media_list_item_unpaid_main}>
                        <img src={IMAGES.not_viewed_grey} alt="" />
                        <span>Unlisted</span>
                    </div>
                </div>
            )}
            <AudioPlayer
                className={`${styles.media_list_item_audio_content}`}
                src={makeCDN(message_content, 'AUDIO')}
                viewedAudio={viewedAudio}
            />
        </div>
    );
}
