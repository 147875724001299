import React, { useEffect, useState } from 'react';
import GirlsList from './GirlsList';
import Connect from './Connect';
import personal from './personal.module.scss';
import useHandlePersonal from '../../hooks/personal/useHandlePersonal';

export default () => {
    const handlePersonal = useHandlePersonal();
    const [mobilePage, setMobilePage] = useState(0);
    const mobilePagesList = [
        {
            className: 'choose_girl',
            buttonText: 'Choose a girl',
        },
        {
            className: 'choose_man',
            buttonText: 'Choose a man',
        },
        {
            className: 'wait_list',
            buttonText: 'Wait chat',
        },
    ];

    const changeNextPage = () => setMobilePage(mobilePage + 1);
    const changePrevPage = () => setMobilePage(mobilePage - 1);

    useEffect(() => {
        handlePersonal.fetchPersonalManList();
    }, []);

    return (
        <div className={`${personal.personal_page_wrap} ${mobilePagesList[mobilePage].className}`}>
            <GirlsList {...handlePersonal} />
            <Connect {...handlePersonal} />
            <div className={personal.personal_mobile_nav}>
                {!!mobilePage && (
                    <div className={personal.personal_mobile_back} onClick={changePrevPage}>
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17 7H1M1 7L7.5 1M1 7L7.5 13"
                                stroke="#B7BDC5"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span>{mobilePagesList[mobilePage - 1].buttonText}</span>
                    </div>
                )}
                {mobilePage < mobilePagesList.length - 1 && (
                    <div className={personal.personal_mobile_next} onClick={changeNextPage}>
                        <span>{mobilePagesList[mobilePage + 1].buttonText}</span>
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 7H17M17 7L10.5 1M17 7L10.5 13"
                                stroke="#1F4F74"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
};
