import React from 'react';
import DnDContainer from '../DragAndDrop/Container';
import Loader from '../Loader';

export default ({ type, audios, status, userId }) => {
    return (
        <div className="upload_popup_tabs_content_middle audio">
            <DnDContainer userId={userId} type={type} data={audios} />
            <Loader status={status} />
        </div>
    );
};
