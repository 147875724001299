import React, { useState } from 'react';
import { getReadableFileSizeString } from '../../../../services/methods';
import * as LENGTH from '../../../../constants/length';
// import * as REGEXP from "../../../../constants/regExp";
import { cutLengthString } from '../../../../services/methods';

export default ({ uploadImg, closeModalImg, handleUploadImg, setImgFile, imgFile }) => {
    const [imgName, setImgName] = useState('');

    // const handleImgName = (e) => {
    // 	if(REGEXP.ATTACH_FILENAME.test(e.target.value[e.target.value.length - 1])) {
    // 		setImgName(e.target.value);
    // 	}
    // }

    const onSubmitImg = () => {
        uploadImg({ file: imgFile, fileName: imgName.length > 0 ? imgName : imgFile.name.split('.')[0] });

        closeModalImg();
        setImgFile({});
        setImgName('');
    };

    return (
        <div className="add_file_popup_wrap" id="add_file_popup_wrap">
            <div className="popup_add_file_head">New image</div>
            {imgFile && imgFile.name && imgFile.size ? (
                <React.Fragment>
                    {
                        imgFile && +imgFile.size > LENGTH.MAX_IMG_SIZE ? (
                            <div className={'popup_add_file_row oversize'}>
                                File you’ve uploaded is too big <span>(max {LENGTH.MAX_IMG_SIZE_TITLE} Mb)</span>
                            </div>
                        ) : null
                        // <div className="popup_add_file_row">
                        // 	<span>Image name</span>
                        // 	<input type="text" placeholder="Enter image name here" value={imgName} required={true}
                        // 		   onChange={handleImgName}/>
                        // </div>
                    }
                    {+imgFile.size <= LENGTH.MAX_IMG_SIZE ? (
                        <div className="popup_add_file_row uploaded">
                            <span>File uploaded</span>
                            <div className="popup_uploaded_info">
                                {cutLengthString(imgFile.name, LENGTH.ATTACH_TITLE_NAME)}
                                <span>({getReadableFileSizeString(imgFile.size)})</span>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                </React.Fragment>
            ) : (
                <div />
            )}
            {imgFile && imgFile.name && imgFile.size && +imgFile.size <= LENGTH.MAX_IMG_SIZE ? (
                <div className="popup_add_file_row add_btn">
                    <div className="popup_add_file_upload_btn" onClick={imgFile && imgFile.name ? onSubmitImg : null}>
                        Add image
                    </div>
                </div>
            ) : (
                <div className="popup_add_file_row upload_btn">
                    <input type="file" accept="image/*" id="popup_inp_file1" onChange={handleUploadImg} />
                    <label htmlFor="popup_inp_file1" className="popup_bottom_upload_btn">
                        <img src="/img/popup-upload.svg" alt="" />
                        Upload
                    </label>
                </div>
            )}
            <div className="popup_add_file_close" onClick={closeModalImg}>
                <img src="/img/cross_white.svg" alt="" />
            </div>
        </div>
    );
};
