export default {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(85,85,85,0)',
		zIndex: 999,
	},
	content: {
		width: '357px',
		height: '428px',
		top: '50%',
		left: '50%',
		padding: 0,

		transform: 'translate(-50%, -50%)'
	}
}
