import * as PERSONAL from '../constants/personal';

export function setPersonal(data) {
    return function (dispatch) {
        return dispatch({
            type: PERSONAL.SET_PERSONAL,
            data,
        });
    };
}
