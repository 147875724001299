import * as BLOCK from '../constants/blockList';

const initState = {
    list: [],
    filterList: [],
    status: false,
    isLoading: false,
    isLoadedAll: false,
    filterSearch: '',
    theyTab: false,
};

export default function (state = initState, action) {
    switch (action.type) {
        case BLOCK.LOADING_BLOCK_LIST:
            return {
                ...state,
                ...{
                    isLoading: true,
                    isLoadedAll: false,
                    list: [],
                },
            };
        case BLOCK.ADD_BLOCK_LIST:
            return {
                ...state,
                ...{
                    list: action.list,
                    filterList:
                        action?.filterSearch &&
                        action?.list.filter((item) => {
                            return (
                                item?.woman_external_id.includes(action?.filterSearch) ||
                                item?.man_external_id.includes(action?.filterSearch) ||
                                item?.woman_name.toLowerCase().includes(action?.filterSearch.toLowerCase())
                            );
                        }),
                    status: true,
                    isLoading: false,
                    isLoadedAll: action?.list?.length < BLOCK.BLOCK_LIST_LENGTH,
                },
            };
        case BLOCK.UNBLOCK:
            return {
                ...state,
                ...{
                    list: state?.list.filter((item) => {
                        return item?.chat_uid !== action?.chatUID;
                    }),
                    filterList: state?.list.filter((item) => {
                        return item?.chat_uid !== action?.chatUID;
                    }),
                },
            };
        case BLOCK.BLOCK_LIST_FILTER_SEARCH:
            return {
                ...state,
                ...{
                    isLoadedAll: action?.list?.length < BLOCK.BLOCK_LIST_LENGTH,
                    isLoading: false,
                    filterSearch: action?.filterSearch,
                },
            };
        case BLOCK.CHANGE_TAB:
            return {
                ...state,
                ...{
                    theyTab: action.theyTab,
                },
            };
        default:
            return state;
    }
}
