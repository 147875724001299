import React from 'react';
import VideoPlayer from '../../../common/VideoPlayer';

export default ({ item, closeModal }) => {
    return (
        <React.Fragment>
            <div className="popup_attach_photo_click_img_wrap">
                <div className={`popup_attach_photo_click_img ${item.attachment_payed === '1' ? '' : 'blur_unpaid'}`}>
                    <VideoPlayer data={item.link} />
                </div>
            </div>
            <div className="pap-btn-close" onClick={closeModal}>
                <img src="/img/pap-btn-close.svg" alt="" />
            </div>
        </React.Fragment>
    );
};
