import Base from '../Base';

export default class VideoConverterApi extends Base {
    postVideoLinkToConvert(link) {
        return super
            .post(`v3/video_converter`, {
                type: 'link',
                data: link,
            })
            .then((res) => res?.data);
    }
}
