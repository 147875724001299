import React from 'react';
import { getReadableFileSizeString, cutLengthString, checkFile } from '../../../../services/methods';
import * as LENGTH from '../../../../constants/length';
import * as IMAGES from '../../Images';
import * as filesAction from '../../../../store/actions/files';
import { useDispatch } from 'react-redux';
import * as loaderAction from '../../../../store/actions/loader';
import * as ATTACH from '../../../../constants/attach';

export default ({ uploadImg, closeModalImg, openModalImg, handleUploadImg, setImgFile, imgFile, userId }) => {
    const dispatch = useDispatch();

    const onSubmitImg = async () => {
        const imageListToUpload = imgFile
            ?.filter((item) => +item?.size <= LENGTH.MAX_IMG_SIZE)
            ?.filter((item) => checkFile(item, ATTACH.TYPE_IMAGE));

        closeModalImg();
        setImgFile({});

        if (imageListToUpload?.length) {
            dispatch(loaderAction.setActiveGifLoader(true));
            for (const item of imageListToUpload) {
                await uploadImg({
                    file: item,
                    fileName: item?.name,
                    index: 0,
                });
            }
            dispatch(filesAction.getAllImages(userId));
            dispatch(loaderAction.setActiveGifLoader(false));
        } else {
            openModalImg();
        }
    };

    return (
        <div className="add_file_popup_wrap" id="add_file_popup_wrap" data-testid="upload-image-modal">
            <div className="popup_add_file_head">New image</div>
            {!!imgFile?.length && imgFile?.length > 1 ? (
                <>
                    <div className="popup_add_file_body">
                        {imgFile?.map((item, key) => (
                            <React.Fragment key={key}>
                                <div
                                    className={`popup_add_file_row uploaded ${+item?.size > LENGTH.MAX_IMG_SIZE || !checkFile(item, ATTACH.TYPE_IMAGE) ? 'warning' : ''}`}
                                >
                                    <span>File uploaded</span>
                                    <div className="popup_uploaded_info">
                                        {cutLengthString(item?.name, LENGTH.ATTACH_TITLE_NAME)}
                                        <span>({getReadableFileSizeString(item?.size)})</span>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="popup_add_file_row upload_btn">
                        <div className="popup_add_file_upload_btn" onClick={onSubmitImg} data-testid="add-image-btn">
                            Add images
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {imgFile?.length && (
                        <div
                            className={`popup_add_file_row uploaded ${+imgFile[0]?.size > LENGTH.MAX_IMG_SIZE || !checkFile(imgFile[0], ATTACH.TYPE_IMAGE) ? 'warning' : ''}`}
                        >
                            <span>File uploaded</span>
                            <div className="popup_uploaded_info">
                                {cutLengthString(imgFile[0]?.name, LENGTH.ATTACH_TITLE_NAME)}
                                <span>({getReadableFileSizeString(imgFile[0]?.size)})</span>
                            </div>
                        </div>
                    )}
                    {+imgFile[0]?.size <= LENGTH.MAX_IMG_SIZE && checkFile(imgFile[0], ATTACH.TYPE_IMAGE) ? (
                        <div className="popup_add_file_row add_btn">
                            <div
                                className="popup_add_file_upload_btn"
                                onClick={onSubmitImg}
                                data-testid="add-image-btn"
                            >
                                Add image
                            </div>
                        </div>
                    ) : (
                        <div className="popup_add_file_row upload_btn">
                            <input type="file" id="popup_inp_file1" accept="image/*" onChange={handleUploadImg} />
                            <label htmlFor="popup_inp_file1" className="popup_bottom_upload_btn">
                                <img src={IMAGES.popup_upload} alt="" />
                                <span>Upload</span>
                            </label>
                        </div>
                    )}
                </>
            )}
            <div className="popup_add_file_close" onClick={closeModalImg} data-testid="close-btn">
                <img src={IMAGES.cross_white} alt="" />
            </div>
        </div>
    );
};
