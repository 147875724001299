import React from 'react';

import ChatMainHeader from './OnlineManChat/ChatMainHeader';
import ChatMain from './OnlineManChat/ChatMain';

import OnlineManList from './OnlineManList/index';
import useEffectToGetOnlineManList from '../../hooks/online-man/useEffectToGetOnlineManList';
import { useSelector } from 'react-redux';

export default function OnlineMan() {
    const onlineManState = useSelector((state) => state.onlineMan);
    useEffectToGetOnlineManList();

    return (
        <>
            <OnlineManList />
            {onlineManState?.currentChat?.external_id ? (
                <div className="column-3">
                    <ChatMainHeader info={onlineManState.currentChat} />
                    <ChatMain />
                </div>
            ) : (
                <div className="column-3 empty" />
            )}
        </>
    );
}
