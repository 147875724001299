import React from 'react';

import global from '../../global.module.scss';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

export default function SenderWomanList({ profilesList, activeSenderProfile, setActiveProfile }) {
    const profilesState = useSelector((state) => state.profiles);

    return (
        <div className={styles.choose_sender}>
            <div className={global.title_wrap}>
                <div className={global.title}>Choose sender</div>
            </div>
            <div className={styles.list}>
                {profilesList.map((profile, key) => (
                    <div
                        key={key}
                        className={`${styles.item} ${+activeSenderProfile === +profile?.external_id ? styles.active : ''}`}
                        onClick={() => setActiveProfile(+profile?.external_id)}
                    >
                        <div className={styles.profile}>
                            <div className={`${styles.photo_wrap} ${+profile?.online ? styles.online : ''}`}>
                                <img src={profile?.photo_link ?? ''} className={styles.photo} alt="" />
                            </div>
                            <div className={styles.info}>
                                <div className={styles.name}>{`${profile?.name ?? ''}, ${profile?.age ?? ''}`}</div>
                                {profilesState.senderListInfo.some(
                                    (item) => +item?.woman_external_id === +profile?.external_id,
                                ) && <div className={styles.clmn_1_mm_chat_list_item_status_green} />}
                                <div className={styles.info_bottom}>
                                    <div className={styles.id}>{`ID ${profile?.external_id ?? ''}`}</div>
                                    <div className={styles.place}>
                                        {profile?.country_image && <img src={profile?.country_image} alt="" />}
                                        <span>{profile?.city ?? ''}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.check_img} />
                    </div>
                ))}
            </div>
        </div>
    );
}
