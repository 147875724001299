import * as MODALS from '../constants/modals';

const initState = {
    videoContentModal: {
        isActive: false,
        item: {},
    },
    photoContentModal: {
        isActive: false,
        item: {},
    },
    audioContentModal: {
        isActive: false,
        item: {},
    },
};

export default function (state = initState, action) {
    switch (action.type) {
        case MODALS.OPEN_VIDEO_CONTENT_MODAL: {
            return {
                ...state,
                videoContentModal: {
                    isActive: true,
                    item: action?.item,
                },
            };
        }
        case MODALS.CLOSE_VIDEO_CONTENT_MODAL: {
            return {
                ...state,
                videoContentModal: {
                    isActive: false,
                    item: {},
                },
            };
        }
        case MODALS.OPEN_PHOTO_CONTENT_MODAL: {
            return {
                ...state,
                photoContentModal: {
                    isActive: true,
                    item: action?.item,
                },
            };
        }
        case MODALS.CLOSE_PHOTO_CONTENT_MODAL: {
            return {
                ...state,
                photoContentModal: {
                    isActive: false,
                    item: {},
                },
            };
        }
        case MODALS.OPEN_AUDIO_CONTENT_MODAL: {
            return {
                ...state,
                audioContentModal: {
                    isActive: true,
                    item: action?.item,
                },
            };
        }
        case MODALS.CLOSE_AUDIO_CONTENT_MODAL: {
            return {
                ...state,
                audioContentModal: {
                    isActive: false,
                    item: {},
                },
            };
        }

        default: {
            return state;
        }
    }
}
