import * as CHATS from '../constants/chats';
import * as CHAT from '../../constants/chat';
import { nowDateUTCFormat } from '../../services/time';
import { sortListByDate, uniqueArrayByChatUid } from '../../services/methods';

const initState = {
    list: [],
    pinList: [],
    page: 2,
    status: false,
    isLoading: false,
    isLoadedAll: false,
    filterOnline: true,
    filterSearch: '',
    filterFreeze: true,
    limits: null,
};

export default function (state = initState, action) {
    switch (action.type) {
        case CHATS.LOADING_CHAT_LIST:
            return {
                ...state,
                ...{
                    isLoading: true,
                    isLoadedAll: false,
                    list: [],
                    page: 2,
                },
            };

        case CHATS.INIT_PIN_CHAT_LIST:
            if (action?.pinChatList?.length) {
                return {
                    ...state,
                    ...{
                        pinList: action?.pinChatList,
                    },
                };
            }
            return state;

        case CHATS.ADD_PIN_CHAT_LIST:
            return {
                ...state,
                ...{
                    pinList: [...state.pinList, ...[action?.chatUID]],
                },
            };

        case CHATS.REMOVE_PIN_CHAT_LIST:
            return {
                ...state,
                ...{
                    pinList: state.pinList.filter((item) => item !== action?.chatUID),
                },
            };

        case CHATS.REMOVE_UNREAD_COUNT:
            if (action?.chatUID) {
                return {
                    ...state,
                    ...{
                        list: state.list.map((chat) => {
                            if (chat?.chat_uid === action?.chatUID) return { ...chat, ...{ unread_count: 0 } };
                            return chat;
                        }),
                    },
                };
            }
            return state;

        case CHATS.ADD_CHAT_LIST:
            return {
                ...state,
                ...{
                    list: action.chatList,
                    status: true,
                    isLoading: false,
                    isLoadedAll: action?.chatList?.length < CHAT.CHAT_LIST_LENGTH,
                },
            };

        case CHATS.ADD_CHAT_LIST_WITH_PAGE:
            return {
                ...state,
                ...{
                    list: uniqueArrayByChatUid([...state.list, ...action.chatList]),
                    isLoadedAll: action?.chatList?.length < CHAT.CHAT_LIST_LENGTH,
                    page: action?.chatList?.length < CHAT.CHAT_LIST_LENGTH ? state?.page : +state?.page + 1,
                },
            };

        case CHATS.SET_FAVORITE_STATUS_CHAT_LIST:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => {
                        if (item?.chat_uid === action?.chatUID)
                            return { ...item, ...{ male_favorite: action?.favoriteStatus } };
                        else return item;
                    }),
                },
            };

        case CHATS.SET_MAYBE_STATUS_CHAT_LIST:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => {
                        if (item?.chat_uid === action?.chatUID) return { ...item, ...{ maybe: action?.maybeStatus } };
                        else return item;
                    }),
                },
            };

        case CHATS.SET_SPECIAL_STATUS_CHAT_LIST:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => {
                        if (item?.chat_uid === action?.chatUID)
                            return { ...item, ...{ special: action?.specialStatus } };
                        else return item;
                    }),
                },
            };

        case CHATS.ADD_CHAT:
            if (action?.chat?.chat_uid) {
                return {
                    ...state,
                    ...{
                        list: [
                            ...[action.chat],
                            ...state.list.filter((item) => item?.chat_uid !== action?.chat?.chat_uid),
                        ],
                    },
                };
            }
            return state;

        case CHATS.REMOVE_CHAT:
            if (action?.chatUID) {
                return {
                    ...state,
                    ...{
                        list: state.list.filter((item) => item?.chat_uid !== action?.chatUID),
                    },
                };
            }
            return state;

        case CHATS.ADD_LAST_MESSAGE:
            if (action?.chatUID && action?.lastMessage) {
                const currentChat = state.list.find((chat) => chat.chatUID === action.chatUID);
                if (currentChat) {
                    currentChat.messages.push(action.lastMessage);
                    currentChat.lastMessage = action.lastMessage;

                    return {
                        ...state,
                        ...{
                            list: [...[currentChat], ...state.list.filter((chat) => chat.chatUID !== action.chatUID)],
                        },
                    };
                }
            }
            return state;

        case CHATS.UPDATE_LAST_MESSAGE: {
            const findChat = state.list.find((chat) =>
                chat?.messages.some((message) => message?.id === action?.hashID),
            );
            if (findChat?.chatUID) {
                findChat.messages = findChat.messages.map((message) => {
                    if (message.id === action?.hashID) {
                        return {
                            ...message,
                            ...{
                                updatedAt: nowDateUTCFormat(),
                                createdAt: nowDateUTCFormat(),
                            },
                        };
                    }
                    return message;
                });
                findChat.lastMessage = {
                    ...findChat.lastMessage,
                    ...{
                        updatedAt: nowDateUTCFormat(),
                        createdAt: nowDateUTCFormat(),
                    },
                };

                return {
                    ...state,
                    ...{
                        list: [...[findChat], ...state.list.filter((chat) => chat.chatUID !== findChat.chatUID)],
                    },
                };
            }
            return state;
        }

        case CHATS.HIDE_CHAT:
            return {
                ...state,
                ...{
                    list: state?.list.map((item) => {
                        if (item?.chatUID === action?.chatUID) return { ...item, ...{ hideMan: true } };
                        return item;
                    }),
                },
            };

        case CHATS.UNHIDE_CHAT:
            return {
                ...state,
                ...{
                    list: state?.list.map((item) => {
                        if (item?.chatUID === action?.chatUID) return { ...item, ...{ hideMan: false } };
                        return item;
                    }),
                },
            };

        case CHATS.ADD_UNREAD_COUNT_LIST:
            if (action?.chatListUnread?.length > 0) {
                return {
                    ...state,
                    ...{
                        list: state.list.map((chat) => {
                            const chatUnread = action?.chatListUnread.find(
                                (chatUnread) => chatUnread?.chatUID === chat?.chatUID,
                            );

                            if (chatUnread?.unread) return { ...chat, ...{ unread: chatUnread.unread } };
                            return { ...chat, ...{ unread: 0 } };
                        }),
                    },
                };
            }
            return state;

        case CHATS.ADD_UNREAD_COUNT:
            if (action?.chatUID && action?.unread) {
                return {
                    ...state,
                    ...{
                        list: state.list.map((chat) => {
                            if (chat?.chatUID === action?.chatUID) return { ...chat, ...{ unread: action?.unread } };
                            return chat;
                        }),
                    },
                };
            }
            return state;

        case CHATS.BLOCK_CHAT:
            return {
                ...state,
                ...{
                    list: state?.list.map((item) => {
                        if (item?.chatUID === action?.chatUID) return { ...item, ...{ blockMan: true } };
                        return item;
                    }),
                },
            };

        case CHATS.UNBLOCK_CHAT:
            return {
                ...state,
                ...{
                    list: state?.list.map((item) => {
                        if (item?.chatUID === action?.chatUID) return { ...item, ...{ blockMan: false } };
                        return item;
                    }),
                },
            };

        case CHATS.CHAT_LIST_FILTER_ONLINE:
            return {
                ...state,
                ...{
                    filterOnline: action?.filterOnline,
                },
            };

        case CHATS.CHAT_LIST_FILTER_SEARCH:
            return {
                ...state,
                ...{
                    filterSearch: action?.filterSearch,
                },
            };

        case CHATS.CHAT_LIST_FILTER_FREEZE:
            return {
                ...state,
                ...{
                    filterFreeze: action?.filterFreeze,
                },
            };
        case CHATS.CHAT_LIST_FILTER_TYPE:
            return {
                ...state,
                ...{
                    limits: action?.limits,
                },
            };

        case CHATS.CHAT_LIST_UPDATE_ACTIVE_CHAT:
            return {
                ...state,
                ...{
                    list: sortListByDate(
                        state?.list.map((item) => {
                            if (item?.chat_uid === action?.newChat?.chat_uid)
                                return {
                                    ...item,
                                    ...{
                                        like_limit: !isNaN(+action.newChat?.like_limit)
                                            ? action.newChat?.like_limit
                                            : item?.like_limit,
                                        message_limit: !isNaN(+action.newChat?.message_limit)
                                            ? action.newChat?.message_limit
                                            : item?.message_limit,
                                        letter_limit: !isNaN(+action.newChat?.letter_limit)
                                            ? action.newChat?.letter_limit
                                            : item?.letter_limit,
                                        sender_external_id: action.newChat?.sender_external_id,
                                        last_message: action.newChat?.last_message,
                                        last_message_date: action.newChat?.last_message_date,
                                        last_message_type: action.newChat?.last_message_type,
                                        last_message_read_status: '0',
                                        is_male: action.newChat?.is_male ?? item?.is_male,
                                        woman_reply: action.newChat?.woman_reply ?? 1,
                                        date_created: new Date(),
                                    },
                                };
                            else return item;
                        }),
                        true,
                    ),
                },
            };

        case CHATS.SET_READ_LAST_MESS_FROM_YOU:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => {
                        if (item?.chat_uid === action?.chatId) {
                            return { ...item, ...{ last_message_read_status: '1' } };
                        } else {
                            return item;
                        }
                    }),
                },
            };

        case CHATS.CHAT_LIST_SET_LIKE:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => {
                        if (item?.chat_uid === action?.chatUID) {
                            return { ...item, ...{ like: true, like_limit: 0 } };
                        } else {
                            return item;
                        }
                    }),
                },
            };

        case CHATS.CHAT_LIST_UPDATE_MESSAGE_LIMIT:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => {
                        if (item?.chat_uid === action?.chatUID) {
                            return {
                                ...item,
                                ...{
                                    message_limit: !isNaN(+action?.messageLimit)
                                        ? action?.messageLimit
                                        : item?.message_limit,
                                    like_limit: !isNaN(+action?.likeLimit) ? action?.likeLimit : item?.like_limit,
                                    letter_limit: !isNaN(+action?.letterLimit)
                                        ? action?.letterLimit
                                        : item?.letter_limit,
                                },
                            };
                        } else {
                            return item;
                        }
                    }),
                },
            };

        case CHATS.DELETE_CHAT:
            return {
                ...state,
                ...{
                    list: Array.isArray(state?.list)
                        ? state?.list.filter((item) => item?.chat_uid !== action?.chatUID)
                        : [],
                },
            };

        case CHATS.OPEN_CHAT_LIST:
            return { ...state, ...{ isOpenChatList: true } };

        case CHATS.CLOSE_CHAT_LIST:
            return { ...state, ...{ isOpenChatList: false } };

        case CHATS.CLEAR_CHAT_LIST:
            return {
                ...state,
                ...{
                    list: [],
                },
            };

        case CHATS.CHAT_LIST_HIDE_MESSAGE:
            return {
                ...state,
                ...{
                    list: state.list.map((item) => {
                        if (item?.chat_uid === action?.chatUID) {
                            return { ...item, last_message: '' };
                        }
                        return item;
                    }),
                },
            };

        default:
            return state;
    }
}
