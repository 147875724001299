import React, { useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import { useSelector } from 'react-redux';
import list from '../StatSender/list.module.scss';
import * as IMAGES from '../../common/Images';

export default function StatSenderDetail() {
    const statisticState = useSelector((state) => state.statistic);

    const [detailList, setDetailList] = useState([]);

    function objectToArray(obj) {
        const arr = [];
        for (let key in obj) {
            arr.push({ ...{ id: key }, ...obj[key] });
        }
        arr.pop();
        arr.pop();

        setDetailList(arr);
    }

    useEffect(() => {
        objectToArray(statisticState?.filterSenderDetailList[0]);
    }, []);
    return (
        <div className={styles.stats_clmn_3}>
            {/*<div className={styles.stats_page_table_wrap}>*/}
            <div className={list.send_stat_list_wrap}>
                <div className={list.send_stat_item}>
                    {statisticState?.senderDetailList[0] && (
                        <div className={`${list.send_stat_profile}  ${list.detail_page}`}>
                            <img
                                src={detailList[0]?.avatar?.length ? detailList[0]?.avatar : IMAGES.profile_img_empty}
                                className={list.send_stat_photo}
                                alt=""
                            />
                            <div className={list.send_stat_name}>{`${detailList[0]?.name}, ${detailList[0]?.age}`}</div>
                            <div className={list.send_stat_id}>ID {detailList[0]?.sender_external_id}</div>
                        </div>
                    )}
                    <div className={list.send_stat_item_right}>
                        <div className={list.send_stat_item_list}>
                            <div className={`${list.send_stat_item_head}`}>
                                <div className={`${list.send_stat_head_col} ${list.content}`}>Message content</div>
                                <div className={`${list.send_stat_head_col} ${list.clear}`}>Clear</div>
                                <div className={`${list.send_stat_head_col} ${list.indirect}`}>Indirect</div>
                                <div className={`${list.send_stat_head_col} ${list.not_answer}`}>Not Answer</div>
                                <div className={`${list.send_stat_head_col} ${list.effective}`}>Effective</div>
                            </div>
                            <div className={`${list.send_stat_item_body} ${list.detail_page}`}>
                                {detailList?.map((msg, key) => (
                                    <div key={key} className={list.send_stat_item_row}>
                                        <div
                                            className={`${list.send_stat_item_col} ${list.content}`}
                                            title={msg?.message_content}
                                        >
                                            {msg?.message_content}
                                        </div>
                                        <div className={`${list.send_stat_item_col} ${list.clear}`}>{msg?.clear}</div>
                                        <div className={`${list.send_stat_item_col} ${list.indirect}`}>
                                            {msg?.indirect}
                                        </div>
                                        <div className={`${list.send_stat_item_col} ${list.not_answer}`}>
                                            {msg?.not_answer}
                                        </div>
                                        <div className={`${list.send_stat_item_col} ${list.effective}`}>
                                            {(((+msg?.clear + +msg?.indirect) / +msg?.all_count) * 100).toFixed(2)}%
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*</div>*/}
        </div>
    );
}
