import React, { useState } from 'react';
import Styles from '../../../../chat/ChatRoom/Form/styles.module.scss';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import * as CHAT from '../../../../../constants/chat';
import AttachModalNew from '../../../../common/AttachModalNew';
import attachStyles from '../../../../../styles/react-modal/attach.style';
import * as filesAction from '../../../../../store/actions/files';
import * as ATTACH from '../../../../../constants/attach';
import * as SENDER from '../../../../../constants/sender';
import * as LENGTH from '../../../../../constants/length';
import * as errorsAction from '../../../../../store/actions/alerts';
import * as ERROR from '../../../../../constants/error';

export default ({
    activeTypeFile,
    setActiveTypeFile,
    isActive,
    onSendSender,
    messageAttachment,
    addMessageAttachment,
    activeSenderType,
}) => {
    const dispatch = useDispatch();
    const filesState = useSelector((state) => state.files);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const senderState = useSelector((state) => state.sender);

    const openModal = () => {
        setModalIsOpen(true);
        dispatch(filesAction.getAllImages(senderState?.activeProfile ?? ''));
        dispatch(filesAction.getAllVideos(senderState?.activeProfile ?? ''));
        dispatch(filesAction.getAllAudios(senderState?.activeProfile ?? ''));
    };

    const closeModal = () => setModalIsOpen(false);

    const switchAttachType = (type) => {
        switch (type) {
            case 'SENT_IMAGE':
                return 'images';
            case 'SENT_VIDEO':
                return 'videos';
            case 'SENT_AUDIO':
                return 'audios';
            default:
                return '';
        }
    };

    const onSubmitMessageContent = async (e, messageType) => {
        const checkedList = e.currentTarget.parentNode.parentNode.querySelectorAll('input:checked');

        if (checkedList?.length > 0) {
            closeModal();

            if (checkedList?.length + messageAttachment?.length > LENGTH.MAX_ATTACHMENTS_TO_LETTER) {
                return dispatch(errorsAction.setMessage('The limit is 5 uploaded files.', ERROR.FILES_LIMIT));
            }

            for (const checkedItem of checkedList) {
                if (activeSenderType === SENDER.SENDER_TYPE_LETTER) {
                    const foundFile = filesState[switchAttachType(messageType)]?.find(
                        (e) => e?.link === checkedItem?.dataset?.link,
                    );
                    addMessageAttachment(foundFile, messageType);
                } else {
                    onSendSender(activeSenderType, checkedItem?.dataset?.link);
                }
            }
        }
    };

    return (
        <>
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.sender} ${Styles.image} ${isActive ? '' : Styles.dis_active}`}
                onClick={() => {
                    if (isActive) {
                        setActiveTypeFile(ATTACH.TAB_IMAGE);
                        openModal();
                    }
                }}
            />
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.sender} ${Styles.video} ${isActive ? '' : Styles.dis_active}`}
                onClick={() => {
                    if (isActive) {
                        setActiveTypeFile(ATTACH.TAB_VIDEO);
                        openModal();
                    }
                }}
            />
            <div
                className={`${Styles.clmn_3_chat_bottom_option_btn} ${Styles.sender} ${Styles.audio} ${isActive ? '' : Styles.dis_active}`}
                onClick={() => {
                    if (isActive) {
                        setActiveTypeFile(ATTACH.TAB_AUDIO);
                        openModal();
                    }
                }}
            />
            <Modal style={attachStyles} isOpen={modalIsOpen} onRequestClose={closeModal} ariaHideApp={false}>
                <AttachModalNew
                    closeModal={closeModal}
                    sendImg={(e) => onSubmitMessageContent(e, CHAT.MESSAGE_CONTENT_TYPE_IMAGE)}
                    sendVideo={(e) => onSubmitMessageContent(e, CHAT.MESSAGE_CONTENT_TYPE_VIDEO)}
                    sendAudio={(e) => onSubmitMessageContent(e, CHAT.MESSAGE_CONTENT_TYPE_AUDIO)}
                    userId={senderState?.activeProfile ?? ''}
                    activeTypeFile={activeTypeFile}
                    setActiveTypeFile={setActiveTypeFile}
                />
            </Modal>
        </>
    );
};
