import React from 'react';
import styles from '../styles.module.scss';

export default ({ countUnread, onSetLettersMenu, activeFolderName }) => {
    return (
        <div className={styles.letters_folders} data-testid="letter-menu">
            <div
                className={`${styles.letters_folder_item} ${styles.mailbox} ${styles[activeFolderName === 'inbox' ? 'active' : '']} `}
                onClick={() => onSetLettersMenu('inbox')}
            >
                <div className={styles.letters_folder_img} />
                <div className={styles.letters_folder_name}>Mailbox</div>
                {+countUnread > 0 ? <div className={styles.letters_folder_count}>{countUnread}</div> : <div />}
            </div>
            <div
                className={`${styles.letters_folder_item} ${styles.drafts} ${styles[activeFolderName === 'drafts' ? 'active' : '']}`}
                onClick={() => onSetLettersMenu('drafts')}
            >
                <div className={styles.letters_folder_img} />
                <div className={styles.letters_folder_name}>Drafts</div>
            </div>
            {/* <div className={`c2l_letter_folder_item ${activeFolderName === 'outbox' ? 'active' : ''}`}
				 onClick={() => onSetLettersMenu("outbox")}>
				<div className="c2l_letter_folder_item_left">
					<svg>
						<use href="#c2l_outbox"/>
					</svg>
					<span>Outbox</span>
				</div>
			</div> */}
            <div
                className={`${styles.letters_folder_item} ${styles.trash} ${styles[activeFolderName === 'trash' ? 'active' : '']}`}
                onClick={() => onSetLettersMenu('trash')}
            >
                <div className={styles.letters_folder_img} />
                <div className={styles.letters_folder_name}>Trash</div>
            </div>
        </div>
    );
};
