export default {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(55, 78, 108, 0.32)',
		zIndex: '999',
	},
	content: {
		width: 'max-content',
		height: 'max-content',
		top: '50%',
		left: '50%',
		padding: 0,
		transform: 'translate(-50%, -50%)',
		border: 'none !important',
		overflow: 'auto'
	}
};
