export const CHECK_ACTIVE_SENDER = 1000 * 60 * 10;

// CHAT
export const CHAT_LIST_MESSAGES = 3500;
export const CHAT_HISTORY = 3000;
export const CHAT_NOTIFY = 3500;
export const CHAT_COUNTER = 10000;
export const CHAT_UPD_NEW_MESS = 15000;
export const MINI_CHAT_HISTORY = 5000;

// LETTER
export const LETTER_UNREAD_COUNT = 7000;
export const LETTER_LIST_MAILS = 3500;
export const LETTER_CHAT_HISTORY = 10000;
export const LETTER_NOTIFY = 3500;
export const LETTER_UPDATE_DRAFT = 1000;

export const CHECK_NOTICE_EXP_DATE = 10000;
export const FREE_NOTIFY_EXP_DATE_MIN = 5;
export const PAID_NOTIFY_EXP_DATE_MIN = 5;
export const TRANSLATE_TIMER = 1000;
