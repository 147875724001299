// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import md5 from "js-md5";
// import moment from "moment";
// import ChatsApi from "../../services/api/ChatsApi";
// import { changeCheckBoxTranslate } from "../../services/dom";
// import { checkBabWords, checkUrl, hideStringStar } from "../../services/methods";
// import { insertedMessageBeforeSync } from "../../services/init";
// import * as errorsAction from "../../store/actions/alerts";
// import * as notifyAction from "../../store/actions/notify";
// import * as currentChatAction from "../../store/actions/currentChat";
// import * as chatsAction from "../../store/actions/chats";
// import * as ERROR from "../../constants/error";
// import * as LENGTH from "../../constants/length";
// import * as REGEXP from "../../constants/regExp";
// import useGetWomanProfile from "../profiles/useGetWomanProfile";

export default function useHandleChatForm() {
    // const dispatch = useDispatch();
    //
    // const syncProvider = useSelector(state => state.sync.provider);
    // const currentChatState = useSelector(state => state.currentChat);
    // const chatsState = useSelector(state => state.chats);
    // const profilesState = useSelector(state => state.profiles);
    // const onlineManState = useSelector(state => state.onlineMan);
    //
    // const { womanProfileInfo } = useGetWomanProfile(!isOnlineManPage ? currentChatState.info : { female_external_id: profilesState.active.external_id });
    //
    // const [checkTranslate, setCheckTranslate] = useState(false);
    // const [translatedText, setTranslatedText] = useState('');
    // const [inputMessage, setInputMessage] = useState('');
    // const [submitMessage, setSubmitMessage] = useState('');
    // const [translateTimerId, setTranslateTimerId] = useState(0);
    // const [messageError,] = useState('');
    //
    // const onTranslateChange = (e) => {
    // 	changeCheckBoxTranslate(e);
    // 	setCheckTranslate(!checkTranslate);
    // 	setTranslatedText('');
    // };
    //
    // const handleTranslating = (e) => {
    // 	const { value } = e.currentTarget;
    // 	if (!checkTranslate) {
    // 		if (REGEXP.INPUT_OPERATOR.test(value)) {
    // 			if (value.length <= LENGTH.MESSAGE_MAX_LENGTH) {
    // 				setInputMessage(value);
    // 			}
    // 		} else if (value === '') {
    // 			if (value.length <= LENGTH.MESSAGE_MAX_LENGTH) {
    // 				setInputMessage(value);
    // 				setSubmitMessage(value);
    // 			}
    // 		}
    // 	} else {
    // 		if (value.length <= LENGTH.MESSAGE_MAX_LENGTH) {
    // 			setInputMessage(value);
    // 		}
    // 	}
    // };
    //
    // const handleTranslated = (e) => {
    // 	if (REGEXP.INPUT_OPERATOR.test(e.currentTarget.value)) {
    // 		setTranslatedText(e.currentTarget.value);
    // 	}
    // };
    //
    // const addEmoji = (emoji) => {
    // 	setInputMessage(inputMessage + emoji.native);
    // };
    //
    // const onSubmitMessage = async () => {
    // 	let _inputData = '';
    // 	const hashId = md5(moment().format());
    //
    // 	if (checkTranslate) {
    // 		_inputData = translatedText.replace(/(\r\n|\n|\r)/gm, "");
    // 	} else {
    // 		_inputData = submitMessage.replace(/(\r\n|\n|\r)/gm, "");
    // 	}
    //
    // 	_inputData = hideStringStar(_inputData, REGEXP.NUMBER_IN_STRING);
    //
    // 	if (checkUrl(_inputData)) {
    // 		return dispatch(errorsAction.setMessage('Exist url', 'Invalid message'));
    // 	}
    //
    // 	if (_inputData.trim().length === 0) {
    // 		setSubmitMessage('');
    // 		setInputMessage('');
    // 		setTranslatedText('');
    // 		return;
    // 	}
    //
    //
    // 	// if (_inputData.trim().length > 0) {
    //     // 	if (!isOnlineManPage) dispatch(currentChatAction.deleteDataOpenFromNotification());
    // 	// 	setSubmitMessage('');
    // 	// 	setInputMessage('');
    // 	// 	setTranslatedText('');
    // 	//
    // 	// 	dispatch(currentChatAction.addMessageToHistory(insertedMessageBeforeSync(hashId, _inputData, +womanProfileInfo?.external_id)));
    // 	//
    // 	// 	new ChatsApi()
    // 	// 		.postMessage(+womanProfileInfo?.external_id, !isOnlineManPage ? currentChatState?.info?.id : onlineManState?.currentChat?.external_id, _inputData, 'SENT_TEXT')
    // 	// 		.then(res => {
    // 	// 			if (!res?.message_id?.msg?.message_object || !res?.message_id?.channel) {
    // 	// 				dispatch(currentChatAction.deleteMessageInHistory(hashId));
    // 	// 				if(res?.error) dispatch(errorsAction.setMessage(res?.error));
    // 	// 				else dispatch(errorsAction.setMessage(ERROR.INTERNAL_ERROR_OCCURRED));
    // 	// 				return;
    // 	// 			}
    // 	// 			if (res?.status) {
    // 	// 				dispatch(currentChatAction.updateMessageInHistory(hashId, res?.message_id?.msg?.message_object));
    // 	// 				syncProvider.publish(res?.message_id?.channel, res?.message_id?.msg);
    // 	// 				dispatch(chatsAction.updateActiveChat({
    // 	// 					...chatsState?.activeChat,
    // 	// 					...{
    // 	// 						...res?.message_id?.msg?.chat_list_object,
    // 	// 						...{sender_external_id: res?.message_id?.msg?.message_object?.sender_external_id}
    // 	// 					}
    // 	// 				}));
    // 	// 				if (!isOnlineManPage) dispatch(notifyAction.removeNotifyByWomanIDAndManID(+womanProfileInfo?.external_id, +currentChatState?.info?.id));
    // 	// 			} else {
    // 	// 				dispatch(currentChatAction.deleteMessageInHistory(hashId));
    // 	// 				if(res?.error) dispatch(errorsAction.setMessage(res?.error));
    // 	// 				else dispatch(errorsAction.setMessage(ERROR.INTERNAL_ERROR_OCCURRED));
    // 	// 			}
    // 	// 		});
    // 	// }
    // };
    //
    // const onSubmitMessageEnter = (e) => {
    // 	if (e.keyCode === 13) {
    // 		e.preventDefault();
    // 		onSubmitMessage();
    // 	}
    // };
    //
    // useEffect(() => {
    // 	if (checkTranslate) {
    // 		if (inputMessage.length > 0) {
    // 			clearTimeout(translateTimerId);
    //
    // 			let timerId = setTimeout(() => {
    // 				(new ChatsApi()).postTranslate(inputMessage).then(res => {
    // 					setTranslatedText(res.text);
    // 					setSubmitMessage(res.text);
    // 				});
    // 			}, 2000);
    //
    // 			setTranslateTimerId(timerId);
    // 		}
    // 	} else {
    // 		setSubmitMessage(inputMessage);
    // 	}
    // }, [inputMessage]);

    return {
        // messageError,
        // checkTranslate,
        // inputMessage,
        // translatedText,
        // onTranslateChange,
        // handleTranslating,
        // handleTranslated,
        // onSubmitMessageEnter,
        // addEmoji,
        // onSubmitMessage
    };
}
