export const IMG_LIST = 'img_list';
export const VIDEO_LIST = 'video_list';
export const AUDIO_LIST = 'audio_list';

export const UPD_SORT_IMAGE = 'image';
export const UPD_SORT_VIDEO = 'video';
export const UPD_SORT_AUDIO = 'audio';

export const GO_TO_LOADER = 'loaded_status';

export const TAB_IMAGE = 'Photo';
export const TAB_VIDEO = 'Video';
export const TAB_AUDIO = 'Audio';

export const TYPE_IMAGE = 'image';
export const TYPE_VIDEO = 'video';
export const TYPE_AUDIO = 'audio';
