import * as VIRTUAL_GIFTS from '../constants/virtualGifts';
import VirtualGiftsApi from '../../services/api/VirtualGifts';

export function getVirtualGiftsData(recipientId) {
    return function (dispatch) {
        new VirtualGiftsApi().getVirtualGiftsData(recipientId).then((res) => {
            if (res?.status && (!!res?.data?.chat?.length || !!res?.data?.stream?.length)) {
                return dispatch({
                    type: VIRTUAL_GIFTS.GET_VIRTUAL_GIFTS_DATA,
                    chat: res?.data?.chat,
                    stream: res?.data?.stream,
                });
            }
        });
    };
}

export function clearVirtualGiftsData() {
    return function (dispatch) {
        return dispatch({ type: VIRTUAL_GIFTS.ClEAR_VIRTUAL_GIFTS_DATA });
    };
}
