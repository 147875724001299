import Base from './Base';

export default class PostsApi extends Base {
    getAllPosts({ womanExternalId, status, page = 1, count = 99999 }) {
        return super
            .post('post/get/all', {
                count,
                page,
                filters: {
                    ...(status ? { status } : {}),
                    womanExternalId,
                },
            })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    getCurrentPost(postId) {
        return super
            .post('post/get', { id: +postId })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    createPost({ status, womanId, text, files }) {
        return super
            .post('post/create', { status, womanId, text, files })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    editPost({ status, id, text = '', files = [] }) {
        return super
            .post('post/update', {
                status,
                id,
                ...(text?.length ? { text } : {}),
                ...(files?.length ? { files } : {}),
            })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    // only for draft
    postDeleteDraft(postId) {
        return super
            .post('post/delete', { id: +postId })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    getAllComments({ postId, page = 1, count = 99999 }) {
        return super
            .post('post/comments/all', { postId, page, count })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    createComment({ postId, parentId, text }) {
        // parentId is required to reply to a comment
        return super
            .post('post/comment/create', {
                postId,
                ...(parentId ? { parentId } : {}),
                text,
            })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    deleteComment(commentId) {
        return super
            .post('post/comment/delete', { id: commentId })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    getAllReplies({ commentId, page = 1, count = 99999 }) {
        return super
            .post('post/comments/replies/all', { commentId, page, count })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }

    getHistoryPost(postId) {
        return super
            .post('post/history/get', { postId })
            .then((res) => res?.data)
            .catch(() => ({ status: false }));
    }
}
