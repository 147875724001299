import React from 'react';
import styles from './styles.module.scss';
import global from '../../global.module.scss';
// import SortSelect from './SortSelect';
import SenderDraftItem from './SenderDraftItem';

export default ({ savedSenderList, onSendSender, onEditSender, onDeleteSender, checkActiveSender }) => {
    return (
        <div className={styles.sender_drafts}>
            <div className={global.title_wrap_2}>
                <div className={global.title_2}>Saved message</div>
                {/*<SortSelect/>*/}
            </div>
            {!!savedSenderList?.length && (
                <div className={styles.drafts_table}>
                    {/*<div className={styles.drafts_table_head}>*/}
                    {/*<div className={styles.drafts_table_head_col_1}>Message body</div>*/}
                    {/*<div className={styles.drafts_table_head_col_2}>Recievers</div>*/}
                    {/*<div className={styles.drafts_table_head_col_3}>Settings</div>*/}
                    {/*</div>*/}
                    <div className={styles.drafts_table_list}>
                        {savedSenderList?.map((item, key) => (
                            <SenderDraftItem
                                key={key}
                                item={item}
                                onSendSender={onSendSender}
                                onEditSender={onEditSender}
                                onDeleteSender={onDeleteSender}
                                checkActiveSender={checkActiveSender}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
