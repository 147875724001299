import React, { useEffect, useMemo, useState } from 'react';

import SvgLogo from '../../components/common/SvgLogo';
import MobileHeader from '../../components/common/MobileHeader';
import MobileBottomNav from '../../components/common/MobileBottomNav';
import SideMenu from '../../components/side-menu';
import Notification from '../../components/notification';
import AlertModal from '../../components/common/modals/AlertModal';
import LoaderModal from '../../components/common/modals/Loader';
import AudioContentModal from '../../components/common/modals/AudioContentModal';
import VideoContentModal from '../../components/common/modals/VideoContentModal';
import PhotoContentModal from '../../components/common/modals/PhotoContentModal';
import SuperOperatorsSelect from '../../components/common/SuperOperatorsSelect';

import Styles from './styles.module.scss';

import '../../styles/column.scss';
import '../../styles/main.scss';
import '../../styles/letters.scss';
import '../../styles/media.scss';

import useInitFirstLoadProject from '../../hooks/useInitFirstLoadProject';
import useInitSync from '../../hooks/sync/useInitSync';
import useCheckActiveSender from '../../hooks/sender/useCheckActiveSender';
import { useHistory, useLocation } from 'react-router-dom';
import useHandleOperatorClick from '../../hooks/click/useHandleOperatorClick';
import PropTypes from 'prop-types';

UserLayout.propTypes = {
    children: PropTypes.object,
};
export default function UserLayout({ children }) {
    const history = useHistory();
    const location = useLocation();
    const handleOperatorClick = useHandleOperatorClick();
    useInitFirstLoadProject();
    useCheckActiveSender();
    useInitSync();

    const [currentOperator, setCurrentOperator] = useState(JSON.parse(localStorage.getItem('current_operator')));

    useEffect(() => {
        handleOperatorClick.sendClick();

        // REMOVE "personal=true" FROM LOCATION SEARCH
        const isPersonal =
            location?.search?.includes('personal=') &&
            !location?.pathname?.split('chat/')[1]?.length &&
            !location?.pathname?.split('letter/')[1]?.length;
        if (isPersonal) history.push(`${location?.pathname}`);
    }, [location]);

    useMemo(
        () => () => {
            if (localStorage.getItem('admin') !== 'true') return;

            const ifChangeOperator = currentOperator === JSON.parse(localStorage.getItem('current_operator'));
            if (ifChangeOperator) return;

            setCurrentOperator(JSON.parse(localStorage.getItem('current_operator')));
        },
        [currentOperator],
    );

    useEffect(() => {
        function listener() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', listener);

        window.onorientationchange = function () {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        return () => {
            window.removeEventListener('resize', listener);
        };
    }, []);

    return (
        <main className="content">
            <div className="container">
                <div className="overlay" />
                <div className={Styles.page_wrap}>
                    <MobileHeader />
                    {localStorage.getItem('admin') === 'true' && (
                        <SuperOperatorsSelect currentOperator={currentOperator} />
                    )}
                    <div className={Styles.wchat_wrap}>
                        <SideMenu />
                        {children}
                        <Notification />
                    </div>
                    <MobileBottomNav />
                </div>
                <SvgLogo />
            </div>
            <AlertModal />
            <LoaderModal />
            <VideoContentModal />
            <PhotoContentModal />
            <AudioContentModal />
        </main>
    );
}
