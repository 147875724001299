import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../styles.module.scss';
import { initCancelTokenSource } from '../../../../../services/methods';
import ChatsApi from '../../../../../services/api/ChatsApi';
import { formatDate } from '../../../../../services/time';
import * as IMAGES from '../../../../common/Images';
import * as blockListAction from '../../../../../store/actions/blockList';
import ConfirmModal from '../../../../common/modals/ConfirmModal';

export default function BlockItemList({ el }) {
    const dispatch = useDispatch();
    const blockListState = useSelector((state) => state.blockList);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const [canceledToken, setCanceledToken] = useState(null);

    const setBlockList = () => {
        const source = initCancelTokenSource(canceledToken, setCanceledToken);
        dispatch(blockListAction.unBlockUser(el?.chat_uid));
        new ChatsApi().postRemoveBlackList(el?.man_external_id, el?.chat_uid, source.token);
    };
    return (
        <div className={styles.item_info_row}>
            <div className={styles.item_info_mans_name}>{`${el?.man_name}, ${el?.man_age || ''}`}</div>
            <div className={styles.item_info_mans_id}>{el?.man_external_id}</div>
            <div className={styles.item_info_mans_date}>{formatDate(el?.last_message ?? '')}</div>
            {!blockListState?.theyTab && (
                <div className={styles.item_info_mans_close} onClick={() => setIsConfirmModalOpen(true)}>
                    <img src={IMAGES.letter_option_restore} alt="" />
                </div>
            )}
            {isConfirmModalOpen && (
                <ConfirmModal
                    isModalOpen={isConfirmModalOpen}
                    onConfirm={setBlockList}
                    onClose={() => setIsConfirmModalOpen(false)}
                    title={`Are you sure you want to unblock this user?`}
                />
            )}
        </div>
    );
}
