import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import '../../../styles/allMedia/allMedia.scss';
import styles from './styles.module.scss';

import ListImages from './ListImages';
import ListVideos from './ListVideos';
import ListAudios from './ListAudios';
import Loader from './Loader';

import useInitMediaContent from '../../../hooks/media/useInitMediaContent';
import * as IMAGES from '../Images';
import * as MEDIA from '../../../constants/media';
import * as CHAT from '../../../constants/chat';
import ListLetterImages from './ListLetterImages';

export default ({ closeModal }) => {
    const currentChatState = useSelector((state) => state.currentChat);

    const [activeTypeSender, setActiveTypeSender] = useState(MEDIA.TYPE_MAN);
    const [activeTypeMedia, setActiveTypeMedia] = useState(MEDIA.TAB_ALL_MEDIA);
    const [activeSenderAllMedia, setActiveSenderAllMedia] = useState([]);
    const [mapMediaList, setMapMediaList] = useState([]);

    const switchListItems = (type, item) => {
        switch (type) {
            case CHAT.MESSAGE_CONTENT_TYPE_IMAGE:
                return <ListImages message_content={item?.message_content} message_popup={item?.message_popup} />;
            case CHAT.MESSAGE_CONTENT_TYPE_IMAGE_MAIL:
                return <ListLetterImages {...item} />;
            case CHAT.MESSAGE_CONTENT_TYPE_VIDEO:
            case CHAT.MESSAGE_CONTENT_TYPE_VIDEO_MAIL:
                return <ListVideos {...item} />;
            case CHAT.MESSAGE_CONTENT_TYPE_AUDIO:
            case CHAT.MESSAGE_CONTENT_TYPE_AUDIO_MAIL:
                return <ListAudios {...item} />;
            default:
                return '';
        }
    };

    const mapMediaListItem = (item, key, array) => {
        const firstDate = array.find((find) => find?.date_created_str === item?.date_created_str);

        if (activeTypeMedia === MEDIA.TAB_ALL_MEDIA) {
            return (
                <Fragment key={key + item?.message_content}>
                    {+firstDate?.id === +item?.id && (
                        <div className={styles.media_list_month}>{item?.date_created_str}</div>
                    )}
                    {switchListItems(item?.message_type, item)}
                </Fragment>
            );
        } else if ([CHAT.MESSAGE_CONTENT_TYPE_IMAGE]?.includes(item?.message_type)) {
            return (
                <Fragment key={key + item?.message_content}>
                    {+firstDate?.id === +item?.id && (
                        <div className={styles.media_list_month}>{item?.date_created_str}</div>
                    )}
                    <ListImages message_content={item?.message_content} message_popup={item?.message_popup} />
                </Fragment>
            );
        } else if ([CHAT.MESSAGE_CONTENT_TYPE_IMAGE_MAIL]?.includes(item?.message_type)) {
            return (
                <Fragment key={key + item?.message_content}>
                    {+firstDate?.id === +item?.id && (
                        <div className={styles.media_list_month}>{item?.date_created_str}</div>
                    )}
                    <ListLetterImages {...item} />
                </Fragment>
            );
        } else if (
            [CHAT.MESSAGE_CONTENT_TYPE_VIDEO, CHAT.MESSAGE_CONTENT_TYPE_VIDEO_MAIL]?.includes(item?.message_type)
        ) {
            return (
                <Fragment key={key + item?.message_content}>
                    {+firstDate?.id === +item?.id && (
                        <div className={styles.media_list_month}>{item?.date_created_str}</div>
                    )}
                    <ListVideos {...item} />
                </Fragment>
            );
        } else if (
            [CHAT.MESSAGE_CONTENT_TYPE_AUDIO, CHAT.MESSAGE_CONTENT_TYPE_AUDIO_MAIL]?.includes(item?.message_type)
        ) {
            return (
                <Fragment key={key + item?.message_content}>
                    {+firstDate?.id === +item?.id && (
                        <div className={styles.media_list_month}>{item?.date_created_str}</div>
                    )}
                    <ListAudios {...item} />
                </Fragment>
            );
        } else {
            return <div key={key} />;
        }
    };

    const { handleScroll } = useInitMediaContent({
        activeTypeMedia,
        activeTypeSender,
        activeSenderAllMedia,
        setActiveSenderAllMedia,
        mapMediaListItem,
        setMapMediaList,
        mapMediaList,
    });

    return (
        <div className="upload_popup_wrap">
            <div className="media_new_popup_left">
                <ul className="media_new_popup_ul">
                    <li
                        className={`${activeTypeSender === MEDIA.TYPE_MAN ? 'active' : ''}`}
                        onClick={() => setActiveTypeSender(MEDIA.TYPE_MAN)}
                    >
                        <div className="media_new_popup_li_wrap">
                            <div className="media_new_popup_li_icon_man" />
                            <span>{MEDIA.TYPE_MAN}</span>
                        </div>
                    </li>
                    <li
                        className={`${activeTypeSender === MEDIA.TYPE_WOMAN ? 'active' : ''}`}
                        onClick={() => setActiveTypeSender(MEDIA.TYPE_WOMAN)}
                    >
                        <div className="media_new_popup_li_wrap">
                            <div className="media_new_popup_li_icon_woman" />
                            <span>{MEDIA.TYPE_WOMAN}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="media_new_popup_right">
                <div className="media_new_popup_tab_wrap">
                    <div className="media_new_popup_tab_content">
                        <div className="media_new_popup_tab_content_top">
                            <ul className="media_new_popup_tab_content_top_ul">
                                <li
                                    className={`${activeTypeMedia === MEDIA.TAB_ALL_MEDIA ? 'active' : ''}`}
                                    onClick={() => setActiveTypeMedia(MEDIA.TAB_ALL_MEDIA)}
                                >
                                    <div className="media_new_popup_tab_content_top_inner_li">
                                        <span>{MEDIA.TAB_ALL_MEDIA}</span>
                                    </div>
                                </li>
                                <li
                                    className={`${activeTypeMedia === MEDIA.TAB_IMAGE ? 'active' : ''}`}
                                    onClick={() => setActiveTypeMedia(MEDIA.TAB_IMAGE)}
                                >
                                    <div className="media_new_popup_tab_content_top_inner_li">
                                        <span>{MEDIA.TAB_IMAGE}</span>
                                    </div>
                                </li>
                                <li
                                    className={`${activeTypeMedia === MEDIA.TAB_VIDEO ? 'active' : ''}`}
                                    onClick={() => setActiveTypeMedia(MEDIA.TAB_VIDEO)}
                                >
                                    <div className="media_new_popup_tab_content_top_inner_li">
                                        <span>{MEDIA.TAB_VIDEO}</span>
                                    </div>
                                </li>
                                <li
                                    className={`${activeTypeMedia === MEDIA.TAB_AUDIO ? 'active' : ''}`}
                                    onClick={() => setActiveTypeMedia(MEDIA.TAB_AUDIO)}
                                >
                                    <div className="media_new_popup_tab_content_top_inner_li">
                                        <span>{MEDIA.TAB_AUDIO}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="media_new_popup_tab_content_midddle" onScroll={handleScroll}>
                            {currentChatState?.isAllMediaLoaded ? mapMediaList : <Loader />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="media_new_popup_close" onClick={closeModal}>
                <img src={IMAGES.attach_close_ico} alt="" />
            </div>
        </div>
    );
};
