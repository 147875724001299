import { useDispatch, useSelector } from 'react-redux';
import * as notifyAction from '../../store/actions/notify';
import useTransition from '../transition/useTransition';
import { useState } from 'react';
import { sortListByDate } from '../../services/methods';
import { closeNotifyList } from '../../services/dom';
import * as CHAT from '../../constants/chat';

export default function useHandlePaidNotification() {
    const dispatch = useDispatch();
    const transition = useTransition();

    const notifyState = useSelector((state) => state.notify);
    const profilesState = useSelector((state) => state.profiles);

    const [filterUp, setFilterUp] = useState(false);
    const [filterDown, setFilterDown] = useState(true);

    const clearAll = () => dispatch(notifyAction.removeAllPaidNotify(profilesState?.operatorId));

    const openChat = (notifyInfo) => {
        closeNotifyList();
        dispatch(notifyAction.closeMobNotify());
        dispatch(
            notifyAction.removePaidNotifyByChatUID(
                notifyInfo?.chat_uid,
                [notifyInfo?.notification_type],
                profilesState?.operatorId,
            ),
        );

        if (notifyInfo?.message_type === CHAT.MESSAGE_CONTENT_TYPE_LETTER) {
            transition.transitionPush(`/letter/${notifyInfo?.chat_uid}`);
        } else if (+notifyInfo?.connect === 0) {
            transition.transitionPush(`/chance/${notifyInfo?.chat_uid}`);
        } else {
            transition.transitionPush(`/chat/${notifyInfo?.chat_uid}`);
        }
    };

    const onSetFilterDown = () => {
        if (!filterDown) {
            setFilterDown(!filterDown);
            setFilterUp(false);
            sortListByDate(notifyState?.listPaid, true);
        }
    };

    const onSetFilterUp = () => {
        if (!filterUp) {
            setFilterUp(!filterUp);
            setFilterDown(false);
            sortListByDate(notifyState?.listPaid, false);
        }
    };

    const initSortByFilter = () => {
        if (filterDown) return sortListByDate(notifyState?.listPaid, true);
        else return sortListByDate(notifyState?.listPaid, false);
    };

    return {
        filterUp,
        filterDown,
        onSetFilterUp,
        onSetFilterDown,
        openChat,
        clearAll,
        paidNotificationList: initSortByFilter() ?? [],
    };
}
