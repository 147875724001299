import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import * as modalsAction from '../../../store/actions/modals';
import LetterAudioPlayer from '../../common/LetterAudioPlayer';
// import { cutLengthString } from '../../../services/methods';
import { stopAudio } from '../../../services/methods/chat';
// import * as LENGTH from '../../../constants/length';
// import * as IMAGES from '../Images';

export default ({ data }) => {
    const dispatch = useDispatch();

    if (data?.message_type === 'SENT_IMAGE') {
        const openContentModal = () => {
            dispatch(
                modalsAction.openPhotoContentModal({
                    link: data?.link,
                    attachment_payed: '1',
                }),
            );
        };

        return (
            <div
                className={`${styles.letters_list_item_media_item} ${styles.photo}`}
                onClick={openContentModal}
                data-testid={data?.title}
            >
                <img src={data?.link} alt="" />
            </div>
        );
    } else if (data?.message_type === 'SENT_VIDEO') {
        const openContentModal = () => {
            dispatch(
                modalsAction.openVideoContentModal({
                    link: data?.link,
                    attachment_payed: '1',
                }),
            );
        };

        return (
            <div
                className={`${styles.letters_list_item_media_item} ${styles.video}`}
                onClick={openContentModal}
                data-testid={data?.title}
            >
                {data?.thumb_link?.length ? (
                    <img src={data?.thumb_link} alt="" />
                ) : (
                    <video
                        width="96"
                        height="56"
                        id={'video-' + data?.id}
                        controls={false}
                        preload="metadata"
                        playsInline
                    >
                        <source src={`${data?.link}#t=0.6`} type="video/mp4" />
                    </video>
                )}
            </div>
        );
    } else if (data?.message_type === 'SENT_AUDIO') {
        // {`${styles.letters_list_item_media_item} ${styles.audio}`}
        return (
            <div className="letters_attach_files_item file_audio" data-testid={data?.title}>
                <div className="letters_attach_files_item_play">
                    {/*<div className="letters_attach_files_item_play_title">*/}
                    {/*    <img src={IMAGES.letter_audio_notes} alt="" />*/}
                    {/*    <span>{cutLengthString(data?.title, LENGTH.ATTACH_LETTER_AUDIO_TITLE)}</span>*/}
                    {/*</div>*/}
                    <LetterAudioPlayer src={data?.link} viewedAudio={() => stopAudio(data?.link)} />
                </div>
            </div>
        );
    } else {
        return <div />;
    }
};
