import React from 'react';

import * as IMAGE from '../../common/Images';

export default function PhotoGallery({ photoPanel, girlImages, openGalleryModal, setGirlImages }) {
    const setSelectedImage = (currentIndex) => {
        setGirlImages([girlImages[currentIndex], ...girlImages.filter((item, index) => index !== currentIndex)]);
    };

    const prevImage = () => {
        setGirlImages([
            girlImages[girlImages.length - 1],
            ...girlImages.filter((item, index) => index !== girlImages.length - 1),
        ]);
    };
    const nextImage = () => {
        setGirlImages([...girlImages.filter((item, index) => index !== 0), girlImages[0]]);
    };

    return (
        <div className="c3_woman_profile_gallery_photo_wrap" data-testid="woman-profile-photo-gallery" ref={photoPanel}>
            <div className="c3_woman_profile_page_title">photos ({girlImages?.length ?? '0'})</div>
            {girlImages && girlImages.length > 0 && (
                <div className="c3_woman_profile_gallery_photo">
                    <div className="c3_woman_profile_gallery_photo_top_big_list_wrap">
                        <div className="c3_woman_profile_gallery_photo_top_big_list">
                            {girlImages.map((item, key) => (
                                <div
                                    key={key}
                                    className={`c3_woman_profile_gallery_photo_top_big_item`}
                                    onClick={() => openGalleryModal(item, key)}
                                >
                                    <img src={item} alt="" />
                                </div>
                            ))}
                        </div>
                        <div className="c3_woman_profile_gallery_photo_btn btn_prev" onClick={prevImage}>
                            <img src={IMAGE.c3wp_btn_prev} alt="" />
                        </div>
                        <div className="c3_woman_profile_gallery_photo_btn btn_next" onClick={nextImage}>
                            <img src={IMAGE.c3wp_btn_next} alt="" />
                        </div>
                    </div>
                    <div className="c3_woman_profile_gallery_photo_top_small_list_wrap">
                        <div className="c3_woman_profile_gallery_photo_top_small_list">
                            {girlImages.map((item, index) => (
                                <div
                                    key={index}
                                    className={`c3_woman_profile_gallery_photo_top_small_list_item`}
                                    onClick={() => setSelectedImage(index)}
                                >
                                    <img src={item} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
