import React, { useImperativeHandle, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import * as ATTACH from '../../../../../constants/attach';
import ItemImage from '../../ListImages/ItemImage';
import ItemVideo from '../../ListVideos/ItemVideo';
import ItemAudio from '../../ListAudios/ItemAudio';

const Card = React.forwardRef(({ userId, type, data, isDragging, connectDragSource, connectDropTarget }, ref) => {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;
    useImperativeHandle(ref, () => ({
        getNode: () => elementRef.current,
    }));

    if (type === ATTACH.IMG_LIST) {
        return <ItemImage userId={userId} elementRef={elementRef} data={data} opacity={opacity} />;
    } else if (type === ATTACH.VIDEO_LIST) {
        return <ItemVideo userId={userId} elementRef={elementRef} data={data} opacity={opacity} />;
    } else if (type === ATTACH.AUDIO_LIST) {
        return <ItemAudio userId={userId} elementRef={elementRef} data={data} opacity={opacity} />;
    }
});
export default DropTarget(
    'card',
    {
        hover(props, monitor, component) {
            if (!component) {
                return null;
            }

            const node = component.getNode();
            if (!node) {
                return null;
            }
            const dragIndex = monitor.getItem().index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = node.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            props.moveCard(dragIndex, hoverIndex);
            monitor.getItem().index = hoverIndex;
        },
    },
    (connect) => ({
        connectDropTarget: connect.dropTarget(),
    }),
)(
    DragSource(
        'card',
        {
            beginDrag: (props) => ({
                id: props.id,
                index: props.index,
            }),
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        }),
    )(Card),
);
