export const daysUnitBirthday = (month, day) => {
    let today = new Date(),
        y = today.getFullYear(),
        next = new Date(y, month - 1, day);
    today.setHours(0, 0, 0, 0);
    if (today > next) next.setFullYear(y + 1);
    return Math.round((next - today) / 8.64e7);
};

export function displayDaysUntilBirthdayOnProfilePage(month, day) {
    if (+month > 0 && +day > 0) {
        let daysUntilBirthday = daysUnitBirthday(+month, +day);
        if (daysUntilBirthday > 14) {
            return null;
        }
        if (daysUntilBirthday === 0) {
            return 'Happy Birthday!';
        }
        let _days = 'days';
        if (daysUntilBirthday === 1) {
            _days = 'day';
        }
        return `(${daysUntilBirthday} ${_days} till birthday)`;
    }
    return null;
}
