import React, { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Modal from 'react-modal';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

import UploadImageModal from './UploadImageModal';
import UploadVideoModal from './UploadVideoModal';
import UploadAudioModal from './UploadAudioModal';
import ListVideos from './ListVideos';
import ListImages from './ListImages';
import ListAudios from './ListAudios';

import uploadStyle from '../../../styles/react-modal/upload.style';
import awsUploadFile from '../../../controllers/aws/uploadFile';
import FilesAPI from '../../../services/api/FilesApi';

import * as filesAction from '../../../store/actions/files';
import * as errorsAction from '../../../store/actions/alerts';
import * as AWS from '../../../constants/aws';
import * as ATTACH from '../../../constants/attach';
import * as LENGTH from '../../../constants/length';
import * as REGEXP from '../../../constants/regExp';
import VideoConverterApi from '../../../services/api/v3/VideoConverter';
import * as IMAGES from '../Images';
import * as alertAction from '../../../store/actions/alerts';
import { sendErrToSentry } from '../../../services/sentry';

export default (props) => {
    const { sender, sendImg, sendVideo, sendAudio, userId, closeAttachModal } = props;
    const dispatch = useDispatch();

    const [modalIsOpenImg, setModalIsOpenImg] = useState(false);
    const [modalIsOpenVideo, setModalIsOpenVideo] = useState(false);
    const [modalIsOpenAudio, setModalIsOpenAudio] = useState(false);

    const filesState = useSelector((state) => state.files);
    const { images, videos, audios } = filesState;

    const [imgFile, setImgFile] = useState({});
    const [videoFile, setVideoFile] = useState({});
    const [audioFile, setAudioFile] = useState({});
    const [imageLoadingStatus, setImageLoadingStatus] = useState(false);
    const [videoLoadingStatus, setVideoLoadingStatus] = useState(false);
    const [audioLoadingStatus, setAudioLoadingStatus] = useState(false);

    const openModalImg = () => setModalIsOpenImg(true);
    const closeModalImg = () => setModalIsOpenImg(false);

    const openModalVideo = () => setModalIsOpenVideo(true);
    const closeModalVideo = () => setModalIsOpenVideo(false);

    const openModalAudio = () => setModalIsOpenAudio(true);
    const closeModalAudio = () => setModalIsOpenAudio(false);

    const handleUploadAudio = (e) => {
        const type = e?.target?.files[0]?.type;
        const name = e?.target?.files[0]?.name;

        if (!e.target?.files.length || !e.target?.files) return dispatch(errorsAction.setMessage('File must be audio'));
        if (type?.split('/')[0] !== 'audio')
            return dispatch(
                errorsAction.setMessage(
                    `Probably, you've uploaded not an audio file. File must be an audio`,
                    `Wrong file attached`,
                ),
            );
        if (name?.search(REGEXP.IMAGE_FILENAME) >= 0)
            return dispatch(
                errorsAction.setMessage(`File name doesn't have to contain special characters`, 'Wrong file name'),
            );

        openModalAudio();
        setAudioFile(e.target.files[0]);
    };

    const handleUploadImg = (e) => {
        const type = e?.target?.files[0]?.type;
        const name = e?.target?.files[0]?.name;

        if (!e.target?.files.length || !e.target?.files) return dispatch(errorsAction.setMessage('File must be image'));
        if (type?.includes('svg'))
            return dispatch(errorsAction.setMessage(`Please upload a file of jpg or png format`, 'Wrong file format'));
        if (type?.split('/')[0] !== 'image')
            return dispatch(
                errorsAction.setMessage(
                    `Probably, you've uploaded not an image file. File must be an image`,
                    `Wrong file attached`,
                ),
            );
        if (name?.search(REGEXP.IMAGE_FILENAME) >= 0)
            return dispatch(
                errorsAction.setMessage(`File name doesn't have to contain special characters`, 'Wrong file name'),
            );

        openModalImg();
        setImgFile(e.target.files[0]);
    };

    const handleUploadVideo = (e) => {
        const type = e?.target?.files[0]?.type;
        const name = e?.target?.files[0]?.name;

        if (!e.target?.files.length || !e.target?.files) return dispatch(errorsAction.setMessage('File must be video'));
        if (type?.split('/')[0] !== 'video')
            return dispatch(
                errorsAction.setMessage(
                    `Probably, you've uploaded not a video file. File must be a video`,
                    `Wrong file attached`,
                ),
            );
        if (name?.search(REGEXP.IMAGE_FILENAME) >= 0)
            return dispatch(
                errorsAction.setMessage(`File name doesn't have to contain special characters`, 'Wrong file name'),
            );

        openModalVideo();
        setVideoFile(e.target.files[0]);
    };

    const uploadImg = ({ file, fileName }) => {
        try {
            setImageLoadingStatus(true);
            awsUploadFile(file, fileName, userId, AWS.IMG_BUCKET).then((res) => {
                if (res?.message || res?.error) {
                    dispatch(alertAction.setMessage('', res?.message || res?.error));
                }
                setImageLoadingStatus(false);
                new FilesAPI()
                    .postImage({
                        external_id: userId,
                        filename: res?.data?.filename,
                        link: res?.data?.link,
                    })
                    .then((res) => {
                        if (res?.status) {
                            dispatch(filesAction.getAllImages(userId));
                        }
                    });
            });
        } catch (error) {
            sendErrToSentry(error);
            setVideoLoadingStatus(false);
        }
    };
    const uploadVideo = ({ file, fileName }) => {
        try {
            setVideoLoadingStatus(true);
            awsUploadFile(file, fileName, userId, AWS.VIDEO_BUCKET).then((resAWS) => {
                if (resAWS?.message || resAWS?.error) {
                    dispatch(alertAction.setMessage('', resAWS?.message || resAWS?.error));
                }
                setVideoLoadingStatus(false);
                new FilesAPI()
                    .postVideo({
                        external_id: userId,
                        filename: resAWS?.data?.filename,
                        link: resAWS?.data?.link,
                    })
                    .then((res) => {
                        if (res?.status) {
                            dispatch(filesAction.getAllVideos(userId));
                            new VideoConverterApi().postVideoLinkToConvert(resAWS?.data?.link);
                        }
                    });
            });
        } catch (error) {
            sendErrToSentry(error);
            setVideoLoadingStatus(false);
        }
    };
    const uploadAudio = ({ file, fileName }) => {
        try {
            setAudioLoadingStatus(true);
            awsUploadFile(file, fileName, userId, AWS.AUDIO_BUCKET).then((res) => {
                if (res?.message || res?.error) {
                    dispatch(alertAction.setMessage('', res?.message || res?.error));
                }
                setAudioLoadingStatus(false);
                new FilesAPI()
                    .postAudio({
                        external_id: userId,
                        filename: res?.data?.filename,
                        link: res?.data?.link,
                    })
                    .then((res) => {
                        if (res?.status) {
                            dispatch(filesAction.getAllAudios(userId));
                        }
                    });
            });
        } catch (error) {
            sendErrToSentry(error);
            setVideoLoadingStatus(false);
        }
    };

    return (
        <DndProvider backend={Backend}>
            <div className="upload_popup_wrap_create" id="upload_popup_wrap_create">
                <div className="upload_popup_tabs" id="upload_popup_tabs">
                    <Tabs>
                        <TabList className="upload_popup_tabs_ul">
                            <Tab className="upload_popup_tabs_li">
                                <span>Photo</span>
                            </Tab>
                            <Tab className="upload_popup_tabs_li">
                                <span>Video</span>
                            </Tab>
                            <Tab className="upload_popup_tabs_li">
                                <span>Audio</span>
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <div className="upload_popup_tabs_content" id="tab-photo">
                                <div className="upload_popup_tabs_content_top">
                                    <div className="sort">
                                        <img src="/img/move.svg" alt="" />
                                        Sorting
                                    </div>
                                </div>
                                <ListImages
                                    userId={userId}
                                    status={imageLoadingStatus}
                                    images={images}
                                    type={ATTACH.IMG_LIST}
                                />
                                <div className="upload_popup_tabs_content_bottom">
                                    <div className="popup_bottom_upload_btn_wrap">
                                        <input
                                            type="file"
                                            id="popup_inp_file1"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={handleUploadImg}
                                            onClick={() => (imgFile.size ? setModalIsOpenImg(true) : null)}
                                        />
                                        <label htmlFor="popup_inp_file1" className="popup_bottom_upload_btn">
                                            <img src="/img/popup-upload.svg" alt="" />
                                            <span>Upload</span>
                                        </label>
                                        <div className="popup_bottom_upload_btn_info">
                                            Upload limit is {LENGTH.MAX_IMG_SIZE_TITLE} Mb
                                        </div>
                                    </div>
                                    {sender ? (
                                        <div className="attach-btn-collection">
                                            <div
                                                className="popup_bottom_send_btn"
                                                onClick={(e) => sendImg(e, !!sender, false)}
                                            >
                                                Run
                                            </div>
                                            {/*< div className="popup_bottom_send_btn"*/}
                                            {/*	  onClick={(e) => sendImg(e, !!sender, true)}>Save*/}
                                            {/*</div>*/}
                                        </div>
                                    ) : (
                                        <div className="popup_bottom_send_btn" onClick={(e) => sendImg(e)}>
                                            <img src="/img/popup-send-btn.svg" alt="" />
                                            Send image
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Modal
                                style={uploadStyle}
                                isOpen={modalIsOpenImg}
                                onRequestClose={closeModalImg}
                                ariaHideApp={false}
                            >
                                <UploadImageModal
                                    imgFile={imgFile}
                                    setImgFile={setImgFile}
                                    handleUploadImg={handleUploadImg}
                                    closeModalImg={closeModalImg}
                                    uploadImg={uploadImg}
                                />
                            </Modal>
                        </TabPanel>
                        <TabPanel>
                            <div className="upload_popup_tabs_content" id="tab-video">
                                <div className="upload_popup_tabs_content_top">
                                    <div className="sort">
                                        <img src="/img/move.svg" alt="" />
                                        Sorting
                                    </div>
                                </div>
                                <ListVideos
                                    userId={userId}
                                    status={videoLoadingStatus}
                                    videos={videos}
                                    type={ATTACH.VIDEO_LIST}
                                />
                                <div className="upload_popup_tabs_content_bottom">
                                    <input
                                        type="file"
                                        accept="video/*"
                                        style={{ display: 'none' }}
                                        id="popup_inp_file2"
                                        onChange={handleUploadVideo}
                                        onClick={() => (videoFile.size ? setModalIsOpenVideo(true) : null)}
                                    />
                                    <label htmlFor="popup_inp_file2" className="popup_bottom_upload_btn">
                                        <img src="/img/popup-upload.svg" alt="" />
                                        <span>Upload</span>
                                    </label>
                                    <div className="popup_bottom_upload_btn_info">
                                        Upload limit is {LENGTH.MAX_VIDEO_SIZE_TITLE} Mb
                                    </div>
                                    {sender ? (
                                        <div className="attach-btn-collection">
                                            <div
                                                className="popup_bottom_send_btn"
                                                onClick={(e) => sendVideo(e, !!sender, false)}
                                            >
                                                Run
                                            </div>
                                            {/*< div className="popup_bottom_send_btn"*/}
                                            {/*	  onClick={(e) => sendVideo(e, !!sender, true)}>Save*/}
                                            {/*</div>*/}
                                        </div>
                                    ) : (
                                        <div className="popup_bottom_send_btn" onClick={(e) => sendVideo(e)}>
                                            <img src="/img/popup-send-btn.svg" alt="" />
                                            Send video
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Modal
                                style={uploadStyle}
                                isOpen={modalIsOpenVideo}
                                onRequestClose={closeModalVideo}
                                ariaHideApp={false}
                            >
                                <UploadVideoModal
                                    videoFile={videoFile}
                                    setVideoFile={setVideoFile}
                                    handleUploadVideo={handleUploadVideo}
                                    closeModalVideo={closeModalVideo}
                                    uploadVideo={uploadVideo}
                                />
                            </Modal>
                        </TabPanel>
                        <TabPanel>
                            <div className="upload_popup_tabs_content" id="tab-audio">
                                <div className="upload_popup_tabs_content_top">
                                    <div className="sort">
                                        <img src="/img/move.svg" alt="" />
                                        Sorting
                                    </div>
                                </div>
                                <ListAudios
                                    userId={userId}
                                    status={audioLoadingStatus}
                                    audios={audios}
                                    type={ATTACH.AUDIO_LIST}
                                />
                                <div className="upload_popup_tabs_content_bottom">
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        id="popup_inp_file3"
                                        accept="audio/*"
                                        onChange={handleUploadAudio}
                                        onClick={() => (audioFile.size ? setModalIsOpenAudio(true) : null)}
                                    />
                                    <label htmlFor="popup_inp_file3" className="popup_bottom_upload_btn">
                                        <img src="/img/popup-upload.svg" alt="" />
                                        <span>Upload</span>
                                    </label>
                                    <div className="popup_bottom_upload_btn_info">
                                        Upload limit is {LENGTH.MAX_AUDIO_SIZE_TITLE} Mb
                                    </div>
                                    {sender ? (
                                        <div className="attach-btn-collection">
                                            <div
                                                className="popup_bottom_send_btn"
                                                onClick={(e) => sendAudio(e, !!sender, false)}
                                            >
                                                Run
                                            </div>
                                            {/*< div className="popup_bottom_send_btn"*/}
                                            {/*	  onClick={(e) => sendAudio(e, !!sender, true)}>Save*/}
                                            {/*</div>*/}
                                        </div>
                                    ) : (
                                        <div className="popup_bottom_send_btn" onClick={(e) => sendAudio(e)}>
                                            <img src="/img/popup-send-btn.svg" alt="" />
                                            Send audio
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Modal
                                style={uploadStyle}
                                isOpen={modalIsOpenAudio}
                                onRequestClose={closeModalAudio}
                                ariaHideApp={false}
                            >
                                <UploadAudioModal
                                    audioFile={audioFile}
                                    setAudioFile={setAudioFile}
                                    handleUploadAudio={handleUploadAudio}
                                    closeModalAudio={closeModalAudio}
                                    uploadAudio={uploadAudio}
                                />
                            </Modal>
                        </TabPanel>
                    </Tabs>
                </div>
                <div className="close_one_popup" onClick={closeAttachModal}>
                    <img src={IMAGES.popup_gift_close} alt="" />
                </div>
            </div>
        </DndProvider>
    );
};
