import React from 'react';
import { useSelector } from 'react-redux';

import Header from './Header';
import List from './List';

export default () => {
    const onlineManState = useSelector((state) => state.onlineMan);

    return (
        <div className="column-2">
            <Header />
            <List chatsState={onlineManState} />
            <div className="c2_close" />
        </div>
    );
};
