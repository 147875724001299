import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as syncAction from '../../store/actions/sync';
import * as SYNC from '../../constants/sync';
import useChatSync from './useChatSync';
import useLetterSync from './useLetterSync';
import useLimitsSync from './useLimitsSync';
import useHandlePersonal from '../personal/useHandlePersonal';
import useHandleWomanProfile from '../profiles/useHandleWomanProfile';

let channels = ['woman_info_channel_0'];

export default function useInitSync() {
    const dispatch = useDispatch();
    const chatSync = useChatSync();
    const letterSync = useLetterSync();
    const limitsSync = useLimitsSync();
    const handlePersonal = useHandlePersonal();
    const handleWomanProfile = useHandleWomanProfile();

    const syncProvider = useSelector((state) => state.sync.provider);
    const profilesState = useSelector((state) => state.profiles);

    const [isSubscribeProfileList, setIsSubscribeProfileList] = useState(false);
    const [message, setMessage] = useState({});
    const [prevMessage, setPrevMessage] = useState({});

    const checkUniqueSocketMessage = () => {
        if (message?.action === SYNC.READ_MAIL_ACTION) return true;
        return !(JSON.stringify(prevMessage) === JSON.stringify(message));
    };

    useEffect(() => {
        dispatch(syncAction.init());
    }, []);

    useEffect(() => {
        if (syncProvider) {
            syncProvider.subscribe(channels);
            syncProvider.addListener(SYNC.MESSAGE_EVENT, setMessage);
        }
    }, [syncProvider, channels]);

    useEffect(() => {
        if (profilesState?.list?.length && !isSubscribeProfileList) {
            channels = profilesState?.list.map((item) => `counters_profile_${item?.external_id}`);
            if (localStorage.getItem('operator')) {
                channels.push(`channel_operator_id_${localStorage.getItem('operator')}`);
            }
            setIsSubscribeProfileList(true);
        }
    }, [profilesState.list]);

    useMemo(() => {
        setPrevMessage(message);

        if (checkUniqueSocketMessage() || message?.action === SYNC.OPEN_CHAT_ACTION) {
            const action = message?.action;

            switch (action) {
                case SYNC.LIKE_ACTION:
                case SYNC.MESS_WINKED:
                case SYNC.MESS_ACTION:
                case SYNC.VIEW_ACTION:
                case SYNC.VIEWED_PHOTOS:
                    chatSync.getMessage(message);
                    break;

                case SYNC.OPEN_CHAT_ACTION: {
                    chatSync.readChat(message);
                    break;
                }

                case SYNC.MAIL_ACTION: {
                    letterSync.getLetter(message);
                    break;
                }

                case SYNC.READ_MAIL_ACTION: {
                    letterSync.readLetter(message);
                    break;
                }

                case SYNC.REACTION_LIMITS: {
                    limitsSync.getLimits(message);
                    break;
                }

                case SYNC.ONLINE_STATUS: {
                    const isOperator = message?.operator_list
                        ?.map((item) => +item)
                        ?.includes(+profilesState?.operatorId);
                    if (isOperator) handleWomanProfile.fetchWomanProfileList();

                    break;
                }

                case SYNC.UPD_PERSONAL: {
                    const isOperator = +profilesState?.operatorId === +message?.operator_id;
                    if (isOperator) handlePersonal.fetchPersonalManList();

                    break;
                }

                default:
                    break;
            }
        }
    }, [message]);
}
