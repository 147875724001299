import React from 'react';

export default function Search({ searchInput, handleSearch, deleteSearchInput, placeHolder = 'Search ID or name' }) {
    return (
        <div className="c2_top_search">
            <input
                type="text"
                name="searchField"
                value={searchInput}
                onChange={handleSearch}
                placeholder={placeHolder}
            />
            <button type="button" onClick={() => (searchInput?.length > 0 ? deleteSearchInput() : null)}>
                {searchInput?.length > 0 ? (
                    <img src="/img/c2-remove.svg" alt="" />
                ) : (
                    <img src="/img/c2_search_btn.svg" alt="" />
                )}
            </button>
        </div>
    );
}
