import loaderStyle from '../../../../styles/react-modal/loader.style';
import LoaderGif from '../../LoaderGif';
import Modal from 'react-modal';
import React from 'react';
import { useSelector } from 'react-redux';

export default () => {
    const activeGifLoader = useSelector((state) => state.loader.activeGifLoader);

    return (
        <Modal style={loaderStyle} isOpen={activeGifLoader} ariaHideApp={false}>
            <LoaderGif />
        </Modal>
    );
};
