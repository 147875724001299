import * as SENDER from '../constants/sender';
import * as SENDER_GLOBAL from '../../constants/sender';

const initState = {
    activeProfile: null,
    activeProfileSendList: [],
    activeType: SENDER_GLOBAL.SENDER_TYPE_CHAT,
    activeGroups: [SENDER_GLOBAL.SENDER_GROUP_ONLINE_NOW],

    sendingTime: SENDER_GLOBAL.SENDER_SENDING_TIME_IMMEDIATELY,
    sendingTimeSetTime: '',

    willExpire: SENDER_GLOBAL.SENDER_WILL_EXPIRE_NEVER,
    willExpireSetTimeNum: 0,
    willExpireSetTimeType: '',
};

export default function (state = initState, action) {
    switch (action.type) {
        case SENDER.SET_ACTIVE_SENDER_PROFILE_SEND_LIST:
            return { ...state, ...{ activeProfileSendList: action?.sendList } };

        case SENDER.REMOVE_ACTIVE_SENDER_PROFILE_SEND_LIST:
            return { ...state, ...{ activeProfileSendList: [] } };

        case SENDER.ADD_ACTIVE_SENDER_PROFILE_SEND_LIST:
            return { ...state, ...{ activeProfileSendList: action?.sendList } };

        case SENDER.SET_ACTIVE_SENDER_PROFILE:
            if (action?.externalID) return { ...state, ...{ activeProfile: action?.externalID } };
            return { ...state, ...{ activeProfile: null } };

        case SENDER.SET_ACTIVE_SENDER_TYPE:
            if (action?.senderType) return { ...state, ...{ activeType: action?.senderType } };
            return state;

        case SENDER.ADD_ACTIVE_SENDER_GROUP:
            if (action?.senderGroup)
                return {
                    ...state,
                    ...{ activeGroups: [...state.activeGroups, ...[action?.senderGroup]] },
                };
            return state;

        case SENDER.REMOVE_ACTIVE_SENDER_GROUP:
            if (action?.senderGroup)
                return {
                    ...state,
                    ...{
                        activeGroups: state.activeGroups.filter((group) => group !== action?.senderGroup),
                    },
                };
            return state;

        case SENDER.SET_SENDING_TIME:
            if (action?.sendingTime) return { ...state, ...{ sendingTime: action?.sendingTime } };
            return state;

        case SENDER.SET_SENDING_TIME_DATE:
            if (action?.sendingTimeDate) return { ...state, ...{ sendingTimeSetTime: action?.sendingTimeDate } };
            return state;

        case SENDER.SET_WILL_EXPIRE:
            if (action?.willExpire) return { ...state, ...{ willExpire: action?.willExpire } };
            return state;

        case SENDER.SET_WILL_EXPIRE_DATE:
            if (action?.willExpireSetTimeNum)
                return { ...state, ...{ willExpireSetTimeNum: action?.willExpireSetTimeNum } };
            return state;

        case SENDER.OPEN_SENDER_LIST:
            return { ...state, ...{ isOpenSenderList: true } };

        case SENDER.CLOSE_SENDER_LIST:
            return { ...state, ...{ isOpenSenderList: false } };

        default:
            return state;
    }
}
