export default {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(85,85,85,0.5)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: '99999',
		inset: '0px'
	},
	content: {
		position: 'relative',
		width: '430px',
		maxWidth: '100%',
		height: '264px',
		display: 'flex',
		alignItems: 'center',
		boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.05)',
		borderRadius: '8px',
		backgroundColor: '#fff',
		textAlign: 'center',
		inset: '0px'
	}
};
