import * as FILTER from '../constants/filter';

export function setGirlFilter(menuName) {
    return function (dispatch) {
        return dispatch({
            type: FILTER.SET_GIRL_FILTER,
            data: menuName,
        });
    };
}

export function setLettersFilter(menuName) {
    return function (dispatch) {
        return dispatch({
            type: FILTER.SET_LETTERS_FILTER,
            data: menuName,
        });
    };
}

export function setFilterById(options) {
    return function (dispatch) {
        return dispatch({
            type: FILTER.SET_FILTER_BY_ID,
            data: options,
        });
    };
}

export function setOnlineFilter(menuName) {
    return function (dispatch) {
        return dispatch({
            type: FILTER.SET_ONLINE_FILTER,
            data: menuName,
        });
    };
}

export function setFreezeFilter(freeze) {
    return function (dispatch) {
        return dispatch({
            type: FILTER.SET_FREEZE_FILTER,
            data: freeze,
        });
    };
}

export function setInitStateFilter() {
    return function (dispatch) {
        return dispatch({
            type: FILTER.SET_INIT_FILTER,
        });
    };
}
