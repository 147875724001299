import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import Header from '../../chat/ChatRoom/Header';
import * as LETTER from '../../../constants/letter';
// import {getLastSeen} from "../../../services/time";
import Messages from './Messages';
import useHandleLettersChat from '../../../hooks/letters/useHandleLettersChat';
import useChatRoomHeaderFunctions from '../../../hooks/chat/useChatRoomHeaderFunctions';
import FormLetters from './FormLetters';
import * as filesAction from '../../../store/actions/files';
import useGetConfig from '../../../hooks/config/useGetConfig';

export default () => {
    const dispatch = useDispatch();

    const isBookmarked = false;
    const currentChatMailboxState = useSelector((state) => state.currentChatMailbox);
    const profilesState = useSelector((state) => state.profiles);

    const chatRoomHeaderFunctions = useChatRoomHeaderFunctions();
    // const lastSeen = getLastSeen(currentChatMailboxState?.history);
    const handleLettersChat = useHandleLettersChat();

    const { configData, sendUserComplaint, configHeader } = useGetConfig(
        currentChatMailboxState?.info?.male_id,
        currentChatMailboxState?.info?.female_id,
    );

    useEffect(() => {
        return () => dispatch(filesAction.clearAllFiles());
    }, []);

    return (
        <div className={styles.letters_chat_wrap}>
            <Header
                {...chatRoomHeaderFunctions}
                womanProfile={profilesState?.list.find(
                    (profile) => +profile?.external_id === +currentChatMailboxState?.info?.female_id,
                )}
                complaintsArray={configData?.complaint}
                sendUserComplaint={sendUserComplaint}
                configData={configData}
                configHeader={configHeader}
            />
            <div className={styles.letters_chat} data-testid="letter-body">
                <div className={styles.letters_chat_list_scroll} onScroll={handleLettersChat.onScrollList}>
                    <div className={styles.letters_chat_list}>
                        {currentChatMailboxState?.history?.length > 0 && (
                            <Messages
                                {...handleLettersChat}
                                currentChatMailboxState={currentChatMailboxState}
                                scrollPosition={handleLettersChat.scrollPosition}
                                isBookmarked={isBookmarked}
                                isUserOnline={chatRoomHeaderFunctions?.currentChatInfo?.isUserOnline}
                                history={
                                    currentChatMailboxState?.info?.actionTarget === LETTER.ACTION_TARGET_LETTER
                                        ? currentChatMailboxState.history //.filter(item => item?.mail?.id === currentChatMailboxState.info.id)
                                        : currentChatMailboxState.history
                                }
                            />
                        )}
                        <div className={styles.clmn_3_chat_typing}>
                            <div className={`${styles.chat_was_online}`}>{/* {lastSeen?.message} */}</div>
                            <div className={styles.chat_typing_right}>
                                {currentChatMailboxState?.info?.letter_limit} letters left
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FormLetters configData={configData} />
        </div>
    );
};
