import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileApi from '../../../services/api/ProfileApi';
import { useHistory } from 'react-router-dom';
import { getLastOnlineMessage } from '../../../services/time';
import * as IMAGES from '../../common/Images';
import * as menuAction from '../../../store/actions/menu';

export default function ProfileManHeader({ user_info }) {
    const profilesState = useSelector((state) => state.profiles);
    const history = useHistory();
    const dispatch = useDispatch();

    const id_operator =
        profilesState && profilesState.active && profilesState.active.external_id
            ? profilesState.active.external_id
            : null;
    const male_id = user_info && user_info.user_detail ? user_info.user_detail.external_id : null;
    const LAST_ONLINE = getLastOnlineMessage(user_info?.user_detail?.last_online);

    const goToChat = () => {
        new ProfileApi().getChatWithProfile(male_id, id_operator).then((response) => {
            if (response?.status) {
                dispatch(menuAction.setMainMenu('chat'));
                history.push(`/chat/${response?.chat_uid}`);
            }
        });
    };

    const goToLetter = () => {
        new ProfileApi().getChatWithProfile(male_id, id_operator).then((response) => {
            if (response?.status) {
                dispatch(menuAction.setMainMenu('letter'));
                history.push(`/letter/${response?.chat_uid}`);
            }
        });
    };

    return (
        <header>
            <div className="container">
                <div className="c3_header">
                    <nav className="header-menu">
                        <div className="header-menu-element">
                            <div
                                onClick={goToChat}
                                className={`${profilesState?.active?.external_id ? '' : 'ui-state-lock ui-state-disabled'}`}
                            >
                                <button className={'header-menu-btn pointer-cursor'} data-testid="go-to-chat">
                                    <div className="header-menu-item">
                                        <img
                                            src={IMAGES?.default?.sender_head_chat}
                                            alt=""
                                            className="header-menu__img"
                                        />
                                        <div className="header-menu-text">Go to chat</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="header-menu-element">
                            <div
                                onClick={goToLetter}
                                className={`${profilesState?.active?.external_id ? '' : 'ui-state-lock ui-state-disabled'}`}
                            >
                                <button className={'header-menu-btn pointer-cursor'} data-testid="go-to-letter">
                                    <div className="header-menu-item">
                                        <img src={IMAGES?.default?.c3ch_letters} alt="" className="header-menu__img" />
                                        <div className="header-menu-text">Go to letter</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </nav>
                    {LAST_ONLINE && (
                        <div className={`c3_header_status ${LAST_ONLINE?.status}`}>
                            <div className="c3_header_status_text">Last online:</div>
                            <div className="c3_header_status_date">{LAST_ONLINE?.message}</div>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}
