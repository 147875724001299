import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as virtualGiftsAction from '../../store/actions/virtualGifts';
import ChatsAPI from '../../services/api/ChatsApi';
import * as CHAT from '../../constants/chat';
import * as currentChatAction from '../../store/actions/currentChat';
import * as alertsAction from '../../store/actions/alerts';
import * as ERROR from '../../constants/error';
import * as chatsAction from '../../store/actions/chats';
import md5 from 'js-md5';
import moment from 'moment';
import useOutsideClicker from '../dom/useOutsideClicker';

export default function useVirtualGifts({ womanExternalId, userExternalId }) {
    const dispatch = useDispatch();
    const virtualGiftsState = useSelector((state) => state.virtualGifts);
    const chatsState = useSelector((state) => state.chats);

    const [isOpenVirtualGifts, setIsOpenVirtualGifts] = useState(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);

    const virtualGiftListRef = useRef(null);
    const virtualGiftBtnRef = useRef(null);

    useOutsideClicker(virtualGiftListRef, setIsOpenVirtualGifts, virtualGiftBtnRef);

    const isStreamPage = window.location?.pathname?.split('/')[1] === 'stream';
    const isMobVirtualGiftsBtn = window.innerWidth <= 1140;

    const toggleVirtualGifts = () => {
        setIsOpenVirtualGifts(!isOpenVirtualGifts);
    };

    const sendVirtualGift = (gift) => {
        if (isLoadingRequest) return false;
        setIsLoadingRequest(true);

        const hashId = md5(moment().format());

        new ChatsAPI()
            .postMessage(womanExternalId, userExternalId, gift?.id, CHAT.MESSAGE_CONTENT_TYPE_VIRTUAL_GIFT, gift?.name)
            .then((res) => {
                setIsLoadingRequest(false);

                function badRequest() {
                    dispatch(currentChatAction.removeMessageInHistory(hashId));

                    if (res?.error) {
                        if (res?.error?.includes('no connection')) {
                            dispatch(currentChatAction.updateMessageLimit(0, 0));
                            return dispatch(alertsAction.setMessage('', 'Message limit reached'));
                        }
                        dispatch(alertsAction.setMessage(res?.error, ERROR.INTERNAL_ERROR_OCCURRED));
                    } else dispatch(alertsAction.setMessage('', ERROR.INTERNAL_ERROR_OCCURRED));
                }

                if (!res?.response?.message_object || !res?.status) return badRequest();
                if (res?.status) {
                    dispatch(
                        currentChatAction.updateMessageLimit(
                            res?.response?.chat_list_object?.message_limit,
                            res?.response?.chat_list_object?.like_limit,
                        ),
                    );
                    dispatch(currentChatAction.updateMessageInHistory(hashId, res?.response?.message_object));
                    dispatch(
                        chatsAction.updateActiveChat({ ...chatsState?.activeChat, ...res?.response?.chat_list_object }),
                    );
                    setIsOpenVirtualGifts(false);
                } else {
                    dispatch(alertsAction.setMessage('', ERROR.NOT_EXIST_OR_BLOCK));
                }
            });
    };

    useEffect(() => {
        if (userExternalId) {
            if (virtualGiftsState?.chat?.length || virtualGiftsState?.stream?.length) {
                dispatch(virtualGiftsAction.clearVirtualGiftsData());
            }
            dispatch(virtualGiftsAction.getVirtualGiftsData(userExternalId));
        }

        return () => {
            dispatch(virtualGiftsAction.clearVirtualGiftsData());
        };
    }, [userExternalId]);

    return {
        isMobVirtualGiftsBtn,
        isOpenVirtualGifts,
        toggleVirtualGifts,
        virtualGiftsData: isStreamPage ? virtualGiftsState?.stream : virtualGiftsState?.chat,
        sendVirtualGift,
        virtualGiftListRef,
        virtualGiftBtnRef,
    };
}
