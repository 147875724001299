import React from 'react';
import StylesMess from '../../styles.module.scss';
import styles from './styles.module.scss';
import * as IMAGES from '../../../../../../common/Images/index';
import ViewPostModal from '../../../../../../posts/ViewPostModal';
import useViewPostModal from '../../../../../../../hooks/posts/useViewPostModal';

export default function ReplyPost({ data, setImageOnLoad, womanInfo }) {
    const viewPostModal = useViewPostModal();
    const postContent = data?.post_content;
    const firstImage = postContent?.files?.[0]?.imageSmallUrl ?? postContent?.files?.[0]?.imageLargeUrl;
    const videoIconStyle =
        postContent?.files?.length === 1 && postContent?.files[0]?.type === 'video' ? styles.video_icon : '';
    const carouselIconStyle = postContent?.files?.length > 1 ? styles.carousel_icon : '';
    const isPostDeleted = postContent === null;

    const handleOpenPost = () => {
        if (isPostDeleted) return;
        viewPostModal?.openViewModalById(postContent?.id);
    };

    return (
        <>
            <div className={`${StylesMess.message_text}`} data-testid="message-reply-post">
                <div className={styles.post_wrap}>
                    <div className={styles.post_header}>
                        <div className={`${styles.post_avatar_wrap} ${+womanInfo?.online === 1 ? styles.online : ''}`}>
                            <img
                                src={womanInfo?.photo_link ?? IMAGES.profile_img_empty}
                                width="40"
                                height="40"
                                alt=""
                            />
                        </div>
                        <h3 className={styles.post_profile_name}>{womanInfo?.name}</h3>
                    </div>
                    <div
                        className={`${styles.post_image_wrap} ${carouselIconStyle} ${videoIconStyle} ${isPostDeleted ? styles.post_deleted : ''}`}
                        onClick={handleOpenPost}
                    >
                        {!isPostDeleted && (
                            <img
                                className={styles.post_image}
                                src={firstImage}
                                onLoad={() => setImageOnLoad(true)}
                                alt=""
                                data-testid={firstImage}
                                width={280}
                                height={280}
                            />
                        )}
                    </div>
                    {!isPostDeleted && <p className={styles.post_text}>{postContent?.text}</p>}
                </div>
            </div>

            <ViewPostModal {...viewPostModal} activeProfile={womanInfo} />
        </>
    );
}
