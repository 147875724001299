import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as alertAction from '../../store/actions/alerts';
import * as filesAction from '../../store/actions/files';
import * as ATTACH from '../../constants/attach';
import * as ALERTS from '../../store/constants/alerts';
import FilesAPI from '../../services/api/FilesApi';

export default function useHandleMediaFolder({ activeTypeFile, userId }) {
    const dispatch = useDispatch();
    const filesState = useSelector((state) => state.files);

    const [folderList, setFolderList] = useState([]);
    const [activeFolder, setActiveFolder] = useState({});
    const [activeRenameFolder, setActiveRenameFolder] = useState({});
    const [isAddToFolderModal, setIsAddToFolderModal] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState([]);

    const switcherActiveType = () => {
        switch (activeTypeFile) {
            case ATTACH.TAB_IMAGE:
                return ATTACH.TYPE_IMAGE;
            case ATTACH.TAB_VIDEO:
                return ATTACH.TYPE_VIDEO;
            case ATTACH.TAB_AUDIO:
                return ATTACH.TYPE_AUDIO;
            default:
                return '';
        }
    };

    const switcherGetFiles = () => {
        switch (activeTypeFile) {
            case ATTACH.TAB_IMAGE:
                return dispatch(filesAction.getAllImages(userId));
            case ATTACH.TAB_VIDEO:
                return dispatch(filesAction.getAllVideos(userId));
            case ATTACH.TAB_AUDIO:
                return dispatch(filesAction.getAllAudios(userId));
            default:
                return '';
        }
    };

    const clearCheckedFiles = () => {
        const checkedList = document.querySelectorAll('#media-content-block .selected');
        const checkedListInput = document.querySelectorAll('#media-content-block input:checked');

        for (const checkedItem of checkedList) {
            checkedItem.classList.remove('selected');
        }

        for (const checkedInput of checkedListInput) {
            checkedInput.checked = false;
        }
    };

    const addNewFolder = () => {
        new FilesAPI().postCreateFolder(userId, switcherActiveType(), `New folder`).then((res) => {
            if (res?.status && Object.keys(res?.folder).length !== 0) {
                dispatch(filesAction.addNewFolder(res?.folder?.type, res?.folder));
            }
        });
    };

    const onSetActiveFolder = (folder) => {
        setActiveFolder(folder);
        clearCheckedFiles();
    };

    const onSetActiveRenameFolder = (folder) => {
        if (folder?.id !== 0) {
            setActiveRenameFolder(folder);
        }
    };

    const handleRenameFolder = (e) => {
        const { value } = e.currentTarget;

        if (+value?.length > 24) return;

        setActiveRenameFolder({ ...activeRenameFolder, ...{ name: value } });
    };

    const saveFolderName = (folderId) => {
        const newFolderName = !activeRenameFolder?.name?.length ? `Undefined` : activeRenameFolder?.name;

        new FilesAPI().postEditFolder({ id: folderId, name: newFolderName }).then((res) => {
            if (res?.status && Object.keys(res?.folder).length !== 0) {
                dispatch(filesAction.renameFolder(res?.folder?.type, res?.folder));

                setActiveRenameFolder({});
            }
        });
    };

    const openDeleteAlert = () => {
        dispatch(
            alertAction.setCustomConfirmAlert({
                type: ALERTS.SET_CUSTOM_CONFIRM_ALERT,
                title: `Are you sure you want to delete the folder "${activeFolder?.name}"?`,
                btnName1: 'No',
                btnName2: 'Yes',
                successCallback: () => deleteFolder(activeFolder.type, activeFolder?.id),
            }),
        );
    };

    const deleteFolder = (folderType, folderId) => {
        const activeFoldersType = `${folderType}Folders`;
        const activeFilesList = filesState[activeFoldersType][folderId]?.list;

        new FilesAPI().postDeleteFolder(folderId).then((res) => {
            if (res.status && res.folder) {
                if (activeFilesList?.length) {
                    switcherGetFiles();
                } else {
                    dispatch(filesAction.deleteFolder(folderType, folderId));
                }
                setActiveFolder({});
            }
        });
    };

    const openAddToFolderModal = () => setIsAddToFolderModal(true);
    const closeAddToFolderModal = () => setIsAddToFolderModal(false);

    const onAddToFolder = () => {
        const checkedList = document.querySelectorAll('#media-content-block input:checked');

        if (!folderList?.filter((el) => el?.id !== 0)?.length)
            return dispatch(alertAction.setMessage('First create a folder'));
        if (!checkedList?.length) return dispatch(alertAction.setMessage('Please select a file'));

        let arrFileId = [];

        for (const checkedItem of checkedList) {
            if (+checkedItem?.name) arrFileId.push(+checkedItem?.name);
        }

        if (arrFileId.length) {
            setSelectedFileId(arrFileId);
            openAddToFolderModal();
        }
    };

    const saveFileToFolder = (folderId) => {
        if (selectedFileId?.length) {
            new FilesAPI().postEditFolder({ id: folderId, addList: selectedFileId }).then((res) => {
                if (res?.status && Object.keys(res?.folder).length !== 0) {
                    switcherGetFiles();
                    closeAddToFolderModal();
                    setSelectedFileId([]);
                    setActiveFolder(res?.folder);
                }
            });
        }
    };

    const deleteFileFromFolder = () => {
        const checkedList = document.querySelectorAll('#media-content-block input:checked');

        if (!checkedList?.length) return dispatch(alertAction.setMessage('Please select a file'));

        let arrFileId = [];

        for (const checkedItem of checkedList) {
            if (+checkedItem?.name) arrFileId.push(+checkedItem?.name);
        }

        if (arrFileId.length && activeFolder?.id) {
            new FilesAPI().postEditFolder({ id: activeFolder?.id, deleteList: arrFileId }).then((res) => {
                if (res?.status && Object.keys(res?.folder).length !== 0) {
                    switcherGetFiles();
                }
            });
        }
    };

    useEffect(() => {
        const withoutFolder = { id: 0, name: 'Not defined', type: activeTypeFile, woman_external_id: userId };

        if (activeFolder?.type && switcherActiveType() !== activeFolder?.type) {
            setActiveFolder({});
        }

        if (activeTypeFile === ATTACH.TAB_IMAGE && filesState?.imageFoldersList?.length) {
            return setFolderList([withoutFolder, ...filesState.imageFoldersList]);
        }

        if (activeTypeFile === ATTACH.TAB_VIDEO && filesState?.videoFoldersList?.length) {
            return setFolderList([withoutFolder, ...filesState.videoFoldersList]);
        }

        if (activeTypeFile === ATTACH.TAB_AUDIO && filesState?.audioFoldersList?.length) {
            return setFolderList([withoutFolder, ...filesState.audioFoldersList]);
        }
    }, [filesState.imageFoldersList, filesState.videoFoldersList, filesState.audioFoldersList, activeTypeFile]);

    return {
        folderList,
        addNewFolder,
        activeFolder,
        onSetActiveFolder,
        activeRenameFolder,
        onSetActiveRenameFolder,
        handleRenameFolder,
        saveFolderName,
        openDeleteAlert,
        onAddToFolder,
        isAddToFolderModal,
        closeAddToFolderModal,
        saveFileToFolder,
        deleteFileFromFolder,
    };
}
