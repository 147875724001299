import * as ALERTS from '../constants/alerts';

export function clearMessage() {
    return function (dispatch) {
        return dispatch({
            type: ALERTS.CLEAR_MESSAGE,
        });
    };
}

export function setMessage(text, title) {
    return function (dispatch) {
        return dispatch({
            type: ALERTS.SET_MESSAGE,
            text,
            title,
        });
    };
}

export function setCustomMessage(type) {
    return function (dispatch) {
        return dispatch({
            type,
        });
    };
}

export function setCustomConfirmAlert({ title = '', text = '', btnName1 = '', btnName2 = '', successCallback = {} }) {
    return function (dispatch) {
        return dispatch({
            type: ALERTS.SET_CUSTOM_CONFIRM_ALERT,
            title,
            text,
            btnName1,
            btnName2,
            successCallback,
        });
    };
}
