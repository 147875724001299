import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import '../../../styles/audio-player/letter-style.scss';
import * as IMAGES from '../Images';

export default ({ src, viewedAudio }) => {
    return (
        <AudioPlayer
            src={src}
            onPlay={viewedAudio}
            customIcons={{
                play: <img src={IMAGES.popup_play} alt="" />,
                pause: <img src={IMAGES.stop_in_attach_file} alt="" />,
            }}
        />
    );
};
