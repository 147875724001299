import React from 'react';
import * as IMAGES from '../../common/Images';
import Styles from './styles.module.scss';

export default function ProfilesList({
    listProfiles,
    setIsOpenListProfiles,
    isOpenListProfiles,
    searchName,
    handleSearchName,
    setActiveProfile,
    setOnlineStatus,
    setAllOnlineStatus,
    isAllOnlineStatus,
    offlineProfilesAmount,
    multiProfileListBlockRef,
    isActiveSender,
}) {
    return (
        <div
            className={`${Styles.clmn_1_mm_chat_wrap} ${isOpenListProfiles ? Styles.opened : ''}`}
            ref={multiProfileListBlockRef}
            data-testid="profiles-btn"
        >
            <div className={Styles.clmn_1_mm_chat_btn} onClick={setIsOpenListProfiles}>
                <img src={IMAGES.default.c1_mm_heart} alt="" className={Styles.clmn_1_mm_chat_btn_heart} />
                <img src={IMAGES.default.c1_mm_arrow} alt="" className={Styles.clmn_1_mm_chat_btn_arrow} />
            </div>
            <div className={Styles.clmn_1_mm_chat_list_wrap} data-testid="profiles-block">
                <div className={Styles.clmn_1_mm_chat_search}>
                    <input
                        type="text"
                        className={Styles.clmn_1_mm_chat_search_inp}
                        value={searchName}
                        data-testid="idOrName"
                        onChange={(e) => handleSearchName(e.target.value)}
                        placeholder="Search ID or name"
                    />
                </div>
                {!!listProfiles?.length && (
                    <>
                        <div className={Styles.clmn_1_mm_chat_list} data-testid="profiles-list">
                            {listProfiles.map((item, key) => (
                                <div
                                    className={Styles.clmn_1_mm_chat_list_item}
                                    key={key}
                                    data-testid={`profile-item-${item?.external_id}`}
                                >
                                    <div
                                        className={`${Styles.clmn_1_mm_chat_list_item_photo} ${+item?.online ? Styles.online : ''}`}
                                        onClick={() => setActiveProfile(item)}
                                        data-testid="profile-photo"
                                    >
                                        {item?.photo_link && <img src={item?.photo_link ?? ''} alt="" />}
                                    </div>
                                    <div
                                        className={Styles.clmn_1_mm_chat_list_item_middle}
                                        onClick={() => setActiveProfile(item)}
                                    >
                                        <div className={Styles.clmn_1_mm_chat_list_item_middle_top}>
                                            <div
                                                className={Styles.clmn_1_mm_chat_list_item_name}
                                                data-testid="profile-name"
                                            >
                                                {`${item?.name ?? ''}, ${item?.age ?? ''}`}
                                            </div>
                                            {isActiveSender(item?.external_id) && (
                                                <div className={Styles.clmn_1_mm_chat_list_item_status_green} />
                                            )}
                                            {/*<div className={Styles.clmn_1_mm_chat_list_item_status_yellow}/>*/}
                                        </div>
                                        {/*<div className={Styles.clmn_1_mm_chat_list_item_count}>*/}
                                        {/*	<div className={Styles.clmn_1_mm_chat_list_item_count_chats}>*/}
                                        {/*		<img src={IMAGES.c1_flag} alt=""/>*/}
                                        {/*		/!*<span>23</span>*!/*/}
                                        {/*	</div>*/}
                                        {/*	<div className={Styles.clmn_1_mm_chat_list_item_count_letters}>*/}
                                        {/*		<img src={IMAGES.c1_mm_letters} alt=""/>*/}
                                        {/*		/!*<span>5</span>*!/*/}
                                        {/*	</div>*/}
                                        {/*	<div className={Styles.clmn_1_mm_chat_list_item_count_likes}>*/}
                                        {/*		<img src={IMAGES.c1_mm_heart_gray} alt=""/>*/}
                                        {/*		/!*<span>5</span>*!/*/}
                                        {/*	</div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div
                                        className={Styles.clmn_1_mm_chat_list_item_right}
                                        data-testid="activate-profile"
                                    >
                                        <input
                                            type="checkbox"
                                            id="www"
                                            checked={!!+item?.online}
                                            onChange={() => {}}
                                            className={Styles.clmn_1_mm_chat_list_item_input}
                                        />
                                        <label
                                            htmlFor="www"
                                            className={Styles.clmn_1_mm_chat_list_item_label}
                                            onClick={() => setOnlineStatus(item?.external_id, item?.online)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={Styles.clmn_1_mm_chat_offline_girls}>
                            {!isNaN(+offlineProfilesAmount) && (
                                <div className={Styles.clmn_1_mm_chat_offline_girls_text}>
                                    {offlineProfilesAmount} girls offline
                                </div>
                            )}
                            <div className={Styles.clmn_1_mm_chat_list_item_right} data-testid="activate-all-profiles">
                                <input
                                    type="checkbox"
                                    id="www2"
                                    checked={!!isAllOnlineStatus}
                                    onChange={() => {}}
                                    className={Styles.clmn_1_mm_chat_list_item_input}
                                />
                                <label
                                    htmlFor="www2"
                                    className={Styles.clmn_1_mm_chat_list_item_label}
                                    onClick={setAllOnlineStatus}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
