import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../services/api/v3/ConfigApi';
import * as loaderAction from '../../store/actions/loader';
import * as CONFIG from '../../constants/config';
import * as alertAction from '../../store/actions/alerts';

export default function useGetConfig(manExternalID, womanExternalID) {
    const dispatch = useDispatch();
    const [configData, setConfigData] = useState({});
    const [configHeader, setConfigHeader] = useState(0);
    const [isConfigHeaderAlert, setIsConfigHeaderAlert] = useState(false);

    const sendUserComplaint = (complaintType) => {
        if (manExternalID && womanExternalID && complaintType) {
            dispatch(loaderAction.setActiveGifLoader(true));
            new ConfigApi()
                .postUserComplaint(+manExternalID, +womanExternalID, complaintType)
                .then((res) => {
                    if (res?.status) {
                        setConfigData({
                            ...configData,
                            ...{ complaint: configData?.complaint?.filter((el) => el !== complaintType) },
                        });
                    } else {
                        dispatch(alertAction.setMessage('Something went wrong!'));
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));
        }
    };

    useEffect(() => {
        if (manExternalID && womanExternalID) {
            dispatch(loaderAction.setActiveGifLoader(true));
            new ConfigApi()
                .getConfigById(+manExternalID, +womanExternalID)
                .then((res) => {
                    if (res?.status && res?.result) {
                        setConfigData(res?.result);
                    } else {
                        setConfigData(CONFIG.DEFAULT_CONFIG);
                    }
                })
                .finally(() => dispatch(loaderAction.setActiveGifLoader(false)));

            new ConfigApi().getConfigHeaderById(+manExternalID, +womanExternalID).then((res) => {
                if (res?.status) {
                    setConfigHeader(res?.result?.type);
                    setIsConfigHeaderAlert(res?.result?.isAlert);
                }
            });
        }
    }, [manExternalID, womanExternalID]);

    return { configData, sendUserComplaint, configHeader, isConfigHeaderAlert };
}
