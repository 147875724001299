import React from 'react';
import styles from '../styles.module.scss';
import * as IMAGES from '../../../common/Images';

export default ({
    letterFilterState,
    setOnline,
    handleChangeFilterMen,
    inputId,
    handleInputId,
    filterById,
    handleChangeFilterLetters,
    setFreeze,
}) => {
    const isFreeze = letterFilterState.freezeFilter;

    return (
        <div className={styles.letters_filters}>
            <div className={styles.letters_filters_head}>
                <div className={styles.letters_filters_head_title}>Filters</div>
                <div className={styles.letters_filters_head_freeze} onClick={() => setFreeze(!isFreeze)}>
                    <img src={isFreeze ? IMAGES.freeze_cube_full : IMAGES.freeze_cube} alt="" />
                </div>
                <div className={styles.letters_filters_head_tumbler} data-testid="recipient-online">
                    <input
                        type="checkbox"
                        id="letters_filter_freeze"
                        checked={letterFilterState.onlineFilter === 'online'}
                        onChange={setOnline}
                    />
                    <label htmlFor="letters_filter_freeze" />
                </div>
            </div>
            <div className={styles.letters_filters_row} data-testid="recipient-filter">
                <div className={styles.letters_filters_title}>MEN</div>
                <div className={styles.letters_filters_list}>
                    <div
                        className={`${styles.letters_filter_item} ${letterFilterState.manFilter === 'all' && styles.active}`}
                        onClick={() => handleChangeFilterMen('all')}
                        data-testid="recipient-all"
                    >
                        All
                    </div>
                    <div
                        className={`${styles.letters_filter_item} ${letterFilterState.manFilter === 'id' && styles.active}`}
                        onClick={() => handleChangeFilterMen('id')}
                        data-testid="recipient-id"
                    >
                        ID
                    </div>
                    {letterFilterState.manFilter === 'id' && (
                        <div className={styles.letters_filter_item_id}>
                            <input
                                type="text"
                                placeholder="Enter ID"
                                data-testid="recipient-input"
                                value={inputId}
                                onChange={handleInputId}
                            />
                            <button onClick={filterById} data-testid="submit-recipient-input-btn">
                                <img src={IMAGES.c2_search} alt="" />
                            </button>
                        </div>
                    )}
                    <div
                        className={`${styles.letters_filter_item} ${letterFilterState.manFilter === 'dialogues' && styles.active}`}
                        onClick={() => handleChangeFilterMen('dialogues')}
                        data-testid="recipient-dialogues"
                    >
                        Dialogues
                    </div>
                    <div
                        className={`${styles.letters_filter_item} ${letterFilterState.manFilter === 'favorites' && styles.active}`}
                        onClick={() => handleChangeFilterMen('favorites')}
                        data-testid="recipient-favorites"
                    >
                        Favorites
                    </div>
                    <div
                        className={`${styles.letters_filter_item} ${letterFilterState.manFilter === 'match' && styles.active}`}
                        onClick={() => handleChangeFilterMen('match')}
                        data-testid="recipient-matches"
                    >
                        Matches
                    </div>
                </div>
            </div>
            <div className={styles.letters_filters_row} data-testid="letter-filter">
                <div className={styles.letters_filters_title}>Letters</div>
                <div className={styles.letters_filters_list}>
                    <div
                        className={`${styles.letters_filter_item} ${letterFilterState.lettersFilter === 'all' && styles.active}`}
                        onClick={() => handleChangeFilterLetters('all')}
                        data-testid="letter-all"
                    >
                        All
                    </div>
                    <div
                        className={`${styles.letters_filter_item} ${letterFilterState.lettersFilter === 'unread' && styles.active}`}
                        onClick={() => handleChangeFilterLetters('unread')}
                        data-testid="letter-unread"
                    >
                        Unread
                    </div>
                    <div
                        className={`${styles.letters_filter_item} ${letterFilterState.lettersFilter === 'unanswered' && styles.active}`}
                        onClick={() => handleChangeFilterLetters('unanswered')}
                        data-testid="letter-unanswered"
                    >
                        Unanswered
                    </div>
                </div>
            </div>
        </div>
    );
};
