import React, { useEffect } from 'react';

import Header from './Header';
import History from './History';
import Form from './Form';

import Styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import useChatRoomHeaderFunctions from '../../../hooks/chat/useChatRoomHeaderFunctions';
import * as filesAction from '../../../store/actions/files';
import useGetConfig from '../../../hooks/config/useGetConfig';
import useCloseCurrentChat from '../../../hooks/chat/useCloseCurrentChat';

export default function ChatRoom() {
    const dispatch = useDispatch();
    const closeCurrentChat = useCloseCurrentChat();

    const currentChatState = useSelector((state) => state.currentChat);
    const profilesState = useSelector((state) => state.profiles);

    const chatRoomHeaderFunctions = useChatRoomHeaderFunctions();
    const { configData, sendUserComplaint, configHeader, isConfigHeaderAlert } = useGetConfig(
        currentChatState?.info?.male_external_id,
        currentChatState?.info?.female_external_id,
    );

    useEffect(() => {
        return () => {
            closeCurrentChat();
            dispatch(filesAction.clearAllFiles());
        };
    }, []);

    return (
        <div className={`${Styles.clmn_3} ${Styles.clmn_3_chat}`}>
            {!!(configHeader === 1 && isConfigHeaderAlert) && (
                <div className={Styles.notification}>
                    <span>Attention: Important User!</span>
                    <span>
                        Please be aware, this user is currently testing our products and services. During this time, it
                        is crucial to be exercise extreme care and caution when communicating with these user.
                    </span>
                </div>
            )}

            <Header
                {...chatRoomHeaderFunctions}
                womanProfile={profilesState?.list.find(
                    (profile) => +profile?.external_id === +currentChatState?.info?.female_external_id,
                )}
                complaintsArray={configData?.complaint}
                sendUserComplaint={sendUserComplaint}
                configData={configData}
                configHeader={configHeader}
            />
            <History />
            <Form configData={configData} />
        </div>
    );
}
