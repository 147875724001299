import React from 'react';
import { useSelector } from 'react-redux';
import StatSearchParams from './StatSearchParams';
import styles from './styles.module.scss';
import '../../styles/stats-page.scss';
import LoaderGif from '../common/LoaderGif';
import './Stat/style.scss';
import useInitStatisticTable from '../../hooks/statisticsTable/useInitStatisticTable';
import * as IMAGES from '../common/Images';

export default function Stat() {
    const statisticState = useSelector((state) => state.statistic);
    const statisticTable = useInitStatisticTable();

    return (
        <>
            <div className={styles.stats_clmn_3}>
                <div className={styles.stats_page_head}>
                    <StatSearchParams {...statisticTable} isLoading={statisticTable?.isLoading} />
                    <div className={styles.stats_page_head_total_text}>
                        {!!statisticTable?.total && (
                            <div>
                                Total balance: <b>{statisticTable?.total}</b>
                            </div>
                        )}
                    </div>
                </div>
                {statisticState?.statistic?.length ? (
                    <div className={styles.stats_page_table_wrap}>
                        <table className={styles.table}>
                            <thead>
                                <tr className={styles.table_head_row}>{statisticTable.getTableHead()}</tr>
                            </thead>
                            <tbody>{statisticTable.GetColumns()}</tbody>
                        </table>
                    </div>
                ) : statisticState?.isLoading ? (
                    <LoaderGif />
                ) : (
                    <div className={styles.block_page_empty}>
                        <img src={IMAGES.not_found_results} alt="" />
                        <div>Your statistic list is empty</div>
                    </div>
                )}
            </div>
        </>
    );
}
