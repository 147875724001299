import React from 'react';
import { useSelector } from 'react-redux';
import Message from './Message';
import Styles from './styles.module.scss';
import LoaderGif from '../../../common/LoaderGif';
import { getLastSeen } from '../../../../services/time';
import useScrollHistory from '../../../../hooks/chat/useScrollHistory';
import { uniqueArrayByID } from '../../../../services/methods';

export default function History() {
    const currentChatState = useSelector((state) => state.currentChat);
    const lastSeen = getLastSeen(currentChatState?.history);
    const { scrollHistory, filterByPageScrollList } = useScrollHistory();

    return (
        <div className={Styles.clmn_3_chat_list_wrap} data-testid="chat-body">
            {currentChatState?.history?.length ? (
                <div className={Styles.clmn_3_chat_scroll} onScroll={scrollHistory}>
                    <div className={Styles.clmn_3_chat_list}>
                        {currentChatState?.historyPageLoading && <LoaderGif />}
                        {uniqueArrayByID(currentChatState?.history?.filter(filterByPageScrollList))?.map(
                            (item, key) => (
                                <Message key={`${key}_${item?.id}`} item={item} isFromYou={!+item?.is_male} />
                            ),
                        )}
                    </div>
                </div>
            ) : currentChatState?.status ? (
                <div />
            ) : (
                <LoaderGif />
            )}
            <div className={Styles.clmn_3_chat_typing}>
                <div className={`${Styles.chat_was_online} ${Styles[lastSeen?.status]}`} data-testid="last-seen">
                    {lastSeen?.message}
                </div>
                <div className={Styles.chat_typing_right} data-testid="message-limit">
                    {`${currentChatState?.info?.message_limit} messages left`}
                </div>
            </div>
        </div>
    );
}
