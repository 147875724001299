import * as VIRTUAL_GIFTS from '../constants/virtualGifts';

const initState = {
    chat: [],
    stream: [],
};

export default function (state = initState, action) {
    switch (action.type) {
        case VIRTUAL_GIFTS.GET_VIRTUAL_GIFTS_DATA:
            return {
                ...state,
                ...{
                    chat: action.chat,
                    stream: action.stream,
                },
            };

        case VIRTUAL_GIFTS.ClEAR_VIRTUAL_GIFTS_DATA:
            return {
                ...state,
                ...{
                    chat: [],
                    stream: [],
                },
            };

        default:
            return state;
    }
}
